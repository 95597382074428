import React, { useState } from 'react';
import {
  Button, Typography, Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';

const NewStripeAccount = () => {
  const stripeUrl = process.env.REACT_APP_STRIPE_URL;
  const tryingStripeAuthorisation = useSelector(
    (state) => state.companies?.tryingStripeAuthorisation,
  );
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <>
      <Typography variant="body1" style={{ marginBottom: '1rem' }}>
        If you do not currently have a Stripe account,
        please continue reading below for further instructions on what to do.
      </Typography>

      <Typography variant="body1" style={{ marginBottom: '1rem' }}>
        Setting up a Stripe account on Pitchbooking Manager
        will make it easy for your customers to make quick, no-hassle
        card payments online.
      </Typography>

      <Typography variant="body1" style={{ marginBottom: '1rem' }}>
        This includes casual online bookings, Subscription bookings and Invoices via
        Pitchbooking Payment Links.
      </Typography>

      <div style={{
        display: 'flex', flexDirection: 'column', width: '75%', marginTop: '2rem',
      }}
      >
        <Typography variant="body1" style={{ marginBottom: '1rem', fontWeight: 'bold', fontStyle: 'italic' }}>
          Below is some helpful information
          if you are setting up a stripe account for the first time.
        </Typography>

        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Your Business/Organisation</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              <ul>
                <li style={{ marginBottom: '1rem' }}>
                  Business website - This must be a valid domain name or it wont be accepted
                </li>
                <li>
                  Business description -
                  You can give as much or as little info as youd like here but include
                  the basics. A sample answer might be Local council/club selling sports
                  facilities to the public via online booking solution.
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Account Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              <ul>
                <li style={{ marginBottom: '1rem' }}>
                  Type of Account is a drop-down menu - select what your business type is
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  {'Note, if you are a Council, select \'Sole trader\' from the menu'}
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  Company number - You can find this on companies house
                  if you dont know it (N/A to sole trader)
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  VAT number is optional
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  Descriptor - Enter a name for your business that your customers will recognise.
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <div style={{ display: 'flex' }}>
                    <Typography variant="body1" color="Secondary" style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>
                      NOTE:
                    </Typography>
                    {`You're setting up Stripe for your company but you also need
                  to include your personal details. This is for verification purposes.
                  You must provide supporting documentation to verify your identity
                  and that you are acting on behalf of your company/organisation.`}
                  </div>
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Two-step Authentication</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              <ul>
                <li style={{ marginBottom: '1rem' }}>
                  Select ‘get text message’ to verify
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <div style={{ display: 'flex' }}>
                    <Typography variant="body1" color="Secondary" style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>
                      Top Tip:
                    </Typography>
                    Pick the number of the person most likely to be dealing with
                    payments on a regular basis because if you ever run into issues,
                    this person will need to authenticate the account
                  </div>
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <div style={{ marginTop: '2rem' }}>
          <Typography variant="body1" style={{ marginBottom: '1rem' }}>
            To get started, click the button below to create a new Stripe account.
          </Typography>

          <Button
            id="manager-settings-set-up-stripe"
            variant="contained"
            color="primary"
            style={{ color: '#fff', backgroundColor: '#47FEB4' }}
            disabled={tryingStripeAuthorisation}
            href={stripeUrl}
            target="_blank"
          >
            Create a new Stripe account
          </Button>
        </div>

        <Typography variant="body1" style={{ margin: '2rem 0rem' }}>
          When you complete the set up questions,
          CONNECT your account to Pitchbooking.
          This will bring you back to the Manager Dashboard
          and you will see a message saying:
          <Typography variant="body1" color="secondary" style={{ fontWeight: 'bold' }}>
            {'\'Connected with Stripe\''}
          </Typography>
          Your account is now connected and you are ready to accept payments.
        </Typography>
      </div>
    </>
  );
};

export default NewStripeAccount;
