/* eslint-disable import/prefer-default-export */
import {
  takeEvery, call, put,
} from 'redux-saga/effects';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  getOrdersService,
} from '../services/ordersServices';
import * as actions from '../reducers/ordersReducer';
import { handleSagaError } from './helperSaga';

function* getSitesOrdersSaga(companyId, action) {
  const { selectedSiteIds, startDate, endDate } = action?.selectedSitesAndDates;
  const isAllSitesSelected = selectedSiteIds?.includes('ALL_SITES');
  const isNonSiteSpecificSelected = selectedSiteIds?.includes('NON_SITE_SPECIFIC');
  const siteIds = selectedSiteIds?.filter((id) => id !== 'ALL_SITES' && id !== 'NON_SITE_SPECIFIC');

  const reqBody = {
    dateRange: {
      startDate,
      endDate,
    },
    orderTypes: {
      all: isAllSitesSelected && isNonSiteSpecificSelected,
      non_site_specific: isNonSiteSpecificSelected,
      sites: siteIds,
    },
    sort: {
      sortBy: 'paidAt',
      sortOrder: 'ASC',
    },
    emailReport: action?.type === 'ORDERS_PAYMENT_REPORT_REQUESTED',
  };

  if (companyId) {
    try {
      const response = yield call(getOrdersService, companyId, reqBody);
      const orders = [...response.data];

      if (!response.error) {
        yield put(actions.siteOrdersRetrievedSuccesfully(orders));
        if (action?.type === 'ORDERS_PAYMENT_REPORT_REQUESTED') {
          yield put(sessionActions.updateSuccessfulSnackbar('Please check your email to access your report', true));
        }
      } else {
        throw response;
      }
    } catch (error) {
      console.log('error', error);
      yield put(actions.siteOrdersRetrievedFailed(error.data));
      yield call(handleSagaError, error, 'getOrdersSaga');
    }
  }
}

// WATCHERS
export function* sitesOrdersWatcher(companyId) {
  yield takeEvery(actions.ORDERS_RETRIEVAL_REQUESTED, getSitesOrdersSaga, companyId);
  yield takeEvery(actions.ORDERS_PAYMENT_REPORT_REQUESTED, getSitesOrdersSaga, companyId);
}
