import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {
  withStyles,
} from '@material-ui/core/styles';

const StyledTooltip = withStyles({
  tooltip: {
    color: '#51545a',
    backgroundColor: '#fff',
    border: '2px solid #4581e2',
    borderRadius: '10px',
    fontSize: '1em',
    boxShadow: '0 0 0 100vmax rgba(0,0,0,.7)',
  },

})(Tooltip);

// Basic styled tooltip component for form inputs etc.
const BasicTooltip = ({ title }) => (
  <StyledTooltip title={title} arrow>
    <IconButton>
      <InfoIcon style={{ color: '#4781e2' }} />
    </IconButton>
  </StyledTooltip>
);

BasicTooltip.propTypes = { title: PropTypes.string.isRequired };

export default BasicTooltip;
