import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, ClickAwayListener } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import * as contentful from 'contentful';
import ReactMarkdown from 'react-markdown';
import {
  withStyles,
} from '@material-ui/core/styles';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import { getToolTipID } from './ToolTipHelper';

const PBTooltip = withStyles({
  tooltip: {
    color: '#51545a',
    backgroundColor: '#fff',
    border: '2px solid #4581e2',
    borderRadius: '20px',
    fontSize: '1em',
    boxShadow: '0 0 0 100vmax rgba(0,0,0,.7)',
  },

})(Tooltip);

function ToolTip(props) {
  const [open, setOpen] = React.useState(false);
  const [toolTipContent, setToolTipContent] = React.useState('');
  const [overrideTitleDisplay, setOverrideTitleDisplay] = React.useState('');
  // eslint-disable-next-line react/prop-types
  const { contentfulName, overrideTitle } = props;
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (open) {
      if (contentfulName) {
        const id = getToolTipID(contentfulName);
        const client = contentful.createClient({
          space: 'usvb7o98ifrz',
          accessToken: '07a808cf4fadbdec5e07be8b4ffc784319f4b0c161ee9bf721e6dd730c876118',
        });
        client.getEntry(id)
          .then((entry) => setToolTipContent(entry.fields.body))
          .catch((err) => console.log(err));
      } else {
        setToolTipContent(overrideTitle);
      }
    } else {
      setToolTipContent('');
      document.body.style.opacity = 1;
    }
    // handle tooltip close on esc key click
    const handleUserKeyPress = (event) => {
      if (event.key === 'Escape') {
        handleTooltipClose();
      }
    };

    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [open]);

  useEffect(() => {
    if (overrideTitle) {
      if (overrideTitle.length > 15) {
        setOverrideTitleDisplay(`${overrideTitle.substring(0, 15)}...`);
      } else {
        setOverrideTitleDisplay(overrideTitle);
      }
    }
  }, [overrideTitle]);

  return (
    <ClickAwayListener onClickAway={() => handleTooltipClose()}>
      <PBTooltip
        interactive
        onClose={() => handleTooltipClose()}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        // eslint-disable-next-line react/no-children-prop
        title={<ReactMarkdown children={toolTipContent} />}
      >

        <Button
          id={contentfulName}
          onClick={() => handleTooltipOpen()}
          style={{
            alignSelf: 'center', verticalAlign: 'center', minWidth: '12px', backgroundColor: 'transparent',
          }}
        >
          <AlternativelyVisible condition={contentfulName}>
            <InfoIcon style={{
              alignSelf: 'center', verticalAlign: 'middle', color: '#4781e2', fontSize: '32px',
            }}
            />
            <div style={{ color: '#3300FF' }}>
              {overrideTitleDisplay}
            </div>
          </AlternativelyVisible>
        </Button>
      </PBTooltip>
    </ClickAwayListener>
  );
}

ToolTip.propTypes = {
  contentfulName: PropTypes.string,
  overrideTitle: PropTypes.string,
};

ToolTip.defaultProps = {
  contentfulName: '',
  overrideTitle: '',
};

export default (ToolTip);
