import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { DialogContentText } from '@material-ui/core';
import InvoicePreview from '../InvoicePreview';
import * as actions from '../../../reducers/invoicesReducer';
import MenuHeading from '../../../components/MenuHeading';

const InvoiceStepperPreview = (props) => {
  const {
    user, invoice, reservations, additionalItems, updateCreateInvoiceStore,
  } = props;
  const { sendEmail } = invoice;

  const handleSendEmail = () => {
    updateCreateInvoiceStore({
      sendEmail: !sendEmail,
    });
  };

  return (
    <>
      <MenuHeading title="Here is a preview of what will be sent to your customer. This will be attached as a pdf in the email they receive." />
      <div style={{ display: 'flex' }}>
        <Checkbox
          style={{ paddingTop: 0 }}
          checked={sendEmail}
          onClick={() => handleSendEmail()}
          disableRipple
        />
        <DialogContentText>
          {`Send to ${user.email}.`}
        </DialogContentText>
      </div>
      <InvoicePreview
        invoice={invoice}
        reservations={reservations}
        additionalItems={additionalItems}
        creation
      />
    </>
  );
};

InvoiceStepperPreview.propTypes = {
  user: PropTypes.shape().isRequired,
  invoice: PropTypes.shape().isRequired,
  reservations: PropTypes.shape().isRequired,
  additionalItems: PropTypes.shape().isRequired,
  updateCreateInvoiceStore: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.invoices.createInvoice.user,
  invoice: state.invoices.createInvoice,
  reservations: state.invoices.createInvoice.selectedReservations,
  additionalItems: state.invoices.createInvoice.additionalItems,
});

const mapDispatchToProps = (dispatch) => ({
  updateCreateInvoiceStore: (keyWithValue) => dispatch(
    actions.updateCreateInvoiceStore(keyWithValue),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceStepperPreview);
