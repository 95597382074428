/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import MenuHeading from '../../../components/MenuHeading';
import AddTagDialog from '../../../components/AddTagDialog';
import * as tagActions from '../../../reducers/tagsReducer';
import { editLeagueDetails } from '../../../services/leaguesServices';

const EditLeagueDialog = () => {
  const dispatch = useDispatch();
  const league = useSelector((state) => state.leagues.league);
  const companyTags = useSelector((state) => state.tags.companyTags);
  const selectedTags = useSelector((state) => state.tags.selectedTags);
  const filteredTags = companyTags ? companyTags.filter((tag) => tag.category === 'LEAGUE_TYPE') : [];
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [error, setError] = useState('');
  const isMobile = window.innerWidth < 768;
  const validationSchema = Yup.object({
    name: Yup.string(),
    description: Yup.string(),
    maxTeams: Yup.number().moreThan(3, 'You need to have at least 4 teams to create a league'),
  });

  const handleUpdateLeagueTags = (league) => {
    const leagueId = league.id;
    dispatch(tagActions.updateLeagueTags(leagueId));
    setOpen(false);
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: league?.name || '',
          description: league?.description || '',
          maxTeams: league?.maxTeams || 4,
          closingDate: league?.closingDate || moment().format('YYYY-MM-DD HH:mm'),
          visible: league?.visible || false,
          additionalInfo: league?.additionalInfo || null,
        }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values) => {
          try {
            const edit = await editLeagueDetails(league.id, values);
            if (edit.status === 200) {
              dispatch(sessionActions.updateSuccessfulSnackbar('Your league has been updated successfully!', true));
              setOpen(false);
            }
          } catch (err) {
            console.error(err);
            setError('An error occurred while attempting to handle your request.Please try again, if the issues persists please contact us at support @pitchbooking.com.');
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (

          <form>
            <Button color="primary" variant="contained" onClick={() => setOpen(true)}>
              Edit League
            </Button>
            <Dialog
              open={open}
              fullScreen={isMobile}
              fullWidth
            >
              <ConditionallyVisible condition={error}>
                <Alert severity="error" style={{ margin: '1rem 2rem' }}>
                  {error}
                </Alert>
              </ConditionallyVisible>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <DialogTitle>
                  Edit League
                </DialogTitle>

                <DialogActions variant="none">
                  <CloseDialogIcon onClick={() => setOpen(false)} />
                </DialogActions>
              </div>
              <DialogContent>
                {/* Title */}
                <MenuHeading title="League Title" />
                <PBInput
                  id="name"
                  type="text"
                  variant="outlined"
                  value={values.name}
                  isError={touched.name && Boolean(errors.name)}
                  errorMessage={touched.name && errors.name}
                  onChange={handleChange}
                />

                {/* Description */}
                <MenuHeading title="Description" />
                <PBInput
                  id="description"
                  type="textarea"
                  rows="4"
                  multiline
                  variant="outlined"
                  value={values.description}
                  isError={touched.description && Boolean(errors.description)}
                  errorMessage={touched.description && errors.description}
                  onChange={handleChange}
                />

                {/* Additional Info */}
                {values.additionalInfo && (
                  <>
                    <MenuHeading title="Additional Info" />
                    <PBInput
                      id="additionalInfo"
                      type="textarea"
                      rows="4"
                      multiline
                      variant="outlined"
                      value={values.additionalInfo}
                      isError={touched.additionalInfo && Boolean(errors.additionalInfo)}
                      errorMessage={touched.additionalInfo && errors.additionalInfo}
                      onChange={handleChange}
                    />
                  </>
                )}

                {/* Max Teams */}
                <MenuHeading title="Maximum Number of Teams" />
                <PBInput
                  id="maxTeams"
                  variant="outlined"
                  isError={touched.maxTeams && Boolean(errors.maxTeams)}
                  errorMessage={touched.maxTeams && errors.maxTeams}
                  value={values.maxTeams}
                  onChange={handleChange}
                />

                {/* Closing Date */}
                <MenuHeading title="Closing Date" />
                <SingleDatePicker
                  date={moment(values.closingDate)}
                  onDateChange={(date) => setFieldValue(
                    'closingDate', moment(date).format('YYYY-MM-DD HH:mm'),
                  )}
                  style={{ width: 200 }}
                  focused={focused}
                  onFocusChange={({ focused }) => setFocused(focused)}
                  displayFormat="ddd, DD/MM/YY"
                  numberOfMonths={1}
                  isOutsideRange={() => false}
                  fullWidth
                  noBorder
                />
                <MenuHeading title="Publicly visible" />
                <Switch
                  id="visible"
                  value={values.visible}
                  checked={values.visible}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />

                {/* <AddTagDialog
                  tags={filteredTags}
                  selectedTags={league.tags}
                  currentSelectedTags={selectedTags}
                  addTag={(value) => dispatch(tagActions.addToSelectedTags(value))}
                  removeTag={(value) => dispatch(tagActions.removeFromSelectedTags(value))}
                  addTags={() => handleUpdateLeagueTags(league)}
                  setSelectedTags={(tags) => dispatch(tagActions.updateSelectedTags(tags))}
                  tagRelation="League"
                /> */}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)} color="secondary" variant="outlined">
                  Close
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  variant="contained"
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>

          </form>
        )}
      </Formik>
    </div>
  );
};

export default EditLeagueDialog;
