/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export const getDashboardBookingsService = async (companyId, query) => {
  let url = `/api/dashboard/${companyId}/dashboard-bookings`;
  if (query) {
    url += '?';
    for (const key in query) {
      url += `${key}=${query[key]}&`;
    }
  }
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
};
