import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export class TabPanel extends React.Component {
  render() {
    const {
      children, value, index, ...other
    } = this.props;
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
};
