/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Typography,
  Grid,
  TextField,
  CircularProgress,
  Card,
  CardContent,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import moment from 'moment';
import { getApplicationFeesService } from '../../../services/companiesServices';

export const FeeCalculationSection = ({ companyInfo }) => {
  const { id: companyId } = companyInfo;
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalApplicationFees, setTotalApplicationFees] = useState(null);
  const [totalStripeFees, setTotalStripeFees] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const [calculationError, setCalculationError] = useState(null);
  const [useDateRange, setUseDateRange] = useState(false);

  useEffect(() => {
    const defaultStartDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    const defaultEndDate = moment().format('YYYY-MM-DD');
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
  }, []);

  const formatCurrency = (amount) => new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
  }).format(amount / 100);

  const handleCalculateFees = async () => {
    setIsCalculating(true);
    setCalculationError(null);
    try {
      const response = await getApplicationFeesService(
        companyId,
        useDateRange ? startDate : null,
        useDateRange ? endDate : null,
      );
      if (response.data) {
        setTotalApplicationFees(response.data.totalApplicationFees);
        setTotalStripeFees(response.data.totalStripeFees);
      } else {
        throw new Error('No data in response');
      }
    } catch (err) {
      setCalculationError(err.message || 'Failed to calculate fees. Please try again.');
    } finally {
      setIsCalculating(false);
    }
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Application Fees and Stripe Fees
      </Typography>

      <FormControlLabel
        control={(
          <Switch
            checked={useDateRange}
            onChange={(e) => setUseDateRange(e.target.checked)}
            color="primary"
          />
        )}
        label="Calculate over date range"
      />

      {useDateRange && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleCalculateFees}
        style={{ marginTop: '1rem' }}
        disabled={isCalculating || (useDateRange && (!startDate || !endDate))}
      >
        {isCalculating ? <CircularProgress size={24} /> : 'Calculate'}
      </Button>

      {calculationError && (
        <Typography color="error" style={{ marginTop: '1rem' }}>
          {calculationError}
        </Typography>
      )}

      {totalApplicationFees !== null && (
        <Card style={{ marginTop: '1rem' }}>
          <CardContent>
            <Typography variant="subtitle1">
              Total Application Fees:
              {' '}
              {formatCurrency(totalApplicationFees)}
            </Typography>
            <Typography variant="subtitle1">
              Total Stripe Fees:
              {' '}
              {formatCurrency(totalStripeFees - totalApplicationFees)}
            </Typography>
          </CardContent>
        </Card>
      )}

      <Divider />
    </>
  );
};
