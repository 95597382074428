import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
} from '@material-ui/core';
import * as actions from '../../../reducers/usersReducer';

// eslint-disable-next-line react/prefer-stateless-function
class PasswordEmailSuccessDialog extends Component {
  render() {
    const { passwordEmailSuccessDialogOpen, closePasswordEmailSuccessDialog } = this.props;
    return (
      <Dialog
        className="access-denied-dialog"
        open={passwordEmailSuccessDialogOpen}
        onClose={() => closePasswordEmailSuccessDialog(false)}
      >
        <DialogTitle>
          Check your mail!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have requested a password reset, you will receive an email shortly.
          </DialogContentText>
          <DialogContentText>
            You have 30 minutes to reset your password.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Button
            style={{
              margin: '5px auto',
              width: '50%',
              borderRadius: '25px',
              color: '#ffffff',
              backgroundColor: '#E8705F',
            }}
            variant="contained"
            onClick={() => closePasswordEmailSuccessDialog(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

PasswordEmailSuccessDialog.propTypes = {
  passwordEmailSuccessDialogOpen: PropTypes.bool.isRequired,
  closePasswordEmailSuccessDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  passwordEmailSuccessDialogOpen: state.users.passwordEmailSuccessDialogOpen,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closePasswordEmailSuccessDialog: (bool) => (actions.closePasswordEmailSuccessDialog(bool)),

}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PasswordEmailSuccessDialog);
