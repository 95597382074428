import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function getOrdersService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/orders`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);

  return response;
}

export async function getOrderService(companyId, orderId) {
  const url = `/api/companies/${companyId}/orders/${orderId}`;
  const response = await httpHelpers.authenticatedGetRequest(url);

  return response;
}
