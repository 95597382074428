/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/CloseRounded';
import { DayPickerSingleDateController } from 'react-dates';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import ToggleButtons from '../../../../components/ToggleButtons';
import * as actions from '../../../../reducers/accessRestrictionsReducer';
import MenuHeading from '../../../../components/MenuHeading';

const exclusionsToggleButtonData = [
  {
    buttonTitle: 'Yes',
    buttonValue: true,
  },
  {
    buttonTitle: 'NO',
    buttonValue: false,
  },
];

const AccessRestrictionsExclusionsPicker = ({
  accessRestriction,
  updateAccessRestrictionCreation,
  subscriptions,
  subscriptionDay,
  validFrom,
  validTo,
  deleteExclusion,
  exclusionDate,
  invalidDates,
}) => {
  const exclusionDates = exclusionDate?.map((exclusion) => exclusion.date);

  const { exclusions, exclusionsEnabled } = accessRestriction;

  const dateAlreadyInState = (date) => exclusions.some((exclusion) => exclusion.date === date);

  const buildNewExclusions = (add, date) => (add
    ? [...exclusions, { date }]
    : exclusions.filter((exclusion) => exclusion !== date));

  const sortedDates = exclusions.sort(
    (a, b) => moment(b).format('X') - moment(a).format('X'),
  );

  return (
    <>
      <MenuHeading title="Add Exclusions?" />
      <ToggleButtons
        buttonsData={exclusionsToggleButtonData}
        changeOption={(option) => updateAccessRestrictionCreation({
          exclusionsEnabled: option,
          exclusions: [],
        })}
        value={exclusionsEnabled}
      />
      <ConditionallyVisible
        condition={exclusionsEnabled && !subscriptions}
      >
        <>
          <DayPickerSingleDateController
            isDayBlocked={(day) => {
              if (accessRestriction.recurrence !== 'WEEKLY') {
                return false;
              }
              const isARDay = moment(day).weekday().toString()
                !== accessRestriction.weekday;
              const isBeforeValidFrom = accessRestriction.validFrom !== null
                && moment(day).isBefore(
                  moment(accessRestriction.validFrom).subtract(1, 'day'),
                );
              const isAfterValidTo = accessRestriction.validTo !== null
                && moment(day).isAfter(
                  moment(accessRestriction.validTo).add(1, 'day'),
                );
              return isARDay || isBeforeValidFrom || isAfterValidTo;
            }}
            onDateChange={(date) => {
              const formattedDate = date.format('YYYY-MM-DD');
              if (dateAlreadyInState(formattedDate)) return;
              updateAccessRestrictionCreation({
                exclusions: buildNewExclusions(true, date.format('YYYY-MM-DD')),
              });
            }}
          />

          <br />
          {exclusions.map((exclusion) => (
            <React.Fragment key={exclusion.id}>
              <span style={{ minWidth: 235, display: 'inline-block' }}>
                {moment(exclusion.date).format('dddd, MMMM Do YYYY')}
              </span>
              <DeleteIcon
                style={{
                  position: 'relative',
                  top: 7,
                  color: 'red',
                  cursor: 'pointer',
                }}
                onClick={() => deleteExclusion(exclusion)}
              />
              <br />
            </React.Fragment>
          ))}
        </>
      </ConditionallyVisible>
      <ConditionallyVisible condition={exclusionsEnabled && subscriptions}>
        <>
          <DayPickerSingleDateController
            isDayBlocked={(day) => {
              const isSubscriptionDay = moment(day).weekday() !== subscriptionDay;
              const isBeforeValidFrom = moment(day).isBefore(
                moment(validFrom).subtract(1, 'day'),
              );
              const isAfterValidTo = moment(day).isAfter(
                moment(validTo).add(1, 'day'),
              );
              const isExclusionDate = exclusionDates.some(
                (x) => moment(x).format('YYYY-MM-DD')
                  === moment(day).format('YYYY-MM-DD'),
              );
              const isInvalidDate = invalidDates?.some(
                (x) => moment(x).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD'),
              );
              return (
                isSubscriptionDay
                || isBeforeValidFrom
                || isAfterValidTo
                || isExclusionDate
                || isInvalidDate
              );
            }}
            onDateChange={(date) => {
              const formattedDate = date.format('YYYY-MM-DD');
              if (dateAlreadyInState(formattedDate)) return;
              updateAccessRestrictionCreation({
                exclusions: buildNewExclusions(true, formattedDate),
              });
            }}
          />
          <br />
          {sortedDates.map((exclusion) => (
            <React.Fragment key={exclusion.id}>
              <span style={{ minWidth: 235, display: 'inline-block' }}>
                {moment(exclusion.date).format('dddd, MMMM Do YYYY')}
              </span>
              <DeleteIcon
                style={{
                  position: 'relative',
                  top: 7,
                  color: 'red',
                  cursor: 'pointer',
                }}
                onClick={() => updateAccessRestrictionCreation({
                  exclusions: buildNewExclusions(false, exclusion),
                })}
              />
              <br />
            </React.Fragment>
          ))}
        </>
      </ConditionallyVisible>
    </>
  );
};

AccessRestrictionsExclusionsPicker.propTypes = {
  accessRestriction: PropTypes.shape().isRequired,
  updateAccessRestrictionCreation: PropTypes.func.isRequired,
  subscriptions: PropTypes.bool,
  subscriptionDay: PropTypes.string,
  validFrom: PropTypes.string,
  validTo: PropTypes.string,
  deleteExclusion: PropTypes.func.isRequired,
  invalidDates: PropTypes.arrayOf(PropTypes.string),
};

AccessRestrictionsExclusionsPicker.defaultProps = {
  subscriptions: false,
  subscriptionDay: null,
  validFrom: moment(),
  validTo: null,
  invalidDates: [],
};

const mapStateToProps = (state) => ({
  accessRestriction: state.accessRestrictions.newAccessRestriction,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccessRestrictionCreation: (keyWithValue) => dispatch(actions.updateAccessRestrictionCreation(keyWithValue)),
  deleteExclusion: (exclusion) => dispatch(actions.deleteExclusion(exclusion)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccessRestrictionsExclusionsPicker);
