/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import moment from 'moment';

export const SCHEDULES_RETRIEVAL_REQUESTED = 'SCHEDULES_RETRIEVAL_REQUESTED';
export const SCHEDULES_RETRIEVAL_SUCCEEDED = 'SCHEDULES_RETRIEVAL_SUCCEEDED ';
export const SCHEDULES_FAILED = 'SCHEDULES_FAILED';

// Store updates
export const UPDATED_SCHEDULE_GROUP_STORE = 'UPDATED_SCHEDULE_GROUP_STORE';
export const UPDATED_SCHEDULES_STORE = 'UPDATED_SCHEDULES_STORE';
export const UPDATED_SCHEDULE_STORE = 'UPDATED_SCHEDULE_STORE';
export const UPDATED_SCHEDULE_TIMES = 'UPDATED_SCHEDULE_TIMES';

// Schedule Group Dialog
export const TOGGLE_SCHEDULE_GROUP_DIALOG = 'TOGGLE_SCHEDULE_GROUP_DIALOG';
export const SCHEDULE_GROUP_DIALOG_RESET = 'SCHEDULE_GROUP_DIALOG_RESET';
export const SCHEDULE_GROUP_STORE_RESET = 'SCHEDULE_GROUP_STORE_RESET';
export const SCHEDULE_STORE_RESET = 'SCHEDULE_STORE_RESET';

// Creating a schedule group.
export const SCHEDULE_GROUP_CREATION_REQUEST = 'SCHEDULE_GROUP_CREATION_REQUEST';
export const SCHEDULE_GROUP_CREATION_SUCCEEDED = 'SCHEDULE_GROUP_CREATION_SUCCEEDED';

// Editing a schedule group
export const TOGGLED_EDITING_SCHEDULE_GROUP_OPENING_TIMES = 'TOGGLED_EDITING_SCHEDULE_GROUP_OPENING_TIMES';
export const SCHEDULE_GROUP_EDIT_ENABLED = 'SCHEDULE_GROUP_EDIT_ENABLED';
export const SAVE_SCHEDULE_GROUP_EDITS_REQUESTED = 'SAVE_SCHEDULE_GROUP_EDITS_REQUESTED';

// Creating a schedule
export const UPDATE_FULL_WEEK_SCHEDULE_STORE = 'UPDATE_FULL_WEEK_SCHEDULE_STORE';
export const REQUEST_CREATION_OF_FULL_WEEK_SCHEDULES = 'REQUEST_CREATION_OF_FULL_WEEK_SCHEDULES';
export const TOGGLE_CHANGES_MADE_TO_SCHEDULES_GROUP = 'TOGGLE_CHANGES_MADE_TO_SCHEDULES_GROUP';
export const TOGGLE_SAVED_NEW_OPENING_TIMES = 'TOGGLE_SAVED_NEW_OPENING_TIMES';

// Swaping a facility between schedules
export const REQUEST_FACILITY_SCHEDULE_SWAP = 'REQUEST_FACILITY_SCHEDULE_SWAP';

export const initialState = {
  schedules: [],
  scheduleGroup: {
    facilities: [],
    schedules: [],
    facilityIdsString: '',
  },
  schedule: {
    weekday: null,
    start: '',
    end: '',
  },
  ScheduleOpeningTimesDialogOpen: false,
  editingScheduleGroupOpeningTimes: false,
  updateScheduleGroup: false,
  fullWeekSchedules: [
    { day: '1', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
    { day: '2', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
    { day: '3', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
    { day: '4', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
    { day: '5', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
    { day: '6', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
    { day: '0', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
  ],
  savedNewOpeningTimes: false,
};

export function getSchedules() {
  return { type: SCHEDULES_RETRIEVAL_REQUESTED };
}
export function requestScheduleGroupCreation() {
  return { type: SCHEDULE_GROUP_CREATION_REQUEST };
}
export function requestFacilityScheduleSwap(id) {
  return { type: REQUEST_FACILITY_SCHEDULE_SWAP, id };
}
export function schedulesRequestSucceeded(schedules) {
  return { type: SCHEDULES_RETRIEVAL_SUCCEEDED, schedules };
}
export function toggleScheduleGroupDialog() {
  return { type: TOGGLE_SCHEDULE_GROUP_DIALOG };
}
export function toggledEditingScheduleGroupOpeningTimes() {
  return { type: TOGGLED_EDITING_SCHEDULE_GROUP_OPENING_TIMES };
}
export function resetScheduleGroupCreation() {
  return { type: SCHEDULE_GROUP_DIALOG_RESET };
}
export function resetScheduleStore() {
  return { type: SCHEDULE_STORE_RESET };
}
export function resetScheduleGroupStore() {
  return { type: SCHEDULE_GROUP_STORE_RESET };
}
export function updateScheduleGroupStore(value) {
  return { type: UPDATED_SCHEDULE_GROUP_STORE, value };
}
export function updateScheduleStore(value) {
  return { type: UPDATED_SCHEDULE_STORE, value };
}
export function updateSchedulesStore(value, id) {
  return { type: UPDATED_SCHEDULES_STORE, value, id };
}
export function updateFullWeekSchedulesStore(value) {
  return { type: UPDATE_FULL_WEEK_SCHEDULE_STORE, value };
}
export function requestFullWeekSchedulesCreation(id) {
  return { type: REQUEST_CREATION_OF_FULL_WEEK_SCHEDULES, id };
}
export function toggleChangesMadeToSchedulesGroup() {
  return { type: TOGGLE_CHANGES_MADE_TO_SCHEDULES_GROUP };
}
export function enableSchedulesGroupEdit(scheduleGroup) {
  return { type: SCHEDULE_GROUP_EDIT_ENABLED, scheduleGroup };
}
export function updateScheduleTimes(value) {
  return { type: UPDATED_SCHEDULE_TIMES, value };
}
export function saveScheduleGroupOpeningTimes() {
  return { type: SAVE_SCHEDULE_GROUP_EDITS_REQUESTED };
}
export function toggleSavedNewOpeningTimes(bool) {
  return { type: TOGGLE_SAVED_NEW_OPENING_TIMES, bool };
}
export default (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULES_RETRIEVAL_SUCCEEDED:
      return { ...state, schedules: action.schedules };
    case TOGGLE_SCHEDULE_GROUP_DIALOG:
      return { ...state, scheduleGroupDialogOpen: !state.scheduleGroupDialogOpen };
    case SCHEDULE_GROUP_STORE_RESET:
      return {
        ...state,
        scheduleGroup: initialState.scheduleGroup,
      };
    case SCHEDULE_GROUP_DIALOG_RESET:
      return {
        ...state,
        scheduleGroup: initialState.scheduleGroup,
        savedNewOpeningTimes: false,
        changesMadeToScheduleGroup: false,
      };
    case TOGGLED_EDITING_SCHEDULE_GROUP_OPENING_TIMES:
      return {
        ...state,
        editingScheduleGroupOpeningTimes: !state.editingScheduleGroupOpeningTimes,
      };
    case UPDATED_SCHEDULE_GROUP_STORE:
      return {
        ...state,
        scheduleGroup: {
          ...state.scheduleGroup,
          ...action.value,
        },
      };
    case UPDATED_SCHEDULE_TIMES:
      const { scheduleGroup } = state;
      const { schedules } = scheduleGroup;

      const { day } = action.value;
      const updatedSchedules = [];

      schedules.forEach((schedule) => {
        if (schedule.day === day && action.value.start !== undefined) {
          schedule.start = action.value.start;
        }
        if (schedule.day === day && action.value.end !== undefined) {
          if (action.value.end === '00:00:00') {
            schedule.end = '23:59:59';
          } else {
            schedule.end = action.value.end;
          }
        }
        updatedSchedules.push(schedule);
      });

      return {
        ...state,
        scheduleGroup: {
          ...state.scheduleGroup,
          schedules: updatedSchedules,
        },
      };
    case UPDATED_SCHEDULES_STORE:
      const schedulesIndex = state.schedules.findIndex(
        ((obj) => obj.facilityIdsString === action.id),
      );
      state.schedules[schedulesIndex] = { ...state.schedules[schedulesIndex], ...action.value };
      return {
        ...state,
        schedules: [...state.schedules],
      };
    case UPDATED_SCHEDULE_STORE:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          ...action.value,
        },
      };
    case SCHEDULE_STORE_RESET: {
      return { ...state, schedule: initialState.schedule };
    }
    case SCHEDULE_GROUP_EDIT_ENABLED: {
      return {
        ...state,
        scheduleGroup: {
          ...state.scheduleGroup,
          ...action.scheduleGroup,
        },
      };
    }
    case TOGGLE_CHANGES_MADE_TO_SCHEDULES_GROUP: {
      return {
        ...state,
        changesMadeToScheduleGroup: !state.changesMadeToScheduleGroup,
      };
    }
    case TOGGLE_SAVED_NEW_OPENING_TIMES: {
      return {
        ...state,
        savedNewOpeningTimes: action.bool,
        changesMadeToScheduleGroup: true,
      };
    }
    case UPDATE_FULL_WEEK_SCHEDULE_STORE: {
      const newTimes = action.value;
      return {
        ...state,
        fullWeekSchedules: newTimes,
        changesMadeToScheduleGroup: true,
      };
    }
    default:
      return state;
  }
};
