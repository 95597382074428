import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Responsive from 'react-responsive';
import DesktopTable from './desktopTable';
import MobileTable from './mobileTable';
import ActionBar from '../../components/ActionBar';
import BookingCreationDialog from './components/CreationStepper/BookingCreationDialog';
import '../../styles/App.css';
import './styles/BookingStepperStyles';
import * as actions from '../../reducers/bookingsReducer';
import ToolTip from '../../components/Tooltip';

class BookingsPage extends Component {
  constructor(props) {
    super(props);
    const { searchOptions } = this.props;
    this.state = {
      selectedDates: {
        startDate: searchOptions.validFrom,
        endDate: searchOptions.validTo,
      },
    };
  }

  componentDidMount = () => {
    const {
      requestBookingsAndSubscriptions,
      resetSelectedRows,
    } = this.props;
    resetSelectedRows();
    const { selectedDates } = this.state;
    const options = {
      startDate: selectedDates.startDate.format('YYYY-MM-DD'),
      endDate: selectedDates.endDate.format('YYYY-MM-DD'),
      status: 'ACTIVE',
      facilities: [],
    };
    requestBookingsAndSubscriptions(options);
  }

  render() {
    const { requestBookingsAndSubscriptions, bookings } = this.props;
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '20px' }}>
          <ActionBar><BookingCreationDialog /></ActionBar>
          <ToolTip contentfulName="bookingsTableOverallToolTip" />
        </div>
        <Responsive minDeviceWidth={992}>
          {(isDesktop) => {
            if (isDesktop) {
              return (
                <DesktopTable
                  bookings={bookings}
                  searchFunction={requestBookingsAndSubscriptions}
                />
              );
            }
            return (
              <MobileTable />
            );
          }}
        </Responsive>
      </div>
    );
  }
}

BookingsPage.propTypes = {
  resetSelectedRows: PropTypes.func.isRequired,
  requestBookingsAndSubscriptions: PropTypes.func.isRequired,
  searchOptions: PropTypes.shape().isRequired,
  bookings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = (state) => ({
  searchOptions: state.bookings.searchOptions,
  bookings: state.bookings.bookingsAndSubscriptions,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetSelectedRows: () => actions.resetSelectedRows(),
  requestBookingsAndSubscriptions: (options) => actions.requestBookingsAndSubscriptions(options),

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BookingsPage);
