import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import '../../../styles/payments.css';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { CsvBuilder } from 'filefy';

const UserMembershipsTable = ({ memberships }) => {
  const columns = [{
    title: 'Membership Name',
    field: 'name',
  },
  {
    title: 'Membership Description',
    field: 'description',
  },
  ];
  return (
    <>
      <div className="table-section">
        <Paper
          style={{
            borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden', width: '100%',
          }}
          component={Paper}
        >
          <MaterialTable
            className="table"
            aria-label="simple table"
            data={memberships}
            title="Pitchbooking Memberships"
            icons={{
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              Export: () => <SaveAlt />,
              ResetSearch: () => <Clear />,
              SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
            }}
            options={{
              tableLayout: 'auto',
              exportButton: true,
              selection: true,
              emptyValue: 'No memberships exist for this user',
              emptyRowsWhenPaging: false,
              exportFileName: 'Pitchbooking Bookings',
              pageSize: 25,
              pageSizeOptions: [10, 25, 50, 100],
              showFirstLastPageButtons: false,
              showTitle: false,
              headerStyle: {
                backgroundColor: '#efefef',
                fontWeight: 'bold',
              },
            }}
            columns={
              columns
              // { title: 'Send Message', field: 'sendMessage' },
            }
            actions={[
              {
                position: 'toolbarOnSelect',
                label: { style: { display: 'block' } },
                icon: () => <Button color="primary" variant="contained">Export</Button>,
                tooltip: 'Export bookings to CSV',
                onClick: (e, rowData) => {
                  const fileName = 'Pitchbooking-User-Bookings';
                  const builder = new CsvBuilder(`${fileName}.csv`);
                  builder
                    .setColumns(columns.map((columnDef) => columnDef.title))
                    .addRows(rowData.map((row) => columns.map((columnDef) => row[columnDef.field])))
                    .exportFile();
                },
              },
            ]}
            localization={{
              toolbar: {
                exportTitle: 'Download',
              },

            }}
          />
        </Paper>
      </div>
    </>
  );
};

UserMembershipsTable.propTypes = {
  memberships: PropTypes.shape().isRequired,

};

export default (UserMembershipsTable);
