import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import EditFormDialog from '../../../forms/EditFormDialog';
import FormSelectorDialog from '../../../forms/FormSelectorDialog';
import { unlinkFormService } from '../../../../services/formsServices';
import { useToast } from '../../../../hooks';

const FacilityFormSelector = ({ selectedForm, facilityId, reloadResource }) => {
  const companyId = useSelector((state) => state.companies.companyInfo.id);
  const toast = useToast();

  const handleUnlinkForm = async () => {
    const body = {
      id: facilityId,
      type: 'FACILITY',
    };

    await unlinkFormService(companyId, body).then((res) => {
      if (res.status === 200) {
        toast.trigger({
          type: 'success',
          message: 'Form unlinked successfully!',
        });
        reloadResource();
      } else {
        toast.trigger({
          type: 'error',
          message: 'Error unlinking form please try again.',
        });
      }
    });
  };

  return (
    <div>
      <p>
        If you wish to make a form to collect information on each booking for this facility,
        please select an existing form or create a new one.
      </p>
      <a href="/forms">View Existing Forms</a>
      <div>
        {selectedForm ? (
          <div>
            <p>
              Selected Form:
              <b>
                {selectedForm.name}
              </b>
            </p>
          </div>
        ) : (
          <p>
            No form selected
          </p>
        )}
        <div style={{ display: 'flex', gap: 10 }}>
          <FormSelectorDialog
            formId={selectedForm?.id}
            resourceId={facilityId}
            type="FACILITY"
          />
          <EditFormDialog
            facilityId={facilityId}
            values={selectedForm?.formData}
            formId={selectedForm?.id}
            name={selectedForm?.name}
          />
          {selectedForm && (
            <Button
              variant="contained"
              style={{ backgroundColor: '#e03534', color: '#FFFFFF' }}
              onClick={() => handleUnlinkForm()}
            >
              Unlink Form
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

FacilityFormSelector.propTypes = {
  selectedForm: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    formData: PropTypes.shape(),
  }),
  facilityId: PropTypes.string.isRequired,
  reloadResource: PropTypes.func.isRequired,
};

FacilityFormSelector.defaultProps = {
  selectedForm: null,
};

export default FacilityFormSelector;
