import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import ScheduleTimePicker from './ScheduleTimePicker';

const ScheduleOpeningTimes = (props) => {
  const { schedules, onUpdate } = props;
  return (
    <>
      {schedules.map((schedule) => (
        <>
          <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px',
          }}
          >
            <div style={{ width: '8vh' }}>
              <div style={{ paddingRight: '10px', textAlign: 'right', fontSize: '22px' }}>{moment().weekday(schedule.day).format('ddd')}</div>
            </div>
            <ScheduleTimePicker onChange={onUpdate} schedule={schedule} />
          </div>
          <Divider />
        </>
      ))}
    </>
  );
};
ScheduleOpeningTimes.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default (ScheduleOpeningTimes);
