export const SUBSCRIPTIONS_REQUESTED = 'SUBSCRIPTIONS_REQUESTED';
export const SUBSCRIPTIONS_SUCCEEDED = 'SUBSCRIPTIONS_SUCCEEDED';
export const SUBSCRIPTIONS_FAILED = 'SUBSCRIPTIONS_FAILED';

export const SUBSCRIPTION_REQUESTED = 'SUBSCRIPTION_REQUEST';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const EXCLUSION_ADD_REQUESTED = 'EXCLUSION_ADD_REQUESTED';
export const EXCLUSION_DELETE_REQUESTED = 'EXCLUSION_DELETE_REQUESTED';
export const DELETE_EXCLUSION_ERROR = 'DELETE_EXCLUSION_ERROR';
export const DELETE_EXCLUSION_ERROR_RESET = 'DELETE_EXCLUSION_ERROR_RESET';

export const DELETE_SUBSCRIPTION_REQUESTED = 'DELETE_SUBSCRIPTION_REQUESTED';
export const DELETE_SUBSCRIPTION_SUCCEEDED = 'DELETE_SUBSCRIPTION_SUCCEEDED';
export const DELETE_SUBSCRIPTION_FAILED = 'DELETE_SUBSCRIPTION_FAILED';

export const EDIT_SUBSCRIPTION_REQUESTED = 'EDIT_SUBSCRIPTION_REQUESTED';
export const EDIT_SUBSCRIPTION_SUCCEEDED = 'EDIT_SUBSCRIPTION_SUCCEEDED';
export const EDIT_SUBSCRIPTION_FAILED = 'EDIT_SUBSCRIPTION_FAILED';
export const UPDATE_EDIT_SUBSCRIPTION_REQUESTED = 'UPDATE_EDIT_SUBSCRIPTION_REQUESTED';

export const PAUSE_SUBSCRIPTIONS_REQUESTED = 'PAUSE_SUBSCRIPTIONS_REQUESTED';
export const UPDATE_PAUSE_SUBSCRIPTIONS_STORE = 'UPDATE_PAUSE_SUBSCRIPTIONS_STORE';
export const PAUSE_SUBSCRIPTIONS_FAILURE = 'PAUSE_SUBSCRIPTIONS_FAILURE';
export const PAUSE_SUBSCRIPTIONS_SUCCESS = 'PAUSE_SUBSCRIPTIONS_SUCCESS';
export const RESET_PAUSED_SUBSCRIPTIONS_STORE = 'RESET_PAUSED_SUBSCRIPTIONS_STORE';

export const UPDATE_SUBSCRIPTIONS_REQUESTED = 'UPDATE_SUBSCRIPTIONS_REQUESTED';
export const UPDATE_SUBSCRIPTIONS_SUCCEEDED = 'UPDATE_SUBSCRIPTIONS_SUCCEEDED';
export const UPDATE_SUBSCRIPTIONS_FAILED = 'UPDATE_SUBSCRIPTIONS_FAILED';

export const UPDATE_SELECTED_SUBSCRIPTIONS = 'UPDATE_SELECTED_SUBSCRIPTIONS';

export const UPDATED_SUBSCRIPTION_STORE = 'UPDATED_SUBSCRIPTION_STORE';
export const UPDATED_SUBSCRIPTION_STORE_FACILITY_IDS = 'UPDATED_SUBSCRIPTION_STORE_FACILITY_IDS';
export const SUBSCRIPTION_STORE_RESET = 'SUBSCRIPTION_STORE_RESET';

export const SUBSCRIPTION_CREATION_CONFLICTS_ALERTED = 'SUBSCRIPTION_CREATION_CONFLICTS_ALERTED';
export const SUBSCRIPTION_CREATION_REQUESTED = 'SUBSCRIPTION_CREATION_REQUESTED';
export const SUBSCRIPTION_CREATION_SUCCEEDED = 'SUBSCRIPTION_CREATION_SUCCEEDED';
export const SUBSCRIPTION_CREATION_FAILED = 'SUBSCRIPTION_CREATION_FAILED';
export const SUBSCRIPTION_CREATION_CONFLICTS_CLEARED = 'SUBSCRIPTION_CREATION_CONFLICTS_CLEARED';
export const SUBSCRIPTION_POPUP_FORCE_CLOSED = 'SUBSCRIPTION_POPUP_FORCE_CLOSED';
export const TOGGLED_LOADING = 'TOGGLED_LOADING';
export const SUBSCRIPTIONS_CREATION_POPUP_TOGGLED = 'SUBSCRIPTIONS_CREATION_POPUP_TOGGLED';

export const SUBSCRIPTION_USER_DETAILS_UPDATED = 'SUBSCRIPTION_USER_DETAILS_UPDATED';
export const UPDATE_APPLY_EXCLUSIONS = 'UPDATE_APPLY_EXCLUSIONS';
export const RESET_APPLY_EXCLUSIONS = 'RESET_APPLY_EXCLUSIONS';

export const UPDATE_SUBSCRIPTION_STATUS = 'UPDATE_SUBSCRIPTION_STATUS';

export const ADD_NOTE_TO_SUBSCRIPTION_REQUESTED = 'ADD_NOTE_TO_SUBSCRIPTION_REQUESTED';

export const initialState = {
  subscriptions: [],
  subscriptionDialogOpen: false,
  applyExclusions: false,
  editedSubscription: {},
  editedSubscriptionSuccess: null,
  editedSubscriptionError: null,
  editedSubscriptionErrorData: null,
  subscription: {
    subscriptionName: '',
    amount: null,
    taxExempt: false,
    email: '',
    validFrom: null,
    facilityId: null,
    facilityIds: [],
    weekday: '1',
    startTime: null,
    endTime: null,
    startTimeFormatted: null,
    endTimeFormatted: null,
    type: 'STRIPE_SUBSCRIPTION',
    recurrence: 'WEEKLY',
    validToType: 'INDEFINITELY',
    numOfWeeks: null,
    validTo: null,
    forceClosePopup: false,
    pitchSplit: 1,
  },
  userDetailsForSubscription: {
    id: '',
    userid: '',
    firstName: '',
    lastName: '',
    teamName: '',
    phone: '',
    adminNotes: '',
    email: '',
    memberships: [],
  },
  subscriptionView: {
    facility: {},
    user: {},
    accessRestriction: {
      exclusions: [],
    },
  },
  failedReason: '',
  conflicts: null,
  isLoading: false,
  isLoadingConflicts: true,
  selectedSubscriptions: [],
  resumesAtOption: 'INDEFINITELY',
  resumesAt: null,
  pausedSubscriptionsError: null,
  pausedSubscriptions: [],
  deleteExclusionError: false,
  deleteExclusionErrorMessage: null,
  subscriptionStatus: 'CURRENT',
  exclusionsAppliedFacility: [],
};

export function getSubscriptions(dates) {
  return { type: SUBSCRIPTIONS_REQUESTED, dates };
}

export function addNoteToSubscription(date, note, subscriptionId) {
  return {
    type: ADD_NOTE_TO_SUBSCRIPTION_REQUESTED, subscriptionId, note, date,
  };
}

export function updateSubscriptionStatus(status) {
  return { type: UPDATE_SUBSCRIPTION_STATUS, status };
}
export function getSubscription(id) {
  return { type: SUBSCRIPTION_REQUESTED, id };
}
export function updateApplyExclusions(facilityIds) {
  return { type: UPDATE_APPLY_EXCLUSIONS, facilityIds };
}
export function resetApplyExclusions() {
  return { type: RESET_APPLY_EXCLUSIONS };
}
export function addExclusions(id) {
  return { type: EXCLUSION_ADD_REQUESTED, id };
}
export function deleteExclusion(id) {
  return { type: EXCLUSION_DELETE_REQUESTED, id };
}
export function deleteSubscription(subscriptionId) {
  return { type: DELETE_SUBSCRIPTION_REQUESTED, subscriptionId };
}
export function deleteExclusionFailure(error) {
  return { type: DELETE_EXCLUSION_ERROR, error };
}
export function deleteExclusionErrorReset() {
  return { type: DELETE_EXCLUSION_ERROR_RESET };
}
export function pauseSubscriptions(subscriptionIds, resumesAt) {
  return {
    type: PAUSE_SUBSCRIPTIONS_REQUESTED, subscriptionIds, resumesAt,
  };
}
export function succeedSubscriptionPause(subscriptions) {
  return {
    type: PAUSE_SUBSCRIPTIONS_SUCCESS, subscriptions,
  };
}
export function failSubscriptionPause(subscriptions, error) {
  return {
    type: PAUSE_SUBSCRIPTIONS_FAILURE, subscriptions, error,
  };
}
export function resetPauseSubscriptionStore() {
  return { type: RESET_PAUSED_SUBSCRIPTIONS_STORE };
}
export function updatePauseSubscriptionsStore(keyWithValue) {
  return { type: UPDATE_PAUSE_SUBSCRIPTIONS_STORE, keyWithValue };
}
export function updateSelectedSubscriptions(rows) {
  return { type: UPDATE_SELECTED_SUBSCRIPTIONS, rows };
}
export function updateSubscriptions(subscriptions) {
  return { type: UPDATE_SUBSCRIPTIONS_REQUESTED, subscriptions };
}
export function updateSubscription(subscription) {
  return { type: UPDATE_SUBSCRIPTION, subscription };
}
export function updateSubscriptionsStore(subscriptions) {
  return { type: UPDATE_SUBSCRIPTIONS_REQUESTED, subscriptions };
}
export function updateSubscriptionStoreFacilityIds(facilityIds) {
  return { type: UPDATED_SUBSCRIPTION_STORE_FACILITY_IDS, facilityIds };
}
export function updateSubscriptionStore(subscription) {
  return { type: UPDATED_SUBSCRIPTION_STORE, subscription };
}
export function resetSubscriptionStore() {
  return { type: SUBSCRIPTION_STORE_RESET };
}
export function succeedSubscriptionCreation() {
  return { type: SUBSCRIPTION_CREATION_SUCCEEDED };
}
export function failSubscriptionCreation() {
  return { type: SUBSCRIPTION_CREATION_FAILED };
}
export function requestSubscriptionCreation(checkForConflicts) {
  return { type: SUBSCRIPTION_CREATION_REQUESTED, checkForConflicts };
}
export function alertSubscriptionCreationConflicts(conflicts) {
  return { type: SUBSCRIPTION_CREATION_CONFLICTS_ALERTED, conflicts };
}
export function clearSubscriptionCreationConflicts() {
  return { type: SUBSCRIPTION_CREATION_CONFLICTS_CLEARED };
}
export function resetForceCloseSubscriptionPopup() {
  return { type: SUBSCRIPTION_POPUP_FORCE_CLOSED };
}
export function toggleIsLoading(bool) {
  return {
    type: TOGGLED_LOADING,
    bool,
  };
}
export function toggleSubscriptionCreationPopup() {
  return { type: SUBSCRIPTIONS_CREATION_POPUP_TOGGLED };
}

export function updateSubscriptionUserDetails(updatedUserDetailsForSubscription) {
  return {
    type: SUBSCRIPTION_USER_DETAILS_UPDATED,
    updatedUserDetailsForSubscription,
  };
}

export function editSubscription(subscriptionId) {
  return {
    type: EDIT_SUBSCRIPTION_REQUESTED,
    subscriptionId,
  };
}

export function editSubscriptionSuccess() {
  return {
    type: EDIT_SUBSCRIPTION_SUCCEEDED,
  };
}

export function editSubscriptionFailure(error, data) {
  return {
    type: EDIT_SUBSCRIPTION_FAILED,
    error,
    data,
  };
}

export function updateEditedSubscription(keyValue) {
  return {
    type: UPDATE_EDIT_SUBSCRIPTION_REQUESTED,
    keyValue,
  };
}

export default (state = initialState, action) => {
  let setPaymentType = {};
  switch (action.type) {
    case PAUSE_SUBSCRIPTIONS_FAILURE:
      return {
        ...state, pausedSubscriptions: action.subscriptions, pausedSubscriptionsError: true,
      };
    case RESET_PAUSED_SUBSCRIPTIONS_STORE:
      return {
        ...state,
        pausedSubscriptions: initialState.pausedSubscriptions,
        pausedSubscriptionsError: initialState.pausedSubscriptionsError,
      };
    case PAUSE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state, pausedSubscriptions: action.subscriptions, pausedSubscriptionsError: false,
      };
    case SUBSCRIPTIONS_REQUESTED:
      return { ...state, isLoading: true };
    case SUBSCRIPTION_CREATION_REQUESTED:
      return { ...state, isLoadingConflicts: true };
    case SUBSCRIPTION_REQUESTED:
      return { ...state, isLoading: true };
    case UPDATE_APPLY_EXCLUSIONS:
      return {
        ...state,
        applyExclusions: !state.applyExclusions,
        exclusionsAppliedFacility: action.facilityIds !== null && action.facilityIds.length > 0
          ? [...state.exclusionsAppliedFacility, ...action.facilityIds]
          : [],
        ...(state.applyExclusions ? {
          subscription: { ...state.subscription, facilityIds: [] },
        } : {}),
      };
    case RESET_APPLY_EXCLUSIONS:
      return { ...state, applyExclusions: initialState.applyExclusions };
    case UPDATE_SUBSCRIPTIONS_REQUESTED:
      return { ...state, subscriptions: action.subscriptions, isLoading: false };
    case DELETE_EXCLUSION_ERROR:
      return { ...state, deleteExclusionError: true, deleteExclusionErrorMessage: action.error };
    case UPDATE_SUBSCRIPTION_STATUS:
      return { ...state, subscriptionStatus: action.status };
    case DELETE_EXCLUSION_ERROR_RESET:
      return {
        ...state,
        deleteExclusionError: false,
        deleteExclusionErrorMessage: initialState.deleteExclusionErrorMessage,
      };
    case UPDATE_SUBSCRIPTION:
      return { ...state, subscriptionView: action.subscription, isLoading: false };
    case SUBSCRIPTION_USER_DETAILS_UPDATED:
      return {
        ...state,
        userDetailsForSubscription:
        {
          ...state.userDetailsForSubscription,
          ...action.updatedUserDetailsForSubscription,
        },
      };
    case SUBSCRIPTION_CREATION_CONFLICTS_ALERTED:
      return { ...state, conflicts: action.conflicts, isLoadingConflicts: false };
    case SUBSCRIPTION_POPUP_FORCE_CLOSED:
      return { ...state, forceClosePopup: false };
    case SUBSCRIPTION_CREATION_CONFLICTS_CLEARED:
      return { ...state, conflicts: null };
    case TOGGLED_LOADING:
      return { ...state, isLoading: action.bool };
    case SUBSCRIPTIONS_CREATION_POPUP_TOGGLED:
      return {
        ...state,
        subscriptionDialogOpen: !state.subscriptionDialogOpen,
        subscription: { ...initialState.subscription },
        userDetailsForSubscription: { ...initialState.userDetailsForSubscription },
        applyExclusions: initialState.applyExclusions,
        exclusionsAppliedFacility: initialState.exclusionsAppliedFacility,
      };
    case SUBSCRIPTION_CREATION_SUCCEEDED:
      return {
        ...state,
        conflicts: null,
        forceClosePopup: true,
        subscription: { ...initialState.subscription },
        userDetailsForSubscription: { ...initialState.subscription },
      };
    case UPDATED_SUBSCRIPTION_STORE:
      if (action.subscription.amount && parseFloat(action.subscription.amount) === 0) { setPaymentType = { type: 'PAYP' }; }
      return {
        ...state,
        subscription:
        {
          ...state.subscription,
          ...action.subscription,
          ...setPaymentType,
        },
      };
    case UPDATE_EDIT_SUBSCRIPTION_REQUESTED: {
      return {
        ...state,
        editedSubscription: {
          ...state.editedSubscription,
          ...action.keyValue,
        },
      };
    }
    case EDIT_SUBSCRIPTION_FAILED:
      return {
        ...state,
        editedSubscriptionSuccess: false,
        editedSubscriptionError: action.error,
        editedSubscriptionErrorData: action.data,
      };
    case EDIT_SUBSCRIPTION_SUCCEEDED:
      return {
        ...state,
        editedSubscriptionSuccess: true,
        editedSubscriptionError: null,
      };
    case UPDATE_PAUSE_SUBSCRIPTIONS_STORE:
      return {
        ...state,
        ...action.keyWithValue,
      };
    case UPDATE_SELECTED_SUBSCRIPTIONS:
      return {
        ...state,
        selectedSubscriptions: [...action.rows],
      };
    case UPDATED_SUBSCRIPTION_STORE_FACILITY_IDS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          facilityIds: action.facilityIds,

        },
      };
    case SUBSCRIPTION_STORE_RESET:
      return {
        ...state,
        subscription: { ...initialState.subscription },
        editedSubscription: { ...initialState.editedSubscription },
        editedSubscriptionSuccess: initialState.editedSubscriptionSuccess,
      };
    default:
      return state;
  }
};
