import React from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import {
  List, ListItem, ListItemText, Checkbox,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PaymentStatusRadio from '../../../../components/PaymentStatusRadio';
import * as reservationsActions from '../../../../reducers/reservationsReducer';

let alertMessage = 'If the booking is being paid for at the point of creation, then select one of the \'Paid\' options above. Otherwise set the payment status to \'Unpaid\'; a payment can later be recorded, or the booking can be added to an invoice.';

const BookingStepperPaymentInfoPicker = ({
  paymentStatus,
  updateReservationPaymentStatus,
  requestManagerReservationCreation,
  updateReservationUserDetails,
  adminNotes,
  requestingReservationsCreation,
  sendNotesToBooker,
  allocations,
}) => {
  const isPowerleague = useSelector((state) => state.companies.companyInfo.isPowerleague);
  if (allocations.length === 0) {
    alertMessage = 'You have not selected any slots for this booking. Please return to facility selection and try again.';
  }
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
      }}
      >
        <div>
          <PaymentStatusRadio
            setPaymentStatus={
          (selectedPaymentStatus) => updateReservationPaymentStatus(selectedPaymentStatus)
        }
            paymentStatus={paymentStatus}
            isPowerleague={isPowerleague}
          />
        </div>
        <div style={{ marginLeft: '20px' }}>
          <FormLabel component="legend">Booking Notes</FormLabel>
          <List>
            <ListItem
              dense
              key={1}
              onClick={(event) => updateReservationUserDetails(
                { sendNotesToBooker: event.target.checked },
              )}
            >
              <Checkbox
                checked={sendNotesToBooker}
                tabIndex="-1"
                disableRipple
              />
              <ListItemText primary="Include these notes in the booking confirmation email?" />
            </ListItem>
          </List>
          <TextField
            id="name"
            label="Notes"
            value={adminNotes}
            onBlur={(event) => updateReservationUserDetails({ adminNotes: event.target.value })}
            multiline
            rows="5"
            margin="normal"
          />
        </div>
      </div>
      <div>
        <Alert
          id="alert"
          severity="info"
          style={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
        <br />
        <Button
          id="manager-bookings-confirm-booking"
          style={{ float: 'right' }}
          variant="contained"
          color="primary"
          disabled={requestingReservationsCreation || allocations.length === 0}
          onClick={() => {
            requestManagerReservationCreation();
          }}
        >
          Confirm Booking
        </Button>
      </div>
    </div>
  );
};

BookingStepperPaymentInfoPicker.propTypes = {
  paymentStatus: PropTypes.string.isRequired,
  adminNotes: PropTypes.string.isRequired,
  updateReservationPaymentStatus: PropTypes.func.isRequired,
  requestManagerReservationCreation: PropTypes.func.isRequired,
  updateReservationUserDetails: PropTypes.func.isRequired,
  requestingReservationsCreation: PropTypes.bool.isRequired,
  sendNotesToBooker: PropTypes.bool.isRequired,
  allocations: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  paymentStatus: state.reservation.selectedPaymentStatus,
  requestingReservationsCreation: state.reservation.requestingReservationsCreation,
  sendNotesToBooker: state.reservation.userDetailsForReservation.sendNotesToBooker,
  allocations: state.basket.confirmedAllocations,
});

const mapDispatchToProps = (dispatch) => ({
  updateReservationPaymentStatus: (selectedPaymentStatus) => dispatch(
    reservationsActions.updateReservationPaymentStatus(selectedPaymentStatus),
  ),
  requestManagerReservationCreation: () => dispatch(
    reservationsActions.requestManagerReservationCreation(),
  ),
  updateReservationUserDetails: (keyWithValue) => dispatch(
    reservationsActions.updateReservationUserDetails(keyWithValue),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingStepperPaymentInfoPicker);
