import React from 'react';
import 'rc-time-picker/assets/index.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import * as accessRestrictionsActions from '../../../../reducers/accessRestrictionsReducer';
import MenuHeading from '../../../../components/MenuHeading';
import FacilityAndSubFacilityPicker from '../../../facilities/components/FacilityAndSubFacilityPicker';

const AccessRestrictionsDialogFacilityPicker = ({
  companyFacilities,
  facilities,
  updateAccessRestrictionCreation,
  editingAccessRestriction,
}) => (
  <>
    <MenuHeading title="Select facility" />
    {facilities.map((facility) => (
      <React.Fragment key={facility.name}>
        <span>{facility.name}</span>
        <br />
      </React.Fragment>
    ))}
    <ConditionallyVisible condition={!!facilities}>
      <FacilityAndSubFacilityPicker
        facilities={companyFacilities}
        listName="Facilities"
        onUpdate={(updatedFacilities) => updateAccessRestrictionCreation(
          { facilities: updatedFacilities },
        )}
        checkedFacilities={facilities}
        editingAccessRestriction={editingAccessRestriction}
        flagFacilitiesChanged={() => updateAccessRestrictionCreation(
          { changedFacilities: true },
        )}
      />

    </ConditionallyVisible>
  </>
);

AccessRestrictionsDialogFacilityPicker.propTypes = {
  companyFacilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  facilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  editingAccessRestriction: PropTypes.bool.isRequired,
  updateAccessRestrictionCreation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  companyFacilities: state.facilities.companyFacilities,
  facilities: state.accessRestrictions.newAccessRestriction.facilities,
  editingAccessRestriction: state.accessRestrictions.editingAccessRestriction,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccessRestrictionCreation: (keyWithValue) => dispatch(
    accessRestrictionsActions.updateAccessRestrictionCreation(keyWithValue),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessRestrictionsDialogFacilityPicker);
