import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import ResetPasswordForm from '@pitchbooking-dev/pb-shared/lib/components/Forms/ResetPasswordForm';
import * as actions from '../../../reducers/usersReducer';

const ResetPasswordDialog = (props) => {
  const {
    newPasswordDialogOpen, token, closeNewPasswordDialog,
  } = props;

  return (
    <Dialog
      className="reset-password-dialog"
      open={newPasswordDialogOpen}
      onClose={() => closeNewPasswordDialog(false)}
    >
      <DialogTitle />
      <DialogContent>
        <ResetPasswordForm
          token={token}
        />
      </DialogContent>
    </Dialog>
  );
};

ResetPasswordDialog.propTypes = {
  newPasswordDialogOpen: PropTypes.bool.isRequired,
  token: PropTypes.string,
  closeNewPasswordDialog: PropTypes.func.isRequired,
};

ResetPasswordDialog.defaultProps = {
  token: '',
};

const mapStateToProps = (state) => ({
  newPasswordDialogOpen: state.users.newPasswordDialogOpen,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeNewPasswordDialog: (bool) => (actions.closeNewPasswordDialog(bool)),
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordDialog);
