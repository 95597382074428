import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as leagueActions from '../../../reducers/leaguesReducer';

import '../../../styles/payments.css';
import Back from '../../../components/Back';
import ActionBar from '../../../components/ActionBar';
import MediaConfiguration from '../../../components/MediaConfiguration';

const LeagueMedia = ({ match }) => {
  const { id } = match.params;
  const {
    league,
  } = useSelector((state) => ({
    league: state.leagues.league,
  }));
  const dispatch = useDispatch();
  return (
    <div style={{ backgroundColor: '#fafbff' }}>
      <ActionBar>
        <Back />
      </ActionBar>

      <div style={{
        height: '95.5vh',
        marginLeft: '20px',
        marginRight: '40px',
      }}
      >
        <MediaConfiguration type="LEAGUE" resourceId={id} initialImage={league.imageUrl} reloadResource={() => dispatch(leagueActions.getLeague(id))} />
      </div>
    </div>
  );
};

LeagueMedia.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default LeagueMedia;
