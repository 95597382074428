import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import '../../../styles/payments.css';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import TableContainer from '@material-ui/core/TableContainer';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import AdjustPointsDialog from './AdjustPointsDialog';
import DeleteTeamDialog from './DeleteTeamDialog';
import ReplaceTeamDialog from './ReplaceTeamDialog';

const TeamTable = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [attendeeTableState, setAttendeeTableState] = useState(
    {
      page: 0,
      rowsPerPage: 25,
    },
  );

  const { teams, leagueId, companyId } = props;

  const confirmedTeams = useMemo(() => teams?.filter((c) => c.league_team?.status === 'CONFIRMED'), [teams]);
  const waitingTeams = useMemo(() => teams?.filter((c) => c.league_team?.status === 'WAITING'), [teams]);

  return (
    <>
      <div className="table-section">
        <TableContainer
          style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden' }}
          component={Paper}
        >
          <MaterialTable
            data={confirmedTeams}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            title="Attendees"
            icons={{
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              Export: () => <SaveAlt />,
              ResetSearch: () => <Clear />,
              SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
            }}
            columns={[
              {
                title: 'Name',
                render: (row) => (
                  <>
                    {row.name}
                    <div style={{ color: '#a2a2a2', fontSize: '.7rem' }}>
                      {row?.team_members[0]?.user?.email ? `Captain: ${row?.team_members[0]?.user?.firstName} ${row?.team_members[0]?.user?.lastName} (${row?.team_members[0]?.user?.email})` : ''}
                    </div>
                  </>
                ),

              },
              {
                sorting: false,
                render: (row) => (
                  <div style={{ display: 'flex', gap: '1rem' }}>
                    <AdjustPointsDialog
                      leagueId={leagueId}
                      teamId={row.id}
                      teamName={row.name}
                    />
                    <ConditionallyVisible condition={waitingTeams.length > 0}>
                      <ReplaceTeamDialog
                        leagueId={leagueId}
                        companyId={companyId}
                        teamId={row.id}
                        teamName={row.name}
                      />
                    </ConditionallyVisible>
                    <DeleteTeamDialog
                      leagueId={leagueId}
                      teamId={row.id}
                      teamName={row.name}
                    />
                  </div>
                ),
              },
            ]}
            options={{
              search: true,
              selection: false,
              emptyRowsWhenPaging: false,
              tableLayout: 'auto',
              exportButton: false,
              exportFileName: 'Pitchbooking Attendees',
              pageSize: 50,
              pageSizeOptions: [10, 25, 50, 100],
              showFirstLastPageButtons: false,
              showTitle: false,
              headerStyle: {
                backgroundColor: '#efefef',
                fontWeight: 'bold',
              },
            }}
            localization={{
              toolbar: {
                exportTitle: 'Download',
              },
            }}
          />
        </TableContainer>
      </div>
    </>
  );
};

TeamTable.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  leagueId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
};

export default (TeamTable);
