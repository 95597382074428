import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as currentUserActions from '@pitchbooking-dev/pb-shared/lib/reducers/currentUserReducer';
import Typography from '@material-ui/core/Typography';
import * as actions from '../../reducers/usersReducer';
import AccessDeniedDialog from './components/accessDeniedDialog';
import ForgottenPasswordDialog from './components/forgottenPasswordDialog';
import PasswordChangedSuccessfullyDialog from './components/passwordChangedSuccessfullyDialog';
import PasswordEmailSuccessDialog from './components/passwordEmailSuccessDialog';
import ResetPasswordDialog from './components/resetPasswordDialog';
import ReferralsBanner from '../../components/ReferralsBanner';
import LoginForm from './components/LoginForm';

import loginGif from '../../images/Manager-Login-Animation2.gif';
import PBManagerLogo from '../../images/pitchbooking-manager-logo-blue.png';

import '../../styles/login.css';

const mapStateToProps = (state) => ({
  isLoading: state.users.isLoading,
  loginError: state.currentUser.loginError,
  userError: state.users.userError,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  loginUser: (email, password) => (currentUserActions.loginUser(email, password)),
  openForgottenPasswordDialog: (bool) => (actions.openForgottenPasswordDialog(bool)),
  openNewPasswordDialog: (bool) => (actions.openNewPasswordDialog(bool)),
}, dispatch);

const LoginPage = (props) => {
  const {
    isLoading,
    loginError,
    loginUser,
    match,
    openNewPasswordDialog,
    openForgottenPasswordDialog,
    userError,
  } = props;
  const { params } = match;
  const { token } = params;

  useEffect(() => {
    const { path } = match;
    if (path.includes('reset-password')) {
      openNewPasswordDialog(true);
    }

    if (navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1) {
      alert('You are using Internet Explorer as your web browser, for performance and security reasons, Pitchbooking highly recommend Google Chrome or secondly, Firefox or Safari browsers');
    }
  }, []);

  const handleLoginSubmit = (values) => {
    const { email, password } = values;
    loginUser(email, password);
  };

  return (
    <div className="login-root">
      <div className="login-left-side">
        <a href="https://pitchbooking.com/solution">
          <img className="login-pb-manager-logo" alt="pb-manager-Logo" src={PBManagerLogo} />
        </a>
        <Typography variant="h5">
          Hi! You’ve arrived at Pitchbooking Manager
        </Typography>
        <br />
        <LoginForm
          onHandleSubmit={handleLoginSubmit}
          userError={userError}
          loginError={loginError}
          isLoading={isLoading}
        />
        <AccessDeniedDialog />
        <ForgottenPasswordDialog />
        <PasswordChangedSuccessfullyDialog />
        <PasswordEmailSuccessDialog />
        <ResetPasswordDialog token={token} />
        <div className="login-forgot-password">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" style={{ color: '#546172' }} onClick={() => openForgottenPasswordDialog(true)}>
            <Typography>
              Forgot your password? Click here.
            </Typography>
          </a>
          <br />
        </div>
      </div>
      <div className="login-right-side">
        <img className="login-ipad" alt="loginIpad" src={loginGif} />
        <br />
        <ReferralsBanner />
        <a href="https://pitchbooking.com/solution" target="_blank" rel="noopener noreferrer">
          <Typography className="login-solution" variant="h6">Read about our solution here &#8618;</Typography>
        </a>
      </div>
    </div>
  );
};

LoginPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  loginError: PropTypes.bool.isRequired,
  loginUser: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  openForgottenPasswordDialog: PropTypes.func.isRequired,
  openNewPasswordDialog: PropTypes.func.isRequired,
  userError: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
