import React from 'react';
import TimetableInitialiser from '@pitchbooking-dev/pb-shared/lib/components/timetable/TimetableInitialiser';
import TimetableSelectedTimeslots from '@pitchbooking-dev/pb-shared/lib/components/timetable/TimetableSelectedTimeslots';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { requestFacilityRetrieval, updateSelectedSubFacility } from '../../reducers/facilitiesReducer';
import CompanyFacilityPicker from '../CompanyFacilityPicker';
import { combineTimeslots } from '../../utils';
import { toggleEditBookingDialog } from '../../reducers/bookingsReducer';

const ChangeAllocation = ({ updateFacility }) => {
  const dispatch = useDispatch();
  const selectedFacility = useSelector((state) => state.facilities.selectedFacility);
  const selectedSubfacility = useSelector((state) => state.facilities.selectedSubFacility);
  const slots = useSelector((state) => state.timetable.selectedTimeslots);

  React.useEffect(() => {
    dispatch(toggleEditBookingDialog());

    return () => {
      dispatch(toggleEditBookingDialog());
    };
  }, []);

  return (
    <>
      <CompanyFacilityPicker
        updateSelectedFacility={(e) => dispatch(requestFacilityRetrieval(e.id))}
        updateSelectedSubFacility={(e) => dispatch(updateSelectedSubFacility(e))}
        siteId={selectedFacility?.facilityTree?.siteId}
      />
      <>
        <div className="reservation-creation-timetable">
          <div className="reservation-creation-timetable-picker">
            {selectedFacility && (
            <TimetableInitialiser
              facilityInformation={{
                facilityId: selectedFacility.id,
                subFacilityId: selectedSubfacility?.id,
                companyId: selectedFacility.companyId,
              }}
              manager
              blockBooking
            />
            )}

          </div>
          <div className="reservation-creation-timetable-basket">
            <TimetableSelectedTimeslots combineSlots />
          </div>
        </div>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            disabled={slots.length <= 0 || !selectedFacility}
            onClick={() => {
              let timeslots = slots;

              if (selectedFacility?.combineSlots) {
                timeslots = combineTimeslots(timeslots);
              }

              updateFacility(selectedFacility, timeslots);
            }}
          >
            Change Facility
          </Button>
        </div>
      </>
    </>
  );
};

ChangeAllocation.propTypes = {
  updateFacility: PropTypes.func.isRequired,
};

export default ChangeAllocation;
