import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import moment from 'moment';
import * as dashboardActions from '../../reducers/dashboardReducer';
import BookingsAndSubsCard from './components/BookingsAndSubs';
import LoadingSkeleton from '../../components/LoadingComponents/LoadingSkeleton';
import ToolTip from '../../components/Tooltip';
import '../../styles/dashboard.css';
// eslint-disable-next-line react/prefer-stateless-function
class Dashboard extends React.Component {
  componentDidMount() {
    const {
      requestDashboardBookings,
      dashboardBookings,
    } = this.props;

    if (!dashboardBookings.lastUpdated) {
      requestDashboardBookings();
    }
  }

  refreshDashboardBookings = () => {
    const {
      requestDashboardBookings,
    } = this.props;
    requestDashboardBookings({ refresh: true });
  };

  render() {
    const { dashboardBookings, dashboardLoading } = this.props;
    const {
      upcomingBookings, unpaidBookings, totalDayReservations,
      totalUnpaidSubscriptions, totalCancellationsToday,
      liveBookings, totalSubscriptions, lastUpdated,
    } = dashboardBookings;

    return (
      <div className="dashboard-main">
        <AlternativelyVisible condition={dashboardLoading}>
          <LoadingSkeleton />
          <>
            <div className="dashboard-last-updated">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.refreshDashboardBookings}
              >
                <RefreshIcon />
                Refresh Data
              </Button>
              <div style={{ marginTop: '0.25rem' }}>
                <strong>{`Last Updated - ${moment(lastUpdated).format('Do MMM, HH:mm:ss')}`}</strong>
              </div>
            </div>
            <div className="dashboard-blocks">
              <div className="dashboard-block-title">
                <div className="dashboard-bookings-today">
                  <div className="dashboard-bookings-today-number">
                    {totalDayReservations}
                  </div>
                  Total Bookings Today
                </div>
                <div className="dashboard-subscriptions-today">
                  <div className="dashboard-subscriptions-today-number">
                    {totalSubscriptions}
                  </div>
                  Subscriptions Today
                </div>
                <div className="dashboard-subscriptions-unpaid">
                  <div className="dashboard-subscriptions-unpaid-number">
                    {totalUnpaidSubscriptions}
                  </div>
                  Unpaid Subscriptions
                </div>
                <div className="dashboard-cancellations-today">
                  <div className="dashboard-cancellations-today-number">
                    {totalCancellationsToday}
                  </div>
                  Cancellations Today
                </div>
              </div>
              <div className="dashboard-tooltip-wrapper">
                <ToolTip contentfulName="todayDashboardTabToolTip" />
              </div>
            </div>
            <div className="dashboard-blocks">
              <div className="dashboard-blocks-ind" style={{}}>
                <div className="dashboard-title">

                  Current (
                  {liveBookings.length}
                  )
                  <div className="live green" />
                </div>
                <div className="dashboard-block-content">
                  {liveBookings.length > 0 ? liveBookings.map((liveBooking) => (
                    <BookingsAndSubsCard booking={liveBooking} />
                  )) : <div style={{ }}>No facilities currently in use</div>}
                </div>
              </div>
              <div className="dashboard-blocks-ind">
                <div className="dashboard-title">
                  Upcoming (
                  {upcomingBookings.length}
                  )
                </div>
                <div className="dashboard-block-content">
                  {upcomingBookings.length > 0 ? upcomingBookings.map((upcomingBooking) => (
                    <BookingsAndSubsCard booking={upcomingBooking} />
                  )) : <div style={{ }}>No upcoming bookings or subscriptions</div>}
                </div>
              </div>
              <div className="dashboard-blocks-ind">
                <div className="dashboard-title">
                  Unpaid (
                  {unpaidBookings.length}
                  )
                </div>
                <div className="dashboard-block-content">
                  {unpaidBookings.length > 0 ? unpaidBookings.map((unpaidBooking) => (
                    <BookingsAndSubsCard booking={unpaidBooking} />
                  )) : <div style={{ paddingLeft: '20px' }}>No unpaid bookings or subscriptions</div>}
                </div>
              </div>
            </div>
          </>
        </AlternativelyVisible>
      </div>
    );
  }
}

Dashboard.propTypes = {
  dashboardBookings: PropTypes.shape().isRequired,
  upcomingBookings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  unpaidBookings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  requestDashboardBookings: PropTypes.func.isRequired,
  dashboardLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    dashboard,
  } = state;

  const { dashboardBookings, dashboardLoading } = dashboard;

  return {
    dashboardBookings,
    dashboardLoading,
  };
}

const mapDispatchToProps = (dispatch) => ({
  requestDashboardBookings: (query) => dispatch(
    dashboardActions.requestDashboardBookings(query),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
