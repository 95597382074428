import React, { useState } from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as eventActions from '../../../reducers/eventsReducer';
import { useMobile } from '../../../hooks';

function DeleteEventDialog({ eventId }) {
  const [open, setOpen] = useState(false);
  const isMobile = useMobile();
  const dispatch = useDispatch();

  return (
    <>
      {/* Table Button */}
      <Button color="secondary" variant="contained" onClick={() => setOpen(true)}>
        <DeleteIcon />
      </Button>

      <Dialog
        open={open}
        fullScreen={isMobile}
        maxWidth="sm"
      >
        {/* Close Icon */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <DialogTitle>
            Delete Event?
          </DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => setOpen(!open)} />
          </DialogActions>
        </div>

        {/* Content */}
        <DialogContent>
          <Typography variant="h6">
            Are you sure you want to delete
            this event? This action cannot be undone.
          </Typography>
        </DialogContent>

        {/* Action Buttons */}
        <DialogActions>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1rem',
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpen(!open)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                dispatch(eventActions.deleteEvent(eventId));
                setOpen(false);
              }}
            >
              Delete Event
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

DeleteEventDialog.propTypes = {
  eventId: PropTypes.string.isRequired,
};

export default DeleteEventDialog;
