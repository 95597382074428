import React from 'react';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';

const BillingLoadingSection = ({ requestingBillingSetup }) => (
  <ConditionallyVisible condition={requestingBillingSetup}>
    <div className="billingLoadingOverlay">
      <div>
        <Typography style={{ fontSize: '3.5vh', paddingBottom: 10 }}>
          Contacting Stripe
        </Typography>
        <CircularProgress size={110} value={100} variant="indeterminate" />
      </div>
    </div>
  </ConditionallyVisible>
);

BillingLoadingSection.propTypes = {
  requestingBillingSetup: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  requestingBillingSetup: state.companies.billingCreationStatus.requesting,
});

export default connect(
  mapStateToProps,
  null,
)(BillingLoadingSection);
