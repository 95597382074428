import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import '../../styles/payments.css';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import TableContainer from '@material-ui/core/TableContainer';
import MaterialTable from 'material-table';
import { Checkbox } from '@material-ui/core';
import { useSelector } from 'react-redux';
import WeekScheduleTable from './WeekScheduleTable';
import ScheduleGroupEditDialog from './components/ScheduleGroupEditDialog';

const SchedulesTable = ({
  schedules,
  hideEdit,
  toggleEditing,
  enableSchedulesGroupEdit,
  addSelectedScheduleGroupToNewFacility,
}) => {
  const company = useSelector((state) => state.companies.companyInfo);
  const facilityTag = (row) => (
    <div style={{
      display: 'flex', flex: 'wrap', width: '100%', margin: '4px', alignItems: 'center',
    }}
    >
      <div style={{
        width: '250px', backgroundColor: '#CFE2F3', padding: '8px', border: '1px solid #333', borderRadius: '7px',
      }}
      >
        {row.name}
      </div>
      {!hideEdit && (
      <ScheduleGroupEditDialog
        facility={row}
        schedules={schedules.filter((s) => !s.facilities.some((f) => f.id === row.id))}
        selectedSchedule={schedules.find((s) => s.facilities.some((f) => f.id === row.id))}
      />
      )}
    </div>
  );

  const handleEdit = (schedulesGroup) => {
    toggleEditing(true);
    enableSchedulesGroupEdit(schedulesGroup);
  };

  const handleSelection = (row) => {
    addSelectedScheduleGroupToNewFacility(row.schedules);
  };

  return (
    <div className="table-section">
      <TableContainer
        style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden' }}
        component={Paper}
      >
        <MaterialTable
          data={schedules}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          title={`${company.name} Opening Times`}
          icons={{
            NextPage: () => <ChevronRight />,
            PreviousPage: () => <ChevronLeft />,
            Search: () => <Search />,
            Export: () => <SaveAlt />,
            ResetSearch: () => <Clear />,
            SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
          }}
          columns={[
            {
              title: 'Selection',
              hidden: !hideEdit,
              render: (row) => (
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <Checkbox onChange={(event) => handleSelection(row, event.target.checked)} />
                </div>
              ),
            },
            {
              title: 'Facilities',
              render: (row) => (
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  {row.facilities.filter((f) => f.status === 'ACTIVE').map((f) => facilityTag(f))}
                </div>
              ),
            },
            {
              title: 'Opening Times',
              render: (row) => (<WeekScheduleTable schedules={row.schedules} />),
            },
            {
              title: 'Edit',
              field: 'edit',
              filtering: false,
              hidden: hideEdit,
              render: (row) => (
                <div>
                  <Button id="manager-schedules-edit" color="secondary" aria-label="edit" onClick={() => handleEdit(row)}>
                    <EditIcon />
                  </Button>
                </div>
              ),
            },
          ]}
          options={{
            search: false,
            selection: false,
            emptyRowsWhenPaging: false,
            tableLayout: 'auto',
            exportButton: false,
            showFirstLastPageButtons: false,
            showTitle: true,
            headerStyle: {
              backgroundColor: '#efefef',
              fontWeight: 'bold',
            },
            paging: false,
          }}
        />
      </TableContainer>
    </div>
  );
};

SchedulesTable.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  hideEdit: PropTypes.bool.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  enableSchedulesGroupEdit: PropTypes.func.isRequired,
  addSelectedScheduleGroupToNewFacility: PropTypes.func.isRequired,
};

export default SchedulesTable;
