/* eslint-disable no-constant-condition */
import {
  takeEvery, call, put,
} from 'redux-saga/effects';
import {
  getUsersService,
  sendManagerEmailToUsersService,
  getUsersBookingsService,
  inviteUser,
  getUserBookingsBySearchService,
  inviteAssociateUser,
  getAssociateUsers,
} from '../services/usersServices';
import * as actions from '../reducers/usersReducer';
import * as leagueActions from '../reducers/leaguesReducer';
import { resetCreateInvoiceSelectedReservations } from '../reducers/invoicesReducer';
import { getCompanyUsers } from '../reducers/companyUsersReducer';
import { handleSagaError } from './helperSaga';

function* sendManagerEmailToUsersSaga(companyId, action) {
  try {
    const { message, userEmails, isSmsRequested } = action;
    const response = yield call(
      sendManagerEmailToUsersService,
      companyId,
      { message, userEmails, isSmsRequested },
    );
    if (!response.error) {
      yield put(actions.succeedUserEmailSending());
    } else { throw response; }
  } catch (error) {
    yield put(actions.failUserEmailSending());
    yield call(handleSagaError, error, 'sendUserCommunicationSaga');
  }
}

// GET USERS
function* getUsersSaga(companyId, action) {
  try {
    const {
      page, term, orderBy, orderDirection,
    } = action;

    const response = yield call(getUsersService, companyId, page, term, orderBy, orderDirection);
    if (!response.error) {
      yield put(actions.updateUsers(response.data.users, response.data.total));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getUsersSaga');
  }
}
function* getUsersBookingsSaga(companyId, action) {
  const { userId, status } = action;
  try {
    yield put(resetCreateInvoiceSelectedReservations());
    const response = yield call(getUsersBookingsService, companyId, userId, status);
    if (!response.error) {
      yield put(actions.updateUsersBookings(response.data.bookings));
      yield put(actions.updateUsersSubscriptionReservations(
        response.data.subscriptionReservations,
      ));
      yield put(actions.updateUsersInformation(response.data.user));
      yield put(actions.succeedGetUsersBookings());
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getUsersBookingsSaga');
    yield put(actions.failGetUsersBookings());
  }
}
function* getUsersBookingsBySearchSaga(companyId, action) {
  const { options, userId } = action;
  const {
    startDate, endDate, status, facilities,
  } = options;
  const dates = {
    fromDate: startDate,
    toDate: endDate,
  };
  try {
    const response = yield call(getUserBookingsBySearchService,
      companyId, userId, dates, status, facilities);
    if (!response.error) {
      yield put(actions.updateUsersBookings(response.data.bookings));
      yield put(actions.updateUsersSubscriptionReservations(
        response.data.subscriptionReservations,
      ));

      yield put(actions.updateUsersInformation(response.data.user));
      yield put(actions.succeedGetUsersBookings());
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getUsersBookingsBySearchSaga');
    yield put(actions.failGetUsersBookings());
  }
}

function* inviteUserSaga(companyId, action) {
  const { user } = action;
  try {
    const response = yield call(inviteUser, user, companyId);
    if (!response.error) {
      yield put(getCompanyUsers(companyId));
      yield put(actions.inviteUserSuccess());
    } else {
      yield put(actions.inviteUserFail());
      throw response;
    }
  } catch (error) {
    yield put(actions.inviteUserFail());
    yield call(handleSagaError, error, 'confirmInviteSaga');
  }
}
function* inviteAssociateSaga(companyId, action) {
  const { associateDetails } = action;
  const body = {
    associateDetails,
    userId: associateDetails.id,
    type: associateDetails.role,
  };
  try {
    const response = yield call(inviteAssociateUser, body, companyId);
    if (!response.error) {
      yield put(actions.getCompanyAssociates());
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'confirmInviteSaga');
  }
}
function* getAssociatesSaga(companyId, action) {
  let siteId = null;
  if (action && action.siteId) { siteId = action.siteId; }
  try {
    const response = yield call(getAssociateUsers, companyId, siteId);
    if (!response.error) {
      yield put(leagueActions.updateAvailableFixtureAssociates(response.data));
      yield put(actions.updateCompanyAssociates(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'confirmInviteSaga');
  }
}

export function* getUsersWatcher(companyId) {
  yield takeEvery(actions.GET_USERS, getUsersSaga, companyId);
}
export function* inviteUserWatcher(companyId) {
  yield takeEvery(actions.INVITE_USER, inviteUserSaga, companyId);
}
export function* getUsersBookingsWatcher(companyId) {
  yield takeEvery(actions.GET_USERS_BOOKINGS, getUsersBookingsSaga, companyId);
}
export function* getUsersBookingsBySearchWatcher(companyId) {
  yield takeEvery(actions.REQUEST_USER_BOOKINGS_BY_SEARCH, getUsersBookingsBySearchSaga, companyId);
}
export function* sendManagerEmailToUsersWatcher(companyId) {
  yield takeEvery(actions.USER_EMAIL_SENDING_REQUESTED, sendManagerEmailToUsersSaga, companyId);
}
export function* inviteAssociateWatcher(companyId) {
  yield takeEvery(actions.INVITE_ASSOCIATE, inviteAssociateSaga, companyId);
}
export function* getAssociatesWatcher(companyId) {
  yield takeEvery(actions.GET_COMPANY_ASSOCIATES, getAssociatesSaga, companyId);
}
