import moment from 'moment';

export const ORDERS_RETRIEVAL_REQUESTED = 'ORDERS_RETRIEVAL_REQUESTED';
export const ORDERS_RETRIEVAL_SUCCEEDED = 'ORDERS_RETRIEVAL_SUCCEEDED';
export const ORDERS_RETRIEVAL_FAILED = 'ORDERS_RETRIEVAL_FAILED';
export const UPDATE_ORDERS_STORE = 'UPDATE_ORDERS_STORE';
export const ORDERS_PAYMENT_REPORT_REQUESTED = 'ORDERS_PAYMENT_REPORT_REQUESTED';

export const initialState = {
  orders: [],
  orderRetrievalFailedMessage: '',
  orderRetrievalLoading: false,
  selectedSiteId: null,
  selectedStartDate: moment().startOf('month'),
  selectedEndDate: moment(),
};

export function getOrders(selectedSitesAndDates) {
  return { type: ORDERS_RETRIEVAL_REQUESTED, selectedSitesAndDates };
}

export function getPaymentReport(selectedSitesAndDates) {
  return { type: ORDERS_PAYMENT_REPORT_REQUESTED, selectedSitesAndDates };
}

export function siteOrdersRetrievedSuccesfully(orders) {
  return { type: ORDERS_RETRIEVAL_SUCCEEDED, orders };
}
export function siteOrdersRetrievedFailed(error) {
  return { type: ORDERS_RETRIEVAL_FAILED, error };
}

export function updateOrdersStore(keyWithValue) {
  return { type: UPDATE_ORDERS_STORE, keyWithValue };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ORDERS_RETRIEVAL_REQUESTED:
      return { ...state, orderRetrievalLoading: true };
    case ORDERS_RETRIEVAL_SUCCEEDED:
      return { ...state, orders: action.orders, orderRetrievalLoading: false };
    case ORDERS_RETRIEVAL_FAILED:
      return { ...state, orderRetrievalFailedMessage: action.error, orderRetrievalLoading: false };
    case UPDATE_ORDERS_STORE:
      return {
        ...state,
        ...action.keyWithValue,
      };
    default:
      return state;
  }
};
