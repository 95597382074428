import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { deleteAllocationsErrors } from '@pitchbooking-dev/pb-shared/lib/reducers/basketReducer';

const renderErrorAllocations = (erroredReservations) => erroredReservations.map(
  (erroredAllocation) => {
    let timezone = 'Europe/London';
    if (erroredAllocation.timezone && erroredAllocation.timezone !== undefined) {
      timezone = erroredAllocation.timezone;
    }
    return (
      <>
        <p>
          {moment(erroredAllocation.startTime).tz(timezone).format('LLL')}
          {' '}
          -
          {' '}
          {moment(erroredAllocation.endTime).tz(timezone).add(1, 'seconds').format('LT')}
        </p>
        {erroredAllocation.errorCode === ''}
        <p>
          <strong>Information: </strong>
          {erroredAllocation.errorCode === 'allocation-AO'
            ? 'Pitch available but selected amenity is already booked for this time. '
            : 'This slot is already booked for this time. '}
          If you feel this is an error please get in touch
          with us via the chat and quote the support code
          {' '}
          <i>{erroredAllocation.errorCode}</i>
        </p>
        <Divider style={{ marginTop: '15px' }} className="divider" />
      </>
    );
  },
);

const AddToBasketErrorDialog = ({ erroredReservations, doDeleteAllocationsErrors }) => (
  <Dialog
    style={{ zIndex: 100001 }}
    open={erroredReservations.length > 0}
    aria-labelledby="form-dialog-title"
  >
    <DialogTitle id="form-dialog-title">Sorry, not all bookings could be added to basket</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {renderErrorAllocations(erroredReservations)}
      </DialogContentText>
      <DialogActions>
        <Button onClick={() => doDeleteAllocationsErrors()} style={{ backgroundColor: '#47FEB4', color: '#fff' }}>
          Close
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
);

AddToBasketErrorDialog.propTypes = {
  erroredReservations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  doDeleteAllocationsErrors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  erroredReservations: state.basket.erroredAllocations,
});

const mapDispatchToProps = (dispatch) => ({
  doDeleteAllocationsErrors: () => dispatch(deleteAllocationsErrors()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddToBasketErrorDialog);
