import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../reducers/usersReducer';
import AssociatesTable from './AssociatesTable';
import '../../styles/payments.css';

class TeamMembers extends React.Component {
  componentDidMount() {
    const {
      getCompanyAssociates,
    } = this.props;
    getCompanyAssociates();
  }

  render() {
    const { props } = this;
    const { associates } = props;
    return (
      <>
        <AssociatesTable
          associates={associates}
        />
      </>
    );
  }
}

TeamMembers.propTypes = {
  associates: PropTypes.shape().isRequired,
  getCompanyAssociates: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    users,
  } = state;
  const { associates } = users;
  return {
    associates,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getCompanyAssociates: () => dispatch(actions.getCompanyAssociates()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamMembers);
