import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import EventsOverview from './EventsOverview';
import EventsSolution from './EventsSolution';

class Events extends React.Component {
  render() {
    const {
      viewEvents,
    } = this.props;
    return (
      <AlternativelyVisible condition={viewEvents}>
        <EventsOverview />
        <EventsSolution />
      </AlternativelyVisible>
    );
  }
}

Events.propTypes = {
  viewEvents: PropTypes.shape().isRequired,
};

function mapStateToProps(state) {
  const { currentUser } = state.currentUser;
  const { managerAccess } = currentUser;
  const { viewEvents } = managerAccess[0].role;
  const { companyInfo } = state.companies;
  const { eventsCommission } = companyInfo;
  return {
    viewEvents: !!viewEvents || (eventsCommission !== null && eventsCommission >= 0),
  };
}

export default connect(mapStateToProps, null)(Events);
