import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function getMembershipService(companyId, id) {
  const url = `/api/companies/${companyId}/memberships/${id}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function userMembershipApprovalService(membershipId, userMembershipId, approval) {
  const url = `/api/memberships/${membershipId}/userMembership/${userMembershipId}/approval`;
  const response = await httpHelpers.authenticatedPostRequest(url, { approval });
  return response;
}

export async function getMembershipFormData(membershipId, membershipFormId) {
  const url = `/api/memberships/${membershipId}/${membershipFormId}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function updateMembershipForm(membershipId, membershipFormId, reqBody) {
  const url = `/api/memberships/${membershipId}/updateMembershipForm/${membershipFormId}`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function getUserMembershipService(membershipId, userId) {
  const url = `/api/memberships/${membershipId}/users/${userId}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function updateUserMembershipExclusionsService(membershipId, userId, exclusions) {
  const body = { exclusions };
  const url = `/api/memberships/${membershipId}/users/${userId}/exclusions`;
  const response = await httpHelpers.authenticatedPutRequest(url, body);

  return response;
}

export async function validateMembershipSubscriptions(companyId) {
  const url = `/api/companies/${companyId}/memberships/subscriptions/validate`;
  const response = await httpHelpers.authenticatedPostRequest(url);

  return response;
}
