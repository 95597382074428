/* eslint-disable import/prefer-default-export */
import {
  takeEvery, call, put, select,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import {
  getXeroConsentUrl,
  generateNewXeroToken,
  processPowerleagueBacsService,
  triggerCronJobService,
} from '../services/utilsServices';
import * as actions from '../reducers/utilsReducer';
import { handleSagaError } from './helperSaga';

function* getXeroConsentUrlSaga(companyId) {
  if (companyId) {
    try {
      const response = yield call(getXeroConsentUrl, companyId);
      if (!response.error) {
        const consentUrl = response.data;
        yield put(actions.succeedXeroConsentUrl(consentUrl));
        window.open(consentUrl);
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'getXeroConsentUrlSaga');
      yield put(actions.failXeroConsentUrl());
    }
  }
}

function* generateNewXeroTokenSaga(companyId, action) {
  const { code } = action;
  if (companyId) {
    try {
      const response = yield call(generateNewXeroToken, companyId, code);
      if (!response.error) {
        yield put(actions.succeedExchangeCode());
        yield put(push('/utils'));
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'generateNewXeroTokenSaga');
      yield put(actions.failExchangeCode());
      yield put(push('/utils'));
    }
  }
}

function* processPowerleagueBacsSaga(companyId) {
  if (companyId) {
    try {
      const response = yield call(processPowerleagueBacsService, companyId);
      if (!response.error) {
        yield put(actions.succeedProcessPowerleagueBacs());
      } else {
        yield put(actions.failProcessPowerleagueBacs());
        throw response;
      }
    } catch (error) {
      yield put(actions.failProcessPowerleagueBacs());
      yield call(handleSagaError, error, 'processPowerleagueBacsSaga');
    }
  }
}

function* triggerCronJobSaga(companyId) {
  const state = yield select();
  const { cronJobName } = state.utils;
  if (companyId) {
    try {
      const response = yield call(triggerCronJobService, companyId, cronJobName);
      if (!response.error) {
        yield put(actions.succeedCronJob());
      } else {
        yield put(actions.failCronJob());
        throw response;
      }
    } catch (error) {
      yield put(actions.failCronJob());
      yield call(handleSagaError, error, 'triggerCronJobSaga');
    }
  }
}

// WATCHERS
export function* getXeroConsentUrlWatcher(companyId) {
  yield takeEvery(actions.XERO_CONSENT_URL_REQUESTED, getXeroConsentUrlSaga, companyId);
}

export function* generateNewXeroTokenWatcher(companyId) {
  yield takeEvery(actions.XERO_EXCHANGE_CODE_FOR_TOKEN, generateNewXeroTokenSaga, companyId);
}

export function* processPowerleagueBacsWatcher(companyId) {
  yield takeEvery(actions.PROCESS_POWERLEAGUE_BACS_REQUESTED,
    processPowerleagueBacsSaga, companyId);
}

export function* triggerCronJobWatcher(companyId) {
  yield takeEvery(actions.TRIGGER_CRON_JOB_REQUESTED,
    triggerCronJobSaga, companyId);
}
