import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PreviousBookerUserSearch from '../../users/components/PreviousBookerUserSearch';
import * as actions from '../../../reducers/invoicesReducer';
import MenuHeading from '../../../components/MenuHeading';

const InvoiceStepperUserPicker = (props) => {
  const { user, updateCreateInvoiceStore } = props;
  return (
    <>
      <MenuHeading title="Who do you want to send an invoice/payment link to?" />
      <div className="reservation-creation-user-selecton">
        <PreviousBookerUserSearch
          updateUserDetails={
            (updatedUser) => (updatedUser.id
              ? updateCreateInvoiceStore({ user: updatedUser })
              : updateCreateInvoiceStore({ user: { id: null } }))
}
          user={user}
          disableClearable={false}
        />
        <br />
      </div>
    </>
  );
};

InvoiceStepperUserPicker.propTypes = {
  user: PropTypes.shape().isRequired,
  updateCreateInvoiceStore: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.invoices.createInvoice.user,
});

const mapDispatchToProps = (dispatch) => ({
  updateCreateInvoiceStore: (keyWithValue) => dispatch(
    actions.updateCreateInvoiceStore(keyWithValue),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceStepperUserPicker);
