import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  CircularProgress,
  ListItemText,
  Input,
  Button,
  Typography,
} from '@material-ui/core/';
import { useMutation } from '@tanstack/react-query';
import { useToast, usePitchbookingUser, useCompany } from '../../hooks';
import { updateCompanyUser } from '../../services/usersServices';
import { isValidUUID } from '../../utils';

const MultiSiteSelector = ({
  sites,
  changeSite,
  disabled,
  calendarIsLoading,
}) => {
  const toast = useToast();
  const { id: userId, preferences } = usePitchbookingUser();
  const companyInfo = useCompany();
  const [sitesRequested, setSitesRequested] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const preferredSiteIds = useMemo(
    () => preferences?.preferredSites?.map((site) => site) || [],
    [preferences],
  );

  // Determine if "All Sites" should be checked or indeterminate
  const allSitesSelected = selectedIds.length === sites.length;
  const allSitesIndeterminate = selectedIds.length > 0 && !allSitesSelected;

  // Used to set users preferred sites || set site[0] if no preferred sites
  useEffect(() => {
    if (sites.length > 0) {
      if (preferredSiteIds.length > 0 && companyInfo.products.multiSiteSelector.toLowerCase() === 'enabled') {
        setSelectedIds((prevIds) => {
          if (JSON.stringify(prevIds) !== JSON.stringify(preferredSiteIds)) {
            return preferredSiteIds;
          }
          return prevIds;
        });
      } else {
        setSelectedIds((prevIds) => {
          if (prevIds[0] !== sites[0].id) {
            return [sites[0].id];
          }
          return prevIds;
        });
      }
    }
  }, [sites, preferredSiteIds]);

  const mutation = useMutation({
    mutationFn: async () => {
      const data = {
        updateUserPreferences: true,
        userPreferences: {
          ...preferences,
          preferredSites: selectedIds,
        },
      };

      const res = await updateCompanyUser(companyInfo.id, userId, data);
      if (res.status !== 200) {
        throw new Error('Error');
      }
    },
    onSuccess: () => {
      toast.trigger({
        type: 'success',
        message: 'Default calendar view saved',
      });
    },
    onError: () => {
      toast.trigger({
        type: 'error',
        message: 'Error saving view. Please refresh the page and try again',
      });
    },
  });

  const handleChange = (event) => {
    const { value } = event.target;

    if (value.includes('all')) {
      // If "All Sites" is selected, select or deselect all
      setSelectedIds(allSitesSelected ? [] : sites.map((site) => site.id));
    } else {
      // Handle individual site selection
      setSelectedIds(value.filter(isValidUUID));
    }
  };

  const handleUpdateSites = async () => {
    setSitesRequested(true);
    try {
      await changeSite(selectedIds);
    } catch (error) {
      console.error('Error updating sites:', error);
      toast.trigger({
        type: 'error',
        message: 'Error updating sites. Please try again.',
      });
    } finally {
      setSitesRequested(false);
    }
  };

  return (
    <FormControl variant="outlined" disabled={disabled || calendarIsLoading || sitesRequested}>
      <Select
        labelId="site-selector-label"
        multiple
        value={selectedIds}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => selected.map((id) => sites.find((site) => site.id === id)?.name || 'N/A').join(', ')}
      >
        <MenuItem value="all">
          <Checkbox
            indeterminate={allSitesIndeterminate}
            checked={allSitesSelected}
          />
          <ListItemText primary="All Sites" />
        </MenuItem>
        {sites.map((site) => (
          <MenuItem key={site.id} value={site.id} disabled={sitesRequested || calendarIsLoading}>
            <Checkbox checked={selectedIds.includes(site.id)} />
            <ListItemText primary={site?.name} />
          </MenuItem>
        ))}
        <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
          <MenuItem>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateSites}
              disabled={sitesRequested || calendarIsLoading || selectedIds.length === 0}
              style={{ width: '100%' }}
              type="submit"
            >
              <Typography variant="button">
                {sitesRequested || calendarIsLoading ? <CircularProgress size={24} color="secondary" /> : 'Update Sites'}
              </Typography>
            </Button>
          </MenuItem>
          {!(calendarIsLoading || sitesRequested || selectedIds.length === 0) && (
            <MenuItem>
              <Button
                variant="contained"
                onClick={mutation.mutate}
                style={{ width: '100%' }}
                type="button"
                disabled={mutation.isLoading}
              >
                <Typography variant="button">
                  {mutation.isLoading ? <CircularProgress size={24} color="secondary" /> : 'Save as Default View'}
                </Typography>
              </Button>
            </MenuItem>
          )}
        </div>
      </Select>
    </FormControl>
  );
};

MultiSiteSelector.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  changeSite: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  calendarIsLoading: PropTypes.bool,
};

MultiSiteSelector.defaultProps = {
  disabled: false,
  calendarIsLoading: false,
};

export default MultiSiteSelector;
