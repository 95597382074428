import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  List, TextField,
} from '@material-ui/core';
import * as userActions from '../../../reducers/usersReducer';
import MenuHeading from '../../../components/MenuHeading';
import InvoiceAdditonalItemField from './InvoiceAdditonalItemField';
import * as actions from '../../../reducers/invoicesReducer';

class InvoiceStepperAdditionalItems extends Component {
  render() {
    const { invoice, updateInvoice } = this.props;
    return (
      <>
        <div>
          <MenuHeading title="Would you like to add any additional items? If you have opted not to include bookings, add lines for each miscellaneous item you wish to charge for below. Please ensure you click the ADD button on each individual line before clicking NEXT." />
          <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Add an item</p>
          <InvoiceAdditonalItemField />
          {invoice.additionalItems.length > 0 && (
          <>
            <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Items included:</p>
            <List>
              {invoice.additionalItems.map((item) => (
                <InvoiceAdditonalItemField item={item} />
              ))}
            </List>
          </>
          )}
        </div>

        <div>
          <MenuHeading title="Would you like to add notes for the booker?" />
          <div style={{ color: '#62738d' }}>(P.O. Number / Customer Address)</div>
          <TextField
            id="bookerNotes"
            label="Booker Notes"
            multiline
            variant="outlined"
            fullWidth
            value={invoice.bookerNotes}
            onChange={(e) => updateInvoice({
              ...invoice,
              bookerNotes: e.target.value,
            })}
            style={{ marginTop: '1rem' }}
          />
        </div>
      </>
    );
  }
}

InvoiceStepperAdditionalItems.propTypes = {
  invoice: PropTypes.shape.isRequired,
  updateInvoice: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  invoice: state.invoices.createInvoice,
});

const mapDispatchToProps = (dispatch) => ({
  getUsersBookings: (userId, type) => dispatch(userActions.getUsersBookings(userId, type)),
  updateInvoice: (invoice) => dispatch(actions.updateCreateInvoiceStore(invoice)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceStepperAdditionalItems);
