/* eslint-disable react/prop-types */
import React from 'react';
import {
  Typography,
} from '@material-ui/core';
import StripeCheckout from '@pitchbooking-dev/pb-shared/lib/components/Stripe/StripeCheckout';
import {
  useCompany,
} from '../../../hooks';

export const CardNotPresent = ({ paymentMethod, booking, onSuccess }) => {
  const {
    products, stripeId,
  } = useCompany();

  if (paymentMethod !== 'CARD_NOT_PRESENT' || products?.cardNotPresent !== 'ENABLED') return null;

  return (
    <div>
      <Typography variant="body2" style={{ marginBottom: '1rem' }}>
        You can now capture payments from your customers over
        the phone or directly from a saved payment method!
      </Typography>

      <StripeCheckout
        mode="PAYMENT_INTENT"
        transactionType={
          booking?.type === 'SUBSCRIPTION'
            ? 'SUBSCRIPTION_PAYMENT'
            : 'RESERVATION_PAYMENT'
        }
        transactionId={booking?.id}
        buttonText="Record Payment"
        companyStripeId={stripeId}
        redirectUrl={`${window.location.origin}/bookings`}
        metadata={{
          isCardNotPresent: true,
          ...(booking?.type === 'SUBSCRIPTION' ? {
            slot: booking.slot,
          } : {}),
        }}
        onSuccess={onSuccess}
      />
    </div>
  );
};
