import React from 'react';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import createDemoTemplates from '../../../utils/createDemoTemplates';

export const initialValues = {
  templateType: '',
};

export const validation = Yup.object({
  templateType: Yup.string().required('Template is required'),
});

const PresetVenueForm = () => {
  const {
    values, errors, handleChange, touched,
  } = useFormikContext();

  return (
    <div>
      <PBInput
        id="templateType"
        type="select"
        label="What template type would you like to use?"
        value={values.templateType}
        options={createDemoTemplates}
        onChange={handleChange}
        errorMessage={errors.templateType}
        isError={touched.templateType && Boolean(errors.templateType)}
      />
      {errors.templateType}
    </div>
  );
};

export default PresetVenueForm;
