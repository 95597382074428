import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { Typography, Card } from '@material-ui/core';
import { Link } from 'react-router-dom';
import LaunchIcon from '@material-ui/icons/Launch';
import { paymentStatusTextColourGenerator } from '../../../helpers';

const MobileBookingResult = (props) => {
  const { booking } = props;
  const {
    amenities,
    paymentStatusText,
    paid,
    facilityName,
    amenityNames,
    fullName,
  } = booking;
  const dateFormat = Moment(booking.startTime).format('ddd, D MMM YYYY');
  const startTimeFormat = Moment(booking.startTime).format('H:mm');
  const endTimeFormat = Moment(booking.endTime).add(1, 'seconds').format('H:mm');
  const time = `${startTimeFormat} – ${endTimeFormat}`;

  // Determine whether to link to a booking or a subscription
  let url = '';
  if (booking.id) {
    url = `/bookings/${booking.id}`;
  } else {
    url = `/subscriptions/${booking.subscriptionId}`;
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', padding: '1rem' }}>
        <Link
          to={url}
          style={{ textDecoration: 'none' }}
        >
          <Card
            style={{ padding: '1rem', marginBottom: '1rem', borderRadius: '0.75rem' }}
          >
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {facilityName}
            </Typography>
            <Typography variant="body1" style={{ margin: '0rem 0.5rem 0.5rem 0rem' }}>
              {fullName}
            </Typography>
            <Typography variant="body1" style={{ margin: '0rem 0.5rem 0.5rem 0rem' }}>
              {dateFormat}
            </Typography>
            <Typography variant="body1" style={{ margin: '0rem 0.5rem 0.5rem 0rem' }}>
              {time}
            </Typography>
            <Typography
              variant="body1"
              style={{
                color: paymentStatusTextColourGenerator(paid, paymentStatusText),
                margin: '0rem 0.5rem 0.5rem 0rem',
              }}
            >
              {paymentStatusText}
            </Typography>

            <ConditionallyVisible condition={amenities.length > 0}>
              <Typography variant="body1" style={{ fontWeight: 'bold', marginTop: '1rem' }}>
                {amenityNames ? amenityNames.join(', ') : 'None booked'}
              </Typography>
            </ConditionallyVisible>

            <div style={{
              marginTop: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'flex-end',
            }}
            >
              <Typography variant="button" style={{ fontWeight: 'bold', color: '#47FEB4' }}>
                View Booking
              </Typography>
              <LaunchIcon style={{ fontSize: '1.2rem', marginLeft: '0.5rem', color: '#47FEB4' }} />
            </div>
          </Card>
        </Link>
      </div>
    </>
  );
};

MobileBookingResult.propTypes = {
  booking: PropTypes.shape().isRequired,
  amenities: PropTypes.shape(),
};
MobileBookingResult.defaultProps = {
  amenities: {},
};

export default (MobileBookingResult);
