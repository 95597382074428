/*
    The HeaderEvents component represents the events statistic
    at the top of the analytics. It is composed of 3 items:
        1. A label (StatisticLabel) that identifies the statistic.
        2. Absolute number of events over the past X days.
        3. An icon (AssignmentTurnedInIcon) that helps further
           identify the statistic.
*/

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import LoadingSkeletonThreeDots from '../../../../components/LoadingComponents/LoadingSkeletonThreeDots';
import FailedSection from '../../../../components/LoadingComponents/FailedSection';
import { StatisticLabel } from './StatisticLabel';
import '../../styles/eventAnalytics.css';

function HeaderAvailableSpace(props) {
  const {
    analyticsEventBookings,
    analyticsEventLoading,
    analyticsEventFailed,
    event,
  } = props;

  const { bookings, eventIDsArray } = analyticsEventBookings;

  let numberOfBookings = 0;

  function bookingChecker(booking) {
    if (booking.eventId === event.id) {
      numberOfBookings += 1;
    }
  }

  function allEventBookingChecker(booking) {
    if (eventIDsArray.includes(booking.eventId)) {
      numberOfBookings += 1;
    }
  }

  if (event?.name === 'All Events') {
    bookings.forEach(allEventBookingChecker);
  } else {
    bookings.forEach(bookingChecker);
  }

  let availableSpace = event.maximumCapacity - numberOfBookings;
  if (availableSpace < 0) {
    availableSpace = 0;
  }

  return (
    <div className="headerItem">

      <AlternativelyVisible condition={analyticsEventLoading}>
        <LoadingSkeletonThreeDots />
        <AlternativelyVisible condition={analyticsEventFailed}>
          <FailedSection />
          <>
            <div className="statistic" id="eventStats">
              <StatisticLabel labelString="Available Space" />
              <div className="statisticContent">
                <div>
                  <NumberFormat
                    displayType="text"
                    thousandSeparator
                    value={availableSpace}
                  />
                </div>
              </div>
            </div>
          </>
        </AlternativelyVisible>
      </AlternativelyVisible>

    </div>
  );
}

HeaderAvailableSpace.propTypes = {
  analyticsEventBookings: PropTypes.shape().isRequired,
  analyticsEventLoading: PropTypes.bool.isRequired,
  analyticsEventFailed: PropTypes.bool.isRequired,
  event: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  analyticsEventBookings: state.analytics.analyticsEventBookings,
  analyticsEventLoading: state.analytics.analyticsEventLoading,
  analyticsEventFailed: state.analytics.analyticsEventFailed,
});

export default connect(mapStateToProps)(HeaderAvailableSpace);
