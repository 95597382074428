/**
 * @fileoverview The CategoryChartBase component
 * represents and holds the actual chart itself.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import config from './configs/CategoryChartBaseConfigPie';

export class CategoryChartBasePie extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartInstance: null,
    };
    this.updateChart = this.updateChart.bind(this);
  }

  componentDidMount() {
    // Set global font.
    Chart.defaults.global.defaultFontFamily = 'Montserrat';
    const { categoryName, bookings } = this.props;
    const context = document.getElementById(`${categoryName}Chart`).getContext('2d');
    // Configure canvas.
    const configCopy = { ...config };
    const newChartInstance = new Chart(context, configCopy);

    this.setState({
      chartInstance: newChartInstance,
    }, () => {
      if (bookings.length === 0) {
        // Update chart if we have no data.
        this.updateChart();
      } else if (bookings[0]?.id !== '') {
        // Update chart if we have non initial-state data.
        this.updateChart();
      }
    });
  }

  // This will trigger every time the component updates
  componentDidUpdate(previousProps) {
    const { event } = this.props;
    // Check if prev event is different
    if (previousProps.event !== event) {
      this.updateChart();
    }
  }

  bookingsDataIsDifferent(prevProps) {
    const { bookings } = this.props;
    const currentBookings = bookings;
    const currentBookingsLength = currentBookings.length;
    const previousBookings = prevProps.bookings;
    const previousBookingsLength = previousBookings.length;

    if (previousBookingsLength === currentBookingsLength) {
      return false;
    }

    if (previousBookingsLength === 0 || currentBookingsLength === 0) {
      return true;
    }

    if (previousBookingsLength !== currentBookingsLength) {
      return true;
    }

    if (previousBookings[0].id === currentBookings[0].id
      && previousBookings[previousBookingsLength - 1].id
      === currentBookings[currentBookingsLength - 1].id) {
      return false;
    }

    return true;
  }

  /*
            updateChart is a method that carries out all of the work involved with
            actually updating the chart. Namely, it determines the visual axis
            granularity. It then updates the chart using the chart instance
            stored in component state.

            See chart.js documentation for more information on manually updating charts.
            https://www.chartjs.org/docs/latest/developers/updates.html
        */
  updateChart() {
    const { chartInstance } = this.state;
    const currentChartInstance = chartInstance;
    const { event, bookings, eventIDsArray } = this.props;

    let numberOfBookings = 0;

    function bookingChecker(booking) {
      if (booking.eventId === event.id) {
        numberOfBookings += 1;
      }
    }

    function allEventBookingChecker(booking) {
      if (eventIDsArray.includes(booking.eventId)) {
        numberOfBookings += 1;
      }
    }

    if (event?.id === -1) {
      bookings.forEach(allEventBookingChecker);
    } else {
      bookings.forEach(bookingChecker);
    }

    const booked = numberOfBookings;
    let notBooked = 0;
    if (event !== null && event.maximumCapacity !== null
      && event.maximumCapacity >= numberOfBookings) {
      notBooked = event.maximumCapacity - numberOfBookings;
    }

    currentChartInstance.data.datasets = [{
      backgroundColor: [
        '#1dcc7d', // green for booked
        '#C0C0C0', // grey for not booked
      ],
      data: [booked, notBooked],
    }];

    currentChartInstance.options.title.text = event?.name;
    if (event?.name === undefined) {
      currentChartInstance.options.title.text = '';
    }
    currentChartInstance.update();
  }

  render() {
    const { categoryName } = this.props;
    return (
      <div className="chartContainer">
        <canvas id={`${categoryName}Chart`} />
      </div>
    );
  }
}

CategoryChartBasePie.propTypes = {
  categoryName: PropTypes.string.isRequired,
  event: PropTypes.shape().isRequired,
  eventIDsArray: PropTypes.shape().isRequired,
  bookings: PropTypes.shape().isRequired,
};
