/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import '../../styles/payments.css';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import TableContainer from '@material-ui/core/TableContainer';
import moment from 'moment';
import * as helper from '../../helpers/index';

const WeekScheduleTable = ({ schedules }) => (
  <>
    <div>
      <TableContainer
        style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden' }}
        component={Paper}
      >
        <MaterialTable
          data={schedules}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          title="Pitchbooking Schedules"
          icons={{
            NextPage: () => <ChevronRight />,
            PreviousPage: () => <ChevronLeft />,
            Search: () => <Search />,
            Export: () => <SaveAlt />,
            ResetSearch: () => <Clear />,
            SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
          }}
          columns={[
            {
              field: 'day',
              render: (row) => moment().weekday(row.day).format('dddd'),
              cellStyle: {
                textAlign: 'right',
              },
            },
            { render: (row) => (helper.createTimeRangeStringForSchedules(row.start, row.end)) },
          ]}
          options={{
            search: false,
            selection: false,
            emptyRowsWhenPaging: false,
            tableLayout: 'auto',
            exportButton: false,
            exportFileName: 'Schedules',
            pageSize: 7,
            showFirstLastPageButtons: false,
            showTitle: false,
            toolbar: false,
            paging: false,
            header: false,
            headerStyle: {
              fontWeight: 'bold',
            },
          }}
        />
      </TableContainer>
    </div>
  </>
);

WeekScheduleTable.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default (WeekScheduleTable);
