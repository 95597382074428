import React from 'react';
import CategoryBookings from './CategoryBookings';
import CategoryRevenue from './CategoryRevenue';
import CategoryRevenueByFacility from './CategoryRevenueByFacility';
import CategoryUtilisation from './CategoryUtilisation';

export class CategoriesWrapper extends React.Component {
  render() {
    return (
      <div className="categoriesWrapper">
        <CategoryBookings />
        <CategoryRevenue />
        <CategoryRevenueByFacility />
        <CategoryUtilisation />
      </div>
    );
  }
}
