/* eslint-disable react/prop-types */
import React from 'react';
import {
  CircularProgress, Divider, Typography,
} from '@material-ui/core';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone';
import { EditTeamMemberDialog } from '../../teamMembers/components/EditTeamMemberDialog';
import { MaterialTable } from '../../../components/MaterialTable';
import { getCompanyUsers } from '../../../reducers/companyUsersReducer';

export const FacilityManagers = ({ companyInfo }) => {
  const dispatch = useDispatch();
  const companyId = companyInfo.id;
  const teamMemberData = useSelector((state) => state.companyUsers.team);

  return (
    <>
      {teamMemberData?.length > 0 ? (
        <>
          <Typography style={{ textDecoration: 'underline' }}>
            Facility Managers
          </Typography>
          <Typography variant="body2">
            This is determined by the users who have emails enbled.
          </Typography>

          <MaterialTable
            data={teamMemberData}
            columns={[
              {
                title: 'Email',
                field: 'email',
                render: (teamMember) => (
                  <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <AccountCircleTwoToneIcon style={{ color: Math.floor(Math.random() * 0xFFFFFF).toString(16), fontSize: '2rem' }} />
                    {teamMember.email}
                  </Typography>
                ),
              },
              {
                title: 'Receives Emails',
                align: 'center',
                render: (teamMember) => <EmailTwoToneIcon color={teamMember.receivesEmails ? 'primary' : 'error'} />,
              },
              {
                title: 'Edit',
                align: 'center',
                render: (teamMember) => (
                  <EditTeamMemberDialog
                    user={teamMember}
                    success={() => dispatch(getCompanyUsers(companyId))}
                    isAccountManagerPage
                  />
                ),
              },
            ]}
            options={{
              sorting: false,
              toolbar: false,
              paging: false,
              headerStyle: {
                backgroundColor: '#efefef',
                fontWeight: '600',
                cursor: 'default',
                position: 'sticky',
                top: 0,
              },
              maxBodyHeight: '16rem',
            }}

          />

        </>
      ) : (
        <div style={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem',
        }}
        >
          <CircularProgress />
          <Typography variant="body1" color="primary" style={{ fontWeight: '600' }}>
            Loading Facility Managers
          </Typography>
        </div>
      )}

      <Divider />
    </>
  );
};
