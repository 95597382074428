import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PBBasket from '@pitchbooking-dev/pb-shared';
import BasketDescription from '@pitchbooking-dev/pb-shared/lib/components/basket/BasketDescription';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import ClearBasketDialog from '@pitchbooking-dev/pb-shared/lib/components/basket/ClearBasketDialog';
import * as basketActions from '@pitchbooking-dev/pb-shared/lib/reducers/basketReducer';
import LoadingSection from '../../../../components/LoadingComponents/LoadingSection';

const clearBasket = (basket, requestAllocationRemoval) => {
  basket.confirmedAllocations.forEach((confirmedAllocation) => {
    requestAllocationRemoval(confirmedAllocation.id);
  });
};

const BookingStepperBasket = ({
  requestingAllocationCreation,
  basket,
  requestAllocationRemoval,
}) => (
  <AlternativelyVisible condition={!requestingAllocationCreation}>
    <>
      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <ConditionallyVisible condition={basket.confirmedAllocations.length > 0}>
          <br />
          <br />
          <ClearBasketDialog
            clearAction={() => clearBasket(basket, requestAllocationRemoval)}
          />
          <br />
          <br />
        </ConditionallyVisible>
        <BasketDescription />
      </div>
      <PBBasket isLoggedIn />
    </>
    <>
      <div style={{ maxWidth: 600, margin: 'auto', textAlign: 'center' }}>
        <LoadingSection loadingText="Loading Basket" />
      </div>
    </>

  </AlternativelyVisible>
);

BookingStepperBasket.propTypes = {
  requestingAllocationCreation: PropTypes.bool.isRequired,
  requestAllocationRemoval: PropTypes.func.isRequired,
  basket: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  requestingAllocationCreation: state.basket.tryingAllocationsCreation,
  basket: state.basket,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  requestAllocationRemoval: (id) => basketActions.requestAllocationRemoval(id),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BookingStepperBasket);
