/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import moment from 'moment';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Box,
  Typography,
} from '@material-ui/core';
import PitchbookingUserOnly from '../../../helpers/PitchbookingUserOnly';
import DownloadCSVButton from '../../../components/DownloadCSVButton';
import { generateConfigurationNote } from '../../../helpers/index';
import PBButton from '../../../components/Button';

const FacilityListDownloadDialog = ({ sitesFacilities }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const externalUnitCount = (facility) => {
    if (facility.internal) {
      return 0;
    }

    if (facility.type === 'AMENITY') {
      return 0;
    }

    if (facility.subUnits > 0 && facility.splitType !== 'USER_SELECTION') {
      return facility.subUnits;
    }

    return 1;
  };

  const internalUnitCount = (facility) => (facility.internal ? 1 : 0);

  const prepareCSVData = () => {
    const data = sitesFacilities.flatMap((site) => site.facilities.map((facility) => ({
      'Site Name': site.name,
      'Facility Name': facility.name,
      'Facility Type': facility.type,
      'Split-Type': facility.splitType,
      Configuration: generateConfigurationNote(facility)?.note,
      'Sub-Units': facility.subUnits || '',
      'External Unit Count': externalUnitCount(facility),
      'Internal Unit Count': internalUnitCount(facility),
      id: facility.id,
    })));
    const fields = data.length > 0 ? Object.keys(data[0]).filter((key) => key !== 'id') : [];
    return { fields, data };
  };

  const { fields, data: tableData } = prepareCSVData();

  const totalExternalUnitCount = tableData.reduce((sum, row) => sum + row['External Unit Count'], 0);
  const totalInternalUnitCount = tableData.reduce((sum, row) => sum + row['Internal Unit Count'], 0);

  return (
    <div>
      <PitchbookingUserOnly>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleOpenDialog}
        >
          Facility List
        </Button>
      </PitchbookingUserOnly>
      <Dialog
        open={dialogOpen}
        onBackdropClick={handleCloseDialog}
        onEscapeKeyDown={handleCloseDialog}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>Facility List</DialogTitle>
        <DialogContent>
          <Box mb={3}>
            <Typography variant="h6">Summary</Typography>
            <Typography>
              {`Total External Unit Count: ${totalExternalUnitCount}`}
            </Typography>
            <Typography>
              {`Total Internal Unit Count: ${totalInternalUnitCount}`}
            </Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                {fields.map((field) => (
                  <TableCell key={field}>{field}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row) => (
                <TableRow key={row.id}>
                  {fields.map((field) => (
                    <TableCell key={field}>{row[field]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <PBButton onClick={handleCloseDialog} variant="contained" color="secondary">
            Cancel
          </PBButton>
          <DownloadCSVButton
            fieldsAndData={prepareCSVData()}
            filename={`Facility_List_${moment().format('YYYY-MM-DD')}.csv`}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FacilityListDownloadDialog;
