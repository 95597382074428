import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import LoadingSkeletonBarChart from '../../../../components/LoadingComponents/LoadingSkeletonBarChart';
import FailedSection from '../../../../components/LoadingComponents/FailedSection';
import { CategoryChartBasePie } from './bases/CategoryChartBasePie';
import { CategoryHeaderBase } from './bases/CategoryTitleBase';
import HeaderUtilisationBookings from '../header/HeaderUtilisationBookings';
import HeaderUtilisation from '../header/HeaderUtilisation';
import HeaderAvailableSpace from '../header/HeaderAvailableSpace';

function CategoryUtilisation(props) {
  const {
    analyticsEvents,
    analyticsEventBookings,
    analyticsEventLoading,
    analyticsEventFailed,
  } = props;

  const {
    bookings, eventIDsArray,
  } = analyticsEventBookings;

  const {
    formattedEvents,
  } = analyticsEvents;

  // Filter out unselected events
  const selectedEvents = formattedEvents.filter((event) => eventIDsArray.includes(event.id));

  const allEventCapacity = selectedEvents.map((event) => event.maximumCapacity)
    .reduce((partialSum, a) => partialSum + a, 0);

  selectedEvents.unshift({
    name: 'Please select', id: -1, maximumCapacity: allEventCapacity,
  });

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (selectedEvents && selectedEvents.length > 1) {
      setSelected(selectedEvents[0]);
    }
  }, [eventIDsArray]);

  return (
    <div className="category" id="Utilisation" style={{ width: '100%' }}>
      <CategoryHeaderBase categoryName="Utilisation of Events" />
      <div
        className="utilisationInfo"
        style={{
          display: 'flex', justifyContent: 'space-evenly',
        }}
      >
        <div
          className="utilisationStats"
          style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center',
          }}
        >
          {/* <HeaderEventUsage /> */}
          <p className="dropdownTitle"> Choose an event to view </p>
          <select
            id="Dropdown"
            style={{
              padding: '10px',
            }}
            onChange={(e) => {
              const c = selectedEvents.find((x) => x.id === e.target.value);
              setSelected(c);
            }}
            value={selected?.id}
          >
            {selectedEvents
              ? selectedEvents.map((event) => (
                <option key={event.id} value={event.id}>
                  {event.name}
                </option>
              ))
              : null}
          </select>
        </div>
        <div
          className="UtilisationChart"
          style={{
            display: 'flex', alignItems: 'center', flexDirection: 'column-reverse', width: '40%', justifyContent: 'space-evenly',
          }}
        >
          <AlternativelyVisible condition={analyticsEventLoading}>
            <div style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: '1',
            }}
            >
              <LoadingSkeletonBarChart />
            </div>
            <AlternativelyVisible condition={analyticsEventFailed}>
              <FailedSection />
              {selected ? (
                <CategoryChartBasePie
                  event={selected}
                  eventIDsArray={eventIDsArray}
                  bookings={bookings}
                  categoryName="UtilisationofEvents"
                />
              ) : null}
            </AlternativelyVisible>
          </AlternativelyVisible>
          {/* This is the legend for the bar chart */}
          <div
            className="legend"
            style={{
              borderStyle: 'solid', borderWidth: '1px', padding: '10px',
            }}
          >
            <div className="bookedLegend" style={{ display: 'flex', alignItems: 'center' }}>
              <div className="square" style={{ height: '10px', width: '20px', backgroundColor: '#47FEB4' }}> </div>
              <div className="bookingLegend"> Booked </div>
            </div>
            <div className="bookedLegend" style={{ display: 'flex', alignItems: 'center' }}>
              <div className="square" style={{ height: '10px', width: '20px', backgroundColor: '#C0C0C0' }}> </div>
              <div className="bookingLegend"> Not Booked </div>
            </div>
          </div>
        </div>
        {/* This is the headers for event utilisation and space */}
        {selected && (
        <div
          className="Stats"
          style={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center',
          }}
        >
          <HeaderUtilisation
            event={selected}
          />
          <HeaderUtilisationBookings
            event={selected}
          />
          <HeaderAvailableSpace
            event={selected}
          />
        </div>
        )}
      </div>
    </div>
  );
}

CategoryUtilisation.propTypes = {
  analyticsEvents: PropTypes.shape().isRequired,
  analyticsEventBookings: PropTypes.shape().isRequired,
  analyticsEventLoading: PropTypes.bool.isRequired,
  analyticsEventFailed: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    analyticsEvents: state.analytics.analyticsEvents,
    analyticsEventBookings: state.analytics.analyticsEventBookings,
    analyticsEventLoading: state.analytics.analyticsEventLoading,
    analyticsEventFailed: state.analytics.analyticsEventFailed,
  };
}

export default connect(mapStateToProps)(CategoryUtilisation);
