import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import {
  FormControl, InputLabel,
} from '@material-ui/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import LoadingSection from '../../components/LoadingComponents/LoadingSection';
import * as companyServices from '../../services/companiesServices';
import {
  useCompany, usePendingChanges, useToast,
} from '../../hooks';
import { getCompanyInfo } from '../../reducers/companiesReducer';

const ImageConfiguration = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const toast = useToast();
  const company = useCompany();
  const [hoverLogo, setHoverLogo] = useState(false);
  const [hoverCover, setHoverCover] = useState(false);
  const claimantInformation = usePendingChanges();

  const { data: companyMedia, isLoading, isError } = useQuery({
    queryKey: ['company', company.id, 'media'],
    queryFn: async () => {
      const res = await companyServices.getCompanyMediaService(company.id);

      if (res.status !== 200) {
        toast.trigger({
          type: 'error',
          message: 'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us',
        });
        throw new Error('An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us');
      }

      return res.data;
    },
    enabled: !!company.id,
  });

  const mutation = useMutation({
    mutationFn: async (values) => {
      const res = await companyServices.updateCompanyMedia(company.id, values, {
        'Content-Type': 'multipart/form-data',
      });

      if (res.status !== 200) {
        throw new Error('Unable to update company media');
      }

      return res.data;
    },
    onSuccess: () => {
      toast.trigger({
        type: 'success',
        message: 'Your company info has been updated successfully!',
      });
      queryClient.invalidateQueries(['company', company.id, 'media']);
      dispatch(getCompanyInfo());
    },
    onError: () => {
      toast.trigger({
        type: 'error',
        message: 'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us.',
      });
    },
  });

  const onHover = (input) => {
    if (input === 'logo') {
      setHoverLogo(true);
    } else if (input === 'cover') {
      setHoverCover(true);
    }
  };

  const onLeave = (input) => {
    if (input === 'logo') {
      setHoverLogo(false);
    } else if (input === 'cover') {
      setHoverCover(false);
    }
  };

  if (isLoading) {
    return (<LoadingSection loadingText="Loading please wait..." />);
  }

  if (isError) {
    return (
      <div>
        <Typography variant="h6" style={{ marginBottom: '1rem' }}>Company Media configuration</Typography>
        <Typography variant="body2">
          An error occurred while attempting to fetch your company media.
          Please try again, if the issues persists please contact us.
        </Typography>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        logo: claimantInformation?.company?.logoUrl || companyMedia?.logoUrl || '',
        cover: claimantInformation?.company?.headerPhoto || companyMedia?.coverUrl || '',
      }}
      enableReinitialize
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        await mutation.mutateAsync(values);
        resetForm();
      }}
    >
      {({
        values, errors, touched, handleSubmit, setFieldValue, resetForm, handleBlur,
      }) => (

        <form
          onSubmit={handleSubmit}
          onReset={resetForm}
          style={{
            display: 'flex', flexDirection: 'column', gap: '0.75rem',
          }}
        >
          {/* Title */}
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>Company Media configuration</Typography>

          <div style={{ marginBottom: '1rem' }}>
            <Typography variant="body2">
              Please note, when entering your images below, please only use appropriate images.
            </Typography>
            <Typography variant="body2">
              We recommend an image size of 1920 x 1080. In most cases, your image should display
              correctly within these dimensions. Any images bigger or larger than this may appear
              off centre once uploaded.
            </Typography>
          </div>

          {/* Inputs */}
          <>
            <FormControl
              error={touched.logo && errors.logo}
              fullWidth
              style={{
                display: 'flex',
              }}
            >
              <InputLabel htmlFor="my-input">Company Logo</InputLabel>
              <label
                htmlFor="logo"
              >
                <div
                  title="Click to change logo"
                  onMouseEnter={() => onHover('logo')}
                  onMouseLeave={() => onLeave('logo')}
                  className="company-image-upload"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid rgb(163, 162, 160)',
                    height: '10rem',
                    width: '10rem',
                    marginTop: 50,
                    marginLeft: 50,
                  }}
                >
                  {!hoverLogo && (
                    values?.logo instanceof File
                    || claimantInformation?.company?.logoUrl
                    || companyMedia?.logoUrl
                  ) ? (
                    <img
                      src={values?.logo instanceof File ? URL.createObjectURL(values?.logo) : claimantInformation?.company?.logoUrl || `${companyMedia?.logoUrl}?${performance.now()}`}
                      alt="Company Logo"
                      style={{
                        objectFit: 'contain',
                        width: '250px',
                        height: '250px',
                        backgroundColor: 'white',
                        padding: '0px',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '85%',
                      }}
                    />
                    ) : (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <AddIcon color="primary" />
                        <span style={{ fontWeight: 800, color: 'rgb(163, 162, 160)' }}>Upload Logo</span>
                      </div>
                    )}
                  <input
                    type="file"
                    id="logo"
                    name="logo"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      if (e.target.files?.length > 0) {
                        setFieldValue('logo', e.target.files[0]);
                      }
                    }}
                    onBlur={handleBlur}
                  />
                </div>
              </label>
            </FormControl>
            <FormControl
              error={touched.coverUrl && errors.coverUrl}
              fullWidth
              style={{
                display: 'flex',
              }}
            >
              <InputLabel htmlFor="my-input">Company Header Image</InputLabel>
              <label
                htmlFor="cover"
              >
                <div
                  title="Click to change cover"
                  className="company-image-upload"
                  onMouseEnter={() => onHover('cover')}
                  onMouseLeave={() => onLeave('cover')}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid rgb(163, 162, 160)',
                    height: '10rem',
                    width: '20rem',
                    marginTop: 50,
                  }}
                >
                  { !hoverCover && (
                    values?.cover instanceof File
                    || claimantInformation?.company?.headerPhoto
                    || companyMedia?.coverUrl) ? (
                      <img
                        src={values?.cover instanceof File ? URL.createObjectURL(values?.cover) : claimantInformation?.company?.headerPhoto || `${companyMedia?.coverUrl}?${performance.now()}`}
                        alt="Company Header"
                        height="100%"
                        width="100%"
                      />
                    ) : (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <AddIcon color="primary" />
                        <span style={{ fontWeight: 800, color: 'rgb(163, 162, 160)' }}>Upload Header Image</span>
                      </div>
                    )}
                  <input
                    type="file"
                    id="cover"
                    name="cover"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      if (e.target.files?.length > 0) {
                        setFieldValue('cover', e.target.files[0]);
                      }
                    }}
                    onBlur={handleBlur}
                  />
                </div>
              </label>
            </FormControl>
          </>

          {/* Buttons */}
          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '0.75rem',
          }}
          >
            <Button
              type="reset"
              variant="contained"
              color="secondary"
              style={{ marginTop: '10px', marginBottom: '10px', width: 200 }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: '10px', marginBottom: '10px', width: 200 }}
            >
              Save
            </Button>
          </div>
        </form>

      )}
    </Formik>
  );
};

export default ImageConfiguration;
