/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import SiteSectionHeader from './SiteSectionHeader';
import FacilitiesGrid from './FacilitiesGrid';

const SitesWithFacilities = ({ sites }) => (
  <div style={{ margin: '40px' }}>
    {sites && sites.map((site) => (
      <>
        <SiteSectionHeader site={site} />
        <FacilitiesGrid
          data={site.facilities}
        />
      </>
    ))}
  </div>
);

SitesWithFacilities.propTypes = {
  sites: PropTypes.shape().isRequired,
};

export default SitesWithFacilities;
