import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import { Checkbox, Typography } from '@material-ui/core';
import * as helper from '../../../helpers/index';

const ScheduleTimePicker = (props) => {
  const { schedule, onChange } = props;
  const { start, end, day } = schedule;
  const [disabled, setDisabled] = useState({});
  const [checked, setChecked] = useState(false);
  const [alert, setAlert] = useState(false);

  const formattedStart = helper.createDateWithTime(
    moment(),
    start,
  ).startOf('minute').format();

  const formattedEnd = helper.createDateWithTime(
    moment(),
    end,
  ).format();

  useEffect(() => {
    setChecked(end === '23:59:59');
    setDisabled((prevDisabled) => ({
      ...prevDisabled,
      [schedule.id]: end === '23:59:59',
    }));
  }, [end, schedule.id]);

  const handleCheckbox = (value) => {
    setChecked(value);
    if (value) {
      onChange({
        day: schedule.day,
        end: '00:00:00',
      });
      setAlert(false);
    } else {
      onChange({
        day: schedule.day,
        end: '21:00:00',
      });
      setAlert(true);
    }
    setDisabled((prevDisabled) => ({
      ...prevDisabled,
      [schedule.id]: value,
    }));
  };

  return (
    <>
      <TimePicker
        value={formattedStart ? moment(formattedStart) : formattedStart}
        showSecond={false}
        minuteStep={5}
        clearIcon={null}
        allowEmpty
        onChange={(x) => onChange({
          day,
          start: moment(x).startOf('minute').format('HH:mm:ss'),
        })}
      />
      <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>-</div>

      <TimePicker
        value={formattedEnd ? moment(formattedEnd) : formattedEnd}
        showSecond={false}
        minuteStep={5}
        clearIcon={null}
        allowEmpty
        onChange={(x) => onChange({
          day,
          end: moment(x).startOf('minute').format('HH:mm:ss'),
        })}
        disabled={disabled[schedule.id]}
      />

      <Checkbox
        checked={checked}
        onChange={(e) => handleCheckbox(e.target.checked, schedule)}
        color="primary"
      />

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="caption">Closes at midnight</Typography>
        {alert && (
          <Typography variant="caption" style={{ color: 'red' }}>Check times before saving</Typography>
        )}
      </div>
    </>
  );
};
ScheduleTimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  schedule: PropTypes.shape().isRequired,
};

export default (ScheduleTimePicker);
