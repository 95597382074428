// REMOVE IGNORE
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import heroFootballPitchDay from '../../../images/heroFootballPitchDay.svg';

export default function ScrollableTabsButtonForce() {
  const [values, setValues] = useState({
    facilities: 1,
    weeks: 50,
    peakPrice: 50,
    offPeakPrice: 20,
    totalPeakSlots: 18,
    totalOffPeakSlots: 5,
    total: 0,
  });
  const {
    facilities, weeks, peakPrice, offPeakPrice, totalPeakSlots, totalOffPeakSlots, total,
  } = values;

  const handleInput = (e) => {
    const { id, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };
  const calculateTotal = useCallback(() => {
    const facilitiesByWeeks = parseInt(facilities, 10) * parseInt(weeks, 10);
    const peakUtilisationTotal = (totalPeakSlots * (parseInt(peakPrice, 10)));
    const offPeakUtilisationTotal = (totalOffPeakSlots * (parseInt(offPeakPrice, 10)));
    setValues((prevValues) => ({
      ...prevValues,
      total: (
        (parseInt(peakUtilisationTotal, 10) + parseInt(offPeakUtilisationTotal, 10))
      ) * facilitiesByWeeks,
    }));
  }, [facilities, weeks, peakPrice, offPeakPrice, totalPeakSlots, totalOffPeakSlots]);

  useEffect(() => {
    calculateTotal();
  }, [
    facilities, weeks, peakPrice, offPeakPrice, totalPeakSlots, totalOffPeakSlots, calculateTotal,
  ]);

  return (
    <>
      <div style={{
        display: 'flex', flexDirection: 'row', boxShadow: '0px 0px 4px #404040', borderRadius: '10px',
      }}
      >
        <div style={{ alignItems: 'center', alignSelf: 'center' }}>
          <div style={{ padding: '10px' }}>
            <h1>Facilities Breakdown</h1>
            <TextField
              variant="outlined"
              id="facilities"
              label="Number of bookable facilities"
              type="number"
              defaultValue={facilities}
              onChange={handleInput}
            />
          </div>
          <div style={{ padding: '10px' }}>
            <TextField
              variant="outlined"
              id="weeks"
              label="Weeks open per year"
              type="number"
              defaultValue={weeks}
              onChange={handleInput}
            />
          </div>
          <div style={{ padding: '10px' }}>
            <TextField
              variant="outlined"
              id="peakPrice"
              label="Slot peak price"
              type="number"
              defaultValue={peakPrice}
              onChange={handleInput}
            />
          </div>
          <div style={{ padding: '10px' }}>
            <TextField
              variant="outlined"
              id="offPeakPrice"
              label="Slot off-peak price"
              type="number"
              defaultValue={offPeakPrice}
              onChange={handleInput}
            />
          </div>
          <div style={{ padding: '10px' }}>
            <TextField
              variant="outlined"
              id="totalPeakSlots"
              label="Slots booked at peak price"
              type="number"
              defaultValue={totalPeakSlots}
              onChange={handleInput}
            />
          </div>
          <div style={{ padding: '10px' }}>
            <TextField
              variant="outlined"
              id="totalOffPeakSlots"
              label="Slots booked at off peak price"
              type="number"
              defaultValue={totalOffPeakSlots}
              onChange={handleInput}
            />
          </div>
        </div>
        <div style={{
          display: 'Flex', flexDirection: 'column', padding: '10px', maxWidth: '400px', marginLeft: '20px', backgroundColor: '#243b55', textAlign: 'center', borderTopRightRadius: '10px', borderBottomRightRadius: '10px',
        }}
        >
          <div>
            <h1 style={{ color: 'white' }}>
              <br />

              With
              <span style={{ color: '#47FEB4' }}> Pitchbooking </span>
              proccesses in place, your facilities should generate:
            </h1>
          </div>
          <div>
            <h1 style={{ color: '#47FEB4' }}>
              {!isNaN(total) ? <NumberFormat displayType="text" thousandSeparator prefix="£" value={total.toFixed(0)} style={{ fontSize: '1.8rem' }} /> : 0}
              {' '}
              <span style={{ color: 'white' }}> per annum </span>
            </h1>
          </div>
          <br />
          <br />
          <img src={heroFootballPitchDay} alt="test" style={{ padding: '0px' }} />
        </div>
      </div>
    </>
  );
}
