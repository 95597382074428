import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';
import * as facilityActions from '../../../../reducers/facilitiesReducer';
import PBButton from '../../../../components/Button';
import SchedulesTable from '../../../schedules/SchedulesTable';
import ScheduleAddNewSetOpeningTimesDialog from '../../../schedules/components/ScheduleAddNewSetOpeningTimesDialog';

const styles = () => ({
  root: {
    minWidth: 1000,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});

class SetOpeningTimesDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      step: 0,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleSetOpeningTimes = () => {
    const { updateNewFacilityStore, fullWeekSchedules, savedNewOpeningTimes } = this.props;
    if (savedNewOpeningTimes) {
      updateNewFacilityStore(
        { schedules: fullWeekSchedules, newSchedules: true },
      );
    }
    this.handleRequestClose();
  }

  handleResumesAtToggle = (option) => {
    const { updateNewFacilityStore } = this.props;
    if (option === 'PUBLIC') {
      updateNewFacilityStore(
        { live: true, visibleStatus: option },
      );
    } else if (option === 'INTERNAL') {
      updateNewFacilityStore(
        { live: false, visibleStatus: option },
      );
    }
  }

  render() {
    const isMobile = window.innerWidth < 768;
    const {
      classes, schedules, addSelectedScheduleGroupToNewFacility, savedNewOpeningTimes,
      fullWeekSchedules,
    } = this.props;
    const { open, step } = this.state;
    const location = window.location.pathname.split('/').pop();

    return (
      <div>
        <PBButton color="secondary" variant="outlined" onClick={() => this.handleClickOpen()}>
          Set opening times
        </PBButton>
        <Dialog
          open={open}
          fullScreen={isMobile}
          fullWidth
          classes={{ paper: classes.root }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>Set opening times</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <DialogContent>
            <div>
              {location !== 'facilities' && (
                <ScheduleAddNewSetOpeningTimesDialog
                  schedules={schedules}
                />
              )}
              <ConditionallyVisible condition={savedNewOpeningTimes}>
                <div style={{
                  margin: '20px',
                  padding: '20px',
                  borderRadius: '15px',
                  border: '1px solid #e0e0e0',
                  backgroundColor: '#f8f8f8',
                }}
                >
                  <Typography variant="h6" gutterBottom>
                    New Schedule Group
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '90px', marginRight: '10px' }}>
                      Day
                    </div>
                    <div style={{ width: '70px', marginRight: '10px' }}>
                      Start
                    </div>
                    <div style={{ marginRight: '10px' }}>
                      End
                    </div>
                  </div>
                  {fullWeekSchedules.map((element) => (
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: '90px', marginRight: '10px' }}>
                        {moment().day(element.day).format('dddd')}
                      </div>
                      <div style={{ width: '70px', marginRight: '10px' }}>
                        {moment(element.start).format('LT')}
                      </div>
                      <div style={{ marginRight: '10px' }}>
                        {moment(element.end).format('LT')}
                      </div>
                    </div>
                  ))}
                </div>
              </ConditionallyVisible>
              <SchedulesTable
                schedules={schedules}
                hideEdit
                addSelectedScheduleGroupToNewFacility={addSelectedScheduleGroupToNewFacility}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary" variant="outlined">
              Close
            </Button>
            <ConditionallyVisible condition={step === 1}>
              <Button onClick={() => this.decreaseStep()} color="secondary" variant="outlined">
                Back
              </Button>
            </ConditionallyVisible>
            <Button
              onClick={() => this.handleSetOpeningTimes()}
              color="primary"
              variant="contained"
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
SetOpeningTimesDialog.propTypes = {
  schedules: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  addSelectedScheduleGroupToNewFacility: PropTypes.func.isRequired,
  updateNewFacilityStore: PropTypes.func.isRequired,
  savedNewOpeningTimes: PropTypes.func.isRequired,
  fullWeekSchedules: PropTypes.shape().isRequired,

};

function mapStateToProps(state) {
  const { facilities, sites } = state;
  const { schedules, savedNewOpeningTimes, fullWeekSchedules } = state.schedules;
  const { newFacility } = facilities;
  const { sitesFacilities } = sites;
  return {
    newFacility,
    sites: sitesFacilities,
    schedules,
    savedNewOpeningTimes,
    fullWeekSchedules,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateNewFacilityStore: (value) => dispatch(facilityActions.updateNewFacilityStore(
    value,
  )),
  addSelectedScheduleGroupToNewFacility: (value) => dispatch(
    facilityActions.addSelectedScheduleGroupToNewFacility(value),
  ),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withStyles(styles())(SetOpeningTimesDialog));
