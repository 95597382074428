import { Card } from '@pitchbooking-dev/pb-shared/lib/components/common';
import React from 'react';

const WelcomeConfirmed = () => (
  <div style={{
    textAlign: 'center',
    width: '70%',
    margin: 'auto',
    maxWidth: '800px',
    padding: '20px',
    paddingTop: '40px',
  }}
  >
    <Card>
      <Card.Body>
        <h1>Welcome to PitchBooking!</h1>
        <p>Thank you for adding your payment details.</p>
        <p>We&apos;re excited to have you on board.</p>
        <a href="https://meetings.hubspot.com/lyndon-mccullough" target="_blank" rel="noreferrer">Book a call to continue</a>
      </Card.Body>
    </Card>
  </div>
);

export default WelcomeConfirmed;
