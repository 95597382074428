export const PAYOUTS_RETRIEVAL_REQUESTED = 'PAYOUTS_RETRIEVAL_REQUESTED';
export const PAYOUTS_RETRIEVAL_SUCCEEDED = 'PAYOUTS_RETRIEVAL_SUCCEEDED';
export const PAYOUTS_RETRIEVAL_FAILED = 'PAYOUTS_RETRIEVAL_FAILED';

export const PAYOUT_RETRIEVAL_REQUESTED = 'PAYOUT_RETRIEVAL_REQUESTED';
export const PAYOUT_RETRIEVAL_SUCCEEDED = 'PAYOUT_RETRIEVAL_SUCCEEDED';
export const PAYOUT_RETRIEVAL_FAILED = 'PAYOUT_RETRIEVAL_FAILED';

export const RECALCULATE_PAYOUT_REQUESTED = 'RECALCULATE_PAYOUT_REQUESTED';
export const RECALCULATE_PAYOUT_SUCCEEDED = 'RECALCULATE_PAYOUT_SUCCEEDED';
export const RECALCULATE_PAYOUT_FAILED = 'RECALCULATE_PAYOUT_FAILED';

export const initialState = {
  payouts: [],
  payout: null,
  recalculatingPayout: false,
};

export const requestPayouts = () => (
  { type: PAYOUTS_RETRIEVAL_REQUESTED }
);
export const succeedPayoutsRetrieval = (payouts) => (
  { type: PAYOUTS_RETRIEVAL_SUCCEEDED, payouts }
);
export const failPayoutsRetrieval = () => (
  { type: PAYOUTS_RETRIEVAL_FAILED }
);

export const requestPayout = (payoutId) => (
  { type: PAYOUT_RETRIEVAL_REQUESTED, payoutId }
);
export const succeedPayoutRetrieval = (payout) => (
  { type: PAYOUT_RETRIEVAL_SUCCEEDED, payout }
);
export const failPayoutRetrieval = () => (
  { type: PAYOUT_RETRIEVAL_FAILED }
);

export const recalculatePayout = (payoutId) => (
  { type: RECALCULATE_PAYOUT_REQUESTED, payoutId }
);
export const succeedRecalculatePayout = (payouts) => (
  { type: RECALCULATE_PAYOUT_SUCCEEDED, payouts }
);
export const failRecalculatePayout = () => (
  { type: RECALCULATE_PAYOUT_FAILED }
);

export default (state = initialState, action) => {
  switch (action.type) {
    case PAYOUTS_RETRIEVAL_SUCCEEDED:
      return {
        ...state,
        payouts: action.payouts,
      };
    case PAYOUT_RETRIEVAL_SUCCEEDED:
      return {
        ...state,
        payout: action.payout,
      };
    case RECALCULATE_PAYOUT_SUCCEEDED:
      return {
        ...state,
        payouts: action.payouts,
        recalculatingPayout: false,
      };
    case RECALCULATE_PAYOUT_REQUESTED:
      return {
        ...state,
        recalculatingPayout: true,
      };
    default:
      return state;
  }
};
