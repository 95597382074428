import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import MenuDrawerNestedLink from './MenuDrawerNestedLink';

const renderChildrenInNestedLinks = (children) => children.map((child) => (
  <MenuDrawerNestedLink key={Math.random()}>
    {child}
  </MenuDrawerNestedLink>
));

const MenuDrawerLinkCollapsedMenu = ({
  children, collapsed,
}) => (
  <Collapse in={collapsed} timeout="auto" unmountOnExit>
    <List component="div" disablePadding>
      {renderChildrenInNestedLinks(children)}
    </List>
  </Collapse>
);

MenuDrawerLinkCollapsedMenu.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.shape(),
  ]).isRequired,
};

export default MenuDrawerLinkCollapsedMenu;
