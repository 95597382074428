import React from 'react';
import CategoryBookings from './CategoryBookings';
import CategoryRevenue from './CategoryRevenue';
import CategoryRevenueByEvent from './CategoryRevenueByEvent';
import CategoryUtilisation from './CategoryUtilisation';

export function CategoriesWrapper() {
  return (
    <div className="categoriesWrapper">
      <CategoryBookings />
      <CategoryRevenue />
      <CategoryRevenueByEvent />
      <CategoryUtilisation />
    </div>
  );
}
