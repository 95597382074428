// @flow weak

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
} from '@material-ui/core';
import * as actions from '../../../reducers/usersReducer';

// eslint-disable-next-line react/prefer-stateless-function
class PasswordChangedSuccessfullyDialog extends Component {
  render() {
    const {
      passwordChangedSuccessfullyDialogOpen,
      closePasswordChangedSuccesfullyDialog,
    } = this.props;
    return (
      <Dialog className="access-denied-dialog" open={passwordChangedSuccessfullyDialogOpen} onClose={() => closePasswordChangedSuccesfullyDialog(false)}>
        <DialogTitle>
          Password successfully changed
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have succesfully reset your password, you can now login in with your new password.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Button
            style={{
              margin: '5px auto',
              width: '50%',
              borderRadius: '25px',
              color: '#ffffff',
              backgroundColor: '#47FEB4',
            }}
            variant="contained"
            onClick={() => closePasswordChangedSuccesfullyDialog(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

PasswordChangedSuccessfullyDialog.propTypes = {
  passwordChangedSuccessfullyDialogOpen: PropTypes.bool.isRequired,
  closePasswordChangedSuccesfullyDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  passwordChangedSuccessfullyDialogOpen: state.users.passwordChangedSuccessfullyDialogOpen,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closePasswordChangedSuccesfullyDialog: (bool) => (
    actions.closePasswordChangedSuccesfullyDialog(bool)),

}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PasswordChangedSuccessfullyDialog);
