import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import IconButton from '@material-ui/core/IconButton';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';

const NotesCard = ({
  notes, updateNote, userId,
}) => {
  const [note, setNote] = useState(notes && notes.length > 0 ? notes[0].value : '');
  const [isEditing, setIsEditing] = useState(false);

  const onSubmit = () => {
    updateNote(userId, note);
    setIsEditing(false);
  };

  useEffect(() => { setNote(notes && notes.length > 0 ? notes[0].value : ''); }, [notes]);

  return (
    <div style={{ display: 'flex', margin: '1rem 0rem' }}>
      <PBInput
        id="note"
        type="textarea"
        label="Note"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        disabled={!isEditing}
      />
      <AlternativelyVisible condition={isEditing}>
        <>
          <IconButton
            onClick={() => setIsEditing(!isEditing)}
            aria-label="edit"
            component="label"
          >
            <CancelOutlined />
          </IconButton>
          <IconButton
            onClick={() => onSubmit()}
            aria-label="save"
            component="label"
            type="submit"
          >
            <CheckCircleOutlineIcon />
          </IconButton>
        </>
        <IconButton
          onClick={() => setIsEditing(!isEditing)}
          aria-label="edit"
          component="label"
        >
          <EditIcon />
        </IconButton>
      </AlternativelyVisible>
    </div>
  );
};

NotesCard.propTypes = {
  notes: PropTypes.arrayOf().isRequired,
  userId: PropTypes.string.isRequired,
  updateNote: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  user: ownProps.user,
  subscriptionUser: state.subscriptions.userDetailsForSubscription,
});

export default connect(mapStateToProps, null)(NotesCard);
