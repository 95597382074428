/* eslint-disable react/prefer-stateless-function */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import FacilityTreeDialog from './FacilityTreeDialog';
import * as facilitiesActions from '../../../../reducers/facilitiesReducer';
import EditSubFacility from './EditSubFacility';

class DetailsConfiguration extends React.Component {
  render() {
    const { facilities, pbUser } = this.props;
    const { selectedFacility } = facilities;
    const { facilityTree } = selectedFacility;
    let facilityTreeData = {};
    if (facilityTree && facilityTree.children && facilityTree.children.length > 0) {
      facilityTreeData = facilityTree;
    }
    return (
      <div style={{ display: 'flex', 'flex-direction': 'column' }}>
        <div className="table-section">
          {selectedFacility.subFacilities ? (
            <>
              {selectedFacility.subFacilities.length > 0 ? (
                <div style={{ display: 'flex', maxWidth: '300px', flexWrap: 'wrap' }}>
                  {selectedFacility.subFacilities.map((element) => (
                    <EditSubFacility subFacilityId={element.id} subFacilityName={element.name} />
                  ))}
                </div>

              ) : <>No sub facilities found</>}
            </>
          ) : <>Loading...</>}
        </div>
        <ConditionallyVisible condition={pbUser}>
          <FacilityTreeDialog data={facilityTreeData} />
        </ConditionallyVisible>
      </div>
    );
  }
}

DetailsConfiguration.propTypes = {
  selectedFacility: PropTypes.shape().isRequired,
  facilities: PropTypes.shape().isRequired,
  pbUser: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    facilities, companies, accessRestrictions, users,
  } = state;
  const { currentUser } = users;
  const pbUser = currentUser && currentUser.email && currentUser.email.includes('@pitchbooking.com');
  return {
    facilities,
    companies,
    accessRestrictions,
    pbUser,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateSelectedFacilityStore: (id) => dispatch(facilitiesActions.updateSelectedFacilityStore(id)),
  requestSelectedFacilityUpdate: (facilityId, facilityData) => dispatch(
    facilitiesActions.requestSelectedFacilityUpdate(facilityId, facilityData),
  ),
  backToFacilities: () => dispatch(push('/facilities')),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailsConfiguration);
