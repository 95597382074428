import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PaymentIcon from '@material-ui/icons/Payment';
import RevenueCalculator from './revenueCalculator';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      style={{ display: 'flex' }} // potentially issue and needs removed
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonForce() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="secondary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          style={{ backgroundColor: '#fff', display: 'flex' }}
        >
          <Tab label="Revenue Calculator" icon={<PaymentIcon />} />
          {/* <Tab label="No Show Calculator" icon={<AccessTimeIcon />} /> */}
          {/* <Tab label="Configuration" icon={<InfoOutlinedIcon />}
          style={facility.subFacilities && facility.subFacilities.length > 0 ? { display: 'block' }
          : { display: 'none' }} /> */}
          {/* <Tab label="Pricing" icon={<InfoOutlinedIcon />} /> */}
          {/* <Tab label="Misc" icon={<SettingsOutlinedIcon />} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <RevenueCalculator />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>No shows</div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* <SetupConfiguration facility={facility} /> */}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {/* <MiscConfiguration facility={facility} /> */}
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
    </div>
  );
}
