import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: 20,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function MultipleSelect(props) {
  const classes = useStyles();
  const { facilities, onUpdate, selectedFacilities } = props;
  const handleChange = (event) => {
    onUpdate(event.target.value);
  };

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>Facilities</InputLabel>
        <Select
          labelId="mutiple-checkbox-label"
          id="mutiple-checkbox"
          label="Facilities"
          multiple
          value={selectedFacilities}
          onChange={handleChange}
          renderValue={(selected) => (selected.length > 0 ? `${selected.length} Selected` : 'All')}
        >
          {facilities.map((facility) => (
            <MenuItem key={facility.id} value={facility.id}>
              <Checkbox checked={selectedFacilities.indexOf(facility.id) > -1} />
              <ListItemText primary={facility.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

MultipleSelect.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  facilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedFacilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
