// @flow weak

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { requestTimetablePriceRetrieval } from '@pitchbooking-dev/pb-shared/lib/actions/timetableActions';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import * as reservationsActions from '../reducers/reservationsReducer';

class AgeGroupRadio extends Component {
  componentDidMount() {
    const { ageGroupBookingModifiers, updateSelectedAgeGroup } = this.props;
    updateSelectedAgeGroup(ageGroupBookingModifiers[0].id);
  }

  handleChange = (event, value) => {
    const { updateSelectedAgeGroup, doRequestTimetablePriceRetrieval } = this.props;
    updateSelectedAgeGroup(value);
    doRequestTimetablePriceRetrieval();
  };

  render() {
    const { selectedAgeGroup, ageGroupBookingModifiers } = this.props;

    return (
      <FormControl component="fieldset" required>
        <FormLabel component="legend">Age Group</FormLabel>
        <RadioGroup
          id="age-group-selector"
          defaultValue={ageGroupBookingModifiers[0].id}
          value={selectedAgeGroup}
          onChange={this.handleChange}
        >
          {ageGroupBookingModifiers.map((mod) => (
            <FormControlLabel
              value={mod.id}
              control={<Radio />}
              label={mod.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
}

AgeGroupRadio.propTypes = {
  selectedAgeGroup: PropTypes.string.isRequired,
  updateSelectedAgeGroup: PropTypes.func.isRequired,
  doRequestTimetablePriceRetrieval: PropTypes.func.isRequired,
  ageGroupBookingModifiers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

function mapStateToProps(state) {
  const { selectedAgeGroup } = state.reservation;
  const { ageGroupBookingModifiers } = state.facilities.selectedFacility;

  return { selectedAgeGroup, ageGroupBookingModifiers };
}

const mapDispatchToProps = (dispatch) => ({
  updateSelectedAgeGroup: (selectedAgeGroup) => dispatch(
    reservationsActions.updateSelectedAgeGroup(selectedAgeGroup),
  ),
  doRequestTimetablePriceRetrieval: () => dispatch(requestTimetablePriceRetrieval()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgeGroupRadio);
