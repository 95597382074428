import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@material-ui/core';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { Alert } from '@material-ui/lab';
import EventFormForm from '../events/components/EventFormForm';
import * as formActions from '../../reducers/formsReducer';

const EditFormDialog = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const {
    values, formId, name, facilityId,
  } = props;

  const title = formId ? 'Edit Form' : 'Create Form';
  const formValues = values;
  const initialValues = {
    form: {
      formData: formValues || [],
    },
    name: name || '',
  };
  return (
    <div>
      <Button variant="contained" color="secondary" aria-label="edit" onClick={() => setOpen(true)}>
        {title}
      </Button>
      <Dialog
        open={open}
      >
        <DialogTitle>{title}</DialogTitle>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              const { form, name } = values;
              const { formData } = form;

              // Check each form name is unique
              const isNameUnique = formData.every(
                (item, index, array) => array.findIndex(
                  (element) => element.name === item.name,
                ) === index,
              );

              // Get repeated field names
              const repeatedNames = formData.filter(
                (item, index, array) => array.findIndex(
                  (element) => element.name === item.name,
                ) !== index,
              ).map((item) => item.name);

              if (!isNameUnique) {
                setAlertMessage(`Each field name must be unique! Remove/update one of the following field name(s) to continue: ${repeatedNames.join(', ')} `);
                setAlert(true);
              } else {
                dispatch(formActions.updateFormRequest(formId, formData, name, facilityId));
                setAlert(false);
                setAlertMessage('');
                setOpen(false);
              }
            } catch (err) {
              console.error(err);
            }
            setSubmitting(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            touched,
            values,
          }) => (
            <Form>
              <DialogContent style={{ width: '600px' }}>
                <PBInput
                  id="name"
                  type="text"
                  label="Form Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  isError={touched.name && errors.name}
                  errorMessage={touched.name && errors.name}
                />

                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  marginTop: '1rem',
                }}
                >
                  {alert && (
                    <Alert severity="error">{alertMessage}</Alert>
                  )}

                  <EventFormForm />
                </div>

              </DialogContent>
              <DialogActions style={{ display: 'flex', gap: '1.2rem' }}>
                <Button
                  onClick={() => {
                    setOpen(false);
                    setAlert(false);
                    setAlertMessage('');
                  }}
                  color="secondary"
                  variant="outlined"
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={values.form.formData.length <= 0}
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

EditFormDialog.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  formId: PropTypes.string.isRequired,
  name: PropTypes.string,
  facilityId: PropTypes.string,
};

EditFormDialog.defaultProps = {
  name: '',
  facilityId: null,
};

export default EditFormDialog;
