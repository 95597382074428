/* eslint-disable react/prop-types */
import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Typography } from '@material-ui/core';

const ImageInput = ({
  id, name, label, touched, isError, errorMessage, onChange, value, uploadLabel, ...props
}) => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }}
  >
    <Typography color={isError ? 'error' : 'inherit'} variant="body1" gutterBottom>
      {label}
    </Typography>

    <label
      htmlFor={name || id}
      style={{
        maxWidth: 'fit-content',
      }}
    >
      <div
        title="Click to change logo"
        style={{
          border: '1px solid rgb(163, 162, 160)',
          height: '10rem',
          width: '10rem',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0.5rem',
        }}
      >
        {value ? (
          <img
            src={value instanceof File ? URL.createObjectURL(value) : null}
            alt="Company Logo"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <AddIcon color="primary" />
            <span style={{ textAlign: 'center', fontWeight: 800, color: 'rgb(163, 162, 160)' }}>
              {uploadLabel}
            </span>
          </div>
        )}
        <input
          id={id}
          name={name || id}
          type="file"
          accept={props.accept || 'image/*'}
          style={{ display: 'none' }}
          onChange={onChange}
          {...props}
        />
      </div>
    </label>

    {isError && (
      <Typography
        color="error"
        variant="body1"
        style={{
          fontSize: '0.75rem',
          marginTop: '0.25rem',
        }}
      >
        {errorMessage}
      </Typography>
    )}

  </div>
);

export default ImageInput;
