import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import EditSiteDialog from './editSite/EditSiteDialog';
import { usePendingChanges } from '../../../hooks';

const SiteSectionHeader = ({ site }) => {
  const pending = usePendingChanges()?.sites?.[site.id] ?? {};

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1>{pending.name ?? site.name}</h1>
        <EditSiteDialog
          site={{
            ...site,
            ...pending,
          }}
        />
      </div>
      <Divider />
    </>
  );
};

SiteSectionHeader.propTypes = {
  site: PropTypes.shape().isRequired,
};

export default SiteSectionHeader;
