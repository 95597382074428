import {
  takeEvery, call, put, select,
} from 'redux-saga/effects';
import moment from 'moment';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  getAmenitiesService,
  getFacilitiesService,
  updateFacilityService,
  createFacilityService,
  createAmenityService,
  createFacilityAmenitiesService,
  deleteFacilityAmenitiesService,
} from '../services/facilitiesServices';
import * as actions from '../reducers/facilitiesReducer';
import * as sitesActions from '../reducers/sitesReducer';
import { handleSagaError } from './helperSaga';
import * as companyActions from '../reducers/companiesReducer';
import { getCompanyFacility } from '../services/companiesServices';

const defaultSchedule = [
  { day: '1', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
  { day: '2', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
  { day: '3', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
  { day: '4', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
  { day: '5', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
  { day: '6', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
  { day: '0', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
];

function* getFacilitiesSaga(companyId) {
  try {
    const response = yield call(getFacilitiesService, companyId);
    if (!response.error) {
      yield put(actions.succeedFacilitiesRetrieval(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getFacilitiesSaga');
  }
}

function* getAmenitiesSaga(companyId, action) {
  const { facilityId } = action;
  try {
    const response = yield call(getAmenitiesService, companyId, facilityId);
    if (!response.error) {
      const formattedResponse = [];
      response.data.forEach((amenity) => {
        formattedResponse.push({ ...amenity, value: false });
      });
      yield put(actions.succeedAmenitiesRetrieval(formattedResponse));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getAmenitiesSaga');
  }
}

function* getFacilitySaga(companyId, action) {
  try {
    const { facilityId } = action;
    if (!facilityId) throw new Error('Facility ID not provided');
    const response = yield call(getCompanyFacility, companyId, facilityId);
    if (!response.error) {
      yield put(actions.updateSelectedFacility(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getFacilitySaga');
  }
}

function* updateSelectedFacilitySaga(companyId, facilitiesAction) {
  const { facilityData } = facilitiesAction;
  const {
    allowedStartQuarterZero, allowedStartQuarterFifteen,
    allowedStartQuarterThirty, allowedStartQuarterFortyFive,
    weekendCutoffFlag, weekday, weekendCutoffTime, weekendCutoff,
  } = facilityData;

  const newAllowedStartQuarter = [];
  if (allowedStartQuarterZero) {
    newAllowedStartQuarter.push(0);
  }
  if (allowedStartQuarterFifteen) {
    newAllowedStartQuarter.push(15);
  }
  if (allowedStartQuarterThirty) {
    newAllowedStartQuarter.push(30);
  }
  if (allowedStartQuarterFortyFive) {
    newAllowedStartQuarter.push(45);
  }

  // Weekend Cut Off
  let newWeekendCutOff;

  if (weekendCutoffFlag) {
    newWeekendCutOff = `${moment().weekday(weekday).format('dddd')},${moment(weekendCutoffTime).format('HH:mm')}`;
  } else {
    newWeekendCutOff = weekendCutoff;
  }
  const formattedFacilityData = {
    name: facilitiesAction.facilityData.name,
    description: facilitiesAction.facilityData.description,
    accessInstructions: facilitiesAction.facilityData.accessInstructions,
    smsAccessInstructions: facilitiesAction.facilityData.smsAccessInstructions,
    sport: facilitiesAction.facilityData.sport,
    size: facilitiesAction.facilityData.size,
    surface: facilitiesAction.facilityData.surface,
    setting: facilitiesAction.facilityData.setting,
    combineSlots: facilitiesAction.facilityData.combineSlots,
    weekendCutoff: newWeekendCutOff,
    minDuration: facilitiesAction.facilityData.minDuration,
    minHoursToBooking: facilitiesAction.facilityData.minHoursToBooking,
    maxDaysToBooking: facilitiesAction.facilityData.maxDaysToBooking,
    definedStartTimes: facilitiesAction.facilityData.definedStartTimes,
    allowedStartQuarters: newAllowedStartQuarter,
    location: facilitiesAction.facilityData.location,
    locationDescription: facilitiesAction.facilityData.locationDescription,
    locationChanged: facilitiesAction.facilityData.locationChanged,
    internal: facilitiesAction.facilityData.internal,
    audit: facilitiesAction.facilityData.audit,
    attributes: facilitiesAction.facilityData.attributes,
    costCode: facilitiesAction.facilityData.costCode,
    taxExemptCostCode: facilitiesAction.facilityData.taxExemptCostCode,
    maxBookingsPerDay: facilitiesAction.facilityData.maxBookingsPerDay,
    bookingInstructions: facilitiesAction.facilityData.bookingInstructions,
    allowedDurations: facilitiesAction.facilityData.allowedDurations,
    taxExempt: facilitiesAction.facilityData.taxExempt,
  };

  // if start quarters not updated or has no value do not send it
  if (newAllowedStartQuarter.length === 0) {
    delete formattedFacilityData.allowedStartQuarters;
  }

  try {
    const response = yield call(
      updateFacilityService,
      companyId,
      facilitiesAction.facilityId,
      formattedFacilityData,
    );
    if (!response.error) {
      yield put(actions.succceedFacilityUpdate(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'updateSelectedFacilitySaga');
    yield put(actions.requestFacilitiesRetrieval());
  }
}

function* updateFacilityStatusSaga(companyId, facilitiesAction) {
  try {
    const response = yield call(
      updateFacilityService,
      companyId,
      facilitiesAction.facilityId,
      facilitiesAction.facilityData,
    );
    if (!response.error) {
      yield put(actions.succceedFacilityStatusUpdate());
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'updateFacilityStatusSaga');
    yield put(actions.requestFacilitiesRetrieval());
  }
}

function* createFacilitySaga(companyId) {
  const state = yield select();
  const { newFacility } = state.facilities;
  if (newFacility.schedules.length <= 0) {
    newFacility.newSchedules = true;
    newFacility.schedules = defaultSchedule;
  }

  const formattedFacility = { newFacility };
  if (companyId) {
    try {
      const response = yield call(createFacilityService, companyId, formattedFacility);
      if (!response.error) {
        yield put(actions.createFacilitySucceeded(response.data));
        yield put(sitesActions.requestSitesWithFacilitiesRetrieval());
        yield put(companyActions.getCompanyInfo());
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'createFacilitySaga');
      yield put(actions.createFacilityFailed(error.error));
    }
  }
}

function* createAmenitySaga(companyId) {
  const state = yield select();
  const { newAmenity } = state.facilities;
  const formattedFacility = { newAmenity };
  if (companyId) {
    try {
      const response = yield call(createAmenityService, companyId, formattedFacility);
      if (!response.error) {
        yield put(actions.createAmenitySucceeded(response.data));
        yield put(sitesActions.requestSitesWithFacilitiesRetrieval());
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'createAmenitySaga');
      yield put(actions.createAmenityFailed(error.error));
    }
  }
}

function* createFacilityAmenitiesSaga(companyId, action) {
  const state = yield select();
  const { selectedFacility } = state.facilities;
  const { facilityAmenities, forcible } = action;

  if (companyId) {
    try {
      const response = yield call(
        createFacilityAmenitiesService, companyId, { facilityAmenities, forcible },
      );
      if (!response.error) {
        yield put(actions.createFacilityAmenitiesSucceeded(response.data));
        yield put(actions.requestFacilityRetrieval(selectedFacility.id));
        yield put(sessionActions.updateSuccessfulSnackbar('Facility amenity creation successful.', true));
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'createFacilityAmenitiesSaga');
      yield put(actions.createFacilityFailed(error.error));
    }
  }
}

function* deleteFacilityAmenitiesSaga(companyId, action) {
  const state = yield select();
  const { selectedFacility } = state.facilities;
  const { facilityAmenities } = action;
  const body = {
    facilityAmenities,
  };
  try {
    const response = yield call(deleteFacilityAmenitiesService, companyId, body);
    if (!response.error) {
      yield put(sessionActions.updateSuccessfulSnackbar('Facility amenities successfully deleted.', true));
      yield put(actions.requestFacilityRetrieval(selectedFacility.id));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'deleteFacilityAmenitiesSaga');
    yield put(sessionActions.updateErrorSnackbar(`Facility amenities deletion failed : ${error.error}`, true));
  }
}

function* updateSubFacilitySaga(companyId, facilitiesAction) {
  const formattedFacilityData = {
    name: facilitiesAction.facilityData.name,
  };
  try {
    const response = yield call(
      updateFacilityService,
      companyId,
      facilitiesAction.facilityId,
      formattedFacilityData,
    );
    if (!response.error) {
      yield put(actions.succceedSubFacilityUpdate(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'updateSubFacilitySaga');
    yield put(actions.requestFacilitiesRetrieval());
  }
}

// Watchers
export function* getFacilitiesWatcher(companyId) {
  yield takeEvery(actions.FACILITIES_RETRIEVAL_REQUESTED, getFacilitiesSaga, companyId);
}
export function* getAmenitiesWatcher(companyId) {
  yield takeEvery(actions.AMENITIES_RETRIEVAL_REQUESTED, getAmenitiesSaga, companyId);
}
export function* getFacilityWatcher(companyId) {
  yield takeEvery(actions.FACILITY_RETRIEVAL_REQUESTED, getFacilitySaga, companyId);
}
export function* updateFacilityWatcher(companyId) {
  yield takeEvery(actions.SELECTED_FACILITY_UPDATE_REQUESTED,
    updateSelectedFacilitySaga, companyId);
}
export function* updateSelectedSubFacilityWatcher(companyId) {
  yield takeEvery(actions.SUB_FACILITY_UPDATE_REQUESTED, updateSubFacilitySaga, companyId);
}
export function* facilityStatusToggledWatcher(companyId) {
  yield takeEvery(actions.FACILITY_STATUS_TOGGLED, updateFacilityStatusSaga, companyId);
}
export function* createFacilityWatcher(companyId) {
  yield takeEvery(actions.CREATE_FACILITY_REQUESTED, createFacilitySaga, companyId);
}
export function* createAmenityWatcher(companyId) {
  yield takeEvery(actions.CREATE_AMENITY_REQUESTED, createAmenitySaga, companyId);
}
export function* createFacilityAmenitiesWatcher(companyId) {
  yield takeEvery(actions.CREATE_FACILITY_AMENITIES_REQUESTED,
    createFacilityAmenitiesSaga, companyId);
}
export function* deleteFacilityAmenitiesWatcher(companyId) {
  yield takeEvery(actions.REMOVE_FACILITY_AMENITIES_REQUESTED,
    deleteFacilityAmenitiesSaga, companyId);
}
