import React from 'react';
import PropTypes from 'prop-types';
import SignUpSection from '@pitchbooking-dev/pb-shared/lib/components/signUp';
import '../../styles/SignUpAccount.css';

const Signup = ({ match }) => {
  const { params } = match;
  const { token } = params;

  const isLoggedIn = localStorage.getItem('PBtoken');

  if (isLoggedIn) {
    localStorage.removeItem('PBtoken');
    window.location.reload();
    return null;
  }

  return (
    <div className="background">
      <div className="sign-up-container">
        <SignUpSection
          token={token}
          recaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
        />
      </div>
    </div>
  );
};

Signup.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default Signup;
