/*
  SettingsModalFacilities represents a list of checkboxes that
  allows the user to change the currently selected facilities.

  If the user makes changes, they are pushed to parent (Settings Modal)
  state using setParentState (this.props.setParentState).
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class SettingsModalFacilities extends React.Component {
  constructor(props) {
    super(props);

    // Zip together facilityIDs and facilityNames.
    const facilityIDsAndNames = [];
    const { initialAnalyticsFacilities } = this.props;
    const { facilityIDs, facilityNames } = initialAnalyticsFacilities;
    facilityIDs.forEach((facilityID, index) => {
      facilityIDsAndNames.push(
        [facilityID, facilityNames[index]],
      );
    });

    this.state = {
      facilityIDsAndNames,
    };
  }

  /*
    Fetch the value of selection and push to parent state.
  */
  handleFacilityToggle(selectedFacilityID, selectedFacilityName) {
    const { value, setParentState } = this.props;

    if (!value.facilityIDs.includes(selectedFacilityID)) {
      const newLocalFacilityIDs = [...value.facilityIDs, selectedFacilityID];
      const newLocalFacilityNames = [...value.facilityNames, selectedFacilityName];
      setParentState('localFacilities', {
        facilityIDs: newLocalFacilityIDs,
        facilityNames: newLocalFacilityNames,
      });
    } else {
      const newLocalFacilityIDs = [];
      const newLocalFacilityNames = [];
      value.facilityIDs.forEach((facilityID, index) => {
        if (facilityID !== selectedFacilityID) {
          newLocalFacilityIDs.push(facilityID);
          newLocalFacilityNames.push(value.facilityNames[index]);
        }
      });
      setParentState('localFacilities', {
        facilityIDs: newLocalFacilityIDs,
        facilityNames: newLocalFacilityNames,
      });
    }
  }

  render() {
    const { value } = this.props;
    const { facilityIDsAndNames } = this.state;
    return (
      <div className="settingsModalCard" id="settingsModalCardFacilities">
        <TableContainer style={{ boxShadow: 'none', border: '1px #00000010 solid', borderBottom: 'none' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ color: 'rgba(0,0,0,0.54)' }}>Facility</TableCell>
                <TableCell align="right" style={{ color: 'rgba(0,0,0,0.54)' }}>Included</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {facilityIDsAndNames.map((facility) => (
                <TableRow key={facility[1]}>
                  <TableCell component="th" scope="row">
                    {facility[1]}
                  </TableCell>
                  <TableCell align="right">
                    <Checkbox
                      checked={value.facilityIDs.includes(facility[0])}
                      onClick={() => this.handleFacilityToggle(facility[0], facility[1])}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

SettingsModalFacilities.propTypes = {
  value: PropTypes.string.isRequired,
  setParentState: PropTypes.func.isRequired,
  initialAnalyticsFacilities: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  initialAnalyticsFacilities: state.analytics.initialAnalyticsFacilities,
});

export default connect(mapStateToProps)(SettingsModalFacilities);
