import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const ScoreInput = (props) => {
  const {
    teamScore, updateScore, id, disabled,
  } = props;
  return (
    <>
      <TextField
        key={id}
        type="number"
        variant="outlined"
        margin="normal"
        defaultValue={0}
        value={teamScore}
        disabled={disabled}
        InputProps={{
          style: { width: 70 },
        }}
        onChange={updateScore}
      />
    </>

  );
};

ScoreInput.propTypes = {
  updateScore: PropTypes.func.isRequired,
  teamScore: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};
export default ScoreInput;
