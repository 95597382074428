/* eslint-disable react/prop-types */
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Dialog, DialogTitle, DialogActions, DialogContent, Button, Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useMobile, useToast } from '../../../../hooks';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';
import { deleteExclusionService } from '../../../../services/subscriptionsServices';

export const SubscriptionDeleteExclusionDialog = ({
  isOpen, onClose, subscription, exclusionId,
}) => {
  const queryClient = useQueryClient();
  const isMobile = useMobile();
  const toast = useToast();

  const exclusion = exclusionId
    ? subscription.accessRestriction.exclusions.find((ex) => ex.id === exclusionId)
    : null;

  const mutation = useMutation({
    mutationFn: async () => {
      if (!exclusion) {
        throw new Error('Exclusion not found!');
      }

      queryClient.setQueryData(['subscription', subscription.id], (oldData) => ({
        ...oldData,
        accessRestriction: {
          ...oldData.accessRestriction,
          exclusions: oldData.accessRestriction.exclusions.filter((ex) => ex.id !== exclusionId),
        },
      }));

      const res = await deleteExclusionService(
        subscription.companyId,
        exclusion.id,
        subscription.id,
      );

      if (res.status !== 200) {
        throw new Error('Failed to delete the exclusion!');
      }

      return res.data;
    },
    onError: (error) => {
      queryClient.invalidateQueries(['subscription', subscription.id]);
      toast.trigger({
        type: 'error',
        message: 'Failed to delete the exclusion!',
      });
      console.error(error);
    },
    onSuccess: () => {
      onClose();
      toast.trigger({
        type: 'success',
        message: 'Exclusion deleted successfully!',
      });
    },
  });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={isMobile}
      maxWidth="sm"
      fullWidth
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle>Delete Exclusion</DialogTitle>
        <DialogActions variant="none">
          <CloseDialogIcon onClick={onClose} />
        </DialogActions>
      </div>

      <DialogContent style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
      >
        <Typography variant="body1">
          {`Are you sure you wish to delete the exclusion on ${moment(exclusion?.date).format('DD/MM/YYYY')}?`}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          autoFocus
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          autoFocus
          disabled={mutation.isLoading}
          variant="outlined"
          onClick={() => mutation.mutate()}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
