/* eslint-disable react/prop-types */
import React from 'react';
import Share from '@pitchbooking-dev/pb-shared/lib/components/Share/Share';
import {
  Switch, Typography, Button,
  ButtonBase,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useMutation } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import './styles.css';
import DeleteIcon from '@material-ui/icons/Delete';
import imageSelector from '@pitchbooking-dev/pb-shared/lib/helpers/imageSelector';
import { updateFacilityService } from '../../../services/facilitiesServices';
import { useCompany, useToast, usePitchbookingUser } from '../../../hooks';
import { generateConfigurationNote } from '../../../helpers/index';

const FacilityCard = ({
  facility, onDelete,
}) => {
  const company = useCompany();
  const toast = useToast();
  const { isPitchbookingManager } = usePitchbookingUser();

  const isDirectory = ['DIRECTORY_APPROVED', 'DIRECTORY_PAID'].some((s) => s === company.status);
  const isDirectoryPending = ['DIRECTORY_CLAIMED_PENDING_APPROVAL', 'DIRECTORY_UNCLAIMED'].some((s) => s === company.status);
  const isEditable = facility.type === 'FACILITY' || facility.type === 'BOOKABLE_AMENITY' || facility.type === 'DIRECTORY';
  const isShareable = (facility.type === 'FACILITY' || facility.type === 'BOOKABLE_AMENITY') && !isDirectoryPending;
  const configurationNoteObj = generateConfigurationNote(facility);
  const configurationNote = isPitchbookingManager ? configurationNoteObj?.note : null;
  const imageURL = imageSelector(facility);
  const isLive = facility.visible === true;

  const facilityLiveMutation = useMutation({
    mutationFn: async (values) => {
      const res = await updateFacilityService(company.id, facility.id, {
        visible: values.isLive,
      });

      if (res.status !== 200) {
        throw new Error('An error occurred while updating the facility!');
      }

      return res.data;
    },
    onSuccess: () => {
      toast.trigger({
        type: 'success',
        message: 'Facility updated successfully!',
      });
    },
    onError: () => {
      toast.trigger({
        type: 'error',
        message: 'An error occurred while updating the facility!',
      });
    },
  });

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      borderRadius: '1rem',
      width: '22rem',
      gap: '0.5rem',
      border: '1px solid #e2e2e2',
      backgroundColor: 'rgb(250, 251, 255)',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      marginBottom: '1rem',
      height: isDirectory ? '26rem' : '24rem',
    }}
    >
      {/* Card Image */}
      <div style={{
        width: '100%',
        height: '15rem',
        position: 'relative',
      }}
      >
        <img
          src={imageURL || 'https://storage.googleapis.com/pitchbooking-images/test.jpeg'}
          alt="Facility"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />

        <div
          className="facility_card__actions"
          style={{
            display: 'flex', gap: '0.25rem', position: 'absolute', top: '0.5rem', right: '0.5rem',
          }}
        >
          {isDirectoryPending && (
            <div
              style={{
                backgroundColor: 'rgba(0,0,0,0.25)',
                borderRadius: '0.5rem',
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <ButtonBase
                style={{
                  width: '100%',
                  height: '100%',
                  padding: '0.35rem 0.75rem',
                }}
                onClick={onDelete}
              >
                <DeleteIcon style={{ color: 'white' }} />
              </ButtonBase>
            </div>
          )}

          {isShareable && (
            <div
              style={{
                backgroundColor: 'rgba(0,0,0,0.25)',
                borderRadius: '0.5rem',
              }}
              className="share__container"
            >
              <Share
                text={`Book ${facility.name} instantly on Pitchbooking!`}
                title="Share Facility"
                url={`https://pitchbooking.com/book/facility/${facility.id}`}
              />
            </div>
          )}

          {configurationNote && (
            <div
              style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                borderRadius: '0.5rem',
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                padding: '0.75rem',
                color: 'white',
                fontSize: '0.75rem',
                textShadow: '1px 1px 2px black',
              }}
            >
              <span>{configurationNote}</span>
            </div>
          )}
        </div>

      </div>

      {/* Card Body */}
      <div style={{
        padding: '0.5rem',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      >
        <div>
          <Typography variant="body1">
            {facility.name}
          </Typography>
          <Typography variant="caption">
            {facility.address}
          </Typography>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
              {isEditable && (
                <Link to={`${window.location.pathname}/edit/${facility.id}`}>
                  <Button variant="outlined">
                    <EditIcon style={{ marginRight: '0.25rem' }} />
                    Edit
                  </Button>
                </Link>
              )}
            </div>

            <div>
              {facility.internal ? (
                <Typography variant="caption" style={{ color: '#ac372f' }}>
                  Internal Only
                </Typography>
              ) : (
                <Formik
                  initialValues={{
                    isLive,
                  }}
                  onSubmit={async (values, formik) => {
                    try {
                      await facilityLiveMutation.mutateAsync(values);
                    } catch (err) {
                      formik.resetForm();
                    }
                  }}
                >
                  {({
                    values, handleChange, getFieldProps, handleSubmit,
                  }) => (
                    <Form>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ color: values.isLive ? '#4581E2' : '#515459' }} type="subheading">
                          {values.isLive ? 'Live: ' : 'Private: '}
                        </Typography>
                        <Switch
                          {...getFieldProps('isLive')}
                          checked={values.isLive}
                          onChange={(event) => {
                            handleChange(event);
                            handleSubmit();
                          }}
                          disabled={facilityLiveMutation.isLoading}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>

          {(isDirectory || isDirectoryPending) && (
            <Link to="/product/facilities">
              <Button
                variant="contained"
                color="primary"
                style={{ width: '100%' }}
              >
                Take Online Bookings
              </Button>
            </Link>
          )}
        </div>

      </div>
    </div>
  );
};

export default FacilityCard;
