/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import * as Yup from 'yup';
import { useMobile, useToast } from '../../../hooks';

const validationSchema = Yup.object().shape({
  message: Yup.string().required('You must provide a message!'),
});

export const RequestInformationModal = ({
  isOpen, onClose, claim, onSubmit,
}) => {
  const isMobile = useMobile();
  const toast = useToast();

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
      onClose={onClose}
    >
      <DialogTitle>
        Request more information
      </DialogTitle>
      <Formik
        initialValues={{
          message: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            await onSubmit({
              companyId: claim.id,
              data: {
                status: 'ADDITIONAL_INFORMATION_REQUIRED',
                claimantId: claim.claimantInformation.id,
                message: values.message,
              },
            });

            toast.trigger({
              type: 'success',
              message: 'Request for more information has been sent!',
            });

            onClose();
          } catch (err) {
            console.error(err);
            toast.trigger({
              type: 'error',
              message: 'An error occurred while attempting to request more information!',
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          getFieldProps, errors, touched, isSubmitting,
        }) => (
          <Form>
            <DialogContent>
              <PBInput
                type="textarea"
                name="message"
                id="message"
                label="Message"
                {...getFieldProps('message')}
                isError={touched.message && Boolean(errors.message)}
                errorMessage={touched.message && errors.message}
              />
            </DialogContent>
            <DialogActions>
              <Button type="button" color="secondary" variant="contained" onClick={onClose} disabled={isSubmitting}>
                Close
              </Button>
              <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
