import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function getSitesWithFacilitiesService(companyId) {
  const url = `/api/companies/${companyId}/sites-facilities`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function updateSiteDetailsService(companyId, siteId, reqBody) {
  const url = `/api/companies/${companyId}/sites/${siteId}`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function requestSiteCreationService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/create-site`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
