import React from 'react';
import PropTypes from 'prop-types';

const CustomTextBoxWithLabel = ({
  id, labelText, errorText, displayValue, onChangeAction, valid,
}) => (
  <div className="custom-text-box-wrapper">
    <p>{labelText}</p>
    <input
      className={`${!valid && 'invalid'}`}
      id={id}
      defaultValue={displayValue}
      onChange={(event) => onChangeAction({ [id]: event.target.value })}
    />
    {errorText && (<p>{errorText}</p>)}
  </div>
);

CustomTextBoxWithLabel.propTypes = {
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  displayValue: PropTypes.string.isRequired,
  onChangeAction: PropTypes.func.isRequired,
  valid: PropTypes.func,
};

CustomTextBoxWithLabel.defaultProps = {
  valid: true,
};

export default CustomTextBoxWithLabel;
