import React from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import PauseIcon from '@material-ui/icons/Pause';
import * as subscriptionsActions from '../../reducers/subscriptionsReducer';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import MenuHeading from '../../components/MenuHeading';
import ToggleButtons from '../../components/ToggleButtons';

const resumesAtButtonsData = [{
  buttonTitle: 'Pause Indefinitely',
  buttonValue: 'INDEFINITELY',
},
{
  buttonTitle: 'Pause Until',
  buttonValue: 'ENDDATE',
},
{
  buttonTitle: 'Unpause',
  buttonValue: 'UNPAUSE',
}];

class PauseSubscriptionsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    const { getSubscriptions, resetPauseSubscriptionStore } = this.props;
    resetPauseSubscriptionStore();
    getSubscriptions();
    this.setState({ open: false });
  };

  pause = () => {
    const { selectedSubscriptions, resumesAt, pauseSubscriptions } = this.props;
    const subscriptionIds = selectedSubscriptions.map((sub) => sub.id);
    pauseSubscriptions(subscriptionIds, resumesAt);
  }

  handleResumesAtToggle = (resumesAtOption) => {
    const { updatePauseSubscriptionsStore } = this.props;
    if (resumesAtOption === 'INDEFINITELY') {
      updatePauseSubscriptionsStore({
        resumesAtOption,
        resumesAt: null,
      });
    } else if (resumesAtOption === 'ENDDATE') {
      updatePauseSubscriptionsStore({
        resumesAtOption,
      });
    } else if (resumesAtOption === 'UNPAUSE') {
      updatePauseSubscriptionsStore({
        resumesAtOption,
        resumesAt: 'unpause',
      });
    }
  };

  handleEndDate = (date) => {
    const { updatePauseSubscriptionsStore } = this.props;
    updatePauseSubscriptionsStore({
      resumesAt: moment(date).startOf('day').subtract(1, 'seconds'),
    });
  };

  render() {
    const isMobile = window.innerWidth < 768;
    const { open } = this.state;
    const {
      resumesAtOption, resumesAt, pausedSubscriptionsError, pausedSubscriptions,
    } = this.props;

    let pauseButtonText = 'Pause';
    if (resumesAt === 'unpause') {
      pauseButtonText = 'Unpause';
    }
    if (pausedSubscriptionsError !== null) {
      pauseButtonText = 'Close';
    }
    return (
      <div>
        <Button
          id="manager-subscriptions-pause-subscription"
          dense="true"
          onClick={this.handleClickOpen}
          variant="contained"
          color="secondary"
          style={{ marginLeft: '20px' }}
        >
          <PauseIcon />
          Pause Subscriptions
        </Button>
        <Dialog open={open} fullScreen={isMobile}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>Pause subscriptions</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <DialogContent>
            <ConditionallyVisible condition={pausedSubscriptionsError === null}>
              <MenuHeading title="Do you want to pause these subscriptions indefinitely, until a specific date, or unpause them?" />
              <ToggleButtons
                buttonsData={resumesAtButtonsData}
                changeOption={(option) => this.handleResumesAtToggle(option)}
                value={resumesAtOption}
              />
              <ConditionallyVisible condition={resumesAtOption === 'ENDDATE'}>
                <DayPickerSingleDateController
                  onDateChange={(date) => this.handleEndDate(date)}
                  date={resumesAt !== null ? moment(resumesAt).add(1, 'seconds') : undefined}
                  noBorder
                  isOutsideRange={(day) => day.isBefore(moment())}
                />
              </ConditionallyVisible>
            </ConditionallyVisible>
            <ConditionallyVisible condition={pausedSubscriptionsError === true}>
              <MenuHeading title="There was a problem changing the status of one of your subscription." />
              {pausedSubscriptions.map((sub) => (
                <div className="cancelledCardDetails">
                  <div>{sub.subscriptionName}</div>
                  <div>
                    Paused:
                    {' '}
                    {sub.paused ? 'Yes' : 'No'}
                  </div>
                </div>
              ))}
            </ConditionallyVisible>
            <ConditionallyVisible condition={pausedSubscriptionsError === false}>
              <MenuHeading title="Subscriptions status successfully changed" />
              {pausedSubscriptions.map((sub) => (
                <div className="cancelledCardDetails">
                  <div>{sub.subscriptionName}</div>
                  <div>
                    Paused:
                    {' '}
                    {sub.paused ? 'Yes' : 'No'}
                  </div>
                </div>
              ))}
            </ConditionallyVisible>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="primary">
              Cancel
            </Button>
            <Button onClick={pauseButtonText !== 'Close' ? this.pause : this.handleRequestClose} color="secondary" autoFocus>
              {pauseButtonText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

PauseSubscriptionsDialog.propTypes = {
  resumesAt: PropTypes.string,
  getSubscriptions: PropTypes.func.isRequired,
  resumesAtOption: PropTypes.string.isRequired,
  pauseSubscriptions: PropTypes.func.isRequired,
  pausedSubscriptionsError: PropTypes.string.isRequired,
  resetPauseSubscriptionStore: PropTypes.func.isRequired,
  updatePauseSubscriptionsStore: PropTypes.func.isRequired,
  pausedSubscriptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedSubscriptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

PauseSubscriptionsDialog.defaultProps = {
  resumesAt: null,
};

function mapStateToProps(state) {
  const {
    selectedSubscriptions,
    resumesAtOption,
    resumesAt,
    pausedSubscriptionsError,
    pausedSubscriptions,
  } = state.subscriptions;
  return {
    selectedSubscriptions,
    resumesAtOption,
    resumesAt,
    pausedSubscriptionsError,
    pausedSubscriptions,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updatePauseSubscriptionsStore: (keyWithValue) => dispatch(
    subscriptionsActions.updatePauseSubscriptionsStore(keyWithValue),
  ),
  pauseSubscriptions: (subscriptionIds, resumesAt) => dispatch(
    subscriptionsActions.pauseSubscriptions(subscriptionIds, resumesAt),
  ),
  getSubscriptions: () => dispatch(subscriptionsActions.getSubscriptions()),
  resetPauseSubscriptionStore: () => dispatch(subscriptionsActions.resetPauseSubscriptionStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PauseSubscriptionsDialog);
