import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import RequestResetPasswordForm from '@pitchbooking-dev/pb-shared/lib/components/Forms/RequestResetPasswordForm';
import * as actions from '../../../reducers/usersReducer';

const RequestResetPasswordDialog = (props) => {
  const { forgottenPasswordDialogOpen, closeForgottenPasswordDialog } = props;
  return (
    <div>
      <Dialog
        className="forgotten-password-dialog"
        open={forgottenPasswordDialogOpen}
        onClose={() => closeForgottenPasswordDialog(false)}
      >
        <DialogTitle />
        <DialogContent>
          <RequestResetPasswordForm />
        </DialogContent>
      </Dialog>
    </div>
  );
};

RequestResetPasswordDialog.propTypes = {
  forgottenPasswordDialogOpen: PropTypes.bool.isRequired,
  closeForgottenPasswordDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  forgottenPasswordDialogOpen: state.users.forgottenPasswordDialogOpen,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeForgottenPasswordDialog: (bool) => (actions.closeForgottenPasswordDialog(bool)),
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(RequestResetPasswordDialog);
