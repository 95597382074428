import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as leagueActions from '../../../reducers/leaguesReducer';
import * as eventActions from '../../../reducers/eventsReducer';
import AssignAssociateSelector from './AssignAssociateSelector';

const styles = () => ({
  root: {
    minWidth: 400,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});

const AssociatePickerWithPrice = ({
  currentAgent, currencySym, index, updateSelectedAgent, updateAgentPrice,
  pickerDiasbled,
}) => {
  const { price, total } = currentAgent;
  return (
    <div key={index}>
      <AssignAssociateSelector
        currentAgent={currentAgent.user ? currentAgent.user : currentAgent}
        updateSelectedAgent={(user) => updateSelectedAgent(user, index)}
        pickerDiasbled={pickerDiasbled}
      />
      <div style={{ textAlign: 'center', margin: 'auto', maxWidth: 400 }}>
        <TextField
          id="associateAmount"
          type="number"
          variant="outlined"
          label="Price of Official"
          margin="normal"
          value={price || total}
          disabled={pickerDiasbled}
          onChange={(event) => updateAgentPrice(event.target.value, index)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currencySym}
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
};

class AssignAssociateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      agents: [
        {
          userId: null,
          price: null,
        },
      ],
    };
    this.updateAgentPrice = this.updateAgentPrice.bind(this);
    this.updateSelectedAgent = this.updateSelectedAgent.bind(this);
    this.removeAgent = this.removeAgent.bind(this);
    this.removeSelectedAgent = this.removeSelectedAgent.bind(this);
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
      agents: [
        {
          userId: null,
          price: null,
        },
      ],
    });
  };

   updateAgentPrice = (price, index) => {
     const { league } = this.props;
     const { maxRefereePayment } = league;
     if (price > maxRefereePayment) {
       // eslint-disable-next-line no-param-reassign
       price = maxRefereePayment;
     }
     const { agents } = this.state;
     agents[index].price = price;
     this.setState({ agents });
   };

   updateSelectedAgent = (agent, index) => {
     const { agents } = this.state;
     agents[index] = agent;
     this.setState({ agents });
   };

   removeAgent = (index) => {
     const { agents } = this.state;
     agents.splice(index, 1);
     this.setState({ agents });
   }

  handleConfirmAssignment = () => {
    const {
      doAssignOfficial, doAssignEventAgent, fixture, event,
    } = this.props;
    const { agents } = this.state;
    if (fixture.id) {
      doAssignOfficial(fixture.id, fixture.leagueId, agents);
    } else {
      doAssignEventAgent(event.id, agents);
    }
    this.setState({
      open: false,
      agents: [
        {
          userId: null,
          price: null,
        },
      ],
    });
  }

  removeSelectedAgent = (agentId, id, type) => {
    const { removeFixtureAgent, removeEventAgent } = this.props;
    if (type === 'FIXTURE') {
      removeFixtureAgent(id, agentId);
    } else {
      removeEventAgent(id, agentId);
    }
  }

  render() {
    const isMobile = window.innerWidth < 768;
    const {
      open,
      agents,
    } = this.state;
    const {
      fixture, editing, currency, event,
    } = this.props;
    const { fixtureAgents } = fixture;
    const eventAgents = event.event_agents;
    const assignedAgents = fixtureAgents || eventAgents;
    const buttonText = fixture.teamA ? 'Official' : 'Staff';
    const type = fixture.teamA ? 'FIXTURE' : 'EVENT';
    const currencySym = getCurrencySymbol(currency);
    return (
      <div>
        <Button color="secondary" variant="outlined" onClick={() => this.handleClickOpen()}>
          {editing ? <EditIcon /> : `Assign ${buttonText}`}
        </Button>
        <Dialog
          open={open}
          fullScreen={isMobile}
          fullWidth
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ConditionallyVisible condition={fixture.teamA}>
              <DialogTitle>
                {`Assign ${buttonText}: ${fixture?.teamA?.name} vs ${fixture?.teamB?.name}`}
              </DialogTitle>
            </ConditionallyVisible>
            <ConditionallyVisible condition={event.id}>
              <DialogTitle>
                {`Assign ${buttonText}: ${event?.name}`}
              </DialogTitle>
            </ConditionallyVisible>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <DialogContent>
            <a href="/associates" style={{ textDecoration: 'none' }} rel="noopener noreferrer">
              <Button color="primary">
                Add
                {' '}
                {buttonText}
                {' '}
                to your company
              </Button>
            </a>
            {assignedAgents && assignedAgents.length > 0 && (
            <>
              <p>
                Assigned
                {' '}
                {buttonText}
              </p>
              {assignedAgents.map((agent, index) => (
                <div style={{
                  margin: '0 auto',
                  textAlign: 'center',
                  border: '1px solid #33333359',
                  marginBottom: 5,
                  padding: 15,
                }}
                >
                  <AssociatePickerWithPrice
                    currentAgent={agent}
                    currencySym={currencySym}
                    index={index}
                    updateSelectedAgent={(agent, index) => this.updateSelectedAgent(agent, index)}
                    updateAgentPrice={(price, index) => this.updateAgentPrice(price, index)}
                    pickerDiasbled
                  />
                  <Button onClick={
                    () => this.removeSelectedAgent(
                      agent.id,
                      fixture?.teamA ? fixture.id : event.id,
                      type,
                    )
                    }
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </>
            )}
            {agents && agents.length > 0 && (
            <>
              <p>
                Add
                {' '}
                {buttonText}
              </p>
              {agents.map((agent, index) => (
                <div style={{
                  margin: '0 auto',
                  textAlign: 'center',
                  border: '1px solid #33333359',
                  marginBottom: 5,
                  padding: 15,
                }}
                >
                  <AssociatePickerWithPrice
                    currentAgent={agent}
                    currencySym={currencySym}
                    index={index}
                    updateSelectedAgent={(agent, index) => this.updateSelectedAgent(agent, index)}
                    updateAgentPrice={(price, index) => this.updateAgentPrice(price, index)}
                  />
                  <ConditionallyVisible condition={index !== 0}>
                    <Button onClick={() => this.removeAgent(index)}>Remove</Button>
                  </ConditionallyVisible>
                </div>
              ))}
            </>
            )}

            <Button
              onClick={() => this.setState({
                agents: [...agents, {
                  userId: null,
                  price: null,
                }],
              })}
              color="primary"
              variant="contained"
            >
              Add Another Official
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary" variant="outlined">
              Close
            </Button>
            <Button onClick={() => this.handleConfirmAssignment()} color="primary" variant="contained">
              Confirm Assignment
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { events, companies, leagues } = state;
  const { eventStore } = events;
  const { currency } = companies?.companyInfo;
  const { selectedAssociatePrice, selectedAssociates, league } = leagues;
  return {
    eventStore,
    currency,
    selectedAssociatePrice,
    selectedAssociates,
    league,
  };
}

const mapDispatchToProps = (dispatch) => ({
  doAssignOfficial: (fixtureId, leagueId, agents) => dispatch(
    leagueActions.assignAssociate(fixtureId, leagueId, agents),
  ),
  doAssignEventAgent: (eventId, agents) => dispatch(
    eventActions.assignEventAgent(eventId, agents),
  ),
  doUpdateSelectedAssociatePrice: (total) => dispatch(
    leagueActions.updateSelectedAssociatePrice(total),
  ),
  removeFixtureAgent: (fixtureId, fixtureAgentId) => dispatch(
    leagueActions.removeFixtureAgent(fixtureId, fixtureAgentId),
  ),
  removeEventAgent: (eventId, eventAgentId) => dispatch(
    eventActions.removeEventAgent(eventId, eventAgentId),
  ),
});

AssignAssociateDialog.propTypes = {
  fixture: PropTypes.shape(),
  doAssignOfficial: PropTypes.func.isRequired,
  doAssignEventAgent: PropTypes.func.isRequired,
  removeEventAgent: PropTypes.func.isRequired,
  event: PropTypes.shape(),
  removeFixtureAgent: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  currency: PropTypes.string,
  league: PropTypes.shape(),
  maxRefereePayment: PropTypes.number,

};
AssignAssociateDialog.defaultProps = {
  editing: false,
  currency: 'gbp',
  fixture: {},
  event: {},
  league: {},
  maxRefereePayment: null,
};

AssociatePickerWithPrice.propTypes = {
  currentAgent: PropTypes.shape().isRequired,
  currencySym: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  updateSelectedAgent: PropTypes.func.isRequired,
  updateAgentPrice: PropTypes.func.isRequired,
  pickerDiasbled: PropTypes.bool,
};
AssociatePickerWithPrice.defaultProps = {
  pickerDiasbled: false,
};

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withStyles(styles())(AssignAssociateDialog));
