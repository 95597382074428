import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import EnquiriesOverView from '@pitchbooking-dev/pb-shared/lib/components/enquiriesOverView';
import * as enquiriesActions from '../../reducers/enquiriesReducer';
import '../../styles/dashboard.css';

class Dashboard extends React.Component {
  componentDidMount() {
    const {
      requestAllEnquiries,
    } = this.props;
    requestAllEnquiries();
  }

  render() {
    const { messages, user, goToMessage } = this.props;
    return (
      user.isLoggedIn ? (
        <EnquiriesOverView
          messages={messages}
          goToMessage={goToMessage}
        />
      ) : (
        <Redirect to="/" />
      )
    );
  }
}

Dashboard.propTypes = {
  messages: PropTypes.shape().isRequired,
  requestAllEnquiries: PropTypes.func.isRequired,
  goToMessage: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};

function mapStateToProps(state) {
  const { messages } = state.enquiries;
  const user = state.currentUser;

  return {
    messages,
    user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  requestAllEnquiries: () => dispatch(enquiriesActions.requestAllEnquiries()),
  goToMessage: (threadId) => dispatch(push(`/messages/${threadId}`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
