import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { useDispatch } from 'react-redux';
import * as companyActions from '../../../../reducers/companiesReducer';

const PayLaterConfiguration = ({
  type, facilityId, eventId, settings,
}) => {
// Your component logic goes here
  const [companyPayLaterMinutes, setCompanyPayLaterMinutes] = useState(settings?.minutes || 0);
  const [companyPayLaterAnchor, setCompanyPayLaterAnchor] = useState(settings?.paymentAnchor || 'START_OF_BOOKING');
  const dispatch = useDispatch();
  return (
    <div>
      <div>
        <h3>
          Set Pay Later Configuration for
          {` ${type}`}
        </h3>
        <p>
          Pay Later Occurs when a customer selects the Pay Later option at checkout.
          Configure when payment will be taken below. (
          Add + or - minutes to the anchor time to set
          the time when payment will be taken. )
        </p>
        <br />
        {settings?.paymentAnchor && (
        <p>
          This
          {' '}
          {type}
          {' '}
          will process payments
          {' '}
          {settings?.minutes}
          {' '}
          minutes
          {settings?.minutes > 0 ? ' after ' : ' before '}
          the
          {' '}
          {settings?.paymentAnchor?.replaceAll('_', ' ').toLowerCase()}
          {'.'}
        </p>
        )}
        <div style={{
          display: 'flex', alignItems: 'center', gap: 20, justifyContent: 'end',
        }}
        >
          <TextField
            id="companyPayLaterMinutes"
            value={companyPayLaterMinutes || 0}
            defaultValue={companyPayLaterMinutes || 0}
            margin="normal"
            multiline
            type="number"
            variant="outlined"
            onChange={(event) => setCompanyPayLaterMinutes(event.target.value)}
            label="+ / - (minutes)"
          />
          <Select
            value={companyPayLaterAnchor}
            onChange={(e) => setCompanyPayLaterAnchor(e.target.value)}
            name="Pay Later Anchor"
            label="Pay Later Anchor"
          >
            <option value="START_OF_BOOKING">Start of Booking</option>
            <option value="END_OF_BOOKING">End of Booking</option>
            <option value="BOOKING_CREATED">Booking Created</option>
          </Select>
          <Button
            variant="contained"
            color="primary"
            onClick={
                    () => dispatch(companyActions.updateCompanyPayLaterSettings(
                      {
                        id: settings?.id,
                        minutes: companyPayLaterMinutes,
                        paymentAnchor: companyPayLaterAnchor,
                        facilityId,
                        eventId,
                      },
                    ))
                  }
          >
            Set Payment Anchor
          </Button>
        </div>
      </div>
    </div>
  );
};
PayLaterConfiguration.propTypes = {
  type: PropTypes.string,
  facilityId: PropTypes.string,
  eventId: PropTypes.string,
  settings: PropTypes.shape(),
};
PayLaterConfiguration.defaultProps = {
  type: 'Facility',
  facilityId: null,
  eventId: null,
  settings: {},
};
export default PayLaterConfiguration;
