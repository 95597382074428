import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { useMobile, useToast } from '../../../hooks';
import { sendBulkDeviceCommand, sendBulkNodeCommand } from '../../../services/companiesServices';

const validationSchema = Yup.object().shape({
  entityType: Yup.string().required('Entity Type is required'),
  entities: Yup.array().min(1, 'At least 1 entity is required.'),
  command: Yup.string().when('entityType', {
    is: 'DEVICES',
    then: Yup.string().required('Command Type is required'),
    otherwise: Yup.string(),
  }),
  commandValue: Yup.string(),
});

const SendDeviceCommandDialog = ({ companyId, devices }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [entities, setEntities] = useState(devices.map((x) => ({
    label: x.friendlyName,
    value: x.id,
  })));
  const isMobile = useMobile();
  const toast = useToast();

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setIsOpen(true)}
      >
        Send Command
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullScreen={isMobile}
        fullWidth
      >
        <DialogTitle>Send Command to Device(s)/Node(s)</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              entityType: 'NODES',
              entities: [],
              command: '',
              value: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                let res;

                if (values.entityType === 'DEVICES') {
                  res = await sendBulkDeviceCommand(companyId, values);
                } else {
                  res = await sendBulkNodeCommand(companyId, values);
                }

                if (res.error) {
                  throw new Error(res.error);
                }

                toast.trigger({
                  type: 'success',
                  message: 'Command successfully sent',
                });
                setEntities(devices.map((x) => ({
                  label: x.friendlyName,
                  value: x.id,
                })));
                setIsOpen(false);
              } catch (err) {
                toast.trigger({
                  type: 'error',
                  message: err.message,
                });
              }

              setSubmitting(false);
            }}
          >
            {({
              values, errors, touched, isSubmitting, handleChange, handleBlur, setFieldValue,
            }) => (
              <Form>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                  <Typography>
                    You can send a command to a device(s) to update the configuration.
                    Please refer to the documentation for the types of commands you can send.
                  </Typography>

                  <PBInput
                    id="entityType"
                    label="Entity Type"
                    type="select"
                    options={[
                      { label: 'Nodes', value: 'NODES' },
                      { label: 'Devices', value: 'DEVICES' },
                    ]}
                    value={values.entityType}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value === 'DEVICES') {
                        setEntities(devices.reduce((acc, curr) => {
                          if (
                            acc.length > 0
                            && acc.some((x) => x.label === curr.device.friendlyName)
                          ) {
                            return acc;
                          }
                          acc.push({
                            value: curr.deviceId,
                            label: curr.device.friendlyName,
                          });

                          return acc;
                        }, []));
                      } else {
                        setEntities(devices.map((x) => ({
                          label: x.friendlyName,
                          value: x.id,
                        })));
                      }
                      setFieldValue('entities', []);
                    }}
                    onBlur={handleBlur}
                    errorMessage={touched.entityType && errors.entityType}
                    isError={touched.entityType && Boolean(errors.entityType)}
                  />

                  <PBInput
                    id="entities"
                    type="select"
                    multiple
                    placeholder="Select devices/nodes"
                    options={entities}
                    label={values.entityType === 'DEVICES' ? 'Devices' : 'Nodes'}
                    value={values.entities}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={touched.entities && errors.entities}
                    isError={touched.entities && Boolean(errors.entities)}
                  />

                  {values.entityType === 'DEVICES' && (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                      <PBInput
                        id="command"
                        type="select"
                        options={[
                          { label: 'Backlog', value: 'Backlog' },
                          { label: 'Status (All)', value: 'Status0' },
                          { label: 'Power0 (All)', value: 'Power0' },
                          { label: 'Rule1', value: 'Rule1' },
                          { label: 'Rule2', value: 'Rule2' },
                          { label: 'Rule3', value: 'Rule3' },
                          { label: 'Rule4', value: 'Rule4' },
                        ]}
                        label="Command"
                        value={values.command}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={touched.command && errors.command}
                        isError={touched.command && Boolean(errors.command)}
                      />

                      {values.command === 'Backlog' && (
                      <Typography variant="subtitle2" color="textSecondary">
                        Backlog allows you send many commands at once. You should really use
                        this if you are updating MQTT/WiFi information. Each command should be
                        separated by a semicolon (e.g. Power1 on; Power2 off)
                      </Typography>
                      )}
                    </div>
                  )}

                  <PBInput
                    id="value"
                    label="Value"
                    type="textarea"
                    value={values.value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={touched.value && errors.value}
                    isError={touched.value && Boolean(errors.value)}
                  />

                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Send Command
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

SendDeviceCommandDialog.propTypes = {
  companyId: PropTypes.string.isRequired,
  devices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default SendDeviceCommandDialog;
