import React from 'react';
import ConfigurationTabs from './components/ConfigurationTabs';

const CompanySettings = (props) => (
  <>
    <div style={{ display: 'flex' }}>
      <ConfigurationTabs {...props} />
    </div>
  </>

);

export default CompanySettings;
