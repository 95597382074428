import {
  takeEvery, call, put,
} from 'redux-saga/effects';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  getReferralsService,
  createReferralService,
} from '../services/referralsServices';
import * as actions from '../reducers/referralsReducer';
import { handleSagaError } from './helperSaga';

function* getReferralsSaga(companyId) {
  try {
    const response = yield call(getReferralsService, companyId);
    if (!response.error) {
      const referrals = response.data;
      yield put(actions.getReferralsSucceeded(referrals));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getReferralsSaga');
    yield put(actions.getReferralsFailed());
  }
}

function* createReferralsSaga(companyId, action) {
  const { values } = action;
  const {
    firstName, lastName, organisation, dialCode, phone, email,
  } = values;
  const referralDetails = {
    firstName, lastName, organisation, dialCode, phone, email,
  };
  try {
    const response = yield call(createReferralService, companyId, referralDetails);
    if (!response.error) {
      const referrals = response.data;
      yield put(actions.createReferralSucceeded());
      yield put(actions.getReferralsSucceeded(referrals));
    } else {
      throw response;
    }
  } catch (error) {
    yield call(handleSagaError, error, 'createReferralsSaga');
    yield put(actions.createReferralFailed());
    yield put(sessionActions.updateErrorSnackbar(`${error.error}`, true));
  }
}

export function* getReferralsWatcher(companyId) {
  yield takeEvery(actions.GET_REFERRALS_REQUESTED, getReferralsSaga, companyId);
}

export function* createReferralsWatcher(companyId) {
  yield takeEvery(actions.CREATE_REFERRALS_REQUESTED, createReferralsSaga, companyId);
}
