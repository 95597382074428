/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Select, OutlinedInput, MenuItem, Checkbox, ListItemText,
} from '@material-ui/core';

const VALUE_ALL_PAYMENTS = 'ALL_PAYMENTS';
const VALUE_NON_SITE_SPECIFIC = 'NON_SITE_SPECIFIC';
const VALUE_ALL_SITES = 'ALL_SITES';

export const MultiSiteSelector = ({
  sitesList, selectedSiteIds, handleSiteChange,
}) => {
  const allSiteIds = sitesList.map((site) => site.id);

  // Helper functions to determine selection states
  const areAllSitesSelected = () => allSiteIds.every((id) => selectedSiteIds.includes(id));
  const isAnySiteSelected = () => allSiteIds.some((id) => selectedSiteIds.includes(id));
  const isNonSiteSpecificSelected = () => selectedSiteIds.includes(VALUE_NON_SITE_SPECIFIC);
  const isAllPaymentsSelected = () => areAllSitesSelected() && isNonSiteSpecificSelected();

  const handleSelectionChange = (event) => {
    const { value } = event.target;
    const isNonSiteSpecificChecked = isNonSiteSpecificSelected();

    // Determine what was changed by comparing the new value array with the current selectedSiteIds
    if (value.length > selectedSiteIds.length) {
      // An item was selected
      const addedValue = value.find((id) => !selectedSiteIds.includes(id));
      if (addedValue === VALUE_ALL_PAYMENTS) {
        // "All Payments" was selected
        handleSiteChange([VALUE_NON_SITE_SPECIFIC, ...allSiteIds]);
      } else if (addedValue === VALUE_ALL_SITES) {
        // "All Sites" was selected
        handleSiteChange([...new Set([...selectedSiteIds, ...allSiteIds])]);
      } else if (addedValue === VALUE_NON_SITE_SPECIFIC) {
        // "Non-Site Specific" was selected
        handleSiteChange([...selectedSiteIds, VALUE_NON_SITE_SPECIFIC]);
      } else {
        // An individual site was selected
        handleSiteChange(value);
      }
    } else {
      // An item was deselected
      const removedValue = selectedSiteIds.find((id) => !value.includes(id));
      if (removedValue === VALUE_ALL_PAYMENTS) {
        // "All Payments" was deselected
        handleSiteChange([]);
      } else if (removedValue === VALUE_ALL_SITES) {
        // "All Sites" was deselected
        handleSiteChange(selectedSiteIds.filter((id) => !allSiteIds.includes(id)));
      } else if (removedValue === VALUE_NON_SITE_SPECIFIC) {
        // "Non-Site Specific" was deselected
        handleSiteChange(selectedSiteIds.filter((id) => id !== VALUE_NON_SITE_SPECIFIC));
      } else {
        // An individual site was deselected
        const updatedSelectedIds = value.filter((id) => ![VALUE_ALL_PAYMENTS, VALUE_ALL_SITES].includes(id));

        // Maintain the NON_SITE_SPECIFIC selection state if it was selected
        if (isNonSiteSpecificChecked && !updatedSelectedIds.includes(VALUE_NON_SITE_SPECIFIC)) {
          updatedSelectedIds.push(VALUE_NON_SITE_SPECIFIC);
        }

        handleSiteChange(updatedSelectedIds);
      }
    }
  };

  const renderSelectedValue = (selected) => {
    if (selected.includes(VALUE_NON_SITE_SPECIFIC) && areAllSitesSelected()) {
      return 'All Payments';
    }
    if (areAllSitesSelected()) {
      return 'All Sites';
    }
    if (selected.includes(VALUE_NON_SITE_SPECIFIC)) {
      return 'Non-Site Specific';
    }
    return selected.map((id) => sitesList.find((site) => site.id === id)?.name).join(', ');
  };

  return (
    <Select
      labelId="site-selector-label"
      multiple
      value={selectedSiteIds}
      onChange={handleSelectionChange}
      input={<OutlinedInput style={{ backgroundColor: 'white' }} />}
      renderValue={renderSelectedValue}
    >
      <MenuItem value={VALUE_ALL_PAYMENTS}>
        <Checkbox
          checked={isAllPaymentsSelected()}
          indeterminate={!isAllPaymentsSelected() && (areAllSitesSelected() || isNonSiteSpecificSelected() || isAnySiteSelected())}
        />
        <ListItemText primary="All Payments" />
      </MenuItem>

      <MenuItem value={VALUE_NON_SITE_SPECIFIC}>
        <Checkbox
          checked={isNonSiteSpecificSelected()}
        />
        <ListItemText primary="Non-Site Specific" />
      </MenuItem>

      {sitesList.length > 0 && (
        <MenuItem value={VALUE_ALL_SITES}>
          <Checkbox
            checked={areAllSitesSelected()}
            indeterminate={!areAllSitesSelected() && isAnySiteSelected()}
          />
          <ListItemText primary="All Sites" />
        </MenuItem>
      )}

      {sitesList.map((site) => (
        <MenuItem
          key={site.id}
          value={site.id}
          style={{ paddingLeft: 40 }}
        >
          <Checkbox
            checked={selectedSiteIds.includes(site.id)}
          />
          <ListItemText primary={site.name} />
        </MenuItem>
      ))}
    </Select>
  );
};
