import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as schedulesActions from '../../reducers/schedulesReducer';
import * as facilitiesActions from '../../reducers/facilitiesReducer';
import SchedulesTable from './SchedulesTable';
import ScheduleOpeningTimesDialog from './components/ScheduleOpeningTimesDialog';
import '../../styles/payments.css';

const Schedules = () => {
  const dispatch = useDispatch();

  const schedules = useSelector((state) => state.schedules.schedules);

  const getSchedules = () => dispatch(schedulesActions.getSchedules());
  const toggleEditing = () => dispatch(schedulesActions.toggledEditingScheduleGroupOpeningTimes());
  const enableSchedulesGroupEdit = (scheduleGroup) => dispatch(
    schedulesActions.enableSchedulesGroupEdit(scheduleGroup),
  );
  const requestFacilitiesRetrieval = () => dispatch(facilitiesActions.requestFacilitiesRetrieval());

  useEffect(() => {
    getSchedules();
    requestFacilitiesRetrieval();
  }, []);

  return (
    <div style={{
      background: '#fafbff', marginTop: '-20px', paddingTop: '20px', height: '95.5vh',
    }}
    >
      <ScheduleOpeningTimesDialog />
      <SchedulesTable
        schedules={schedules}
        toggleEditing={toggleEditing}
        enableSchedulesGroupEdit={enableSchedulesGroupEdit}
      />
    </div>
  );
};

export default Schedules;
