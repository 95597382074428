/* eslint-disable react/prop-types */
import React from 'react';
import MT from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { Typography } from '@material-ui/core';

const defaultOptions = {
  draggable: false,
  search: false,
  tableLayout: 'auto',
  exportButton: true,
  emptyRowsWhenPaging: false,
  pageSize: 25,
  pageSizeOptions: [10, 25, 50, 100],
  showFirstLastPageButtons: false,
  showTitle: true,
  headerStyle: {
    backgroundColor: '#efefef',
    fontWeight: 'bold',
  },
};

export const MaterialTable = ({
  data, columns, options, title, ToolTip,
}) => (
  <MT
    className="table"
    data={data}
    title={(
      <Typography variant="h6">
        {title}
        {ToolTip && <ToolTip />}
      </Typography>
      )}
    icons={{
      NextPage: () => <ChevronRight />,
      PreviousPage: () => <ChevronLeft />,
      Search: () => <Search />,
      Export: () => <SaveAlt />,
      ResetSearch: () => <Clear />,
      SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
    }}
    columns={columns}
    options={{
      ...defaultOptions,
      ...options,
    }}
    localization={{
      toolbar: {
        exportTitle: 'Download',
      },
    }}
  />
);
