import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { closeAnalyticsAlert } from '../../../../reducers/analyticsReducer';

class AlertFullWidth extends React.Component {
  constructor(props) {
    super(props);
    this.closeAlert = this.closeAlert.bind(this);
  }

  closeAlert() {
    const { closeAnalyticsAlert } = this.props;

    closeAnalyticsAlert();
  }

  render() {
    const { analyticsAlertOpen, alertMessage, severity } = this.props;
    return (
      <ConditionallyVisible condition={analyticsAlertOpen}>
        <Alert id="alert" onClose={this.closeAlert} severity={severity}>
          {alertMessage}
        </Alert>
      </ConditionallyVisible>
    );
  }
}

AlertFullWidth.propTypes = {
  closeAnalyticsAlert: PropTypes.func.isRequired,
  analyticsAlertOpen: PropTypes.bool.isRequired,
  alertMessage: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  closeAnalyticsAlert,
};

const mapStateToProps = (state) => ({
  analyticsAlertOpen: state.analytics.analyticsAlertOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertFullWidth);
