import React from 'react';
import PropTypes from 'prop-types';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import AssignAssociateDialog from './AssignAssociateDialog';

class AssignedAssociateCell extends React.Component {
  render() {
    const { fixture, event } = this.props;
    let agentAssigned = false;
    let agent = null;
    let status = null;
    let agentPrice = null;
    let displayText = 'Officials';
    if (fixture?.fixtureAgents?.length > 0) {
      agentAssigned = true;
      agent = fixture.fixtureAgents[0].user;
      status = fixture.fixtureAgents[0].status;
      agentPrice = fixture.fixtureAgents[0].total;
    }
    if (event?.event_agents?.length > 0) {
      agentAssigned = true;
      displayText = 'Staff';
      agent = event.event_agents[0].user;
      status = event.event_agents[0].status;
      agentPrice = event.event_agents[0].total;
    }
    return (
      <AlternativelyVisible condition={agentAssigned}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div>
            {displayText}
            {' '}
            Assigned
          </div>
          <ConditionallyVisible condition={status === 'PENDING'}>
            <div style={{ width: '5px' }} />
            <AssignAssociateDialog
              fixture={fixture}
              event={event}
              editing
              currentAgent={agent}
              agentPrice={agentPrice}
            />
          </ConditionallyVisible>
        </div>
        <AssignAssociateDialog fixture={fixture} event={event} />
      </AlternativelyVisible>
    );
  }
}

AssignedAssociateCell.propTypes = {
  fixture: PropTypes.shape(),
  event: PropTypes.shape(),
};
AssignedAssociateCell.defaultProps = {
  fixture: {},
  event: {},
};
export default AssignedAssociateCell;
