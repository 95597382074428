import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import { BulkInsertUserForm } from './BulkInsertUserForm';
import { useMobile } from '../../hooks';

const styles = {
  dialogContent: {
    paddingRight: '2.5rem',
    paddingLeft: '2.5rem',
    paddingBottom: '2.5rem',
  },
  dialogTitle: { paddingLeft: '0rem' },
  actionWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '1rem',
  },
  triggerButton: {
    fontWeight: 800,
    marginRight: '1.25rem',
    width: 225,
  },
};

export const BulkInsertUserDialog = () => {
  const isMobile = useMobile();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        style={styles.triggerButton}
        onClick={() => setIsOpen(true)}
      >
        Bulk Upload
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth="sm"
        fullScreen={isMobile}
      >
        <div style={styles.actionWrapper}>
          <CloseDialogIcon style={styles.closeDialogIcon} onClick={() => setIsOpen(false)} />
        </div>
        <DialogContent style={styles.dialogContent}>
          <DialogTitle style={styles.dialogTitle} color="primary">Bulk Invite Users</DialogTitle>
          <DialogContentText>
            <a href="/UserUploadTemplate.csv" download>User Upload Template</a>
            <p>
              You can invite multiple users at once by uploading a CSV file.
              Please use the template provided to ensure the correct format.
            </p>
          </DialogContentText>
          <BulkInsertUserForm onSuccess={() => setIsOpen(false)} />
        </DialogContent>
      </Dialog>
    </>
  );
};
