import React from 'react';
import PropTypes from 'prop-types';

const ActionBar = ({ children }) => (<div className="action-bar">{children}</div>);

ActionBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape()),
  ]).isRequired,
};

export default ActionBar;
