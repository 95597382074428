import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Dialog from '@material-ui/core/Dialog';
import CheckBox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const AddTagDialog = ({
  tags, addTags, addTag, removeTag, tagRelation, selectedTags, currentSelectedTags, setSelectedTags,
}) => {
  const [open, setOpen] = useState(false);
  const selectedTagIds = selectedTags ? selectedTags.map((tag) => tag.id) : [];

  const handleOpen = () => {
    setOpen(true);
    setSelectedTags(selectedTagIds);
  };
  return (
    <div>
      <Button variant="contained" color="secondary" aria-label="edit" onClick={() => handleOpen()}>
        Add Tag
        <AddCircleOutlineIcon style={{ marginLeft: '10px' }} />
      </Button>
      <Dialog
        open={open}
      >
        <DialogTitle>
          Add a tag to the
          {' '}
          {tagRelation}
        </DialogTitle>
        <DialogContent style={{ width: '600px' }}>
          <div style={{ marginBottom: '20px' }}>
            {tags.length > 0 ? (
              <div style={{ display: 'flex', maxWidth: '300px', flexWrap: 'wrap' }}>
                {tags.map((tag) => (
                  <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                    <div>
                      <CheckBox
                        checked={
                          (selectedTagIds.includes(tag.id) && currentSelectedTags.includes(tag.id))
                           || currentSelectedTags.includes(tag.id)
}
                        onChange={
                          (event) => (event.target.checked
                            ? addTag(tag.id) : removeTag(tag.id))
                        }
                      />
                    </div>
                    <div style={{
                      width: '300px', margin: '10px 0px', padding: '10px', borderRadius: '4px', border: '1px solid #4581E2',
                    }}
                    >
                      {tag.value}
                    </div>
                  </div>
                ))}
              </div>

            ) : <>No Tags found</>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary" variant="contained">
            Close
          </Button>
          <Button onClick={() => (addTags ? addTags() : setOpen(false))} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
AddTagDialog.propTypes = {
  tags: PropTypes.shape().isRequired,
  selectedTags: PropTypes.shape().isRequired,
  currentSelectedTags: PropTypes.shape().isRequired,
  addTags: PropTypes.func.isRequired,
  addTag: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
  setSelectedTags: PropTypes.func.isRequired,
  tagRelation: PropTypes.string,
};

AddTagDialog.defaultProps = {
  tagRelation: 'Event',
};

export default AddTagDialog;
