import {
  takeEvery, call, put, select,
} from 'redux-saga/effects';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  getPricingPackagesService,
  addFacilitiesToPriceGroupService,
  addRuleToPriceGroupService,
  deletePriceModifierService,
  updatePriceGroupStoreService,
  requestPriceGroupCreationService,
  requestDuplicatePriceGroupService,
  requestPriceGroupDeletionService,
  requestPriceRuleSaveService,
} from '../services/pricingServices';
import * as actions from '../reducers/pricingReducer';
import { handleSagaError } from './helperSaga';

function* getPricingPackagesSaga(companyId) {
  try {
    const response = yield call(getPricingPackagesService, companyId);
    const formattedPricing = [];
    const { pricing, pricingErrorMessages } = response.data;
    pricing.forEach((priceData) => {
      let formattedPrice = {};
      const formattedFacilities = [];
      if (priceData.facilities) {
        priceData.facilities.forEach((f) => {
          if (f.facility) {
            formattedFacilities.push({ ...f.facility, resourceType: 'Facility' });
          } else if (f.company) {
            formattedFacilities.push({ ...f.company, resourceType: 'Company' });
          } else if (f.site) {
            formattedFacilities.push({ ...f.site, resourceType: 'Site' });
          } else if (f.event) {
            formattedFacilities.push({ ...f.event, resourceType: 'Event' });
          }
        });

        formattedPrice = {
          ...priceData,
          facilities: formattedFacilities,
        };

        formattedPricing.push(formattedPrice);
      }
    });

    if (!response.error) {
      yield put(actions.requestPricePackagesSuccess(formattedPricing, pricingErrorMessages));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getPricingPackagesSaga');
  }
}

function* addFacilitiesToPriceGroupSaga(companyId, action) {
  const state = yield select();
  const { newFacilities } = state.pricing.updatePricing;

  const reqBody = {
    newFacilities,
    priceModifiers: action.priceModifiers,
    membershipId: action.membershipId,
  };

  try {
    const response = yield call(addFacilitiesToPriceGroupService, companyId, reqBody);
    if (!response.error) {
      const { pricing, pricingErrorMessages } = response.data;
      yield put(actions.requestPricePackagesSuccess(pricing, pricingErrorMessages));
      yield put(actions.updatePricingGroupSuccess());
      yield put(sessionActions.updateSuccessfulSnackbar('Facilities successfully added.', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'addFacilitiesToPriceGroupSaga');
    yield put(actions.updatePricingGroupFail());
    yield put(sessionActions.updateErrorSnackbar(`Pricing update failed : ${error.error}`, true));
  }
}

function* addRuleToPriceGroupSaga(companyId, action) {
  const state = yield select();
  const { priceModifier } = state.pricing.updatePricing;
  const reqBody = {
    priceModifier,
    priceGroup: action.priceGroup,
  };
  try {
    const response = yield call(addRuleToPriceGroupService, companyId, reqBody);
    if (!response.error) {
      const { pricing, pricingErrorMessages } = response.data;
      yield put(actions.requestPricePackagesSuccess(pricing, pricingErrorMessages));
      yield put(actions.updatePricingGroupSuccess());
      yield put(sessionActions.updateSuccessfulSnackbar('Rule successfully added.', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'addRuleToPriceGroupSaga');
    yield put(actions.updatePricingGroupFail());
    yield put(sessionActions.updateErrorSnackbar(`Pricing update failed : ${error.error}`, true));
  }
}

function* deletePriceModifierSaga(companyId, action) {
  const { id } = action;
  const reqBody = { priceModifierId: id };
  try {
    const response = yield call(deletePriceModifierService, companyId, reqBody);
    if (!response.error) {
      yield put(actions.requestPricePackagesRetrieval());
      yield put(actions.updatePricingGroupSuccess());
      yield put(sessionActions.updateSuccessfulSnackbar('Rule successfully removed.', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'deletePriceModifierSaga');
    yield put(actions.updatePricingGroupFail());
    yield put(sessionActions.updateErrorSnackbar(`Pricing update failed : ${error.error}`, true));
  }
}

function* requestPriceGroupCreationSaga(companyId) {
  const state = yield select();
  const { priceGroup } = state.pricing;
  const reqBody = {
    priceGroup,
  };
  try {
    const response = yield call(requestPriceGroupCreationService, companyId, reqBody);
    if (!response.error) {
      yield put(actions.requestPriceGroupCreationSuccess());
      yield put(actions.requestPricePackagesRetrieval());
      yield put(actions.resetPriceGroupCreation());
      yield put(sessionActions.updateSuccessfulSnackbar('Price group successfully created.', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'requestPriceGroupCreationSaga');
    yield put(actions.updatePricingGroupFail());
    yield put(sessionActions.updateErrorSnackbar(`Pricing update failed : ${error.error}`, true));
  }
}

function* editPriceGroupSaga(companyId) {
  const state = yield select();
  const { priceGroup, priceModifiersToDelete } = state.pricing;
  const reqBody = {
    priceGroup,
    priceModifiersToDelete,
  };
  try {
    const response = yield call(updatePriceGroupStoreService, companyId, reqBody);
    if (!response.error) {
      yield put(actions.requestPricePackagesRetrieval());
      yield put(sessionActions.updateSuccessfulSnackbar('Price group successfully saved.', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'editPriceGroupSaga');
    yield put(actions.updatePricingGroupFail());
    yield put(sessionActions.updateErrorSnackbar(`Pricing update failed : ${error.error}`, true));
  }
}

function* duplicatePriceGroupSaga(companyId) {
  const state = yield select();
  const { priceGroup, priceModifiersToDelete } = state.pricing;
  const reqBody = {
    priceGroup,
    priceModifiersToDelete,
  };
  try {
    const response = yield call(requestDuplicatePriceGroupService, companyId, reqBody);
    if (!response.error) {
      yield put(actions.priceGroupDuplicateSuccessful());
      yield put(actions.requestPricePackagesRetrieval());
      yield put(sessionActions.updateSuccessfulSnackbar('Price group successfully duplicated.', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'duplicatePriceGroupSaga');
    yield put(actions.updatePricingGroupFail());
    yield put(sessionActions.updateErrorSnackbar(`Price group duplicated failed : ${error.error}`, true));
  }
}

function* deletePriceGroupSaga(companyId, action) {
  const { priceGroup } = action;
  const reqBody = {
    priceGroup,
  };
  try {
    const response = yield call(requestPriceGroupDeletionService, companyId, reqBody);
    if (!response.error) {
      yield put(actions.requestPricePackagesRetrieval());
      yield put(sessionActions.updateSuccessfulSnackbar('Price group successfully deleted.', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'deletePriceGroupSaga');
    yield put(actions.updatePricingGroupFail());
    yield put(sessionActions.updateErrorSnackbar(`Price group deletion failed : ${error.error}`, true));
  }
}

function* savePriceRuleSaga(companyId, action) {
  const state = yield select();
  const { priceModifier } = state.pricing;
  const reqBody = {
    priceModifierId: priceModifier.id,
    priceModifier,
  };
  try {
    const response = yield call(requestPriceRuleSaveService, companyId, reqBody);
    if (!response.error) {
      yield put(actions.requestPricePackagesRetrieval());
      if (!action.isEvent) {
        yield put(sessionActions.updateSuccessfulSnackbar('Price rule successfully saved.', true));
      }
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'savePriceRuleSaga');
    yield put(actions.updatePricingGroupFail());
    yield put(sessionActions.updateErrorSnackbar(`Price rule save failed : ${error.error}`, true));
  }
}

// Watchers
export function* getPricingPackagesWatcher(companyId) {
  yield takeEvery(actions.PRICE_PACKAGES_RETRIEVAL_REQUESTED, getPricingPackagesSaga, companyId);
}

export function* addFacilitiesToPriceGroupWatcher(companyId) {
  yield takeEvery(actions.REQUEST_ADD_FACILITIES_TO_PRICE_GROUP,
    addFacilitiesToPriceGroupSaga, companyId);
}

export function* addRuleToPriceGroupWatcher(companyId) {
  yield takeEvery(actions.REQUEST_ADD_RULE_TO_PRICE_GROUP, addRuleToPriceGroupSaga, companyId);
}

export function* deletePriceModifierWatcher(companyId) {
  yield takeEvery(actions.REMOVE_PRICE_MODIFIER, deletePriceModifierSaga, companyId);
}
export function* requestPriceGroupCreationWatcher(companyId) {
  yield takeEvery(actions.PRICE_GROUP_CREATION_REQUEST, requestPriceGroupCreationSaga, companyId);
}
export function* editPriceGroupWatcher(companyId) {
  yield takeEvery(actions.REQUEST_EDIT_PRICE_GROUP, editPriceGroupSaga, companyId);
}
export function* duplicatePriceGroupWatcher(companyId) {
  yield takeEvery(actions.DUPLICATE_PRICE_GROUP_REQUESTED, duplicatePriceGroupSaga, companyId);
}
export function* deletePriceGroupWatcher(companyId) {
  yield takeEvery(actions.PRICE_GROUP_DELETION_REQUESTED, deletePriceGroupSaga, companyId);
}
export function* savePriceRuleWatcher(companyId) {
  yield takeEvery(actions.PRICE_RULE_SAVE_REQUESTED, savePriceRuleSaga, companyId);
}
