import React from 'react';
import { connect } from 'react-redux';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import PropTypes from 'prop-types';
// import '../../styles/payments.css';
import moment from 'moment-timezone';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { generateTotalWithTaxText } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import * as actions from '../../reducers/payoutsReducer';
import LoadingSkeletonDataTable from '../../components/LoadingComponents/LoadingSkeletonDataTable';
import Back from '../../components/Back';
import PayoutOrdersTable from './PayoutTable';
import PayoutReconciliationButton from './PayoutReconciliationButton';

const generatePayoutStatusTag = (status) => {
  let orderStatusTag;
  switch (status) {
    case 'paid':
      orderStatusTag = (<div className="successTag">Paid</div>);
      break;
    case 'pending':
      orderStatusTag = (<div className="pendingTag">Pending</div>);
      break;
    case 'in_transit':
      orderStatusTag = (<div className="voidTag">In Transit</div>);
      break;
    case 'failed':
      orderStatusTag = (<div className="failTag">Failed</div>);
      break;
    case 'canceled':
      orderStatusTag = (<div className="voidTag">Cancelled</div>);
      break;
    default:
      break;
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {orderStatusTag}
    </div>
  );
};
class PayoutView extends React.Component {
  componentDidMount() {
    const {
      getPayout,
      match,
    } = this.props;
    const { payoutId } = match.params;
    getPayout(payoutId);
  }

  render() {
    const {
      payout, isLoading, companyInfo,
    } = this.props;
    const { stripeId, currencySym, timezone } = companyInfo;
    let arrivalDate;
    let amount;
    let status;
    let stripePayoutId;
    if (payout) {
      arrivalDate = payout.arrivalDate;
      amount = payout.amount;
      status = payout.status;
      stripePayoutId = payout.stripeId;
    }

    return (
      <AlternativelyVisible condition={isLoading && payout}>
        <LoadingSkeletonDataTable />
        <>
          <div style={{ padding: 20 }}>
            <Back />
            <div className="subscription-view-card">
              <h2><b> Payout Details </b></h2>
              <div className="break" />
              <h3>
                <ScheduleIcon style={{
                  alignSelf: 'center',
                  verticalAlign: 'middle',
                  color: '#4781e2',
                  marginRight: '10px',
                }}
                />
                {`Arrival Date: ${moment(arrivalDate).tz(timezone).format('ll')}`}
              </h3>
              <div className="break" />
              <h3>
                <AccountBalanceIcon style={{
                  alignSelf: 'center',
                  verticalAlign: 'middle',
                  color: '#4781e2',
                  marginRight: '10px',
                }}
                />
                {`Total: ${generateTotalWithTaxText({ total: amount }, currencySym, true)}`}
              </h3>
              <div className="break" />
              <div className="break" />
              <div>
                {generatePayoutStatusTag(status)}
              </div>
              <div className="break" />
              <h3>
                <a target="_blank" rel="noopener noreferrer" href={`https://dashboard.stripe.com/${stripeId}/payouts/${stripePayoutId}`}>
                  View in Stripe
                </a>
              </h3>
              <ConditionallyVisible condition={payout}>
                <div className="break" />
                <PayoutReconciliationButton payout={payout} />
              </ConditionallyVisible>
            </div>
          </div>
          <PayoutOrdersTable
            payout={payout}
            stripeCompanyId={stripeId}
            currencySym={currencySym}
            timezone={timezone}
          />
        </>
      </AlternativelyVisible>
    );
  }
}

PayoutView.propTypes = {
  getPayout: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  payout: PropTypes.shape().isRequired,
  companyInfo: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    payouts, companies,
  } = state;
  const { payout } = payouts;
  const { companyInfo } = companies;
  return {
    payout,
    companyInfo,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getPayout: (id) => dispatch(actions.requestPayout(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayoutView);
