/* eslint-disable no-case-declarations */
export const EVENTS_RETRIEVAL_REQUESTED = 'EVENTS_RETRIEVAL_REQUESTED';
export const EVENTS_RETRIEVAL_SUCCEEDED = 'EVENTS_RETRIEVAL_SUCCEEDED';
export const EVENTS_RETRIEVAL_FAILED = 'EVENTS_RETRIEVAL_FAILED';

export const EVENTS_FAILED = 'EVENTS_FAILED';

export const EVENT_RETRIEVAL_REQUESTED = 'EVENT_RETRIEVAL_REQUESTED';
export const EVENT_RETRIEVAL_SUCCEEDED = 'EVENT_RETRIEVAL_SUCCEEDED ';
export const EVENT_FAILED = 'EVENT_FAILED';
export const TOGGLE_EVENT_LOADING = 'TOGGLE_EVENT_LOADING';

export const UPDATE_EVENT_STORE = 'UPDATE_EVENT_STORE';
export const UPDATE_EVENT_FORM_STORE = 'UPDATE_EVENT_FORM_STORE';

// Creating a schedule group.
export const CREATE_EVENT_REQUESTED = 'CREATE_EVENT_REQUESTED';
export const CREATE_EVENT_SUCCEEDED = 'CREATE_EVENT_SUCCEEDED';

export const EVENT_STORE_RESET = 'EVENT_STORE_RESET';
export const TOGGLE_SELECTED_EVENTS_VIEW = 'TOGGLE_SELECTED_EVENTS_VIEW';

export const UPDATE_EVENT_CONSENT_FORM_STORE = 'UPDATE_EVENT_CONSENT_FORM_STORE';

// Editing
export const TOGGLED_EVENT_EDITING = 'TOGGLED_EVENT_EDITING';
export const UPDATE_EVENT_REQUESTED = 'UPDATE_EVENT_REQUESTED';
export const SAVE_EVENT_ADDONS_REQUESTED = 'SAVE_EVENT_ADDONS_REQUESTED';
export const SAVE_EVENT_ADDONS_SUCCEEDED = 'SAVE_EVENT_ADDONS_SUCCEEDED';
export const REMOVE_EVENT_ADDONS_REQUESTED = 'REMOVE_EVENT_ADDONS_REQUESTED';
export const REMOVE_EVENT_ADDONS_SUCCEEDED = 'REMOVE_EVENT_ADDONS_SUCCEEDED';

export const ASSIGN_EVENT_AGENT = 'ASSIGN_EVENT_AGENT';
export const REMOVE_EVENT_AGENT = 'REMOVE_EVENT_AGENT';

export const DELETE_EVENT_REQUESTED = 'DELETE_EVENT_REQUESTED';

export const FORM_DOB_VALIDATION_DIALOG = 'FORM_DOB_VALIDATION_DIALOG';

export const initialState = {
  events: [],
  subEventsStore: [],
  eventStore: {
    status: 'INACTIVE',
    customForm: false,
    subEventsRequired: false,
    isOpen: false,
    isInternal: false,
    addons: [],
  },
  event: {
    defaultForm: [],
  },
  eventForm: {
    attendeeFirstName: true,
    attendeeLastName: true,
    dateOfBirth: false,
    AgeATOAttendance: false,
    address1: false,
    address2: false,
    address3: false,
    postcode: false,
    contactNumber: false,
    mobile: false,
    gender: false,
    genderOther: false,
    femaleOnlySection: false,
    school: false,
    club: false,
    team: false,
    email: false,
    allergies: false,
    medication: false,
    medicalInformation: false,
    guardianFirstName: false,
    guardianLastName: false,
    guardianAddress: false,
    guardianEmailAddress: false,
    guardianPhoneNumber: false,
    guardianPostcode: false,
    guardianPhotographUsageAuth: false,
    guardianFirstAidAuth: false,
    secondaryGuardianFirstName: false,
    secondaryGuardianLastName: false,
    secondaryGuardianAddress: false,
    secondaryGuardianEmailAddress: false,
    secondaryGuardianPhoneNumber: false,
    secondaryGuardianPostcode: false,
    secondaryGuardianPhotographUsageAuth: false,
    secondaryGuardianFirstAidAuth: false,
    faNumber: false,
    schoolYear: false,
    studentId: false,
    membershipNumber: false,
    authorisePhotography: false,
    authoriseFirstAid: false,
    consentToMarketing: false,
    clubRole: false,
    ethnicity: false,
    disabilityInformation: false,
    guardianOrEmergencyContactName: false,
    guardianOrEmergencyContactPhone: false,
  },
  selectedToggle: 'ACTIVE',
  consentForms: [],
  editingEvent: false,
  eventsLoading: false,
  eventLoading: false,
  eventFormDobDialog: false,
};

export function getEvents(status, orderBy) {
  return { type: EVENTS_RETRIEVAL_REQUESTED, status, orderBy };
}
export function getEvent(id) {
  return { type: EVENT_RETRIEVAL_REQUESTED, id };
}
export function deleteEvent(id) {
  return { type: DELETE_EVENT_REQUESTED, id };
}
export function removeEventAgent(eventId, eventAgentId) {
  return { type: REMOVE_EVENT_AGENT, eventId, eventAgentId };
}
export function requestEventCreation(eventType = 'EVENT', parentEventId = null) {
  return { type: CREATE_EVENT_REQUESTED, eventType, parentEventId };
}
export function updateEventStore(eventProperty) {
  return { type: UPDATE_EVENT_STORE, eventProperty };
}
export function updateEventFormStore(eventProperty) {
  return { type: UPDATE_EVENT_FORM_STORE, eventProperty };
}
export function updateEventConsentFormsStore(consentForm, fieldName) {
  return { type: UPDATE_EVENT_CONSENT_FORM_STORE, consentForm, fieldName };
}
export function eventsRequestSucceeded(events) {
  return { type: EVENTS_RETRIEVAL_SUCCEEDED, events };
}
export function eventsRequestFailed() {
  return { type: EVENTS_RETRIEVAL_FAILED };
}
export function eventRequestSucceeded(event) {
  return { type: EVENT_RETRIEVAL_SUCCEEDED, event };
}
export function resetEventStore() {
  return { type: EVENT_STORE_RESET };
}
export function toggleSelectedEventsView(status) {
  return { type: TOGGLE_SELECTED_EVENTS_VIEW, status };
}
export function requestEventUpdate(eventId, reqBody, parentEventId) {
  return {
    type: UPDATE_EVENT_REQUESTED, eventId, reqBody, parentEventId,
  };
}

export function assignEventAgent(eventId, agents) {
  return {
    type: ASSIGN_EVENT_AGENT, eventId, agents,
  };
}

export function toggleEventEditing(isEditing) {
  return { type: TOGGLED_EVENT_EDITING, isEditing };
}
export function requestSaveEventAddons(eventId) {
  return { type: SAVE_EVENT_ADDONS_REQUESTED, eventId };
}
export function requestRemoveEventAddons(eventId, addonId) {
  return { type: REMOVE_EVENT_ADDONS_REQUESTED, eventId, addonId };
}
export function saveEventAddonsSucceeded(event) {
  return { type: SAVE_EVENT_ADDONS_SUCCEEDED, event };
}
export function removeEventAddonsSucceeded(event) {
  return { type: REMOVE_EVENT_ADDONS_SUCCEEDED, event };
}
export function toggleEventFormDobDialog(open) {
  return { type: FORM_DOB_VALIDATION_DIALOG, open };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_EVENT_LOADING:
      return { ...state, eventLoading: action.status };
    case EVENTS_RETRIEVAL_REQUESTED:
      return { ...state, eventsLoading: true };
    case EVENTS_RETRIEVAL_SUCCEEDED:
      return { ...state, events: action.events, eventsLoading: false };
    case EVENTS_RETRIEVAL_FAILED:
      return { ...state, eventsLoading: false };
    case EVENT_RETRIEVAL_SUCCEEDED:
      return { ...state, event: action.event };
    case SAVE_EVENT_ADDONS_SUCCEEDED:
      return { ...state, eventStore: { ...action.event } };
    case REMOVE_EVENT_ADDONS_SUCCEEDED:
      return { ...state, eventStore: { ...action.event } };
    case UPDATE_EVENT_STORE:
      if (Object.keys(action.eventProperty)[0] === 'subEventsRequired') {
        if (action.eventProperty.subEventsRequired) {
          return {
            ...state,
            eventStore: {
              ...initialState.eventStore,
              name: state.eventStore.name,
              description: state.eventStore.description,
              subEventsRequired: action.eventProperty.subEventsRequired,
              isOpen: state.eventStore.isOpen,
            },
          };
        }
      }
      return { ...state, eventStore: { ...state.eventStore, ...action.eventProperty } };
    case UPDATE_EVENT_FORM_STORE:
      return {
        ...state,
        eventForm: { ...state.eventForm, ...action.eventProperty },
      };
    case UPDATE_EVENT_CONSENT_FORM_STORE:
      const consent = {
        name: action.fieldName ? action.fieldName.replace(/\s/g, '') : null,
        label: action.consentForm,
        type: 'CONSENT',
        visible: true,
      };
      const formArray = [];
      formArray.push(consent);
      return {
        ...state,
        consentForms: [...state.consentForms, ...formArray],
      };
    case TOGGLE_SELECTED_EVENTS_VIEW:
      return {
        ...state,
        selectedToggle: action.status,
      };
    case TOGGLED_EVENT_EDITING:
      return {
        ...state,
        editingEvent: action.isEditing,
      };
    case EVENT_STORE_RESET: {
      return {
        ...state,
        eventStore: {
          ...initialState.eventStore,
          subEventsRequired: state.eventStore.subEventsRequired,
        },
        eventForm: initialState.eventForm,
      };
    }
    case FORM_DOB_VALIDATION_DIALOG:
      return {
        ...state,
        eventFormDobDialog: action.open,
      };
    default:
      return state;
  }
};
