import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import ToggleButtons from '../../../../components/ToggleButtons';
import MenuHeading from '../../../../components/MenuHeading';
import * as actions from '../../../../reducers/subscriptionsReducer';
import CustomTextbox from '../../../../shared-components/CustomTextBoxWithLabel';

const recurrenceButtonsData = [{
  buttonTitle: 'Indefinitely',
  buttonValue: 'INDEFINITELY',
},
{
  buttonTitle: 'Set End Date',
  buttonValue: 'ENDDATE',
},
{
  buttonTitle: 'Set Number of Weeks',
  buttonValue: 'NUMOFWEEKS',
}];

const BlockBookingStepperRecurrence = (props) => {
  const {
    updateSubscriptionStore,
    subscription,
  } = props;

  const { validFrom, validTo } = subscription;

  const handleRecurrenceToggle = (validToType) => {
    if (validToType === 'INDEFINITELY') {
      updateSubscriptionStore({
        validToType,
        validTo: null,
        numOfWeeks: null,
      });
    } else if (validToType === 'ENDDATE') {
      updateSubscriptionStore({
        validToType,
      });
    } else {
      updateSubscriptionStore({
        validToType,
      });
    }
  };

  const handleEndDate = (date) => {
    updateSubscriptionStore({
      validTo: date.endOf('day').format(),
      numOfWeeks: moment(date).diff(validFrom, 'weeks') + 1,
    });
  };

  const handleNumOfWeeks = (numWeeks) => {
    updateSubscriptionStore({
      numOfWeeks: numWeeks,
      validTo: moment(validFrom).add(numWeeks - 1, 'weeks').endOf('day').format(),
    });
  };

  const formattedValidFrom = moment(validFrom);
  const validFromDayOfWeek = formattedValidFrom.day();
  const isDayBlocked = (day) => {
    const dayMoment = moment(day);
    return dayMoment.day() !== validFromDayOfWeek;
  };

  return (
    <div>
      <MenuHeading title="How long do you want this block booking to be reserved for?" />
      <ToggleButtons
        buttonsData={recurrenceButtonsData}
        changeOption={(option) => handleRecurrenceToggle(option)}
        value={subscription.validToType}
      />
      <ConditionallyVisible condition={subscription.validToType === 'ENDDATE'}>
        <DayPickerSingleDateController
          initialVisibleMonth={() => (validTo !== null ? moment(validTo) : moment())}
          focused
          onDateChange={(date) => handleEndDate(date)}
          date={validTo !== null ? moment(validTo) : validTo}
          noBorder
          isOutsideRange={(day) => day.isBefore(moment(validFrom))}
          isDayBlocked={isDayBlocked}
        />
      </ConditionallyVisible>
      <ConditionallyVisible condition={subscription.validToType === 'NUMOFWEEKS'}>
        <CustomTextbox
          id="numOfWeeks"
          labelText="Number of weeks"
          displayValue={subscription.numOfWeeks}
          onChangeAction={(e) => handleNumOfWeeks(e.numOfWeeks)}
        />
      </ConditionallyVisible>

    </div>
  );
};

BlockBookingStepperRecurrence.propTypes = {
  subscription: PropTypes.shape().isRequired,
  updateSubscriptionStore: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  subscription: state.subscriptions.subscription,
});

const mapDispatchToProps = (dispatch) => ({
  updateSubscriptionStore: (keyWithValue) => dispatch(
    actions.updateSubscriptionStore(keyWithValue),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockBookingStepperRecurrence);
