import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import {
  Button,
  Divider,
  Typography,
  Card,
  CardContent,
  Grid,
  Tooltip,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import { getCompanyInfo, updateCompany } from '../../reducers/companiesReducer';
import { getCompanyUsers } from '../../reducers/companyUsersReducer';
import { triggerCronJob, changeCronJob } from '../../reducers/utilsReducer';
import OnboardingChecklist from './onboardingChecklist';
import PitchbookingUserOnly from '../../helpers/PitchbookingUserOnly';
import { infoStatuses } from './constants';
import { determineRecurrenceText } from './companies';
import {
  AccountManager,
  CompnayStatus,
  CreateXeroCompany,
  CustomerTestimonial,
  FacilityInfo,
  SuccessPlan,
  ContactUs,
  GoLive,
  FacilityManagers,
  FeeCalculationSection,
} from './components';

const AccountDashboard = () => {
  const dispatch = useDispatch();
  const companyInfo = useSelector((state) => state.companies.companyInfo);
  const companyId = companyInfo.id;
  const {
    stripeId, hubspotCompanyId, xeroContactId, accounting,
  } = companyInfo;
  const [infoStatus, setInfoStatus] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [facilityInfo, setFacilityInfo] = useState({});

  useEffect(() => {
    dispatch(getCompanyInfo(true));
    dispatch(getCompanyUsers());
  }, []);

  useEffect(() => {
    setInfoStatus(companyInfo.warningMessageForAdmins?.type || 'NOT_SET');
    setInfoMessage(companyInfo.warningMessageForAdmins?.value || '');
    setFacilityInfo(companyInfo?.onboarding?.facilityInfo);

    if (infoStatus === 'NOT_SET' || infoStatus === null) {
      setInfoMessage(null);
    }
  }, [companyInfo]);

  useEffect(() => {
    if (infoStatus === 'NOT_SET' || infoStatus === null) {
      setInfoMessage(null);
    }
  }, [infoStatus]);

  const commissions = [
    { label: 'Facilities', value: companyInfo.commission, type: 'percentage' },
    { label: 'Events', value: companyInfo.eventsCommission, type: 'percentage' },
    { label: 'Leagues', value: companyInfo.leaguesCommission, type: 'percentage' },
    { label: 'Memberships', value: companyInfo.membershipCommission, type: 'percentage' },
    { label: 'Terminal', value: companyInfo.terminalCommission, type: 'percentage' },
    { label: 'Booking Fee', value: Number(companyInfo.bookingFee), type: 'fixed' },
  ];

  const currencySym = '£'; // TODO: Get from company info

  // TODO: Break into components
  return (
    <PitchbookingUserOnly>
      <Typography variant="h5" style={{ fontWeight: '600', padding: '2rem', textDecoration: 'underline' }}>
        Account Dashboard
      </Typography>

      <div style={{
        display: 'flex',
        flex: 1,
        padding: '0 2rem',
        backgroundColor: '#fff',
        gap: '2rem',
        height: '100%',
      }}
      >
        {/* Col 1  */}
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {/* Assign Account Manager */}
            <AccountManager companyInfo={companyInfo} />

            {/* Company status */}
            <CompnayStatus companyInfo={companyInfo} updateCompany={updateCompany} />

            {/* Facility Managers */}
            <FacilityManagers companyInfo={companyInfo} />

            {/* Onboarding Checklist */}
            <>
              <Typography style={{ textDecoration: 'underline' }}>
                Onboarding Checklist
              </Typography>
              {companyInfo.onboarding?.onboardingStartDate && (
              <Typography>
                Started :
                {' '}
                {companyInfo.onboarding?.onboardingStartDate}
                {' '}
                <br />
                <small>
                  {moment(companyInfo.onboarding?.onboardingStartDate).diff(moment(), 'days') * -1}
                  {' days ago'}
                </small>
              </Typography>
              )}
              {companyInfo.onboarding?.onboardingCompletionDate && (
              <Typography>
                Completed :
                {' '}
                {companyInfo.onboarding?.onboardingCompletionDate}
                {' '}
                <br />
                <small>
                  {moment(companyInfo.onboarding?.onboardingCompletionDate).diff(moment(), 'days') * -1}
                  {' days ago'}
                </small>
              </Typography>
              )}
              <OnboardingChecklist />
            </>

            <Divider />
          </div>
        </div>

        {/* Col 2 */}
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {/* Information Message */}
            <>
              <Typography style={{ textDecoration: 'underline' }}>
                Information Message
              </Typography>
              <Typography variant="body" style={{ color: '#555' }}>
                To include a link url must start with https://
              </Typography>
              <PBInput
                id="status"
                type="select"
                value={infoStatus}
                options={infoStatuses}
                onChange={(e) => setInfoStatus(e.target.value)}
              />
              <PBInput
                id="status"
                type="text"
                value={infoMessage}
                onChange={(e) => setInfoMessage(e.target.value)}
                placeholder="Message"
              />
              <Button
                variant="contained"
                color="primary"
                type="button"
                style={{ width: '50%', alignSelf: 'end' }}
                onClick={() => {
                  dispatch(
                    updateCompany(companyId,
                      { warningMessageForAdmins: infoStatus === 'NOT_SET' ? null : { type: infoStatus, value: infoMessage } }),
                  );
                }}
              >
                Save
              </Button>
            </>

            <Divider />

            {/* Success Plan */}
            <SuccessPlan companyInfo={companyInfo} updateCompany={updateCompany} />

            {/* Customer Testimonial */}
            <CustomerTestimonial companyInfo={companyInfo} updateCompany={updateCompany} />
          </div>
        </div>

        {/* Col 3 */}
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>

            {/* Facility Count */}
            <FacilityInfo facilityInfo={facilityInfo} />

            {/* Hubspot link */}
            <>
              <Typography style={{ textDecoration: 'underline' }}>
                Company Hubspot
              </Typography>
              {hubspotCompanyId && (
              <Typography style={{ alignSelf: 'end' }}>
                <a href={`https://app.hubspot.com/contacts/4272364/record/0-2/${hubspotCompanyId}`} target="_blank" rel="noreferrer">
                  View Company Hubspot
                </a>
              </Typography>
              )}
            </>

            <Divider />

            {!companyInfo.xeroContactId
              ? <CreateXeroCompany company={companyInfo} />
              : (
                <>
                  <>
                    {xeroContactId && (
                    <>
                      <Typography>
                        <a href={`https://go.xero.com/app/!WmVwF/contacts/contact/${xeroContactId}`} target="_blank" rel="noreferrer">
                          Xero Contact
                        </a>
                      </Typography>
                    </>
                    )}
                  </>
                  <>
                    {accounting && accounting?.xeroInfo?.repeatingInvoices?.map((info) => (
                      info?.repeatingInvoiceId && info?.status && (
                      <>
                        {info && (
                        <Typography>
                          <a href={['https://go.xero.com/RepeatTransactions/Edit.aspx?invoiceID=', info?.repeatingInvoiceId, '&type=AR'].join('')} target="_blank" rel="noreferrer">
                            Repeating Xero Invoice
                          </a>
                          <span style={{ fontSize: '12px', marginLeft: '8px' }}>
                            {info?.status === 'DRAFT' && 'Saved as Draft'}
                            {info?.status === 'AUTHORISED' && !info?.approvedForSending && 'Created and unsent'}
                            {info?.status === 'AUTHORISED' && info?.approvedForSending && 'Created and sent'}
                          </span>
                        </Typography>
                        )}
                        <span style={{ fontSize: '12px', marginLeft: '8px', marginTop: 0 }}>
                          {accounting?.xeroInfo?.contacts?.length > 0 ? 'Xero email recipients:' : 'No Xero email recipients.'}
                          {accounting?.xeroInfo?.contacts?.length > 0 ? (
                            accounting?.xeroInfo?.contacts
                              .sort((a, b) => {
                                if (a?.primary && !b?.primary) return -1;
                                if (!a?.primary && b?.primary) return 1;

                                if (a?.includeInEmails && !b?.includeInEmails) return -1;
                                if (!a?.includeInEmails && b?.includeInEmails) return 1;

                                return a?.firstName?.localeCompare(b?.firstName);
                              })
                              .map((c) => (
                                <li key={c.email}>
                                  {[c.firstName, ' ', c.lastName, ' (', c.email, ') ', c.primary ? '(Primary)' : '', !c.includeInEmails ? '(Excluded)' : ''].join('')}
                                </li>
                              ))
                          ) : null}
                        </span>
                      </>
                      )
                    ))}

                  </>
                  <>
                    {accounting?.stripeInfo?.customers
        && accounting?.stripeInfo?.customers.length > 0 && (
        <Typography>
          {[...new Set(accounting.stripeInfo.customers)].map((c) => (
            <div key={c.id}>
              <a href={`https://dashboard.stripe.com/customers/${c.id}`} target="_blank" rel="noopener noreferrer">
                Stripe Customer
              </a>
              <span style={{ fontSize: '12px', marginLeft: '8px' }}>{`(${c.email})`}</span>
            </div>
          ))}
        </Typography>
                    )}
                  </>
                  <>
                    {accounting?.xeroInfo?.repeatingInvoices?.map((info) => (
                      info?.total && (
                      <Typography key={info?.total}>
                        <div>
                          {['Price: ', currencySym, parseFloat(info?.total).toFixed(2), ' (', currencySym, parseFloat(info?.totalExTax).toFixed(2), ' + ', currencySym, parseFloat(info?.tax).toFixed(2), ' tax)'].join('')}
                        </div>
                      </Typography>
                      )
                    ))}
                  </>
                  <>
                    {accounting?.xeroInfo?.repeatingInvoices?.map((info) => (
                      info?.schedule?.unit && info?.schedule?.period && (
                      <Typography key={info?.schedule?.unit}>
                        <div>
                          {['Xero Invoice Recurrence: ', determineRecurrenceText(info)].join('')}
                        </div>
                      </Typography>
                      )
                    ))}
                  </>
                  <>
                    {accounting?.pausedUntil ? (
                      <Typography>
                        <div>
                          {`Stripe Payment paused until: ${moment(accounting.pausedUntil).format('ddd, DD-MMM-YYYY')} (${moment(accounting.pausedUntil).fromNow()})`}
                        </div>
                      </Typography>
                    ) : (
                      accounting?.nextStripePaymentDate && (
                      <Typography>
                        <div>
                          {`Next Stripe Payment Date: ${moment(accounting.nextStripePaymentDate).format('ddd, DD-MMM-YYYY')} (${moment(accounting.nextStripePaymentDate).fromNow()})`}
                        </div>
                      </Typography>
                      )
                    )}
                  </>
                  <>
                    {accounting?.xeroInfo?.repeatingInvoices?.map((info) => (
                      info?.schedule?.nextScheduledDate && (
                      <Typography key={info?.schedule?.nextScheduledDate}>
                        <div>
                          {['Next Xero Repeating Invoice Date: ', moment(info?.schedule?.nextScheduledDate).format('ddd, DD-MMM-YYYY'), ' (', moment(info?.schedule?.nextScheduledDate).fromNow(), ')'].join('')}
                        </div>
                      </Typography>
                      )
                    ))}
                  </>

                  <>
                    {accounting?.contractRenewalDate && (
                    <Tooltip title={accounting.contractDetails || ''}>
                      <Typography variant="body2">
                        {`Contract Renewal Date: ${moment(accounting.contractRenewalDate).format('DD-MMM-YYYY')} 
                          (${moment(accounting.contractRenewalDate).fromNow()})`}
                      </Typography>
                    </Tooltip>
                    )}
                  </>

                  <IconButton
                    style={{ width: '5%', alignSelf: 'end' }}
                    onClick={() => {
                      dispatch(changeCronJob('refreshSingleCompanyAccounting'));
                      dispatch(triggerCronJob());
                    }}
                  >
                    <RefreshIcon fontSize="small" />
                  </IconButton>
                  <Divider />
                </>
              )}

            {/* Go Live Date */}
            <GoLive companyInfo={companyInfo} updateCompany={updateCompany} />

            <Divider />

            {/* Enable Contact Us */}
            <ContactUs companyInfo={companyInfo} updateCompany={updateCompany} />

            {/* Asana board link */}
            <>
              <Typography style={{ textDecoration: 'underline' }}>
                Upsell Asana Board
              </Typography>
              <Typography style={{ alignSelf: 'end' }}>
                <a href="https://app.asana.com/0/1206850102024546/1206851115026167" target="_blank" rel="noreferrer">
                  Open Upsell Board
                </a>
              </Typography>
            </>
            <Divider />

            {/* Billing Agreement */}
            {companyInfo.billings && companyInfo.billings.length > 0 && (
            <>
              <Typography style={{ textDecoration: 'underline' }}>
                Billing Agreements
              </Typography>
              {companyInfo.billings
                .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                .map((billing, index) => (
                  <a href={`/agreement/${billing.id}`} target="_blank" rel="noreferrer">
                    <Typography style={{ display: 'block' }}>
                      {index + 1}
                      {'. '}
                      {billing.type.charAt(0).toUpperCase() + billing.type.slice(1).toLowerCase()}
                      {' - '}
                      {billing.status.charAt(0).toUpperCase()
                      + billing.status.slice(1).toLowerCase()}
                      {' - £'}
                      {billing.amount?.toLocaleString()}
                      {billing.gameOn ? ' - Game On' : ''}
                      {billing.required ? ' (Required)' : ''}
                    </Typography>
                  </a>
                ))}
            </>
            )}

            {/* Stripe Connect Account */}
            {stripeId && (
            <>
              <Divider />
              <Typography style={{ textDecoration: 'underline' }}>
                <a href={`https://dashboard.stripe.com/${stripeId}`} target="_blank" rel="noreferrer">
                  Stripe Connect Account
                </a>
              </Typography>
              <Typography style={{ textDecoration: 'underline' }}>
                <a href={`https://dashboard.stripe.com/connect/accounts/${stripeId}/activity?tab=actionsRequired`} target="_blank" rel="noreferrer">
                  Stripe Account Overview
                </a>
              </Typography>
            </>
            )}

            {/* Commission */}
            <>
              <Divider />
              <Typography variant="h6" gutterBottom>
                Commission
              </Typography>
              <Grid container spacing={2}>
                {commissions.map((commission) => {
                  const formatValue = (value, type) => {
                    if (value === null || value === undefined) {
                      return 'None';
                    }
                    if (type === 'percentage') {
                      const percentage = value * 100;
                      return percentage % 1 === 0 ? `${percentage.toFixed(0)}%` : `${percentage.toFixed(1)}%`;
                    }
                    return `£${value.toFixed(2)}`;
                  };

                  return (
                    <Grid item xs={12} sm={6} md={4}>
                      <Card>
                        <CardContent>
                          <Typography variant="subtitle1" gutterBottom>
                            {commission.label}
                          </Typography>
                          <Typography variant="h6" color="textSecondary">
                            {formatValue(commission.value, commission.type)}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </>
            {/* FeeCalculationSection */}
            <>
              <Divider />
              <FeeCalculationSection companyInfo={companyInfo} />
            </>

          </div>
        </div>
      </div>
    </PitchbookingUserOnly>

  );
};

export default AccountDashboard;
