import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FormControlLabel, Checkbox, TextField,
  Dialog, DialogContent, DialogTitle,
  Button, Chip,
} from '@material-ui/core';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as siteActions from '@pitchbooking-dev/pb-shared/lib/reducers/siteReducer';
import { requestUserEmailSending, resetUserEmailSending } from '../../reducers/usersReducer';
import * as enquiryActions from '../../reducers/enquiriesReducer';
import * as usersActions from '../../reducers/usersReducer';
import { useCompany } from '../../hooks';

const UserTableSendMessageDialog = ({
  open,
  onClose,
  userEmails = [],
  userIds = [],
  doRequestUserEmailSending,
  doResetUserEmailSending,
  sendUserCommunicationStatus,
}) => {
  const { id: companyId, products } = useCompany();
  const isCompanySmsEnabled = products?.smsNotifications === 'ENABLED';

  const dispatch = useDispatch();
  const [isSmsRequested, setIsSmsRequested] = useState(false);
  const selectedEnquiryType = useSelector((state) => state.enquiry.selectedEnquiryType);
  const [userTableSendMessageDialogState, setUserTableSendMessageDialogState] = useState({ message: '' });
  const setMessageInState = (message) => setUserTableSendMessageDialogState({ message });
  const { message } = userTableSendMessageDialogState;
  const { error, succeeded, requesting } = sendUserCommunicationStatus;
  const usersToMessge = useSelector((state) => state?.users?.messageUsers);

  // Get sites for pwl manager
  useEffect(() => {
    dispatch(siteActions.getSiteByCompanyId(companyId));
  }, []);

  const resetUsersToMessage = () => {
    dispatch(usersActions.messageUsersReset());
  };

  return (
    <Dialog
      style={{ zIndex: 100001 }}
      open={open}
      disableEnforceFocus
      fullWidth
      maxWidth="sm"
    >
      {/* Title */}
      <DialogTitle id="form-dialog-title">
        Send Message To Following Users:
      </DialogTitle>

      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {/* Users to message */}
          <div style={{
            maxHeight: 300, overflow: 'scroll', margin: '1rem',
          }}
          >
            {usersToMessge
              ? (usersToMessge?.userEmails?.map((userEmail) => (
                <Chip
                  label={userEmail}
                  color="secondary"
                  style={{ margin: '0.2rem' }}
                />
              )))
              : (
                userEmails?.map((userEmail) => (
                  <Chip
                    label={userEmail}
                    color="secondary"
                    style={{ margin: '0.2rem' }}
                  />
                ))
              )}
          </div>

          {/* Message box */}
          <div style={{ margin: '1rem' }}>
            <TextField
              style={{ padding: '10px 10px 0px 10px' }}
              id="description"
              fullWidth
              className="textBox"
              rows="4"
              multiline
              type="textarea"
              value={message}
              onChange={
                (event) => setMessageInState(event.target.value)
              }
            />

            {isCompanySmsEnabled && (
            <div style={{ margin: '1rem' }}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={isSmsRequested}
                    onChange={() => setIsSmsRequested((prev) => !prev)}
                    color="secondary"
                  />
              )}
                label="Also send as SMS message"
              />
            </div>
            )}
          </div>

          {/* Buttons */}
          <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '0.5rem 0rem' }}>
            {/* Close */}
            <Button
              onClick={() => {
                doResetUserEmailSending();
                setMessageInState('');
                onClose(true);
                resetUsersToMessage();
              }}
            >
              Close
            </Button>
            {/* Send */}
            <Button
              color="primary"
              variant="contained"
              onClick={() => doRequestUserEmailSending(message, userEmails, isSmsRequested)}
              disabled={
                    !!(!userEmails?.length > 0 || !message.length > 0)
              }
            >
              Send
            </Button>

          </div>
        </div>

        {/* Error message */}
        <ConditionallyVisible condition={error}>
          <span style={{ color: 'red' }}>
            Apologies. There has been an error sending this message.
          </span>
        </ConditionallyVisible>

        {/* Success message */}
        <ConditionallyVisible condition={succeeded}>
          <span style={{ color: 'green' }}>
            Success! The message has been sent.
          </span>
        </ConditionallyVisible>

      </DialogContent>
      <LinearProgress variant={requesting ? 'indeterminate' : 'determinate'} value={100} />
    </Dialog>
  );
};

UserTableSendMessageDialog.propTypes = {
  userEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
  userIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  doRequestUserEmailSending: PropTypes.func.isRequired,
  doResetUserEmailSending: PropTypes.func.isRequired,
  sendUserCommunicationStatus: PropTypes.shape({
    requesting: PropTypes.bool.isRequired,
    succeeded: PropTypes.bool.isRequired,
    error: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  sendUserCommunicationStatus: state.users.sendUserEmailStatus,
});

const mapDispatchToProps = (dispatch) => ({
  doRequestUserEmailSending: (
    message, emailList, isSmsRequested,
  ) => dispatch(requestUserEmailSending(message, emailList, isSmsRequested)),
  doResetUserEmailSending: () => dispatch(resetUserEmailSending()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTableSendMessageDialog);
