import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import '../../styles/billing.css';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';

const PriceTable = ({
  amount, currencySym, taxRate, offerValid, offerAmount, offerExpiresAt,
}) => {
  const tax = amount * taxRate;
  const total = amount + tax;
  const offerTax = offerAmount * taxRate;
  const offerTotal = offerAmount + offerTax;
  const savings = amount - offerAmount;
  return (
    <div className="billingPriceTable">
      <TableContainer>
        <Table>
          <TableBody>
            {offerValid ? (
              <>
                <TableRow>
                  <TableCell align="left">Monthly fee (Offer)</TableCell>
                  <TableCell align="right">
                    <span style={{ textDecoration: 'line-through' }}>
                      {currencySym}
                      {amount.toFixed(2)}
                    </span>
                    {' '}
                    <span style={{ color: 'green', fontWeight: 'bold' }}>
                      {currencySym}
                      {offerAmount.toFixed(2)}
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Monthly Savings</TableCell>
                  <TableCell align="right" style={{ color: 'red' }}>
                    {currencySym}
                    {savings.toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Offer expires on</TableCell>
                  <TableCell align="right" style={{ fontStyle: 'italic' }}>
                    {moment(offerExpiresAt).format('Do MMM YYYY')}
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell align="left">Monthly fee</TableCell>
                <TableCell align="right">
                  {currencySym}
                  {amount.toFixed(2)}
                </TableCell>
              </TableRow>
            )}
            <ConditionallyVisible condition={taxRate > 0}>
              <TableRow>
                <TableCell align="left">VAT (20%)</TableCell>
                <TableCell align="right">
                  {currencySym}
                  {offerValid ? offerTax.toFixed(2) : tax.toFixed(2)}
                </TableCell>
              </TableRow>
            </ConditionallyVisible>
            <TableRow>
              <TableCell align="left" style={{ fontWeight: 800 }}>Total</TableCell>
              <TableCell align="right" style={{ fontWeight: 800 }}>
                {currencySym}
                {offerValid ? offerTotal.toFixed(2) : total.toFixed(2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

PriceTable.propTypes = {
  amount: PropTypes.number.isRequired,
  currencySym: PropTypes.string,
  offerValid: PropTypes.bool,
  offerAmount: PropTypes.number,
  offerExpiresAt: PropTypes.string,
  taxRate: PropTypes.number,
};

PriceTable.defaultProps = {
  currencySym: '£',
  offerValid: false,
  offerAmount: 0,
  offerExpiresAt: '',
  taxRate: 0.2,
};

export default PriceTable;
