/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';
import { useMobile } from '../../../hooks';

export const ClaimantChangesModal = ({
  isOpen, onClose, claim,
}) => {
  const isMobile = useMobile();

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
      onClose={onClose}
    >
      <DialogTitle>
        Changes made to claimant information
      </DialogTitle>
      <DialogContent>
        {claim && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {claim.company && (
              <div>
                <strong>Company:</strong>
                <div>
                  <pre>
                    {JSON.stringify(claim.company, null, 2)}
                  </pre>
                </div>
              </div>
            )}

            {claim.facilities && (
              <div>
                <strong>Facilities:</strong>
                <div>
                  <pre>
                    {JSON.stringify(claim.facilities, null, 2)}
                  </pre>
                </div>
              </div>
            )}

            {claim.sites && (
              <div>
                <strong>Sites:</strong>
                <div>
                  <pre>
                    {JSON.stringify(claim.sites, null, 2)}
                  </pre>
                </div>
              </div>
            )}

            {claim.schedules && (
              <div>
                <strong>Schedules:</strong>
                <div>
                  <pre>
                    {JSON.stringify(claim.schedules, null, 2)}
                  </pre>
                </div>
              </div>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button type="button" color="secondary" variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
