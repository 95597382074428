import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UserSelectionInfoAlert from '../../../../components/UserSelectionInfoAlert';
import * as subscriptionsActions from '../../../../reducers/subscriptionsReducer';
import TabbedNavigation from '../../../../shared-components/TabbedNavigation';
import PreviousBookerUserSearch from '../../../users/components/PreviousBookerUserSearch';
import NewBookerUserCreation from '../../../users/components/NewBookerUserCreation';

const BlockBookingStepperUserInfoPicker = (props) => {
  const tabs = [];
  const { user, updateSubscriptionStore, updateSubscriptionUserDetails } = props;
  const updateSubscriptionDetails = (updatedUserDetails) => {
    if (updatedUserDetails.email) {
      updateSubscriptionStore({ email: updatedUserDetails.email, id: updatedUserDetails.id });
    }
    updateSubscriptionUserDetails(updatedUserDetails);
  };

  tabs.push({
    id: 0,
    name: 'Existing Customer',
    body: (<PreviousBookerUserSearch
      updateUserDetails={(updatedUserDetails) => updateSubscriptionDetails(updatedUserDetails)}
      user={user}
    />),
  });

  tabs.push({
    id: 1,
    name: 'New Customer',
    body: (<NewBookerUserCreation
      updateUserDetails={(updatedUserDetails) => updateSubscriptionDetails(updatedUserDetails)}
      user={user}
      subscription
    />),
  });

  return (
    <div className="reservation-creation-user-selecton">
      <TabbedNavigation tabsToNavigate={tabs} />
      <div className="tabbed-navigation-tabs-wrapper">
        <UserSelectionInfoAlert />
      </div>
      <br />
    </div>
  );
};

BlockBookingStepperUserInfoPicker.propTypes = {
  user: PropTypes.shape().isRequired,
  updateSubscriptionStore: PropTypes.func.isRequired,
  updateSubscriptionUserDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.subscriptions.userDetailsForSubscription,
});

const mapDispatchToProps = (dispatch) => ({
  updateSubscriptionUserDetails: (keyWithValue) => dispatch(
    subscriptionsActions.updateSubscriptionUserDetails(keyWithValue),
  ),
  updateSubscriptionStore: (keyWithValue) => dispatch(
    subscriptionsActions.updateSubscriptionStore(keyWithValue),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockBookingStepperUserInfoPicker);
