/*
    The HeaderHighlights component is simply a container
    for the HeaderBookings and HeaderRevenue components.
*/

import React from 'react';

import HeaderBookings from './HeaderBookings';
import HeaderRevenue from './HeaderRevenue';

const HeaderHighlights = () => (
  <div id="headerHighlights">
    <HeaderBookings />
    <HeaderRevenue />

  </div>
);

export default HeaderHighlights;
