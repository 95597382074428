import React from 'react';
import { Check, Close } from '@material-ui/icons';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

export const statusConverter = (status, validTo) => {
  switch (status) {
    case 'ACTIVE':
      return {
        backgroundColor: validTo ? 'rgb(130, 224, 170, 0.6)' : 'rgb(222, 102, 96, 0.6)',
        borderColor: validTo ? 'rgb(39, 174, 96, 0.6)' : 'rgb(217, 76, 69, 0.6)',
        text: validTo ? 'Active' : 'Expired',
        icon: validTo ? <Check fontSize="small" /> : <Close fontSize="small" />,
      };

    case 'CONFIRMED':
      return {
        backgroundColor: validTo ? 'rgb(130, 224, 170, 0.6)' : 'rgb(222, 102, 96, 0.6)',
        borderColor: validTo ? 'rgb(39, 174, 96, 0.6)' : 'rgb(217, 76, 69, 0.6)',
        text: validTo ? 'Active' : 'Expired',
        icon: validTo ? <Check fontSize="small" /> : <Close fontSize="small" />,
      };

    default:
      return {
        backgroundColor: 'rgb(206, 206, 206, 0.6)',
        borderColor: '#C2C2C2',
        text: status,
        icon: <PriorityHighIcon fontSize="small" />,
      };
  }
};
