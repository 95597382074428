import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import BlockBookingStepperWrapper from './Stepper/BlockBookingStepperWrapper';
import * as actions from '../../../reducers/subscriptionsReducer';

const BlockBookingCreationDialog = () => {
  const dispatch = useDispatch();
  const subscriptionDialogOpen = useSelector((state) => state.subscriptions.subscriptionDialogOpen);

  const toggleSubscriptionCreationPopup = () => {
    dispatch(actions.toggleSubscriptionCreationPopup());
  };

  return (
    <div>
      <Button
        id="manager-subscription-new-subscription"
        variant="contained"
        color="primary"
        style={{ width: 200 }}
        onClick={toggleSubscriptionCreationPopup}
      >
        New Subscription
      </Button>
      <Dialog
        className="reservation-creation-modal-wrapper"
        open={subscriptionDialogOpen}
        onClose={toggleSubscriptionCreationPopup}
        fullWidth
        fullScreen
      >
        <DialogContent style={{ width: 'flex' }}>
          <BlockBookingStepperWrapper handleClose={toggleSubscriptionCreationPopup} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BlockBookingCreationDialog;
