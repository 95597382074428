import React from 'react';
import moment from 'moment';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ViewListIcon from '@material-ui/icons/ViewList';
import PropTypes from 'prop-types';
import '../../styles/order.css';
import { generateTotalWithTaxText } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import { Button, Tooltip, Typography } from '@material-ui/core';
import PayoutReconciliationButton from './PayoutReconciliationButton';
import { usePitchbookingUser } from '../../hooks';

const generatePayoutStatusTag = (status) => {
  let orderStatusTag;
  switch (status) {
    case 'paid':
      orderStatusTag = (<div className="successTag">Paid</div>);
      break;
    case 'pending':
      orderStatusTag = (<div className="pendingTag">Pending</div>);
      break;
    case 'in_transit':
      orderStatusTag = (<div className="voidTag">In Transit</div>);
      break;
    case 'failed':
      orderStatusTag = (<div className="failTag">Failed</div>);
      break;
    case 'canceled':
      orderStatusTag = (<div className="voidTag">Cancelled</div>);
      break;
    default:
      break;
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {orderStatusTag}
    </div>
  );
};

const PayoutsTable = (props) => {
  const {
    payouts, currencySym, timezone, stripeCompanyId,
  } = props;
  const stripeLinkBase = `https://dashboard.stripe.com/${stripeCompanyId}/payouts/`;
  const { isPitchbookingManager } = usePitchbookingUser();
  console.log(isPitchbookingManager);

  return (
    <MaterialTable
      className="table"
      aria-label="simple table"
      data={payouts}
      icons={{
        NextPage: () => <ChevronRight />,
        PreviousPage: () => <ChevronLeft />,
        SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
      }}
      columns={[
        {
          title: 'Payout ID',
          field: 'stripeId',
          align: 'center',
          sorting: false,
          render: (row) => (
            <Typography variant="body2">
              <a href={`${stripeLinkBase}${row.stripeId}`} target="_blank" rel="noreferrer">
                {row.stripeId}
              </a>
            </Typography>
          ),
        },
        {
          title: 'Created At',
          field: 'createdAt',
          align: 'center',
          render: (row) => (<Typography variant="body2">{moment(row.stripeCreatedAt).tz(timezone).format('ll')}</Typography>),
        },
        {
          title: 'Arrival date',
          field: 'createdAt',
          align: 'center',
          render: (row) => (<Typography variant="body2">{moment(row.arrivalDate).tz(timezone).format('ll')}</Typography>),
        },
        {
          title: 'Amount',
          field: 'total',
          align: 'center',
          render: (row) => {
            const totalText = `${generateTotalWithTaxText({ total: row.amount }, currencySym, true)}`;
            return (
              <Typography variant="body2">{totalText}</Typography>
            );
          },
        },
        {
          title: 'Status',
          field: 'status',
          headerStyle: {
            textAlign: 'center',
          },
          render: (row) => (generatePayoutStatusTag(row.status)),
        },
        {
          title: 'View Breakdown',
          align: 'center',
          sorting: false,
          render: (row) => (
            row.status !== 'pending' && row.reconciled ? (
              <Tooltip title={`View breakdown for ${row.stripeId}`}>
                <a href={`/payouts/${row.id}`} style={{ textDecoration: 'none' }} rel="noreferrer">
                  <Button variant="outlined">
                    <ViewListIcon />
                  </Button>
                </a>
              </Tooltip>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Tooltip title="Payout is pending. Your paid breakdown will be available soon,
              please contact your account manager if you have any queries."
                >
                  <div className="pendingTag" style={{ display: 'flex', flexDirection: 'column', gap: '0.4rem' }}>
                    <Typography variant="body2">
                      Pending
                    </Typography>
                    {isPitchbookingManager && (
                      <a href={`/payouts/${row.id}`} style={{ textDecoration: 'none' }} rel="noreferrer">
                        <Typography variant="caption">
                          View Breakdown
                        </Typography>
                      </a>
                    )}
                  </div>
                </Tooltip>
              </div>
            )
          ),
        },
        {
          title: 'Reconciliation',
          field: 'reconciled',
          align: 'center',

          render: (row) => (<PayoutReconciliationButton payout={row} />),
        },
      ]}
      options={{
        draggable: false,
        search: false,
        tableLayout: 'auto',
        toolbar: false,
        exportButton: false,
        selection: false,
        emptyRowsWhenPaging: false,
        exportFileName: 'Pitchbooking Stripe Payouts',
        pageSize: 25,
        pageSizeOptions: [25, 50, 75, 100],
        showFirstLastPageButtons: false,
        showTitle: true,
        headerStyle: {
          backgroundColor: '#efefef',
          fontWeight: 'bold',
          cursor: 'default',
        },
      }}
      localization={{
        toolbar: {
          exportTitle: 'Download',
        },

      }}
    />
  );
};

PayoutsTable.propTypes = {
  payouts: PropTypes.arrayOf(PropTypes.shape()),
  currencySym: PropTypes.string,
  timezone: PropTypes.string,
  stripeCompanyId: PropTypes.string.isRequired,
};

PayoutsTable.defaultProps = {
  payouts: [],
  currencySym: '£',
  timezone: 'Europe/London',
};

export default PayoutsTable;
