export const defaultColor = ['#24dc89'];
export const defaultTextColor = ['#000'];

export const primaryColors = [
  '#f44336',
  '#e91e63',
  '#FF0000',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
  '#4A4A4A',
  '#000000',
];

export const textColors = [
  '#000',
  '#F5F5F5',
];
