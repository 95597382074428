function findPermutations(array) {
  if (!array || typeof array !== 'object') {
    // eslint-disable-next-line no-throw-literal
    throw 'Argument 1 must be an array';
  } else if (array.length < 2) {
    return array;
  }

  const permutationsArray = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < array.length; i++) {
    const element = [array[i]];

    const remainingObjects = [...array.slice(0, i), ...array.slice(i + 1, array.length)];

    // eslint-disable-next-line no-restricted-syntax
    for (const permutation of findPermutations(remainingObjects)) {
      permutationsArray.push(element.concat(permutation));
    }
  }
  return permutationsArray;
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function factorial(n) {
  if (n === 1) {
    return n;
  }
  return n * factorial(n - 1);
}

export { findPermutations, getRandomInt, factorial };
