/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { Button, Divider, Typography } from '@material-ui/core';

export const SuccessPlan = ({ companyInfo, updateCompany }) => {
  const dispatch = useDispatch();
  const { id, onboarding } = companyInfo;
  const [successPlanLink, setSuccessPlanLink] = useState('');

  useEffect(() => {
    setSuccessPlanLink(onboarding?.successPlanLink);
  }, [onboarding]);

  return (
    <>
      <Typography style={{ textDecoration: 'underline' }}>
        Success Plan
      </Typography>
      <Typography variant="body2" style={{ textDecoration: 'none' }}>
        A link to external url must start with https://
      </Typography>

      <PBInput
        id="successPlan"
        type="text"
        value={successPlanLink}
        onChange={(e) => setSuccessPlanLink(e.target.value)}
        placeholder="Link to success plan"
      />

      {successPlanLink && (
        <Typography style={{ alignSelf: 'end' }}>
          <a href={`${successPlanLink}`} target="_blank" rel="noreferrer">
            Open Company Success Plan
          </a>
        </Typography>
      )}

      <Button
        variant="contained"
        color="primary"
        type="button"
        style={{ width: '50%', alignSelf: 'end' }}
        onClick={() => {
          dispatch(
            updateCompany(id, {
              onboarding: {
                ...companyInfo.onboarding,
                successPlanLink,
              },
            }),
          );
        }}
      >
        Save
      </Button>

      <Divider />
    </>
  );
};
