import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = () => ({
  menuHeading: {
    marginTop: 30,
  },
});

const MenuHeadingNoStyle = (props) => {
  const { noMargin, classes, title } = props;
  return (
    <Typography
      className={!noMargin && classes.menuHeading}
      variant="subtitle1"
    >
      {title}
    </Typography>
  );
};

MenuHeadingNoStyle.propTypes = {
  title: PropTypes.string.isRequired,
  noMargin: PropTypes.bool.isRequired,
  classes: PropTypes.string.isRequired,
};

export default withStyles(styles)(MenuHeadingNoStyle);
