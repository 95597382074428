import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  Snackbar,
  DialogContent,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { MultiStepForm } from '@pitchbooking-dev/pb-shared/lib/components/MultiStepForm';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';
import NewMembershipForm, { initialValues as membershipFormValues, validationSchema as membershipFormValidation } from './NewMembershipForm';
import CustomMembershipForm, { initialValues as customMembershipFormValues, validationSchema as customMembershipFormValidation } from './CustomMembershipForm';
import { createCompanyMembership, toggleNewMembershipDialog } from '../../../../reducers/companiesReducer';

const styles = {
  dialogContent: {
    paddingRight: '2.5rem',
    paddingLeft: '2.5rem',
    paddingBottom: '2.5rem',
  },
  dialogTitle: { paddingLeft: '0rem' },
  actionWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '1rem',
  },
  triggerButton: {
    fontWeight: 800,
    width: 225,
  },
};

const NewMembershipDialog = (props) => {
  const {
    isOpen,
    isSuccess,
    onToggleDialog,
    onCreateCompanyMembership,
  } = props;
  const [displaySnackbar, setDisplaySnackbar] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const [isCustomFormRequired, setIsCustomFormRequired] = useState(false);
  const formSteps = [
    {
      label: 'Membership Details',
      component: NewMembershipForm,
      validation: membershipFormValidation,
    },
    {
      label: 'Custom Membership Form',
      component: CustomMembershipForm,
      validation: customMembershipFormValidation,
    },
  ];
  const [steps, setSteps] = useState(formSteps);

  useEffect(() => {
    if (isCustomFormRequired.target?.value === 'true') {
      setSteps(formSteps);
    } else {
      setSteps(formSteps.slice(0, 1));
    }
  }, [isCustomFormRequired]);

  const handleSubmit = (values) => {
    const reqBody = {
      name: values.membershipName,
      description: values.membershipDescription,
      ...values,
    };
    onCreateCompanyMembership(reqBody);
  };

  const handleOpenClick = () => {
    onToggleDialog();
  };

  const handleDialogClose = () => {
    onToggleDialog();
  };

  const handleSnackbarClose = () => {
    setDisplaySnackbar(false);
  };

  useEffect(() => {
    if (isSuccess) {
      handleDialogClose();
      setDisplaySnackbar(true);
    }
  }, [isSuccess]);

  return (
    <>
      <Button
        onClick={handleOpenClick}
        variant="contained"
        color="primary"
        style={styles.triggerButton}
      >
        New Membership
      </Button>
      <Dialog open={isOpen} onClose={handleDialogClose} fullScreen={isMobile} maxWidth="sm">
        <div style={styles.actionWrapper}>
          <CloseDialogIcon style={styles.closeDialogIcon} onClick={handleDialogClose} />
        </div>
        <DialogContent style={styles.dialogContent}>
          <DialogTitle style={styles.dialogTitle}>Create a Membership</DialogTitle>
          <DialogContentText>
            You will be able to add members to this membership once it is created.
            Just click on the membership name and use the Add or Remove Members button.
          </DialogContentText>
          <MultiStepForm
            initialValues={{
              ...membershipFormValues,
              ...customMembershipFormValues,
            }}
            steps={steps}
            onValuesChanged={(e) => {
              setIsCustomFormRequired(e);
            }}
            onSubmit={async (values) => {
              await handleSubmit(values);
            }}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={displaySnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Membership created successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

NewMembershipDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  onToggleDialog: PropTypes.func.isRequired,
  onCreateCompanyMembership: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { companies } = state;
  const { newCompanyMembership } = companies;
  const {
    isNewMembershipDialogOpen: isOpen,
    isCreatingCompanyMembershipRequest: isSubmitting,
    isCreateCompanyMembershipSuccess: isSuccess,
    isCreateCompanyMembershipError: isError,
  } = newCompanyMembership;

  return {
    isOpen, isSubmitting, isSuccess, isError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onToggleDialog: () => dispatch(toggleNewMembershipDialog()),
  onCreateCompanyMembership: (reqBody) => dispatch(createCompanyMembership(reqBody)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewMembershipDialog);
