import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const CheckInLogDialog = ({
  isOpen,
  onClose,
  checkInLogs,
}) => (

  <Dialog
    open={isOpen}
  >
    <DialogTitle>
      Check In/Out Log
    </DialogTitle>
    <DialogContent style={{ width: '600px' }}>
      <div style={{ marginBottom: '20px' }}>
        {checkInLogs?.length > 0 ? (
          <List>
            {
              checkInLogs.map((log) => (
                <ListItem
                  key={log.id}
                >
                  <ListItemText
                    primary={log.type === 'CHECK_IN' ? 'Checked In' : 'Checked Out'}
                    secondary={new Date(log.createdAt).toLocaleString()}
                  />
                </ListItem>
              ))
            }
          </List>
        ) : (
          <p>No Check In/Out Logs</p>
        )}
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary" variant="contained">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

CheckInLogDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  checkInLogs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default CheckInLogDialog;
