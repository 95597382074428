import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { priceTaxCalculator } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import {
  TextField,
  Typography,
  Checkbox,
  InputAdornment,
} from '@material-ui/core';
import ToggleButtons from '../../../../components/ToggleButtons';
import * as actions from '../../../../reducers/subscriptionsReducer';
import MenuHeading from '../../../../components/MenuHeading';

const BookingStepperPayment = () => {
  const dispatch = useDispatch();
  const updateSubscriptionStore = (keyWithValue) => dispatch(
    actions.updateSubscriptionStore(keyWithValue),
  );
  const subUserData = useSelector((state) => state.subscriptions.userDetailsForSubscription);

  useEffect(() => {
    updateSubscriptionStore({ subscriptionName: `${subUserData.firstName} ${subUserData.lastName} ${subUserData.teamName}` });
  }, []);

  const subscription = useSelector((state) => state.subscriptions.subscription);

  const taxRate = useSelector((state) => state.companies.companyInfo.taxRate) ?? 0.2;
  const currencySym = useSelector((state) => state.companies.companyInfo.currencySym) ?? '£';
  const facility = useSelector((state) => state.facilities.selectedFacility);

  const { subscriptionName, amount, taxExempt } = subscription;
  const { totalExTax, tax } = priceTaxCalculator(amount, taxExempt, taxRate);

  let stripeDisabled = {};
  if (parseFloat(amount) === 0) {
    stripeDisabled = { disabled: true };
  }

  useEffect(() => {
    updateSubscriptionStore({ taxExempt: !!facility.taxExempt });
  }, [facility.taxExempt]);

  const subscriptionTypeButtonsData = [{
    buttonTitle: 'Automatic',
    buttonValue: 'STRIPE_SUBSCRIPTION',
    ...stripeDisabled,
  },
  {
    buttonTitle: 'Invoice / Pay on Arrival',
    buttonValue: 'PAYP',
  }];

  const subscriptionNameInput = (
    <div>
      <MenuHeading title="Subscription Title:" />
      <TextField
        id="subscriptionName"
        label="E.g. Senior Team Training"
        type="text"
        variant="outlined"
        fullWidth
        value={subscriptionName}
        onChange={(event) => updateSubscriptionStore({ subscriptionName: event.target.value })}
      />
    </div>
  );

  const subscriptionPriceInput = (
    <div>
      <MenuHeading title="Subscription Price:" />
      <Typography variant="caption">The following price is based on your current pricing rules of how much this slot should cost. You can edit the price by changing the text below.</Typography>
      <br />
      <br />
      <TextField
        id="amount"
        type="number"
        fullWidth
        value={amount}
        variant="outlined"
        onChange={(event) => updateSubscriptionStore({ amount: event.target.value })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {currencySym}
            </InputAdornment>
          ),
        }}
      />
      <MenuHeading title="Subscription Tax:" />
      <Typography variant="caption">Should tax be included for this subscription?</Typography>
      <Checkbox checked={!taxExempt} onChange={() => updateSubscriptionStore({ taxExempt: !taxExempt })} name="subscriptionTaxCharged" />
      <Typography variant="caption">
        {`(Price: ${currencySym}${parseFloat(totalExTax).toFixed(2)} + ${currencySym}${parseFloat(tax).toFixed(2)} tax))`}
      </Typography>
    </div>
  );

  const subscriptionPaymentInput = (
    <div>
      <MenuHeading title="Payment:" />
      <ToggleButtons
        buttonsData={subscriptionTypeButtonsData}
        changeOption={(option) => updateSubscriptionStore(
          { type: option },
        )}
        value={subscription.type}
      />
      <ConditionallyVisible condition={subscription.type === 'STRIPE_SUBSCRIPTION'}>
        <MenuHeading title="Payment information" />
        A subscription is active when the customer makes their first payment.
        The customer will be charged via Stripe every week on the day of their slot.
        You can view the status of the subscription under the
        status column within the subscriptions menu.
      </ConditionallyVisible>
    </div>
  );
  return (
    <div>
      {subscriptionNameInput}
      {subscriptionPriceInput}
      {subscriptionPaymentInput}
    </div>
  );
};

export default BookingStepperPayment;
