/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Tooltip, Chip, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { useMutation } from '@tanstack/react-query';
import CircularProgress from '@material-ui/core/CircularProgress';
import { resendUserInvite } from '../services/usersServices';
import { useCompany, useToast } from '../hooks';

export const UnclaimedUserTag = ({ userId }) => {
  const company = useCompany();
  const toast = useToast();
  const path = window.location.pathname.split('/')[1];
  const emailType = path === 'users' ? 'userInvite' : 'managerInvite';
  const [isHovered, setIsHovered] = useState(false);

  const mutation = useMutation({
    mutationFn: async () => {
      const res = await resendUserInvite(company.id, userId, emailType);

      if (res.status !== 200) {
        throw new Error('Error resending invite');
      }

      return res.data;
    },
    onSuccess: (res) => {
      toast.trigger({
        type: 'success',
        message: res,
      });
    },
    onError: () => {
      toast.trigger({
        type: 'error',
        message: 'Error resending invite',
      });
    },
  });
  return (
    <Chip
      label={(
        <Typography
          variant="inherit"
          style={{
            display: 'flex', alignItems: 'center', color: '#ed8d48', fontWeight: '600',
          }}
        >
          Unclaimed
          <Tooltip title="Resend invite">
            {mutation.isLoading ? <CircularProgress size={20} style={{ marginLeft: '0.5rem', color: 'var(--accent)' }} /> : (
              <SendIcon
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                fontSize="small"
                style={{ marginLeft: '0.5rem', cursor: 'pointer', color: isHovered ? 'rgb(237, 141, 72)' : 'rgb(237, 141, 72, 0.6)' }}
                onClick={() => mutation.mutate()}
              />
            )}
          </Tooltip>
        </Typography>
    )}
      style={{ border: '2px solid #ed8d48', backgroundColor: '#ffe1c8', color: '#ed8d48' }}
    />
  );
};
