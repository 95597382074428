/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Divider, Link, Typography, Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useMutation } from '@tanstack/react-query';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createXeroCompany } from '../../../services/companiesServices';
import { useToast } from '../../../hooks';

export const CreateXeroCompany = ({ company }) => {
  const toast = useToast();
  const [missingAddressValues, setMissingAddressValues] = useState([]);
  const companyAddress = {
    AddressLine1: company.addressLine1,
    AddressLine2: company.addressLine2,
    City: company.addressLine2,
    PostalCode: company.postcode,
    Country: company.country,
  };
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await createXeroCompany(company);

      if (res.status !== 200) {
        throw new Error('Error creating Xero company');
      }

      return res.data;
    },
    onSuccess: () => {
      toast.trigger({
        type: 'success',
        message: 'Xero company created successfully.',
      });
    },
    onError: () => {
      toast.trigger({
        type: 'error',
        message: 'Error creating xero company',
      });
    },
  });

  useEffect(() => {
    const missingValues = Object.entries(companyAddress).filter(
      ([key, value]) => value === null || value === undefined,
    ).map(
      ([key]) => key,
    );
    if (missingValues.length > 0) {
      setMissingAddressValues(missingValues);
    }
  }, [company]);

  return (
    <>
      <Typography style={{ textDecoration: 'underline' }}>
        Create Xero Company
      </Typography>

      {missingAddressValues.length > 0
        ? (
          <Alert severity="warning">
            Please check the following company address details:
            <ul>
              {missingAddressValues.map((value) => (
                <li key={value}>{value}</li>
              ))}
            </ul>

            <Link href="/company-settings" color="secondary">
              Update company address
            </Link>
          </Alert>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => mutation.mutate()}
            disabled={mutation.isLoading}
          >
            {mutation.isLoading ? <CircularProgress size={24} style={{ color: 'var(--accent)' }} /> : 'Create Xero Company'}
          </Button>
        )}

      <Divider />
    </>
  );
};
