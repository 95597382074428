import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Select,
  Paper,
} from '@material-ui/core';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import * as addonsActions from '../../reducers/addonsReducer';
import * as facilityActions from '../../reducers/facilitiesReducer';
import * as eventActions from '../../reducers/eventsReducer';
import { requestSitesWithFacilitiesRetrieval } from '../../reducers/sitesReducer';
import NewAddonDialog from './NewAddonDialog';
import ActionBar from '../../components/ActionBar';
import AddonTable from './AddonTable';
import NewCategoryDialog from './NewCategoryDialog';

class Addons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFacility: null,
      selectedEvent: null,
    };
    const {
      requestFacilitiesRetrieval, requestEventsRetrieval, getCompanyAddons,
      requestSites,
    } = this.props;
    requestFacilitiesRetrieval();
    requestEventsRetrieval();
    getCompanyAddons();
    requestSites();
  }

  handleFacilityChange(event) {
    const { getCompanyAddons } = this.props;
    const facilityId = event.target.value;
    if (facilityId !== '0') {
      this.setState({ selectedFacility: facilityId });
      this.setState({ selectedEvent: '' });
      getCompanyAddons(facilityId);
    } else {
      getCompanyAddons();
      this.setState({ selectedFacility: '' });
    }
  }

  handleEventChange(event) {
    const { getCompanyAddons } = this.props;
    const eventId = event.target.value;
    if (eventId !== '0') {
      this.setState({ selectedEvent: eventId });
      this.setState({ selectedFacility: '' });
      getCompanyAddons(null, eventId);
    } else {
      getCompanyAddons();
      this.setState({ selectedEvent: '' });
    }
  }

  render() {
    const {
      facilities, addons, events,
    } = this.props;
    const { selectedFacility, selectedEvent } = this.state;
    return (
      <div>
        <div>
          <ActionBar>
            <NewAddonDialog />
            <div style={{ width: 20 }} />
            <NewCategoryDialog />
          </ActionBar>
          <Paper component={Paper}>
            <>
              <div style={{ display: 'flex', marginLeft: 40 }}>
                <ConditionallyVisible condition={facilities.length > 0}>
                  <div style={{ marginRight: 40 }}>
                    <p>Facility: </p>
                    <Select
                      native
                      defaultValue={selectedFacility}
                      value={selectedFacility}
                      onChange={(event) => this.handleFacilityChange(event)}
                    >
                      <option value={0}>Select Facility</option>
                      {facilities.map((facility) => (
                        <option value={facility.id}>{facility.name}</option>
                      ))}
                    </Select>
                  </div>
                </ConditionallyVisible>
                <ConditionallyVisible condition={events.length > 0}>
                  <div>
                    <p>Event:</p>
                    <Select
                      native
                      defaultValue={selectedEvent}
                      value={selectedEvent}
                      onChange={(event) => this.handleEventChange(event)}
                    >
                      <option value={0}>Select Event</option>
                      {events.map((event) => (
                        <option value={event.id}>{event.name}</option>
                      ))}
                    </Select>
                  </div>
                </ConditionallyVisible>
              </div>
              <div style={{ margin: 30 }}>
                <AddonTable
                  addons={addons}
                />
              </div>
            </>
          </Paper>
        </div>

      </div>
    );
  }
}

Addons.propTypes = {
  getCompanyAddons: PropTypes.func.isRequired,
  requestFacilitiesRetrieval: PropTypes.func.isRequired,
  requestEventsRetrieval: PropTypes.func.isRequired,
  requestSites: PropTypes.func.isRequired,
  facilities: PropTypes.shape().isRequired,
  addons: PropTypes.arrayOf(PropTypes.shape()),
  events: PropTypes.arrayOf(PropTypes.shape()),
};
Addons.defaultProps = {
  addons: [],
  events: [],
};
const mapStateToProps = (state) => {
  const { addons, facilities, events } = state;
  return { addons: addons.addons, facilities: facilities.companyFacilities, events: events.events };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyAddons: (facilityId, eventId) => dispatch(
    addonsActions.getCompanyAddons(facilityId, eventId),
  ),
  requestSites: () => dispatch(requestSitesWithFacilitiesRetrieval()),
  requestFacilitiesRetrieval: () => dispatch(facilityActions.requestFacilitiesRetrieval()),
  requestEventsRetrieval: () => dispatch(eventActions.getEvents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Addons);
