// @flow weak

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { requestTimetablePriceRetrieval } from '@pitchbooking-dev/pb-shared/lib/actions/timetableActions';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import * as reservationsActions from '../reducers/reservationsReducer';

class EventRadio extends Component {
  componentDidMount() {
    const { eventBookingModifiers, updateSelectedEvent } = this.props;
    updateSelectedEvent(eventBookingModifiers[0].id);
  }

  handleChange = (event) => {
    const { updateSelectedEvent, doRequestTimetablePriceRetrieval } = this.props;
    updateSelectedEvent(event.target.value);
    doRequestTimetablePriceRetrieval();
  };

  render() {
    const { selectedEvent, eventBookingModifiers } = this.props;

    return (
      <FormControl component="fieldset" required>
        <FormLabel component="legend">Event</FormLabel>
        <Select
          displayEmpty
          defaultValue={eventBookingModifiers[0].id}
          value={selectedEvent}
          onChange={this.handleChange}
          name="Event"
          label="Event"
        >
          {eventBookingModifiers.map((mod) => (
            <MenuItem value={mod.id}>{mod.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

EventRadio.propTypes = {
  selectedEvent: PropTypes.string.isRequired,
  updateSelectedEvent: PropTypes.func.isRequired,
  doRequestTimetablePriceRetrieval: PropTypes.func.isRequired,
  eventBookingModifiers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

function mapStateToProps(state) {
  const { selectedEvent } = state.reservation;
  const { eventBookingModifiers } = state.facilities.selectedFacility;

  return { selectedEvent, eventBookingModifiers };
}

const mapDispatchToProps = (dispatch) => ({
  updateSelectedEvent: (selectedEvent) => dispatch(
    reservationsActions.updateSelectedEvent(selectedEvent),
  ),
  doRequestTimetablePriceRetrieval: () => dispatch(requestTimetablePriceRetrieval()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventRadio);
