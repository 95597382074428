import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import '../../../styles/payments.css';
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import MaterialTable from 'material-table';
import { Chip, Typography } from '@material-ui/core';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import * as bookingModifiersActions from '../../../reducers/bookingModifiersReducer';
import { GenerateBookingModifierTag } from './GenerateBookingModifierTag';
import { bookingModifierColors } from './constants';

const BookingModifiersTable = (props) => {
  const {
    bookingModifiers,
    toggleEditingBookingModifier,
    updateBookingModifierStore,
  } = props;

  const handleEdit = (bookingModifier) => {
    updateBookingModifierStore(bookingModifier);
    toggleEditingBookingModifier();
  };

  return (
    <div>
      <MaterialTable
        style={{ margin: '0rem 1rem' }}
        data={bookingModifiers}
        title="Pitchbooking Booking Modifiers"
        icons={{
          NextPage: () => <ChevronRight />,
          PreviousPage: () => <ChevronLeft />,
          Search: () => <Search />,
          Export: () => <SaveAlt />,
          ResetSearch: () => <Clear />,
          SortArrow: () => <UnfoldMoreIcon style={{ fill: 'var(--accent)' }} />,
        }}
        columns={[
          // Facilities
          {
            title: 'Facilities',
            render: (row) => (
              <div style={{
                display: 'flex', gap: '0.75rem', flexWrap: 'wrap',
              }}
              >
                {row.facilities && row.facilities.map((f) => (
                  <Chip
                    key={f.id}
                    label={(
                      <Typography variant="body2" style={{ fontWeight: '600' }}>
                        {f.name}
                      </Typography>
                    )}
                    style={{
                      border: bookingModifierColors.facility.border,
                      backgroundColor:
                        bookingModifierColors.facility.backgroundColor,
                      color: bookingModifierColors.facility.color,
                    }}
                  />
                ))}
              </div>
            ),
          },

          // Booking Modifiers
          {
            title: 'Booking Modifiers',
            render: (row) => (
              <GenerateBookingModifierTag bookingModifiers={row.bookingModifiers} />
            ),
          },

          // Actions
          {
            title: 'Actions',
            field: 'actions',
            filtering: false,
            render: (row) => (
              <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                <Button variant="contained" color="primary" onClick={() => handleEdit(row)}>
                  <Typography
                    style={{
                      display: 'flex', alignItems: 'center', gap: '0.5rem', fontWeight: '600',
                    }}
                    variant="button"
                  >
                    <EditTwoToneIcon />
                    Edit
                  </Typography>
                </Button>
              </div>
            ),
          },
        ]}
        options={{
          draggable: false,
          sorting: false,
          search: false,
          toolbar: false,
          selection: false,
          emptyRowsWhenPaging: false,
          tableLayout: 'auto',
          exportButton: false,
          pageSize: 50,
          pageSizeOptions: [10, 25, 50, 100],
          showFirstLastPageButtons: false,
          headerStyle: {
            backgroundColor: '#efefef',
            fontWeight: 'bold',
            textAlign: 'center',
            cursor: 'default',
          },
          rowStyle: {
            backgroundColor: '#ffffff',
          },
        }}
      />
    </div>
  );
};

BookingModifiersTable.propTypes = {
  bookingModifiers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  toggleEditingBookingModifier: PropTypes.func.isRequired,
  updateBookingModifierStore: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  timezone: state.companies.companyInfo.timezone,
  companyFacilities: state.facilities.companyFacilities,
  bookingModifier: state.bookingModifiers.bookingModifier,
  bookingModifierDialogOpen: state.bookingModifiers.bookingModifierDialogOpen,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  toggleEditingBookingModifier: () => (bookingModifiersActions.toggleEditingBookingModifier()),
  updateBookingModifierStore: (bookingModifier) => (
    bookingModifiersActions.updateBookingModifierStore(bookingModifier)),
}, dispatch);

export default connect(
  mapStateToProps, mapDispatchToProps,
)(BookingModifiersTable);
