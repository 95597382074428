import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import EnquiriesView from '@pitchbooking-dev/pb-shared/lib/components/enquiriesView';
import * as enquiriesActions from '../../reducers/enquiriesReducer';

class EnquiryView extends React.Component {
  componentDidMount() {
    const {
      requestEnquiry,
      requestAllEnquiries,
      updateContent,
      match,
    } = this.props;
    requestAllEnquiries();
    requestEnquiry(match.params.id);
    updateContent({ threadId: match.params.id });
  }

  handleSend = () => {
    const {
      resetContentStore, match, updateContent, requestSendContent,
    } = this.props;
    updateContent({ threadId: match.params.id });
    requestSendContent();
    resetContentStore();
  };

  render() {
    const {
      messages, user, thread, updateContent, match,
    } = this.props;

    return (
      <div style={{ marginTop: '-80px' }}>
        {user.isLoggedIn ? (
          <EnquiriesView
            title={messages.find((msg) => msg.id === match.params.id)?.site?.name}
            thread={thread}
            updateContent={updateContent}
            handleSend={this.handleSend}
            managerSide
          />
        ) : (
          <Redirect to="/" />
        )}
      </div>
    );
  }
}

EnquiryView.propTypes = {
  messages: PropTypes.shape().isRequired,
  thread: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  requestAllEnquiries: PropTypes.func.isRequired,
  requestEnquiry: PropTypes.func.isRequired,
  updateContent: PropTypes.func.isRequired,
  resetContentStore: PropTypes.func.isRequired,
  requestSendContent: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};

function mapStateToProps(state) {
  const {
    messages, content, thread,
  } = state.enquiries;

  const user = state.currentUser;

  return {
    messages,
    messageStore: content,
    thread,
    user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  requestAllEnquiries: () => dispatch(enquiriesActions.requestAllEnquiries()),
  requestEnquiry: (id) => dispatch(enquiriesActions.requestEnquiry(id)),
  updateContent: (value) => dispatch(enquiriesActions.updateContent(value)),
  resetContentStore: () => dispatch(enquiriesActions.resetContentStore()),
  requestSendContent: () => dispatch(enquiriesActions.requestSendContent()),
  goToMessage: (threadId) => dispatch(push(`/messages/${threadId}`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnquiryView);
