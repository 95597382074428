/* eslint-disable no-mixed-operators */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import { invoiceNumberFormatted } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import TableFormattedName from '../../components/TableFormattedName';
import VoidInvoiceDialog from './VoidInvoiceDialog';
import InvoiceRecordPaymentDialog from './InvoiceRecordPaymentDialog';
import PreviewInvoiceDialog from './PreviewInvoiceDialog';
import '../../styles/order.css';

const generateInvoiceStatusTag = (row, timezone) => {
  let statusTag;
  switch (row.status) {
    case 'PAID':
      statusTag = (<div className="successTag">Paid</div>);
      break;
    case 'UNPAID':
      statusTag = (<div className="failTag">{`Unpaid. Due: ${moment(row.dueDate).tz(timezone).format('DD MMM YYYY')}`}</div>);
      break;
    case 'PENDING':
      statusTag = (<div className="pendingTag">{`Pending. Due: ${moment(row.dueDate).tz(timezone).format('DD MMM YYYY')}`}</div>);
      break;
    case 'VOID':
      statusTag = (<div className="voidTag">Voided</div>);
      break;
    default:
      break;
  }
  return statusTag;
};

const InvoicesTable = (props) => {
  const {
    invoices, currencySym, timezone, invoiceDisplay, selectedMonth, updateCheck,
  } = props;

  let actionColumn = null;
  let paidAtColumn = null;
  if (invoices.some((inv) => inv.status === 'PAID')) {
    paidAtColumn = {
      title: 'Invoice Paid Date',
      field: 'order.paidAt',
      render: (row) => (row.order ? <div>{moment(row.order.paidAt).tz(timezone).format('ll')}</div> : <></>),
    };
  }
  if (invoices.some((inv) => inv.status === 'UNPAID' || inv.status === 'PENDING')) {
    actionColumn = {
      title: '',
      field: '',
      render: (invoiceEntry) => (
        <ConditionallyVisible condition={invoiceEntry.status === 'UNPAID' || invoiceEntry.status === 'PENDING'}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
          }}
          >
            <VoidInvoiceDialog
              invoice={invoiceEntry}
            />
            <InvoiceRecordPaymentDialog
              invoice={invoiceEntry}
            />
          </div>
        </ConditionallyVisible>
      ),
    };
  }

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <ConditionallyVisible condition={invoiceDisplay === 'PAID'}>
          <div style={{ fontSize: '1rem', display: 'flex', padding: 12 }}>
            <div>Invoice Date:</div>
            <ChevronLeft style={{ paddingBottom: 3 }} onClick={() => updateCheck(invoiceDisplay, selectedMonth.subtract(1, 'month'))}>
              Previous Month
            </ChevronLeft>
            <div style={{ verticalAlign: 'text-top', fontSize: '1rem' }}>{moment(selectedMonth).format('MMMM YYYY')}</div>
            <ChevronRight style={{ paddingBottom: 3 }} onClick={() => updateCheck(invoiceDisplay, selectedMonth.add(1, 'month'))}>
              Next Month
            </ChevronRight>
          </div>
        </ConditionallyVisible>
      </div>
      <MaterialTable
        className="table"
        aria-label="simple table"
        data={invoices}
        title=""
        icons={{
          NextPage: () => <ChevronRight />,
          PreviousPage: () => <ChevronLeft />,
          Search: () => <Search />,
          Export: () => <SaveAlt />,
          ResetSearch: () => <Clear />,
          SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
        }}
        columns={[
          {
            title: 'Reference Number',
            field: 'invoiceNumber',
            render: (row) => (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {invoiceNumberFormatted(row.invoiceNumber)}
                <PreviewInvoiceDialog invoiceId={row.id} />
              </div>
            ),
          },
          {
            title: 'User',
            field: 'user.firstName',
            customFilterAndSearch: (term, rowData) => (
              rowData.user.firstName.toLowerCase().includes(term.toLowerCase())
              || rowData.user.lastName.toLowerCase().includes(term.toLowerCase())
            ),

            render: (row) => (
              <TableFormattedName
                user={row.user}
                showTeam
              />
            ),
          },
          {
            title: 'Amount',
            field: 'total',
            render: (row) => (row.total ? `${currencySym}${(row.total).toFixed(2)}` : ''),
          },
          {
            title: 'Date',
            field: 'createdAt',
            render: (row) => (<div>{moment(row.createdAt).tz(timezone).format('ll')}</div>),
          },
          {
            title: 'Status',
            field: 'status',
            render: (row) => (generateInvoiceStatusTag(row, timezone)),
          },
        ].concat(actionColumn || []).concat(paidAtColumn || [])}
        options={{
          search: true,
          tableLayout: 'auto',
          exportButton: false,
          emptyRowsWhenPaging: false,
          exportFileName: 'Pitchbooking Invoices / Payment Links',
          pageSize: 25,
          pageSizeOptions: [10, 25, 50, 100],
          showFirstLastPageButtons: false,
          showTitle: true,
          headerStyle: {
            backgroundColor: '#efefef',
            fontWeight: 'bold',
          },

        }}
        localization={{
          toolbar: {
            exportTitle: 'Download',
          },
        }}
      />
    </div>
  );
};

InvoicesTable.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape()),
  currencySym: PropTypes.string,
  timezone: PropTypes.string,
  invoiceDisplay: PropTypes.string,
  selectedMonth: PropTypes.string.isRequired,
  updateCheck: PropTypes.func.isRequired,
};

InvoicesTable.defaultProps = {
  invoices: [],
  currencySym: '£',
  timezone: 'Europe/London',
  invoiceDisplay: 'UNPAID',
};

export default InvoicesTable;
