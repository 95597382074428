import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { useSelector } from 'react-redux';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { InputAdornment } from '@material-ui/core';
import { useMobile, useToast } from '../../../hooks';
import { updateDeviceNode } from '../../../services/companiesServices';
import FacilityAndSubFacilityPicker from '../../facilities/components/FacilityAndSubFacilityPicker';
import BasicTooltip from '../../../components/BasicToolTip';

const validationSchema = Yup.object().shape({
  friendlyName: Yup.string().required('Friendly Name is required'),
  scheduleType: Yup.string().required('Schedule Type is required'),
  overrideDuration: Yup.number().positive('The number must be positive!').integer('The number must be an integer!').required('Override Duration is required'),
  offsetOn: Yup.number().integer('The number must be an integer!').required('Offset On is required'),
  offsetOff: Yup.number().integer('The number must be an integer!').required('Offset Off is required'),
  offsetSunrise: Yup.number().integer('The number must be an integer!').required('Offset Sunrise is required'),
  offsetSunset: Yup.number().integer('The number must be an integer!').required('Offset Sunset is required'),
  triggerDelay: Yup.number().min(0, 'The number must be positive!').integer('The number must be an integer!').required('Trigger Delay is required'),
  cooldownPeriod: Yup.number().min(0, 'The number must be positive!').integer('The number must be an integer!').required('Cooldown Period is required'),
  isTwilightModeActive: Yup.boolean(),
  designatedOffTime: Yup.string().when('scheduleType', {
    is: 'TWILIGHT',
    then: Yup.string().required('Designated Off Time is required'),
    otherwise: Yup.string().nullable(),
  }),
  facilities: Yup.array().when('scheduleType', {
    is: 'AUTOMATIC',
    then: Yup.array().min(1, 'At least 1 facility is required for automatic schedule type.'),
    otherwise: Yup.array(),
  }),
});

const EditDeviceNodeDialog = ({ node, onSuccess, scheduleTypeOptions }) => {
  const { companyId, facilities } = useSelector((state) => (
    {
      companyId: state.companies.companyInfo.id,
      facilities: state.facilities.companyFacilities,
    }
  ));
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMobile();
  const toast = useToast();

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setIsOpen(true)}
      >
        Edit Device
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullScreen={isMobile}
        fullWidth
      >
        <DialogTitle>Edit Device</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              friendlyName: node.friendlyName,
              scheduleType: node.scheduleType,
              overrideDuration: node.overrideDuration,
              offsetOn: node.offsetOn,
              offsetOff: node.offsetOff,
              offsetSunrise: node.offsetSunrise,
              offsetSunset: node.offsetSunset,
              triggerDelay: node.triggerDelay,
              cooldownPeriod: node.cooldownPeriod,
              isTwilightModeActive: node.isTwilightModeActive,
              designatedOffTime: node.designatedOffTime,
              facilities: node.facilities,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const res = await updateDeviceNode(companyId, node.id, {
                  ...values,
                  facilities: values.facilities.map((x) => x.id),
                });

                if (res.error) {
                  throw new Error(res.error);
                }

                await onSuccess(res);

                toast.trigger({
                  type: 'success',
                  message: 'Device successfully updated',
                });
                setIsOpen(false);
              } catch (err) {
                toast.trigger({
                  type: 'error',
                  message: err.message,
                });
              }

              setSubmitting(false);
            }}
          >
            {({
              values, errors, touched, isSubmitting, handleChange, handleBlur, setFieldValue,
            }) => (
              <Form>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                  <PBInput
                    id="friendlyName"
                    label="Friendly Name"
                    value={values.friendlyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={touched.friendlyName && errors.friendlyName}
                    isError={touched.friendlyName && Boolean(errors.friendlyName)}
                  />

                  <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <FacilityAndSubFacilityPicker
                      facilities={facilities}
                      checkedFacilities={values.facilities}
                      onUpdate={(e) => {
                        setFieldValue('facilities', e);
                      }}
                      editingAccessRestriction={false}
                    />
                    { Boolean(errors.facilities) && touched.facilities ? (
                      <Typography variant="subheading" color="error">
                        {errors.facilities}
                      </Typography>
                    ) : null}
                  </div>

                  <ConditionallyVisible condition={node.isSmart && node.type === 'LIGHTING'}>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem' }}>
                      <PBInput
                        id="scheduleType"
                        label="Scheduling"
                        type="select"
                        options={scheduleTypeOptions}
                        value={values.scheduleType}
                        onChange={(e) => {
                          handleChange(e);

                          if (e.target.value !== 'TWILIGHT') {
                            setFieldValue('designatedOffTime', null);
                          } else {
                            setFieldValue('designatedOffTime', '00:00');
                          }
                        }}
                        onBlur={handleBlur}
                        errorMessage={touched.scheduleType && errors.scheduleType}
                        isError={touched.scheduleType && Boolean(errors.scheduleType)}
                      />
                      { values.scheduleType === 'TWILIGHT' && (
                        <PBInput
                          id="designatedOffTime"
                          label="Designated Off Time"
                          type="time"
                          value={values.designatedOffTime}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errorMessage={touched.designatedOffTime && errors.designatedOffTime}
                          isError={touched.designatedOffTime && Boolean(errors.designatedOffTime)}
                        />
                      )}
                      { values.scheduleType === 'AUTOMATIC' && (
                        <>
                          <PBInput
                            id="isTwilightModeActive"
                            label="Use Sunset/Sunrise?"
                            type="select"
                            options={[
                              { value: true, label: 'Yes' },
                              { value: false, label: 'No' },
                            ]}
                            value={values.isTwilightModeActive}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errorMessage={
                              touched.isTwilightModeActive && errors.isTwilightModeActive
                            }
                            isError={
                            touched.isTwilightModeActive && Boolean(errors.isTwilightModeActive)
                          }
                          />

                          {!values.isTwilightModeActive && (
                          <div style={{ gridColumn: '1 / span 2' }}>
                            <Typography variant="subheading" color="textSecondary">
                              By turning off sunset/sunrise, your lights will come on at anytime
                              of the day provided there is a booking taking place.
                            </Typography>
                          </div>
                          )}
                        </>
                      )}
                    </div>
                  </ConditionallyVisible>

                  <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem' }}>
                    <PBInput
                      id="offsetOn"
                      label="+/- Offset On (minutes)"
                      value={values.offsetOn}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorMessage={touched.offsetOn && errors.offsetOn}
                      isError={touched.offsetOn && Boolean(errors.offsetOn)}
                      InputProps={{
                        className: 'text-field',
                        endAdornment: (
                          <InputAdornment>
                            <BasicTooltip title="This is the amount of time relative to the start time of the booking that the device will activate. A positive number means the device will operate this amount of time BEFORE the booking starts." />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <PBInput
                      id="offsetOff"
                      label="+/- Offset Off (minutes)"
                      value={values.offsetOff}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorMessage={touched.offsetOff && errors.offsetOff}
                      isError={touched.offsetOff && Boolean(errors.offsetOff)}
                      InputProps={{
                        className: 'text-field',
                        endAdornment: (
                          <InputAdornment>
                            <BasicTooltip title="This is the amount of time relative to the end time of the booking that the device will activate. A positive number means the device will operate this amount of time AFTER the booking ends." />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ConditionallyVisible condition={node.isSmart && node.type === 'LIGHTING'}>
                      <PBInput
                        id="offsetSunrise"
                        label="+/- Offset Sunrise (minutes)"
                        value={values.offsetSunrise}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={touched.offsetSunrise && errors.offsetSunrise}
                        isError={touched.offsetSunrise && Boolean(errors.offsetSunrise)}
                      />
                      <PBInput
                        id="offsetSunset"
                        label="+/- Offset Sunset (minutes)"
                        value={values.offsetSunset}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={touched.offsetSunset && errors.offsetSunset}
                        isError={touched.offsetSunset && Boolean(errors.offsetSunset)}
                      />
                      <PBInput
                        id="overrideDuration"
                        label="Override Duration (minutes)"
                        value={values.overrideDuration}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={touched.overrideDuration && errors.overrideDuration}
                        isError={touched.overrideDuration && Boolean(errors.overrideDuration)}
                      />
                      <PBInput
                        id="triggerDelay"
                        label="Trigger Delay (seconds)"
                        value={values.triggerDelay}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={touched.triggerDelay && errors.triggerDelay}
                        isError={touched.triggerDelay && Boolean(errors.triggerDelay)}
                      />
                      <PBInput
                        id="cooldownPeriod"
                        label="Cooldown Period (minutes)"
                        value={values.cooldownPeriod}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={touched.cooldownPeriod && errors.cooldownPeriod}
                        isError={touched.cooldownPeriod && Boolean(errors.cooldownPeriod)}
                      />
                    </ConditionallyVisible>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>Save Changes</Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

EditDeviceNodeDialog.propTypes = {
  node: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
  scheduleTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ).isRequired,
};

export default EditDeviceNodeDialog;
