import Pusher from 'pusher-js';

Pusher.logToConsole = process.env.NODE_ENV === 'development';
let pusherInstance = null;
if (process.env.REACT_APP_PUSHER_KEY) {
  pusherInstance = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  });
}
const pusher = pusherInstance;
export default pusher;
