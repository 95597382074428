import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import { useMobile, useToast } from '../../../hooks';
import { updateReservationServices } from '../../../services/reservationsServices';

const validationSchema = Yup.object().shape({
  notes: Yup.string().max(255, 'Notes must be less than 255 characters'),
});

const AddEditBookingNotesDialog = ({ reservation, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMobile();
  const toast = useToast();

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await updateReservationServices(reservation.companyId, reservation.id, values);
      toast.trigger({ type: 'success', message: 'Notes updated successfully' });
      onSuccess();
      handleClose();
    } catch (err) {
      toast.trigger({ type: 'error', message: 'Error occurred while attempting to update notes' });
      console.error(err);
    }
    setSubmitting(false);
  };

  const initialValues = {
    notes: reservation.notes,
  };

  return (
    <div>
      <Button
        id="manager-bookings-edit-booking"
        variant="outlined"
        color="primary"
        style={{ width: '100%' }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {reservation.notes ? 'Edit Notes' : 'Add Notes'}
      </Button>

      <Dialog
        className="reservation-creation-modal-wrapper"
        open={isOpen}
        onRequestClose={handleClose}
        fullScreen={isMobile}
        fullWidth
      >
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>
            {reservation.notes ? 'Edit Notes' : 'Add Notes'}
          </DialogTitle>
          <CloseDialogIcon onClick={handleClose} />
        </DialogActions>
        <DialogContent>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {({
              values, errors, touched, handleChange, handleSubmit, isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{
                  display: 'flex', flexDirection: 'column', gap: '1rem',
                }}
              >
                <PBInput
                  id="notes"
                  type="textarea"
                  label="Notes"
                  value={values.notes}
                  isError={touched.notes && Boolean(errors.notes)}
                  errorMessage={errors.notes}
                  onChange={handleChange}
                />

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

AddEditBookingNotesDialog.propTypes = {
  reservation: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddEditBookingNotesDialog;
