import moment from 'moment';

export const COMPANY_INVOICES_RETRIEVAL_REQUESTED = 'COMPANY_INVOICES_RETRIEVAL_REQUESTED';
export const COMPANY_INVOICES_RETRIEVAL_SUCCEEDED = 'COMPANY_INVOICES_RETRIEVAL_SUCCEEDED';
export const COMPANY_INVOICES_RETRIEVAL_FAILED = 'COMPANY_INVOICES_RETRIEVAL_FAILED';

export const COMPANY_INVOICE_INFORMATION_RETRIEVAL_REQUESTED = 'COMPANY_INVOICE_INFORMATION_RETRIEVAL_REQUESTED';
export const COMPANY_INVOICE_INFORMATION_RETRIEVAL_SUCCEEDED = 'COMPANY_INVOICE_INFORMATION_RETRIEVAL_SUCCEEDED';
export const COMPANY_INVOICE_INFORMATION_RETRIEVAL_FAILED = 'COMPANY_INVOICE_INFORMATION_RETRIEVAL_FAILED';
export const INVOICE_INFORMATION_STORE_RESET = 'INVOICE_INFORMATION_STORE_RESET';

export const UPDATE_CREATE_INVOICE_SELECTED_RESERVATIONS = 'UPDATE_CREATE_INVOICE_SELECTED_RESERVATIONS';
export const COMPANY_INVOICE_CREATION_REQUESTED = 'COMPANY_INVOICE_CREATION_REQUESTED';
export const COMPANY_INVOICE_CREATION_SUCCEEDED = 'COMPANY_INVOICE_CREATION_SUCCEEDED';
export const COMPANY_INVOICE_CREATION_FAILED = 'COMPANY_INVOICE_CREATION_FAILED';

export const UPDATE_CREATE_INVOICE_STORE = 'UPDATE_CREATE_INVOICE_STORE';
export const CREATE_INVOICE_STORE_RESET = 'CREATE_INVOICE_STORE_RESET';
export const UPDATE_RECORD_PAYMENT_INVOICE_STORE = 'UPDATE_RECORD_PAYMENT_INVOICE_STORE';
export const COMPANY_RECORD_PAYMENT_INVOICE_REQUESTED = 'COMPANY_RECORD_PAYMENT_INVOICE_REQUESTED';
export const COMPANY_RECORD_PAYMENT_INVOICE_SUCCEEDED = 'COMPANY_RECORD_PAYMENT_INVOICE_SUCCEEDED';
export const COMPANY_RECORD_PAYMENT_INVOICE_FAILED = 'COMPANY_RECORD_PAYMENT_INVOICE_FAILED';
export const UPDATE_COMPANY_INVOICE_INSTRUCTIONS = 'UPDATE_COMPANY_INVOICE_INSTRUCTIONS';

export const VOID_INVOICE_REQUESTED = 'VOID_INVOICE_REQUESTED';
export const SUCCEED_VOID_INVOICE = 'SUCCEED_VOID_INVOICE';
export const FAIL_VOID_INVOICE = 'FAIL_VOID_INVOICE';

export const RESEND_INVOICE_REQUESTED = 'RESEND_INVOICE_REQUESTED';
export const SUCCEED_RESEND_INVOICE = 'SUCCEED_RESEND_INVOICE';
export const FAIL_RESEND_INVOICE = 'FAIL_RESEND_INVOICE';

export const UPDATE_INVOICE_REQUESTED = 'UPDATE_INVOICE_REQUESTED';
export const SUCCEED_UPDATE_INVOICE = 'SUCCEED_UPDATE_INVOICE';
export const FAIL_UPDATE_INVOICE = 'FAIL_UPDATE_INVOICE';

export const INVOICES_CREATION_POPUP_TOGGLED = 'INVOICES_CREATION_POPUP_TOGGLED';
export const RESET_CREATE_INVOICE_SELECTED_RESERVATIONS = 'RESET_CREATE_INVOICE_SELECTED_RESERVATIONS';
export const ADD_INVOICE_ADDITIONAL_ITEM = 'ADD_INVOICE_ADDITIONAL_ITEM';
export const REMOVE_INVOICE_ADDITIONAL_ITEM = 'REMOVE_INVOICE_ADDITIONAL_ITEM';

export const initialState = {
  invoices: [],
  invoice: {
    user: {
      id: null,
      firstName: '',
      lastName: '',
      teamName: '',
      email: '',
    },
    dueDate: null,
    total: null,
    totalExTax: null,
    tax: null,
    formattedReservations: [],
    formattedSubscriptions: [],
    bookerNotes: '',
  },
  createInvoiceDialogOpen: false,
  createInvoice: {
    user: {
      id: null,
      firstName: null,
      lastName: null,
      teamName: null,
      email: null,
    },
    dueDate: null,
    total: null,
    totalExTax: null,
    tax: null,
    selectedReservations: [],
    additionalItems: [],
    sendEmail: true,
  },
  recordPaymentInvoice: {
    paymentMethod: 'BANKTRANSFER',
    paidAt: moment(),
  },
  invoicesLoading: false,
};

export const calculatePrice = (createInvoice) => {
  let totalExTax = 0;
  let tax = 0;
  let total = 0;

  createInvoice.selectedReservations.forEach((res) => {
    totalExTax += res.totalExTax;
    tax += res.tax;
    total += res.total;
  });

  createInvoice.additionalItems.forEach((item) => {
    totalExTax += parseFloat(item.totalExTax);
    tax += parseFloat(item.tax);
    total += parseFloat(item.total);
  });

  return {
    totalExTax,
    tax,
    total,
  };
};

export const updateSelectedReservations = (state, reservation) => {
  const updatedState = { ...state };
  if (reservation) {
    let index = updatedState.createInvoice.selectedReservations.map(
      (e) => e.id,
    ).indexOf(reservation.id);
    if (reservation.type === 'SUBSCRIPTION') {
      index = updatedState.createInvoice.selectedReservations.map(
        (e) => e.subscriptionReservationId,
      ).indexOf(reservation.subscriptionReservationId);
    }
    const removeFromState = index > -1;

    if (removeFromState) {
      updatedState.createInvoice.selectedReservations.splice(index, 1);
    } else {
      updatedState.createInvoice.selectedReservations.push(reservation);
    }
  }

  let totalExTax = 0;
  let tax = 0;
  let total = 0;
  updatedState.createInvoice.selectedReservations.forEach((res) => {
    totalExTax += res.totalExTax;
    tax += res.tax;
    total += res.total;
  });
  updatedState.createInvoice.additionalItems.forEach((item) => {
    totalExTax += parseFloat(item.totalExTax);
    tax += parseFloat(item.tax);
    total += parseFloat(item.total);
  });
  updatedState.createInvoice.totalExTax = totalExTax;
  updatedState.createInvoice.tax = tax;
  updatedState.createInvoice.total = total;
  return updatedState;
};

export const getCompanyInvoices = (status, selectedMonth) => (
  { type: COMPANY_INVOICES_RETRIEVAL_REQUESTED, status, selectedMonth }
);

export const addInvoiceAdditionalItem = (item) => (
  { type: ADD_INVOICE_ADDITIONAL_ITEM, item }
);
export const removeInvoiceAdditionalItem = (item) => (
  { type: REMOVE_INVOICE_ADDITIONAL_ITEM, item }
);
export const getInvoiceInformation = (invoiceId) => (
  { type: COMPANY_INVOICE_INFORMATION_RETRIEVAL_REQUESTED, invoiceId }
);
export const succeedInvoiceInformationRetrieval = (invoice) => (
  { type: COMPANY_INVOICE_INFORMATION_RETRIEVAL_SUCCEEDED, invoice }
);
export const failInvoiceInformationRetrieval = () => (
  { type: COMPANY_INVOICE_INFORMATION_RETRIEVAL_FAILED }
);
export const updateInvoiceInstructions = (instructions) => (
  { type: UPDATE_COMPANY_INVOICE_INSTRUCTIONS, instructions }
);
export const requestInvoiceCreation = () => (
  { type: COMPANY_INVOICE_CREATION_REQUESTED }
);
export const voidInvoice = (invoiceId) => (
  { type: VOID_INVOICE_REQUESTED, invoiceId }
);
export const succeedVoidInvoice = (invoices) => (
  { type: SUCCEED_VOID_INVOICE, invoices }
);
export const failVoidInvoice = () => (
  { type: FAIL_VOID_INVOICE }
);
export const resendInvoice = (invoiceId, userIds) => (
  { type: RESEND_INVOICE_REQUESTED, invoiceId, userIds }
);
export const succeedResendInvoice = () => (
  { type: SUCCEED_RESEND_INVOICE }
);
export const failResendInvoice = () => (
  { type: FAIL_RESEND_INVOICE }
);
export const updateInvoice = (invoiceId, newInvoice) => (
  { type: UPDATE_INVOICE_REQUESTED, invoiceId, newInvoice }
);
export const succeedUpdateInvoice = (invoice) => (
  { type: SUCCEED_UPDATE_INVOICE, invoice }
);
export const failUpdateInvoice = () => (
  { type: FAIL_UPDATE_INVOICE }
);
export const succeedCompanyInvoicesCreation = (invoices) => (
  { type: COMPANY_INVOICE_CREATION_SUCCEEDED, invoices }
);
export const failCompanyInvoicesCreation = () => (
  { type: COMPANY_INVOICE_CREATION_FAILED }
);
export const succeedCompanyInvoicesRetrieval = (invoices) => (
  { type: COMPANY_INVOICES_RETRIEVAL_SUCCEEDED, invoices }
);
export const failCompanyInvoicesRetrieval = () => (
  { type: COMPANY_INVOICES_RETRIEVAL_FAILED }
);

export function updateCreateInvoiceStore(invoice) {
  return { type: UPDATE_CREATE_INVOICE_STORE, invoice };
}
export function updateCreateInvoiceSelectedReservations(reservation) {
  return { type: UPDATE_CREATE_INVOICE_SELECTED_RESERVATIONS, reservation };
}
export function resetCreateInvoiceSelectedReservations() {
  return { type: RESET_CREATE_INVOICE_SELECTED_RESERVATIONS };
}
export function updateRecordPaymentInvoiceStore(keyValue) {
  return { type: UPDATE_RECORD_PAYMENT_INVOICE_STORE, keyValue };
}
export const requestRecordPaymentInvoice = (invoiceId) => (
  { type: COMPANY_RECORD_PAYMENT_INVOICE_REQUESTED, invoiceId }
);
export const succeedRecordPaymentInvoice = (invoices) => (
  { type: COMPANY_RECORD_PAYMENT_INVOICE_SUCCEEDED, invoices }
);
export const failRecordPaymentInvoice = () => (
  { type: COMPANY_RECORD_PAYMENT_INVOICE_FAILED }
);
export function resetCreateInvoiceStore() {
  return { type: CREATE_INVOICE_STORE_RESET };
}
export function resetInvoiceInformationStore() {
  return { type: INVOICE_INFORMATION_STORE_RESET };
}

export function toggleInvoiceCreationPopup() {
  return { type: INVOICES_CREATION_POPUP_TOGGLED };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_INVOICES_RETRIEVAL_REQUESTED:
      return { ...state, invoicesLoading: true };
    case COMPANY_INVOICES_RETRIEVAL_SUCCEEDED:
      return { ...state, invoices: action.invoices, invoicesLoading: false };
    case COMPANY_INVOICE_INFORMATION_RETRIEVAL_SUCCEEDED:
      return { ...state, invoice: action.invoice };
    case COMPANY_INVOICE_CREATION_SUCCEEDED:
      return { ...state, invoices: action.invoices, invoicesLoading: false };
    case COMPANY_INVOICE_CREATION_REQUESTED:
      return { ...state, invoicesLoading: true };
    case COMPANY_RECORD_PAYMENT_INVOICE_SUCCEEDED:
      return { ...state, invoices: action.invoices };
    case SUCCEED_VOID_INVOICE:
      return { ...state, invoices: action.invoices };
    case SUCCEED_UPDATE_INVOICE:
      return { ...state, invoice: action.invoice };
    case UPDATE_CREATE_INVOICE_STORE:
      return {
        ...state,
        createInvoice:
            {
              ...state.createInvoice,
              ...action.invoice,
            },
      };
    case UPDATE_CREATE_INVOICE_SELECTED_RESERVATIONS:
      return updateSelectedReservations(state, action.reservation);
    case RESET_CREATE_INVOICE_SELECTED_RESERVATIONS:
      return {
        ...state,
        createInvoice: {
          ...state.createInvoice,
          selectedReservations: [],
        },
      };
    case ADD_INVOICE_ADDITIONAL_ITEM:
      return {
        ...state,
        createInvoice: {
          ...state.createInvoice,
          additionalItems: [...state.createInvoice.additionalItems, action.item],
        },
      };
    case REMOVE_INVOICE_ADDITIONAL_ITEM: {
      let updatedState = {
        ...state.createInvoice,
        additionalItems: [...state.createInvoice.additionalItems.filter(
          (item) => item !== action.item,
        )],
      };
      const updatedPrice = calculatePrice(updatedState);
      updatedState = {
        ...updatedState,
        ...updatedPrice,
      };

      return {
        ...state,
        createInvoice: updatedState,
      };
    }
    case UPDATE_RECORD_PAYMENT_INVOICE_STORE:
      return {
        ...state,
        recordPaymentInvoice:
            {
              ...state.recordPaymentInvoice,
              ...action.keyValue,
            },
      };
    case CREATE_INVOICE_STORE_RESET:
      return {
        ...state,
        createInvoice: {
          ...initialState.createInvoice,
          selectedReservations: [],
        },
      };
    case INVOICE_INFORMATION_STORE_RESET:
      return {
        ...state,
        invoice: {
          ...initialState.invoice,
        },
      };
    case INVOICES_CREATION_POPUP_TOGGLED:
      return {
        ...state,
        createInvoiceDialogOpen: !state.createInvoiceDialogOpen,
      };
    default:
      return state;
  }
};
