import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog, DialogTitle, DialogActions, DialogContent, Button, Typography,
  ExpansionPanelSummary, ExpansionPanel, ExpansionPanelDetails,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { currencies } from '@pitchbooking-dev/pb-shared/lib/helpers';
import moment from 'moment-timezone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import DialogContentText from '@material-ui/core/DialogContentText';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import * as yup from 'yup';
import { Formik } from 'formik';
import { SingleDatePicker } from 'react-dates';
import CloseDialogIcon from '../shared-components/CloseDialogIcon';
import PBButton from './Button';
import useMobile from '../hooks/useMobile';
import ImageInput from './ImageInput';
import { createCompanyService } from '../services/companiesServices';

const styles = () => ({
  root: {
    minWidth: 670,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});

const INITIAL_VALUES = {
  address: {
    location: {
      lat: null,
      lng: null,
    },
    fullAddress: null,
    name: null,
  },
  name: '',
  logo: null,
  timezone: 'Europe/London',
  currency: 'gbp',
  taxRate: 0.2,
  createXeroContact: false,
  hubspotCompanyId: '',
  billing: {
    includeBilling: true,
    amount: 100,
    note: 'Your payment details are simply for verification. Billing will not commence until the date of your first onboarding call with your Account Manager, unless specifically stated otherwise.',
    isExpiryDate: false,
    expiryDate: null,
    externalFacilities: null,
  },
  user: {
    firstName: '',
    lastName: '',
    email: '',
  },
};

const VALIDATION_SCHEMA = yup.object().shape({
  address: yup.object().shape({
    location: yup.object().shape({
      lat: yup.number(),
      lng: yup.number(),
    }),
    fullAddress: yup.object().shape({
      addressLineOne: yup.string(),
      addressLineTwo: yup.string(),
      city: yup.string(),
      country: yup.string(),
      postcode: yup.string(),
    }),
    placeName: yup.string(),
  }).required('Company Address is required'),
  name: yup.string().min(4, 'Company Name must be at least 4 characters long').required('Company Name is required'),
  logo: yup.mixed().required('Company Logo is required').test(
    'fileType',
    'Unsupported File Format',
    (value) => value?.type === 'image/png',
  ),

  // User Settings
  user: yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    email: yup.string().email('Email Address must be a valid email').required('Email Address is required'),
  }).required('User is required'),

  // Billing settings
  billing: yup.object().shape({
    includeBilling: yup.boolean(),
    amount: yup.number().when('includeBilling', {
      is: true,
      then: yup.number().required('Amount is required'),
      otherwise: yup.number().nullable(),
    }),
    note: yup.string().when('includeBilling', {
      is: true,
      then: yup.string().required('Note is required'),
      otherwise: yup.string().nullable(),
    }),
    externalFacilities: yup.number().nullable(),
    isExpiryDate: yup.boolean(),
    expiryDate: yup.date().when('isExpiryDate', {
      is: true,
      then: yup.date().required('Expiry Date is required'),
      otherwise: yup.date().nullable(),
    }),
  }),

  // Sales and Finance settings
  hubspotCompanyId: yup.string().required('Hubspot Company ID is required'),
  createXeroContact: yup.boolean(),

  // Advanced settings
  timezone: yup.string().required('Timezone is required'),
  currency: yup.string().required('Currency is required'),
  taxRate: yup.number().required('Tax Rate is required'),
});

const CreateCompanyDialog = () => {
  const isMobile = useMobile();
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [billingLink, setBillingLink] = useState(null);
  const { id: companyId } = useSelector((state) => state.companies.companyInfo);

  const timezones = moment.tz.names();
  const isSuccessful = !!billingLink;

  return (
    <div>
      <PBButton
        color="primary"
        variant="contained"
        onClick={() => setOpen(true)}
        buttonRole="pbAdmin"
      >
        Create Company
      </PBButton>
      <Dialog
        open={open || isSuccessful}
        fullScreen={isMobile}
        maxWidth="lg"
        style={{
          minHeight: 300,
          maxWidth: 800,
          margin: 'auto',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>{isSuccessful ? 'Company created' : 'Create a new company'}</DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => setOpen(false)} />
          </DialogActions>
        </div>
        <ConditionallyVisible condition={!isSuccessful}>
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                const { data } = await createCompanyService(companyId, {
                  ...values,
                  ...values.address,
                  newUser: values.user,
                }, {
                  'Content-Type': 'multipart/form-data',
                });
                resetForm();
                setBillingLink(data.data.billingLink);
              } catch (err) {
                console.error(err);
              }
              setSubmitting(false);
            }}
          >
            {({
              values, setFieldValue, errors, touched, handleChange,
              handleBlur, handleSubmit, isSubmitting, resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent style={{
                  display: 'flex', flexDirection: 'column', gap: '0.75rem',
                }}
                >
                  <ImageInput
                    id="logo"
                    accept="image/png"
                    label="Logo"
                    uploadLabel="Upload Company Logo"
                    value={values.logo}
                    onChange={(e) => {
                      if (e.target.files?.length > 0) {
                        setFieldValue('logo', e.target.files[0]);
                      }
                    }}
                    onBlur={handleBlur}
                    required
                    isError={touched.logo && errors.logo}
                    errorMessage={touched.logo && errors.logo}
                  />

                  <PBInput
                    id="address"
                    type="location"
                    locationDescription="Company Address"
                    value={JSON.stringify(values.address)}
                    onChange={(newValue) => {
                      if (newValue) {
                        setFieldValue('address', newValue);
                        setFieldValue('name', newValue?.fullAddress?.addressLineOne ?? '');
                      }
                    }}
                    onBlur={handleBlur}
                    isError={errors.address && touched.address}
                    errorMessage={touched.address && 'Company Address is required'}
                  />
                  <PBInput
                    id="name"
                    type="text"
                    label="Company Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    isError={touched.name && errors.name}
                    errorMessage={touched.name && errors.name}
                  />

                  <div>
                    <ExpansionPanel className="expansionPanel" style={{ flexGrow: 1, boxShadow: 'none', padding: '0' }}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>User settings</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails style={{
                        wordBreak: 'normal',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.75rem',
                      }}
                      >
                        <div>
                          <Typography type="subheading">
                            Add a new team member who will have access to this Manager Dashboard.
                          </Typography>
                          <Typography gutterBottom color="secondary" type="body2">
                            {'This will automatically send an \u0027Agreement\u0027 email to them.'}
                          </Typography>
                        </div>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
                          <PBInput
                            id="user.firstName"
                            type="text"
                            label="First Name"
                            value={values.user.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            isError={touched.user?.firstName && errors.user?.firstName}
                            errorMessage={touched.user?.firstName && errors.user?.firstName}
                          />
                          <PBInput
                            id="user.lastName"
                            type="text"
                            label="Last Name"
                            value={values.user.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            isError={touched.user?.lastName && errors.user?.lastName}
                            errorMessage={touched.user?.lastName && errors.user?.lastName}
                          />
                          <PBInput
                            id="user.email"
                            type="text"
                            label="Email Address"
                            value={values.user.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            isError={touched.user?.email && errors.user?.email}
                            errorMessage={touched.user?.email && errors.user?.email}
                          />
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel className="expansionPanel" style={{ flexGrow: 1, boxShadow: 'none', padding: '0' }}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Billing settings</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails style={{
                        wordBreak: 'normal',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.75rem',
                      }}
                      >
                        <PBInput
                          id="billing.includeBilling"
                          type="toggle"
                          label="Include Billing?"
                          value={values.billing.includeBilling}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isError={
                            touched.billing?.includeBilling && errors.billing?.includeBilling
                          }
                          errorMessage={
                            touched.billing?.includeBilling && errors.billing?.includeBilling
                          }
                        />

                        {values.billing?.includeBilling && (
                          <>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
                              <PBInput
                                id="billing.amount"
                                type="number"
                                label="Amount"
                                value={values.billing.amount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isError={touched.billing?.amount && errors.billing?.amount}
                                errorMessage={touched.billing?.amount && errors.billing?.amount}
                                required
                              />
                              <PBInput
                                id="billing.externalFacilities"
                                type="number"
                                label="External Facilities"
                                value={values.billing.externalFacilities}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isError={
                                  touched.billing?.externalFacilities
                                  && errors.billing?.externalFacilities
                                }
                                errorMessage={
                                  touched.billing?.externalFacilities
                                  && errors.billing?.externalFacilities
                                }
                              />
                              <div style={{
                                gridColumn: '1 / span 2',
                              }}
                              >
                                <PBInput
                                  id="billing.note"
                                  type="textarea"
                                  label="Billing Note"
                                  value={values.billing.note}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isError={touched.billing?.note && errors.billing?.note}
                                  errorMessage={touched.billing?.note && errors.billing?.note}
                                  required
                                />
                              </div>

                            </div>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '0.75rem',
                            }}
                            >
                              <PBInput
                                id="billing.isExpiryDate"
                                type="toggle"
                                label="Set an expiry date?"
                                value={values.billing.isExpiryDate}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (!e.target.checked) {
                                    setFieldValue('billing.expiryDate', null);
                                  } else {
                                    setFieldValue('billing.expiryDate', moment());
                                  }
                                }}
                                onBlur={handleBlur}
                                isError={
                                  touched.billing?.isExpiryDate && errors.billing?.isExpiryDate
                                }
                                errorMessage={
                                  touched.billing?.isExpiryDate && errors.billing?.isExpiryDate
                                }
                              />

                              {values.billing?.isExpiryDate && (
                                <>
                                  <SingleDatePicker
                                    date={moment(values.billing.expiryDate)}
                                    onDateChange={(date) => {
                                      setFieldValue('billing.expiryDate', moment(date).format('YYYY-MM-DD HH:mm'));
                                    }}
                                    focused={focused}
                                    onFocusChange={({ focused }) => setFocused(focused)}
                                    displayFormat="ddd, DD/MM/YY"
                                    numberOfMonths={1}
                                    isOutsideRange={() => false}
                                    fullWidth
                                    noBorder
                                  />
                                  {touched.billing?.expiryDate && errors.billing?.expiryDate && (
                                    <Typography color="error" style={{ fontSize: '0.75rem' }}>{errors.billing?.expiryDate}</Typography>
                                  )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel className="expansionPanel" style={{ flexGrow: 1, boxShadow: 'none', padding: '0' }}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Sales and Finance settings</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails style={{
                        wordBreak: 'normal',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.75rem',
                      }}
                      >
                        <div>
                          <PBInput
                            id="hubspotCompanyId"
                            type="text"
                            label="Hubspot Company ID"
                            value={values.hubspotCompanyId}
                            onChange={handleChange}
                            required
                            onBlur={handleBlur}
                            isError={errors.hubspotCompanyId && touched.hubspotCompanyId}
                            errorMessage={errors.hubspotCompanyId}
                          />
                          <Typography variant="caption">
                            Test Hubspot Link: &nbsp;
                            <a
                              href={`https://app.hubspot.com/contacts/4272364/record/0-2/${values.hubspotCompanyId}`}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {`https://app.hubspot.com/contacts/4272364/record/0-2/${values.hubspotCompanyId}`}
                            </a>
                          </Typography>

                          <PBInput
                            id="createXeroContact"
                            type="toggle"
                            label="Would you like to create a Xero Contact?"
                            value={values.createXeroContact}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isError={errors.createXeroContact && touched.createXeroContact}
                            errorMessage={errors.createXeroContact}
                          />
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel className="expansionPanel" style={{ flexGrow: 1, boxShadow: 'none', padding: '0' }}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Advanced settings</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails style={{
                        wordBreak: 'normal',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.75rem',
                      }}
                      >
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
                          <PBInput
                            id="currency"
                            type="select"
                            label="Currency"
                            options={currencies.map((c) => ({ value: c.code, label: c.codeCaps }))}
                            value={values.currency}
                            onChange={handleChange}
                            required
                            onBlur={handleBlur}
                            isError={errors.currency && touched.currency}
                            errorMessage={errors.currency}
                          />
                          <PBInput
                            id="timezone"
                            type="select"
                            label="Timezone"
                            options={timezones.map((t) => ({ value: t, label: t }))}
                            value={values.timezone}
                            onChange={handleChange}
                            required
                            onBlur={handleBlur}
                            isError={errors.timezone && touched.timezone}
                            errorMessage={errors.timezone}
                          />
                          <PBInput
                            id="taxRate"
                            type="number"
                            label="Tax Rate (as a decimal)"
                            value={values.taxRate}
                            onChange={handleChange}
                            required
                            onBlur={handleBlur}
                            isError={errors.taxRate && touched.taxRate}
                            errorMessage={errors.taxRate}
                          />
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                </DialogContent>

                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpen(false);
                      resetForm();
                    }}
                    color="secondary"
                    variant="outlined"
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    CREATE
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </ConditionallyVisible>

        <ConditionallyVisible
          condition={isSuccessful}
        >
          <DialogContent>
            <DialogContentText>
              <Typography variant="subtitle1">
                <a
                  href={billingLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Billing link
                </a>
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setOpen(false)} color="secondary">
              Close
            </Button>
          </DialogActions>
        </ConditionallyVisible>

      </Dialog>
    </div>
  );
};

export default withStyles(styles())(CreateCompanyDialog);
