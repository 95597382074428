import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware, { END } from 'redux-saga';
import sagaMonitor from '@redux-saga/simple-saga-monitor';
import { createBrowserHistory } from 'history';
import rootReducer from './reducers';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
const initialState = {};
const enhancers = [];

if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  enhancers.push(composeEnhancers());
}

const composedEnhancers = compose(
  applyMiddleware(routerMiddleware(history), sagaMiddleware),
  ...enhancers,
);

const store = createStore(
  rootReducer(history),
  initialState,
  composedEnhancers,
);

store.runSaga = sagaMiddleware.run;
store.close = () => store.dispatch(END);

export default store;
