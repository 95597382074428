/*
    The HeaderRevenue component represents the revenue statistic
    at the top of the analytics. It is composed of 3 items:
        1. A label (StatisticLabel) that identifies the statistic.
        2. Absolute amount of revenue over the past X days.
        3. An icon (AccountBalanceIcon) that helps further
           identify the statistic.
*/

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import NumberFormat from 'react-number-format';
import LoadingSkeletonThreeDots from '../../../../components/LoadingComponents/LoadingSkeletonThreeDots';
import FailedSection from '../../../../components/LoadingComponents/FailedSection';
import { PercentChange } from './PercentChange';
import { StatisticLabel } from './StatisticLabel';

import '../../styles/analytics.css';

class HeaderRevenue extends React.Component {
  render() {
    const {
      currencySym,
      totalRevenue,
      totalRevenuePercentChange,
      analyticsLoading,
      analyticsFailed,
    } = this.props;
    return (
      <div className="headerItem">
        <AlternativelyVisible condition={analyticsLoading}>
          <LoadingSkeletonThreeDots />
          <AlternativelyVisible condition={analyticsFailed}>
            <FailedSection />
            <>
              <div className="statistic">
                <StatisticLabel labelString="Total Revenue" />
                <div className="statisticContent">
                  <NumberFormat displayType="text" thousandSeparator prefix={currencySym} value={totalRevenue?.toFixed(2)} />
                  <PercentChange percentChange={totalRevenuePercentChange} />
                </div>
              </div>
            </>
          </AlternativelyVisible>
        </AlternativelyVisible>

      </div>
    );
  }
}

HeaderRevenue.propTypes = {
  currencySym: PropTypes.string.isRequired,
  totalRevenue: PropTypes.string.isRequired,
  totalRevenuePercentChange: PropTypes.string.isRequired,
  analyticsLoading: PropTypes.bool.isRequired,
  analyticsFailed: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  totalRevenue: state.analytics.analyticsBookings.totalRevenue,
  totalRevenuePercentChange: state.analytics.analyticsBookings.totalRevenuePercentChange,
  currencySym: state.companies.companyInfo.currencySym,
  analyticsLoading: state.analytics.analyticsLoading,
  analyticsFailed: state.analytics.analyticsFailed,
});

export default connect(mapStateToProps)(HeaderRevenue);
