import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { DayPickerSingleDateController } from 'react-dates';
import MenuHeading from '../MenuHeading';

class EditSubscriptionEndDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validToDate: null,
    };
  }

  handleEndDate = (date, timezone) => {
    const { updateEditedSubscription, onChange } = this.props;
    const validToEndOfDay = date.tz(timezone).endOf('day');
    this.setState({
      validToDate: validToEndOfDay,
    });
    updateEditedSubscription({ validTo: validToEndOfDay });
    onChange(validToEndOfDay);
  };

  handleSetDate = (validToDate, validTo) => {
    if (validToDate !== null) {
      return moment(validToDate);
    } if (validTo !== null) {
      return moment(validTo);
    } return undefined;
  }

  render() {
    const { subscription, timezone } = this.props;
    const { validToDate } = this.state;
    const { validTo, validFrom, weekday } = subscription.accessRestriction;
    let currentEndDate = 'None';
    if (validTo !== null) {
      currentEndDate = moment(validTo).tz(timezone).format('DD-MMM-YYYY');
    }

    return (
      <div>
        <MenuHeading title={`Currently valid until: ${currentEndDate}`} />
        <DayPickerSingleDateController
          onDateChange={(date) => this.handleEndDate(date, timezone)}
          date={this.handleSetDate(validToDate, validTo)}
          isOutsideRange={(day) => day.isBefore(moment().subtract(1, 'week'))}
          focused
          isDayBlocked={(day) => {
            const isSubscriptionDay = (moment(day).weekday() !== weekday);
            const isBeforeValidFrom = moment(day).isBefore(moment(validFrom).subtract(1, 'day'));
            const isBeforeNow = moment(day).isBefore(moment().subtract(1, 'week'));
            return (isSubscriptionDay || isBeforeValidFrom || isBeforeNow);
          }}
        />
      </div>
    );
  }
}

EditSubscriptionEndDate.propTypes = {
  subscription: PropTypes.shape().isRequired,
  timezone: PropTypes.string,
  updateEditedSubscription: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};
EditSubscriptionEndDate.defaultProps = {
  timezone: 'Europe/London',
  onChange: () => {},
};

const mapStateToProps = (state) => ({
  timezone: state.companies.companyInfo.timezone,
});

export default connect(mapStateToProps, null)(EditSubscriptionEndDate);
