/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, {
  useState, useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { MultiStepForm } from '@pitchbooking-dev/pb-shared/lib/components/MultiStepForm';
import moment from 'moment';
import EventDetailsForm, { validationSchema as EventDetailsValidationSchema } from './EventDetailsForm';
import EventFormForm, { validationSchema as EventFormValidationSchema } from './EventFormForm';
import { createEventService, updateEventServices } from '../../../services/eventsServices';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import { getEvents } from '../../../reducers/eventsReducer';
import { createDateWithTime } from '../../../helpers';
import { useToast, useMobile } from '../../../hooks';

const convertTimeToMoment = (time) => {
  const todaysDate = moment().format('YYYY-MM-DD');
  const date = moment(`${todaysDate} ${time}`, 'YYYY-MM-DD HH:mm:ss');
  return date;
};

const formSteps = [
  {
    label: 'Event Details',
    component: EventDetailsForm,
    validation: EventDetailsValidationSchema,
  },
  {
    label: 'Form Creation',
    component: EventFormForm,
    validation: EventFormValidationSchema,
  },
];

const EventMutationDialog = ({ mutate, onClose }) => {
  const { isOpen, event } = mutate;
  const isMobile = useMobile();
  const toast = useToast();
  const dispatch = useDispatch();

  const { id: companyId } = useSelector((state) => state.companies.companyInfo);
  const [isCustomFormRequired, setIsCustomFormRequired] = useState(false);
  const steps = useMemo(() => {
    if (isCustomFormRequired) {
      return formSteps;
    }

    return formSteps.slice(0, 1);
  }, [isCustomFormRequired]);

  let startTime = null;
  let endTime = null;

  if (event?.startTime) {
    startTime = moment(createDateWithTime(moment(), event.startTime)).startOf('minute').format('YYYY-MM-DD HH:mm:ss');
  }
  if (event?.endTime) {
    const eventMoment = convertTimeToMoment(event.endTime).add(1, 's');
    endTime = eventMoment.format('YYYY-MM-DD HH:mm:ss');
  }

  const defaultPrice = event?.priceModifiers && event?.priceModifiers.length > 0 ? event.priceModifiers.filter((pm) => pm.modifierType === 'DEFAULT')[0]?.value : 0;
  const defaultCloseTime = moment().set({
    hour: 12, minute: 0, second: 0, millisecond: 0,
  });

  const initialValues = useMemo(() => ({
    id: event?.id || null,
    name: event?.name || '',
    description: event?.description || '',
    subEventsRequired: event?.subEventsRequired || false,
    maximumCapacity: event?.maximumCapacity || null,
    price: defaultPrice
     || ((event?.priceModifiers?.[0]?.value || event?.priceModifiers?.[0]?.value === 0)
       ? event?.priceModifiers?.[0]?.value : null),
    isOnSiteLocation: event?.isOnSiteLocation || false,
    isOnlineEvent: event?.isOnlineEvent || false,
    siteId: event?.siteId || null,
    facilities: event?.accessRestrictions?.flatMap((x) => x.facilities) || [],
    location: event?.location ? {
      lng: event.location.coordinates[0],
      lat: event.location.coordinates[1],
    } : null,
    locationDescription: event?.locationDescription || null,
    startTime,
    endTime,
    startDate: event?.startDate || null,
    endDate: event?.endDate || null,
    isSetCloseDate: Boolean(event?.closeDate) || false,
    closeDate: event?.closeDate || null,
    emailInstructions: event?.emailInstructions || null,
    status: event?.status || 'ACTIVE',
    isInternal: event?.isInternal || false,
    isCustomFormRequired: Boolean(event?.form) || false,
    formId: event?.formId || null,
    accessRestrictions: event?.accessRestrictions || [],
    addons: event?.addons || [],
    isParentEvent: event?.isParentEvent || false,
    parentEventId: event?.parentEventId || null,
    type: event?.type || (event?.parentEventId ? 'SUB_EVENT' : 'EVENT'),
    costCode: event?.costCode || null,
    taxExempt: event?.taxExempt || false,
    form: event?.form || null,
    isDuplicate: event?.isDuplicate || false,
    bookingModifiers: event?.bookingModifiers || [],
    closeTime: event?.closeDate || defaultCloseTime,
  }), [event]);

  const onSubmit = async (values) => {
    // If editing and end time hasn't changed we need to ensure it's the same as the original
    if (event?.endTime) {
      const eventEndTime = moment(values.endTime).subtract(1, 's').format('HH:mm:ss');

      if (event.endTime === eventEndTime) {
        values.endTime = event.endTime;
      }
    }

    // closeDate is a combination of closeDate and closeTime
    if (values.isSetCloseDate) {
      const closeDate = moment(values.closeDate).format('YYYY-MM-DD');
      const closeTime = moment(values.closeTime).format('HH:mm:ss');
      values.closeDate = `${closeDate} ${closeTime}`;
    }

    try {
      if (values.id) {
        const res = await updateEventServices(companyId, values.id, values);
        if (res.error) throw res.error;
      } else {
        const res = await createEventService(companyId, values);
        if (res.error) throw res.error;
      }

      // Resfresh events list, show toast and close dialog
      dispatch(getEvents('ACTIVE'));
      toast.trigger({ type: 'success', message: 'Event saved successfully' });
      onClose();
    } catch (err) {
      toast.trigger({ type: 'error', message: err });
      throw err;
    }
  };

  return (
    <Dialog
      open={isOpen}
      fullScreen={isMobile}
      fullWidth
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle>
          {
            event ? event.isDuplicate ? 'Create Duplicate Event' : 'Edit Event'
              : 'Create a New Event'
          }
        </DialogTitle>
        <DialogActions variant="none">
          <CloseDialogIcon onClick={onClose} />
        </DialogActions>
      </div>

      <DialogContent>
        <MultiStepForm
          initialValues={initialValues}
          steps={steps}
          onValuesChanged={(values) => {
            const { isCustomFormRequired, formId } = values;
            // if custom form is required and no formId selected
            setIsCustomFormRequired(isCustomFormRequired && !formId);
          }}
          onSubmit={onSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};

EventMutationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  mutate: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    event: PropTypes.shape(),
  }).isRequired,
};

export default EventMutationDialog;
