import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CheckBox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import * as facilitiesActions from '../../../../reducers/facilitiesReducer';

class AddAmenityDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      newAmenities: [],
    };
  }

  componentDidMount() {
    const { requestAmenitiesRetrieval, selectedFacilityId } = this.props;
    requestAmenitiesRetrieval(selectedFacilityId);
  }

  handleOpen() {
    this.setState({ dialogOpen: true });
  }

  handleClose() {
    this.setState({ dialogOpen: false });
  }

  handleSave = () => {
    const { newAmenities } = this.state;
    const { requestFacilityAmenitiesCreation } = this.props;
    requestFacilityAmenitiesCreation(newAmenities);
    this.handleClose();
  }

  handleCheck = (value, amenity) => {
    const { newAmenities } = this.state;
    const { selectedFacilityId } = this.props;
    if (value) {
      newAmenities.push({ amenityId: amenity.id, coreFacilityId: selectedFacilityId });
    } else {
      const i = newAmenities.findIndex((item) => item.amenityId === amenity.id);
      if (i > -1) {
        newAmenities.splice(i, 1);
      }
    }
  }

  render() {
    const {
      dialogOpen,
    } = this.state;
    const {
      companyAmenities,
      amenities,
    } = this.props;
    const filteredAmenities = companyAmenities.filter((amenity) => amenities.filter((
      amen,
    ) => amen.id === amenity.id).length === 0);
    return (
      <div>
        <Button variant="contained" color="secondary" aria-label="edit" onClick={() => this.handleOpen()}>
          Add Amenity
          <AddCircleOutlineIcon style={{ marginLeft: '10px' }} />
        </Button>
        <Dialog
          open={dialogOpen}
        >
          <DialogTitle>Add an amenity to the facility</DialogTitle>
          <DialogContent style={{ width: '600px' }}>
            <div style={{ marginBottom: '20px' }}>
              {filteredAmenities.length > 0 ? (
                <div style={{ display: 'flex', maxWidth: '300px', flexWrap: 'wrap' }}>
                  {filteredAmenities.map((amenity) => (
                    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                      <div>
                        <CheckBox
                          checked={amenity.checked}
                          onChange={(event) => this.handleCheck(event.target.checked, amenity)}
                        />
                      </div>
                      <div style={{
                        width: '300px', margin: '10px 0px', padding: '10px', borderRadius: '4px', border: '1px solid #4581E2',
                      }}
                      >
                        {amenity.name}
                      </div>
                    </div>
                  ))}
                </div>

              ) : <>No amenities found</>}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="secondary" variant="contained">
              Close
            </Button>
            <Button onClick={() => this.handleSave()} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AddAmenityDialog.propTypes = {
  requestAmenitiesRetrieval: PropTypes.func.isRequired,
  requestFacilityAmenitiesCreation: PropTypes.func.isRequired,
  companyAmenities: PropTypes.shape().isRequired,
  amenities: PropTypes.shape().isRequired,
  selectedFacilityId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  priceGroup: state.pricing.priceGroup,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  requestAmenitiesRetrieval: (x) => facilitiesActions.requestAmenitiesRetrieval(x),
  requestFacilityAmenitiesCreation: (
    newAmenities,
  ) => facilitiesActions.requestFacilityAmenitiesCreation(newAmenities),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddAmenityDialog);
