import {
  takeEvery, call, put, select,
} from 'redux-saga/effects';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  createCompanyBookingModifiersService,
  getAllCompanyBookingModifiersService,
  getCompanyBookingModifiersService,
  requestBookingModifiersSaveService,
  requestBookingModifiersGroupSaveService,
} from '../services/bookingModifiersServices';
import * as actions from '../reducers/bookingModifiersReducer';
import { handleSagaError } from './helperSaga';

function* createCompanyBookingModifiersSaga(companyId, action) {
  const { reqBody } = action;
  try {
    const response = yield call(createCompanyBookingModifiersService, companyId, reqBody);
    if (!response.error) {
      yield put(actions.getCompanyBookingModifiers());
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'createCompanyBookingModifiersSaga');
  }
}

function* getCompanyBookingModifiersSaga(companyId) {
  try {
    const response = yield call(getCompanyBookingModifiersService, companyId);
    if (!response.error) {
      yield put(actions.updateCompanyBookingModifiers(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getCompanyBookingModifiersSaga');
    console.log('getCompanyBookingModifiersSaga error', error);
  }
}

function* getAllCompanyBookingModifiersSaga(companyId) {
  try {
    const response = yield call(getAllCompanyBookingModifiersService, companyId);
    if (!response.error) {
      yield put(actions.updateAllCompanyBookingModifiers(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getAllCompanyBookingModifiersSaga');
  }
}

function* saveBookingModifiersSaga(companyId, action) {
  let state = yield select();
  const { singularBookingModifier } = state.bookingModifiers;
  try {
    const response = yield call(
      requestBookingModifiersSaveService,
      companyId, singularBookingModifier,
    );
    if (!response.error) {
      yield put(actions.getCompanyBookingModifiers());
      state = yield select();
      const { bookingModifiers } = state.bookingModifiers.bookingModifier;
      const arr = bookingModifiers.map((bm) => {
        if (bm.id === state.bookingModifiers.singularBookingModifier.id) {
          return state.bookingModifiers.singularBookingModifier;
        }
        return bm;
      });

      yield put(actions.updateBookingModifierStore({
        ...state.bookingModifiers.bookingModifier,
        bookingModifiers: arr,
      }));
      if (!action.isEvent) {
        yield put(sessionActions.updateSuccessfulSnackbar('Booking modifier successfully saved.', true));
      }
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'saveBookingModifiersSaga');
    yield put(sessionActions.updateErrorSnackbar(`Booking modifier save failed : ${error.error}`, true));
  }
}
function* saveBookingModifiersGroupSaga(companyId, action) {
  const state = yield select();
  const { bookingModifier } = state.bookingModifiers;
  try {
    const response = yield call(
      requestBookingModifiersGroupSaveService,
      companyId, bookingModifier,
    );
    if (!response.error) {
      yield put(actions.getCompanyBookingModifiers());
      if (!action.isEvent) {
        yield put(sessionActions.updateSuccessfulSnackbar('Booking modifier group successfully saved.', true));
      }
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'saveBookingModifiersGroupSaga');
    yield put(sessionActions.updateErrorSnackbar(`Booking modifier group save failed : ${error.error}`, true));
  }
}

export function* saveBookingModifierWatcher(companyId) {
  yield takeEvery(actions.BOOKING_MODIFIER_UPDATE_REQUESTED, saveBookingModifiersSaga, companyId);
}
export function* saveBookingModifierGroupWatcher(companyId) {
  yield takeEvery(actions.BOOKING_MODIFIER_UPDATE_GROUP_REQUESTED,
    saveBookingModifiersGroupSaga, companyId);
}
export function* createCompanyBookingModifiersWatcher(companyId) {
  yield takeEvery(actions.CREATE_COMPANY_BOOKING_MODIFIER,
    createCompanyBookingModifiersSaga, companyId);
}
export function* getCompanyBookingModifiersWatcher(companyId) {
  yield takeEvery(actions.GET_COMPANY_BOOKING_MODIFIERS,
    getCompanyBookingModifiersSaga, companyId);
}
export function* getAllCompanyBookingModifiersWatcher(companyId) {
  yield takeEvery(actions.GET_ALL_COMPANY_BOOKING_MODIFIERS,
    getAllCompanyBookingModifiersSaga, companyId);
}
