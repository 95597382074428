import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import LoadingSkeletonBarChart from '../../../../components/LoadingComponents/LoadingSkeletonBarChart';
import FailedSection from '../../../../components/LoadingComponents/FailedSection';
import { CategoryChartBaseLine } from './bases/CategoryChartBaseLine';
import { CategoryHeaderBase } from './bases/CategoryTitleBase';

function CategoryBookings(props) {
  const {
    analyticsEventBookings,
    analyticsEventTimeframe,
    analyticsEventTimeframeCustom,
    analyticsEventLoading,
    analyticsEventFailed,
  } = props;

  const { bookings, xAxisBookings, yAxisBookings } = analyticsEventBookings;

  return (
    <div className="category" id="Bookings">
      <CategoryHeaderBase categoryName="Bookings" />
      <AlternativelyVisible condition={analyticsEventLoading}>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: '1',
        }}
        >
          <LoadingSkeletonBarChart />
        </div>
        <AlternativelyVisible condition={analyticsEventFailed}>
          <FailedSection />
          <CategoryChartBaseLine
            backgroundColor="#87CEFA10"
            bookings={bookings}
            borderColor="#87CEFA"
            categoryName="Bookings"
            analyticsEventTimeframe={analyticsEventTimeframe}
            analyticsEventTimeframeCustom={analyticsEventTimeframeCustom}
            xAxis={xAxisBookings}
            yAxis={yAxisBookings}
            yAxisLabel="Bookings"
            pointBackgroundColor="#87CEFA"
          />
        </AlternativelyVisible>
      </AlternativelyVisible>
    </div>
  );
}

CategoryBookings.propTypes = {
  analyticsEventBookings: PropTypes.shape().isRequired,
  analyticsEventTimeframe: PropTypes.shape().isRequired,
  analyticsEventTimeframeCustom: PropTypes.shape().isRequired,
  analyticsEventLoading: PropTypes.bool.isRequired,
  analyticsEventFailed: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    analyticsEventBookings: state.analytics.analyticsEventBookings,
    analyticsEventTimeframe: state.analytics.analyticsEventTimeframe,
    analyticsEventTimeframeCustom: state.analytics.analyticsEventTimeframeCustom,
    analyticsEventLoading: state.analytics.analyticsEventLoading,
    analyticsEventFailed: state.analytics.analyticsEventFailed,
  };
}

export default connect(mapStateToProps)(CategoryBookings);
