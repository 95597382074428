import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import { Button, Typography } from '@material-ui/core';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';
import { push } from 'react-router-redux';
import draftToMarkdown from 'draftjs-to-markdown';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useMutation } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import * as eventsActions from '../../../reducers/eventsReducer';
import * as addonsActions from '../../../reducers/addonsReducer';
import * as reservationActions from '../../../reducers/reservationsReducer';
import '../../../styles/payments.css';
import EventsTable from '../EventsTable';
import AttendeeTable from './AttendeeTable';
import Back from '../../../components/Back';
import ActionBar from '../../../components/ActionBar';
import EventMutationDialog from '../components/EventMutationDialog';
import { useOrigin, usePitchbookingUser, useToast } from '../../../hooks';
import PromotionModal from './PromotionModal';
import { updateEventServices } from '../../../services/eventsServices';

const EventView = ({ match }) => {
  const { id } = match.params;
  const [mutate, setMutate] = useState({ isOpen: false, event: null });
  const { bookerUrl } = useOrigin();
  const dispatch = useDispatch();
  const {
    event,
    eventLoading,
    company,
    cancelReservations,
  } = useSelector((state) => ({
    event: state.events.event,
    eventLoading: state.events.eventLoading,
    company: state.companies.companyInfo,
    cancelReservations: state.reservation.cancelReservations,
  }));
  const [markdown, setMarkdown] = useState();
  const [isPromotionModalOpen, setIsPromotionModalOpen] = useState(false);
  const { isPitchbookingManager } = usePitchbookingUser();
  const toast = useToast();

  const promotionMutation = useMutation({
    mutationFn: async (data) => {
      const res = await updateEventServices(
        company.id,
        event.id,
        data,
      );

      if (res.error) {
        throw new Error(res.error);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.trigger({
        type: 'error',
        message: 'Unable to perform action!',
      });
    },
    onSuccess: () => {
      dispatch(eventsActions.getEvent(id));
      toast.trigger({
        type: 'success',
        message: 'Promotion status successfully updated!',
      });
    },
  });

  useEffect(() => {
    dispatch(eventsActions.getEvent(id));
    dispatch(addonsActions.requestAddonsRetrieval());
  }, [id]);

  const requestCancel = (event) => {
    const bookingArray = event?.map((res) => (
      res.id
    ));
    dispatch(
      reservationActions.cancelReservations(bookingArray, cancelReservations, true),
    );
  };

  useEffect(() => {
    if (event.description) {
      try {
        const contentState = convertFromRaw(JSON.parse(event.description));
        const newEditorState = EditorState.createWithContent(contentState);
        setMarkdown(draftToMarkdown(convertToRaw(newEditorState.getCurrentContent())));
      } catch (err) {
        setMarkdown(event.description);
      }
    }
  }, [event.description]);

  const onClose = () => {
    dispatch(eventsActions.getEvent(id));
    setMutate({ isOpen: false, event: null });
  };

  return (
    <div style={{ backgroundColor: '#fafbff' }}>
      <ActionBar>
        <Back />
        <ConditionallyVisible condition={event.isParentEvent}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setMutate({ isOpen: true, event: { parentEventId: id } })}
            style={{ marginLeft: '10px' }}
          >
            New Event Occurrence
          </Button>
        </ConditionallyVisible>

        <ConditionallyVisible condition={!company.isPowerleague && event.type === 'EVENT'}>
          <Button
            onClick={() => window.open(`${bookerUrl}/event-view/${event.id}`, '_blank')}
            color="secondary"
            variant="contained"
            style={{ marginLeft: '10px' }}
          >
            View Event Page
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => dispatch(push(`/events/${event.id}/media`))}
            style={{ marginLeft: '10px' }}
          >
            Add Event Media
          </Button>
          {company.products.payLater === 'ENABLED' && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => dispatch(push(`/events/${event.id}/pay-later`))}
            style={{ marginLeft: '10px' }}
          >
            Pay Later Configuration
          </Button>
          )}
          {event.formId && (
            <Link to="/forms">
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: '10px' }}
              >
                View Existing Forms
              </Button>
            </Link>
          )}
        </ConditionallyVisible>

        {(!event.isParentEvent || event.type === 'SUB_EVENT') && event.promotion === null && (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setIsPromotionModalOpen(true);
              }}
              style={{ marginLeft: '10px' }}
            >
              Activate Promotion
            </Button>
            <PromotionModal
              isOpen={isPromotionModalOpen}
              onClose={() => setIsPromotionModalOpen(false)}
              event={event}
            />
          </>
        )}
      </ActionBar>

      <div style={{
        height: '95.5vh',
        marginLeft: '20px',
        marginRight: '40px',
      }}
      >
        <div>

          {event.promotion && event.promotion.status === 'REQUESTED' && (
            <div style={{ marginBottom: '1rem' }}>
              <Typography variant="h5" color="secondary">{`Promotion Requested - ${event.promotion.label}`}</Typography>
              <Typography>{`Thank you for activating the ${event.promotion.label} promotion. Your Account Manager has been notified and will verify your access.`}</Typography>
              {isPitchbookingManager && (
                <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => promotionMutation.mutate(
                      {
                        promotion: { ...event.promotion, status: 'ACTIVE' },
                        commissionOverride: event.promotion.commission,
                      },
                    )}
                    disabled={promotionMutation.isLoading}
                  >
                    Accept Promotion
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => promotionMutation.mutate(
                      {
                        promotion: null,
                      },
                    )}
                    disabled={promotionMutation.isLoading}
                  >
                    Reject Promotion
                  </Button>
                </div>
              )}
            </div>
          )}

          {event.promotion && event.promotion.status === 'ACTIVE' && (
            <div style={{ marginBottom: '1rem' }}>
              <Typography variant="h5" color="secondary">Promotion Applied</Typography>
              <Typography>{event.promotion.description}</Typography>
            </div>
          )}

          <Typography variant="h5">{event.name}</Typography>

          <ReactMarkdown linkTarget="_blank">
            {markdown}
          </ReactMarkdown>

          {event.startTime && (
            <>
              <br />
              <Typography variant="body1">{`Time: ${event.startTime} - ${event.endTime}`}</Typography>
            </>
          )}
        </div>

        <ConditionallyVisible condition={event.isParentEvent}>
          <EventsTable
            events={event.subEvents}
            onEdit={(event) => setMutate({ isOpen: true, event })}
            isParentEvent
          />
        </ConditionallyVisible>

        <ConditionallyVisible condition={
          !event.isParentEvent
          && event.subEvents && event.subEvents.length === 1 && !eventLoading
          }
        >
          <AttendeeTable
            reservations={event.reservations}
            requestMultipleCancel={requestCancel}
            event={event}
            company={company}
          />
        </ConditionallyVisible>

      </div>

      <ConditionallyVisible condition={event.isParentEvent}>
        <EventMutationDialog
          onClose={() => onClose()}
          mutate={mutate}
        />
      </ConditionallyVisible>
    </div>
  );
};

EventView.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default EventView;
