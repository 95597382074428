import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import '../../styles/App.css';

const FailedSection = (props) => {
  const { failedText } = props;
  return (
    <div className="loadingOverlay">
      <Typography style={{ fontSize: '2.5vh', paddingBottom: 10 }}>
        {failedText}
      </Typography>
    </div>
  );
};

FailedSection.propTypes = {
  failedText: PropTypes.string,
};

FailedSection.defaultProps = {
  failedText: '',
};

export default (FailedSection);
