import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as invoiceActions from '../../../reducers/invoicesReducer';
import InvoiceStepperSteps from './InvoiceStepperSteps';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';

class InvoiceStepperWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
    };
  }

  closeStepper = () => {
    const {
      resetCreateInvoiceStore,
      toggleInvoiceCreationPopup,
    } = this.props;
    this.setState({
      activeStep: 0,
    });
    toggleInvoiceCreationPopup();
    resetCreateInvoiceStore();
  };

  handleNext = () => {
    const { activeStep } = this.state;
    if (activeStep === 1) {
      this.setState({
        activeStep: activeStep + 1,
      });
    } else {
      this.setState({
        activeStep: activeStep + 1,
      });
    }
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  createInvoice = () => {
    const { doRequestInvoiceCreation } = this.props;
    doRequestInvoiceCreation();
    this.handleNext();
  };

  render() {
    const steps = InvoiceStepperSteps();

    const {
      createInvoice,
    } = this.props;

    const { activeStep } = this.state;

    const numberOfSteps = steps.length;
    const activeStepObject = steps[activeStep];
    const {
      user, dueDate, selectedReservations, additionalItems,
    } = createInvoice;
    const isBackDisabled = activeStep === 0;
    let isNextDisabled;

    switch (activeStep) {
      case 0:
        if (user.id === null) {
          isNextDisabled = true;
        }
        break;
      case 2:
        if (selectedReservations.length === 0 && additionalItems.length === 0) {
          isNextDisabled = true;
        } break;
      case 3:
        if (dueDate === null) {
          isNextDisabled = true;
        }
        break;
      default:
        break;
    }

    let backButton = (
      <Button
        variant="contained"
        color="secondary"
        onClick={this.handleBack}
        disabled={isBackDisabled}
      >
        back
      </Button>
    );
    let nextButton = (
      <Button
        variant="contained"
        color="primary"
        onClick={this.handleNext}
        disabled={isNextDisabled}
      >
        next
      </Button>
    );
    if (activeStep === numberOfSteps - 2) {
      nextButton = (
        <Button
          id="manager-bookings-create-send-invoice"
          variant="contained"
          color="primary"
          onClick={() => {
            isNextDisabled = true;
            this.createInvoice();
          }}
          disabled={isNextDisabled}
        >
          Create & Send
        </Button>
      );
    }
    if (activeStep === numberOfSteps - 1) {
      nextButton = (
        <Button
          id="manager-bookings-create-send-invoice"
          variant="contained"
          color="primary"
          onClick={() => {
            isNextDisabled = true;
            this.closeStepper();
          }}
          disabled={isNextDisabled}
        >
          Done
        </Button>
      );
    }
    if (activeStep === 0 || activeStep === numberOfSteps - 1) {
      backButton = (<div />);
    }

    return (
      <div className="reservation-creation-stepper">
        <div
          className="reservation-creation-stepper-header"
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography>
            Step
            {' '}
            {activeStep + 1}
            {' '}
            of
            {' '}
            {numberOfSteps}
          </Typography>
          <CloseDialogIcon onClick={() => this.closeStepper()} />
        </div>
        <div className="reservation-creation-stepper-body">
          {activeStepObject.view}
        </div>
        <div className="reservation-creation-stepper-navigation">
          <Stepper
            variant="none"
            type="text"
            steps={numberOfSteps}
            activeStep={activeStep}
            onBack={this.handleBack}
            onNext={this.handleNext}
            nextButton={nextButton}
            backButton={backButton}
            position="static"
          />
        </div>
      </div>
    );
  }
}

InvoiceStepperWrapper.propTypes = {
  createInvoice: PropTypes.shape().isRequired,
  doRequestInvoiceCreation: PropTypes.func.isRequired,
  resetCreateInvoiceStore: PropTypes.func.isRequired,
  toggleInvoiceCreationPopup: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  createInvoice: state.invoices.createInvoice,
});

const mapDispatchToProps = (dispatch) => ({
  doRequestInvoiceCreation: () => dispatch(
    invoiceActions.requestInvoiceCreation(),
  ),
  toggleInvoiceCreationPopup: () => dispatch(
    invoiceActions.toggleInvoiceCreationPopup(),
  ),
  resetCreateInvoiceStore: () => dispatch(
    invoiceActions.resetCreateInvoiceStore(),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceStepperWrapper);
