import { useEffect } from 'react';
import { pusher } from '../libs';

/**
 * Hook allows us to use Pusher in a functional component
 * @param {String} channel - the channel that you want to subscribe to
 * @param {String} event - the event that you want to listen to
 * @param {function} callback - a callback function that will be called when the channel is updated
 * @param {Array} dependencies - an array of dependencies that will trigger the hook to re-run
 */
const usePusher = (channel, event, callback, dependencies = []) => {
  useEffect(() => {
    const subscribedChannel = pusher.subscribe(channel);

    subscribedChannel.bind(event, callback);

    return () => {
      pusher.unsubscribe(channel);
    };
  }, dependencies);
};

export default usePusher;
