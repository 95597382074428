import { useDispatch } from 'react-redux';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';

/**
 * Custom hook that allows you to push a toast message to the user
 */
const useToast = () => {
  const dispatch = useDispatch();

  /**
   * Triggers a toast message
   * @param {{
   * type: 'error' | 'success',
   * message: string
   * }} param - type of toast and message to display
   */
  const trigger = ({ type = 'success', message }) => {
    switch (type) {
      case 'success':
        dispatch(sessionActions.updateSuccessfulSnackbar(message, true));
        break;
      case 'error':
        dispatch(sessionActions.updateErrorSnackbar(message, true));
        break;
      case 'info':
        dispatch(sessionActions.updateInfoSnackbar(message, true));
        break;
      default:
        dispatch(sessionActions.updateSuccessfulSnackbar(message, true));
        break;
    }
  };

  return {
    trigger,
  };
};

export default useToast;
