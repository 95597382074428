import React from 'react';
import BlockBookingStepperTimeslotPicker from './BlockBookingStepperTimeslotPicker';
import BlockBookingStepperUserInfoPicker from './BlockBookingStepperUserInfoPicker';
import BlockBookingStepperRecurrence from './BlockBookingStepperRecurrence';
import BlockBookingStepperPayment from './BlockBookingStepperPayment';
import BookingStepperConflicts from './BlockBookingStepperConflicts';

const BookingStepperSteps = () => [
  {
    title: 'Booker Information Selection',
    view: <BlockBookingStepperUserInfoPicker />,
  },
  {
    title: 'Timeslot Selection:',
    view: <BlockBookingStepperTimeslotPicker />,
  },
  {
    title: 'Reccurence Selection:',
    view: <BlockBookingStepperRecurrence />,
  },
  {
    title: 'Conflicts:',
    view: <BookingStepperConflicts />,
  },
  {
    title: 'Payment Status Selection:',
    view: <BlockBookingStepperPayment />,
  },
];

export default BookingStepperSteps;
