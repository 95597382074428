import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function getFacilitiesService(companyId) {
  const url = `/api/companies/${companyId}/facilities`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}
export async function getAmenitiesService(companyId, facilityId) {
  const url = `/api/companies/${companyId}/amenities/${facilityId}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}
export async function createFacilityAmenitiesService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/facility-amenities`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
export async function deleteFacilityAmenitiesService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/delete-facility-amenities`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
export async function getFacilityService(id) {
  const url = `/api/facilities/${id}?manager=true`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}
export async function updateFacilityService(companyId, facilityId, reqBody) {
  const url = `/api/companies/${companyId}/facilities/${facilityId}`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}
export async function createFacilityService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/create-facility`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
export async function createAmenityService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/create-amenity`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
export async function updateFacilityAmenityService(companyId, amenityId, facilityId, body) {
  const url = `/api/companies/${companyId}/amenities/${amenityId}/facility/${facilityId}`;
  const response = await httpHelpers.authenticatedPutRequest(url, body);
  return response;
}
export async function getAllFacilitiesService() {
  const url = '/api/facilities';
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}
