import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
} from '@material-ui/core';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';

const OrderTable = ({
  order, handleOrderChange, isProductAvailable, currency, disabled,
}) => (
  <TableContainer
    style={{
      opacity: disabled ? 0.5 : 1,
    }}
  >
    <Table>
      <TableHead>
        <TableCell style={{ width: '40%' }} align="left">Name</TableCell>
        <TableCell style={{ width: '40%' }} align="left">Quantity</TableCell>
        <TableCell style={{ width: '20%' }} align="left">Price</TableCell>
      </TableHead>
      <TableBody>
        {order && order.map((addon) => (
          <ConditionallyVisible condition={addon.quantity > 0}>
            <TableRow key={addon.id}>
              <TableCell style={{ width: '40%' }} align="left">{addon.name}</TableCell>
              <TableCell style={{ width: '50%' }} align="left">
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <Button
                    disabled={addon.quantity === 0 || disabled}
                    onClick={() => handleOrderChange(order, 'REMOVE', addon)}
                  >
                    -
                  </Button>
                  {addon.quantity}
                  <Button
                    disabled={!isProductAvailable(addon) || disabled}
                    onClick={() => handleOrderChange(order, 'ADD', addon)}
                  >
                    +
                  </Button>
                </div>
              </TableCell>
              <TableCell style={{ width: '10%' }} align="left">
                {getCurrencySymbol(currency)}
                {addon?.price?.toFixed(2)}
              </TableCell>
            </TableRow>
          </ConditionallyVisible>
        ))}
        <TableRow>
          <TableCell style={{ width: '40%', fontWeight: 'bold' }} align="left">Total</TableCell>
          <TableCell style={{ width: '40%', fontWeight: 'bold' }} align="left">
            {order.reduce((acc, addon) => acc + (addon.quantity), 0)}
            {' items'}
          </TableCell>
          <TableCell style={{ width: '20%' }} align="left">
            {getCurrencySymbol(currency)}
            {order.reduce((acc, addon) => acc + (addon.price), 0)?.toFixed(2)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

OrderTable.propTypes = {
  order: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleOrderChange: PropTypes.func.isRequired,
  isProductAvailable: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

OrderTable.defaultProps = {
  disabled: false,
};

export default OrderTable;
