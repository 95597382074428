/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import FacilityAndSubFacilityPickerList from '../facilities/components/FacilityAndSubFacilityPickerList';
import * as actions from '../../reducers/pricingReducer';

const styles = () => ({
  root: {
    minWidth: 360,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});

class PricingAddFacilityDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    const { editingPriceGroup, flagFacilitiesChanged } = this.props;
    if (editingPriceGroup) {
      flagFacilitiesChanged();
    }
    this.setState({ open: false });
  };

  render() {
    const isMobile = window.innerWidth < 768;

    const {
      classes, companyFacilities, priceGroup, updatePriceGroupStore,
    } = this.props;
    const { facilities } = priceGroup;
    const { open } = this.state;
    return (
      <div>
        <Button className={classes.button} color="primary" variant="contained" onClick={() => this.handleClickOpen()}>
          Add
        </Button>
        <Dialog
          open={open}
          onRequestClose={this.handleCancelClose}
          fullScreen={isMobile}
          classes={{ paper: classes.root }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>Add facilities to price group</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <DialogContent>
            <FacilityAndSubFacilityPickerList
              facilities={companyFacilities}
              updateFacilities={(nFacilities) => updatePriceGroupStore(
                { facilities: nFacilities },
              )}
              showSubFacilities={false}
              checkedFacilities={facilities}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary" variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    facilities,
    pricing,
  } = state;
  const { companyFacilities } = facilities;
  const { priceGroup } = pricing;
  return {
    companyFacilities,
    priceGroup,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updatePriceGroupStore: (keyWithValue) => dispatch(actions.updatePriceGroupStore(keyWithValue)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withStyles(styles())(PricingAddFacilityDialog));
