/* eslint-disable react/prop-types */
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Dialog, DialogTitle, DialogActions, DialogContent, Button,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { DayPickerSingleDateController } from 'react-dates';
import { useMobile, useToast } from '../../../../hooks';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';
import { createExclusionService } from '../../../../services/subscriptionsServices';

const validationSchema = Yup.object().shape({
  exclusions: Yup.array().of(Yup.object().shape({
    date: Yup.string().required('Date is required'),
  })).min(1, 'Please apply at least one exclusion date, or simply click the cancel button.'),
});

export const SubscriptionAddExclusionDialog = ({
  isOpen, onClose, onSuccess, subscription,
}) => {
  const queryClient = useQueryClient();
  const isMobile = useMobile();
  const toast = useToast();

  const mutation = useMutation({
    mutationFn: async (data) => {
      queryClient.setQueryData(['subscription', subscription.id], (oldData) => ({
        ...oldData,
        accessRestriction: {
          ...oldData.accessRestriction,
          exclusions: [
            ...oldData.accessRestriction.exclusions,
            ...data.exclusions.map((x) => ({
              id: x.date,
              accessRestrictionId: oldData.accessRestriction.id,
              date: x.date,
              status: 'ACTIVE',
            })),
          ],
        },
      }));

      const res = await createExclusionService(
        subscription.companyId,
        subscription.accessRestriction.id,
        data.exclusions,
      );

      if (res.status !== 200) {
        throw new Error(res.data);
      }

      return res.data;
    },
    onError: (error) => {
      queryClient.invalidateQueries(['subscription', subscription.id]);
      toast.trigger({
        type: 'error',
        message: 'Failed to update the subscription exclusions!',
      });
      console.error(error);
    },
    onSuccess: () => {
      toast.trigger({
        type: 'success',
        message: 'Subscription exclusions updated successfully',
      });
      queryClient.invalidateQueries(['subscription', subscription.id]);
      onClose();
      onSuccess?.();
    },
  });

  const { accessRestriction, invoices } = subscription;
  const { weekday, validFrom, validTo } = accessRestriction;

  const invalidDates = invoices?.filter((x) => x.status !== 'VOID').map((inv) => inv.invoice_subscription?.slot);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={isMobile}
      maxWidth="sm"
      fullWidth
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle>Add Exclusion</DialogTitle>
        <DialogActions variant="none">
          <CloseDialogIcon onClick={onClose} />
        </DialogActions>
      </div>

      <Formik
        initialValues={{
          exclusions: [],
        }}
        validationSchema={validationSchema}
        onSubmit={(data) => mutation.mutate(data)}
      >
        {({
          values, setFieldValue, handleSubmit, errors,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              minHeight: '380px',
            }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'center',
              }}
              >
                <DayPickerSingleDateController
                  isDayHighlighted={(day) => values.exclusions.some(
                    (x) => moment(x.date).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD'),
                  )}
                  isDayBlocked={(day) => {
                    // Is the day a subscription day?
                    if (moment(day).weekday() !== weekday) return true;

                    // Is the day before the valid from date?
                    if (moment(day).isBefore(moment(validFrom).subtract(1, 'day'))) return true;

                    // Is the day after the valid to date?
                    if (moment(day).isAfter(moment(validTo).add(1, 'day'))) return true;

                    // Is the day already an exclusion date?
                    if (accessRestriction.exclusions.some(
                      (x) => moment(x.date).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD'),
                    )) return true;

                    // Is the day an invalid date?
                    if (invalidDates?.some(
                      (x) => moment(x).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD'),
                    )) return true;

                    return false;
                  }}
                  onDateChange={(date) => {
                    const formattedDate = date.format('YYYY-MM-DD');
                    if (values.exclusions.some(
                      (exclusion) => exclusion.date === formattedDate,
                    )
                    ) {
                      setFieldValue('exclusions', values.exclusions.filter((x) => x.date !== formattedDate));
                    } else {
                      setFieldValue('exclusions', [...values.exclusions, { date: formattedDate }]);
                    }
                  }}
                />
              </div>
              {errors.exclusions && (
                <Typography variant="body2" color="error">
                  {errors.exclusions}
                </Typography>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                type="button"
                onClick={onClose}
                color="secondary"
                autoFocus
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                autoFocus
                disabled={mutation.isLoading}
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
