import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import * as Yup from 'yup';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import { useFormikContext } from 'formik';
import createDemoTemplates from '../../../utils/createDemoTemplates';
import AddAddOn from '../AddAddOn';
import AddAmenity from '../AddAmenity';
import AddFacility from '../AddFacility';

export const initialValues = {
  sites: [
    {
      name: '',
      siteAddress: null,
      fullAddress: null,
      location: null,
      facilities: [],
      amenities: [],
      addons: [],
    },
  ],
};

export const validation = Yup.object({
  sites: Yup.array().of(
    Yup.object(
      {
        name: Yup.string().required('Site name is required'),
        siteAddress: Yup.string().required('Site address is required'),
        fullAddress: Yup.object({
          addressLineOne: Yup.string(),
          addressLineTwo: Yup.string(),
          city: Yup.string(),
          country: Yup.string(),
          postcode: Yup.string(),
        }),
        location: Yup.object({
          lat: Yup.number(),
          lng: Yup.number(),
        }),
        facilities: Yup.array().of(
          Yup.object({
            name: Yup.string().required('Name is required'),
            templateName: Yup.string().required('Template Name is required'),
            amount: Yup.number().required('Price is required'),
            subUnits: Yup.number().required('Sub-Units is required'),
            unitName: Yup.string(),
            quantity: Yup.number(),
          }),
        ),
        amenities: Yup.array().of(
          Yup.object({
            name: Yup.string().required('Name is required'),
            price: Yup.number().required('Price is required'),
          }),
        ),
        addons: Yup.array().of(
          Yup.object({
            name: Yup.string().required('Name is required'),
            price: Yup.number().required('Price is required'),
            type: Yup.string().required('Type is required'),
          }),
        ),
      },
    ),
  ).min(1, 'You must have at least 1 site').required('Sites are required'),
});

const SiteDetailsForm = () => {
  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    dirty,
  } = useFormikContext();

  const [addOnData, setAddOnData] = useState(null);
  const [amenityData, setAmenityData] = useState(null);
  const [facilityData, setFacilityData] = useState(null);
  const [companyAddressIsSiteAddress, setCompanyAddressIsSiteAddress] = useState(true);

  useEffect(() => {
    const template = createDemoTemplates.find((x) => x.value === values.templateType);
    setFieldValue('sites', template.configuration.sites.map((x) => ({
      ...x,
      siteAddress: companyAddressIsSiteAddress ? values.companyAddress : values.siteAddress,
      fullAddress: values.fullAddress,
      location: values.location,
    })));
  }, [values.templateType]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        <PBInput
          type="toggle"
          label="Use company address as site address"
          value={companyAddressIsSiteAddress}
          onChange={(event) => setCompanyAddressIsSiteAddress(event.target.checked)}
        />
        {
          values.sites.map((site, index) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                paddingBottom: '1rem',
                borderBottom: '1px solid gray',
              }}
              key={site}
            >
              <PBInput
                id={`sites[${index}].name`}
                label="Site Name"
                type="text"
                value={site.name}
                onChange={handleChange}
                errorMessage={errors?.sites?.[index]?.name}
                isError={dirty && Boolean(errors?.sites?.[index]?.name)}
              />

              <ConditionallyVisible condition={!companyAddressIsSiteAddress}>
                <PBInput
                  id={`sites[${index}].siteAddress`}
                  type="location"
                  locationDescription="Site Address"
                  value={site.siteAddress}
                  onChange={(newValue) => {
                    setFieldValue(`sites[${index}].siteAddress`, newValue.placeName);
                    setFieldValue(`sites[${index}].location`, newValue.location);
                    setFieldValue(`sites[${index}].fullAddress`, newValue.fullAddress);
                  }}
                  errorMessage={errors?.sites?.[index]?.siteAddress}
                  isError={dirty && Boolean(errors?.sites?.[index]?.siteAddress)}
                />
              </ConditionallyVisible>
              {/* Facilities */}
              <ConditionallyVisible condition={site.facilities.length > 0}>
                <div>
                  <Typography variant="h6" color="secondary">Facilities</Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Facility Name</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Sub-Units</TableCell>
                          <TableCell>Unit Name</TableCell>
                          <TableCell>Units</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {site.facilities.map((facility, facilityIndex) => (
                          <TableRow key={`site-${site.name}-facility-${facility.name}`}>
                            <TableCell>{facility.name}</TableCell>
                            <TableCell>{facility.templateName}</TableCell>
                            <TableCell>{facility.amount}</TableCell>
                            <TableCell>{facility.subUnits}</TableCell>
                            <TableCell>{facility.unitName}</TableCell>
                            <TableCell>{facility.quantity}</TableCell>
                            <TableCell>
                              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}>
                                <Button
                                  onClick={() => setFacilityData({
                                    ...facility,
                                    siteIndex: index,
                                    facilityIndex,
                                  })}
                                >
                                  <EditIcon />
                                </Button>
                                <Button
                                  onClick={() => setFieldValue(`sites[${index}].facilities`, site.facilities.filter((x) => x.name !== facility.name))}
                                >
                                  <DeleteIcon color="error" />
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </ConditionallyVisible>

              {/* Addons */}
              <ConditionallyVisible condition={site.addons.length > 0}>
                <div>
                  <Typography variant="h6" color="secondary">Addons</Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Addon Name</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Resource/Consumable</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {site.addons.map((addon, addonIndex) => (
                          <TableRow key={`site-${site.name}-addon-${addon.name}`}>
                            <TableCell>{addon.name}</TableCell>
                            <TableCell>{addon.price}</TableCell>
                            <TableCell>{addon.type}</TableCell>
                            <TableCell>
                              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}>
                                <Button
                                  onClick={() => setAddOnData({
                                    ...addon,
                                    siteIndex: index,
                                    addonIndex,
                                  })}
                                >
                                  <EditIcon />
                                </Button>
                                <Button
                                  onClick={() => setFieldValue(`sites[${index}].addons`, site.addons.filter((x) => x.name !== addon.name))}
                                >
                                  <DeleteIcon color="error" />
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </ConditionallyVisible>

              {/* Amenities */}
              <ConditionallyVisible condition={site.amenities.length > 0}>
                <div>
                  <Typography variant="h6" color="secondary">Amenities</Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Amenity Name</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {site.amenities.map((amenity, amenityIndex) => (
                          <TableRow key={`site-${site.name}-amenity-${amenity.name}`}>
                            <TableCell>{amenity.name}</TableCell>
                            <TableCell>{amenity.price}</TableCell>
                            <TableCell>
                              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}>
                                <Button
                                  onClick={() => setAmenityData({
                                    ...amenity,
                                    siteIndex: index,
                                    amenityIndex,
                                  })}
                                >
                                  <EditIcon />
                                </Button>
                                <Button
                                  onClick={() => setFieldValue(`sites[${index}].amenities`, site.amenities.filter((x) => x.name !== amenity.name))}
                                >
                                  <DeleteIcon color="error" />
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </ConditionallyVisible>

              {/* Buttons */}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: '1rem' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setFacilityData({
                      siteIndex: index,
                      facilityIndex: null,
                      name: '',
                      amount: '',
                      templateName: '',
                      unitName: '',
                      quantity: '',
                      subUnits: '',
                    })}
                  >
                    Add Facility
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setAddOnData({
                      siteIndex: index,
                      addonIndex: null,
                      name: '',
                      price: '',
                      type: '',
                    })}
                  >
                    Add AddOn
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setAmenityData({
                      siteIndex: index,
                      amenityIndex: null,
                      name: '',
                      price: '',
                    })}
                  >
                    Add Amenity
                  </Button>
                </div>

                <Button
                  variant="outlined"
                  onClick={() => setFieldValue('sites', values.sites.filter((x, i) => i !== index))}
                >
                  <DeleteIcon color="error" />
                  <span>Delete Site</span>
                </Button>
              </div>
            </div>
          ))
        }

        <div style={{ display: 'flex' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setFieldValue('sites', [
              ...values.sites,
              {
                name: '',
                siteAddress: values.companyAddress,
                fullAddress: values.fullAddress,
                location: values.location,
                facilities: [],
                amenities: [],
                addons: [],
              },
            ])}
          >
            Add Another Site
          </Button>
        </div>

        {/* Modals */}
        <>
          <AddAmenity
            onSubmit={(e) => {
              if (e.amenityIndex !== null) {
                setFieldValue(
                  `sites[${e.siteIndex}].amenities`,
                  values.sites[e.siteIndex].amenities.map((
                    amenity, index,
                  ) => (index === e.amenityIndex ? e : amenity)),
                );
              } else {
                setFieldValue(
                  `sites[${e.siteIndex}].amenities`,
                  [...values.sites[e.siteIndex].amenities, e],
                );
              }
              setAmenityData(null);
            }}
            onClose={() => setAmenityData(null)}
            values={amenityData}
          />

          <AddFacility
            onSubmit={(e) => {
              if (e.facilityIndex !== null) {
                setFieldValue(
                  `sites[${e.siteIndex}].facilities`,
                  values.sites[e.siteIndex].facilities.map((
                    facility, index,
                  ) => (index === e.facilityIndex ? e : facility)),
                );
              } else {
                setFieldValue(
                  `sites[${e.siteIndex}].facilities`,
                  [...values.sites[e.siteIndex].facilities, e],
                );
              }
              setFacilityData(null);
            }}
            onClose={() => setFacilityData(null)}
            values={facilityData}
          />

          <AddAddOn
            onSubmit={(e) => {
              if (e.addonIndex !== null) {
                setFieldValue(
                  `sites[${e.siteIndex}].addons`,
                  values.sites[e.siteIndex].addons.map((
                    addon, index,
                  ) => (index === e.addonIndex ? e : addon)),
                );
              } else {
                setFieldValue(
                  `sites[${e.siteIndex}].addons`,
                  [...values.sites[e.siteIndex].addons, e],
                );
              }
              setAddOnData(null);
            }}
            onClose={() => setAddOnData(null)}
            values={addOnData}
          />
        </>
      </div>
    </>
  );
};

export default SiteDetailsForm;
