import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const parseSubfacilitySelectorDisplayValue = (selectedFacility, selectedSubFacility) => {
  if (selectedSubFacility) {
    return selectedSubFacility;
  }
  if (
    selectedFacility && selectedFacility.subFacilities && selectedFacility.subFacilities?.length > 0
  ) {
    return null;
  }
  return null;
};

const parseSubfacilitySelectorLabel = (selectedFacility) => {
  if (selectedFacility.subFacilities && selectedFacility.subFacilities?.length > 0) {
    return 'Select Sub Facility';
  }
  return 'No Sub Facilities Found';
};

const parseDisableSubfacilityChoice = (selectedFacility, allowSubAutoSelectionChoice) => {
  if (!selectedFacility.subFacilities || selectedFacility.subFacilities?.length < 1) {
    return true;
  }
  if ((selectedFacility.splitType === 'USER_SELECTION'
    || selectedFacility.splitType === 'NONE'
    || selectedFacility.splitType === 'MULTIPLE_SELECTION')
    && !allowSubAutoSelectionChoice) {
    return true;
  }
  return false;
};

const CompanyFacilityPicker = ({
  updateSelectedFacility,
  updateSelectedSubFacility,
  companyFacilities,
  selectedFacility,
  selectedSubFacility,
  allowSubAutoSelectionChoice,
  editBookingDialogOpen,
  siteId,
}) => {
  let facilities = companyFacilities.filter((facility) => facility.type !== 'AMENITY');
  if (siteId) {
    facilities = companyFacilities.filter((facility) => facility.siteId === siteId);
  }
  return (
    <div className="facility-picker-wrapper">
      <Autocomplete
        options={facilities}
        getOptionLabel={(option) => option.name}
        style={{ maxWidth: 350, width: '100%' }}
        onChange={(event, option) => updateSelectedFacility({ ...option })}
        value={selectedFacility && selectedFacility}
        disableClearable
        renderInput={(params) => (
          <TextField {...params} label="Select Facility" variant="outlined" fullWidth />
        )}
        disabled={editBookingDialogOpen}
      />
      {selectedFacility
       && selectedFacility.subFacilities && selectedFacility.subFacilities?.length > 0 && (
       <Autocomplete
         options={selectedFacility?.subFacilities}
         getOptionLabel={(option) => option.name}
         style={{ width: 350 }}
         disableClearable
         disabled={parseDisableSubfacilityChoice(selectedFacility, allowSubAutoSelectionChoice)
          || editBookingDialogOpen}
         onChange={(event, option) => updateSelectedSubFacility({ ...option })}
         value={parseSubfacilitySelectorDisplayValue(selectedFacility, selectedSubFacility)}
         renderInput={(params) => (
           <TextField
             {...params}
             label={parseSubfacilitySelectorLabel(selectedFacility)}
             variant="outlined"
             fullWidth
           />
         )}
       />
      )}
    </div>
  );
};

CompanyFacilityPicker.propTypes = {
  companyFacilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateSelectedSubFacility: PropTypes.func.isRequired,
  updateSelectedFacility: PropTypes.func.isRequired,
  selectedFacility: PropTypes.shape().isRequired,
  selectedSubFacility: PropTypes.shape(),
  allowSubAutoSelectionChoice: PropTypes.bool,
  editBookingDialogOpen: PropTypes.bool.isRequired,
  siteId: PropTypes.string,
};

CompanyFacilityPicker.defaultProps = {
  selectedSubFacility: null,
  siteId: null,
  allowSubAutoSelectionChoice: false,
};

const mapStateToProps = (state) => ({
  companyFacilities: state.facilities.companyFacilities,
  selectedFacility: state.facilities.selectedFacility,
  selectedSubFacility: state.facilities.selectedSubFacility,
  pitchSplit: state.reservation.selectedPitchSplit,
  editBookingDialogOpen: state.bookings.editBookingDialogOpen,
});

export default connect(mapStateToProps, null)(CompanyFacilityPicker);
