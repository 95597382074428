/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import { Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as bookingModifiersActions from '../../../reducers/bookingModifiersReducer';
import FacilityAndSubFacilityPickerList from '../../facilities/components/FacilityAndSubFacilityPickerList';
import { GenerateBookingModifierTag } from './GenerateBookingModifierTag';

const EditBookingModifierGroupDialog = ({
  bookingModifier,
  editingBookingModifier,
  updateBookingModifierStore,
  companyFacilities,
  toggleEditingBookingModifier,
  resetBookingModifierDialog,
  requestBookingModifierGroupUpdate,
}) => {
  const handleRequestClose = () => {
    if (editingBookingModifier) {
      toggleEditingBookingModifier();
    }
    resetBookingModifierDialog();
  };

  const saveBookingModifierGroup = () => {
    requestBookingModifierGroupUpdate();
    handleRequestClose();
  };

  const { facilities, bookingModifiers } = bookingModifier;

  return (
    <Dialog open={editingBookingModifier} onClose={handleRequestClose} fullWidth>
      <div style={{
        display: 'flex', gap: '2rem', padding: '2rem', flexDirection: 'column',
      }}
      >

        {/* Title and Close */}
        <div style={{
          display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between',
        }}
        >
          <Typography variant="h6" style={{ fontWeight: 600 }}>
            Edit Booking Modifier
          </Typography>
          <Button onClick={handleRequestClose}>
            <CancelTwoToneIcon style={{ justifySelf: 'flex-end' }} />
          </Button>
        </div>

        {/* Facilities */}
        <Accordion
          style={{
            display: 'flex', flexDirection: 'column', backgroundColor: '#efefef', border: '1px solid rgb(224, 224, 224)', borderRadius: '1rem',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="facilityList-content"
            id="facilityList-header"
          >
            <Typography style={{ fontWeight: '600' }}>Facilities Booking Modifier Applies To</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FacilityAndSubFacilityPickerList
              facilities={companyFacilities}
              updateFacilities={(nFacilities) => updateBookingModifierStore(
                { facilities: nFacilities },
              )}
              showSubFacilities={false}
              checkedFacilities={facilities}
            />
          </AccordionDetails>
        </Accordion>

        <GenerateBookingModifierTag bookingModifiers={bookingModifiers} isEditing />

        <DialogActions>
          <Button onClick={handleRequestClose} variant="contained">
            Cancel
          </Button>
          <Button onClick={saveBookingModifierGroup} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

EditBookingModifierGroupDialog.propTypes = {
  facilities: PropTypes.arrayOf(PropTypes.string).isRequired,
  bookingModifier: PropTypes.shape().isRequired,
  requestBookingModifierGroupUpdate: PropTypes.func.isRequired,
  companyFacilities: PropTypes.shape().isRequired,
  editingBookingModifier: PropTypes.bool.isRequired,
  updateBookingModifierStore: PropTypes.func.isRequired,
  toggleEditingBookingModifier: PropTypes.func.isRequired,
  resetBookingModifierDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  timezone: state.companies.companyInfo.timezone,
  companyFacilities: state.facilities.companyFacilities,
  bookingModifier: state.bookingModifiers.bookingModifier,
  editingBookingModifier: state.bookingModifiers.editingBookingModifier,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createBookingModifier: (reqBody) => (bookingModifiersActions.createBookingModifier(reqBody)),
  toggleBookingModifierDialog: () => (bookingModifiersActions.toggleBookingModifierDialog()),
  toggleEditingBookingModifier: () => (bookingModifiersActions.toggleEditingBookingModifier()),
  resetBookingModifierDialog: () => (bookingModifiersActions.resetBookingModifierDialog()),
  updateBookingModifierStore: (value) => (
    bookingModifiersActions.updateBookingModifierStore(value)),
  requestBookingModifierGroupUpdate: () => (
    bookingModifiersActions.requestBookingModifierGroupUpdate()),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditBookingModifierGroupDialog);
