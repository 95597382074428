import React, { useState, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import LoadingButton from '@pitchbooking-dev/pb-shared/lib/components/LoadingButton';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import { useMobile } from '../../../hooks';
import { replaceTeamInLeague } from '../../../services/leaguesServices';
import * as leagueActions from '../../../reducers/leaguesReducer';

const ReplaceTeamDialog = ({
  leagueId, companyId, teamId, teamName,
}) => {
  const dispatch = useDispatch();
  const { teams } = useSelector((state) => state.leagues.league);

  const [open, setOpen] = useState(false);
  const isMobile = useMobile();
  const replacableTeams = useMemo(() => teams.filter((x) => x.league_team.status === 'WAITING'), [teams]);

  const validationSchema = Yup.object().shape({
    replacementTeam: Yup.string().required('You must select a team'),
    inheritPoints: Yup.boolean(),
    inheritFixtures: Yup.boolean(),
  });

  const onSubmit = async ({ replacementTeam, inheritPoints, inheritFixtures }, actions) => {
    actions.setSubmitting(true);

    try {
      const res = await replaceTeamInLeague(
        companyId, leagueId, teamId, replacementTeam, inheritPoints, inheritFixtures,
      );
      if (res.error) throw new Error(res.error);

      dispatch(leagueActions.getLeague(leagueId));
      dispatch(sessionActions.updateSuccessfulSnackbar('Team successfully replaced!', true));
      setOpen(false);
    } catch (err) {
      console.error(err);
      dispatch(sessionActions.updateErrorSnackbar(`Failed to replace team: ${err}`, true));
    }

    actions.setSubmitting(false);
  };

  return (
    <div>
      <Button color="secondary" variant="outlined" onClick={() => setOpen(true)}>
        Replace Team
      </Button>

      <Dialog
        open={open}
        fullScreen={isMobile}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">{`Replace ${teamName}`}</Typography>

            <CloseDialogIcon onClick={() => setOpen(!open)} />
          </div>
        </DialogTitle>

        <DialogContent>
          <Formik
            initialValues={{
              replacementTeam: '',
              inheritPoints: true,
              inheritFixtures: true,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values, errors, touched, handleChange, handleSubmit, resetForm, isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                onReset={resetForm}
                style={{
                  display: 'flex', flexDirection: 'column', gap: '0.75rem', minWidth: !isMobile ? 500 : 0,
                }}
              >
                <PBInput
                  id="replacementTeam"
                  type="select"
                  label="Replacement Team"
                  value={values.replacementTeam}
                  onChange={handleChange}
                  options={replacableTeams.map((x) => ({
                    label: x.name,
                    value: x.id,
                  }))}
                  isError={touched.replacementTeam && Boolean(errors.replacementTeam)}
                  errorMessage={touched.replacementTeam && errors.replacementTeam}
                />

                <div>
                  <Typography>{`Should this team inherit ${teamName}'s:`}</Typography>

                  <PBInput
                    id="inheritPoints"
                    type="toggle"
                    label="Points?"
                    value={values.inheritPoints}
                    onChange={handleChange}
                    isError={touched.inheritPoints && Boolean(errors.inheritPoints)}
                    errorMessage={touched.inheritPoints && errors.inheritPoints}
                  />

                  <PBInput
                    id="inheritFixtures"
                    type="toggle"
                    label="Fixtures?"
                    value={values.inheritFixtures}
                    onChange={handleChange}
                    isError={touched.inheritFixtures && Boolean(errors.inheritFixtures)}
                    errorMessage={touched.inheritFixtures && errors.inheritFixtures}
                  />
                </div>

                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <LoadingButton
                    buttonText="Replace Team"
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                    color="primary"
                  />
                </div>

              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ReplaceTeamDialog.propTypes = {
  companyId: PropTypes.string.isRequired,
  leagueId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
};

export default ReplaceTeamDialog;
