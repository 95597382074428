import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function getXeroConsentUrl(companyId) {
  const url = `/api/companies/${companyId}/utils/xero-consent-url`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function generateNewXeroToken(companyId, code) {
  const url = `/api/companies/${companyId}/utils/xero-token?code=${code}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function processPowerleagueBacsService(companyId) {
  const url = `/api/companies/${companyId}/utils/process-powerleague-officials-bacs`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function triggerCronJobService(companyId, cronJobName) {
  const url = `/api/companies/${companyId}/utils/trigger-cron-job/${cronJobName}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}
export async function refreshLeagues(companyId) {
  const url = `/api/companies/${companyId}/utils/refresh-leagues`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}
