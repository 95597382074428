import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import Button from '@material-ui/core/Button';
import { Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PricingAddRuleDialog from './PricingAddRuleDialog';
import PriceModifierTag from './PriceModifierTag';
import EditPriceRuleDialog from './EditPriceRuleDialog';

const PriceModifierTags = (props) => {
  const {
    priceGroup, showAdd = false,
    removePriceModifier, removeTempPriceModifier, showEditButton = true,
  } = props;

  const hasMoreThanOneDefault = useMemo(() => priceGroup.priceModifiers.reduce(
    (acc, curr) => (curr.modifierType === 'DEFAULT' ? [...acc, curr] : acc), [],
  ).length > 1, [priceGroup]);

  const hasNoDefault = useMemo(() => priceGroup.priceModifiers.reduce(
    (acc, curr) => (curr.modifierType === 'DEFAULT' ? [...acc, curr] : acc), [],
  ).length === 0, [priceGroup]);

  return (
    <div>
      {hasMoreThanOneDefault ? (
        <Alert severity="error">
          You have more than 1 default pricing, please remove one.
        </Alert>
      ) : showEditButton && hasNoDefault && (
      <Alert severity="warning">
        You must have a default pricing rule.
      </Alert>
      )}

      {priceGroup.priceModifiers
        .sort((a, b) => {
          if (a.modifierType === 'DEFAULT' && b.modifierType !== 'DEFAULT') { return -1; }
          if (a.modifierType !== 'DEFAULT' && b.modifierType === 'DEFAULT') { return 1; }
          return 0;
        })
        .map((priceModifier) => (
          <>
            <div style={{ display: 'flex', margin: '1rem 0' }}>
              <div>
                <PriceModifierTag priceModifier={priceModifier} />
                <div style={{ color: priceModifier.id ? '#a2a2a2' : '#24dc89', fontSize: '10px', marginLeft: '0.25rem' }}>
                  {priceModifier.id ?? 'Newly added'}
                </div>
              </div>

              {/* Buttons */}
              <div style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'end',
                alignItems: 'center',
              }}
              >
                {showEditButton && (
                <EditPriceRuleDialog pM={priceModifier} />
                )}

                {priceModifier.modifierType !== 'DEFAULT' && showEditButton ? (
                  <Button
                    onClick={() => {
                      if (priceModifier.modifierType === '') {
                        removeTempPriceModifier(priceModifier);
                      } else {
                        removePriceModifier(priceModifier.id);
                      }
                    }}
                    variant="contained"
                    size="small"
                    style={{
                      marginLeft: '10px',
                      backgroundColor: '#AC372F',
                      color: '#fff',
                    }}
                  >
                    DELETE
                  </Button>
                ) : hasMoreThanOneDefault && showEditButton && (
                <Button
                  onClick={() => {
                    if (priceModifier.modifierType === '') {
                      removeTempPriceModifier(priceModifier);
                    } else {
                      removePriceModifier(priceModifier.id);
                    }
                  }}
                  variant="contained"
                  size="small"
                  style={{
                    marginLeft: '10px',
                    backgroundColor: '#AC372F',
                    color: '#fff',
                  }}
                >
                  DELETE
                </Button>
                )}

              </div>
            </div>

            {/* Hide divider in table view */}
            {showEditButton && (
            <Divider />
            )}
          </>
        ))}

      <ConditionallyVisible condition={showAdd}>
        <PricingAddRuleDialog
          priceGroup={priceGroup}
        />
      </ConditionallyVisible>
    </div>
  );
};

PriceModifierTags.propTypes = {
  priceGroup: PropTypes.shape().isRequired,
  showAdd: PropTypes.bool.isRequired,
  removePriceModifier: PropTypes.func.isRequired,
  removeTempPriceModifier: PropTypes.func.isRequired,
  showEditButton: PropTypes.bool.isRequired,
};

export default (PriceModifierTags);
