import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Yup from 'yup';

import {
  Button,
  Step,
  Stepper,
  StepLabel,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import LeagueDetailsForm from './Forms/LeagueDetailsForm';
import LeagueMatchDetailsForm from './Forms/LeagueMatchDetailsForm';
import LeagueOptionalsForm from './Forms/LeagueOptionalsForm';

const styles = {
  button: {
    marginLeft: 10,
  },
  buttonsWrapper: {
    marginRight: 20,
    marginTop: 20,
    marginBottom: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
  },
};

// 3 step validation schema
const validationSchema = [
  Yup.object().shape({
    name: Yup.string().required('League name is required'),
    description: Yup.string().required('Description is required'),
    additionalInfo: Yup.string(),
    isOnSite: Yup.boolean(),
    Site: Yup.string().when('isOnSite', (isOnSite, schema) => (isOnSite ? schema.required('Site is required') : schema)),
    facilities: Yup.array(Yup.string()),
  }),
  Yup.object().shape({
    maxTeams: Yup.number().moreThan(3, 'You need to have at least 4 teams to create a league').required('Maximum teams is required'),
    costPerFixture: Yup.string().required('Cost Per Fixture is required'),
    teamsPlayOtherTeams: Yup.number().required('Number of times teams to play each other is required'),
  }),
  Yup.object().shape({
    pointsForDraw: Yup.number().required('Points for draw is required'),
    pointsForLoss: Yup.number().required('Points for loss is required'),
    pointsForWin: Yup.number().required('Points for win is required'),
  }),
];

const formSteps = ['League details', 'Match details', 'Options'];

const renderFormStep = (step, sites, facilities) => {
  switch (step) {
    case 0:
      return (
        <LeagueDetailsForm sites={sites} facilities={facilities} />
      );
    case 1:
      return (
        <LeagueMatchDetailsForm />
      );
    case 2:
      return (
        <LeagueOptionalsForm />
      );
    default:
      return <div>Not Found</div>;
  }
};

const CreateLeagueStepper = (props) => {
  const [error, setError] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchemaStep = validationSchema[activeStep];
  const isLastStep = activeStep === formSteps.length - 1;

  const {
    createLeague, sites, facilities,
  } = props;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      try {
        createLeague({ ...values });
      } catch (err) {
        setError('An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us at support@pitchbooking.com.');
      }
    } else {
      actions.setTouched({});
      actions.setSubmitting(false);
      handleNext();
    }
  };

  return (
    <>
      <Stepper activeStep={activeStep}>
        {formSteps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Formik
        initialValues={{
          pointsForWin: 3,
          pointsForDraw: 1,
          pointsForLoss: 0,
          name: '',
          description: '',
          maxTeams: '',
          costPerFixture: '',
          closingDate: moment().format('YYYY-MM-DD HH:mm'),
          location: '',
          locationDescription: '',
          locationChanged: false,
          isDivision: false,
          numberOfDivisions: 0,
          Site: '',
          leagueTimings: false,
          kickOffTime: null,
          dayOfWeek: null,
          gameLength: null,
          teamsPlayOtherTeams: '',
          visible: false,
          isCustomPointsOptions: false,
          maxRefereePayment: null,
          isOnSite: sites.length > 0,
          facilities: [],
          startDate: moment().format('YYYY-MM-DD HH:mm'),
          endDate: moment().add(10, 'weeks').format('YYYY-MM-DD HH:mm'),
          additionalInfo: null,
        }}
        validationSchema={currentValidationSchemaStep}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        { () => (
          <Form
            style={{
              display: 'flex', flexDirection: 'column', gap: '0.75rem',
            }}
          >
            {/* Form Steps */}
            {renderFormStep(activeStep, sites, facilities)}

            {/* Form Submit Error */}
            <div style={{ height: '20px' }} />
            <ConditionallyVisible condition={error}>
              <div style={{ color: 'red' }}>{error}</div>
            </ConditionallyVisible>

            {/* Form Control Buttons */}
            <div style={styles.buttonsWrapper}>
              {activeStep !== 0 && (
              <Button color="secondary" onClick={handleBack}>
                Back
              </Button>
              )}
              <Button
                style={styles.button}
                color="primary"
                variant="contained"
                type="submit"
              >
                {isLastStep ? 'Create League' : 'Next'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

CreateLeagueStepper.propTypes = {
  createLeague: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  facilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default CreateLeagueStepper;
