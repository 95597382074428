import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function getPricingPackagesService(companyId) {
  const url = `/api/companies/${companyId}/pricing`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function addFacilitiesToPriceGroupService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/pricing-add-facilities`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function addRuleToPriceGroupService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/pricing-add-rule`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function deletePriceModifierService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/delete-price-modifier`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function updatePriceGroupStoreService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/update-pricing`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function requestPriceGroupCreationService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/create-pricing-group`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function requestDuplicatePriceGroupService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/duplicate-pricing-group`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
export async function requestPriceGroupDeletionService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/delete-pricing-group`;
  const response = await httpHelpers.authenticatedDeleteRequest(url, reqBody);
  return response;
}
export async function requestPriceRuleSaveService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/save-price-modifier`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
