import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
} from '@material-ui/core';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import InvoiceReservationsTableRow from './InvoiceReservationsTableRow';

const InvoiceReservationsTable = ({
  invoice,
  reservations,
  selectable,
  showType,
  showTypeSymbol,
  showSumRow,
  currency,
}) => {
  const { totalExTax, tax, total } = invoice;

  const filteredReservations = reservations.filter((res) => res.total !== 0 && res.paymentStatus !== 'NOT_CHARGED');

  return (
    <div>
      <br />
      <br />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <ConditionallyVisible condition={selectable}>
                <TableCell />
              </ConditionallyVisible>
              <TableCell align="left" style={{ fontWeight: 800 }}>Item</TableCell>
              <ConditionallyVisible condition={showType}>
                <TableCell align="center" style={{ fontWeight: 800 }}>Type</TableCell>
              </ConditionallyVisible>
              <TableCell align="center" style={{ fontWeight: 800 }}>Unit price</TableCell>
              <TableCell align="center" style={{ fontWeight: 800 }}>Tax</TableCell>
              <TableCell align="center" style={{ fontWeight: 800 }}>Amount</TableCell>
            </TableRow>
            {filteredReservations.map(
              (res) => (
                <InvoiceReservationsTableRow
                  selectable={selectable}
                  showType={showType}
                  showTypeSymbol={showTypeSymbol}
                  row={res}
                />
              ),
            )}
            <ConditionallyVisible condition={showSumRow && reservations.length > 0}>
              <TableRow>
                <ConditionallyVisible condition={selectable}>
                  <TableCell />
                </ConditionallyVisible>
                <TableCell align="right" style={{ fontWeight: 800 }} colSpan={showType ? 2 : 1}>{`Total ${currency}`}</TableCell>
                <TableCell align="center" style={{ fontWeight: 800 }}>{parseFloat(totalExTax).toFixed(2)}</TableCell>
                <TableCell align="center" style={{ fontWeight: 800 }}>{parseFloat(tax).toFixed(2)}</TableCell>
                <TableCell align="center" style={{ fontWeight: 800 }}>{parseFloat(total).toFixed(2)}</TableCell>
              </TableRow>
            </ConditionallyVisible>

          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

InvoiceReservationsTable.propTypes = {
  invoice: PropTypes.shape().isRequired,
  currency: PropTypes.string,
  reservations: PropTypes.arrayOf(PropTypes.shape()),
  selectable: PropTypes.bool,
  showType: PropTypes.bool,
  showTypeSymbol: PropTypes.bool,
  showSumRow: PropTypes.bool,
};
InvoiceReservationsTable.defaultProps = {
  currency: 'GBP',
  reservations: [],
  selectable: false,
  showType: false,
  showTypeSymbol: false,
  showSumRow: false,
};

const mapStateToProps = (state) => ({
  currency: state.companies.companyInfo.currency.toUpperCase(),
});

export default connect(mapStateToProps, null)(InvoiceReservationsTable);
