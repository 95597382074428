const BookingStepperStyles = (theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'visible',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing.unit * 4,
    marginBottom: 20,
    background: theme.palette.background.default,
  },
  footer: {
    marginTop: 'auto',
  },
  priceTag: {
    textAlign: 'center',
    fontWeight: 700,
    textTransform: 'uppercase',
    marginTop: 25,
  },
});

export default BookingStepperStyles;
