import React from 'react';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as accessRestrictionsActions from '../../../../reducers/accessRestrictionsReducer';
import MenuHeading from '../../../../components/MenuHeading';

const AccessRestrictionsDialogTimePicker = (
  {
    startTime, endTime, updateAccessRestrictionCreation,
  },
) => (
  <>
    <MenuHeading title="Time Period:" />
    <div style={{ display: 'flex' }}>
      <div style={{ margin: '10px' }}>
        <TimePicker
          value={startTime ? moment(startTime) : startTime}
          showSecond={false}
          minuteStep={5}
          clearIcon={null}
          allowEmpty
          onChange={(value) => updateAccessRestrictionCreation({
            startTime: moment(value).startOf('minute')
              .format('YYYY-MM-DD HH:mm'),
          })}
        />
      </div>
      <div style={{ margin: '10px' }}>
        <TimePicker
          value={endTime ? moment(endTime) : endTime}
          showSecond={false}
          minuteStep={5}
          clearIcon={null}
          allowEmpty
          onChange={(value) => updateAccessRestrictionCreation(
            {
              endTime: moment(value).startOf('minute')
                .format('YYYY-MM-DD HH:mm'),
              changedEndTime: true,
            },
          )}
        />
      </div>
    </div>
  </>
);

AccessRestrictionsDialogTimePicker.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  updateAccessRestrictionCreation: PropTypes.func.isRequired,
};

AccessRestrictionsDialogTimePicker.defaultProps = {
  startTime: null,
  endTime: null,
};

const mapStateToProps = (state) => ({
  startTime: state.accessRestrictions.newAccessRestriction.startTime,
  endTime: state.accessRestrictions.newAccessRestriction.endTime,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccessRestrictionCreation: (keyWithValue) => dispatch(
    accessRestrictionsActions.updateAccessRestrictionCreation(keyWithValue),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessRestrictionsDialogTimePicker);
