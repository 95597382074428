import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';
import InviteAssociate from './InviteAssociate';

const toolbarStyleSheet = (theme) => ({
  root: {
    paddingRight: 2,
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

const TableToolbar = () => (
  <Toolbar>
    <div style={{ display: 'block', float: 'right' }}>
      <InviteAssociate />
    </div>
  </Toolbar>
);

export default withStyles(toolbarStyleSheet)(TableToolbar);
