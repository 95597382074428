import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import MenuHeading from '../../../components/MenuHeading';
import * as actions from '../../../reducers/invoicesReducer';

const InvoiceStepperDueDate = (props) => {
  const {
    updateCreateInvoiceStore,
    dueDate,
  } = props;

  const handleDueDate = (date) => {
    updateCreateInvoiceStore({
      dueDate: date.format(),
    });
  };

  return (
    <div>
      <MenuHeading title="When is payment due?" />
      <DayPickerSingleDateController
        onDateChange={(date) => handleDueDate(date)}
        date={dueDate !== null ? moment(dueDate) : null}
        noBorder
        isOutsideRange={(day) => day.isBefore(moment().startOf('day'))}
      />
    </div>
  );
};

InvoiceStepperDueDate.propTypes = {
  dueDate: PropTypes.string.isRequired,
  updateCreateInvoiceStore: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dueDate: state.invoices.createInvoice.dueDate,
});

const mapDispatchToProps = (dispatch) => ({
  updateCreateInvoiceStore: (keyWithValue) => dispatch(
    actions.updateCreateInvoiceStore(keyWithValue),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceStepperDueDate);
