import { useSelector } from 'react-redux';
import usePitchbookingUser from './usePitchbookingUser';

const usePendingChanges = () => {
  const { id: userId } = usePitchbookingUser();
  const { claimantInformation } = useSelector((state) => state.companies.companyInfo);
  return claimantInformation?.find((x) => x.userId === userId && x.status !== 'REJECTED');
};

export default usePendingChanges;
