import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const itemShape = PropTypes.shape(
  { id: PropTypes.string.isRequired, description: PropTypes.string.isRequired },
);

const CheckboxListById = (props) => {
  const { items, toggleItem, checkedItems } = props;

  return (
    <div>
      <List>
        {items.map((item) => (
          <ListItem
            key={item.id}
            dense
            button
            onClick={() => toggleItem(item)}
          >
            <Checkbox
              checked={checkedItems.some((checkedItem) => checkedItem.id === item.id)}
              tabIndex={-1}
              disableRipple
            />
            <ListItemText primary={item.description} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

CheckboxListById.propTypes = {
  items: PropTypes.arrayOf(itemShape).isRequired,
  checkedItems: PropTypes.arrayOf(itemShape).isRequired,
  toggleItem: PropTypes.func.isRequired,
};

export default CheckboxListById;
