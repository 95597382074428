import React from 'react';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LoadingSection from '../../../components/LoadingComponents/LoadingSection';

const InvoiceStepperConfirmation = () => {
  const isLoading = useSelector((state) => state.invoices.invoicesLoading);

  if (isLoading) {
    return (
      <LoadingSection
        height="auto"
        loadingText="Processing Invoice Creation. Please wait."
      />
    );
  }

  return (
    <div style={{
      display: 'flex', textAlign: 'center', flexDirection: 'column', marginTop: '30px',
    }}
    >
      <div style={{ fontWeight: 'bold' }}>
        <CheckCircleIcon style={{ fill: '#24dc89', fontSize: '4rem' }} />
      </div>
      <p style={{ fontSize: '2rem' }}>
        Invoice Created
      </p>
    </div>
  );
};

export default InvoiceStepperConfirmation;
