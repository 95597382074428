/* eslint-disable import/prefer-default-export */
import { takeEvery, call, put } from 'redux-saga/effects';
import {
  getAnalyticsBookingsService,
  getAnalyticsEventBookingsService,
  getAnalyticsFacilitiesService,
  getAnalyticsEventsService,
} from '../services/analyticsServices';
import * as actions from '../reducers/analyticsReducer';
import { handleSagaError } from './helperSaga';

function* getAnalyticsBookingsSaga(companyId, action) {
  const { query, refresh, status } = action;
  if (companyId) {
    try {
      const response = yield call(getAnalyticsBookingsService, companyId, query, status, refresh);
      if (!response.error) {
        yield put(actions.updateAnalyticsBookings(response.data));
      } else { throw response; }
    } catch (error) {
      yield put(actions.retrieveAnalyticsBookingsFailed());
      yield call(handleSagaError, error, 'getAnalyticsBookingsSaga');
    }
  }
}

function* getAnalyticsEventBookingsSaga(companyId, action) {
  const { query, refresh, status } = action;
  if (companyId) {
    try {
      const response = yield call(getAnalyticsEventBookingsService,
        companyId, query, status, refresh);
      if (!response.error) {
        yield put(actions.updateAnalyticsEventBookings(response.data));
      } else { throw response; }
    } catch (error) {
      yield put(actions.retrieveAnalyticsEventBookingsFailed());
      yield call(handleSagaError, error, 'getAnalyticsEventBookingsSaga');
    }
  }
}

function* retrieveAnalyticsFacilitiesSaga(companyId) {
  try {
    const response = yield call(getAnalyticsFacilitiesService, companyId);
    if (!response.error) {
      yield put(actions.updateAnalyticsFacilities(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'retrieveAnalyticsFacilitiesSaga');
  }
}

function* retrieveAnalyticsEventsSaga(companyId) {
  try {
    const response = yield call(getAnalyticsEventsService, companyId);
    if (!response.error) {
      yield put(actions.updateAnalyticsEvents(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'retrieveAnalyticsEventsSaga');
  }
}

/* Watchers */
export function* analyticsBookingsWatcher(companyId) {
  yield takeEvery(actions.RETRIEVE_ANALYTICS_BOOKINGS, getAnalyticsBookingsSaga, companyId);
}

export function* analyticsEventBookingsWatcher(companyId) {
  yield takeEvery(actions.RETRIEVE_EVENT_ANALYTICS_BOOKINGS,
    getAnalyticsEventBookingsSaga, companyId);
}

export function* analyticsFacilitiesWatcher(companyId) {
  yield takeEvery(actions.ANALYTICS_FACILITIES_RETRIEVED,
    retrieveAnalyticsFacilitiesSaga, companyId);
}

export function* analyticsEventsWatcher(companyId) {
  yield takeEvery(actions.ANALYTICS_EVENTS_RETRIEVED,
    retrieveAnalyticsEventsSaga, companyId);
}
