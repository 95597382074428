import { fork, all } from 'redux-saga/effects';
import {
  allocationsAmenityToggledWatcher,
  removeAllocationWatcher,
  retrieveBasketWatcher,
  updateAllocationWatcher,
  allocationsAddonChangeWatcher,
} from '@pitchbooking-dev/pb-shared/lib/sagas/basketSaga';
import {
  retrieveTimetableAvailabilityWatcher,
  getSelectedTimeSlotsPriceWatcher,
} from '@pitchbooking-dev/pb-shared/lib/sagas/timetableSaga';
import {
  requestEnquiryTypesWatcher,
} from '@pitchbooking-dev/pb-shared/lib/sagas/enquirySaga';
import { getSiteByCompanyIdWatcher } from '@pitchbooking-dev/pb-shared/lib/sagas/siteSaga';
import {
  updateCurrentUserAccountDataWatcher,
  retrieveUserAccountDataWatcher,
  updateUserPasswordWatcher,
  issuePasswordTokenWatcher,
  resetPasswordWithTokenWatcher,
  retrieveUserPrefillDataWatcher,
  confirmInviteWatcher,
  loginUserWatcher,
} from '@pitchbooking-dev/pb-shared/lib/sagas/currentUserSaga';
import { removeTokenAndReload } from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';
import {
  getFacilitiesWatcher,
  getAmenitiesWatcher,
  getFacilityWatcher,
  updateFacilityWatcher,
  facilityStatusToggledWatcher,
  updateSelectedSubFacilityWatcher,
  createFacilityAmenitiesWatcher,
  deleteFacilityAmenitiesWatcher,
  createFacilityWatcher,
  createAmenityWatcher,
} from './facilitiesSaga';
import { updateFormSagaWatcher, updateResourceFormSagaWatcher, getCompanyFormsSagaWatcher } from './formSaga';
import {
  getAddonsWatcher,
  getCompanyAddonsWatcher,
  createCompanyAddonsWatcher,
  createFacilityAddonsWatcher,
  deleteFacilityAddonsWatcher,
  requestAddonUpdateWatcher,
  deleteAddonWatcher,
  createCategoryWatcher,
  deleteCategoryWatcher,
  getAddonWatcher,
  createStockTransactionWatcher,
  createAddonsReservationWatcher,
  editProductWatcher,
  fetchPosOrdersWatcher,
} from './addonsSaga';

import {
  getSitesWatcher,
  updateSiteDetailsWatcher,
  requestSiteCreationWatcher,
} from './sitesSaga';
import {
  getUsersWatcher,
  getUsersBookingsWatcher,
  sendManagerEmailToUsersWatcher,
  inviteUserWatcher,
  getUsersBookingsBySearchWatcher,
  inviteAssociateWatcher,
  getAssociatesWatcher,
} from './usersSaga';
import {
  bookingsWatcher,
  bookingWatcher,
  updateBookingWatcher,
  editBookingAndCreationWatcher,
  requestBookingsAndSubscriptionsWatcher,
  swapBookingsWatcher,
  updateFacilityBookingWatcher,
} from './bookingsSaga';
import {
  sitesOrdersWatcher,
} from './ordersSaga';
import {
  requestAllEnquiriesWatcher,
  requestEnquiryWatcher,
  requestSendContentWatcher,
} from './enquiriesSaga';
import {
  companyUsersWatcher,
  removeUserFromCompanyWatcher,
  addUserToCompanyWatcher,
  setAccountManagerWatcher,
} from './companyUsersSaga';
import {
  analyticsBookingsWatcher,
  analyticsEventBookingsWatcher,
  analyticsFacilitiesWatcher,
  analyticsEventsWatcher,
} from './analyticsSaga';
import {
  createManagerReservationWatcher,
  cancelReservationsWatcher,
  createManagerAllocationsWatcher,
  recordMultiplePaymentWatcher,
  updateReservationWatcher,
  retrospectiveReservationsWatcher,
  fetchReservationSelectedRowWatcher,
  toggleManagerCalendarReservationsWatcher,
  convertReservationToBlockBookingWatcher,
} from './reservationsSaga';
import {
  getAccessRestrictionsWatcher,
  createAccessRestrictionsWatcher,
  saveAccessRestrictionWatcher,
  getMembershipsWatcher,
  updateMembershipWatcher,
  updateAccessRestrictionWatcher,
  getMembershipUsersWatcher,
  removeUserFromMembershipWatcher,
  inviteUserToMembershipWatcher,
  addUsersToMembershipWatcher,
} from './accessRestrictionsSaga';
import {
  getSubscriptionsWatcher,
  getSubscriptionsReservationsWatcher,
  getSubscriptionWatcher,
  createSubscriptionWatcher,
  deleteSubscriptionsWatcher,
  pauseSubscriptionsWatcher,
  addExclusionWatcher,
  deleteExclusionWatcher,
  editSubscriptionWatcher,
  addSubscriptionNoteWatcher,
} from './subscriptionsSaga';
import {
  getCompanyMembershipWatcher,
  getCompanyInvoicesWatcher,
  getInvoiceInformationWatcher,
  resendInvoiceWatcher,
  createCompanyMembershipWatcher,
  getCompanyInfoWatcher,
  changePaymentPreferenceWatcher,
  authoriseStripeWatcher,
  retrieveBillingWatcher,
  createStripeBillingWatcher,
  setBillingAsProcessingWatcher,
  runDbHealthCheckWatcher,
  createCompanyInvoicesWatcher,
  voidInvoiceWatcher,
  recordPaymentInvoicesWatcher,
  updateInvoiceWatcher,
  updateInvoiceInstructionsWatcher,
  updateCompanyDetailsWatcher,
  updateInvoiceCalculationWatcher,
  createCompanyWatcher,
  getCompanyUsersWatcher,
  updateBillingWatcher,
  saveCompanyProductsWatcher,
  createBillingWatcher,
  updateCompanyWatcher,
  companiesPayLaterWatcher,
} from './companiesSaga';
import {
  getCompanyBookingModifiersWatcher,
  createCompanyBookingModifiersWatcher,
  getAllCompanyBookingModifiersWatcher,
  saveBookingModifierWatcher,
  saveBookingModifierGroupWatcher,
} from './bookingModifiersSaga';
import {
  calendarWatcher,
  calendarSitesWatcher,
  calendarNoteWatcher,
  calendarNoteDeleteWatcher,
} from './calendarSaga';
import {
  updateCompanyUserNoteWatcher,
} from './notesSaga';
import {
  editPriceGroupWatcher,
  deletePriceGroupWatcher,
  getPricingPackagesWatcher,
  addRuleToPriceGroupWatcher,
  deletePriceModifierWatcher,
  duplicatePriceGroupWatcher,
  requestPriceGroupCreationWatcher,
  addFacilitiesToPriceGroupWatcher,
  savePriceRuleWatcher,
} from './pricingSaga';
import {
  getPayoutsWatcher,
  getPayoutWatcher,
  recalculatePayoutWatcher,
} from './payoutsSaga';
import {
  getMembershipWatcher,
} from './membershipsSaga';
import {
  dashboardBookingsWatcher,
} from './dashboardSaga';
import {
  getSchedulesWatcher,
  saveScheduleGroupOpeningTimesWatcher,
  createFullWeekSchedulesWatcher,
  swapFaclityScheduleWatcher,
} from './schedulesSaga';
import {
  getEventsWatcher,
  createEventsWatcher,
  getEventWatcher,
  updateEventWatcher,
  requestSaveEventAddonsWatcher,
  requestRemoveEventAddonsWatcher,
  assignEventAgentWatcher,
  removeEventAgentWatcher,
  updateEventTagsWatcher,
  deleteEventWatcher,
} from './eventsSaga';
import {
  createLeaguesWatcher,
  getLeaguesWatcher,
  getLeagueWatcher,
  confirmFixturesWatcher,
  recordLeagueResultWatcher,
  confirmFacilityLinkWatcher,
  inviteUserToLeagueWatcher,
  adjustTeamPointsWatcher,
  createNewFixtureWatcher,
  getLeagueFixtureWatcher,
  submitGoalScorersWatcher,
  assignAssociateWatcher,
  cancelFixtureWatcher,
  removeFixtureAgentWatcher,
  updateLeagueFixtureFacilityWatcher,
  deleteLeagueTeamWatcher,
  fetchLeagueFixtureSagaWatcher,
  updateLeagueTagsWatcher,
  deleteLeagueWatcher,
  updateLeagueScoreWatcher,
} from './leaguesSaga';
import {
  getXeroConsentUrlWatcher,
  generateNewXeroTokenWatcher,
  processPowerleagueBacsWatcher,
  triggerCronJobWatcher,
} from './utilsSaga';
import {
  getReferralsWatcher,
  createReferralsWatcher,
} from './referralsSaga';

export default function* root() {
  const token = localStorage.getItem('PBtoken');
  const selectedCompanyId = localStorage.getItem('selectedCompany') || null;
  let companyId = '';
  if (token !== null) {
    const tokenDataEncoded = token.split('.')[1];
    const currentUser = JSON.parse(window.atob(tokenDataEncoded));
    if (selectedCompanyId) {
      companyId = selectedCompanyId;
    } else {
      companyId = currentUser.managerAccess ? currentUser.managerAccess[0].companyId : '';
    }
  }
  if (token && companyId === '') { removeTokenAndReload('No company ID.'); }
  yield all([
    fork(updateLeagueScoreWatcher),
    fork(updateCompanyWatcher),
    fork(fetchLeagueFixtureSagaWatcher),
    fork(saveCompanyProductsWatcher),
    fork(deleteLeagueTeamWatcher),
    fork(requestEnquiryTypesWatcher),
    fork(companiesPayLaterWatcher, companyId),
    fork(addSubscriptionNoteWatcher, companyId),
    fork(createStockTransactionWatcher, companyId),
    fork(createAddonsReservationWatcher, companyId),
    fork(editProductWatcher, companyId),
    fork(createCategoryWatcher, companyId),
    fork(deleteCategoryWatcher, companyId),
    fork(getAddonWatcher, companyId),
    fork(createBillingWatcher, companyId),
    fork(updateFormSagaWatcher, companyId),
    fork(setAccountManagerWatcher, companyId),
    fork(fetchPosOrdersWatcher, companyId),
    fork(updateResourceFormSagaWatcher, companyId),
    fork(getCompanyFormsSagaWatcher, companyId),
    fork(getSiteByCompanyIdWatcher),
    fork(retrieveTimetableAvailabilityWatcher),
    fork(retrospectiveReservationsWatcher),
    fork(updateInvoiceCalculationWatcher),
    fork(getCompanyUsersWatcher),
    fork(updateBillingWatcher, companyId),
    fork(calendarNoteWatcher, companyId),
    fork(calendarNoteDeleteWatcher, companyId),
    fork(getCompanyInfoWatcher, companyId),
    fork(deleteEventWatcher, companyId),
    fork(updateLeagueTagsWatcher, companyId),
    fork(updateEventTagsWatcher, companyId),
    fork(assignEventAgentWatcher, companyId),
    fork(removeEventAgentWatcher, companyId),
    fork(removeFixtureAgentWatcher, companyId),
    fork(cancelFixtureWatcher, companyId),
    fork(inviteAssociateWatcher, companyId),
    fork(getAssociatesWatcher, companyId),
    fork(getAddonsWatcher, companyId),
    fork(deleteAddonWatcher, companyId),
    fork(inviteUserToLeagueWatcher, companyId),
    fork(deleteLeagueWatcher, companyId),
    fork(getLeagueFixtureWatcher, companyId),
    fork(submitGoalScorersWatcher, companyId),
    fork(assignAssociateWatcher, companyId),
    fork(createNewFixtureWatcher, companyId),
    fork(adjustTeamPointsWatcher, companyId),
    fork(getCompanyAddonsWatcher, companyId),
    fork(createCompanyAddonsWatcher, companyId),
    fork(getCompanyBookingModifiersWatcher, companyId),
    fork(getAllCompanyBookingModifiersWatcher, companyId),
    fork(createCompanyBookingModifiersWatcher, companyId),
    fork(saveBookingModifierWatcher, companyId),
    fork(saveBookingModifierGroupWatcher, companyId),
    fork(getEventsWatcher, companyId),
    fork(createEventsWatcher, companyId),
    fork(getEventWatcher, companyId),
    fork(updateEventWatcher, companyId),
    fork(requestSaveEventAddonsWatcher, companyId),
    fork(requestRemoveEventAddonsWatcher, companyId),
    fork(createLeaguesWatcher, companyId),
    fork(getLeaguesWatcher, companyId),
    fork(calendarSitesWatcher, companyId),
    fork(recordMultiplePaymentWatcher, companyId),
    fork(updateReservationWatcher, companyId),
    fork(calendarWatcher, companyId),
    fork(changePaymentPreferenceWatcher, companyId),
    fork(getSelectedTimeSlotsPriceWatcher, companyId),
    fork(bookingsWatcher, companyId),
    fork(bookingWatcher, companyId),
    fork(getAccessRestrictionsWatcher, companyId),
    fork(getFacilitiesWatcher, companyId),
    fork(getAmenitiesWatcher, companyId),
    fork(getFacilityWatcher, companyId),
    fork(updateFacilityWatcher, companyId),
    fork(updateSelectedSubFacilityWatcher, companyId),
    fork(facilityStatusToggledWatcher, companyId),
    fork(getSubscriptionsWatcher, companyId),
    fork(editSubscriptionWatcher, companyId),
    fork(getSubscriptionsReservationsWatcher, companyId),
    fork(getSubscriptionWatcher, companyId),
    fork(getUsersWatcher, companyId),
    fork(getUsersBookingsWatcher, companyId),
    fork(getUsersBookingsBySearchWatcher, companyId),
    fork(createFacilityAmenitiesWatcher, companyId),
    fork(deleteFacilityAmenitiesWatcher, companyId),
    fork(getCompanyMembershipWatcher, companyId),
    fork(getCompanyInvoicesWatcher, companyId),
    fork(getInvoiceInformationWatcher, companyId),
    fork(resendInvoiceWatcher, companyId),
    fork(createCompanyInvoicesWatcher, companyId),
    fork(voidInvoiceWatcher, companyId),
    fork(recordPaymentInvoicesWatcher, companyId),
    fork(updateInvoiceWatcher, companyId),
    fork(updateInvoiceInstructionsWatcher, companyId),
    fork(getMembershipsWatcher, companyId),
    fork(getMembershipUsersWatcher),
    fork(addExclusionWatcher, companyId),
    fork(deleteExclusionWatcher, companyId),
    fork(deleteSubscriptionsWatcher),
    fork(pauseSubscriptionsWatcher, companyId),
    fork(inviteUserWatcher, companyId),
    fork(requestAllEnquiriesWatcher, companyId),
    fork(requestEnquiryWatcher, companyId),
    fork(requestSendContentWatcher, companyId),
    fork(updateMembershipWatcher),
    fork(loginUserWatcher),
    fork(addUsersToMembershipWatcher),
    fork(inviteUserToMembershipWatcher),
    fork(removeUserFromMembershipWatcher),
    fork(createAccessRestrictionsWatcher, companyId),
    fork(saveAccessRestrictionWatcher, companyId),
    fork(createCompanyMembershipWatcher, companyId),
    fork(createManagerReservationWatcher, companyId),
    fork(createSubscriptionWatcher, companyId),
    fork(cancelReservationsWatcher, companyId),
    fork(updateBookingWatcher, companyId),
    fork(updateUserPasswordWatcher),
    fork(updateCurrentUserAccountDataWatcher),
    fork(retrieveUserAccountDataWatcher),
    fork(issuePasswordTokenWatcher),
    fork(resetPasswordWithTokenWatcher),
    fork(retrieveUserPrefillDataWatcher),
    fork(confirmInviteWatcher),
    fork(createFacilityWatcher, companyId),
    fork(createAmenityWatcher, companyId),
    fork(authoriseStripeWatcher, companyId),
    fork(updateAccessRestrictionWatcher, companyId),
    fork(sitesOrdersWatcher, companyId),
    fork(companyUsersWatcher, companyId),
    fork(removeUserFromCompanyWatcher, companyId),
    fork(addUserToCompanyWatcher, companyId),
    fork(createManagerAllocationsWatcher, companyId),
    fork(removeAllocationWatcher, companyId),
    fork(allocationsAmenityToggledWatcher),
    fork(allocationsAddonChangeWatcher),
    fork(retrieveBasketWatcher, companyId),
    fork(analyticsBookingsWatcher, companyId),
    fork(analyticsEventBookingsWatcher, companyId),
    fork(analyticsFacilitiesWatcher, companyId),
    fork(analyticsEventsWatcher, companyId),
    fork(retrieveBillingWatcher, companyId),
    fork(createStripeBillingWatcher, companyId),
    fork(setBillingAsProcessingWatcher, companyId),
    fork(runDbHealthCheckWatcher, companyId),
    fork(sendManagerEmailToUsersWatcher, companyId),
    fork(getPricingPackagesWatcher, companyId),
    fork(addFacilitiesToPriceGroupWatcher, companyId),
    fork(addRuleToPriceGroupWatcher, companyId),
    fork(deletePriceModifierWatcher, companyId),
    fork(editPriceGroupWatcher, companyId),
    fork(savePriceRuleWatcher, companyId),
    fork(requestPriceGroupCreationWatcher, companyId),
    fork(updateAllocationWatcher, companyId),
    fork(getPayoutsWatcher, companyId),
    fork(getPayoutWatcher, companyId),
    fork(recalculatePayoutWatcher, companyId),
    fork(updateCompanyDetailsWatcher, companyId),
    fork(dashboardBookingsWatcher, companyId),
    fork(editBookingAndCreationWatcher, companyId),
    fork(requestBookingsAndSubscriptionsWatcher, companyId),
    fork(getSchedulesWatcher, companyId),
    fork(createFullWeekSchedulesWatcher, companyId),
    fork(swapFaclityScheduleWatcher, companyId),
    fork(saveScheduleGroupOpeningTimesWatcher, companyId),
    fork(getSitesWatcher, companyId),
    fork(duplicatePriceGroupWatcher, companyId),
    fork(deleteFacilityAddonsWatcher, companyId),
    fork(requestAddonUpdateWatcher, companyId),
    fork(createFacilityAddonsWatcher, companyId),
    fork(deletePriceGroupWatcher, companyId),
    fork(updateSiteDetailsWatcher, companyId),
    fork(getLeagueWatcher, companyId),
    fork(requestSiteCreationWatcher, companyId),
    fork(getMembershipWatcher, companyId),
    fork(createCompanyWatcher, companyId),
    fork(updateCompanyUserNoteWatcher, companyId),
    fork(confirmFixturesWatcher, companyId),
    fork(recordLeagueResultWatcher, companyId),
    fork(confirmFacilityLinkWatcher, companyId),
    fork(getXeroConsentUrlWatcher, companyId),
    fork(generateNewXeroTokenWatcher, companyId),
    fork(processPowerleagueBacsWatcher, companyId),
    fork(triggerCronJobWatcher, companyId),
    fork(updateLeagueFixtureFacilityWatcher, companyId),
    fork(swapBookingsWatcher),
    fork(getReferralsWatcher, companyId),
    fork(createReferralsWatcher, companyId),
    fork(updateFacilityBookingWatcher, companyId),
    fork(fetchReservationSelectedRowWatcher, companyId),
    fork(toggleManagerCalendarReservationsWatcher),
    fork(convertReservationToBlockBookingWatcher),
  ]);
}
