/* eslint-disable import/prefer-default-export */
import { takeEvery, call, put } from 'redux-saga/effects';
import {
  getDashboardBookingsService,
} from '../services/dashboardServices';
import * as dashboardActions from '../reducers/dashboardReducer';
import { handleSagaError } from './helperSaga';

function* getDashboardBookings(companyId, action) {
  const { query } = action;
  if (companyId) {
    try {
      const response = yield call(getDashboardBookingsService, companyId, query);
      if (!response.error) {
        yield put(dashboardActions.dashboardBookingsRequestSucceeded(response.data));
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'getDashboardBookings');
    }
  }
}

/* Watchers */
export function* dashboardBookingsWatcher(companyId) {
  yield takeEvery(
    dashboardActions.DASHBOARD_BOOKINGS_REQUESTED,
    getDashboardBookings,
    companyId,
  );
}
