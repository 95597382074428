import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import ActionBar from '../../components/ActionBar';
import { LibraryItemDialog, LibraryCard } from './Components';
import { getLibraryNotes } from '../../services/notesServices';
import { useCompany } from '../../hooks';
import { OverlayLoader } from '../../components/OverlayLoader';

export const Library = () => {
  const company = useCompany();
  const { id: companyId, colorScheme } = company;
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState([]);

  const fetchNotes = async () => {
    try {
      setIsLoading(true);
      const res = await getLibraryNotes(companyId);
      setNotes(res.data);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (companyId) {
      fetchNotes();
    }
  }, [companyId]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: 'fit-content',
          padding: '1rem',
          borderRadius: '10px',
          border: '1px solid #e0e0e0',
          margin: '1.25rem',
          background: '#fff',
          gap: '1rem',
        }}
      >
        <Typography variant="h6" style={{ textDecoration: 'underLine' }}>Library</Typography>
        <Typography variant="body1">
          {`Our new library feature brings together all your essential links & resources,
          saved directly in your Manager Dashboard. Whether it’s customer insurance docs,
          facility maintenance logs, or 3rd party software to handle other aspects of your
          operations — all are accessible with just a click`}
        </Typography>
      </div>

      <ActionBar>
        <LibraryItemDialog isEditing={false} onSuccessCallback={fetchNotes} />
      </ActionBar>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: '1rem',
          padding: '1rem',
        }}
      >
        {isLoading && <OverlayLoader />}
        {notes && notes.length > 0 && notes.map((note) => (
          <LibraryCard
            key={note.id}
            noteData={note}
            colorScheme={colorScheme || null}
            companyId={companyId}
            fetchNotes={fetchNotes}
          />
        ))}
      </div>
    </>
  );
};
