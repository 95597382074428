/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import {
  ChevronLeft,
  ChevronRight,
  Search,
  Clear,
  UnfoldMore,
  Check,
  Close,
  SaveAlt,
} from '@material-ui/icons';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import MaterialTable from 'material-table';
import { Chip, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import JSON2CSV from 'json2csv';
import fileDownload from 'js-file-download';
import { useSelector } from 'react-redux';
import DownloadPdf from '../../../../components/DownloadPdf';

export const PaymentHistoryTable = ({
  orders,
  currency,
  userMembership,
  ...props
}) => {
  const company = useSelector((state) => state.companies.companyInfo);
  const stripeId = company?.stripeId;

  const handleExport = (columns, data, exportType) => {
    if (!exportType) throw new Error('Export type is required');

    const mappedData = data.map((row) => ({
      PaymentId: row.paymentId,
      PaymentDate: moment(row.paidAt).format('MMMM Do YYYY'),
      Amount: `${currency}${Math.floor(row.total / 100).toFixed(2)}`,
      PaymentStatus: row.status === 'CREATED' ? 'Success' : 'Failed',
    }));

    const fileName = `${userMembership?.membership?.name} - Payment History - ${userMembership?.user?.firstName} ${userMembership?.user?.lastName}`;

    if (exportType === 'pdf') {
      try {
        const rowData = mappedData.map((row) => Object.values(row));
        DownloadPdf(
          Object.keys(mappedData[0]),
          rowData,
          `${fileName}.pdf`,
        );
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const csvData = JSON2CSV({ data: mappedData, field: columns, excelStrings: false });
        fileDownload(csvData, `${fileName}.csv`);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const statusConverter = (status) => {
    switch (status) {
      case 'CREATED':
        return {
          backgroundColor: 'rgb(130, 224, 170, 0.4)',
          borderColor: 'rgb(39, 174, 96, 0.6)',
          text: 'Success',
          icon: <Check fontSize="small" />,
        };

      case 'FAILED':
        return {
          backgroundColor: 'rgb(222, 102, 96, 0.4)',
          borderColor: 'rgb(217, 76, 69, 0.6)',
          text: 'Failed',
          icon: <Close fontSize="small" />,
        };

      default:
        return {
          backgroundColor: 'rgb(206, 206, 206, 0.6)',
          borderColor: '#C2C2C2',
          text: status,
          icon: <PriorityHighIcon fontSize="small" />,
        };
    }
  };

  return (
    <div {...props}>
      <MaterialTable
        columns={[
          {
            title: 'Payment ID',
            field: 'id',
            sorting: false,
            render: (rowData) => (
              <a
                href={`https://dashboard.stripe.com/${stripeId}/payments/${rowData.paymentId}`}
                target="_blank"
                rel="noreferrer"
              >
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                  {rowData.paymentId}
                </Typography>
              </a>
            ),
          },
          {
            title: 'Payment Date',
            field: 'date',
            align: 'center',
            render: (rowData) => moment(rowData.paidAt).format('MMMM Do YYYY HH:mmA'),
          },
          {
            title: 'Amount',
            field: 'amount',
            align: 'center',
            render: (rowData) => `${currency}${Math.floor(rowData.total / 100).toFixed(2)}`,
          },
          {
            title: 'Payment Status',
            field: 'status',
            align: 'center',
            render: (rowData) => (
              <Chip
                avatar={(
                  <Avatar style={{
                    backgroundColor: statusConverter(rowData.status).borderColor,
                    display: 'flex',
                    justifyContent: 'space-around',
                    color: 'white',
                  }}
                  >
                    {statusConverter(rowData.status).icon}
                  </Avatar>
                )}
                label={statusConverter(rowData.status).text}
                style={{
                  backgroundColor: statusConverter(rowData.status).backgroundColor,
                  color: 'black',
                  fontWeight: 'bold',
                  border: `2px solid ${statusConverter(rowData.status).borderColor}`,
                }}
              />
            ),
          },
        ]}
        data={orders}
        icons={{
          NextPage: () => <ChevronRight />,
          PreviousPage: () => <ChevronLeft />,
          Search: () => <Search />,
          ResetSearch: () => <Clear />,
          SortArrow: () => <UnfoldMore style={{ fill: '#4581E2' }} />,
          Export: () => <SaveAlt />,

        }}
        title="Payment History"
        options={{
          showTitle: true,
          sorting: true,
          selection: false,
          emptyRowsWhenPaging: false,
          showFirstLastPageButtons: false,
          exportButton: true,
          exportCsv: (columns, data) => handleExport(columns, data, 'csv'),
          exportPdf: (columns, data) => handleExport(columns, data, 'pdf'),
          search: false,
          draggable: false,
          pageSize: 5,
          pageSizeOptions: [],
          tableLayout: 'fixed',
          headerStyle: {
            backgroundColor: '#efefef',
            fontWeight: 'bold',
          },
          actionsColumnIndex: -1,
        }}
      />
    </div>
  );
};
