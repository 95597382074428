/* eslint-disable react/prop-types */
import React from 'react';
import SportsBasketballTwoToneIcon from '@material-ui/icons/SportsBasketballTwoTone';
import DomainTwoToneIcon from '@material-ui/icons/DomainTwoTone';
import LockOpenTwoToneIcon from '@material-ui/icons/LockOpenTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import { Divider, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export const FacilityInfo = ({ facilityInfo }) => (
  <>
    <Typography style={{ textDecoration: 'underline' }}>
      {`Total Facilities (${facilityInfo?.facilityCount + facilityInfo?.subFacilityCount})`}
    </Typography>

    {!facilityInfo ? (
      <Alert severity="warning">
        No Facility Information Available.
      </Alert>
    ) : (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <SportsBasketballTwoToneIcon
            color="secondary"
            style={{ fontSize: '2.5rem' }}
          />
          <Typography variant="h5">
            {facilityInfo.facilityCount}
            <Typography style={{ fontSize: '1rem' }}>Facilities</Typography>
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <DomainTwoToneIcon
            color="action"
            style={{ fontSize: '2.5rem' }}
          />
          <Typography variant="h5">
            {facilityInfo.subFacilityCount}
            <Typography style={{ fontSize: '1rem' }}>Sub-Facilities</Typography>
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <LockOpenTwoToneIcon color="primary" style={{ fontSize: '2.5rem' }} />
          <Typography variant="h5">
            {facilityInfo.externalFacilities}
            <Typography style={{ fontSize: '1rem' }}>External</Typography>
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <LockTwoToneIcon color="error" style={{ fontSize: '2.5rem' }} />
          <Typography variant="h5">
            {facilityInfo.internalFacilities}
            <Typography style={{ fontSize: '1rem' }}>Internal</Typography>
          </Typography>
        </div>
      </div>
    )}
    <Divider />
  </>
);

export default FacilityInfo;
