import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as membershipActions from '../../reducers/membershipsReducer';
import Back from '../../components/Back';
import ActionBar from '../../components/ActionBar';
import MediaConfiguration from '../../components/MediaConfiguration';

const MembershipMedia = ({ match }) => {
  const { membershipId } = match.params;
  const dispatch = useDispatch();

  const fetchMembership = () => dispatch(membershipActions.requestMembership(membershipId));

  const {
    membership,
    isLoading,
  } = useSelector((state) => ({
    membership: state.memberships.membership,
    isLoading: state.memberships.loading,
  }));

  useEffect(() => {
    if (!membership.id) {
      fetchMembership();
    }
  }, []);

  if (isLoading) return null;

  return (
    <div style={{ backgroundColor: '#fafbff' }}>
      <ActionBar>
        <Back />
      </ActionBar>

      <div style={{
        height: '95.5vh',
        marginLeft: '20px',
        marginRight: '40px',
      }}
      >
        <MediaConfiguration
          type="MEMBERSHIP"
          resourceId={membershipId}
          initialImage={membership.imageUrl}
          reloadResource={fetchMembership}
        />
      </div>
    </div>
  );
};

MembershipMedia.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default MembershipMedia;
