import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function createCompanyBookingModifiersService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/booking-modifiers`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function getCompanyBookingModifiersService(companyId) {
  const url = `/api/companies/${companyId}/booking-modifiers`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function getAllCompanyBookingModifiersService(companyId) {
  const url = `/api/companies/${companyId}/all-booking-modifiers`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function requestBookingModifiersSaveService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/save-booking-modifier`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
export async function requestBookingModifiersGroupSaveService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/save-booking-modifiers-group`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
