/* eslint-disable max-len */
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as facilitiesActions from '../../reducers/facilitiesReducer';
import * as calendarActions from '../../reducers/calendarReducer';
import OrderTable from './OrderTable';
import '../../styles/payments.css';
import {
  PaymentsActionBar,
  PermanentPaymentBreakdown,
  ProductBreakdownCards,
  productBreakdownConstants,
  SubNonSubBreakdownCards,
} from './Components';

const Payments = () => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.orders);
  const { stripeId, currencySym } = useSelector((state) => state.companies.companyInfo);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [productCardData, setProductCardData] = useState([]);
  const [subNonSubData, setSubNonSubData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    dispatch(calendarActions.getCompanySites());
    dispatch(facilitiesActions.requestFacilitiesRetrieval());
  }, [dispatch]);

  // Process orders into product card data
  useEffect(() => {
    if (orders && orders.length > 0) {
      setFilteredOrders(orders); // Showing all orders in table to start
      const newOrderData = {
        facilityOrders: orders.filter((order) => order?.reservations?.some((reservation) => reservation?.allocations?.some((allocation) => allocation.type === 'FACILITY')) || order?.subscriptions?.some((subscription) => subscription?.productType === 'FACILITY')),
        eventOrders: orders.filter((order) => order?.reservations?.some((reservation) => reservation?.allocations?.some((allocation) => allocation.type === 'EVENT'))),
        membershipOrders: orders.filter((order) => order?.subscriptions?.some((sub) => sub.membershipId)),
        posOrders: orders.filter((order) => order?.reservations?.some((reservation) => reservation?.type === 'POS')),
        invoiceOrders: orders.filter((order) => order?.reservations?.some((reservation) => reservation?.type === 'INVOICE_ITEM')),
      };

      const initialCardData = Object.entries(newOrderData).map(([key, filteredOrders]) => {
        const total = filteredOrders
          .filter((o) => o.status === 'CREATED')
          .reduce((acc, order) => {
            let itemTotal = 0;
            if (key === 'facilityOrders') {
              const reservationTotal = 100 * order.reservations
                ?.filter((reservation) => reservation?.allocations?.some((allocation) => allocation.type === 'FACILITY'))
                .reduce((resAcc, reservation) => resAcc + ((order.partial ? order.total / 100 : reservation.total) || 0), 0);
              const subscriptionTotal = order.subscriptions
                ?.filter((subscription) => subscription.productType === 'FACILITY')
                .reduce((subAcc, subscription) => {
                  const shmsoCount = subscription.shmso?.length || 1;
                  return subAcc + ((order.partial ? order.total / 100 : subscription.amount) || 0) * shmsoCount;
                }, 0);
              itemTotal = reservationTotal + subscriptionTotal;
            } else if (key === 'eventOrders') {
              itemTotal = 100 * order.reservations
                ?.filter((reservation) => reservation?.allocations?.some((allocation) => allocation.type === 'EVENT'))
                .reduce((resAcc, reservation) => resAcc + (reservation.total || 0), 0);
            } else if (key === 'membershipOrders') {
              itemTotal = order.subscriptions
                ?.filter((subscription) => subscription.membershipId)
                .reduce((subAcc, subscription) => subAcc + (subscription.amount || 0), 0);
            } else if (key === 'posOrders') {
              itemTotal = 100 * order.reservations
                ?.filter((reservation) => reservation?.type === 'POS')
                .reduce((posAcc, reservation) => posAcc + (reservation.total || 0), 0);
            } else if (key === 'invoiceOrders') {
              itemTotal = 100 * order.reservations
                ?.filter((reservation) => reservation?.type === 'INVOICE_ITEM')
                .reduce((posAcc, reservation) => posAcc + (reservation.total || 0), 0);
            }
            return acc + itemTotal;
          }, 0);

        const refundTotal = filteredOrders
          .filter((o) => o.status === 'REFUNDED')
          .reduce((acc, order) => {
            let refundItemTotal = 0;
            if (key === 'facilityOrders') {
              const reservationRefundTotal = 100 * order.reservations
                ?.filter((reservation) => reservation?.allocations?.some((allocation) => allocation.type === 'FACILITY'))
                .reduce((resAcc, reservation) => {
                  const refundAmount = reservation.reservation_order?.partialRefundTotal !== null
                    ? reservation.reservation_order.partialRefundTotal
                    : reservation.total || 0;
                  return resAcc + refundAmount;
                }, 0);
              const subscriptionRefundTotal = order.subscriptions
                ?.filter((subscription) => subscription.productType === 'FACILITY')
                .reduce((subAcc, subscription) => {
                  const shmsoCount = subscription.shmso?.length || 1;
                  return subAcc + (subscription.amount || 0) * shmsoCount;
                }, 0);
              refundItemTotal = reservationRefundTotal + subscriptionRefundTotal;
            } else if (key === 'eventOrders') {
              refundItemTotal = 100 * order.reservations
                ?.filter((reservation) => reservation?.allocations?.some((allocation) => allocation.type === 'EVENT'))
                .reduce((resAcc, reservation) => {
                  const refundAmount = reservation.reservation_order?.partialRefundTotal !== null
                    ? reservation.reservation_order.partialRefundTotal
                    : reservation.total || 0;
                  return resAcc + refundAmount;
                }, 0);
            } else if (key === 'membershipOrders') {
              refundItemTotal = order.subscriptions
                ?.filter((subscription) => subscription.membershipId)
                .reduce((subAcc, subscription) => subAcc + (subscription.amount || 0), 0);
            } else if (key === 'posOrders') {
              refundItemTotal = 100 * order.reservations
                ?.filter((reservation) => reservation?.type === 'POS')
                .reduce((posAcc, reservation) => {
                  const refundAmount = reservation.reservation_order?.partialRefundTotal !== null
                    ? reservation.reservation_order.partialRefundTotal
                    : reservation.total || 0;
                  return posAcc + refundAmount;
                }, 0);
            } else if (key === 'invoiceOrders') {
              refundItemTotal = 100 * order.reservations
                ?.filter((reservation) => reservation?.type === 'INVOICE_ITEM')
                .reduce((posAcc, reservation) => {
                  const refundAmount = reservation.reservation_order?.partialRefundTotal !== null
                    ? reservation.reservation_order.partialRefundTotal
                    : reservation.total || 0;
                  return posAcc + refundAmount;
                }, 0);
            }
            return acc + refundItemTotal;
          }, 0);

        return {
          title: key.charAt(0).toUpperCase() + key.slice(1).replace('Orders', ''),
          count: filteredOrders.length,
          type: key.replace('Orders', '').toLowerCase(),
          total,
          refundTotal,
          orders: filteredOrders,
        };
      });

      const productCardData = initialCardData.filter((card) => card.count > 0 && card.type !== 'subscription');
      setProductCardData(productCardData);

      const allFilteredData = initialCardData.filter((card) => card.count > 0);
      setSubNonSubData(allFilteredData);

      const options = productCardData.map((card) => ({
        label: card.title,
        value: card.type,
      }));
      setSelectedOptions(options);
    }
  }, [orders]);

  const handleProductBreakdownSelectionChange = useCallback(
    (selected) => {
      const selectedTitles = selected.map((option) => option.label);
      const selectedCardData = productCardData.filter((card) => selectedTitles.includes(card.title));
      const updatedOrders = selectedCardData.flatMap((card) => card.orders);

      setFilteredOrders(updatedOrders);
      setSelectedOptions(selected);
    },
    [productCardData],
  );

  // Only show select options that have data
  const availableOptions = productCardData.map((card) => ({
    label: card.title,
    value: card.type,
  }));

  // Custom styles for react-select (to colourise selected options)
  const selectStyles = {
    multiValue: (provided, state) => {
      const color = productBreakdownConstants[state.data.value]?.color || provided.backgroundColor;
      return {
        ...provided,
        backgroundColor: color,
      };
    },
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
      fontWeight: '600',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: 'white',
      ':hover': {
        backgroundColor: state.data.color,
        color: 'black',
        cursor: 'pointer',
      },
    }),
  };

  return (
    <>
      <PaymentsActionBar />
      {orders.length === 0 ? (
        <div className="site-selection">There is no data available for this date and site.</div>
      ) : (
        <>
          <PermanentPaymentBreakdown />

          <Accordion
            elevation={0}
            style={{
              backgroundColor: '#F5F5F5',
              border: '1px solid #ddd',
              margin: '0 2rem',
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography style={{ fontWeight: '600' }}>Product Revenue Breakdown</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Select
                options={availableOptions}
                onChange={handleProductBreakdownSelectionChange}
                isMulti
                value={selectedOptions}
                placeholder="Select filters"
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                styles={selectStyles}
              />

              <ProductBreakdownCards
                productCardData={productCardData}
                selectedOptions={selectedOptions}
                currencySym={currencySym}
              />
            </AccordionDetails>
          </Accordion>

          <SubNonSubBreakdownCards
            filteredOrderData={subNonSubData}
            selectedOptions={selectedOptions}
            currencySym={currencySym}
          />

          <OrderTable
            orders={filteredOrders}
            stripeId={stripeId}
            currencySym={currencySym}
          />
        </>
      )}
    </>
  );
};

export default Payments;
