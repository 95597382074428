import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import basketReducer from '@pitchbooking-dev/pb-shared/lib/reducers/basketReducer';
import currentUserReducer from '@pitchbooking-dev/pb-shared/lib/reducers/currentUserReducer';
import timetableReducer from '@pitchbooking-dev/pb-shared/lib/reducers/timetableReducer';
import bookingReducer from '@pitchbooking-dev/pb-shared/lib/reducers/bookingReducer';
import sessionReducer from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import siteReducer from '@pitchbooking-dev/pb-shared/lib/reducers/siteReducer';
import enquiryReducer from '@pitchbooking-dev/pb-shared/lib/reducers/enquiryReducer';
import bookingsReducer from './bookingsReducer';
import reservationsReducer from './reservationsReducer';
import facilitiesReducer from './facilitiesReducer';
import usersReducer from './usersReducer';
import companyUsersReducer from './companyUsersReducer';
import accessRestrictionsReducer from './accessRestrictionsReducer';
import addonsReducer from './addonsReducer';
import companiesReducer from './companiesReducer';
import subscriptionsReducer from './subscriptionsReducer';
import calendarReducer from './calendarReducer';
import ordersReducer from './ordersReducer';
import invoicesReducer from './invoicesReducer';
import analyticsReducer from './analyticsReducer';
import pricingReducer from './pricingReducer';
import payoutsReducer from './payoutsReducer';
import notesReducer from './notesReducer';
import dashboardReducer from './dashboardReducer';
import schedulesReducer from './schedulesReducer';
import eventsReducer from './eventsReducer';
import enquiriesReducer from './enquiriesReducer';
import sitesReducer from './sitesReducer';
import leaguesReducer from './leaguesReducer';
import bookingModifiersReducer from './bookingModifiersReducer';
import membershipsReducer from './membershipsReducer';
import utilsReducer from './utilsReducer';
import referralsReducer from './referralsReducer';
import tagsReducer from './tagsReducer';
import formsReducer from './formsReducer';

const createRootReducer = (history) => combineReducers({
  accessRestrictions: accessRestrictionsReducer,
  addons: addonsReducer,
  analytics: analyticsReducer,
  bookings: bookingsReducer,
  booking: bookingReducer,
  bookingModifiers: bookingModifiersReducer,
  basket: basketReducer,
  calendar: calendarReducer,
  companies: companiesReducer,
  companyUsers: companyUsersReducer,
  currentUser: currentUserReducer,
  dashboard: dashboardReducer,
  enquiries: enquiriesReducer,
  events: eventsReducer,
  forms: formsReducer,
  leagues: leaguesReducer,
  facilities: facilitiesReducer,
  invoices: invoicesReducer,
  memberships: membershipsReducer,
  pricing: pricingReducer,
  payouts: payoutsReducer,
  notes: notesReducer,
  orders: ordersReducer,
  referrals: referralsReducer,
  reservation: reservationsReducer,
  router: connectRouter(history),
  timetable: timetableReducer,
  subscriptions: subscriptionsReducer,
  schedules: schedulesReducer,
  session: sessionReducer,
  sites: sitesReducer,
  tags: tagsReducer,
  users: usersReducer,
  utils: utilsReducer,
  site: siteReducer,
  enquiry: enquiryReducer,
});

export default createRootReducer;
