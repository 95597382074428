export const SITES_FACILITIES_RETRIEVAL_REQUESTED = 'SITES_FACILITIES_RETRIEVAL_REQUESTED';
export const SITES_FACILITIES_RETRIEVAL_SUCCEEDED = 'SITES_FACILITIES_RETRIEVAL_SUCCEEDED';

export const SITE_DETAILS_UPDATE_REQUESTED = 'SITE_DETAILS_UPDATE_REQUESTED';
export const SITE_DETAILS_UPDATE_SUCCEEDED = 'SITE_DETAILS_UPDATE_SUCCEEDED';

export const UPDATE_SITE_STORE = 'UPDATE_SITE_STORE';
export const RESET_SITE_STORE = 'RESET_SITE_STORE';

export const UPDATED_NEW_SITE_STORE = 'UPDATED_NEW_SITE_STORE';
export const RESET_NEW_SITE_STORE = 'RESET_NEW_SITE_STORE';

export const SITE_CREATION_REQUESTED = 'SITE_CREATION_REQUESTED';
export const SITE_CREATION_SUCCEEDED = 'SITE_CREATION_SUCCEEDED';
export const SITE_CREATION_FAILED = 'SITE_CREATION_FAILED';

export const initialState = {
  sitesFacilities: [],
  companySites: [],
  siteStore: {},
  newSite: {
    name: '',
    addressLine1: '',
    addressLine2: '',
    postcode: '',
    description: '',
  },
};

export const requestSitesWithFacilitiesRetrieval = () => ({
  type: SITES_FACILITIES_RETRIEVAL_REQUESTED,
});
export const succeedSitesWithFacilitiesRetrieval = (value) => (
  { type: SITES_FACILITIES_RETRIEVAL_SUCCEEDED, value }
);
export function updateSiteStore(value) {
  return { type: UPDATE_SITE_STORE, value };
}
export function resetSiteStore() {
  return { type: RESET_SITE_STORE };
}
export const requestSiteDetailsUpdate = (siteId) => ({
  type: SITE_DETAILS_UPDATE_REQUESTED, siteId,
});
export const updateNewSiteStore = (value) => (
  { type: UPDATED_NEW_SITE_STORE, value }
);
export function resetNewSiteStore() {
  return { type: RESET_NEW_SITE_STORE };
}
export const requestSiteCreation = (value) => (
  { type: SITE_CREATION_REQUESTED, value }
);
export const siteCreationSucceeded = (value) => (
  { type: SITE_CREATION_SUCCEEDED, value }
);
export const siteCreationFailed = (value) => (
  { type: SITE_CREATION_FAILED, value }
);

export default (state = initialState, action) => {
  switch (action.type) {
    case SITES_FACILITIES_RETRIEVAL_SUCCEEDED:
      return {
        ...state,
        sitesFacilities: action.value.sites,
        companySites: action.value.companySites,
      };
    case UPDATE_SITE_STORE:
      return { ...state, site: { ...state.site, ...action.value } };
    case RESET_SITE_STORE:
      return { ...state, site: initialState.site };
    case RESET_NEW_SITE_STORE:
      return { ...state, newSite: initialState.newSite };
    case UPDATED_NEW_SITE_STORE:
      return { ...state, newSite: { ...state.newSite, ...action.value } };
    case SITE_CREATION_SUCCEEDED:
    default:
      return state;
  }
};
