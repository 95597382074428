import { useState, useEffect } from 'react';

/**
 * Custom hook that adds an event listener to the window to track the size of the users screen
 * @returns Boolean - isMobile
 */
const useMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const resize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return isMobile;
};

export default useMobile;
