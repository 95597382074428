/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useDispatch } from 'react-redux';
import EditBillingDialog from './editBillingDialog';
import { createBilling } from '../../reducers/companiesReducer';
import PitchbookingUserOnly from '../../helpers/PitchbookingUserOnly';

const CreateBilling = () => {
  const dispatch = useDispatch();
  return (

    <div>
      <PitchbookingUserOnly>
        <EditBillingDialog
          currencySym="£"
          updateBilling={(billingParams) => dispatch(createBilling(billingParams))}
        />
      </PitchbookingUserOnly>
    </div>
  );
};

export default CreateBilling;
