export const COMPANY_USERS_RETRIEVAL_REQUESTED = 'COMPANY_USERS_RETRIEVAL_REQUESTED';
export const COMPANY_USERS_RETRIEVAL_SUCCEEDED = 'COMPANY_USERS_RETRIEVAL_SUCCEEDED';
export const COMPANY_USERS_RETRIEVAL_FAILED = 'COMPANY_USERS_RETRIEVAL_FAILED';

export const REMOVE_USER_FROM_COMPANY = 'REMOVE_USER_FROM_COMPANY';
export const ADD_USER_TO_COMPANY = 'ADD_USER_TO_COMPANY';
export const SET_ACCOUNT_MANAGER = 'SET_ACCOUNT_MANAGER';
export const SET_ROLES = 'SET_ROLES';

export const initialState = {
  team: [],
  companyUsersRetrievalFailedMessage: '',
  companyUsersRetrievalLoading: false,
  roles: [],
};

export function getCompanyUsers(companyId) {
  return { type: COMPANY_USERS_RETRIEVAL_REQUESTED, companyId };
}

export function setAccountOwner(userId) {
  return { type: SET_ACCOUNT_MANAGER, userId };
}
export function companyUsersRetrievedSuccesfully(companyUsers) {
  return { type: COMPANY_USERS_RETRIEVAL_SUCCEEDED, companyUsers };
}
export function companyUsersRetrievedFailed(error) {
  return { type: COMPANY_USERS_RETRIEVAL_FAILED, error };
}

export function removeUserFromCompany(companyUserId, reqBody) {
  return { type: REMOVE_USER_FROM_COMPANY, companyUserId, reqBody };
}

export function addUserToCompany(userDetails, reqBody) {
  return { type: ADD_USER_TO_COMPANY, userDetails, reqBody };
}

export function setRoles(roles) {
  return { type: SET_ROLES, roles };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_USERS_RETRIEVAL_REQUESTED:
      return { ...state, companyUsersRetrievalLoading: true };
    case COMPANY_USERS_RETRIEVAL_SUCCEEDED:
      return {
        ...state,
        team: action.companyUsers.companyUsers,
        companyUsersRetrievalLoading: false,
        roles: action.companyUsers.roles,
      };
    case COMPANY_USERS_RETRIEVAL_FAILED:
      return {
        ...state,
        companyUsersRetrievalFailedMessage: action.error,
        companyUsersRetrievalLoading: false,
      };
    case SET_ROLES:
      return { ...state, roles: action.roles };
    default:
      return state;
  }
};
