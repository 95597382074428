import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import {
  Button,
  Dialog,
  DialogContent,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Messenger from '@pitchbooking-dev/pb-shared/lib/components/Messenger';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import * as calendarActions from '../../reducers/calendarReducer';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';

const styles = {
  dialogContent: {
    paddingRight: '2.5rem',
    paddingLeft: '2.5rem',
    paddingBottom: '2.5rem',
  },
  dialogTitle: { paddingLeft: '0rem' },
  actionWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '1rem',
  },
  triggerButton: {
    fontWeight: 800,
    marginRight: '1.25rem',
    marginLeft: '1.25rem',
    width: 225,
  },
};

const NotesDialog = (props) => {
  const {
    isSuccess,
    onToggleDialog,
    notes,
    date,
    createNote,
    deleteNote,
  } = props;

  const [displaySnackbar, setDisplaySnackbar] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
  const [open, setOpen] = useState(false);

  const handleDialogClose = () => {
    onToggleDialog();
  };

  const handleSnackbarCloseClick = () => {
    setDisplaySnackbar(false);
  };

  useEffect(() => {
    if (isSuccess) {
      handleDialogClose();
      setDisplaySnackbar(true);
    }
  }, [isSuccess]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        style={styles.triggerButton}
        onClick={() => setOpen(true)}
      >
        Daily Notes
        <ConditionallyVisible condition={notes.length > 0}>
          <div style={{
            backgroundColor: '#4581E2', borderRadius: '70px', marginLeft: 15, height: 25, width: 25, float: 'right',
          }}
          >
            {notes.length}
          </div>
        </ConditionallyVisible>
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        fullScreen={isMobile}
      >
        <div style={styles.actionWrapper}>
          <CloseDialogIcon style={styles.closeDialogIcon} onClick={() => setOpen(false)} />
        </div>
        <DialogContent style={styles.dialogContent}>
          <Messenger
            sendMessage={(value) => createNote(date, value)}
            title={`Notes for ${date.format('dddd, Do MMMM YYYY')}`}
            isManager
            messages={notes}
            placeholder="Add a note..."
            smsDisabled
            isNotes
            deleteMessage={(id) => deleteNote(id)}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={displaySnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarCloseClick}
      >
        <Alert onClose={handleSnackbarCloseClick} severity="success" sx={{ width: '100%' }}>
          Note Created
        </Alert>
      </Snackbar>
    </>
  );
};

NotesDialog.propTypes = {
  isSuccess: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  onToggleDialog: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  createNote: PropTypes.func.isRequired,
  notes: PropTypes.arrayOf(PropTypes.shape()),
};

NotesDialog.defaultProps = {
  notes: [],
};

function mapStateToProps(state) {
  const { memberships } = state;
  const { users } = state;
  const {
    isNotesDialogOpen: isOpen,
    isInvitingUserRequest: isSubmitting,
    isInviteUserSuccess: isSuccess,
    isInviteUserError: isError,
  } = users;

  return {
    memberships, isOpen, isSubmitting, isSuccess, isError,
  };
}

const mapDispatchToProps = (dispatch) => ({
  createNote: (date, note) => dispatch(calendarActions.createCalendarNote(date, note)),
  deleteNote: (id) => dispatch(calendarActions.deleteCalendarNoteById(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesDialog);
