import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CheckBox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import * as facilitiesActions from '../../../../reducers/facilitiesReducer';

const AddForcibleBookingDialog = () => {
  const dispatch = useDispatch();
  const {
    forcibleBookings,
    relatedBookables,
    id: coreFacilityId,
  } = useSelector((state) => state.facilities.selectedFacility);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newForcibles, setNewForcibles] = useState([]);

  const filtered = useMemo(() => (
    relatedBookables.filter((bookable) => (
      !forcibleBookings.some((x) => x.id === bookable.id) && bookable.id !== coreFacilityId))
      .sort((a, b) => a.name.localeCompare(b.name))
  ), [relatedBookables, forcibleBookings]);

  const filteredFacilities = filtered.filter((x) => x.type === 'FACILITY' && x.status === 'ACTIVE');
  const filteredSubFacilities = filtered.filter((x) => x.type === 'SUB_FACILITY' && x.status === 'ACTIVE');

  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setNewForcibles([]);
    setDialogOpen(false);
  };

  const handleSave = () => {
    dispatch(facilitiesActions.requestFacilityAmenitiesCreation(newForcibles, true));
    handleClose();
  };

  const handleCheck = (value, bookable) => {
    if (value) {
      setNewForcibles((prev) => [...prev, { ...bookable, amenityId: bookable.id, coreFacilityId }]);
    } else {
      setNewForcibles((prev) => prev.filter((x) => x.id !== bookable.id));
    }
  };

  return (
    <div>
      <Button variant="contained" color="secondary" aria-label="edit" onClick={() => handleOpen()}>
        Add Forcible Booking
        <AddCircleOutlineIcon style={{ marginLeft: '10px' }} />
      </Button>
      <Dialog
        open={dialogOpen}
      >
        <DialogTitle>Add a Forcible Booking to the facility</DialogTitle>
        <DialogContent style={{ width: '600px' }}>
          <div style={{ marginBottom: '20px' }}>
            {filteredFacilities.length > 0 ? (
              <div style={{ display: 'flex', maxWidth: '300px', flexWrap: 'wrap' }}>
                {filteredFacilities.map((entity) => (
                  <React.Fragment key={entity.id}>
                    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                      <div>
                        <CheckBox
                          checked={newForcibles.some((x) => x.id === entity.id)}
                          onChange={(event) => handleCheck(event.target.checked, entity)}
                        />
                      </div>
                      <div style={{
                        width: '300px',
                        margin: '10px 0px',
                        padding: '10px',
                        borderRadius: '4px',
                        border: '1px solid #4581E2',
                      }}
                      >
                        {entity.name}
                      </div>
                    </div>
                    {filteredSubFacilities.filter(
                      (x) => x.parentFacilityId === entity.id,
                    ).map((subEntity) => (
                      <div
                        key={subEntity.id}
                        style={{
                          display: 'flex', flexWrap: 'nowrap', alignItems: 'center', marginLeft: '2rem',
                        }}
                      >
                        <div>
                          <CheckBox
                            checked={newForcibles.some((x) => x.id === subEntity.id)}
                            onChange={(event) => handleCheck(event.target.checked, subEntity)}
                          />
                        </div>
                        <div style={{
                          width: '300px',
                          margin: '10px 0px',
                          padding: '10px',
                          borderRadius: '4px',
                          border: '1px solid #4581E2',
                        }}
                        >
                          {subEntity.name}
                        </div>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
              </div>

            ) : <>No Related Bookables found</>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="secondary" variant="contained">
            Close
          </Button>
          <Button onClick={() => handleSave()} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddForcibleBookingDialog;
