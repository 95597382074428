/* eslint-disable no-case-declarations */
export const ENQUIRIES_RETRIEVAL_REQUESTED = 'ENQUIRIES_RETRIEVAL_REQUESTED';
export const ENQUIRIES_RETRIEVAL_SUCCEEDED = 'ENQUIRIES_RETRIEVAL_SUCCEEDED ';

export const ENQUIRY_RETRIEVAL_REQUESTED = 'ENQUIRY_RETRIEVAL_REQUESTED';
export const ENQUIRY_RETRIEVAL_SUCCEEDED = 'ENQUIRY_RETRIEVAL_SUCCEEDED';

export const UPDATE_CONTENT_STORE = 'UPDATE_CONTENT_STORE';
export const RESET_CONTENT_STORE = 'RESET_CONTENT_STORE';

export const SEND_CONTENT_REQUESTED = 'SEND_CONTENT_REQUESTED';
export const SEND_CONTENT_SUCCEEDED = 'SEND_CONTENT_SUCCEEDED';

export const initialState = {
  messages: [],
  thread: {
    contents: [],
    site: {
      name: '',
    },
  },
  content: {
    content: '',
    companyId: '',
    siteId: '',
    sendAsSms: false,
  },
};

export function requestAllEnquiries() {
  return { type: ENQUIRIES_RETRIEVAL_REQUESTED };
}
export function requestEnquiry(id) {
  return { type: ENQUIRY_RETRIEVAL_REQUESTED, id };
}
export function updateAllEnquiries(enquiries) {
  return { type: ENQUIRIES_RETRIEVAL_SUCCEEDED, enquiries };
}

export function updateThreadStore(thread) {
  return { type: ENQUIRY_RETRIEVAL_SUCCEEDED, thread };
}
export function updateContent(value) {
  return { type: UPDATE_CONTENT_STORE, value };
}
export function resetContentStore() {
  return { type: RESET_CONTENT_STORE };
}
export function requestSendContent() {
  return { type: SEND_CONTENT_REQUESTED };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ENQUIRIES_RETRIEVAL_SUCCEEDED:
      return { ...state, messages: action.enquiries };
    case ENQUIRY_RETRIEVAL_SUCCEEDED:
      return {
        ...state,
        thread: action.thread,
        content: {
          ...state.content,
          companyId: action.thread.companyId,
          siteId: action.thread.siteId,
        },
      };
    case UPDATE_CONTENT_STORE:
      return {
        ...state,
        content: {
          ...state.content,
          ...action.value,
        },
      };
    case RESET_CONTENT_STORE:
      return {
        ...state,
        content: {
          ...initialState.content,
          content: initialState.content.content,
        },
      };
    default:
      return state;
  }
};
