import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CheckBox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import * as addonsActions from '../../../../reducers/addonsReducer';

class AddAddonDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      newAddons: [],
    };
  }

  componentDidMount() {
    const { requestAddonsRetrieval } = this.props;
    requestAddonsRetrieval();
  }

  handleOpen() {
    this.setState({ dialogOpen: true });
  }

  handleClose() {
    this.setState({ dialogOpen: false });
  }

  handleSave = () => {
    const { newAddons } = this.state;
    const { requestFacilityAddonsCreation } = this.props;
    requestFacilityAddonsCreation(newAddons);
    this.handleClose();
  }

  handleCheck = (value, addon) => {
    const { newAddons } = this.state;
    const { selectedFacilityId } = this.props;
    if (value) {
      newAddons.push({ addonId: addon.id, facilityId: selectedFacilityId });
    } else {
      const i = newAddons.findIndex((item) => item.addonId === addon.id);
      if (i > -1) {
        newAddons.splice(i, 1);
      }
    }
  }

  render() {
    const {
      dialogOpen,
    } = this.state;
    const {
      companyAddons,
      addons,
    } = this.props;
    let filteredAddons = [];
    if (companyAddons) {
      filteredAddons = companyAddons.filter((addon) => addons.filter((
        add,
      ) => add.id === addon.id).length === 0);
    }

    return (
      <div>
        <Button variant="contained" color="secondary" aria-label="edit" onClick={() => this.handleOpen()}>
          Add Addon
          <AddCircleOutlineIcon style={{ marginLeft: '10px' }} />
        </Button>
        <Dialog
          open={dialogOpen}
        >
          <DialogTitle>Add an addon to the facility</DialogTitle>
          <DialogContent style={{ width: '600px' }}>
            <div style={{ marginBottom: '20px' }}>
              {filteredAddons.length > 0 ? (
                <div style={{ display: 'flex', maxWidth: '300px', flexWrap: 'wrap' }}>
                  {filteredAddons.map((addon) => (
                    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                      <div>
                        <CheckBox
                          checked={addon.checked}
                          onChange={(event) => this.handleCheck(event.target.checked, addon)}
                        />
                      </div>
                      <div style={{
                        width: '300px', margin: '10px 0px', padding: '10px', borderRadius: '4px', border: '1px solid #4581E2',
                      }}
                      >
                        {addon.name}
                      </div>
                    </div>
                  ))}
                </div>

              ) : <>No addons found</>}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="secondary" variant="contained">
              Close
            </Button>
            <Button onClick={() => this.handleSave()} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AddAddonDialog.propTypes = {
  requestAddonsRetrieval: PropTypes.func.isRequired,
  requestFacilityAddonsCreation: PropTypes.func.isRequired,
  companyAddons: PropTypes.shape().isRequired,
  addons: PropTypes.shape().isRequired,
  selectedFacilityId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  priceGroup: state.pricing.priceGroup,
  companyAddons: state.addons.companyAddons,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  requestAddonsRetrieval: () => addonsActions.requestAddonsRetrieval(),
  requestFacilityAddonsCreation: (newAddons) => addonsActions.requestFacilityAddonsCreation(
    newAddons,
  ),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddAddonDialog);
