import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper, Tooltip } from '@material-ui/core';
import moment from 'moment-timezone';
import { invoiceNumberFormatted } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import LoadingSection from '../../components/LoadingComponents/LoadingSection';
import InvoiceReservationsTable from './Stepper/InvoiceReservationsTable';

const invoiceInfoSnippet = (title, info) => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
  }}
  >
    <div>
      <strong>
        {title}
      </strong>
    </div>
    <div>
      <div>
        {info}
      </div>
    </div>
  </div>
);

class InvoicePreview extends React.Component {
  componentDidMount() {

  }

  render() {
    const {
      invoice, company, reservations, additionalItems, showTypeSymbol, creation,
    } = this.props;
    const { user, dueDate, invoiceNumber } = invoice;
    const { invoiceInstructions, timezone } = company;

    const invoiceNumberText = invoiceNumberFormatted(invoiceNumber);

    if (!invoiceNumber && !creation) {
      return (
        <Paper
          elevation={3}
          style={{
            margin: '0 auto',
            marginTop: '30px',
            width: '600px',
            minHeight: '848px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '40px',
          }}
        >
          <LoadingSection loadingText="Loading Invoice" />
        </Paper>
      );
    }
    let createdAt = moment();
    let reservationsAndAdditionalItems = reservations.concat(additionalItems);
    if (invoice.createdAt) {
      createdAt = invoice.createdAt;
      reservationsAndAdditionalItems = reservations;
    }
    const previewCreatedAt = invoiceInfoSnippet('Invoice date:', moment(createdAt).tz(timezone).format('DD MMM YYYY'));
    const previewDueDate = invoiceInfoSnippet('Due date:', moment(dueDate).tz(timezone).format('DD MMM YYYY'));
    const previewInvoiceNumber = invoiceInfoSnippet('Invoice Number:', invoiceNumberText);
    const previewVatNumber = invoiceInfoSnippet('VAT Number:', company.vatNumber || 'N/A');
    const { addressLine1, addressLine2, postcode } = company;
    let addressLine1Div;
    let addressLine2Div;
    let postcodeDiv;
    if (addressLine1 !== null && addressLine1 !== '') { addressLine1Div = <div>{`${addressLine1}`}</div>; }
    if (addressLine2 !== null && addressLine2 !== '') { addressLine2Div = <div>{`${addressLine2}`}</div>; }
    if (postcode !== null && postcode !== '') { postcodeDiv = <div>{`${postcode}`}</div>; }

    return (
      <>
        <Paper
          elevation={3}
          style={{
            margin: '0 auto',
            width: '700px',
            minHeight: '848px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '40px',
          }}
        >
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
          >
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '33%',
            }}
            >
              <h1>INVOICE</h1>
              <div style={{ fontSize: 'medium' }}>{`${user.firstName} ${user.lastName}`}</div>
              <div style={{ fontSize: 'small' }}>{user.teamName}</div>
              <div style={{ fontSize: 'xx-small' }}>{user.email}</div>

              <div style={{ fontSize: 'small', marginTop: '1rem' }}>
                {invoice.bookerNotes}
              </div>

            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              maxWidth: '33%',
            }}
            >
              {previewCreatedAt}
              <br />
              {previewDueDate}
              <br />
              <Tooltip title="Generated when sent" arrow>
                {previewInvoiceNumber}
              </Tooltip>
              <br />
              {previewVatNumber}
            </div>

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              maxWidth: '33%',
            }}
            >
              <div style={{
                float: 'right',
              }}
              >
                <img
                  src={company.logoUrl}
                  alt=""
                  style={{
                    maxWidth: '190px',
                    maxHeight: '130px',
                    float: 'right',
                  }}
                />
              </div>
              <div style={{
                textAlign: 'right',
              }}
              >
                <h3>
                  {`${company.name}`}
                </h3>
                {addressLine1Div}
                {addressLine2Div}
                {postcodeDiv}
              </div>
            </div>
          </div>

          <InvoiceReservationsTable
            invoice={invoice}
            selectable={false}
            showSumRow
            showTypeSymbol={showTypeSymbol}
            reservations={reservationsAndAdditionalItems}
          />

          <pre style={{
            display: 'flex',
            fontFamily: 'Montserrat',
            whiteSpace: 'pre-wrap',
          }}
          >
            {invoiceInstructions}
          </pre>

        </Paper>
      </>
    );
  }
}

InvoicePreview.propTypes = {
  invoice: PropTypes.shape().isRequired,
  company: PropTypes.shape().isRequired,
  reservations: PropTypes.shape().isRequired,
  additionalItems: PropTypes.shape().isRequired,
  showTypeSymbol: PropTypes.bool,
  creation: PropTypes.bool,
};
InvoicePreview.defaultProps = {
  showTypeSymbol: false,
  creation: false,
};

const mapStateToProps = (state) => ({
  company: state.companies.companyInfo,
});

export default connect(mapStateToProps, null)(InvoicePreview);
