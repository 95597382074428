import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as leagueActions from '../../../reducers/leaguesReducer';
import FixtureList from './FixtureList';
import { generateRandomFixture } from './fixtureGenerator';

const styles = () => ({
  root: {
    minWidth: 400,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});
class GenerateFixturesDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      games: [],
    };
  }

  handleClickOpen = (teams) => {
    this.setState({ open: true });
    if (teams) {
      const games = generateRandomFixture(teams);
      this.setState({ games });
    }
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  generateNewSetOfFixtures = (teams) => {
    if (teams) {
      const games = generateRandomFixture(teams);
      this.setState({ games });
    }
  }

  handleConfirmFixtures = (leagueId) => {
    const { games } = this.state;
    const { confirmFixtures } = this.props;
    confirmFixtures(games, leagueId);
    this.setState({ open: false });
  }

  render() {
    const isMobile = window.innerWidth < 768;
    const {
      classes, teams, leagueId,
    } = this.props;

    const { open, games } = this.state;

    return (
      <div>
        <Button color="primary" variant="contained" onClick={() => this.handleClickOpen(teams)}>
          Generate Fixtures
        </Button>
        <Dialog
          open={open}
          fullScreen={isMobile}
          fullWidth
          classes={{ paper: classes.root }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>Generate and Confirm Fixtures</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <DialogContent>
            <div style={{ width: '100%', height: '30px' }}>
              <Button style={{ float: 'right', marginRight: 10 }} color="secondary" variant="contained" onClick={() => this.generateNewSetOfFixtures(teams)}>
                Randomise Fixtures
              </Button>
            </div>
            <ConditionallyVisible condition={games.length > 0}>
              <FixtureList
                fixtures={games}
                teams={teams}
                generated={false}
              />
            </ConditionallyVisible>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary" variant="outlined">
              Close
            </Button>
            <Button onClick={() => this.handleConfirmFixtures(leagueId)} color="primary" variant="contained">
              Confirm Fixtures
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { events } = state;
  const { eventStore, event } = events;
  return {
    eventStore,
    event,
  };
}

const mapDispatchToProps = (dispatch) => ({
  confirmFixtures: (games, leagueId) => dispatch(leagueActions.confirmFixtures(games, leagueId)),
});

GenerateFixturesDialog.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  classes: PropTypes.shape().isRequired,
  confirmFixtures: PropTypes.func.isRequired,
  leagueId: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withStyles(styles())(GenerateFixturesDialog));
