// @flow weak

import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import TextField from '@material-ui/core/TextField';
import * as actions from '../../../../reducers/facilitiesReducer';

const InvoiceNoteSection = ({
  requestSubFacilityUpdate, subFacilityName, subFacilityId,
}) => {
  const [stage, setStage] = useState('initial');
  const [name, setName] = useState('');
  if (!name && subFacilityName) {
    setName(subFacilityName);
  }
  const updateAndSave = () => {
    const facilityData = {
      name,
    };
    requestSubFacilityUpdate(subFacilityId, facilityData);
    setStage('save');
  };
  if (subFacilityName && stage === 'initial') {
    setStage('save');
  }

  return (
    <div>
      <ConditionallyVisible condition={stage === 'add' || stage === 'save'}>
        <TextField
          id="name"
          label="Facility Name"
          variant="outlined"
          inputProps={{ 'max-width': '100px' }}
          disabled={stage === 'save'}
          onChange={(event) => setName(event.target.value)}
          defaultValue={stage === 'save' ? subFacilityName : name}
          margin="normal"
        />
        <div style={{ minWidth: '150px', display: 'flex' }}>
          <Button
            onClick={() => (stage === 'save' ? setStage('add') : updateAndSave())}
            variant="contained"
            color="primary"
          >
            {stage === 'save' ? 'Edit' : 'Save'}
          </Button>
          <ConditionallyVisible condition={stage === 'add'}>
            <Button
              onClick={() => setStage('save')}
              variant="contained"
              color="secondary"
              style={{ marginLeft: '5px' }}
            >
              Cancel
            </Button>
          </ConditionallyVisible>
        </div>

      </ConditionallyVisible>
    </div>
  );
};

InvoiceNoteSection.propTypes = {
  requestSubFacilityUpdate: PropTypes.func.isRequired,
  subFacilityId: PropTypes.string.isRequired,
  subFacilityName: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  requestSubFacilityUpdate: (facilityId, facilityData) => dispatch(
    actions.requestSubFacilityUpdate(facilityId, facilityData),
  ),
});

export default connect(null, mapDispatchToProps)(InvoiceNoteSection);
