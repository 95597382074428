import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const UserSelectionInfoAlert = ({ newUser, booking }) => (

  <Alert
    id="alert"
    severity="info"
    style={{ width: '100%', textAlign: 'left' }}
  >
    <AlertTitle style={{ fontWeight: '600' }}>Please Note:</AlertTitle>
    <ul style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      {newUser && (
        <li>
          <Typography variant="body2">
            Please ensure to enter a valid and correct email address.
            This ensures that this and all future bookings are correctly accounted for.
          </Typography>
        </li>
      )}
      {booking && (
        <>
          <li>
            <Typography variant="body2">
              The customer selected at this stage determines
              both the availability and pricing in the following steps.
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              {`Please ensure all bookings are added for the owner of the booking.
            If you are adding a booking on behalf of a customer,
            the customer's email address must be used to avoid inconsistent records.`}
            </Typography>
          </li>
        </>
      )}
    </ul>
  </Alert>
);

UserSelectionInfoAlert.propTypes = {
  newUser: PropTypes.bool,
  booking: PropTypes.bool,
};

UserSelectionInfoAlert.defaultProps = {
  newUser: false,
  booking: true,
};

export default (UserSelectionInfoAlert);
