import React from 'react';
import PropTypes from 'prop-types';

import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

export class SettingsModalTimeframesCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleCustomDateChanges(dates) {
    const { setAncestorState } = this.props;
    this.setState({
    }, () => setAncestorState('localTimeframe', dates));
  }

  render() {
    const { focusedInput } = this.state;
    const { customValues } = this.props;
    const { startDate, endDate } = customValues;
    return (
      <div style={{ borderBottom: '1px #00000020 solid', margin: '50px 0', padding: '10px 0' }}>
        <DateRangePicker
          startDate={startDate}
          startDateId="startDate"
          endDate={endDate}
          endDateId="endDate"
          onDatesChange={({ startDate, endDate }) => this.handleCustomDateChanges(
            { startDate, endDate },
          )}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => this.setState({ focusedInput })}
          displayFormat="DD/MM/YYYY"
          anchorDirection="left"
          openDirection="up"
          numberOfMonths={1}
          noBorder
          isOutsideRange={() => false}
        />
      </div>
    );
  }
}

SettingsModalTimeframesCustom.propTypes = {
  setAncestorState: PropTypes.func.isRequired,
  customValues: PropTypes.string.isRequired,
};
