import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const PitchbookingUserOnly = ({ currentUser, children }) => (
  (currentUser && currentUser.email && currentUser.email.includes('@pitchbooking.com')) ? children : (<></>)
);

function mapStateToProps(state) {
  return { currentUser: state.users.currentUser };
}

PitchbookingUserOnly.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  children: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape()),
  ]).isRequired,
};

export default connect(mapStateToProps, null)(PitchbookingUserOnly);
