export const UPDATE_USER_NOTE_REQUESTED = 'UPDATE_USER_NOTE_REQUESTED';
export const UPDATE_USER_NOTE_SUCCEEDED = 'UPDATE_USER_NOTE_SUCCEEDED';
export const UPDATE_USER_NOTE_FAILED = 'UPDATE_USER_NOTE_FAILED';

export const initialState = {
  loading: false,
  error: null,
};

export const updateCompanyUserNote = (userId, note) => ({
  type: UPDATE_USER_NOTE_REQUESTED, userId, note,
});
export const succeedUpdateCompanyUserNote = () => ({
  type: UPDATE_USER_NOTE_SUCCEEDED,
});
export const failUpdateCompanyUserNote = () => ({
  type: UPDATE_USER_NOTE_FAILED,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_NOTE_REQUESTED:
      return {
        ...state,
        loading: true,
        error: initialState.error,
      };
    case UPDATE_USER_NOTE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case UPDATE_USER_NOTE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
