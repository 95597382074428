/* eslint-disable no-use-before-define */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import JSON2CSV from 'json2csv';
import FileDownload from 'js-file-download';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import '../../../styles/accessRestriction.css';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { parseSubscriptionRowsForDownload } from '../../../helpers';
import DeletePrompt from '../../accessRestrictions/components/DeleteAccessRestrictionDialog';
import EditSubscriptionDialog from '../../../components/Subscriptions/EditSubscriptionDialog';
import PauseSubscriptionsDialog from '../PauseSubscriptionsDialog';
import TableFormattedName from '../../../components/TableFormattedName';
import * as subscriptionsActions from '../../../reducers/subscriptionsReducer';
import * as helper from '../../../helpers/index';
import ToggleButtons from '../../../components/ToggleButtons';
import DownloadPdf from '../../../components/DownloadPdf';
import { StatusTag } from '../../../components/StatusTag';

const SubDisplayButtonsData = [{
  buttonTitle: 'Current Subscriptions',
  buttonValue: 'CURRENT',
},
{
  buttonTitle: 'Future Subscriptions',
  buttonValue: 'FUTURE',
},
{
  buttonTitle: 'Expired Subscriptions',
  buttonValue: 'EXPIRED',
}];

const handleExport = (columns, data, type) => {
  const mappedData = data.map((row) => ({
    SubscriptionName: row.subscriptionName,
    FacilityName: row.accessRestriction.facilities[0].name,
    Name: `${row.user.firstName} ${row.user.lastName}`,
    User: row.user.email,
    Amount: (row.amount / 100).toFixed(2),
    Validity: helper.generateValidityText(row.accessRestriction),
    Day: moment().weekday(parseInt(row.accessRestriction.weekday, 10)).format('dddd'),
    Time: helper.createTimeRangeString(
      row.accessRestriction.startTime, row.accessRestriction.endTime,
    ),
    Type: row.type,
  }));

  if (type === 'PDF') {
    const timestamp = moment(new Date()).format('YYYY-MM-DD');
    const rowData = mappedData.map((row) => Object.values(row));
    DownloadPdf(
      Object.keys(mappedData[0]),
      rowData,
      `Pitchbooking Subscriptions Export - (${timestamp}).pdf`,
    );
  }
};

export const renderSubscriptionStatus = (sub) => {
  let pausedText = 'Paused Indefinitely';
  if (sub.paused && sub.pausedUntil !== null) {
    pausedText = `Paused (Resumes: ${moment(sub.pausedUntil).add(1, 'seconds').format('DD MMM')})`;
  }
  const statusIcons = (
    <div>
      <AlternativelyVisible condition={sub.type === 'PAYP'}>
        <StatusTag
          label="Invoice / Pay on Arrival"
          colorScheme={{
            color: 'rgb(0, 73, 122)',
            borderColor: 'rgb(31, 165, 255, 0.8)',
            backgroundColor: 'rgb(71, 182, 255, 0.3)',
          }}
        />
        <>
          <AlternativelyVisible condition={sub.paused}>
            <>
              <StatusTag
                label={pausedText}
                colorScheme={{
                  color: 'rgb(0, 0, 0)',
                  borderColor: 'rgb(173, 173, 173)',
                  backgroundColor: 'rgb(206, 206, 206, 0.4)',
                }}
              />
            </>
            <>
              <ConditionallyVisible condition={sub.status === 'ACTIVE'}>
                <StatusTag
                  label="Confirmed"
                  colorScheme={{
                    color: 'rgb(21, 122, 73)',
                    borderColor: 'rgb(30, 174, 104)',
                    backgroundColor: 'rgb(168, 240, 205)',
                  }}
                />
              </ConditionallyVisible>
              <ConditionallyVisible condition={sub.status === 'PROCESSING'}>
                <StatusTag
                  label="Processing Payment Set Up"
                  colorScheme={{
                    color: 'rgb(179, 112, 5)',
                    borderColor: 'rgb(219, 137, 6, 0.75)',
                    backgroundColor: 'rgb(249, 160, 16, 0.4)',
                  }}
                />
              </ConditionallyVisible>
              <ConditionallyVisible condition={sub.status === 'INACTIVE' && sub.expires === null}>
                <StatusTag
                  data={sub}
                  label="Unconfirmed"
                  colorScheme={{
                    color: 'rgb(144, 46, 39)',
                    borderColor: 'rgb(172, 55, 47)',
                    backgroundColor: 'rgb(233, 179, 175)',
                    hoverColor: 'rgb(172, 55, 47, 0.5)',
                  }}
                  sendEmail
                  toolTipText="Resend subscription email"
                  emailType="resendSubscriptionConfirmation"
                />
              </ConditionallyVisible>
              <ConditionallyVisible condition={sub.status === 'INACTIVE' && moment(sub.expires).isAfter(moment())}>
                <StatusTag
                  label="In Progress/Awaiting Confirmation"
                  colorScheme={{
                    color: 'rgb(179, 112, 5)',
                    borderColor: 'rgb(219, 137, 6, 0.75)',
                    backgroundColor: 'rgb(249, 160, 16, 0.4)',
                  }}
                />
              </ConditionallyVisible>
            </>
          </AlternativelyVisible>
        </>
      </AlternativelyVisible>
    </div>
  );
  return statusIcons;
};

const SubscriptionsTable = ({
  subscriptions, updateSelectedSubscriptions, subscriptionStatus, updateSubscriptionStatus,
}) => (
  <div>
    <div style={{ marginLeft: 20 }}>
      <ToggleButtons
        buttonsData={SubDisplayButtonsData}
        changeOption={(option) => updateSubscriptionStatus(option)}
        value={subscriptionStatus}
      />
    </div>
    <div className="table-section">
      <TableContainer
        style={{
          borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden',
        }}
        component={Paper}
      >
        <MaterialTable
          data={subscriptions}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          title="Pitchbooking Subscriptionss"
          icons={{
            NextPage: () => <ChevronRight />,
            PreviousPage: () => <ChevronLeft />,
            Search: () => <Search />,
            Export: () => <SaveAlt />,
            ResetSearch: () => <Clear />,
            SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
          }}
          columns={[
            {
              title: 'Name',
              field: 'subscriptionName',
              render: (row) => (
                <a href={`/subscriptions/${row.id}`}>
                  {row.subscriptionName}
                </a>
              ),
            },
            {
              title: 'Facility',
              field: 'accessRestriction.facilities[0].name',
              render: (row) => (
                row.accessRestriction.facilities.map((facility) => (
                  <div>
                    {facility.name}
                  </div>
                ))
              ),
            },
            {
              title: 'User',
              field: 'user.email',
              render: (row) => (
                <TableFormattedName
                  user={row.user}
                  showTeam
                />
              ),
              customFilterAndSearch: (term, row) => {
                const string = `${row.user?.firstName} ${row.user?.lastName} ${row.user?.teamName}`.toLowerCase();
                return string.includes(term.toLowerCase());
              },
            },
            {
              title: 'Amount',
              field: 'amount',
              render: (row) => {
                const formattedAmount = (row.amount / 100).toFixed(2);
                return formattedAmount;
              },
            },
            {
              title: 'Validity',
              field: 'accessRestriction.validFrom',
              render: (row) => (helper.generateValidityText(row.accessRestriction,
                row.accessRestriction?.facilities[0].timezone)),
              customFilterAndSearch: (term, row) => {
                const string = helper.generateValidityText(row.accessRestriction,
                  row.accessRestriction?.facilities[0].timezone).toLowerCase();
                return string.includes(term.toLowerCase());
              },
            },
            {
              title: 'Day',
              field: 'accessRestriction.weekday',
              render: (row) => {
                const formattedWeekday = moment().weekday(parseInt(row.accessRestriction.weekday, 10)).format('dddd');
                return formattedWeekday;
              },
              customFilterAndSearch: (term, row) => {
                const formattedWeekday = moment().weekday(parseInt(row.accessRestriction.weekday, 10)).format('dddd');
                const dayNameString = formattedWeekday.toString().toLowerCase();
                return dayNameString.includes(term.toLowerCase());
              },
            },
            {
              title: 'Time',
              field: 'accessRestriction.startTime',
              render: (row) => (helper.createTimeRangeString(
                row.accessRestriction.startTime, row.accessRestriction.endTime,
              )),
            },
            {
              title: 'Status',
              field: 'status',
              align: 'center',
              render: (row) => renderSubscriptionStatus(row),
            },
            {
              title: 'Actions',
              render: (row) => (
                <div style={{ display: 'flex', 'align-items': 'center', 'flex-direction': 'row' }}>
                  <ConditionallyVisible
                    condition={moment(row.accessRestriction.validFrom) >= moment().startOf('day')}
                  >
                    <DeletePrompt
                      name="Subscription"
                      bodyTag="subscription"
                      type="SUBSCRIPTION"
                      subscriptionId={row.id}
                    />
                  </ConditionallyVisible>
                  <div style={{ width: '5px' }} />
                  <EditSubscriptionDialog
                    subscription={row}
                  />
                </div>
              ),
            },
          ]}
          options={{
            search: true,
            selection: true,
            emptyRowsWhenPaging: false,
            tableLayout: 'auto',
            exportButton: true,
            exportFileName: 'Pitchbooking Subscriptions',
            exportCsv: (columns, data) => {
              const { colHeaders, rows } = parseSubscriptionRowsForDownload(columns, data);
              const csv = JSON2CSV({ data: rows, fields: colHeaders, excelStrings: false });
              const timestamp = moment(new Date()).format('YYYY-MM-DD');
              const filename = `Pitchbooking Subscription Export (${timestamp}).csv`;
              FileDownload(csv, filename);
            },
            exportPdf: (columns, data) => {
              handleExport(columns, data, 'PDF');
            },
            pageSize: 50,
            pageSizeOptions: [10, 25, 50, 100],
            showFirstLastPageButtons: false,
            showTitle: false,
            headerStyle: {
              backgroundColor: '#efefef',
              fontWeight: 'bold',
            },
          }}
          actions={[
            {
              tooltip: 'Pause all selected subscriptions.',
            },
          ]}
          onSelectionChange={(rows) => updateSelectedSubscriptions(rows)}
          components={{
            Action: () => (
              <PauseSubscriptionsDialog />
            ),
          }}
          localization={{
            toolbar: {
              exportTitle: 'Download',
            },
          }}
        />
      </TableContainer>
    </div>
  </div>
);

SubscriptionsTable.propTypes = {
  subscriptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateSelectedSubscriptions: PropTypes.func.isRequired,
  updateSubscriptionStatus: PropTypes.func.isRequired,
  subscriptionStatus: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return { currentUser: state.users.currentUser };
}

const mapDispatchToProps = (dispatch) => ({
  updateSelectedSubscriptions: (subs) => dispatch(
    subscriptionsActions.updateSelectedSubscriptions(subs),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsTable);
