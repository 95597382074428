import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FacilityAndSubFacilityPickerList from './FacilityAndSubFacilityPickerList';

class FacilityAndSubFacilityPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    const { editingAccessRestriction, flagFacilitiesChanged } = this.props;
    if (editingAccessRestriction) {
      flagFacilitiesChanged();
    }
    this.setState({ open: false });
  };

  handleRequestCancel = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      handleRequestClose, handleRequestCancel, state,
    } = this;
    const { open } = state;
    const {
      facilities, singleSelectionLimit, onUpdate, checkedFacilities,
    } = this.props;
    const buttonLabel = singleSelectionLimit ? 'Select Facility' : 'Select Facilities';

    return (
      <div>
        <Button
          onClick={this.handleClickOpen}
          variant="contained"
          disabled={!facilities || facilities.length <= 0}
        >
          {buttonLabel}
          {' '}
          (
          {checkedFacilities.length}
          )
        </Button>
        <Dialog
          open={open}
          fullWidth
          onBackdropClick={handleRequestCancel}
          onEscapeKeyDown={handleRequestCancel}
        >
          <DialogTitle>
            {buttonLabel}
          </DialogTitle>
          <DialogContent>
            <FacilityAndSubFacilityPickerList
              facilities={facilities}
              checkedFacilities={checkedFacilities}
              updateFacilities={(x) => onUpdate(x)}
              showSubFacilities
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestCancel} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleRequestClose} color="primary" autoFocus>
              Choose
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

FacilityAndSubFacilityPicker.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  checkedFacilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  facilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  flagFacilitiesChanged: PropTypes.func.isRequired,
  editingAccessRestriction: PropTypes.bool.isRequired,
  singleSelectionLimit: PropTypes.bool,
};

FacilityAndSubFacilityPicker.defaultProps = {
  singleSelectionLimit: false,
};

export default FacilityAndSubFacilityPicker;
