/* eslint-disable no-mixed-operators */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */

import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Alert } from '@material-ui/lab';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import * as actions from '../../reducers/pricingReducer';
import PricingAddRuleDialogSection from './PricingAddRuleDialogSection';
import ToggleButtons from '../../components/ToggleButtons';
import { useMobile } from '../../hooks';

const styles = () => ({
  root: {
    minWidth: 400,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});

const pricePerButtonsData = [{
  buttonTitle: 'Reservation',
  buttonValue: 'RESERVATION',
},
{
  buttonTitle: 'Hour',
  buttonValue: 'HOUR',
}];

const priceTypeButtonsData = [{
  buttonTitle: 'Actual price',
  buttonValue: 'ACTUAL',
},
{
  buttonTitle: 'Fixed alteration',
  buttonValue: 'ABSOLUTE',
},
{
  buttonTitle: 'Percentage',
  buttonValue: 'PERCENTAGE',
}];

const PricingAddRuleDialog = ({ classes }) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);

  const { priceModifier } = useSelector((state) => state.pricing);

  const [open, setOpen] = useState(false);

  const modifierType = useMemo(() => {
    const {
      ageGroupBookingModifierId,
      bookedAsAmenity,
      day,
      duration,
      endTime,
      eventBookingModifierId,
      quantity,
      startTime,
      subFacility_quantity,
    } = priceModifier;

    if (
      ageGroupBookingModifierId
      || bookedAsAmenity
      || day >= 0
      || duration
      || endTime
      || eventBookingModifierId
      || quantity
      || startTime
      || subFacility_quantity
    ) {
      return 'COMBINATION';
    }

    return 'DEFAULT';
  }, [priceModifier]);

  const {
    value, pricePer, priceType, subFacility_quantity,
  } = priceModifier;

  const [displayedValue, setDisplayedValue] = useState(
    () => (subFacility_quantity && subFacility_quantity > 0
      ? value * subFacility_quantity
      : value),
  );

  useEffect(() => {
    if (subFacility_quantity && subFacility_quantity > 0) {
      setDisplayedValue(value * subFacility_quantity);
    } else {
      setDisplayedValue(value);
    }
  }, [value, subFacility_quantity]);
  // Handler to update value in Redux and local displayed value
  const handleValueChange = (event) => {
    const newValue = event.target.value;
    const originalValue = subFacility_quantity ? newValue / subFacility_quantity : newValue;
    dispatch(actions.updatePriceModifierStore({ type: priceType, value: originalValue }));
    setDisplayedValue(newValue);
  };

  let amountAdornment = '';

  if (priceType === 'PERCENTAGE') {
    amountAdornment = '%';
  } else if (pricePer === 'HOUR') {
    amountAdornment = '/ hour';
  } else if (pricePer === 'RESERVATION') {
    amountAdornment = '/ reservation';
  }

  const priceRules = [
    {
      title: 'Start Time',
      parameter: 'startTime',
      value: priceModifier.startTime,
    },
    {
      title: 'End Time',
      parameter: 'endTime',
      value: priceModifier.endTime,
    },
    {
      title: 'Weekday',
      parameter: 'day',
      value: priceModifier.day,
    },
    {
      title: 'Fractional quantity',
      parameter: 'subFacility_quantity',
      value: priceModifier.subFacility_quantity,
    },
    {
      title: 'Quantity',
      parameter: 'quantity',
      value: priceModifier.quantity,
    },
    {
      title: 'Duration',
      parameter: 'duration',
      value: priceModifier.duration,
    },
    {
      title: 'Age Group',
      parameter: 'ageGroupBookingModifierId',
      value: priceModifier.ageGroupBookingModifierId,
    },
    {
      title: 'Event',
      parameter: 'eventBookingModifierId',
      value: priceModifier.eventBookingModifierId,
    },
    {
      title: 'Applies when booked as an amenity',
      parameter: 'bookedAsAmenity',
      value: priceModifier.bookedAsAmenity,
    },
    {
      title: 'Floodlights',
      parameter: 'floodlights',
      value: priceModifier.floodlights,
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
    dispatch(actions.resetPriceGroupPriceModifierStore());
  };

  const updatePriceModifierStore = (keyWithValue) => {
    if (keyWithValue.type === 'PERCENTAGE' && keyWithValue.value > 100) {
      setAlert(true);
    } else {
      setAlert(false);
    }

    dispatch(actions.updatePriceModifierStore(keyWithValue));
  };

  const handleAddPriceRule = () => {
    const pm = { ...priceModifier, modifierType };

    if (pm.startTime) {
      pm.startTime = moment(pm.startTime).startOf('minute').format('HH:mm:ss');
    }

    if (pm.endTime) {
      pm.endTime = moment(pm.endTime).subtract(1, 'minute').format('HH:mm:ss');
    }

    dispatch(actions.updatePriceGroupPriceRuleStore(pm));
    handleRequestClose();
  };

  return (
    <div>
      <Button className={classes.button} color="primary" variant="contained" onClick={() => handleClickOpen()}>
        Add Price Rule
      </Button>
      <Dialog
        open={open}
        onRequestClose={handleRequestClose}
        fullScreen={isMobile}
        classes={{ paper: classes.root }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

          {/* Title */}
          <DialogTitle>Add new price rule to price group</DialogTitle>

          {/* Close Button */}
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => handleRequestClose()} />
          </DialogActions>
        </div>

        {/* Alert message */}
        <Alert severity="warning" style={{ margin: '0 1.5rem' }}>
          Please note if you remove an already selected price modifier,
          the modifier may change from combination to default.
        </Alert>
        <DialogContent>
          {priceRules.map((pr) => (
            <>
              <PricingAddRuleDialogSection
                title={pr.title}
                parameter={pr.parameter}
                value={pr.value}
                onUpdate={updatePriceModifierStore}
              />
              <Divider />
            </>
          ))}
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
          }}
          >
            <Typography variant="subtitle1" noWrap style={{ minWidth: 'max-content' }}>
              Price Type:
            </Typography>
            <div style={{ width: '20px' }} />
            <ToggleButtons
              buttonsData={priceTypeButtonsData}
              changeOption={(option) => updatePriceModifierStore({ priceType: option })}
              value={priceType}
            />
          </div>
          <Divider />

          {priceModifier.priceType === 'PERCENTAGE' && alert && (
          <Alert severity="error" style={{ marginTop: '0.75rem' }}>
            Percentage cannot be less than 1% or more than 100%
          </Alert>
          )}

          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
          }}
          >
            <Typography variant="subtitle1" nowrap>
              Amount:
            </Typography>
            <div style={{ width: '20px' }} />
            <TextField
              value={displayedValue}
              margin="normal"
              variant="outlined"
              type="number"
              onChange={handleValueChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">{amountAdornment}</InputAdornment>,
              }}
            />
          </div>
          <Divider />
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
          }}
          >
            <Typography variant="subtitle1" noWrap>
              Price Per:
            </Typography>
            <div style={{ width: '20px' }} />
            <ToggleButtons
              buttonsData={pricePerButtonsData}
              changeOption={(option) => updatePriceModifierStore({ pricePer: option })}
              value={pricePer}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary" variant="outlined">
            Close
          </Button>
          <Button onClick={handleAddPriceRule} color="primary" variant="contained" disabled={alert}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles())(PricingAddRuleDialog);
