/* eslint-disable react/prop-types */
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Dialog, DialogTitle, DialogActions, DialogContent, Button, Typography,
} from '@material-ui/core';
import { useMobile, useToast } from '../../../../hooks';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';
import { deleteCalendarNoteService } from '../../../../services/calendarServices';

export const SubscriptionDeleteNoteDialog = ({
  isOpen, onClose, subscription, noteId,
}) => {
  const queryClient = useQueryClient();
  const isMobile = useMobile();
  const toast = useToast();

  const note = noteId
    ? subscription.notes.find((ex) => ex.id === noteId)
    : null;

  const mutation = useMutation({
    mutationFn: async () => {
      if (!note) {
        throw new Error('Note not found!');
      }

      queryClient.setQueryData(['subscription', subscription.id], (oldData) => ({
        ...oldData,
        notes: oldData.notes.filter((ex) => ex.id !== noteId),
      }));

      const res = await deleteCalendarNoteService(
        subscription.companyId,
        note.id,
      );

      if (res.status !== 200) {
        throw new Error('Failed to delete the exclusion!');
      }

      return res.data;
    },
    onError: (error) => {
      queryClient.invalidateQueries(['subscription', subscription.id]);
      toast.trigger({
        type: 'error',
        message: 'Failed to delete the note!',
      });
      console.error(error);
    },
    onSuccess: () => {
      onClose();
      toast.trigger({
        type: 'success',
        message: 'Note deleted successfully!',
      });
    },
  });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={isMobile}
      maxWidth="sm"
      fullWidth
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle>Delete Note</DialogTitle>
        <DialogActions variant="none">
          <CloseDialogIcon onClick={onClose} />
        </DialogActions>
      </div>

      <DialogContent style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
      >
        <Typography variant="body1">
          Are you sure you wish to delete this note?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          autoFocus
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          autoFocus
          disabled={mutation.isLoading}
          variant="outlined"
          onClick={() => mutation.mutate()}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
