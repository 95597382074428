import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import TextField from '@material-ui/core/TextField';
import { getUsers } from '../../../reducers/usersReducer';

const userPropShape = ({
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
});

const parseUsers = (users) => users.map((user) => {
  const fullName = `${user.firstName} ${user.lastName}`;
  const {
    email, teamName, phone, dialCode,
  } = user;
  const mergedPhone = dialCode && phone ? `+${dialCode}${phone}` : '';
  let label = `${fullName} ${email} ${mergedPhone || ''}`;
  if (teamName !== '' && teamName !== null) { label = `${label} (${teamName})`; }
  return ({
    ...user,
    label,
  });
});

class PreviousBookerUserSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term: null,
    };
    const searchAPIDebounced = AwesomeDebouncePromise(this.fireOffSearch, 800);
    this.searchAPIDebounced = searchAPIDebounced;
  }

  fireOffSearch = async (searchTerm) => {
    const { doGetUsers } = this.props;
    doGetUsers(0, searchTerm);
    this.setState({ term: searchTerm });
  };

   handleUserSearch = (value) => {
     this.searchAPIDebounced(value);
   };

   render() {
     const {
       users, user, updateUserDetails, disableClearable,
     } = this.props;
     const { term } = this.state;
     const extraProps = { };
     if (user && user.label) {
       // eslint-disable-next-line prefer-destructuring
       extraProps.value = parseUsers([user])[0];
     }
     return (
       <div style={{ textAlign: 'center', margin: 'auto', maxWidth: 400 }}>
         <Typography gutterBottom type="subheading">
           Search for an existing customer:
         </Typography>
         <Autocomplete
           className="auto-complete-combo"
           options={parseUsers(users)}
           clearIcon
           loading={users?.length === 0 && term !== null} // only show loading if we have a term
           getOptionLabel={(option) => option.label}
           style={{ width: 300, margin: 'auto' }}
           value={user.id && parseUsers([user])[0]}
           onChange={(event, option) => updateUserDetails({ ...option })}
           disableClearable={disableClearable}
           renderInput={(params) => (
             <>
               <TextField
                 {...params}
                 defaultValue={term}
                 label="Select Customer"
                 variant="outlined"
                 fullWidth
                 onChange={(event) => this.handleUserSearch(event.target.value)}
               />
             </>
           )}
         />
       </div>
     );
   }
}

PreviousBookerUserSearch.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape(userPropShape)).isRequired,
  user: PropTypes.shape(userPropShape).isRequired,
  selectedUser: PropTypes.shape(userPropShape).isRequired,
  doGetUsers: PropTypes.func.isRequired,
  updateUserDetails: PropTypes.func.isRequired,
  disableClearable: PropTypes.bool,
};

PreviousBookerUserSearch.defaultProps = {
  disableClearable: true,
};

const mapStateToProps = (state) => ({
  users: state.users.users,
});

const mapDispatchToProps = (dispatch) => ({
  doGetUsers: (page, term) => dispatch(getUsers(page, term)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviousBookerUserSearch);
