/* eslint-disable react/prop-types */
import React from 'react';
import Responsive from 'react-responsive';
import Slider from 'react-slick';
import '../../styles/solution.scss';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ListItemText from '@material-ui/core/ListItemText';
import SalesEnquiryDialog from '@pitchbooking-dev/pb-shared/lib/components/SalesEnquiryDialog';

import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PaymentIcon from '@material-ui/icons/Payment';
import TimerIcon from '@material-ui/icons/Timer';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

// import Football from '../../images/FootballandTennis.svg';
// import ClaimFacilityDialog from '../../components/ClaimFacilityDialog';

// import backgroundStock from '../../images/heroPitchNight.svg';
// import playerDevice from '../../images/player-and-device.svg';
// import CurrentEvents from './currentEvents';

const data = [
  {
    code: 'DEFAULT',
    header: 'Event Management Software for Sports',
    subheader: 'Making it simple to create sports events, camps and sessions - with easy set-up and secure payment',
    subheader2: 'Trusted by sporting clubs and organisations across the world',
    subHeaderFacilitiesBooking: 'https://pitchbooking.com/solution',
    solutionFeatureTitle1: 'Create sports events with ease',
    solutionFeatureTitle2: 'Instant and secure online payments',
    solutionFeatureTitle3: 'Making administration simple',
    solutionFeature1: 'Flexible setup of capture forms, availability and pricing via your management login',
    solutionFeature2: 'Deposited directly into your bank account, via our trusted and secure gateway',
    solutionFeature3: 'Automated confirmation emails, straightforward cancellation and refund process, and much more',
    solutionParagraph: 'Pitchbooking is a trusted and user-friendly scheduling and payments system for creating and administering sporting events. Our online software helps our customers across the UK, Ireland and US save admin time, increase event revenue and provide a smooth booking process for your customers. Discover how other sporting clubs and organisations just like you use our software features to make the end to end event process easier. From instant payment processing, through to customer communication, we provide bespoke and easily configurable booking software that can be applied to a wide range of sporting events.',
    featureListHeader1: 'Set availability',
    featureListHeader2: 'Dynamic Forms',
    featureListHeader3: 'Attendee Overview',
    featureListHeader4: 'Custom Pricing Rules',
    featureListHeader5: 'Real Time Availability',
    featureListHeader6: 'Payout Reconciliation',
    featureListHeader7: 'Marketing',
    featureListHeader8: 'Staff Access',
    featureListHeader9: 'Multi-Device',
    featureListHeader10: 'Simple Setup',
    featureListHeader11: 'Enhanced Reporting',
    featureListHeader12: 'Reliable Support',

    featureList1: 'Handle event capacity and availability with our intuitive software',
    featureList2: 'Make sure you capture the detail you need from your event attendees',
    featureList3: 'Get a full overview of those attending your event, from payment status to contact details ',
    featureList4: 'Price your sports event how you need it, inclduing offers, discounts and time based pricing',
    featureList5: 'Alway up to date availability means you dont run the risk of double bookings',
    featureList6: 'Making life easy for your finance team with automated income breakdowns',
    featureList7: 'Enhance your online presence and have customers easily find your events',
    featureList8: 'Control access to information for any team members involved with your events',
    featureList9: 'Desktop, phone, or tablet, our events software works flawlessly on them all',
    featureList10: 'Our intuitive software means you can get up and running in no time',
    featureList11: 'Instant access to real-time stats and data on your events',
    featureList12: 'The Pitchbooking team are on hand to provide support and guidance (if you need it)',
    mapHeader: '1000+ Pitchbooking customers across the UK and Ireland',
    mapText1: 'Football, tennis, rugby, cricket – whatever the sport, whatever your event, Pitchbooking makes it simply to create events that your users can easily book and pay for online!',
    mapText2: 'Kids summer camps, elite training sessions, invitation only sports events - whatever event you need to host, Pitchbooking gives you the tools you need',
    getStartedHeader: 'Ready to get up and running?',
    getStartedText1: 'Easily arrange a free demo with our team to learn how our software solution can help you setup and administer your events. Then give us the go ahead and one of our Pitchbooking specialists will coach you through the simple setting-up process. Plus, we’ll be on hand afterwards (should you require any pep talks from the sidelines).',
    getStartedText2: 'We take time to find out all about your club or organsiation and how you want to promote your events. We will get straight to work to configure your events, and set you up to take booking and payments online within minutes.',
    pricingHeader: 'Pricing',
    pricingText1: 'Whether you’re a small sports club or a large organisation managing thousands of event attendees every year, we’ve got a pricing tier to suit. Sports event management software that works with your budget.',
    pricingText2: 'Our pricing for our events software is laid out up front and all inclusive. This includes all event setup, employee training and platform support on an ongoing basis. Even better, we can work on a monthly contract basis, so you can cancel anytime you wish. Pitchbooking.com - like having your own virtual events manager.',
    trustedHeader: 'Tried and Trusted',
    trustedText: 'Hundreds of clubs and organisations are already using our platform to create and manage their events, saving time and offering a fantastic online booking service to their customers. We work with local and city councils, community clubs, schools, universities, charities, voluntary organisations and a range of other sports associations to provide market leading event booking software.',
    speakToUsText: 'If you would like to speak to us, please click the button below',
    SEOTitle: 'Online bookings and payments for sports events',
    SEODescription: 'Pitchbooking helps you manage your sports event bookings and payments with our online software. Allow your customers to book online while you can manage your events on any device 24/7',
    SEOOGURL: 'https://pitchbooking.com/events',
    SEOOGTitle: 'Online bookings and payments for sports events',

  },
];

// eslint-disable-next-line react/no-children-prop
const Default = ({ children }) => <Responsive minWidth={120} children={children} />;
const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const SolutionEvents = (props) => {
  const { match } = props;
  const paramCode = match?.params.code;
  const code = paramCode ? paramCode.toUpperCase() : 'DEFAULT';
  const version = data.some((d) => d.code === code) ? code : 'DEFAULT';
  const versionData = data.find((d) => d.code === version);
  return (
    <Default>
      {/* <div className="solutionHeader triangles" style={{ backgroundImage: playerDevice }}> */}
      <div className="solutionHeader triangles">
        <h1>
          {versionData.header}
          {' '}
        </h1>
        <div className="solutionSubHeader">
          <h2 style={{ fontSize: '1rem', fontWeight: '400' }}>
            {versionData.subheader}
          </h2>
          <h2 style={{ fontSize: '1rem', fontWeight: '400' }}>
            {versionData.subheader2}
          </h2>
          {/* <p>{schoolFacilitiesSubheader}</p> */}
        </div>
        {/* <div className="ctaButton">
          <ClaimFacilityDialog
            council
            phone="00000"
            facility={{}}
            name="name"
            about="description"
            buttonTitle="Get Started"
            raised
            solution
            source="Events - Get Started"
          />
        </div> */}
        {/* <div className="ctaButtonMobile">
          <ClaimFacilityDialog
            council
            phone="00000"
            facility={{}}
            name="name"
            about="description"
            buttonTitle="Get Started"
            raised
            mobileGreen
            solution
            source="Get Started."
          />
        </div> */}
        <div className="diagonal" />
        {/* <img
          className="solutionHeroImage autoAspectRatio"
          height="3"
          width="7"
          src={playerDevice}
          alt="pitchbooking-players"
          style={{ width: '50%' }}
        /> */}
      </div>
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <div style={{
          maxWidth: '50%',
        }}
        >
          <SalesEnquiryDialog
            council
            raised
            events
            phone="00000"
            facility={{}}
            name="name"
            about="description"
            buttonTitle="Get Started with Events"
            hostFacility
            solution
            source="Manager Events"
          />
        </div>
      </div>
      <div className="doublediagonal">
        {/* <CurrentEvents /> */}
        <div className="solutionFeatures" style={{ borderBottom: '1px solid #fff' }}>
          <div className="solutionFeat">
            <img width="100" height="100" alt={versionData.solutionFeatureTitle1} src="/img/more-bookings-alt.svg" />
            <h2>{versionData.solutionFeatureTitle1}</h2>
            <p>{versionData.solutionFeature1}</p>
          </div>
          <div className="solutionFeat">
            <img width="100" height="100" alt="occ" src="/img/chasing-payment-alt.svg" className="cardPaymentSolution" />
            <h2>{versionData.solutionFeatureTitle2}</h2>
            <p>{versionData.solutionFeature2}</p>
          </div>
          <div className="solutionFeat">
            <img width="100" height="100" alt="occ" src="/img/save-time.svg" className="dashboardSolution" />
            <h2>{versionData.solutionFeatureTitle3}</h2>
            <p>{versionData.solutionFeature3}</p>
          </div>
          <p>{versionData.solutionParagraph}</p>
        </div>
        <div />
        <div>
          <div className="solutionSubFeatures">
            <div className="solutionSubFeat">
              <EventAvailableIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader1}</h3>
              <p>{versionData.featureList1}</p>
            </div>
            <div className="solutionSubFeat">
              <FormatListNumberedIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader2}</h3>
              <p>{versionData.featureList2}</p>
            </div>
            <div className="solutionSubFeat">
              <AssignmentIndIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader3}</h3>
              <p>{versionData.featureList3}</p>
            </div>
            <div className="solutionSubFeat">
              <PaymentIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader4}</h3>
              <p>{versionData.featureList4}</p>
            </div>
            <div className="solutionSubFeat">
              <TimerIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader5}</h3>
              <p>{versionData.featureList5}</p>
            </div>
            <div className="solutionSubFeat">
              <AccountBalanceIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader6}</h3>
              <p>{versionData.featureList6}</p>
            </div>
            <div className="solutionSubFeat">
              <NewReleasesIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader7}</h3>
              <p>{versionData.featureList7}</p>
            </div>
            <div className="solutionSubFeat">
              <GroupAddIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader8}</h3>
              <p>{versionData.featureList8}</p>
            </div>
            <div className="solutionSubFeat">
              <PhoneIphoneIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader9}</h3>
              <p>{versionData.featureList9}</p>
            </div>
            <div className="solutionSubFeat">
              <DoneOutlineIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader10}</h3>
              <p>{versionData.featureList10}</p>
            </div>
            <div className="solutionSubFeat">
              <InsertChartIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader11}</h3>
              <p>{versionData.featureList11}</p>
            </div>
            <div className="solutionSubFeat">
              <ContactSupportIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader12}</h3>
              <p>{versionData.featureList12}</p>
            </div>
          </div>
          <div style={{ display: 'flex', 'justify-content': 'center' }}>
            {/* <div className="ctaButton">
              <ClaimFacilityDialog
                council
                phone="00000"
                facility={{}}
                name="Id liked to know more"
                about="description"
                buttonTitle="Discover our features"
                black
                solution
                source="Events - Discover our features"
              />
            </div> */}
          </div>
        </div>
        <div className="sliderSolutionFeatures">
          <Slider {...settings}>
            <div className="solutionSubFeat">
              <EventAvailableIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader1}</h3>
              <p>{versionData.featureList1}</p>
            </div>
            <div className="solutionSubFeat">
              <FormatListNumberedIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader2}</h3>
              <p>{versionData.featureList2}</p>
            </div>
            <div className="solutionSubFeat">
              <AssignmentIndIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader3}</h3>
              <p>{versionData.featureList3}</p>
            </div>
            <div className="solutionSubFeat">
              <PaymentIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader4}</h3>
              <p>{versionData.featureList4}</p>
            </div>
            <div className="solutionSubFeat">
              <TimerIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader5}</h3>
              <p>{versionData.featureList5}</p>
            </div>
            <div className="solutionSubFeat">
              <AccountBalanceIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader6}</h3>
              <p>{versionData.featureList6}</p>
            </div>
            <div className="solutionSubFeat">
              <NewReleasesIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader7}</h3>
              <p>{versionData.featureList7}</p>
            </div>
            <div className="solutionSubFeat">
              <GroupAddIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader8}</h3>
              <p>{versionData.featureList8}</p>
            </div>
            <div className="solutionSubFeat">
              <PhoneIphoneIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader9}</h3>
              <p>{versionData.featureList9}</p>
            </div>
            <div className="solutionSubFeat">
              <DoneOutlineIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader10}</h3>
              <p>{versionData.featureList10}</p>
            </div>
            <div className="solutionSubFeat">
              <InsertChartIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader11}</h3>
              <p>{versionData.featureList11}</p>
            </div>
            <div className="solutionSubFeat">
              <ContactSupportIcon style={{ color: '#fff', fontSize: '2.5em' }} />
              <h3>{versionData.featureListHeader12}</h3>
              <p>{versionData.featureList12}</p>
            </div>
          </Slider>
          {/* <div className="ctaButtonMobile">
            <ClaimFacilityDialog
              council
              phone="00000"
              facility={{}}
              name="name"
              about="description"
              buttonTitle="I'd like to know more"
              mobileBlack
              solution
              source="I'd like to know more"
            />
          </div> */}
        </div>
      </div>
      <div className="pricingBlock">
        <div className="pricingHeader">
          <h1>{versionData.pricingHeader}</h1>
        </div>
        <div className="solutionSubHeader">
          <p>
            {versionData.pricingText1}
          </p>
          <p>
            {versionData.pricingText2}
          </p>
          <br />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'left' }}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon style={{ fill: '#24dc89', fontSize: '48px' }} />
                </ListItemIcon>
                <ListItemText
                  primary="  No hidden charges, unexpected bills or support fees"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon style={{ fill: '#24dc89', fontSize: '48px' }} />
                </ListItemIcon>
                <ListItemText
                  primary="  Short term contracts available"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon style={{ fill: '#24dc89', fontSize: '48px' }} />
                </ListItemIcon>
                <ListItemText
                  primary="  No cancellation or exit fees tying you in"
                />
              </ListItem>
            </List>
          </div>
          <br />
        </div>

        {/* <div className="solutionFeatures">
          <div className="solutionTestimonialText">
            <p className="solutionTestimonialQuote">
              “For me, it’s about saving time.
              Customers can book the pitch and make the payment themselves.”
            </p>
            <img className="testimonialFace lazyload" width="100px"
            height="100px" alt="testimonial-michael" data-src="/img/michael.jpg" />
            <strong>Michael Woods</strong>
            <p>Parks Officer at Oxford City Council</p>
          </div>
          <div className="solutionTestimonialText">
            <p className="solutionTestimonialQuote">
              “I could not go back to the old process of booking.
              I only use Pitchbooking now to book our weekly slots.”
            </p>
            <img className="testimonialFace lazyload" width="100px"
            height="100px" alt="testimonial-jasdeep" data-src="/img/jasdeep.jpg" />
            <strong>Jasdeep Kalsi</strong>
            <p>Keble College Oxford Captain</p>
          </div>
        </div> */}
        {/* <BlogHero solution /> */}
      </div>
      {/* <div style={{ width: '100%' }}>
        <img width="10" height="3" className="lazyload
        autoAspectRatio" alt="pitchbooking" src={backgroundStock} />
      </div> */}
    </Default>
  );
};

export default SolutionEvents;
