/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const fullWeekSchedules = [
  { day: '1', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
  { day: '2', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
  { day: '3', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
  { day: '4', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
  { day: '5', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
  { day: '6', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
  { day: '0', start: moment('09:00', 'HH:mm'), end: moment('22:00', 'HH:mm') },
];

const WeekdayTimesList = ({
  onChange, openingTimes,
}) => {
  const [updatedOpeningTimes, setUpdatedOpeningTimes] = useState(openingTimes);

  useEffect(() => {
    if (openingTimes) {
      onChange(openingTimes);
    } else {
      onChange(fullWeekSchedules);
    }
  }, [openingTimes]);

  const handleChange = (value, type, index) => {
    const newOpeningTimes = [...updatedOpeningTimes];
    const updatedTime = { ...newOpeningTimes[index] };

    if (type === 'start') {
      updatedTime.start = value.format('HH:mm:ss');
    } else if (type === 'end') {
      updatedTime.end = value.format('HH:mm:ss');
    }

    newOpeningTimes[index] = updatedTime;
    setUpdatedOpeningTimes(newOpeningTimes);
    onChange(newOpeningTimes);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          gap: '1rem',
          margin: '1.5rem 0',
        }}
      >
        {((updatedOpeningTimes || fullWeekSchedules).map((time, index) => (
          <div
            style={{ display: 'flex', gap: '1.5rem' }}
            key={time.id}
          >
            <Typography variant="body1">
              {moment().day(time.day).format('dddd')}
            </Typography>

            <TimePicker
              value={moment(time.start, 'HH:mm')}
              showSecond={false}
              minuteStep={5}
              clearIcon={null}
              onChange={(value) => handleChange(value, 'start', index)}
              style={{ width: '4rem' }}
            />

            <Typography variant="body1"> - </Typography>

            <TimePicker
              value={moment(time.end, 'HH:mm')}
              showSecond={false}
              minuteStep={5}
              clearIcon={null}
              onChange={(value) => handleChange(value, 'end', index)}
              style={{ width: '4rem' }}
            />
          </div>
        )))}
      </div>
    </>
  );
};

WeekdayTimesList.propTypes = {
  onChange: PropTypes.func.isRequired,
  openingTimes: PropTypes.array.isRequired,
};

export default WeekdayTimesList;
