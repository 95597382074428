import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Responsive from 'react-responsive';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import TimetableInitialiser from '@pitchbooking-dev/pb-shared/lib/components/timetable/TimetableInitialiser';
import TimetableSelectedTimeslots from '@pitchbooking-dev/pb-shared/lib/components/timetable/TimetableSelectedTimeslots';
import * as actions from '../../../../reducers/subscriptionsReducer';
import * as facilitiesActions from '../../../../reducers/facilitiesReducer';
import * as reservationsActions from '../../../../reducers/reservationsReducer';
import MenuHeading from '../../../../components/MenuHeading';
import CompanyFacilityPicker from '../../../../components/CompanyFacilityPicker';

class BlockBookingStepperTimeslotPicker extends Component {
  constructor(props) {
    super(props);
    this.updateFacility = this.updateFacility.bind(this);
    this.updateSubFacility = this.updateSubFacility.bind(this);
  }

  componentWillUnmount() {
    const {
      updateSubscriptionStore,
      selectedTimeSlots,
      priceForSlots,
      timezone,
    } = this.props;
    if (selectedTimeSlots.length > 1) {
      const validFrom = moment(selectedTimeSlots[0].startTime).tz(timezone).startOf('day');
      const allTimeSlotStartAndEndTimes = [];
      selectedTimeSlots.forEach(
        (timeSlot) => allTimeSlotStartAndEndTimes.push(
          moment(timeSlot.startTime),
        )
          && allTimeSlotStartAndEndTimes.push(moment(timeSlot.endTime)),
      );
      const earliestStartTime = moment.min(allTimeSlotStartAndEndTimes);
      const latestEndTime = moment.max(allTimeSlotStartAndEndTimes);
      updateSubscriptionStore({
        validFrom,
        startTimeFormatted: moment(earliestStartTime).tz(timezone),
        endTimeFormatted: moment(latestEndTime).tz(timezone),
        weekday: moment(validFrom).day(),
        amount: priceForSlots,
      });
    }
    if (selectedTimeSlots[0] && selectedTimeSlots.length === 1) {
      const validFrom = moment(selectedTimeSlots[0].startTime).tz(timezone).startOf('day');
      const startTimeFormatted = moment(selectedTimeSlots[0].startTime).tz(timezone);
      const endTimeFormatted = moment(selectedTimeSlots[0].endTime).tz(timezone);
      updateSubscriptionStore({
        validFrom,
        startTimeFormatted,
        endTimeFormatted,
        weekday: moment(validFrom).day(),
        amount: priceForSlots,
      });
    }
  }

  updateFacility = (facility) => {
    const {
      updateSubscriptionStore, updateSelectedFacility,
      resetSelectedDuration,
    } = this.props;
    resetSelectedDuration();
    updateSubscriptionStore({ facilityId: facility.id });
    updateSelectedFacility(facility);
  };

  updateSubFacility = (facility) => {
    const {
      updateSubscriptionStore, updateSelectedSubFacility,
      resetSelectedDuration,
    } = this.props;
    resetSelectedDuration();
    updateSubscriptionStore({ facilityId: facility.id });
    updateSelectedSubFacility(facility);
  };

  render() {
    const {
      searchDate, selectedFacility, selectedSubFacility, companyId, pitchSplit,
    } = this.props;

    const {
      splitType,
    } = selectedFacility;
    const facilityId = selectedFacility.id;

    let errorMessage;

    let selectedPitchSplit = pitchSplit;
    let subFacilityId = null;

    if (selectedSubFacility && selectedSubFacility.id) {
      selectedPitchSplit = 1;
      subFacilityId = selectedSubFacility.id;
    }

    const renderTimeTableWrapper = () => (
      <div className="reservation-creation-timetable">
        <br />
        <div className="reservation-creation-timetable-picker">
          <TimetableInitialiser
            facilityInformation={{
              facilityId,
              subFacilityId,
              companyId,
              pitchSplit: selectedPitchSplit,
            }}
            searchDate={searchDate}
            manager
            blockBooking
          />
        </div>
        <Responsive minDeviceWidth={992}>
          <div className="reservation-creation-timetable-basket">
            <TimetableSelectedTimeslots combineSlots />
          </div>
        </Responsive>
      </div>
    );

    return (
      <div>
        <CompanyFacilityPicker
          updateSelectedFacility={this.updateFacility}
          updateSelectedSubFacility={this.updateSubFacility}
        />
        <ConditionallyVisible condition={facilityId}>
          <ConditionallyVisible condition={(splitType && ((splitType !== 'AUTOMATIC_SELECTION') && (splitType !== 'HIDDEN_AUTOMATIC_SELECTION'))) || selectedSubFacility}>
            <div>
              <MenuHeading title="Choose the first slot of the block booking:" />
              <br />
              {renderTimeTableWrapper()}
            </div>
          </ConditionallyVisible>
        </ConditionallyVisible>
        <br />
        <div style={{ color: '#ff0000' }}>
          {errorMessage}
        </div>
      </div>
    );
  }
}

BlockBookingStepperTimeslotPicker.propTypes = {
  companyId: PropTypes.string.isRequired,
  selectedFacility: PropTypes.shape().isRequired,
  selectedSubFacility: PropTypes.shape().isRequired,
  updateSubscriptionStore: PropTypes.func.isRequired,
  updateSelectedFacility: PropTypes.func.isRequired,
  updateSelectedSubFacility: PropTypes.func.isRequired,
  selectedTimeSlots: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  priceForSlots: PropTypes.string.isRequired,
  searchDate: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  pitchSplit: PropTypes.number.isRequired,
  resetSelectedDuration: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  facilities: state.facilities.companyFacilities,
  companyId: state.companies.companyInfo.id,
  selectedFacility: state.facilities.selectedFacility,
  selectedSubFacility: state.facilities.selectedSubFacility,
  subscriptions: state.subscriptions,
  selectedTimeSlots: state.timetable.selectedTimeslots,
  priceForSlots: state.timetable.priceForSlots,
  searchDate: state.timetable.searchDate,
  timezone: state.timetable.timetableSlots.timezone,
  selectedDuration: state.reservation.selectedDuration,
  pitchSplit: state.booking.selectedPitchSplit,
});

const mapDispatchToProps = (dispatch) => ({
  updateSubscriptionStore: (keyWithValue) => dispatch(
    actions.updateSubscriptionStore(keyWithValue),
  ),
  updateSelectedDuration: (duration) => dispatch(
    reservationsActions.updateSelectedDuration(duration),
  ),
  resetSelectedDuration: () => dispatch(reservationsActions.resetSelectedDuration()),
  updateSelectedFacility: (selectedFacility) => dispatch(
    facilitiesActions.requestFacilityRetrieval(selectedFacility.id),
  ),
  updateSelectedSubFacility: (selectedSubFacility) => dispatch(
    facilitiesActions.updateSelectedSubFacility(selectedSubFacility),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockBookingStepperTimeslotPicker);
