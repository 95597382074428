import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PropTypes, { element } from 'prop-types';
import Paper from '@material-ui/core/Paper';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableContainer from '@material-ui/core/TableContainer';
import Checkbox from '@material-ui/core/Checkbox';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import MaterialTable from 'material-table';
import ScheduleAddNewSetOpeningTimesDialog from './ScheduleAddNewSetOpeningTimesDialog';
import WeekScheduleTable from '../WeekScheduleTable';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as scheduleActions from '../../../reducers/schedulesReducer';
import 'rc-time-picker/assets/index.css';
import ActionBar from '../../../components/ActionBar';

const ScheduleGroupEditDialog = ({
  facility,
  schedules,
  changesMadeToScheduleGroup,
  fullWeekSchedules,
  savedNewOpeningTimes,
  scheduleGroup,
  toggledEditingScheduleGroupOpeningTimes,
  resetScheduleGroupCreation,
  editingScheduleGroup,
  updateSchedulesStore,
  getSchedules,
  resetScheduleGroupStore,
  createFullWeekSchedules,
  updateScheduleGroupStore,
  requestFacilityScheduleSwap,
  selectedSchedule,
}) => {
  const [scheduleGroupDialogOpen, setScheduleGroupDialogOpen] = useState(false);
  const [selectedScheduleIds, setSelectedScheduleIds] = useState(null);

  const handleClickOpen = () => {
    setScheduleGroupDialogOpen(true);
  };

  const handleChange = (row) => {
    if (!row.checked) {
      setSelectedScheduleIds(row.facilityIdsString);
      updateScheduleGroupStore({ schedules: row.schedules });
    } else {
      setSelectedScheduleIds(null);
      resetScheduleGroupStore();
    }

    updateSchedulesStore({ checked: !row.checked }, row.facilityIdsString);
  };

  const handleRequestClose = () => {
    if (editingScheduleGroup) {
      toggledEditingScheduleGroupOpeningTimes();
    }

    resetScheduleGroupCreation();
    setScheduleGroupDialogOpen(false);
  };

  const handleRequestToCancel = () => {
    handleRequestClose();
    getSchedules();
  };

  const handleSave = (id) => {
    if (scheduleGroup.schedules.length > 0) {
      requestFacilityScheduleSwap(id);
      handleRequestClose();
      return;
    }
    createFullWeekSchedules(id);
    handleRequestClose();
  };

  const facilityTag = (row) => (
    <div style={{
      display: 'flex', flex: 'wrap', width: '100%', margin: '4px', alignItems: 'center',
    }}
    >
      <div style={{
        marginRight: '15px', width: '250px', backgroundColor: '#CFE2F3', padding: '8px', border: '1px solid #333', borderRadius: '7px',
      }}
      >
        {row.name}
      </div>
    </div>
  );

  const isMobile = window.innerWidth < 768;

  return (
    <div>
      <div>
        <Button
          onClick={handleClickOpen}
          color="secondary"
        >
          <SwapVerticalCircleIcon fontSize="large" />
        </Button>
      </div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={scheduleGroupDialogOpen}
        fullScreen={isMobile}
      >
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <ActionBar>
            <ScheduleAddNewSetOpeningTimesDialog
              selectedSchedule={selectedSchedule}
              open={scheduleGroupDialogOpen}
            />
          </ActionBar>

          <div style={{ marginRight: '2rem' }}>
            <CloseDialogIcon onClick={handleRequestToCancel} />
          </div>
        </DialogActions>

        <DialogContent>

          <ConditionallyVisible condition={savedNewOpeningTimes}>
            <div style={{
              margin: '20px',
              padding: '20px',
              borderRadius: '15px',
              border: '1px solid #e0e0e0',
              backgroundColor: '#f8f8f8',
            }}
            >

              <Typography variant="h6" gutterBottom>
                New Schedule Group
              </Typography>

              <div style={{ display: 'flex', marginBottom: '1rem' }}>
                {/* Day */}
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.25rem',
                  alignItems: 'end',
                }}
                >
                  <Typography variant="body1" style={{ textDecoration: 'underline' }}>
                    Day
                  </Typography>
                  {fullWeekSchedules.map((element) => (
                    <Typography variant="body2">
                      {`${moment().day(element.day).format('dddd')} : `}
                    </Typography>
                  ))}
                </div>

                {/* Start Time */}
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.25rem',
                  marginLeft: '1rem',
                  alignItems: 'end',
                }}
                >
                  <Typography variant="body1" style={{ textDecoration: 'underline' }}>
                    Open
                  </Typography>
                  {fullWeekSchedules.map((time) => (
                    <Typography variant="body2">
                      {moment(time.start, 'HH:mm').format('HH:mm')}
                    </Typography>
                  ))}
                </div>

                {/* End Time */}
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.25rem',
                  marginLeft: '1rem',
                  alignItems: 'end',
                }}
                >
                  <Typography variant="body1" style={{ textDecoration: 'underline' }}>
                    Close
                  </Typography>
                  {fullWeekSchedules.map((time) => (
                    <Typography variant="body2">
                      { moment(time.end, 'HH:mm').format('HH:mm') }
                    </Typography>
                  ))}
                </div>
              </div>
            </div>

          </ConditionallyVisible>
          <div className="table-section">
            <TableContainer
              style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden' }}
              component={Paper}
            >
              <MaterialTable
                data={schedules}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
                title={`Choose a different set of opening times for ${facility.name}`}
                icons={{
                  NextPage: () => <ChevronRight />,
                  PreviousPage: () => <ChevronLeft />,
                  Search: () => <Search />,
                  Export: () => <SaveAlt />,
                  ResetSearch: () => <Clear />,
                  SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
                }}
                columns={[
                  {
                    title: 'Selection',
                    cellStyle: {
                      maxWidth: 100,
                    },
                    headerStyle: {
                      maxWidth: 100,
                    },
                    render: (row) => (
                      <div style={{
                        width: '100px',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                      >
                        <ConditionallyVisible
                          condition={!selectedScheduleIds
                            || row.facilityIdsString === selectedScheduleIds}
                        >
                          <Checkbox
                            checked={row.checked}
                            onChange={() => handleChange(row)}
                            name="checkedB"
                            color="secondary"
                          />
                        </ConditionallyVisible>
                      </div>
                    ),
                  },
                  {
                    title: 'Facilities',
                    render: (row) => (
                      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        {row.facilities.map((f) => facilityTag(f))}
                      </div>
                    ),
                  },
                  {
                    title: 'Opening Times',
                    render: (row) => (
                      <WeekScheduleTable schedules={row.schedules} />
                    ),
                  },
                ]}
                options={{
                  search: false,
                  emptyRowsWhenPaging: false,
                  paging: false,
                  showFirstLastPageButtons: false,
                  tableLayout: 'fixed',
                  headerStyle: {
                    backgroundColor: '#efefef',
                    fontWeight: 'bold',
                  },
                }}
              />
            </TableContainer>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestToCancel} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button disabled={!changesMadeToScheduleGroup && scheduleGroup.schedules.length <= 0} onClick={() => handleSave(facility.id)} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ScheduleGroupEditDialog.propTypes = {
  scheduleGroup: PropTypes.shape().isRequired,
  facility: PropTypes.shape().isRequired,
  schedules: PropTypes.shape().isRequired,
  toggledEditingScheduleGroupOpeningTimes: PropTypes.func.isRequired,
  editingScheduleGroup: PropTypes.func.isRequired,
  updateSchedulesStore: PropTypes.func.isRequired,
  getSchedules: PropTypes.func.isRequired,
  resetScheduleGroupCreation: PropTypes.func.isRequired,
  changesMadeToScheduleGroup: PropTypes.bool.isRequired,
  savedNewOpeningTimes: PropTypes.bool.isRequired,
  createFullWeekSchedules: PropTypes.func.isRequired,
  updateScheduleGroupStore: PropTypes.func.isRequired,
  resetScheduleGroupStore: PropTypes.func.isRequired,
  requestFacilityScheduleSwap: PropTypes.func.isRequired,
  fullWeekSchedules: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedSchedule: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  schedule: state.schedules.schedule,
  scheduleGroup: state.schedules.scheduleGroup,
  scheduleGroupDialogOpen: state.schedules.scheduleGroupDialogOpen,
  changesMadeToScheduleGroup: state.schedules.changesMadeToScheduleGroup,
  editingScheduleGroup: state.schedules.editingScheduleGroup,
  fullWeekSchedules: state.schedules.fullWeekSchedules,
  savedNewOpeningTimes: state.schedules.savedNewOpeningTimes,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getSchedules: () => scheduleActions.getSchedules(),
  toggledEditingScheduleGroupOpeningTimes: (
  ) => scheduleActions.toggledEditingScheduleGroupOpeningTimes(),
  resetScheduleGroupCreation: () => scheduleActions.resetScheduleGroupCreation(),
  resetScheduleGroupStore: () => scheduleActions.resetScheduleGroupStore(),
  requestFacilityScheduleSwap: (id) => scheduleActions.requestFacilityScheduleSwap(id),
  createFullWeekSchedules: (id) => scheduleActions.requestFullWeekSchedulesCreation(
    id,
  ),
  updateSchedulesStore: (value, id) => scheduleActions.updateSchedulesStore(
    value, id,
  ),
  updateScheduleGroupStore: (value) => dispatch(scheduleActions.updateScheduleGroupStore(
    value,
  )),
}, dispatch);

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ScheduleGroupEditDialog);
