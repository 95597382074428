import React from 'react';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import * as helper from '../../helpers/index';
import { useCompany } from '../../hooks';

const priceRuleTag = (row, showId = false) => {
  const { currencySym } = useCompany();

  return (
    <div>
      <div className="rulesTag">
        {helper.generatePriceModifierString(row, currencySym)}
      </div>
      <ConditionallyVisible condition={showId}>
        <div style={{ color: '#a2a2a2' }}>
          {row.id}
        </div>
      </ConditionallyVisible>
    </div>
  );
};

const PriceModifierTag = (props) => {
  const { priceModifier } = props;

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
    }}
    >
      {priceRuleTag(priceModifier)}
    </div>
  );
};

PriceModifierTag.propTypes = {
  priceModifier: PropTypes.shape().isRequired,
};

export default (PriceModifierTag);
