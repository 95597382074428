import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Formik } from 'formik';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  Snackbar,
  DialogContent,
  Divider,
  Tooltip,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import Alert from '@material-ui/lab/Alert';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import PitchbookingUserOnly from '../../helpers/PitchbookingUserOnly';
import { calculateFullPitchbookingPrice } from '../../helpers/index';

const styles = {
  dialogContent: {
    paddingRight: '2.5rem',
    paddingLeft: '2.5rem',
    paddingBottom: '2.5rem',
  },
  dialogTitle: { paddingLeft: '0rem' },
  actionWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '1rem',
  },
  triggerButton: {
    fontWeight: 800,
    width: 225,
  },
};

const EditBillingDialog = (props) => {
  const {
    isSuccess,
    billing,
    updateBilling,
    currencySym,
  } = props;
  const [displaySnackbar, setDisplaySnackbar] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [isFocused, setIsFocused] = useState(false);

  const actionName = billing ? 'Edit' : 'Create';

  const handleOpenClick = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setDisplaySnackbar(false);
  };

  const handleExpiryRequiredChange = (setFieldValue, expiryRequired) => {
    setFieldValue('expiryRequired', expiryRequired);
    if (expiryRequired === false) {
      setFieldValue('expiryDate', null);
    }
  };

  const handleShowBreakdownChange = (setFieldValue, showBreakdown) => {
    setFieldValue('showBreakdown', showBreakdown);
    if (showBreakdown === false) {
      setFieldValue('externalFacilities', null);
      setFieldValue('internalFacilities', null);
    }
  };

  const handleOfferAppliedChange = (setFieldValue, offerApplied) => {
    setFieldValue('offerApplied', offerApplied);
    if (offerApplied === false) {
      setFieldValue('offerAmount', null);
      setFieldValue('offerExpiresAt', null);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleDialogClose();
      setDisplaySnackbar(true);
    }
  }, [isSuccess]);

  const statuses = [
    { value: 'PENDING', label: 'Pending' },
    { value: 'ACCEPTED', label: 'Accepted' },
    { value: 'CONFIRMED', label: 'Confirmed' },
  ];

  return (
    <>
      <PitchbookingUserOnly>
        <Button
          onClick={handleOpenClick}
          variant="contained"
          color="primary"
          style={styles.triggerButton}
        >
          {actionName}
          {' '}
          Agreement
        </Button>
      </PitchbookingUserOnly>
      <Dialog open={open} onClose={handleDialogClose} fullScreen={isMobile} maxWidth="sm">
        <div style={styles.actionWrapper}>
          <CloseDialogIcon style={styles.closeDialogIcon} onClick={handleDialogClose} />
        </div>
        <DialogContent style={styles.dialogContent}>
          <DialogTitle style={styles.dialogTitle}>
            {actionName}
            {' '}
            Agreement
          </DialogTitle>
          <DialogContentText>
            Edit the deal by changing the fields below.
          </DialogContentText>
          <Formik
            initialValues={{
              note: billing?.note || '',
              expiryDate: billing?.expiryDate || null,
              setFirstPaymentDate: !!billing?.firstPaymentDate,
              firstPaymentDate: billing?.firstPaymentDate || null,
              showBreakdown: billing?.showBreakdown || false,
              bulkDiscount: billing?.bulkDiscount || false,
              externalFacilities: billing?.externalFacilities || null,
              internalFacilities: billing?.internalFacilities || null,
              discount: billing?.discount || 0,
              required: billing?.required ?? true,
              status: billing?.status || 'PENDING',
              expiryRequired: !!billing?.expiryDate || false,
              offerExpiresAt: billing?.offerExpiresAt || null,
              offerAmount: billing?.offerAmount || null,
              offerTitle: billing?.offerTitle || null,
              offerNote: billing?.offerNote || null,
              offerApplied: !!billing?.offerExpiresAt || false,
              gameOn: false,
              amount: billing?.amount || 0,
            }}
            enableReinitialize
            onSubmit={async (values, { setSubmitting }) => {
              const form = {
                ...values,
              };
              setSubmitting(true);

              try {
                delete form.expiryRequired;
                delete form.offerApplied;
                updateBilling(form);
                setOpen(false);
                // resetForm();
              } catch (err) {
                dispatch(sessionActions.updateErrorSnackbar(
                  'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us at info@pitchbooking.com.',
                  true,
                ));
              }
            }}
          >
            {({
              values, errors, touched, handleSubmit,
              handleChange, resetForm, setFieldValue, handleBlur,
            }) => {
              useEffect(() => {
                if (values.showBreakdown) {
                  const newAmount = calculateFullPitchbookingPrice(
                    values.externalFacilities,
                    values.internalFacilities,
                    values.bulkDiscount,
                    values.discount,
                  );
                  setFieldValue('amount', newAmount);
                }
              }, [
                values.externalFacilities,
                values.internalFacilities,
                values.bulkDiscount,
                values.discount,
              ]);

              return (
                <form
                  onSubmit={handleSubmit}
                  onReset={resetForm}
                  style={{
                    display: 'flex', flexDirection: 'column', gap: '0.75rem',
                  }}
                >
                  <PBInput
                    id="isGameOn"
                    type="toggle"
                    label="Is this a game on agreement"
                    value={values.gameOn}
                    onChange={(e) => setFieldValue('gameOn', e.target.checked)}
                    isError={touched.gameOn && errors.gameOn}
                    errorMessage={touched.gameOn && errors.gameOn}
                  />
                  {!values.gameOn && (
                    <>
                      <PBInput
                        type="textarea"
                        id="note"
                        label="Agreement Note"
                        value={values.note}
                        onChange={handleChange}
                        isError={touched.note && Boolean(errors.note)}
                        errorMessage={touched.note && errors.note}
                      />
                      <p>If company has completed billing through self service set as accepted</p>
                      <PBInput
                        id="status"
                        type="select"
                        label="Status"
                        options={statuses.map((c) => ({ value: c.value, label: c.label }))}
                        value={values.status}
                        onChange={handleChange}
                        required
                        onBlur={handleBlur}
                        isError={errors.status && touched.status}
                        errorMessage={errors.status}
                      />
                      <PBInput
                        id="expiryRequired"
                        type="toggle"
                        label="Set Expiry Date"
                        value={values.expiryRequired}
                        onChange={
                      () => handleExpiryRequiredChange(setFieldValue, !values.expiryRequired)
}
                        isError={touched.expiryRequired && errors.expiryRequired}
                        errorMessage={touched.expiryRequired && errors.expiryRequired}
                      />
                      <ConditionallyVisible condition={values.expiryRequired || values.expiryDate}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p style={{ marginRight: '10px' }}>Agreement Expires At</p>
                          <SingleDatePicker
                            date={values.expiryDate ? moment(values.expiryDate) : moment()}
                            onDateChange={(date) => {
                              setFieldValue('expiryDate', moment(date).format('YYYY-MM-DD HH:mm'));
                            }}
                            focused={isFocused}
                            onFocusChange={({ focused }) => setIsFocused(focused)}
                            displayFormat="ddd, DD/MM/YY"
                            numberOfMonths={1}
                            isOutsideRange={() => false}
                            fullWidth
                            noBorder
                          />
                          <Tooltip title="If set, the invited managers will receive reminder emails of their agreement." placement="right">
                            <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '15px' }} />
                          </Tooltip>
                        </div>
                      </ConditionallyVisible>

                      <div style={{ display: 'flex', alignItems: 'center' }}>

                        <PBInput
                          id="setFirstPaymentDate"
                          type="toggle"
                          label="Set First Payment Date"
                          value={values.setFirstPaymentDate}
                          onChange={() => setFieldValue('setFirstPaymentDate', !values.setFirstPaymentDate)}
                          isError={touched.setFirstPaymentDate && errors.setFirstPaymentDate}
                          errorMessage={touched.setFirstPaymentDate && errors.setFirstPaymentDate}
                        />
                        {values.setFirstPaymentDate && (
                        <SingleDatePicker
                          date={values.firstPaymentDate
                            ? moment(values.firstPaymentDate) : null}
                          onDateChange={(date) => {
                            setFieldValue('firstPaymentDate', moment(date).format('YYYY-MM-DD HH:mm'));
                          }}
                          focused={isFocused}
                          onFocusChange={({ focused }) => setIsFocused(focused)}
                          displayFormat="ddd, DD/MM/YY"
                          numberOfMonths={1}
                          isOutsideRange={() => false}
                          fullWidth
                          noBorder
                        />
                        )}
                        <Tooltip title="If set, the invited managers will receive reminder emails of their first payment date." placement="right">
                          <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '15px' }} />
                        </Tooltip>
                      </div>
                      <Divider style={{ marginTop: '15px' }} className="divider" />
                      <PBInput
                        id="showBreakdown"
                        type="toggle"
                        label="Calculate Price"
                        value={values.showBreakdown}
                        onChange={() => handleShowBreakdownChange(
                          setFieldValue, !values.showBreakdown,
                        )}
                        isError={touched.showBreakdown && errors.showBreakdown}
                        errorMessage={touched.showBreakdown && errors.showBreakdown}
                      />
                      {values.showBreakdown ? (
                        <>
                          <PBInput
                            id="externalFacilities"
                            type="number"
                            label="External Facilities"
                            value={values.externalFacilities}
                            onChange={handleChange}
                            isError={touched.externalFacilities && errors.externalFacilities}
                            errorMessage={touched.externalFacilities && errors.externalFacilities}
                            required
                          />
                          <PBInput
                            id="internalFacilities"
                            type="number"
                            label="Internal Facilities"
                            value={values.internalFacilities}
                            onChange={handleChange}
                            isError={touched.internalFacilities && errors.internalFacilities}
                            errorMessage={touched.internalFacilities && errors.internalFacilities}
                            required
                          />
                          <PBInput
                            id="discount"
                            type="number"
                            label="Discount"
                            value={values.discount}
                            onChange={handleChange}
                            isError={touched.discount && errors.discount}
                            errorMessage={touched.discount && errors.discount}
                            required
                          />
                          <PBInput
                            id="bulkDiscount"
                            type="toggle"
                            label="Apply Bulk Discount"
                            value={values.bulkDiscount}
                            onChange={handleChange}
                            isError={touched.bulkDiscount && errors.bulkDiscount}
                            errorMessage={touched.bulkDiscount && errors.bulkDiscount}
                          />
                          <Typography>{`Calculated Price: ${currencySym}${values?.amount ? values?.amount?.toFixed(2) : 0}`}</Typography>
                        </>
                      ) : (
                        <PBInput
                          id="amount"
                          type="number"
                          label="Set Price"
                          value={values.amount}
                          onChange={handleChange}
                          isError={touched.amount && errors.amount}
                          errorMessage={touched.amount && errors.amount}
                          required
                        />
                      )}
                      <Divider style={{ marginTop: '15px' }} className="divider" />
                      <PBInput
                        id="offerApplied"
                        type="toggle"
                        label="Apply Offer"
                        value={values.offerApplied}
                        onChange={() => handleOfferAppliedChange(
                          setFieldValue, !values.offerApplied,
                        )}
                        isError={touched.offerApplied && errors.offerApplied}
                        errorMessage={touched.offerApplied && errors.offerApplied}
                      />
                      <ConditionallyVisible
                        condition={values.offerApplied || values.offerExpiresAt}
                      >
                        <PBInput
                          type="text"
                          id="offerTitle"
                          label="Offer Title"
                          value={values.offerTitle}
                          onChange={handleChange}
                          isError={touched.offerTitle && Boolean(errors.offerTitle)}
                          errorMessage={touched.offerTitle && errors.offerTitle}
                        />
                        <PBInput
                          id="offerAmount"
                          type="number"
                          label="Offer Amount"
                          value={values.offerAmount}
                          onChange={handleChange}
                          isError={touched.offerAmount && errors.offerAmount}
                          errorMessage={touched.offerAmount && errors.offerAmount}
                          required
                        />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p style={{ marginRight: '10px' }}>Offer Expires At</p>
                          <SingleDatePicker
                            date={values.offerExpiresAt ? moment(values.offerExpiresAt) : moment()}
                            onDateChange={(date) => {
                              setFieldValue('offerExpiresAt', moment(date).format('YYYY-MM-DD HH:mm'));
                            }}
                            focused={isFocused}
                            onFocusChange={({ focused }) => setIsFocused(focused)}
                            displayFormat="ddd, DD/MM/YY"
                            numberOfMonths={1}
                            isOutsideRange={() => false}
                            fullWidth
                            noBorder
                          />
                        </div>
                        <PBInput
                          type="textarea"
                          id="offerNote"
                          label="Offer Note"
                          value={values.offerNote}
                          onChange={handleChange}
                          isError={touched.offerNote && Boolean(errors.offerNote)}
                          errorMessage={touched.offerNote && errors.offerNote}
                        />
                      </ConditionallyVisible>
                    </>
                  )}

                  <div style={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '0.75rem',
                  }}
                  >
                    <Button
                      type="reset"
                      variant="contained"
                      color="secondary"
                      style={{ marginTop: '10px', marginBottom: '10px', width: 200 }}
                      onClick={handleDialogClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ marginTop: '10px', marginBottom: '10px', width: 200 }}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              );
            }}

          </Formik>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={displaySnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Agreement updated successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

EditBillingDialog.propTypes = {
  billing: PropTypes.shape.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  updateBilling: PropTypes.func.isRequired,
  currencySym: PropTypes.string.isRequired,
};

export default EditBillingDialog;
