import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Typography, Button } from '@material-ui/core';
import moment from 'moment';
import { MaterialTable } from '../../components/MaterialTable';
import { Loading } from './_Loading';
import { Error } from './_Error';
import { getCompanies, verifyPendingCompany } from '../../services/companiesServices';
import { RequestInformationModal } from './components/RequestInformationModal';
import { ClaimantChangesModal } from './components/ClaimantChangesModal';
import PitchbookingUserOnly from '../../helpers/PitchbookingUserOnly';

export const Verify = () => {
  const queryClient = useQueryClient();

  const [requestInformationModal, setRequestInformationModal] = React.useState({
    isOpen: false,
    claim: null,
  });
  const [claimantChangesModal, setClaimantChangesModal] = React.useState({
    isOpen: false,
    claim: null,
  });

  const { data, isError, isLoading } = useQuery({
    queryKey: ['companies', 'pending'],
    queryFn: async () => {
      const res = await getCompanies({
        status: 'DIRECTORY_CLAIMED_PENDING_APPROVAL',
      });

      if (res.status !== 200) {
        throw new Error('Error fetching companies');
      }

      const parsed = res.data.filter((x) => x.claimantInformation).reduce((acc, company) => [
        ...acc,
        ...company.claimantInformation.filter((x) => x.status !== 'REJECTED').map((x) => ({
          ...company,
          claimantInformation: x,
        })),
      ], []);

      return parsed;
    },
  });

  const mutation = useMutation({
    mutationFn: async (x) => {
      const res = await verifyPendingCompany(x.companyId, x.data);

      if (res.status !== 201) {
        throw new Error('Error claiming company');
      }

      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['companies', 'pending']);
    },
  });

  if (isLoading) return <Loading />;

  if (isError) return <Error />;

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: '1rem',
    }}
    >
      <Typography variant="h5">
        Verify Requests
      </Typography>
      <PitchbookingUserOnly>
        <MaterialTable
          columns={[
            {
              title: 'Company Name',
              field: 'name',
              sorting: false,
            },
            {
              title: 'Claimant User',
              field: 'claimantInformation.userId',
              render: (row) => (row.claimantInformation ? (
                <>
                  <Typography>{`${row.claimantInformation.firstName} ${row.claimantInformation.lastName}`}</Typography>
                  <Typography variant="body2">{row.claimantInformation.email}</Typography>
                </>
              ) : null),
              sorting: false,
            },
            {
              title: 'Additional Information',
              field: 'claimantInformation.additionalInformation',
              sorting: false,
            },
            {
              title: 'Status',
              field: 'claimantInformation.status',
              sorting: false,
              render: (row) => (
                <span style={{ textTransform: 'capitalize' }}>
                  {row.claimantInformation.status.replace(/_/g, ' ').toLowerCase()}
                </span>
              ),
            },
            {
              title: 'Date Requested',
              render: (row) => (row.claimantInformation ? moment(row.claimantInformation.date).format('DD-MMM-YYYY HH:mm') : null),
            },
            {
              title: '',
              sorting: false,
              render: (row) => (
                <div style={{ display: 'flex', gap: '1rem' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => mutation.mutate({
                      companyId: row.id,
                      data: {
                        status: 'APPROVED',
                        claimantId: row.claimantInformation.id,
                      },
                    })}
                    disabled={mutation.isLoading}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setRequestInformationModal({
                        isOpen: true,
                        claim: row,
                      });
                    }}
                    disabled={mutation.isLoading}
                  >
                    Request
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => mutation.mutate({
                      companyId: row.id,
                      data: {
                        status: 'REJECTED',
                        claimantId: row.claimantInformation.id,
                      },
                    })}
                    disabled={mutation.isLoading}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => setClaimantChangesModal({
                      isOpen: true,
                      claim: row.claimantInformation,
                    })}
                  >
                    Changes
                  </Button>
                </div>
              ),
            },
          ]}
          data={data}
          options={{
            exportButton: false,
            paging: false,
            showTitle: false,
          }}
        />
      </PitchbookingUserOnly>
      <RequestInformationModal
        isOpen={requestInformationModal.isOpen}
        claim={requestInformationModal.claim}
        onSubmit={mutation.mutateAsync}
        onClose={() => setRequestInformationModal({ isOpen: false, claim: null })}
      />
      <ClaimantChangesModal
        isOpen={claimantChangesModal.isOpen}
        claim={claimantChangesModal.claim}
        onClose={() => setClaimantChangesModal({ isOpen: false, claim: null })}
      />
    </div>
  );
};
