import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArraySort from 'array-sort';

export default function LeagueTable(props) {
  const { standings, adjustments } = props;
  ArraySort(standings, ['points', 'goalDifference']).reverse();
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Team</TableCell>
              <TableCell align="right">PL</TableCell>
              <TableCell align="right">W</TableCell>
              <TableCell align="right">D</TableCell>
              <TableCell align="right">L</TableCell>
              <TableCell align="right">GD</TableCell>
              <TableCell align="right">PT</TableCell>
              <TableCell align="right">+/-</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {standings.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{index + 1 }</TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.played}</TableCell>
                <TableCell align="right">{row.won}</TableCell>
                <TableCell align="right">{row.drawn}</TableCell>
                <TableCell align="right">{row.lost}</TableCell>
                <TableCell align="right">{row.goalDifference}</TableCell>
                <TableCell align="right">{row.points}</TableCell>
                <TableCell align="right">{row.adjustments}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <h3>Adjustments</h3>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Team</TableCell>
              <TableCell>Adjustment Type</TableCell>
              <TableCell>Points</TableCell>
              <TableCell>Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adjustments.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.team.name}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.points}</TableCell>
                <TableCell>{row.reason}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

LeagueTable.propTypes = {
  standings: PropTypes.shape().isRequired,
  adjustments: PropTypes.shape().isRequired,
};
