import moment from 'moment';

export const GET_BOOKINGS = 'GET_BOOKINGS';
export const GET_SUBSCRIPTION_BOOKINGS = 'GET_SUBSCRIPTION_BOOKINGS';
export const UPDATE_BOOKINGS = 'UPDATE_BOOKINGS';
export const UPDATE_SUBSCRIPTION_BOOKINGS = 'UPDATE_SUBSCRIPTION_BOOKINGS';
export const CREATE_MANAGER_RESERVATION = 'CREATE_MANAGER_RESERVATION';
export const RESERVATION_UPDATED = 'bookingsReducer/RESERVATION_UPDATED';
export const GET_ALLOCATIONS = 'GET_ALLOCATIONS';
export const UPDATE_ALLOCATIONS = 'UPDATE_ALLOCATIONS';
export const CHANGE_SELECTED_PITCH_SPLIT = 'CHANGE_SELECTED_PITCH_SPLIT';
export const UPDATE_REQUESTED_DATES = 'UPDATE_REQUESTED_DATES';
export const UPDATE_SELECTED_ROWS = 'UPDATE_SELECTED_ROWS';
export const UPDATE_SELECTED_BOOKINGS_AND_SUBSCRIPTIONS = 'UPDATE_SELECTED_BOOKINGS_AND_SUBSCRIPTIONS';
export const RESET_SELECTED_ROWS = 'RESET_SELECTED_ROWS';
export const BOOKING_RETRIEVAL_REQUESTED = 'BOOKING_RETRIEVAL_REQUESTED';
export const BOOKING_RETRIEVAL_SUCCEEDED = 'BOOKING_RETRIEVAL_SUCCEEDED';
export const BOOKING_RETRIEVAL_FAILED = 'BOOKING_RETRIEVAL_FAILED';
export const UPDATE_BOOKING_REQUESTED = 'UPDATE_BOOKING_REQUESTED';
export const UPDATE_BOOKING_SUCCEEDED = 'UPDATE_BOOKING_SUCCEEDED';
export const UPDATE_BOOKING_FAILED = 'UPDATE_BOOKING_FAILED';
export const TOGGLE_BOOKING_FULL_EDIT = 'TOGGLE_BOOKING_FULL_EDIT';
export const TOGGLE_EDIT_BOOKING_DIALOG = 'TOGGLE_EDIT_BOOKING_DIALOG';
export const TOGGLE_EDIT_TIME_SELECTED = 'TOGGLE_EDIT_TIME_SELECTED';
export const BOOKING_EDIT_AND_CREATION_REQUESTED = 'BOOKING_EDIT_AND_CREATION_REQUEST';
export const BOOKING_EDIT_AND_CREATION_SUCCEEDED = 'BOOKING_EDIT_AND_CREATION_SUCCEEDED';

export const BOOKINGS_AND_SUBSCRIPTIONS_RETRIEVAL_REQUESTED = 'BOOKINGS_AND_SUBSCRIPTIONS_RETRIEVAL_REQUESTED';
export const BOOKINGS_AND_SUBSCRIPTIONS_RETRIEVAL_SUCCEEDED = 'BOOKINGS_AND_SUBSCRIPTIONS_RETRIEVAL_SUCCEEDED';
export const UPDATE_SEARCH_OPTIONS_STORE = 'UPDATE_SEARCH_OPTIONS_STORE';
export const UPDATE_STORED_FILTER = 'UPDATE_STORED_FILTER';

export const TOGGLE_SWAP_BOOKING_DIALOG = 'TOGGLE_SWAP_BOOKING_DIALOG';
export const SWAP_BOOKINGS_REQUESTED = 'SWAP_BOOKINGS_REQUESTED';

export const UPDATE_FACILITY_BOOKING_REQUESTED = 'UPDATE_FACILITY_BOOKING_REQUESTED';

export const UPDATE_BOOKINGS_EDIT_SUCCESS = 'UPDATE_BOOKINGS_EDIT_SUCCESS';
export const UPDATE_BOOKINGS_EDIT_FAILED = 'UPDATE_BOOKINGS_EDIT_FAILED';
export const UPDATE_SELECTED_ROWS_PARTIAL_PAYMENT = 'UPDATE_SELECTED_ROWS_PARTIAL_PAYMENT';

export const initialState = {
  bookings: [],
  subscriptionBookings: [],
  storedFilters: {},
  bookingsAndSubscriptions: [],
  dates: {},
  bookingsLoading: false,
  subscriptionBookingsLoading: false,
  selectedRows: [],
  selectedBookingsAndSubscriptions: [],
  reservationLoading: true,
  reservation: [],
  bookingFullEdit: false,
  editBookingDialogOpen: false,
  editTimeSelected: true,
  formattedForRefundBookings: [],
  searchOptions: {
    validFrom: moment(),
    validTo: moment(),
    slotDateType: 'OCCURED',
    slotStatus: 'ACTIVE',
    paymentStatus: 'ALL',
    slotType: 'ALL',
  },
  swapBookingDialogOpen: false,
  editSuccess: null,
  editFailed: false,
};

export function updateSelectedRow(state, reservation, singularBooking) {
  const updatedState = { ...state };
  if (reservation.type === 'RESERVATION') {
    if (updatedState.selectedRows.filter((e) => e.id === reservation.id).length > 0) {
      const updateArray = singularBooking ? updatedState.selectedRows
        : updatedState.selectedRows.filter((item) => item.id !== reservation.id);
      updatedState.selectedRows = updateArray;
    } else {
      updatedState.selectedRows = [...updatedState.selectedRows, reservation];
    }
  } else if (reservation.type === 'SUBSCRIPTION') {
    if (updatedState.selectedRows.filter(
      (e) => e.subscriptionReservationId === reservation.subscriptionReservationId,
    ).length > 0) {
      const updateArray = updatedState.selectedRows.filter(
        (item) => item.subscriptionReservationId !== reservation.subscriptionReservationId,
      );
      updatedState.selectedRows = updateArray;
    } else {
      updatedState.selectedRows = [...updatedState.selectedRows, reservation];
    }
  }
  return updatedState;
}

export function updateSearchOptions(value) {
  return { type: UPDATE_SEARCH_OPTIONS_STORE, value };
}
export function updateStoredFilter(columnField, term) {
  return { type: UPDATE_STORED_FILTER, columnField, term };
}
// Gets
export function getBookings(dates) {
  return { type: GET_BOOKINGS, dates };
}
export function requestBookingsAndSubscriptions(options) {
  return { type: BOOKINGS_AND_SUBSCRIPTIONS_RETRIEVAL_REQUESTED, options };
}
export function bookingsAndSubsciptionsSuccessful(bookings) {
  return { type: BOOKINGS_AND_SUBSCRIPTIONS_RETRIEVAL_SUCCEEDED, bookings };
}
export function getBooking(reservationId) {
  return { type: BOOKING_RETRIEVAL_REQUESTED, reservationId };
}
export function bookingRetrievalSuccessful(reservation) {
  return { type: BOOKING_RETRIEVAL_SUCCEEDED, reservation };
}
export function getUnpaidBookings() {
  return { type: GET_BOOKINGS, dates: {}, status: 'UNPAID' };
}
export function getCancelledBookings() {
  return { type: GET_BOOKINGS, dates: {}, status: 'CANCELLED' };
}
export function getRequestedBookings() {
  return { type: GET_BOOKINGS, dates: {}, status: 'REQUESTED' };
}
export function getSubscriptionBookings(dates) {
  return { type: GET_SUBSCRIPTION_BOOKINGS, dates };
}
export function updateBookings(bookings) {
  return { type: UPDATE_BOOKINGS, bookings };
}
export function updateSelectedRows(selectedRow, singularBooking) {
  return { type: UPDATE_SELECTED_ROWS, selectedRow, singularBooking };
}
export function resetSelectedRows() {
  return { type: RESET_SELECTED_ROWS };
}
export function updateSelectedBookingsAndSubscriptions(rows) {
  return { type: UPDATE_SELECTED_BOOKINGS_AND_SUBSCRIPTIONS, rows };
}
export function updateSubscriptionBookings(subscriptionBookings) {
  return { type: UPDATE_SUBSCRIPTION_BOOKINGS, subscriptionBookings };
}
export function updateRequestedDates(dates) {
  return { type: UPDATE_REQUESTED_DATES, dates };
}
export function requestBookingUpdate(reservationId, reqBody) {
  return { type: UPDATE_BOOKING_REQUESTED, reservationId, reqBody };
}
export function toggleBookingFullEdit(bool) {
  return { type: TOGGLE_BOOKING_FULL_EDIT, bool };
}
export function toggleEditBookingDialog() {
  return { type: TOGGLE_EDIT_BOOKING_DIALOG };
}
export function toggleEditTimeSelected() {
  return { type: TOGGLE_EDIT_TIME_SELECTED };
}
export function requestBookingEditAndCreation() {
  return { type: BOOKING_EDIT_AND_CREATION_REQUESTED };
}
export function ManagerBookingEditAndCreationSuccessful() {
  return { type: BOOKING_RETRIEVAL_SUCCEEDED };
}
export function toggleSwapBookingDialog() {
  return { type: TOGGLE_SWAP_BOOKING_DIALOG };
}

export function swapBookingsRequested(bookings, options) {
  return { type: SWAP_BOOKINGS_REQUESTED, bookings, options };
}

export function updateFacilityBooking(bookingId, timeslots, resetRows = true) {
  return {
    type: UPDATE_FACILITY_BOOKING_REQUESTED, bookingId, timeslots, resetRows,
  };
}

export function updateBookingsEditSuccess(message) {
  return { type: UPDATE_BOOKINGS_EDIT_SUCCESS, message };
}

export function updateBookingEditFailed(value) {
  return { type: UPDATE_BOOKINGS_EDIT_FAILED, value };
}

export function updateSelectedRowsPartialPayment(selectedRow) {
  return { type: UPDATE_SELECTED_ROWS_PARTIAL_PAYMENT, selectedRow };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKINGS:
      return { ...state, bookingsLoading: true };
    case BOOKING_RETRIEVAL_REQUESTED:
      return { ...state, reservationLoading: true };
    case UPDATE_SEARCH_OPTIONS_STORE:
      return { ...state, searchOptions: { ...action.value } };
    case BOOKINGS_AND_SUBSCRIPTIONS_RETRIEVAL_REQUESTED:
      return { ...state, bookingsLoading: true };
    case BOOKINGS_AND_SUBSCRIPTIONS_RETRIEVAL_SUCCEEDED:
      return {
        ...state,
        bookingsAndSubscriptions: action.bookings,
        bookingsLoading: false,
      };
    case BOOKING_RETRIEVAL_SUCCEEDED:
      return {
        ...state,
        reservation: action.reservation,
        reservationLoading: false,
      };
    case GET_SUBSCRIPTION_BOOKINGS:
      return { ...state, subscriptionBookingsLoading: true };
    case UPDATE_STORED_FILTER:
      return {
        ...state, storedFilters: { ...state.storedFilters, [action.columnField]: action.term },
      };
    case UPDATE_BOOKINGS:
      return { ...state, bookings: action.bookings, bookingsLoading: false };
    case UPDATE_SELECTED_BOOKINGS_AND_SUBSCRIPTIONS:
      return {
        ...state,
        selectedRows: [...action.rows],
      };
    case UPDATE_SELECTED_ROWS:
      return updateSelectedRow(state, action.selectedRow, action.singularBooking);
    case RESET_SELECTED_ROWS:
      return { ...state, selectedRows: initialState.selectedRows };
    case UPDATE_SUBSCRIPTION_BOOKINGS:
      return {
        ...state,
        subscriptionBookings: action.subscriptionBookings,
        subscriptionBookingsLoading: false,
      };
    case UPDATE_REQUESTED_DATES:
      return { ...state, dates: action.dates };
    case TOGGLE_BOOKING_FULL_EDIT:
      return { ...state, bookingFullEdit: action.bool };
    case TOGGLE_EDIT_BOOKING_DIALOG:
      return { ...state, editBookingDialogOpen: !state.editBookingDialogOpen };
    case TOGGLE_EDIT_TIME_SELECTED:
      return { ...state, editTimeSelected: !state.editTimeSelected };
    case BOOKING_EDIT_AND_CREATION_SUCCEEDED:
      return { ...state, reservation: action.reservation, reservationLoading: false };
    case TOGGLE_SWAP_BOOKING_DIALOG:
      return { ...state, swapBookingDialogOpen: !state.swapBookingDialogOpen };
    case UPDATE_BOOKINGS_EDIT_SUCCESS:
      return { ...state, editSuccess: action.message };
    case UPDATE_BOOKINGS_EDIT_FAILED:
      return { ...state, editFailed: action.value };
    case UPDATE_SELECTED_ROWS_PARTIAL_PAYMENT:
      return { ...state, selectedRows: [action.selectedRow] };
    default:
      return state;
  }
};
