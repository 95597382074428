import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: 'transparent',
  },
  divider: {
    alignSelf: 'stretch',
    height: 'auto',
    margin: theme.spacing(1, 0.5),
  },
  button: {
    minWidth: '150px',
    backgroundColor: '#f0f0f0',
    minHeight: '38px',
  },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    minHeight: '36px',
    margin: theme.spacing(0.5),
    border: 'none',
    padding: theme.spacing(0, 1),
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

function CustomizedDividers(props) {
  const handleAlignment = (event, newAlignment) => {
    const { changeOption } = props;
    if (newAlignment !== null) {
      changeOption(newAlignment);
    }
  };
  const classes = useStyles();
  const { buttonsData, value, ARtitle } = props;
  return (
    <div>
      <Paper elevation={0} className={classes.paper}>
        <StyledToggleButtonGroup
          size="small"
          value={value}
          exclusive
          onChange={handleAlignment}
          aria-label="toggle-button"
          style={{
            display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start',
          }}
        >
          {buttonsData && buttonsData.map((buttonData) => (
            <ToggleButton
              key={buttonData.buttonTitle}
              className={classes.button}
              value={buttonData.buttonValue}
              disabled={buttonData.disabled ? buttonData.disabled : false}
              style={{ marginRight: '5px', marginLeft: 0, gap: '5px' }}
            >
              {buttonData.buttonTitle}
              {' '}
              {ARtitle || ''}
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
}

CustomizedDividers.propTypes = {
  buttonsData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  value: PropTypes.shape().isRequired,
  changeOption: PropTypes.func.isRequired,
  ARtitle: PropTypes.string.isRequired,
};

export default (CustomizedDividers);
