// TODO - Consolidate all membership actions into this reducer
// export const MEMBERSHIPS_RETRIEVAL_REQUESTED = 'MEMBERSHIPS_RETRIEVAL_REQUESTED';
// export const MEMBERSHIPS_RETRIEVAL_SUCCEEDED = 'MEMBERSHIPS_RETRIEVAL_SUCCEEDED';
// export const MEMBERSHIPS_RETRIEVAL_FAILED = 'MEMBERSHIPS_RETRIEVAL_FAILED';

export const MEMBERSHIP_RETRIEVAL_REQUESTED = 'MEMBERSHIP_RETRIEVAL_REQUESTED';
export const MEMBERSHIP_RETRIEVAL_SUCCEEDED = 'MEMBERSHIP_RETRIEVAL_SUCCEEDED';
export const MEMBERSHIP_RETRIEVAL_FAILED = 'MEMBERSHIP_RETRIEVAL_FAILED';

export const initialState = {
  membership: { name: null, description: null, users: [] },
  loading: false,
  error: null,
};

export const requestMembership = (membershipId) => (
  { type: MEMBERSHIP_RETRIEVAL_REQUESTED, membershipId }
);
export const succeedMembershipRetrieval = (membership) => (
  { type: MEMBERSHIP_RETRIEVAL_SUCCEEDED, membership }
);
export const failMembershipRetrieval = (error) => (
  { type: MEMBERSHIP_RETRIEVAL_FAILED, error }
);

export default (state = initialState, action) => {
  switch (action.type) {
    case MEMBERSHIP_RETRIEVAL_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case MEMBERSHIP_RETRIEVAL_SUCCEEDED:
      return {
        ...state,
        membership: action.membership,
        loading: false,
        error: initialState.error,
      };
    case MEMBERSHIP_RETRIEVAL_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
