import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { push } from 'connected-react-router';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import { Typography } from '@material-ui/core';
import * as companyActions from '../../reducers/companiesReducer';
import { getBlogEntries } from '../../services/contentfulService';
import { enableProductService } from '../../services/companiesServices';
import { Markdown } from '../../helpers/Markdown';
import { useMobile } from '../../hooks';
import { StoryLane } from './storyLane';

const AdditionalProduct = (props) => {
  const { match } = props;
  const [productData, setProductData] = useState({ fields: {} });
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.companies.companyInfo.id);
  const products = useSelector((state) => state.companies.companyInfo.products);
  const { type } = match.params;
  const isMobile = useMobile();

  useEffect(() => {
    const loadData = async () => {
      const fetchedBlogPosts = await getBlogEntries({ content_type: 'upsellProduct' });
      setProductData(fetchedBlogPosts.filter((post) => post.fields.slug === type)[0] || {});
    };

    loadData();
  }, [type]);

  const enableProduct = async (type, findOutMore, commercialAgreement) => {
    await enableProductService(companyId, type, findOutMore, commercialAgreement);
    dispatch(companyActions.getCompanyInfo());
  };

  const { fields } = productData;
  const {
    title, description, findOutMore, commercialAgreement, displayImage, youtubeLink,
  } = fields;

  const imageUrl = displayImage ? displayImage.fields.file.url : null;
  const isProductEnabled = products && products[type] === 'ENABLED';
  const isProductRequested = products && products[type] === 'REQUESTED';

  const defaultDescription = `Elevate Your Experience with ${title}! 
  Unlock the full potential of your bookings with ${title}, 
  the latest addition to our suite of services designed to enhance your management capabilities and user satisfaction.
  Whether you're looking to streamline operations,
  increase revenue, or offer your clients an unparalleled booking experience,
  Pitchbooking is your solution.`;

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        maxWidth: '1240px',
        padding: '3rem',
        gap: '1rem',
      }}
      >
        <Typography variant="h4" style={{ fontWeight: '600' }}>{title}</Typography>

        <Typography variant="body1">
          <Markdown>
            {description || defaultDescription}
          </Markdown>
        </Typography>

        {imageUrl && <img src={imageUrl} alt={title} style={{ width: '100%' }} />}

        {youtubeLink && (
          <StoryLane storyLaneLink={youtubeLink} isMobile={isMobile} />
        )}

        {!isProductEnabled ? (
          <>
            <ConditionallyVisible condition={commercialAgreement}>
              <p>{commercialAgreement}</p>
            </ConditionallyVisible>
            <div style={{
              display: 'flex', justifyContent: 'center', gap: '8px',
            }}
            >
              <ConditionallyVisible condition={findOutMore && !isProductRequested}>
                <Button
                  variant={commercialAgreement ? 'outlined' : 'contained'}
                  color={commercialAgreement ? 'secondary' : 'primary'}
                  onClick={() => enableProduct(type, findOutMore)}
                >
                  Find out more
                </Button>
              </ConditionallyVisible>
              <ConditionallyVisible condition={commercialAgreement}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => enableProduct(type, findOutMore, commercialAgreement)}
                >
                  Accept
                </Button>
              </ConditionallyVisible>
            </div>
          </>
        ) : (
          <>
            <ConditionallyVisible condition={isProductEnabled}>
              <Typography variant="subtitle1" style={{ color: '#65a30d', fontWeight: '600' }}>
                {`Pitchbooking ${title} is enabled. Thank you!`}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => dispatch(push(`/${type}`))}
              >
                Get Started
              </Button>
            </ConditionallyVisible>
          </>
        )}
        {isProductRequested && (
        <Typography variant="subtitle1" style={{ color: '#65a30d', fontWeight: '600' }}>
            {`Thanks for requesting to find out more about Pitchbooking ${title}. We will be in touch soon.`}
        </Typography>
        )}
      </div>
    </div>
  );
};

AdditionalProduct.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default AdditionalProduct;
