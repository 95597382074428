/*
    The HeaderEvents component represents the events statistic
    at the top of the analytics. It is composed of 3 items:
        1. A label (StatisticLabel) that identifies the statistic.
        2. Absolute number of events over the past X days.
        3. An icon (AssignmentTurnedInIcon) that helps further
           identify the statistic.
*/

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import LoadingSkeletonThreeDots from '../../../../components/LoadingComponents/LoadingSkeletonThreeDots';
import FailedSection from '../../../../components/LoadingComponents/FailedSection';
import { StatisticLabel } from './StatisticLabel';
import '../../styles/eventAnalytics.css';

class HeaderUtilisationBookings extends React.Component {
  render() {
    const {
      analyticsEventBookings,
      analyticsEventLoading,
      analyticsEventFailed,
      event,
    } = this.props;

    const { bookings, eventIDsArray } = analyticsEventBookings;

    let numberOfBookings = 0;

    function bookingChecker(booking) {
      if (booking.eventId === event.id) {
        numberOfBookings += 1;
      }
    }

    function allEventBookingChecker(booking) {
      if (eventIDsArray.includes(booking.eventId)) {
        numberOfBookings += 1;
      }
    }

    if (event?.id === -1) {
      bookings.forEach(allEventBookingChecker);
    } else {
      bookings.forEach(bookingChecker);
    }

    return (
      <div className="headerItem">

        <AlternativelyVisible condition={analyticsEventLoading}>
          <LoadingSkeletonThreeDots />
          <AlternativelyVisible condition={analyticsEventFailed}>
            <FailedSection />
            <>
              <div className="statistic" id="eventStats">
                <StatisticLabel labelString="Bookings" />
                <div className="statisticContent">
                  <NumberFormat
                    displayType="text"
                    thousandSeparator
                    value={numberOfBookings.toFixed(0)}
                  />
                </div>
              </div>
            </>
          </AlternativelyVisible>
        </AlternativelyVisible>

      </div>
    );
  }
}

HeaderUtilisationBookings.propTypes = {
  analyticsEventBookings: PropTypes.shape().isRequired,
  analyticsEventLoading: PropTypes.bool.isRequired,
  analyticsEventFailed: PropTypes.bool.isRequired,
  event: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  analyticsEventBookings: state.analytics.analyticsEventBookings,
  analyticsEventLoading: state.analytics.analyticsEventLoading,
  analyticsEventFailed: state.analytics.analyticsEventFailed,
});

export default connect(mapStateToProps)(HeaderUtilisationBookings);
