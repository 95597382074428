import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCompanyInfo } from '../../reducers/companiesReducer';

const OnboardingChecklist = () => {
  const company = useSelector((state) => state.companies.companyInfo);
  const { onboarding } = company;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompanyInfo(true));
  }, []);

  const renderStatus = (label, status) => (
    <tr>
      <td>{label}</td>
      <td>
        {status
          ? <div style={{ color: 'green', marginLeft: '1rem' }}>Complete</div>
          : <div style={{ color: '#fa755a', marginLeft: '1rem' }}>Incomplete</div>}
      </td>
    </tr>
  );

  return (
    <div>
      <table>
        {renderStatus('Create Facilities', onboarding && onboarding.facilities)}
        {renderStatus('Create Prices', onboarding && onboarding.prices)}
        {renderStatus('Create Opening Hours', onboarding && onboarding.schedules)}
        {renderStatus('Create Block Bookings', onboarding && onboarding.subscriptions)}
        {renderStatus('Invite Users', onboarding && onboarding.users)}
        {renderStatus('Create Closures', onboarding && onboarding.accessRestrictions)}
        {renderStatus('Receive First Payment', onboarding && onboarding.orders)}
        {renderStatus('Receive First Invoice Payment', onboarding && onboarding.invoices)}
      </table>
    </div>
  );
};

export default OnboardingChecklist;
