import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { HubspotForm } from './Components/HubspotForm';
import { getCompanyInfo } from '../../reducers/companiesReducer';

export const ContactUs = () => {
  const dispatch = useDispatch();
  const companyInfo = useSelector((state) => state.companies.companyInfo);
  const { accountOwnerDetails } = companyInfo;

  useEffect(() => {
    dispatch(getCompanyInfo(false, true));
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '1rem',
        height: '100%',
        padding: '2rem',
      }}
    >
      {/* Title */}
      <Typography
        variant="h5"
        style={{ fontWeight: 600, textDecoration: 'underline' }}
      >
        Contact Us
      </Typography>

      {/* Subtitle */}
      <Typography style={{ fontSize: '1rem' }}>
        {`Use the form below to arrange a meeting with ${accountOwnerDetails?.firstName}. We look forward to
        hearing from you!`}
      </Typography>

      {/* Hubspot iFrame */}
      {accountOwnerDetails && <HubspotForm accountOwner={accountOwnerDetails?.email} />}
    </div>
  );
};
