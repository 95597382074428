import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as leagueActions from '../../../reducers/leaguesReducer';

const styles = () => ({
  root: {
    minWidth: 400,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});
class AdjustPointsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      points: 0,
      type: 'DEDUCTION',
      reason: '',
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
      points: 0,
      type: 'DEDUCTION',
      reason: '',
    });
  };

  handleConfirmPointsAdjustment = () => {
    const { points, type, reason } = this.state;
    const { confirmPointsAdjustment, teamId, leagueId } = this.props;
    confirmPointsAdjustment(teamId, leagueId, points, type, reason);
    this.setState({ open: false });
  }

  render() {
    const isMobile = window.innerWidth < 768;

    const {
      type,
      open,
      points,
      reason,
    } = this.state;
    const { teamName } = this.props;
    return (
      <div>
        <Button color="primary" variant="contained" onClick={() => this.handleClickOpen()}>
          Adjust Points
        </Button>
        <Dialog
          open={open}
          fullScreen={isMobile}
          fullWidth
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>
              Adjust
              {' '}
              {teamName}
              {'\'s '}
              Points
            </DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <DialogContent>
            <InputLabel id="currency-select-outlined-label">Adjustment Type</InputLabel>
            <Select
              displayEmpty
              value={type}
              label="Adjustment Type"
              onChange={(event) => this.setState(
                { type: event.target.value },
              )}
            >

              <MenuItem value="ADDITION">Addition</MenuItem>
              <MenuItem value="DEDUCTION">Deduction</MenuItem>
            </Select>
            <TextField
              label="Total Points"
              id="points"
              type="number"
              variant="outlined"
              margin="normal"
              value={points}
              onChange={(event) => this.setState(
                { points: event.target.value },
              )}
            />
            <TextField
              label="Reason for Adjustment"
              id="reason"
              variant="outlined"
              margin="normal"
              value={reason}
              onChange={(event) => this.setState(
                { reason: event.target.value },
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary" variant="outlined">
              Close
            </Button>
            <Button onClick={() => this.handleConfirmPointsAdjustment()} color="primary" variant="contained">
              Confirm Points Adjustment
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { events } = state;
  const { eventStore, event } = events;
  return {
    eventStore,
    event,
  };
}

const mapDispatchToProps = (dispatch) => ({
  confirmPointsAdjustment: (teamId, leagueId, points, adjustmentType, reason) => dispatch(
    leagueActions.confirmPointsAdjustment(teamId, leagueId, points, adjustmentType, reason),
  ),
});

AdjustPointsDialog.propTypes = {
  teamId: PropTypes.string.isRequired,
  leagueId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  confirmPointsAdjustment: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withStyles(styles())(AdjustPointsDialog));
