/* eslint-disable import/prefer-default-export */
import {
  takeEvery, call, put, select,
} from 'redux-saga/effects';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  getSchedulesService,
  saveScheduleGroupOpeningTimesService,
  createFullWeekSchedulesService,
  swapFacilityScheduleService,
} from '../services/schedulesServices';
import * as schedulesActions from '../reducers/schedulesReducer';
import { handleSagaError } from './helperSaga';

function* getSchedulesSaga(companyId, action) {
  const { query } = action;
  if (companyId) {
    try {
      const response = yield call(getSchedulesService, companyId, query);
      if (!response.error) {
        yield put(schedulesActions.schedulesRequestSucceeded(response.data));
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'getSchedulesSaga');
      yield put(sessionActions.updateErrorSnackbar(`Schedules Retrieval Failed : ${error}`, true));
    }
  }
}

function* saveScheduleGroupOpeningTimesSaga(companyId) {
  const state = yield select();
  const { scheduleGroup } = state.schedules;
  const reqBody = {
    scheduleGroup,
  };
  try {
    const response = yield call(saveScheduleGroupOpeningTimesService, companyId, reqBody);
    if (!response.error) {
      yield put(schedulesActions.getSchedules());
      yield put(sessionActions.updateSuccessfulSnackbar('Schedules successfully saved.', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'saveScheduleGroupOpeningTimesSaga');
    yield put(sessionActions.updateErrorSnackbar(`Schedule update failed : ${error.error}`, true));
  }
}

function* createFullWeekSchedulesSaga(companyId, action) {
  const state = yield select();
  const { fullWeekSchedules } = state.schedules;
  const reqBody = {
    facilityId: action.id,
    fullWeekSchedules,
  };
  try {
    const response = yield call(createFullWeekSchedulesService, companyId, reqBody);
    if (!response.error) {
      yield put(schedulesActions.getSchedules());
      yield put(sessionActions.updateSuccessfulSnackbar('Schedules successfully saved.', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'createFullWeekSchedulesSaga');
    yield put(sessionActions.updateErrorSnackbar(`Schedule update failed : ${error.error}`, true));
  }
}
function* swapFacilityScheduleSaga(companyId, action) {
  const state = yield select();
  const { schedules } = state.schedules.scheduleGroup;
  const reqBody = {
    facilityId: action.id,
    schedules,
  };
  try {
    const response = yield call(swapFacilityScheduleService, companyId, reqBody);
    if (!response.error) {
      yield put(schedulesActions.getSchedules());
      yield put(sessionActions.updateSuccessfulSnackbar('Schedules successfully saved.', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'swapFacilityScheduleService');
    yield put(sessionActions.updateErrorSnackbar(`Schedule update failed : ${error.error}`, true));
  }
}

/* Watchers */
export function* getSchedulesWatcher(companyId) {
  yield takeEvery(schedulesActions.SCHEDULES_RETRIEVAL_REQUESTED, getSchedulesSaga, companyId);
}
export function* swapFaclityScheduleWatcher(companyId) {
  yield takeEvery(schedulesActions.REQUEST_FACILITY_SCHEDULE_SWAP,
    swapFacilityScheduleSaga, companyId);
}
export function* saveScheduleGroupOpeningTimesWatcher(companyId) {
  yield takeEvery(
    schedulesActions.SAVE_SCHEDULE_GROUP_EDITS_REQUESTED,
    saveScheduleGroupOpeningTimesSaga, companyId,
  );
}
export function* createFullWeekSchedulesWatcher(companyId) {
  yield takeEvery(
    schedulesActions.REQUEST_CREATION_OF_FULL_WEEK_SCHEDULES,
    createFullWeekSchedulesSaga, companyId,
  );
}
