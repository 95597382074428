import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Fade from '@material-ui/core/Fade';
import { useSelector } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import TextField from '@material-ui/core/TextField';
import { usePendingChanges } from '../hooks';

const UserMenu = ({
  companyLogo, currentCompanyName, logoutTrigger, updateCompany, isMobile,
}) => {
  const pending = usePendingChanges()?.company;
  const userCompanies = useSelector((state) => state.companies.userCompanies);
  const [anchorElement, setAnchorElement] = useState();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const transformString = (string) => {
    if (string.length > 15) {
      return `${string.substring(0, 15)}...`;
    }
    return string;
  };

  const handleClick = (event) => {
    setOpen(true);
    setAnchorElement(event.currentTarget);
  };

  const handleRequestClose = (action) => {
    setOpen(false);

    if (!action.bubbles) {
      action();
    }
  };

  let managerAccess = userCompanies;
  if (searchTerm) {
    managerAccess = userCompanies.filter(
      (uc) => uc.company?.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }

  return (
    <>
      <div
        style={{
          margin: '10px',
          width: '100%',
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: isMobile ? 'flex-end' : 'end',
        }}
      >
        <Button
          onClick={handleClick}
          variant="contained"
          style={{ boxShadow: 'none', backgroundColor: '#ffffff' }}
        >
          {currentCompanyName}
          <ArrowDropDownIcon style={{ color: '#4781e2' }} />
        </Button>

        <img src={pending?.logoUrl || companyLogo} alt="" height="35px" />
        <Menu
          id="customized-menu"
          anchorEl={anchorElement}
          keepMounted
          autoFocus
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open}
          style={{ top: 30 }}
          onClose={handleRequestClose}
          TransitionComponent={Fade}
        >
          <MenuItem
            style={{ width: 200, flexDirection: 'row-reverse', paddingRight: '30px' }}
            onClick={() => handleRequestClose(logoutTrigger)}
          >
            <ExitToAppIcon style={{ marginLeft: '10px' }} />
            Log out
          </MenuItem>
          <ConditionallyVisible condition={userCompanies?.length > 1}>
            <MenuItem
              key={1}
              style={{ width: 200, flexDirection: 'row', paddingRight: '30px' }}
            >
              <TextField
                placeholder="Search"
                onKeyDown={(e) => { e.stopPropagation(); }}
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </MenuItem>
            {managerAccess.map((userCompany) => (
              <MenuItem
                key={userCompany?.company?.id}
                style={{ width: 200, flexDirection: 'row', paddingRight: '30px' }}
                onClick={() => updateCompany(userCompany.company.id)}
              >
                <img src={userCompany?.company?.logoUrl} alt="" height="35px" style={{ marginRight: 5, maxWidth: '35px' }} />
                {userCompany?.company?.name && (
                  transformString(userCompany?.company?.name)
                )}
              </MenuItem>
            ))}
          </ConditionallyVisible>
        </Menu>
      </div>

    </>
  );
};

UserMenu.propTypes = {
  logoutTrigger: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired,
  companyLogo: PropTypes.string,
  isMobile: PropTypes.bool,
  currentCompanyName: PropTypes.string,
};

UserMenu.defaultProps = {
  companyLogo: '',
  isMobile: false,
  currentCompanyName: '',
};

export default UserMenu;
