/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import '../../styles/payments.css';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import MaterialTable, {
  MTableBodyRow, MTableHeader, MTableCell,
} from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import Chip from '@material-ui/core/Chip';
import { Typography } from '@material-ui/core';
import DeleteMembershipDialog from './components/DeleteMembershipDialog';
import EditMembershipDialog from './components/EditMembershipDialog';
import { Markdown } from '../../helpers/Markdown';

const checkRecurrence = (recurrence) => {
  if (recurrence === 'MONTHLY') {
    return 'Month';
  }
  if (recurrence === 'ANNUALLY') {
    return 'Year';
  }
  return 'N/A';
};

const MembershipsTable = ({
  memberships, reload, showInvoiceAccepted, history, currencySym,
}) => (
  <div className="table-section" style={{ padding: '1rem' }}>
    <Paper
      style={{
        borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden', width: '100%',
      }}
      component={Paper}
    >
      <MaterialTable
        className="table"
        aria-label="simple table"
        data={memberships}
        title="Pitchbooking Memberships"
        icons={{
          NextPage: () => <ChevronRight />,
          PreviousPage: () => <ChevronLeft />,
          Search: () => <Search />,
          Export: () => <SaveAlt />,
          ResetSearch: () => <Clear />,
          SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
        }}
        columns={[
          {
            title: 'Membership Name',
            field: 'name',
            align: 'center',
            render: (rowData) => (
              <Button
                color="secondary"
                style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                onClick={() => history.push(`/memberships/${rowData.id}`)}
              >
                {rowData.name}
              </Button>
            ),
          },
          {
            title: 'Description',
            field: 'description',
            sorting: false,
            render: (rowData) => (
              <Markdown>
                {rowData.description}
              </Markdown>
            ),
          },
          {
            title: 'Price',
            field: 'price',
            sorting: true,
            align: 'center',
            render: (rowData) => (
              <Chip
                label={(
                  <Typography variant="body1" style={{ fontWeight: '600' }}>
                    {`${rowData.price ? `${currencySym}${rowData?.price?.toFixed(2)}` : 'Free Membership'} 
                      ${rowData.recurrence ? `- Per ${checkRecurrence(rowData.recurrence)}` : ''}`}
                  </Typography>
                  )}
                color="secondary"
                style={{
                  backgroundColor: rowData.price ? 'rgb(50, 255, 164)' : 'var(--accent)',
                  color: rowData.price ? 'black' : 'white',
                  border: rowData.price ? '1px solid rgb(39, 174, 96, 0.6)' : '1px solid rgb(31, 165, 255, 0.6)',
                }}
              />
            ),
          },
          {
            title: 'Pending Approval',
            field: 'pendingApproval',
            align: 'center',
            render: (rowData) => (
              rowData.requiresApproval ? (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
                  <AlternativelyVisible condition={rowData.totalPendingUsers > 0}>
                    <>
                      {rowData.totalPendingUsers}
                      {' Users requested to join'}
                      <PriorityHigh fontSize="small" style={{ color: '#4581e2' }} />

                    </>
                    <>
                      No pending requests
                    </>
                  </AlternativelyVisible>
                </div>
              ) : 'Not Applicable'

            ),
          },
          {
            title: 'Actions',
            field: '',
            sorting: false,
            align: 'center',
            render: (membershipEntry) => (
              <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                <div style={{
                  display: 'flex',
                  gap: '1rem',
                  justifyContent: 'center',
                }}
                >
                  <EditMembershipDialog
                    membership={membershipEntry}
                    success={reload}
                    showInvoiceAccepted={showInvoiceAccepted}
                  />

                  <DeleteMembershipDialog
                    style={{ marginRight: 10 }}
                    membershipName={membershipEntry.name}
                    membershipID={membershipEntry.id}
                    hasActiveSubscriptions={membershipEntry.hasActiveSubscriptions}
                    success={reload}
                  />
                </div>
              </div>
            ),
          },
        ]}
        options={{
          search: true,
          draggable: false,
          tableLayout: 'auto',
          emptyRowsWhenPaging: false,
          exportButton: true,
          exportFileName: 'Pitchbooking Memberships',
          pageSize: 25,
          pageSizeOptions: [10, 25, 50, 100],
          showFirstLastPageButtons: false,
          showTitle: false,
          headerStyle: {
            backgroundColor: '#efefef',
            fontWeight: 'bold',
            cursor: 'default',
          },
        }}
        localization={{
          toolbar: {
            exportTitle: 'Download',
          },

        }}
      >
        <MTableHeader style={{ backgroundColor: '#f8f9fb', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
          <MTableBodyRow>
            <MTableCell>Membership Name</MTableCell>
            <MTableCell>Description</MTableCell>
            <ConditionallyVisible condition={showInvoiceAccepted}>
              <MTableCell>Invoice Accepted</MTableCell>
            </ConditionallyVisible>
            <MTableCell>Members</MTableCell>
            <MTableCell>Actions</MTableCell>
            <MTableCell />
          </MTableBodyRow>
        </MTableHeader>
      </MaterialTable>
    </Paper>
  </div>
);

MembershipsTable.propTypes = {
  memberships: PropTypes.shape().isRequired,
  reload: PropTypes.shape().isRequired,
  showInvoiceAccepted: PropTypes.bool.isRequired,
  history: PropTypes.shape().isRequired,
  currencySym: PropTypes.string.isRequired,
};

export default (MembershipsTable);
