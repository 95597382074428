import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MembersList from './MembersList';
import AddMemberDialog from './AddMemberDialog';
import * as membershipsActions from '../../../reducers/accessRestrictionsReducer';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as actions from '../../../reducers/membershipsReducer';

const styles = () => ({
  root: {
    minWidth: 360,
  },
});

class MembersDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    const {
      selectedMembershipId, membershipID, getMembershipUsers,
    } = this.props;
    if (selectedMembershipId && membershipID === selectedMembershipId) {
      getMembershipUsers(membershipID);
      this.setState({ open: true });
    }
  }

  handleClickOpen = (membershipID) => {
    const {
      getMembershipUsers,
    } = this.props;
    getMembershipUsers(membershipID);
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    const {
      resetMembershipUsers,
    } = this.props;
    this.setState({ open: false });
    resetMembershipUsers();
    const {
      getMembership,
      membershipID,
    } = this.props;
    getMembership(membershipID);
  };

  render() {
    const {
      membershipID, classes, membershipName, addUsers, users,
      accessRestrictions, revokeUser,
    } = this.props;

    let userList = [];
    if (users && users.length > 0) {
      userList = users.map((user) => ({
        label: `${user.firstName} ${user.lastName} <${user.email}>`,
        fullName: `${user.firstName} ${user.lastName}`,
        email: `${user.email}`,
        id: `${user.id}`,
      }));
    }

    const { open } = this.state;
    const isMobile = window.innerWidth < 768;
    return (
      <div>
        <Button variant="outlined" color="secondary" onClick={() => this.handleClickOpen(membershipID)}>Add or Remove Members</Button>
        <Dialog
          open={open}
          onRequestClose={this.handleRequestClose}
          fullScreen={isMobile}
          classes={{
            paper: classes.root,
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>{`${membershipName}'s Users`}</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <AddMemberDialog
            membershipName={membershipName}
            membershipID={membershipID}
            addUsers={(userIDs) => addUsers(userIDs)}
            userList={userList}
          />
          <DialogContent>
            <MembersList
              membershipID={membershipID}
              membersList={accessRestrictions.membersList}
              revokeUser={revokeUser}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

MembersDialog.propTypes = {
  accessRestrictions: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  users: PropTypes.shape().isRequired,
  membershipID: PropTypes.string.isRequired,
  membershipName: PropTypes.string.isRequired,
  addUsers: PropTypes.func.isRequired,
  revokeUser: PropTypes.func.isRequired,
  getMembership: PropTypes.func.isRequired,
  getMembershipUsers: PropTypes.func.isRequired,
  resetMembershipUsers: PropTypes.func.isRequired,
  selectedMembershipId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { accessRestrictions } = state;
  const { users } = state.users;
  return {
    accessRestrictions, users,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getMembershipUsers: (membershipId) => dispatch(
    membershipsActions.getMembershipUsers(membershipId),
  ),
  resetMembershipUsers: (membershipId) => dispatch(
    membershipsActions.resetMembershipUsers(membershipId),
  ),
  getMembership: (id) => dispatch(actions.requestMembership(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MembersDialog));
