import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DeleteIcon from '@material-ui/icons/Delete';
import GradeIcon from '@material-ui/icons/Grade';
import { useColor } from '@pitchbooking-dev/pb-shared/lib/hooks';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const CreateLeagueTeam = ({ onSubmit }) => {
  const { primary, secondary, error: errorColor } = useColor();
  const [error, setError] = useState(null);
  const validationSchema = Yup.object({
    teamName: Yup.string().required('Team name is required'),
    teamMembers: Yup.array().of(
      Yup.object(
        {
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          email: Yup.string().email('Invalid email address').required('Email is required'),
          dialCode: Yup.string().required('Dial code is required'),
          phone: Yup.string().required('Phone number is required'),
        },
      ),
    ).min(1, 'Team must have at least one member').required('Team members are required'),
    captain: Yup.number().required('A captain is required'),
  });

  return (
    <>
      <Formik
        initialValues={{
          teamName: '',
          captain: 0,
          teamMembers: [
            {
              firstName: '',
              lastName: '',
              email: '',
              dialCode: '44',
              phone: '',
            },
          ],
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          setError(null);
          try {
            await onSubmit({ ...values, captain: values.teamMembers[values.captain] });
            resetForm();
          } catch (err) {
            console.error(err);
            setError('An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us at info@pitchbooking.com.');
          }
          setSubmitting(true);
        }}
      >
        {({
          values, errors, touched, isSubmitting, handleSubmit, handleChange, setFieldValue,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              display: 'flex', flexDirection: 'column', gap: '0.75rem',
            }}
          >
            <PBInput
              id="teamName"
              label="Team Name"
              value={values.teamName}
              onChange={handleChange}
              isError={touched.teamName && Boolean(errors.teamName)}
              errorMessage={touched.teamName && errors.teamName}
            />

            <div
              style={{
                display: 'flex', flexDirection: 'column', gap: '0.75rem',
              }}
            >
              {
              values.teamMembers.map((member, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                  }}
                  key={member}
                >
                  <PBInput
                    id={`teamMembers[${index}].firstName`}
                    label={`Team Member ${index + 1} - First Name`}
                    value={member.firstName}
                    onChange={handleChange}
                    isError={
                      touched.teamMembers?.[index]?.firstName
                      && Boolean(errors.teamMembers?.[index]?.firstName)
                    }
                    errorMessage={
                      touched.teamMembers?.[index]?.firstName
                      && errors.teamMembers?.[index]?.firstName
                    }
                    icon={index === values.captain ? <GradeIcon /> : null}
                  />
                  <PBInput
                    id={`teamMembers[${index}].lastName`}
                    label={`Team Member ${index + 1} - Last Name`}
                    value={member.lastName}
                    onChange={handleChange}
                    isError={
                      touched.teamMembers?.[index]?.lastName
                      && Boolean(errors.teamMembers?.[index]?.lastName)
                    }
                    errorMessage={
                      touched.teamMembers?.[index]?.lastName
                      && errors.teamMembers?.[index]?.lastName
                    }
                  />
                  <PBInput
                    id={`teamMembers[${index}].email`}
                    label={`Team Member ${index + 1} - Email Address`}
                    type="email"
                    value={member.email}
                    onChange={handleChange}
                    isError={
                      touched.teamMembers?.[index]?.email
                      && Boolean(errors.teamMembers?.[index]?.email)
                    }
                    errorMessage={
                      touched.teamMembers?.[index]?.email
                      && errors.teamMembers?.[index]?.email
                    }
                  />
                  <PBInput
                    id={`teamMembers[${index}].phone`}
                    label={`Team Member ${index + 1} - Phone Number`}
                    type="phone"
                    value={{ dialCode: member.dialCode, phone: member.phone }}
                    onChange={(e) => {
                      const { name } = e.target;

                      if (name === 'dialCode') {
                        setFieldValue(`teamMembers[${index}].dialCode`, e.target.value);
                      } else {
                        handleChange(e);
                      }
                    }}
                    isError={
                      touched.teamMembers?.[index]?.phone
                      && Boolean(errors.teamMembers?.[index]?.phone)
                    }
                    errorMessage={
                      touched.teamMembers?.[index]?.phone
                      && errors.teamMembers?.[index]?.phone
                    }
                  />

                  <div style={{ display: 'flex', gap: '0.75rem' }}>
                    <ConditionallyVisible condition={values.teamMembers.length > 1}>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          if (values.captain === index) {
                            setFieldValue('captain', 0);
                          } else if (index < values.captain) {
                            setFieldValue('captain', values.captain - 1);
                          }

                          setFieldValue('teamMembers', values.teamMembers.filter((_, i) => i !== index));
                        }}
                        disabled={isSubmitting}
                      >
                        <DeleteIcon />
                        Delete Team Member
                      </Button>
                    </ConditionallyVisible>
                    <ConditionallyVisible condition={values.captain !== index}>
                      <Button
                        variant="outlined"
                        style={{
                          borderColor: primary.color,
                          color: primary.color,
                        }}
                        onClick={() => {
                          setFieldValue('captain', index);
                        }}
                        disabled={isSubmitting}
                      >
                        Set as Captain
                      </Button>
                    </ConditionallyVisible>
                  </div>
                </div>
              ))
            }
            </div>

            <ConditionallyVisible condition={error}>
              <div style={{ color: errorColor.color }}>{error}</div>
            </ConditionallyVisible>

            <div style={{ display: 'flex', gap: '0.75rem' }}>
              <Button
                type="button"
                variant="outlined"
                style={{
                  backgroundColor: secondary.color,
                  textColor: secondary.text,
                }}
                disabled={isSubmitting}
                onClick={() => setFieldValue('teamMembers', [...values.teamMembers, {
                  firstName: '',
                  lastName: '',
                  email: '',
                  dialCode: '44',
                  phone: '',
                }])}
              >
                Add Team Member
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: primary.color,
                  textColor: primary.text,
                }}
                color="success"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

CreateLeagueTeam.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CreateLeagueTeam;
