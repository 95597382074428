import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CloseDialogIcon from '../shared-components/CloseDialogIcon';
import { useMobile } from '../hooks';
import { snoozeUserAlert } from '../services/usersServices';

const AlertDialog = ({
  open,
  close,
  needsBilling,
  doubleBookingAlert,
  needsStripeVerification,
}) => {
  const isMobile = useMobile();
  const companyId = useSelector((state) => state.companies.companyInfo.id);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const progressBar = <LinearProgress variant={isLoading ? 'indeterminate' : 'determinate'} value={100} />;
  const handleRequestClose = () => close(needsBilling, doubleBookingAlert, needsStripeVerification);

  const snoozeAlert = async () => {
    setIsLoading(true);
    const { error } = await snoozeUserAlert(companyId, moment().endOf('day'));
    setIsLoading(false);

    if (error) {
      setErrorMessage(error);
    } else {
      handleRequestClose();
    }
  };

  let message = (
    <div>
      Your company has invoicing active but no invoice instructions set.
      Please update your company information to include invoice instructions.
      <br />
    </div>
  );
  let messageStyle = {};
  if (needsBilling && needsBilling !== null) {
    message = (
      <div>
        {needsBilling}
        <br />
      </div>
    );
  } else if (doubleBookingAlert && doubleBookingAlert !== null) {
    message = (
      <pre style={{
        display: 'flex',
        fontFamily: 'Montserrat',
        whiteSpace: 'pre-wrap',
      }}
      >
        {`Conflict of bookings: \n\n${doubleBookingAlert}If corrections are made, please allow time for this alert to update.`.replaceAll('*', '').replaceAll('_', '')}
        <br />
      </pre>
    );
  } else if (needsStripeVerification && needsStripeVerification !== null) {
    message = (
      <pre style={{
        display: 'flex',
        fontFamily: 'Montserrat',
        whiteSpace: 'pre-wrap',
        flexDirection: 'column',
        justifyContent: 'start',
        gap: '0.75rem',
      }}
      >
        {needsStripeVerification.importance === 'HIGH' && (
          <Typography color="error">
            Priority: HIGH
          </Typography>
        )}

        <span>
          Stripe requires various identity and verification documents in order to secure
          your account. If you are seeing this message each time you log in, it means
          there is a current or potential future issue with your Stripe account.
        </span>

        <span>
          The message below will tell you what information Stripe requires in order to
          fully verify your account. You MUST go to your Stripe dashboard to upload the
          requested information. There will be an orange box at the top of the screen -
          Action Required - with &apos;View Details&apos; and &apos;Add Information&apos;
          buttons. Please take the time to read this information as your account will be
          restricted if the requested actions are not completed by the stated date.
        </span>

        <span>
          If you go to your Stripe dashboard and DO NOT see an orange box at the top of
          the screen, please click the &apos;Snooze&apos; button below to temporarily snooze this
          alert for the day.
        </span>

        <a
          href="https://storage.googleapis.com/pitchbooking-images/PBStripePDFHelper.png"
          rel="noopener noreferrer"
          target="_blank"
        >
          Please click here to view a PDF Helper.
        </a>

        <div style={{ height: '1px', backgroundColor: 'rgba(0,0,0,0.1)', margin: '0.5rem 0' }} />

        <span>
          {needsStripeVerification.message}
        </span>

        <span>
          Failure to provide outstanding documentation to Stripe could result in
          a temporary restriction of your account. This means that no payments can
          be made by your customers and that payouts to your bank will be suspended.
        </span>

        <span>
          If corrections are made, please allow 24 hours for this alert to update.
          If you&apos;re unsure of what to do next, please contact your Account Manager.
        </span>

        <br />
        <a
          href="https://dashboard.stripe.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to go to your Stripe Dashboard
        </a>
        <br />
      </pre>
    );
  }

  if (errorMessage !== null) {
    messageStyle = {
      color: 'RGBA(235, 76, 68, 1.00)',
    };
    message = errorMessage;
  }

  return (
    <div>
      <Dialog open={open} onClose={() => handleRequestClose()} fullScreen={isMobile}>
        {progressBar}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>Action Required</DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => handleRequestClose()} />
          </DialogActions>
        </div>
        <DialogContent>
          <DialogContentText style={messageStyle}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {needsStripeVerification && (
            <Button
              onClick={snoozeAlert}
              color="secondary"
              variant="outlined"
            >
              Snooze
            </Button>
          )}
          <Button
            onClick={() => handleRequestClose()}
            color="secondary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AlertDialog.propTypes = {
  close: PropTypes.func.isRequired,
  needsBilling: PropTypes.string.isRequired,
  doubleBookingAlert: PropTypes.string.isRequired,
  needsStripeVerification: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
};

export default AlertDialog;
