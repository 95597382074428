/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, TextField,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import { useMutation } from '@tanstack/react-query';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import { updateCompanyUser } from '../../../services/usersServices';
import {
  useCompany, useMobile, usePitchbookingUser, useToast,
} from '../../../hooks';

export const EditTeamMemberDialog = ({ user, success, isAccountManagerPage }) => {
  const isMobile = useMobile();
  const toast = useToast();
  const { id: companyId, products } = useCompany();
  const { isPitchbookingManager } = usePitchbookingUser();

  const { roles } = useSelector((state) => state.companyUsers);

  const [open, setOpen] = useState(false);

  const isSmsEnabled = products?.smsNotifications === 'ENABLED';

  const { mutate, isLoading } = useMutation({
    mutationFn: async (values) => {
      const data = {
        ...values,
        ...(isSmsEnabled ? {
          updateUserPreferences: true,
          userPreferences: {
            ...values.userPreferences,
            sms: values.sms,
          },
        } : {}),
      };

      const res = await updateCompanyUser(
        companyId,
        user.userId,
        data,
      );

      if (res.status !== 200) {
        throw new Error('Error updating team member');
      }
    },
    onSuccess: () => {
      setOpen(false);
      toast.trigger({
        type: 'success',
        message: 'Team member updated successfully!',
      });
      success();
    },
    onError: (e) => {
      console.log(e);
      toast.trigger({
        type: 'error',
        message: 'Failed to update team member!',
      });
    },
  });

  return (
    <div>
      {!isAccountManagerPage ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
          disabled={!user}
        >
          Edit
        </Button>
      ) : (
        <EditTwoToneIcon
          color="secondary"
          onClick={() => setOpen(true)}
          disabled={!user}
        />
      )}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={false}
        fullScreen={isMobile}
      >
        {isLoading && (
          <LinearProgress
            color="primary"
            variant="indeterminate"
            value={100}
          />
        )}

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle color="primary">Edit Team Member Details</DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => setOpen(false)} />
          </DialogActions>
        </div>

        <Formik
          initialValues={{
            name: `${user?.firstName} ${user?.lastName}`,
            receivesEmails: user?.receivesEmails,
            ...(isSmsEnabled ? { sms: user?.userPreferences?.sms ?? false } : {}),
            roleId: roles.find((role) => role.name === user?.roleName)?.id,
          }}
          onSubmit={(values) => mutate(values)}
        >
          {({
            touched, errors, getFieldProps,
          }) => (
            <Form>
              <DialogContent
                style={{
                  minWidth: 700,
                  minHeight: 300,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                <PBInput
                  id="name"
                  label="Name"
                  {...getFieldProps('name')}
                  error={touched.name && errors.name}
                />
                <PBInput
                  id="receivesEmails"
                  label="Receives Emails"
                  type="select"
                  options={[
                    { value: false, label: 'No' },
                    { value: true, label: 'Yes' },
                  ]}
                  {...getFieldProps('receivesEmails')}
                  error={touched.receivesEmails && errors.receivesEmails}
                />

                {/* Receives SMS */}
                {isSmsEnabled && (
                  <PBInput
                    id="sms"
                    label="Receives SMS"
                    type="select"
                    options={[
                      { value: false, label: 'No' },
                      { value: true, label: 'Yes' },
                    ]}
                    {...getFieldProps('sms')}
                    error={touched.sms && errors.sms}
                  />
                )}

                {/* Role */}
                {isPitchbookingManager && (
                  <PBInput
                    id="roleId"
                    label="Role"
                    type="select"
                    options={roles.map((role) => ({
                      value: role.id,
                      label: role.name,
                    }))}
                    {...getFieldProps('roleId')}
                    error={touched.roleId && errors.roleId}
                  />
                )}
              </DialogContent>

              <DialogActions>
                <Button onClick={() => setOpen(false)} color="secondary" variant="contained">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isLoading}
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};
