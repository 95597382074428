/* eslint-disable react/prop-types */
import React from 'react';
import {
  Dialog, DialogActions, DialogTitle, DialogContent, Typography, Button,
} from '@material-ui/core';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import CloseIcon from '@material-ui/icons/Close';
import { useMutation } from '@tanstack/react-query';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useMobile, useToast } from '../../../hooks';
import { updateEventServices } from '../../../services/eventsServices';
import { getEvent } from '../../../reducers/eventsReducer';

const promotionOptions = [
  {
    label: 'Game On!',
    value: 'GAME_ON',
    description: 'Pitchbooking Game On! - Verified events will receive access to 0% commission on Women and Girls events',
    commission: 0,
  },
];

const promotionSchema = yup.object().shape({
  promotion: yup.string().required('Please select a promotion you want to apply.'),
});

const PromotionModal = ({
  isOpen,
  onClose,
  event,
}) => {
  const toast = useToast();
  const isMobile = useMobile();
  const dispatch = useDispatch();

  const { id: companyId } = useSelector((state) => state.companies.companyInfo);

  const mutation = useMutation({
    mutationFn: async (data) => {
      const promotion = promotionOptions.find((option) => option.value === data.promotion);
      const res = await updateEventServices(
        companyId,
        event.id,
        {
          promotion: {
            ...data,
            label: promotion.label,
            description: promotion.description,
            commission: promotion.commission,
            status: 'REQUESTED',
          },
        },
      );

      if (res.error) {
        throw new Error(res.error);
      }
    },
    onError: (error) => {
      toast.trigger({
        type: 'error',
        message: 'Failed to request promotion!',
      });
    },
    onSuccess: () => {
      onClose();
      dispatch(getEvent(event.id));
      toast.trigger({
        type: 'success',
        message: 'Promotion requested!',
      });
    },
  });

  return (
    <Dialog
      open={isOpen}
      fullScreen={isMobile}
      fullWidth
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle>Activate a Promotion</DialogTitle>
        <DialogActions variant="none">
          <CloseIcon onClick={onClose} />
        </DialogActions>
      </div>
      <DialogContent>
        <Typography gutterBottom={4}>
          What promotion would you like to activate?
        </Typography>
        <Typography>
          Once you have requested a promotion, your Account Manager will be notified
          and will verify your access.
        </Typography>

        <Formik
          initialValues={{
            promotion: '',
          }}
          validationSchema={promotionSchema}
          onSubmit={mutation.mutate}
        >
          {({
            values, errors, touched, getFieldProps,
          }) => (
            <Form
              style={{
                display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '2rem',
              }}
            >
              <PBInput
                {...getFieldProps('promotion')}
                id="promotion"
                label="Promotion"
                type="select"
                options={promotionOptions}
                isError={touched.promotion && errors.promotion}
                errorMessage={errors.promotion}
              />
              {values.promotion && (
              <Typography>
                {promotionOptions.find((option) => option.value === values.promotion).description}
              </Typography>
              )}
              <div style={{ textAlign: 'right' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={mutation.isLoading}
                >
                  Request
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default PromotionModal;
