export const GET_USERS = 'GET_USERS';
export const GET_USERS_BOOKINGS = 'GET_USERS_BOOKINGS';
export const SUCCEED_GET_USERS_BOOKINGS = 'SUCCEED_GET_USERS_BOOKINGS';
export const FAIL_GET_USERS_BOOKINGS = 'FAIL_GET_USERS_BOOKINGS';
export const UPDATE_USERS_INFORMATION = 'UPDATE_USERS_INFORMATION';
export const UPDATE_USERS_BOOKINGS = 'UPDATE_USERS_BOOKINGS';
export const UPDATE_USERS_SUBSCRIPTION_RESERVATIONS = 'UPDATE_USERS_SUBSCRIPTION_RESERVATIONS';
export const UPDATE_USERS = 'UPDATE_USERS';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const USER_PASSWORD_UPDATED = 'USER_PASSWORD_UPDATED';
export const FORGOTTEN_PASSWORD_DIALOG_OPENED = 'FORGOT_PASSWORD_DIALOG_OPENED';
export const FORGOTTEN_PASSWORD_DIALOG_CLOSED = 'FORGOT_PASSWORD_DIALOG_CLOSED';
export const NEW_PASSWORD_DIALOG_OPENED = 'NEW_PASSWORD_DIALOG_OPENED';
export const NEW_PASSWORD_DIALOG_CLOSED = 'NEW_PASSWORD_DIALOG_CLOSED';
export const IS_LOADING = 'IS_LOADING';
export const PASSWORD_CHANGED_SUCCESSFULLY_OPENED = 'PASSWORD_CHANGED_SUCCESSFULLY_OPENED';
export const PASSWORD_CHANGED_SUCCESSFULLY_CLOSED = 'PASSWORD_CHANGED_SUCCESSFULLY_CLOSED';
export const PASSWORD_EMAIL_SUCCESS_OPENED = 'PASSWORD_EMAIL_SUCCESS_OPENED';
export const PASSWORD_EMAIL_SUCCESS_CLOSED = 'PASSWORD_EMAIL_SUCCESS_CLOSED';
export const INVITE_USER = 'INVITE_USER';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_FAIL = 'INVITE_USER_FAIL';
export const TOGGLE_INVITE_USER_DIALOG = 'TOGGLE_INVITE_USER_DIALOG';
export const USER_SIGNUP_FAILED = 'USER_SIGNUP_FAILED';
export const USER_EMAIL_SENDING_REQUESTED = 'USER_EMAIL_SENDING_REQUESTED';
export const USER_EMAIL_SENDING_SUCCEEDED = 'USER_EMAIL_SENDING_SUCCEEDED';
export const USER_EMAIL_SENDING_FAILED = 'USER_EMAIL_SENDING_FAILED';
export const USER_EMAIL_SENDING_RESET = 'USER_EMAIL_SENDING_RESET';
export const REQUEST_USER_BOOKINGS_BY_SEARCH = 'REQUEST_USER_BOOKINGS_BY_SEARCH';
export const UPDATE_ASSOCIATE_DETAILS = 'UPDATE_ASSOCIATE_DETAILS';
export const RESET_ASSOCIATE_DETAILS = 'RESET_ASSOCIATE_DETAILS';
export const INVITE_ASSOCIATE = 'INVITE_ASSOCIATE';
export const GET_COMPANY_ASSOCIATES = 'GET_COMPANY_ASSOCIATES';
export const UPDATE_COMPANY_ASSOCIATES = 'UPDATE_COMPANY_ASSOCIATES';
export const UPDATE_CURRENT_USER_SELECTED_COMPANY = 'UPDATE_CURRENT_USER_SELECTED_COMPANY';
export const MESSAGE_USERS = 'MESSAGE_USERS';
export const MESSAGE_USERS_RESET = 'MESSAGE_USERS_RESET';

export const UPDATE_USER_NOTES = 'UPDATE_USER_NOTES';

export const initialState = {
  users: [],
  bookings: [],
  userBookingsLoading: false,
  subscriptionReservations: [],
  currentUser: {
    selectedCompany: null,
  },
  selectedUser: {},
  invitedUser: {},
  associates: [],
  userError: '',
  isLoggedIn: false,
  isLoading: false,
  forgottenPasswordDialogOpen: false,
  newPasswordDialogOpen: false,
  passwordChangedSuccessfullyDialogOpen: false,
  passwordEmailSuccessDialogOpen: false,
  sendUserEmailStatus: {
    requesting: false,
    succeeded: false,
    error: false,
  },
  isManager: true,
  associate: {
    firstName: '',
    lastName: '',
    email: '',
    teamName: '',
    phone: '',
    role: '',
  },
  isInvitingUserRequest: false,
  isInviteUserSuccess: false,
  isInviteUserError: false,
  isInviteUserDialogOpen: false,
  messageUsers: null,
  totalUsers: null,
};

export function getUsers(page, term, orderBy, orderDirection) {
  return {
    type: GET_USERS, page, term, orderBy, orderDirection,
  };
}
export function updateUserSelectedCompany(companyId) {
  return { type: UPDATE_CURRENT_USER_SELECTED_COMPANY, companyId };
}
export function getUsersBookings(userId, status) {
  return { type: GET_USERS_BOOKINGS, userId, status };
}
export function getUsersBookingsBySearch(options, userId) {
  return { type: REQUEST_USER_BOOKINGS_BY_SEARCH, options, userId };
}
export function succeedGetUsersBookings() {
  return { type: SUCCEED_GET_USERS_BOOKINGS };
}
export function failGetUsersBookings() {
  return { type: FAIL_GET_USERS_BOOKINGS };
}
export function inviteAUser(user) {
  return { type: INVITE_USER, user };
}
export function inviteUserSuccess() {
  return { type: INVITE_USER_SUCCESS };
}
export function inviteUserFail() {
  return { type: INVITE_USER_FAIL };
}
export function updateCurrentUser(user) {
  return { type: UPDATE_CURRENT_USER, user };
}
export function updateUserError(error) {
  return { type: UPDATE_USER_ERROR, error };
}
export function updateUsers(users, totalUsers) {
  return { type: UPDATE_USERS, users, totalUsers };
}
export function updateUsersBookings(bookings) {
  return { type: UPDATE_USERS_BOOKINGS, bookings };
}
export function updateUsersSubscriptionReservations(reservations) {
  return { type: UPDATE_USERS_SUBSCRIPTION_RESERVATIONS, reservations };
}
export function updateUsersInformation(user) {
  return { type: UPDATE_USERS_INFORMATION, user };
}
export function isLoading(bool) {
  return { type: IS_LOADING, bool };
}
export const failUserSignUp = (error) => ({
  type: USER_SIGNUP_FAILED,
  error,
});
export function requestUserEmailSending(message, userEmails, isSmsRequested = false) {
  return {
    type: USER_EMAIL_SENDING_REQUESTED,
    message,
    userEmails,
    isSmsRequested,
  };
}
export function failUserEmailSending() {
  return { type: USER_EMAIL_SENDING_FAILED };
}
export function succeedUserEmailSending() {
  return { type: USER_EMAIL_SENDING_SUCCEEDED };
}
export function resetUserEmailSending() {
  return { type: USER_EMAIL_SENDING_RESET };
}
export function updateUserNotes(notes) {
  return { type: UPDATE_USER_NOTES, notes };
}

// DIALOGS
export function updateAssociateUserDetails(user) {
  return { type: UPDATE_ASSOCIATE_DETAILS, user };
}
export function resetAssociateUserDetails() {
  return { type: RESET_ASSOCIATE_DETAILS };
}
export function inviteUserAsAssociate(associateDetails) {
  return { type: INVITE_ASSOCIATE, associateDetails };
}
export function getCompanyAssociates(siteId) {
  return { type: GET_COMPANY_ASSOCIATES, siteId };
}
export function updateCompanyAssociates(associates) {
  return { type: UPDATE_COMPANY_ASSOCIATES, associates };
}
export function openForgottenPasswordDialog(bool) {
  return { type: FORGOTTEN_PASSWORD_DIALOG_OPENED, bool };
}
export function closeForgottenPasswordDialog(bool) {
  return { type: FORGOTTEN_PASSWORD_DIALOG_CLOSED, bool };
}
export function openNewPasswordDialog(bool) {
  return { type: NEW_PASSWORD_DIALOG_OPENED, bool };
}
export function closeNewPasswordDialog(bool) {
  return { type: NEW_PASSWORD_DIALOG_CLOSED, bool };
}
export function openPasswordChangedSuccesfullyDialog(bool) {
  return { type: PASSWORD_CHANGED_SUCCESSFULLY_OPENED, bool };
}
export function closePasswordChangedSuccesfullyDialog(bool) {
  return { type: PASSWORD_CHANGED_SUCCESSFULLY_CLOSED, bool };
}
export function openPasswordEmailSuccessDialog(bool) {
  return { type: PASSWORD_EMAIL_SUCCESS_OPENED, bool };
}
export function closePasswordEmailSuccessDialog(bool) {
  return { type: PASSWORD_EMAIL_SUCCESS_CLOSED, bool };
}
export function toggleInviteUserDialog() {
  return { type: TOGGLE_INVITE_USER_DIALOG };
}
export function messageUsers(userEmails, userIds) {
  return { type: MESSAGE_USERS, userEmails, userIds };
}
export function messageUsersReset() {
  return { type: MESSAGE_USERS_RESET };
}

// selectors
export const getCurrentUser = (state) => state.users.currentUser;

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return { ...state, isLoading: true };
    case UPDATE_USERS:
      return {
        ...state,
        users: action.users,
        totalUsers: action.totalUsers,
        isLoading: false,
      };
    case GET_USERS_BOOKINGS:
      return { ...state, userBookingsLoading: true };
    case SUCCEED_GET_USERS_BOOKINGS:
      return { ...state, userBookingsLoading: false };
    case FAIL_GET_USERS_BOOKINGS:
      return { ...state, userBookingsLoading: false, bookings: initialState.bookings };
    case UPDATE_USERS_BOOKINGS:
      return { ...state, bookings: action.bookings };
    case UPDATE_USERS_SUBSCRIPTION_RESERVATIONS:
      return { ...state, subscriptionReservations: action.reservations };
    case UPDATE_USERS_INFORMATION:
      return { ...state, selectedUser: action.user };
    case UPDATE_CURRENT_USER:
      return { ...state, currentUser: action.user };
    case UPDATE_CURRENT_USER_SELECTED_COMPANY:
      localStorage.setItem('selectedCompany', action.companyId);
      window.location.reload();
      return state;
    case UPDATE_USER_ERROR:
      return { ...state, userError: action.error };
    case FORGOTTEN_PASSWORD_DIALOG_OPENED:
      return { ...state, forgottenPasswordDialogOpen: action.bool };
    case FORGOTTEN_PASSWORD_DIALOG_CLOSED:
      return { ...state, forgottenPasswordDialogOpen: action.bool };
    case NEW_PASSWORD_DIALOG_OPENED:
      return { ...state, newPasswordDialogOpen: action.bool };
    case NEW_PASSWORD_DIALOG_CLOSED:
      return { ...state, newPasswordDialogOpen: action.bool };
    case PASSWORD_CHANGED_SUCCESSFULLY_OPENED:
      return { ...state, passwordChangedSuccessfullyDialogOpen: action.bool };
    case PASSWORD_CHANGED_SUCCESSFULLY_CLOSED:
      return { ...state, passwordChangedSuccessfullyDialogOpen: action.bool };
    case PASSWORD_EMAIL_SUCCESS_OPENED:
      return { ...state, passwordEmailSuccessDialogOpen: action.bool };
    case PASSWORD_EMAIL_SUCCESS_CLOSED:
      return { ...state, passwordEmailSuccessDialogOpen: action.bool };
    case IS_LOADING:
      return { ...state, isLoading: action.bool };
    case UPDATE_ASSOCIATE_DETAILS:
      return {
        ...state,
        associate: {
          ...state.associate,
          ...action.user,
        },
      };
    case RESET_ASSOCIATE_DETAILS:
      return { ...state, associate: {} };
    case UPDATE_COMPANY_ASSOCIATES:
      return { ...state, associates: action.associates };
    case USER_SIGNUP_FAILED:
      return {
        ...state,
        tryingUserSignUp: false,
        userSignUpError: true,
        userSignUpErrorMessage: action.error,
      };
    case USER_EMAIL_SENDING_REQUESTED:
      return {
        ...state,
        sendUserEmailStatus: {
          requesting: true,
          succeeded: false,
          error: false,
        },
      };
    case USER_EMAIL_SENDING_SUCCEEDED:
      return {
        ...state,
        sendUserEmailStatus: {
          requesting: false,
          succeeded: true,
        },
      };
    case USER_EMAIL_SENDING_FAILED:
      return {
        ...state,
        sendUserEmailStatus: {
          requesting: false,
          error: true,
        },
      };
    case USER_EMAIL_SENDING_RESET:
      return {
        ...state,
        sendUserEmailStatus: initialState.sendUserEmailStatus,
      };
    case UPDATE_USER_NOTES:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          notes: action.notes,
        },
      };
    case TOGGLE_INVITE_USER_DIALOG:
      return {
        ...state,
        isInviteUserDialogOpen: !state.isInviteUserDialogOpen,
        isInvitingUserRequest: false,
        isInviteUserSuccess: false,
        isInviteUserError: false,
      };
    case INVITE_USER:
      return {
        ...state,
        isInvitingUserRequest: true,
      };
    case INVITE_USER_SUCCESS:
      return {
        ...state,
        isInviteUserSuccess: true,
        isInvitingUserRequest: false,
      };
    case INVITE_USER_FAIL:
      return {
        ...state,
        isInviteUserError: true,
        isInvitingUserRequest: false,
      };
    case MESSAGE_USERS:
      return {
        ...state,
        messageUsers: {
          userEmails: action.userEmails,
          userIds: action.userIds,
        },
      };
    case MESSAGE_USERS_RESET:
      return {
        ...state,
        messageUsers: null,
      };
    default:
      return state;
  }
};
