import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogContent, DialogTitle,
} from '@material-ui/core';
import { useMobile } from '../../../../hooks';
import { AddDeviceForm } from './Form';

export const AddDeviceDialog = ({ type, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMobile();

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsOpen(true)}
      >
        Add Device
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullScreen={isMobile}
      >
        <DialogTitle>Add Device</DialogTitle>
        <DialogContent>
          <AddDeviceForm
            type={type}
            onSuccess={() => {
              onSuccess();
              setIsOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

AddDeviceDialog.propTypes = {
  type: PropTypes.oneOf(['lock', 'light', 'terminal']).isRequired,
  onSuccess: PropTypes.func.isRequired,
};
