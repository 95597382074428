import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';

const styles = (theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: '#47FEB4',
      color: '#fff',
    },
  },
  primaryIcon: {
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#fff',
      color: '#47FEB4',
    },
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: '#476BB2',
      color: '#fff',
    },
  },
  secondaryOutlined: {
    ...theme.palette.secondaryOutlined,
    marginLeft: -10,
    '&:hover': {
      backgroundColor: '#6699FF',
      color: '#fff',
      borderRaidus: '0px solid #fff',
    },
  },
  secondaryIcon: {
    backgroundColor: '#fff',
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: '#fff',
      color: '#476BB2',
    },
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: '#800017',
      color: '#fff',
    },
  },
  basic: {
    backgroundColor: '#fff',
    color: '#545454',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000',
    },
  },
});

class PermissionsButton extends Component {
  render() {
    const {
      className, title, onClick, color,
      managerAccess, buttonRole, children,
      variant, autoFocus, style, noMargin,
      classes, fullWidth, disabled,
    } = this.props;
    const displayButton = managerAccess.map((uc) => uc.role[buttonRole]);
    let classesColor;
    switch (color) {
      case 'primary':
        classesColor = { root: classes.primary };
        break;
      case 'primaryIcon':
        classesColor = { root: classes.primaryIcon };
        break;
      case 'secondary':
        if (variant === 'outlined') {
          classesColor = { root: classes.secondaryOutlined };
        } else {
          classesColor = { root: classes.secondary };
        }
        break;
      case 'secondaryIcon':
        classesColor = { root: classes.secondaryIcon };
        break;
      case 'error':
        classesColor = { root: classes.error };
        break;
      default:
        classesColor = { root: classes.basic };
        break;
    }

    return (
      <AlternativelyVisible condition={buttonRole}>
        <ConditionallyVisible condition={displayButton[0] || managerAccess[0].role.type === 'PITCHBOOKING_INTERNAL'}>
          <div style={noMargin ? { display: 'inline-block', margin: 0 } : { display: 'inline-block', margin: 10 }}>
            <Button
              classes={classesColor}
              className={className}
              onClick={onClick}
              variant={variant}
              style={style}
              autoFocus={autoFocus}
              fullWidth={fullWidth}
              disabled={disabled}
            >
              {title || children}
            </Button>
          </div>
        </ConditionallyVisible>
        <div style={noMargin ? { display: 'inline-block', margin: 0 } : { display: 'inline-block', margin: 10 }}>
          <Button
            classes={classesColor}
            className={className}
            onClick={onClick}
            variant={variant}
            style={style}
            autoFocus={autoFocus}
            fullWidth={fullWidth}
            disabled={disabled}
          >
            {title || children}
          </Button>
        </div>
      </AlternativelyVisible>

    );
  }
}

PermissionsButton.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool.isRequired,
  noMargin: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  buttonRole: PropTypes.string.isRequired,
  style: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  managerAccess: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape()),
  ]).isRequired,
};

PermissionsButton.defaultProps = {
  disabled: false,
};

function mapStateToProps(state) {
  const { currentUser } = state.currentUser;
  const { managerAccess } = currentUser;
  return {
    managerAccess,
  };
}

const mapDispatchToProps = (dispatch) => ({
  changeSelectedDate: (date) => dispatch(sessionActions.changeSelectedDate(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles,
  { withTheme: true })(PermissionsButton));
