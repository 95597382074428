import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { create } from 'jss';
import jssGlobal from 'jss-plugin-global';
import { withRouter } from 'react-router-dom';
import 'whatwg-fetch';

import { ThemeProvider, StylesProvider, jssPreset } from '@material-ui/core/styles';
import theme from '@pitchbooking-dev/pb-shared/lib/theme';

import Snackbar from '@pitchbooking-dev/pb-shared/lib/components/SnackBar';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import store, { history } from './store';
import App from './containers/app';
import rootSaga from './sagas/rootSaga';
import { createEPOSNowTransaction } from './helpers/EposNow';

store.runSaga(rootSaga);
createEPOSNowTransaction();
const jss = create({
  plugins: [...jssPreset().plugins, jssGlobal()],
});
const target = document.querySelector('#root');

const AppRouted = withRouter((props) => <App {...props} />);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // Data is stale after 5 minutes
      cacheTime: 1000 * 60 * 60, // Data is cached for 1 hour
      retry: 2,
      refetchOnWindowFocus: true,
    },
  },
});

try {
  render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ConnectedRouter history={history}>
          <div>
            <ThemeProvider theme={theme}>
              <Snackbar />
              <StylesProvider jss={jss}>
                <AppRouted />
              </StylesProvider>
            </ThemeProvider>
          </div>
        </ConnectedRouter>

        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>,
    target,
  );
} catch (e) {
  // javascript window error alert
  console.log(e, 'error');
  localStorage.clear();
  alert(JSON.stringify(e));
}
