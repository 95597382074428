import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  convertToRaw, convertFromRaw, EditorState, ContentState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToMarkdown from 'draftjs-to-markdown';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { BlockPicker, TwitterPicker } from 'react-color';
import {
  defaultColor, defaultTextColor, primaryColors, textColors,
} from './colorArrays';
import * as companyActions from '../../reducers/companiesReducer';
import ClipboardCopy from '../../components/ClipboardCopy';
import PitchbookingUserOnly from '../../helpers/PitchbookingUserOnly';
import ChangeCompanyAddressDialog from './ChangeCompanyAddressDialog';
// import ColorScheme from './colorScheme/index';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { usePendingChanges } from '../../hooks';

const DetailsConfiguration = () => {
  const pending = usePendingChanges()?.company;
  const company = useSelector((state) => state.companies.companyInfo);
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState();
  const [markdown, setMarkdown] = useState();
  // Hide hex input for twitter picker
  const twitterStyle = {
    default: {
      input: {
        display: 'none',
      },
      hash: {
        display: 'none',
      },
    },
  };

  useEffect(() => {
    if (company.about) {
      try {
        const contentState = convertFromRaw(JSON.parse(company.about));
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
        setMarkdown(draftToMarkdown(convertToRaw(newEditorState.getCurrentContent())));
      } catch (err) {
        setEditorState(
          EditorState.createWithContent(ContentState.createFromText(company.about)),
        ); setMarkdown(company.about);
      }
    } else {
      setEditorState(EditorState.createEmpty());
      setMarkdown('');
    }
  }, [company.about]);

  const saveCompanyDetails = async (values) => {
    dispatch(companyActions.updateCompanyDetails(values));
    dispatch(companyActions.getCompanyInfo());
    dispatch(sessionActions.updateSuccessfulSnackbar('Your company info has been updated successfully!', true));
  };

  return (
    <Formik
      initialValues={{
        name: pending?.name || company.name || '',
        phone: pending?.phone || company.phone || '',
        alertMessage: pending?.alertMessage || company.alertMessage || '',
        about: pending?.about || company.about || '',
        logoUrl: pending?.logoUrl || company.logoUrl || '',
        termsLink: pending?.termsLink || company.termsLink || '',
        hubspotCompanyId: pending?.hubspotCompanyId || company.hubspotCompanyId || '',
        generalEmail: pending?.generalEmail || company.generalEmail || '',
        colorScheme: pending?.colorScheme || company.colorScheme || '',
        vatNumber: pending?.vatNumber || company.vatNumber || '',
      }}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        // Convert editor state to markdown
        const contentState = editorState.getCurrentContent();
        const rawContentState = JSON.stringify(convertToRaw(contentState));

        const form = {
          ...values,
          vatNumber: values.vatNumber || null,
          about: rawContentState,
        };
        setSubmitting(true);

        try {
          saveCompanyDetails(form);
          // resetForm();
        } catch (err) {
          dispatch(sessionActions.updateErrorSnackbar(
            'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us at info@pitchbooking.com.',
            true,
          ));
        }
      }}
    >
      {({
        values, errors, touched, handleSubmit, handleChange, resetForm, setFieldValue,
      }) => (
        <form
          onSubmit={handleSubmit}
          onReset={resetForm}
          style={{
            display: 'flex', flexDirection: 'column', gap: '0.75rem',
          }}
        >
          <Typography variant="h6">Company details configuration</Typography>
          <Typography variant="h6">Company Name</Typography>
          <PBInput
            type="text"
            id="name"
            label="Company Name"
            value={values.name}
            onChange={handleChange}
            isError={touched.name && Boolean(errors.name)}
            errorMessage={touched.name && errors.name}
          />
          <Typography variant="h6">Company VAT Number</Typography>
          <PBInput
            type="text"
            id="vatNumber"
            label="VAT Number"
            value={values.vatNumber}
            onChange={handleChange}
            isError={touched.vatNumber && Boolean(errors.vatNumber)}
            errorMessage={touched.vatNumber && errors.vatNumber}
          />

          {/* Company address */}
          <Typography variant="h6">Company Address</Typography>
          <div style={{
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '5px',
            padding: '6px 8px',
            borderColor: 'rgba(0, 0, 0, 0.23)',
          }}
          >
            <Typography variant="h8">
              {
              (pending?.addressLine1
                ? [pending?.addressLine1, pending?.addressLine2, pending?.postcode]
                : [company?.addressLine1, company?.addressLine2, company?.postcode]).filter((val) => val).join(', ')
                }
            </Typography>

            <ChangeCompanyAddressDialog />
          </div>

          {/* Phone */}
          <Typography variant="h6">Phone Number</Typography>
          <PBInput
            type="text"
            id="phone"
            label="Phone"
            value={values.phone}
            onChange={handleChange}
            isError={touched.phone && Boolean(errors.phone)}
            errorMessage={touched.phone && errors.phone}
          />

          {/* Email */}
          <Typography variant="h6">General Email</Typography>
          <PBInput
            type="text"
            id="generalEmail"
            label="Email"
            value={values.generalEmail}
            onChange={handleChange}
            isError={touched.generalEmail && Boolean(errors.generalEmail)}
            errorMessage={touched.generalEmail && errors.generalEmail}
          />

          {/* Alert Message */}
          <Typography variant="h6">Alert Message</Typography>
          <PBInput
            type="textarea"
            id="alertMessage"
            label="Alert Message"
            value={values.alertMessage}
            onChange={handleChange}
            isError={touched.alertMessage && Boolean(errors.alertMessage)}
            errorMessage={touched.alertMessage && errors.alertMessage}
          />

          {/* About */}
          <Typography variant="h6">About</Typography>
          <div style={{
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '5px',
            padding: '6px 8px',
            borderColor: 'rgba(0, 0, 0, 0.23)',
          }}
          >
            <div style={{
              borderStyle: 'solid',
              borderWidth: '1px',
              borderRadius: '5px',
              padding: '6px 8px',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              backgroundColor: '#EBEBE4',
              marginBottom: '1rem',
            }}
            >
              <Typography variant="h8">Current about text: </Typography>
              <ReactMarkdown linkTarget="_blank">
                {markdown}
              </ReactMarkdown>
            </div>
            <Editor
              editorState={editorState}
              onEditorStateChange={(editorState) => setEditorState(editorState)}
              toolbar={{
                options: ['inline', 'blockType', 'list', 'link', 'history'],
                inline: {
                  options: ['bold', 'italic'],
                },
                blockType: {
                  inDropdown: true,
                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                },
              }}
              placeholder="Enter your about description here..."
              stripPastedStyles
            />
          </div>

          {/* Logo */}
          <PitchbookingUserOnly>
            <Typography variant="h6">Logo</Typography>
            <PBInput
              type="text"
              id="logoUrl"
              label="logoUrl"
              value={values.logoUrl}
              onChange={handleChange}
              isError={touched.logoUrl && Boolean(errors.logoUrl)}
              errorMessage={touched.logoUrl && errors.logoUrl}
            />
            <div style={{ textAlign: 'right' }}>
              <Typography variant="caption">
                <a
                  href="https://console.cloud.google.com/storage/browser/pitchbooking-images/Demo/DemoLogos"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Upload file here
                </a>
              </Typography>
            </div>
            <PBInput
              id="hubspotCompanyId"
              type="text"
              label="Hubspot Company ID"
              value={values.hubspotCompanyId}
              onChange={handleChange}
              errorMessage={touched.hubspotCompanyId && errors.hubspotCompanyId}
              isError={touched.hubspotCompanyId && Boolean(errors.hubspotCompanyId)}
            />
            <Typography variant="caption">
              Test Hubspot Link: &nbsp;
              <a
                href={`https://app.hubspot.com/contacts/4272364/record/0-2/${values.hubspotCompanyId}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {`https://app.hubspot.com/contacts/4272364/record/0-2/${values.hubspotCompanyId}`}
              </a>
            </Typography>
          </PitchbookingUserOnly>

          {/* Terms */}
          <div>
            <Typography variant="h6">Terms and Conditions</Typography>
            <PBInput
              type="text"
              id="termsLink"
              label="Terms and Conditions Link"
              placeholder="Add a link to your terms and conditions here..."
              value={values.termsLink}
              onChange={handleChange}
              isError={touched.termsLink && Boolean(errors.termsLink)}
              errorMessage={touched.termsLink && errors.termsLink}
            />
            {values.termsLink && (
              <Typography variant="caption">
                {'Test T&C\'s link : '}
                <a
                  href={`${values.termsLink}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {values.termsLink}
                </a>
              </Typography>
            )}
          </div>

          {/* Color Picker */}
          <Typography variant="h6" style={{ margin: '0.5rem 0rem' }}>Color Picker</Typography>
          <Typography variant="body1">
            This color picker allows you to easily customize
            the color scheme of the buttons displayed on your partner page.
          </Typography>
          <>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ padding: '1rem' }}>
                <Typography variant="h8">Primary Color: </Typography>
              </div>
              <div style={{ flex: 1, padding: '1rem' }}>
                <BlockPicker
                  triangle="hide"
                  onChange={(e) => {
                    setFieldValue('colorScheme.primary.color', e.hex);
                  }}
                  color={values?.colorScheme?.primary
                    ? values?.colorScheme?.primary?.color
                    : defaultColor}
                  colors={primaryColors}
                />
              </div>
              <div style={{ flex: 1, padding: '1rem' }}>
                <Typography variant="h8">Text Color: </Typography>
                <TwitterPicker
                  triangle="hide"
                  onChange={(e) => {
                    setFieldValue('colorScheme.primary.text', e.hex);
                  }}
                  color={values?.colorScheme?.primary
                    ? values?.colorScheme?.primary?.text
                    : defaultTextColor}
                  colors={textColors}
                  styles={twitterStyle}
                />

                <div style={{ marginTop: '2rem' }}>
                  <Typography variant="h8">Preview: </Typography>
                  <Button
                    variant="contained"
                    style={{
                      marginLeft: '1rem',
                      padding: '0.5rem',
                      backgroundColor: values?.colorScheme?.primary?.color,
                      width: '50%',
                      color: values?.colorScheme?.primary?.text,
                      fontSize: '1rem',
                    }}
                  >
                    Preview
                  </Button>
                </div>
              </div>
            </div>
          </>

          <div>
            <Typography variant="caption"> Company ID: </Typography>
            <ClipboardCopy copyText={company.id} />
          </div>

          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '0.75rem',
          }}
          >
            <Button
              type="reset"
              variant="contained"
              color="secondary"
              style={{ marginTop: '10px', marginBottom: '10px', width: 200 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: '10px', marginBottom: '10px', width: 200 }}
            >
              Save
            </Button>
          </div>
        </form>

      )}
    </Formik>
  );
};

export default DetailsConfiguration;
