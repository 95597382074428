import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';
import * as facilityActions from '../../../../reducers/facilitiesReducer';
import SiteSelector from '../../../../components/CompanySitePicker';

const styles = () => ({
  root: {
    minWidth: 670,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});

class CreateEventDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      step: 0,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    const { resetNewAmenityStore } = this.props;
    this.setState({ open: false });
    resetNewAmenityStore();
  };

  handleCreateEvent = () => {
    const { createAmenity } = this.props;
    createAmenity();
    this.handleRequestClose();
  }

  render() {
    const isMobile = window.innerWidth < 768;
    const {
      classes, newAmenity, updateNewAmenityStore, companySites,
    } = this.props;
    const { open, step } = this.state;
    const {
      name,
      amount,
      selectedSite,
    } = newAmenity;

    let createButtonDisabled = true;
    if (name !== '' && amount !== null && selectedSite !== '') {
      createButtonDisabled = false;
    }

    return (
      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={() => this.handleClickOpen()}
        >
          Create an amenity
        </Button>
        <Dialog
          open={open}
          fullScreen={isMobile}
          fullWidth
          classes={{ paper: classes.root }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>Create a new amenity</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <DialogContent>
            <div>
              <TextField
                id="name"
                type="text"
                variant="outlined"
                margin="normal"
                label="Amenity name"
                value={name}
                onChange={(event) => updateNewAmenityStore(
                  { name: event.target.value },
                )}
              />
              <SiteSelector
                selectedSite={selectedSite}
                sites={companySites}
                onChange={(event) => updateNewAmenityStore(
                  { selectedSite: event.target.value },
                )}
              />
              <TextField
                label="Base price"
                id="price"
                type="number"
                variant="outlined"
                margin="normal"
                value={amount}
                onChange={(event) => updateNewAmenityStore(
                  { amount: event.target.value },
                )}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary" variant="outlined">
              Close
            </Button>
            <ConditionallyVisible condition={step === 1}>
              <Button onClick={() => this.decreaseStep()} color="secondary" variant="outlined">
                Back
              </Button>
            </ConditionallyVisible>
            <Button
              onClick={() => this.handleCreateEvent()}
              color="primary"
              variant="contained"
              disabled={createButtonDisabled}
            >
              CREATE
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
CreateEventDialog.propTypes = {
  classes: PropTypes.shape().isRequired,
  createAmenity: PropTypes.func.isRequired,
  newAmenity: PropTypes.shape().isRequired,
  companySites: PropTypes.shape().isRequired,
  resetNewAmenityStore: PropTypes.func.isRequired,
  updateNewAmenityStore: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { facilities, sites } = state;
  const { newAmenity } = facilities;
  const { sitesFacilities, companySites } = sites;
  return {
    newAmenity,
    sites: sitesFacilities,
    companySites,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateNewAmenityStore: (value) => dispatch(facilityActions.updateNewAmenityStore(
    value,
  )),
  createAmenity: () => dispatch(facilityActions.createAmenity()),
  resetNewAmenityStore: () => dispatch(facilityActions.resetNewAmenityStore()),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withStyles(styles())(CreateEventDialog));
