import {
  takeEvery, call, put, select,
} from 'redux-saga/effects';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  getMembershipUsersService,
  updateMembershipServices,
  inviteUserToMembershipService,
  removeUserFromMembershipService,
  addUsersToMembershipService,
  createAccessRestrictionsService,
  getAccessRestrictionsService,
  updateAccessRestrictionService,
  getMembershipsService,
} from '../services/accessRestrictionsServices';
import * as actions from '../reducers/accessRestrictionsReducer';
import * as companiesActions from '../reducers/companiesReducer';
import { handleSagaError } from './helperSaga';

function* getAccessRestrictionsSaga(companyId, action) {
  try {
    const { showLiveOnly, arType } = action;
    const response = yield call(
      getAccessRestrictionsService, companyId, showLiveOnly, arType,
    );
    if (!response.error) {
      yield put(actions.updateAccessRestrictions(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getAccessRestrictionsSaga');
    yield put(actions.updateAccessRestrictionsFailed(error.error));
  }
}

function* getMembershipsSaga(companyId) {
  if (companyId) {
    try {
      const response = yield call(getMembershipsService, companyId);

      if (!response.error) {
        yield put(actions.membershipsRetrievedSuccesfully(response.data));
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'getMembershipsSaga');
      yield put(actions.membershipsRetrievedFailed(error.error));
    }
  }
}

function* createAccessRestrictionsSaga(companyId, accessRestrictionAction) {
  const state = yield select();
  const { newAccessRestriction } = state.accessRestrictions;

  const formattedAccessRestriction = {
    ...newAccessRestriction,
    facilityIds: newAccessRestriction.facilities.map((facility) => facility.id),
    membershipIds: newAccessRestriction.memberships.map((membership) => membership.id),
  };

  try {
    const response = yield call(
      createAccessRestrictionsService, companyId, formattedAccessRestriction,
    );
    if (!response.error) {
      const { showLiveOnly, arType } = accessRestrictionAction.getOptions;
      yield put(actions.getAccessRestrictions(showLiveOnly, arType));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'createAccessRestrictionsSaga');
  }
}

function* updateAccessRestrictionSaga(companyId, accessRestrictionAction) {
  const state = yield select();
  let reqBody;

  const { newAccessRestriction } = state.accessRestrictions;
  const { memberships, facilities } = newAccessRestriction;

  if (accessRestrictionAction.requestedOperation === 'DELETE') {
    reqBody = {
      status: 'DELETED',
    };
  } else if (accessRestrictionAction.requestedOperation === 'SAVE') {
    reqBody = {
      ...newAccessRestriction,
      facilityIds: facilities.map((facility) => facility.id),
      membershipIds: memberships.map((membership) => membership.id),
    };
  }

  try {
    const response = yield call(
      updateAccessRestrictionService,
      accessRestrictionAction.id,
      reqBody,
      companyId,
    );
    if (!response.error) {
      const { showLiveOnly, arType } = accessRestrictionAction.getOptions;
      yield put(actions.getAccessRestrictions(showLiveOnly, arType));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'updateAccessRestrictionSaga');
  }
}

function* getMembershipUsersSaga(membershipsAction) {
  try {
    const response = yield call(
      getMembershipUsersService,
      membershipsAction.membershipId,
    );
    if (response.error) {
      throw response.error;
    } else {
      yield put(actions.updateMembershipUsers(
        response.data.user_memberships.filter(
          (um) => um.status === 'CONFIRMED',
        ).map((userMembership) => userMembership.user),
      ));
    }
  } catch (error) {
    yield call(handleSagaError, error, 'getMembershipUsersSaga');
  }
}

function* updateMembershipSaga(membershipsAction) {
  try {
    const response = yield call(
      updateMembershipServices,
      membershipsAction.membershipId,
      membershipsAction.reqBody,
    );
    if (!response.error) {
      yield put(companiesActions.requestCompanyMembershipRetrieval());
      if (membershipsAction.reqBody.status === 'DELETED') {
        yield put(sessionActions.updateSuccessfulSnackbar('Membership deleted successfully', true));
      }
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'updateMembershipSaga');
  }
}

function* inviteUserToMembershipSaga(membershipsAction) {
  try {
    const response = yield call(
      inviteUserToMembershipService,
      membershipsAction.membershipId,
      membershipsAction.reqBody,
    );
    if (!response.error) {
      yield put(actions.getMembershipUsers(membershipsAction.membershipId));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'inviteUserToMembershipSaga');
  }
}

function* addUsersToMembershipSaga(membershipsAction) {
  try {
    const response = yield call(
      addUsersToMembershipService,
      membershipsAction.membershipId,
      membershipsAction.reqBody,
    );
    if (!response.error) {
      yield put(actions.getMembershipUsers(membershipsAction.membershipId));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'addUsersToMembershipSaga');
  }
}

function* removeUserFromMembershipSaga(membershipsAction) {
  try {
    const response = yield call(
      removeUserFromMembershipService,
      membershipsAction.membershipId,
      membershipsAction.reqBody,
    );
    if (!response.error) {
      yield put(actions.getMembershipUsers(membershipsAction.membershipId));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'removeUserFromMembershipSaga');
  }
}

// Watchers
export function* getAccessRestrictionsWatcher(companyId) {
  yield takeEvery(
    actions.ACCESS_RESTRICTIONS_RETRIEVAL_REQUESTED,
    getAccessRestrictionsSaga,
    companyId,
  );
}
export function* createAccessRestrictionsWatcher(companyId) {
  yield takeEvery(
    actions.ACCESS_RESTRICTION_CREATION_REQUEST,
    createAccessRestrictionsSaga,
    companyId,
  );
}
export function* saveAccessRestrictionWatcher(companyId) {
  yield takeEvery(
    actions.SAVE_ACCESS_RESTRICTION_REQUESTED,
    updateAccessRestrictionSaga,
    companyId,
  );
}
export function* updateAccessRestrictionWatcher(companyId) {
  yield takeEvery(
    actions.ACCESS_RESTRICTION_UPDATE_REQUESTED,
    updateAccessRestrictionSaga, companyId,
  );
}
export function* getMembershipsWatcher(companyId) {
  yield takeEvery(actions.GET_MEMBERSHIPS, getMembershipsSaga, companyId);
}
export function* getMembershipUsersWatcher() {
  yield takeEvery(actions.GET_MEMBERSHIP_USERS, getMembershipUsersSaga);
}
export function* updateMembershipWatcher() {
  yield takeEvery(actions.UPDATE_MEMBERSHIP, updateMembershipSaga);
}
export function* inviteUserToMembershipWatcher() {
  yield takeEvery(actions.INVITE_USER_TO_MEMBERSHIP, inviteUserToMembershipSaga);
}
export function* addUsersToMembershipWatcher() {
  yield takeEvery(actions.ADD_USERS_TO_MEMBERSHIP, addUsersToMembershipSaga);
}
export function* removeUserFromMembershipWatcher() {
  yield takeEvery(actions.REMOVE_USER_FROM_MEMBERSHIP, removeUserFromMembershipSaga);
}
