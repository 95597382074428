/* eslint-disable max-len */
import '../../styles/payments.css';
import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible/alternativelyVisible';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import MaterialTable from 'material-table';
import {
  ChevronLeft, ChevronRight, Search,
  SaveAlt, Clear, UnfoldMore as UnfoldMoreIcon, Person as PersonIcon,
} from '@material-ui/icons';
import {
  InputAdornment, Button, TableContainer,
  TablePagination, Typography, Paper, TextField,
} from '@material-ui/core';
import moment from 'moment';
import JSON2CSV from 'json2csv';
import fileDownload from 'js-file-download';
import Share from '@pitchbooking-dev/pb-shared/lib/components/Share/Share';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import TableFormattedName from '../../components/TableFormattedName';
import UserTableSendMessageDialog from './UserTableSendEmailDialog';
import InviteUserDialog from '../../components/InviteUserDialog/InviteUserDialog';
import { BulkInsertUserDialog } from '../../components/BulkInsertUserDialog';
import ActionBar from '../../components/ActionBar';
import LoadingSkeletonDataTable from '../../components/LoadingComponents/LoadingSkeletonDataTable';
import { userMembershipApprovalService } from '../../services/membershipsServices';
import { useToast } from '../../hooks';
import EditSubscriptionDialog from '../../components/Subscriptions/EditSubscriptionDialog';
import ViewMembershipFormDialog from '../memberships/components/ViewMembershipFormDialog';
import * as membershipActions from '../../reducers/membershipsReducer';
import * as userService from '../../services/companyUsersServices';
import ToolTip from '../../components/Tooltip';
import DownloadPdf from '../../components/DownloadPdf';
import { archiveUsers } from '../../services/companiesServices';
import ToggleButtons from '../../components/ToggleButtons';

const displayButtonsData = [{
  buttonTitle: 'All User Memberships',
  buttonValue: 'ALL',
},
{
  buttonTitle: 'Valid Memberships',
  buttonValue: 'VALID',
},
{
  buttonTitle: 'Expired Memberships',
  buttonValue: 'EXPIRED',
},
{
  buttonTitle: 'Other Membership Statuses',
  buttonValue: 'OTHER',
}];

const UserTable = ({
  membershipUsers, isMembersTable, getUsers, membership,
}) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const getMembership = (id) => dispatch(membershipActions.requestMembership(id));
  const [userTableState, setUserTableState] = useState(
    {
      rowsPerPage: 25,
      sendMessageRequested: false,
      changePasswordOpen: false,
    },
  );

  const users = isMembersTable ? membershipUsers : useSelector((state) => state.users.users);
  const totalUsers = useSelector((state) => state.users.totalUsers);
  const isLoading = useSelector((state) => state.users.isLoading);
  const tableRef = useRef(null);

  // TODO: Remove this, send message button should be hidden unless users selected
  const company = useSelector((state) => state.companies.companyInfo);
  const isPowerleague = useSelector((state) => state.companies.companyInfo.isPowerleague) ?? false;
  const [page, setPage] = useState(0);
  const [term, setTerm] = useState(null);
  const selectedUserEmails = useRef([]);
  const selectedUserIds = useRef([]);
  const [isProccessingMembership, setIsProccessingMembership] = useState(false);
  const [isOrderedBy, setIsOrderedBy] = useState(0);
  const [orderedDirection, setOrderedDirection] = useState('asc');
  const [membershipTableView, setMembershipTableView] = useState('ALL');

  const orderTable = (orderBy, page = 0) => {
    const orderField = tableRef.current.dataManager.columns.find(
      (c) => c.tableData.id === orderBy,
    ).field;

    getUsers(page, term, orderField, orderedDirection);
    setIsOrderedBy(orderBy);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    orderTable(isOrderedBy, newPage, orderedDirection, term);
  };

  const checkRow = (checkedRow) => {
    if (checkedRow?.email) {
      if (checkedRow.tableData.checked) {
        // Add to selection
        if (!selectedUserEmails.current.includes(checkedRow.email)) {
          selectedUserEmails.current.push(checkedRow.email);
          selectedUserIds.current.push(checkedRow.id);
        }
      } else {
        // Remove from selection
        selectedUserEmails.current = selectedUserEmails.current.filter(
          (email) => email !== checkedRow.email,
        );
        selectedUserIds.current = selectedUserIds.current.filter(
          (id) => id !== checkedRow.id,
        );
      }
    }
  };

  const handleSelectAll = (rows) => {
    if (rows.length === selectedUserEmails.current.length) {
      // All rows are selected
      selectedUserEmails.current = rows.map((row) => row.email);
      selectedUserIds.current = rows.map((row) => row.id);
    } else {
      // No rows or partial rows are selected
      selectedUserEmails.current = [];
      selectedUserIds.current = [];
    }
  };

  const fireOffSearch = async (searchTerm) => {
    getUsers(0, searchTerm);
    setPage(0);
    setTerm(searchTerm);
  };

  const searchAPIDebounced = AwesomeDebouncePromise(fireOffSearch, 800);

  const handleUserSearch = (value) => {
    searchAPIDebounced(value);
  };

  const handleSendMessageRequestedToggle = (isClose) => {
    if (isClose) {
      selectedUserEmails.current = [];
      selectedUserIds.current = [];
    }
    setUserTableState(
      {
        ...userTableState,
        sendMessageRequested: !userTableState.sendMessageRequested,
      },
    );
  };

  const {
    sendMessageRequested,
  } = userTableState;

  const statusConverter = (status, validTo) => {
    switch (status) {
      case 'CONFIRMED':
        return {
          backgroundColor: validTo ? 'rgb(130, 224, 170, 0.6)' : 'rgb(222, 102, 96, 0.6)',
          borderColor: validTo ? 'rgb(39, 174, 96, 0.6)' : 'rgb(217, 76, 69, 0.6)',
          text: validTo ? 'Active' : 'Expired',
          icon: validTo ? <Check fontSize="small" /> : <Close fontSize="small" />,
        };

      case 'PENDING_PAYMENT':
        return {
          backgroundColor: 'rgb(71, 182, 255, 0.4)',
          borderColor: 'rgb(31, 165, 255, 0.6)',
          text: 'Pending Payment',
          icon: <PriorityHighIcon fontSize="small" />,
        };

      default:
        return {
          backgroundColor: 'rgb(206, 206, 206, 0.6)',
          borderColor: '#C2C2C2',
          text: status,
          icon: <PriorityHighIcon fontSize="small" />,
        };
    }
  };

  const formattedUsers = useMemo(() => {
    const formatted = users ?? [];
    if (formatted.length <= 0) {
      return formatted;
    }

    if (isMembersTable) {
      let filter;
      const today = moment();

      switch (membershipTableView) {
        case 'VALID':
          filter = (userMembership) => !moment(userMembership.validTo).isBefore(today) && userMembership.status === 'CONFIRMED';
          break;

        case 'EXPIRED':
          filter = (userMembership) => moment(userMembership.validTo).isBefore(today) && userMembership.status === 'CONFIRMED';
          break;

        case 'OTHER':
          filter = (userMembership) => userMembership.status !== 'CONFIRMED';
          break;

        default:
          filter = () => true;
          break;
      }

      return formatted.filter(filter).map((userMembership) => {
        const status = statusConverter(userMembership.status, !moment(userMembership.validTo).isBefore(today));
        return ({
          ...userMembership,
          fullName: `${userMembership.user.firstName} ${userMembership.user.lastName}`,
          email: userMembership.user.email,
          phone: userMembership.user.phone,
          dialCode: userMembership.user.dialCode,
          subscription: userMembership.subscription,
          status: (
            <Chip
              avatar={(
                <Avatar style={{
                  backgroundColor: status.backgroundColor,
                  display: 'flex',
                  justifyContent: 'space-around',
                  color: 'white',
                }}
                >
                  {status.icon}
                </Avatar>
              )}
              label={status.text}
              style={{
                backgroundColor: status.backgroundColor,
                color: 'black',
                fontWeight: 'bold',
                border: `2px solid ${status.borderColor}`,
              }}
            />
          ),
        });
      });
    }
    return formatted.map((user) => ({
      ...user,
      fullName: `${user.firstName} ${user.lastName}`,
    }));
  }, [membershipTableView, users]);

  const userRedirection = (row) => {
    if (isMembersTable && row.subscription?.amount > 0) {
      return `${window.location.pathname}/user/${row.userId}`;
    } if (isMembersTable) {
      return `/users/${row.user.id}`;
    }
    return `/users/${row.id}`;
  };

  const fixedColumns = [
    {
      title: 'Name',
      field: 'fullName',
      customFilterAndSearch: (term, row) => {
        const userFullname = `${row.firstName} ${row.lastName}`;
        const userFullnameString = userFullname.toString().toLowerCase();
        return userFullnameString.includes(term.toLowerCase());
      },
      render: (row) => (
        <TableFormattedName
          user={isMembersTable ? row.user : row}
          showTags={!isMembersTable}
          userUrl={userRedirection(row)}
        />
      ),
    },
    { title: 'Email', field: 'email' },
    {
      title: 'Phone Number',
      field: 'phone',
      render: (row) => (
        <ConditionallyVisible condition={!!row.phone}>
          <>
            {`+${row.dialCode} ${row.phone}`}
          </>
        </ConditionallyVisible>
      ),
    },
    { title: 'Team Name', field: 'teamName' },
    {
      title: 'Note',
      field: 'notes',
      customFilterAndSearch: (term, row) => {
        const note = row.notes && row.notes.length > 0 ? row.notes[0].value : '';
        const noteString = note.toString().toLowerCase();
        return noteString.includes(term.toLowerCase());
      },
      export: true,
      render: (row) => {
        const note = row.notes && row.notes.length > 0 ? row.notes[0].value : '';
        return (
          <ConditionallyVisible condition={!!note}>
            <ToolTip overrideTitle={note} />
          </ConditionallyVisible>
        );
      },
    },
  ];

  const userMembershipApproval = async (userMembership, approval) => {
    setIsProccessingMembership(true);
    try {
      const { data } = await userMembershipApprovalService(
        userMembership.membershipId,
        userMembership.id,
        approval,
      );

      if (data) {
        toast.trigger({ type: 'success', message: 'User membership updated successfully' });
        getMembership(userMembership.membershipId);
      }
    } catch (err) {
      console.error(err);
      toast.trigger({ type: 'error', message: 'An issue occurred while attempting to update this membership' });
    }
    setIsProccessingMembership(false);
  };

  const conditionalColumns = [
    ...(isMembersTable && membership?.stripePlan ? [{
      title: 'Created Date',
      field: 'createdAt',
      export: false,
      render: (row) => (
        <>
          {(moment(row.user_membership?.createdAt).format('Do'))}
        </>
      ),
    }] : []),
    ...(isMembersTable ? [{
      title: 'Valid To',
      field: 'user_membership.validTo',
      export: false,
      render: (row) => (
        <>
          {row.validTo === null ? 'Indefinitely'
            : moment(row.validTo).format('DD/MM/YYYY')}
        </>
      ),
    },
    {
      title: 'Status',
      field: 'status',
      export: false,
      render: (row) => (
        <>
          {row.status.props.label === 'PENDING_APPROVAL' ? (
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => userMembershipApproval(row, true)}
                disabled={isProccessingMembership}
              >
                Approve
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => userMembershipApproval(row, false)}
                disabled={isProccessingMembership}
              >
                Reject
              </Button>
            </div>
          ) : (
            <div>
              {row.status}
            </div>
          )}
        </>
      ),
    }, {
      title: 'Subscription',
      field: 'subscription',
      export: false,
      render: (row) => {
        if (!row.subscription) return null;
        const subscription = {
          ...row.subscription,
          accessRestriction: {
            validTo: row.validTo,
            validFrom: row.createdAt,
            weekday: moment(row.createdAt).weekday(),
          },
        };

        return (
          <>
            <EditSubscriptionDialog subscription={subscription} />
          </>
        );
      },
    }, {
      title: 'Membership From',
      field: 'formData',
      export: false,
      render: (row) => (
        <>
          {row.formData
            ? (
              <div style={{ display: 'flex', gap: '0.5rem' }}>
                <ViewMembershipFormDialog
                  formData={row.formData}
                />
              </div>
            ) : null}
        </>
      ),
    },
    ] : []),
  ];

  const columns = [
    ...fixedColumns,
    ...conditionalColumns,
  ];

  const exportUsers = () => {
    const { id } = company;
    userService.exportAllUsersToCSV(id).then((response) => {
      if (response.status === 200) {
        toast.trigger({ type: 'success', message: response.data });
      } else {
        toast.trigger({ type: 'error', message: 'An issue occurred while attempting to export the user data. Please try again.' });
      }
    });
  };

  const handleExport = (columns, data, fileType) => {
    let mappedData = [];
    if (isMembersTable) {
      mappedData = data.map((row) => ({
        name: `${row.firstName} ${row.lastName}`,
        email: row.email,
        phone: row.phone ? `${row.dialCode} ${row.phone}` : '',
        teamName: row.teamName,
        notes: row.notes && row.notes.length > 0 ? row.notes[0].value : '',
        formData: Object.keys(row.user_membership.formData).map((key) => `${key}: ${row.user_membership.formData[key]}`).join(', '),
      }));
    } else {
      mappedData = data.map((row) => ({
        name: `${row.firstName} ${row.lastName}`,
        email: row.email,
        phone: row.phone ? `${row.dialCode} ${row.phone}` : '',
        teamName: row.teamName,
        notes: row.notes && row.notes.length > 0 ? row.notes[0].value : '',
      }));
    }

    if (fileType === 'csv') {
      const csvData = JSON2CSV({ data: mappedData, field: columns, excelStrings: false });
      fileDownload(csvData, 'Pitchbooking Users.csv');
    } else {
      const rowData = mappedData.map((row) => Object.values(row));
      DownloadPdf(Object.keys(mappedData[0]), rowData, 'Pitchbooking Users.pdf');
    }
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <ActionBar>
          <ConditionallyVisible condition={!isMembersTable}>
            <InviteUserDialog />
            <BulkInsertUserDialog />
          </ConditionallyVisible>
          <ConditionallyVisible condition={!isPowerleague}>
            <Button
              id="manager-users-message-users"
              onClick={() => handleSendMessageRequestedToggle()}
              variant="contained"
              color="primary"
            >
              Send Message to Customer(s)
            </Button>
          </ConditionallyVisible>
          <ConditionallyVisible condition={!isMembersTable}>
            <div style={{ marginLeft: '1rem' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => exportUsers()}
              >
                Export Customer List (CSV)
              </Button>
            </div>
            <div style={{ marginLeft: '1rem' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={async () => {
                  if (selectedUserIds.current.length === 0) return;

                  const { data, error } = await archiveUsers(company.id, selectedUserIds.current);
                  if (!error) {
                    window.location.reload();
                  }
                }}
              >
                Archive Customer(s)
              </Button>
            </div>
          </ConditionallyVisible>
        </ActionBar>

        {!isMembersTable && (
        <ActionBar>
          <div style={{ display: 'flex', alignItems: 'self-end' }}>
            <PersonIcon style={{ marginRight: '0.5rem' }} />
            <Typography variant="p" style={{ fontWeight: 'bold' }}>
              {`Total Customers: ${totalUsers}`}
            </Typography>
          </div>
        </ActionBar>
        )}

        {!isMembersTable && (
          <ActionBar>
            <Share
              text={`Book with ${company?.name} on Pitchbooking.`}
              title={`Share ${company?.name} Signup`}
              url={`https://pitchbooking.com/partners/${company?.code}/signup`}
              showLink
            />
            <Typography style={{ fontWeight: 'bold' }}>
              Share Signup Link
            </Typography>
          </ActionBar>
        )}

      </div>

      <AlternativelyVisible condition={isLoading}>
        <LoadingSkeletonDataTable />
        <>
          <ConditionallyVisible condition={!isMembersTable}>
            <div className="pb-table-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <p>
                  Search for an existing customer
                </p>
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
                  }}
                  defaultValue={term}
                  onChange={(event) => handleUserSearch(event.target.value)}
                />
              </div>
              <div style={{ marginTop: 3 }}>
                <TablePagination
                  component="div"
                  // count={users?.length < 50 ? users.length : -1}
                  count={totalUsers}
                  rowsPerPage={50}
                  rowsPerPageOptions={[50]}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </div>
            </div>
          </ConditionallyVisible>
          <div style={{ padding: 10 }}>
            <TableContainer
              style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px', overflowX: 'hidden' }}
              component={Paper}
            >
              <MaterialTable
                tableRef={tableRef}
                onOrderChange={(orderBy) => {
                  // TODO: fix this to work with desc, currently breaks when changing page
                  // if (orderedDirection === 'asc') {
                  //   setOrderedDirection('desc');
                  // } else {
                  //   setOrderedDirection('asc');
                  // }
                  orderTable(orderBy);
                }}
                data={formattedUsers}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
                title={isMembersTable ? (
                  <ToggleButtons
                    buttonsData={displayButtonsData}
                    changeOption={(option) => setMembershipTableView(option)}
                    value={membershipTableView}
                  />
                ) : null}
                icons={{
                  NextPage: () => <ChevronRight />,
                  PreviousPage: () => <ChevronLeft />,
                  Search: () => <Search />,
                  Export: () => <SaveAlt />,
                  ResetSearch: () => <Clear />,
                  SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
                }}
                columns={columns}
                onSelectionChange={(rows) => {
                  if (rows.length === rows.length - 1) {
                    // Handle Select All
                    handleSelectAll(rows);
                  } else {
                    // Handle individual selections
                    rows.forEach((row) => checkRow(row));
                  }
                }}
                options={{
                  search: !!isMembersTable,
                  selection: true,
                  emptyRowsWhenPaging: false,
                  tableLayout: 'auto',
                  paging: !!isMembersTable,
                  exportButton: true,
                  exportCsv: (columns, data) => handleExport(columns, data, 'csv'),
                  exportPdf: (columns, data) => handleExport(columns, data, 'pdf'),
                  exportFileName: 'Pitchbooking Users',
                  pageSize: 50,
                  pageSizeOptions: [10, 25, 50, 100],
                  showFirstLastPageButtons: false,
                  showTitle: true,
                  headerStyle: {
                    backgroundColor: '#efefef',
                    fontWeight: 'bold',
                  },
                  showSelectAllCheckbox: true,
                }}
                localization={{
                  toolbar: {
                    exportTitle: 'Download',
                  },
                }}
              />
            </TableContainer>
            <UserTableSendMessageDialog
              open={sendMessageRequested}
              userEmails={selectedUserEmails.current}
              onClose={(isClose) => handleSendMessageRequestedToggle(isClose)}
              userIds={selectedUserIds.current}
            />
          </div>
        </>
      </AlternativelyVisible>
    </>
  );
};

UserTable.propTypes = {
  isMembersTable: PropTypes.bool,
  membershipUsers: PropTypes.arrayOf(PropTypes.shape({})),
  getUsers: PropTypes.func.isRequired,
  membership: PropTypes.shape(),
};

UserTable.defaultProps = {
  isMembersTable: false,
  membershipUsers: [],
  membership: null,
};

export default UserTable;
