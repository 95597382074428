/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PaymentIcon from '@material-ui/icons/Payment';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TwitterIcon from '@material-ui/icons/Twitter';
import ImageIcon from '@material-ui/icons/Image';
import LightBulbIcon from '@material-ui/icons/WbIncandescent';
import LockIcon from '@material-ui/icons/Lock';
import CardIcon from '@material-ui/icons/CreditCard';
import { useSelector } from 'react-redux';
import PaymentConfiguration from '../PaymentConfiguration';
import DetailsConfiguration from '../DetailsConfiguration';
import SocialsConfiguration from '../SocialsConfiguration';
import LightingConfiguration from '../LightingConfiguration';
import ImageConfiguration from '../ImageConfiguration';
import LockConfiguration from '../LockConfiguration';
import TerminalConfiguration from '../TerminalConfiguration';
import { usePendingChanges } from '../../../hooks';

const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const ConfigurationTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const company = useSelector((state) => state.companies.companyInfo);
  const pending = usePendingChanges();

  let tabs = [
    {
      label: 'Configuration',
      Icon: InfoOutlinedIcon,
      Component: DetailsConfiguration,
      isVisible: true,
    },
    {
      label: 'Payments',
      Icon: PaymentIcon,
      Component: PaymentConfiguration,
      isVisible: true,
    },
    {
      label: 'Social Media',
      Icon: TwitterIcon,
      Component: SocialsConfiguration,
      isVisible: true,
    },
    {
      label: 'Media Configuration',
      Icon: ImageIcon,
      Component: ImageConfiguration,
      isVisible: true,
    },
    {
      label: 'Lighting Control',
      Icon: LightBulbIcon,
      Component: LightingConfiguration,
      isVisible: company.products.lights === 'ENABLED',
    },
    {
      label: 'Lock Control',
      Icon: LockIcon,
      Component: LockConfiguration,
      isVisible: company.products.locks === 'ENABLED',
    },
    {
      label: 'Terminal',
      Icon: CardIcon,
      Component: TerminalConfiguration,
      isVisible: company.products.terminal === 'ENABLED',
    },
  ];

  if (pending) {
    tabs = [
      {
        label: 'Configuration',
        Icon: InfoOutlinedIcon,
        Component: DetailsConfiguration,
        isVisible: true,
      },
      {
        label: 'Social Media',
        Icon: TwitterIcon,
        Component: SocialsConfiguration,
        isVisible: true,
      },
      {
        label: 'Media Configuration',
        Icon: ImageIcon,
        Component: ImageConfiguration,
        isVisible: true,
      },
    ];
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    const tab = searchParams.get('tab');
    if (tab) {
      setValue(Number(tab));
    }
  }, [props.location.search]);

  const handleChange = (event, newValue) => {
    const values = {
      tab: newValue,
    };
    const searchParams = new URLSearchParams(values);
    props.history.push(`${props.location.pathname}?${searchParams.toString()}`);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="secondary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          style={{ backgroundColor: '#fff', display: 'flex' }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.label}
              label={tab.label}
              icon={<tab.Icon />}
              disabled={!tab.isVisible}
            />
          ))}
        </Tabs>
      </AppBar>

      {tabs.map((tab, _idx) => (
        <TabPanel key={tab.label} value={value} index={_idx}>
          <tab.Component />
        </TabPanel>
      ))}
    </div>
  );
};

export default ConfigurationTabs;
