import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  IconButton, List, ListItem, ListItemSecondaryAction, ListItemText,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    background: theme.palette.background.paper,
  },
});

class MembersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [0],
    };
  }

  handleToggle = (value) => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };

  render() {
    const { classes, didClick } = this.props;

    return (
      <div className={classes.root}>
        <List>
          {[0].map((value) => (
            <ListItem
              key={value}
              // dense
              button
              onClick={didClick}
              className={classes.listItem}
            >
              <ListItemText primary="Add New Member" />
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="Add"
                  onClick={didClick}
                >
                  <AddIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

MembersList.propTypes = {
  classes: PropTypes.shape().isRequired,
  didClick: PropTypes.bool.isRequired,
};

export default withStyles(styles)(MembersList);
