import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export const getReferralsService = async (companyId) => {
  const url = `/api/companies/${companyId}/referrals`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
};

export async function createReferralService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/referrals`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
