/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import { useMutation } from '@tanstack/react-query';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import { updateLibraryNotes } from '../../../services/notesServices';
import { useCompany, useToast } from '../../../hooks';
import { OverlayLoader } from '../../../components/OverlayLoader';

export const DeleteLibraryItemDialog = ({ onSuccessCallback, data }) => {
  const { id: noteId, name } = data;
  const company = useCompany();
  const { id: companyId } = company;
  const toast = useToast();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const mutation = useMutation({
    mutationFn: async () => {
      const data = {
        noteId,
        status: 'DELETED',
      };

      const res = await updateLibraryNotes(companyId, data);

      if (res.status !== 200) {
        throw new Error('Error creating exclusion');
      }

      return res.data;
    },
    onSuccess: () => {
      toast.trigger({
        type: 'success',
        message: 'Library item deleted successfully',
      });
      setIsDialogOpen(false);

      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: () => {
      toast.trigger({
        type: 'error',
        message: 'Error deleting library item',
      });
    },
  });

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        style={{
          backgroundColor: 'var(--destructive)',
          color: 'white',
        }}
        startIcon={<DeleteTwoToneIcon />}
        onClick={handleOpen}
      >
        Delete
      </Button>

      <Dialog open={isDialogOpen} onClose={handleClose} fullWidth maxWidth="sm">
        {mutation.isLoading && <OverlayLoader />}

        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6">
              {`Delete library item: ${name}?`}
            </Typography>
            <HighlightOffTwoToneIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
          </div>
        </DialogTitle>

        <DialogContent>
          <Typography variant="body1">
            Please be aware that if you delete the following item:
            <strong>{` ${name} `}</strong>
            it will be
            <strong>{' permanently removed '}</strong>
            from the system.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleClose} disabled={mutation.isLoading}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => mutation.mutate()}
            style={{
              backgroundColor: 'var(--destructive)',
              color: 'white',
            }}
            disabled={mutation.isLoading}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
