import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import '../../styles/payments.css';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import EditIcon from '@material-ui/icons/Edit';
import CopyIcon from '@material-ui/icons/FileCopy';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import TableContainer from '@material-ui/core/TableContainer';
import * as eventsActions from '../../reducers/eventsReducer';
import AssignedAssociateCell from '../leagues/leagueView/AssignedAssociateCell';
import DeleteEventDialog from './components/DeleteEventDialog';

const EventsTable = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [eventsTableState, setEventsTableState] = useState(
    {
      page: 0,
      rowsPerPage: 25,
      selectedUserEmails: [],
      sendMessageRequested: false,
      changePasswordOpen: false,
    },
  );

  const {
    events, isPowerleague, onEdit, isParentEvent, getEvents, readOnly,
  } = props;

  // Only show sub events
  const filteredEventData = events.filter((e) => e.type === 'SUB_EVENT');

  const tableRef = useRef(null);

  const orderTable = (orderBy) => {
    const orderField = tableRef.current.dataManager.columns.find(
      (c) => c.tableData.id === orderBy,
    ).field;

    if (orderField === 'name' || orderField === 'status') {
      getEvents('ACTIVE', orderField);
    }
  };

  return (
    <>
      <div className="table-section">
        <TableContainer
          style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden' }}
          component={Paper}
        >
          <MaterialTable
            tableRef={tableRef}
            onOrderChange={
              (orderBy) => {
                orderTable(orderBy);
              }
            }
            data={isParentEvent ? filteredEventData : events}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            title="Pitchbooking Events"
            icons={{
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              Export: () => <SaveAlt />,
              ResetSearch: () => <Clear />,
              SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
            }}
            columns={[
              {
                title: 'Name',
                field: 'name',
                render: (row) => (
                  <>
                    <a href={`/events/${row.id}`}>
                      {row.name}
                    </a>
                  </>
                ),
              },
              {
                title: 'Date',
                hidden: !isPowerleague,
                render: (row) => (row.startDate && (
                  <>
                    {moment(row.startDate).format('DD/MM/YYYY')}
                    {' '}
                    -
                    {' '}
                    {moment(row.endDate).format('DD/MM/YYYY')}
                  </>
                )),
              },
              {
                title: 'Time',
                hidden: !isPowerleague,
                render: (row) => {
                  if (!row.startTime) return null;

                  const startTime = row.startTime.slice(0, -3);
                  const endTime = row.endTime.slice(0, -3);
                  return (
                    <>

                      {startTime}
                      {' '}
                      -
                      {' '}
                      {endTime}
                    </>
                  );
                },
              },
              {
                title: 'Site',
                hidden: !isPowerleague,
                render: (row) => (row.site && (
                  <>
                    {row.site?.name}
                  </>
                )),
                customFilterAndSearch: (term, row) => {
                  const siteName = row.site?.name || '';
                  const siteNameString = siteName.toString().toLowerCase();
                  return siteNameString.includes(term.toLowerCase());
                },
              },
              {
                title: 'Type',
                hidden: !isPowerleague,
                render: (row) => (row.tags && (
                  row.tags.map((tag) => (
                    <>
                      {tag.value}
                    </>
                  ))
                )
                ),
              },
              {
                title: 'Multi-Event',
                hidden: isParentEvent,
                sorting: false,
                render: (row) => (
                  <>
                    {row.isParentEvent ? 'Yes' : 'No'}
                  </>
                ),
              },
              {
                title: 'Event Capacity',
                sorting: false,
                render: (row) => (
                  <>
                    {row.maximumCapacity ? `${row.maximumCapacity} spaces` : 'N/A'}
                  </>
                ),
              },
              {
                title: 'Start Date',
                sorting: false,
                render: (row) => (
                  <>
                    {row.startDate ? moment(row.startDate).format('LL') : 'N/A'}
                  </>
                ),
              },
              {
                title: 'End Date',
                sorting: false,
                render: (row) => (
                  <>
                    {row.endDate ? moment(row.endDate).format('LL') : 'N/A'}
                  </>
                ),
              },
              {
                title: 'Close Date',
                sorting: false,
                render: (row) => (
                  <>
                    {row.closeDate ? moment(row.closeDate).add(1, 'second').format('LL [@] hh:mm a') : 'N/A'}
                  </>
                ),
              },
              {
                title: 'Visible On Partners Page',
                field: 'status',
                filtering: false,
                hidden: readOnly,
                render: (row) => {
                  const statusIcons = row.status === 'INACTIVE'
                    ? (<div className="keys inactiveKey" style={{ marginLeft: '10px' }} />)
                    : (<div className="keys activeKey" style={{ marginLeft: '10px' }} />);
                  return (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {statusIcons}
                    </div>
                  );
                },
              },
              {
                title: 'Open For Booking',
                field: 'isInternal',
                filtering: false,
                hidden: readOnly,
                render: (row) => {
                  const statusIcons = row.isInternal
                    ? (<div className="keys inactiveKey" style={{ marginLeft: '10px' }} />)
                    : (<div className="keys activeKey" style={{ marginLeft: '10px' }} />);
                  return (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {statusIcons}
                    </div>
                  );
                },
              },
              {
                field: 'actions',
                sorting: false,
                hidden: readOnly,
                render: (row) => (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        const data = { ...row, isDuplicate: true };
                        delete data.id;
                        delete data.name;
                        if (data.form) {
                          delete data.form.id;
                        }
                        onEdit(data);
                      }}
                    >
                      <CopyIcon />
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => onEdit(row)}
                    >
                      <EditIcon />
                    </Button>
                    <DeleteEventDialog
                      eventId={row.id}
                    />
                  </div>
                ),
              },
              {
                title: 'Add staff',
                field: 'staff',
                filtering: false,
                hidden: !isPowerleague,
                render: (row) => (
                  <div>
                    <AssignedAssociateCell event={row} />
                  </div>
                ),
              },
            ]}
            options={{
              sorting: true,
              // customSort: (a, b) => console.log('a.name :', a.name, 'b.name :', b.name),
              search: true,
              selection: false,
              emptyRowsWhenPaging: false,
              tableLayout: 'auto',
              exportButton: false,
              exportFileName: 'Pitchbooking Users',
              pageSize: 50,
              pageSizeOptions: [10, 25, 50, 100],
              showFirstLastPageButtons: false,
              showTitle: false,
              headerStyle: {
                backgroundColor: '#efefef',
                fontWeight: 'bold',
              },
            }}
            localization={{
              toolbar: {
                exportTitle: 'Download',
              },
            }}
          />
        </TableContainer>
      </div>
    </>
  );
};

EventsTable.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isPowerleague: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  isParentEvent: PropTypes.bool,
  getEvents: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

EventsTable.defaultProps = {
  isParentEvent: false,
  readOnly: false,
};

function mapStateToProps(state) {
  const {
    editingEvent,
  } = state.events;
  const { isPowerleague } = state.companies.companyInfo;
  return {
    editingEvent,
    isPowerleague,
  };
}

const mapDispatchToProps = (dispatch) => ({
  toggleEventEditing: (isEditing) => dispatch(eventsActions.toggleEventEditing(isEditing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventsTable);
