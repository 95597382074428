import React from 'react';
import { useFormikContext } from 'formik';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';

import { InputAdornment } from '@material-ui/core';
import BasicTooltip from '../../../../../components/BasicToolTip';

import lang from '../lang';

import '../styles.css';

// Form Step 2
const LeagueMatchDetailsForm = () => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
  } = useFormikContext();

  return (
    <>
      <div>Match details</div>

      <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns: '1fr 1fr' }}>
        <PBInput
          id="maxTeams"
          variant="outlined"
          isError={touched?.maxTeams && Boolean(errors.maxTeams)}
          errorMessage={errors.maxTeams}
          value={values.maxTeams}
          onChange={handleChange}
          label="Maximum Number of Teams"
          type="number"
          onBlur={handleBlur}
          required
        />
        <PBInput
          id="teamsPlayOtherTeams"
          variant="outlined"
          isError={touched?.teamsPlayOtherTeams && Boolean(errors.teamsPlayOtherTeams)}
          errorMessage={touched?.teamsPlayOtherTeams && errors.teamsPlayOtherTeams}
          value={values.teamsPlayOtherTeams}
          onChange={handleChange}
          label="Times to play each other"
          type="number"
          onBlur={handleBlur}
          required
        />

        <PBInput
          id="costPerFixture"
          variant="outlined"
          isError={touched?.costPerFixture && Boolean(errors.costPerFixture)}
          errorMessage={touched?.costPerFixture && errors.costPerFixture}
          value={values.costPerFixture}
          onChange={handleChange}
          label="Cost Per Fixture"
          type="number"
          required
          onBlur={handleBlur}
          InputProps={{
            className: 'text-field',
            startAdornment: (
              <InputAdornment position="start">
                <BasicTooltip title={lang.costPerFixtureTooltip} />
              </InputAdornment>
            ),
          }}
        />

        {/* <PBInput
          id="maxRefereePayment"
          variant="outlined"
          isError={touched?.maxRefereePayment && Boolean(errors.maxRefereePayment)}
          errorMessage={touched?.maxRefereePayment && errors.maxRefereePayment}
          value={values.maxRefereePayment}
          onChange={handleChange}
          label="Max Referee Payment"
          type="number"
          onBlur={handleBlur}
          InputProps={{
            className: 'text-field',
            startAdornment: (
              <InputAdornment position="start">
                <BasicTooltip title={lang.maxRefereePaymentTooltip} />
              </InputAdornment>
            ),
          }}
        /> */}

      </div>
    </>
  );
};

export default LeagueMatchDetailsForm;
