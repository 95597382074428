import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { CategoriesWrapper } from './components/categories/CategoriesWrapper';
import HeaderHighlights from './components/header/HeaderHighlights';
import { SettingsWrapper } from './components/header/SettingsWrapper';
import {
  retrieveAnalyticsEventBookings,
  retrieveAnalyticsEvents,
  setAnalyticsEventBookingsType,
  setAnalyticsEventTimeframe,
} from '../../reducers/analyticsReducer';
import { getFromToDates, getFromToDatesPrevious } from './helper';
import './styles/eventAnalytics.css';

class EventAnalytics extends React.Component {
  /*
    The conditional statements prevent re-retrieving bookings and
    event data if REAL data is already in the Redux store.

    A good example of this is if a manager uses the ANALYTICS tab
    and then navigates away to the USERS tab. When the manager navigates
    back to the ANALYTICS tab, the component will mount again. However,
    REAL data is still in the Redux store. Hence, we do not want to
    re-retrieve and overwrite this data.
  */
  componentDidMount() {
    const {
      analyticsEvents, retrieveAnalyticsEvents,
    } = this.props;
    if (analyticsEvents.formattedEvents.length === 1
      && analyticsEvents.formattedEvents[0]?.id === '') {
      retrieveAnalyticsEvents();
    }
  }

  componentDidUpdate(previousProps) {
    const { analyticsEvents, retrieveAnalyticsEventBookings } = this.props;
    if (previousProps.analyticsEvents.eventIDs.length
      !== analyticsEvents.eventIDs.length) {
      const defaultDates = getFromToDates('This_Month');
      const defaultDatesPrevious = getFromToDatesPrevious('This_Month');
      const defaultSettings = {
        bookingType: 'Created',
        dates: defaultDates,
        datesPrevious: defaultDatesPrevious,
        eventIDs: analyticsEvents.eventIDs,
        eventNames: analyticsEvents.eventNames,
      };
      retrieveAnalyticsEventBookings(defaultSettings);
    }
  }

  render() {
    const { analyticsEventBookings } = this.props;
    return (
      <div className="analytics">

        <ConditionallyVisible condition={analyticsEventBookings.lastUpdated}>
          <div style={{ marginTop: '1rem' }}>
            <strong>{`Last Updated - ${moment(analyticsEventBookings.lastUpdated).format('Do MMM, HH:mm:ss')}`}</strong>
          </div>
        </ConditionallyVisible>
        <div className="headerRow">
          <HeaderHighlights />
          <SettingsWrapper />
        </div>
        <CategoriesWrapper />
      </div>
    );
  }
}

EventAnalytics.propTypes = {
  analyticsEventBookings: PropTypes.shape().isRequired,
  analyticsEvents: PropTypes.shape().isRequired,
  retrieveAnalyticsEventBookings: PropTypes.func.isRequired,
  retrieveAnalyticsEvents: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  retrieveAnalyticsEventBookings,
  retrieveAnalyticsEvents,
  setAnalyticsEventBookingsType,
  setAnalyticsEventTimeframe,
};

const mapStateToProps = (state) => ({
  companyInfo: state.companies.companyInfo,
  analyticsEventBookings: state.analytics.analyticsEventBookings,
  analyticsEvents: state.analytics.analyticsEvents,
});

export default connect(mapStateToProps, mapDispatchToProps)(EventAnalytics);
