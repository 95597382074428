// @flow weak

import React, { useState } from 'react';

import { connect } from 'react-redux';
import {
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import TextField from '@material-ui/core/TextField';
import * as actions from '../../reducers/invoicesReducer';

const InvoiceNoteSection = ({ updateInvoice, invoiceId, existingNote }) => {
  const [stage, setStage] = useState('initial');
  const [notes, setNotes] = useState('');
  const updateAndSave = () => {
    updateInvoice(invoiceId, { notes });
    setStage('save');
  };
  const updateNotes = (notes) => {
    setStage('edit');
    setNotes(notes);
  };
  if (existingNote && stage === 'initial') {
    setStage('save');
  }
  if (existingNote && stage === 'add' && !notes) {
    setNotes(existingNote);
  }

  return (
    <div>
      <ConditionallyVisible condition={stage === 'initial'}>
        <Button
          onClick={() => setStage('add')}
        >
          Add Note
        </Button>
      </ConditionallyVisible>
      <div style={{
        display: 'flex', alignItems: 'center', 'flex-direction': 'row', justifyContent: 'flex-start',
      }}
      >
        <ConditionallyVisible condition={stage === 'add' || stage === 'save' || stage === 'edit'}>
          <TextField
            id="notes"
            label="Add note"
            variant="outlined"
            inputProps={{ 'max-width': '100px' }}
            disabled={stage === 'save'}
            onChange={(event) => updateNotes(event.target.value)}
            value={stage === 'save' ? existingNote : notes}
          />
          <div style={{ width: '5px' }} />
          <Button
            onClick={() => (stage === 'save' ? setStage('add') : updateAndSave())}
          >
            {stage === 'save' ? 'Edit' : 'Add'}
          </Button>
        </ConditionallyVisible>
      </div>
    </div>
  );
};

InvoiceNoteSection.propTypes = {
  updateInvoice: PropTypes.func.isRequired,
  invoiceId: PropTypes.string.isRequired,
  existingNote: PropTypes.string,
};

InvoiceNoteSection.defaultProps = {
  existingNote: null,
};

const mapDispatchToProps = (dispatch) => ({
  updateInvoice: (invoiceId, keyValue) => dispatch(actions.updateInvoice(invoiceId, keyValue)),
});

export default connect(null, mapDispatchToProps)(InvoiceNoteSection);
