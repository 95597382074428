/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../../../styles/dashboard.css';
import { paymentStatusTextColourGenerator } from '../../../helpers';

const subscriptionsCard = ({
  booking,
}) => {
  const {
    fullName, facilityName, startTime, paymentStatusText,
    endTime, subscriptionId, facility, paid, user,
  } = booking;

  const {
    timezone,
  } = facility;

  const {
    teamName,
  } = user;

  const paymentStatusStyleColor = paymentStatusTextColourGenerator(paid, paymentStatusText);

  return (
    <Link
      href={`/subscriptions/${subscriptionId}`}
      style={{ color: '#333', textDecoration: 'none' }}
      to={`/subscriptions/${subscriptionId}`}
    >
      <div className="dashboard-subsriptions-card">
        <div className="dashboard-subsriptions-card-contents">
          <div className="dashboard-subsriptions-card-title">
            {fullName}
          </div>
          <div className="dashboard-card-title">
            {teamName}
          </div>
          {facilityName}
          <div className="dashboard-card-reservation-time">
            {moment(startTime).tz(timezone).format('LT')}
            {' - '}
            {moment(endTime).tz(timezone).add(1, 'minutes').format('LT')}
          </div>
          <div className="dashboard-subsriptions-card-paymentstatus">
            <div style={{ color: paymentStatusStyleColor }}>
              {paymentStatusText}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

subscriptionsCard.propTypes = {
  booking: PropTypes.shape().isRequired,
  startTime: PropTypes.string.isRequired,
};

export default (subscriptionsCard);
