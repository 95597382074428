import React, { useEffect, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import * as Yup from 'yup';
import { Formik, Form, useFormikContext } from 'formik';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { Tooltip, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';
import defaultForm from './DefaultCustomForm.json';
import { MinMaxDobDialog } from './MinMaxDobDialog';
import { toggleEventFormDobDialog } from '../../../reducers/eventsReducer';

export const validationSchema = Yup.object().shape({
  form: Yup.object().shape({
    id: Yup.string().nullable(),
    formData: Yup.array(Yup.object().shape({
      name: Yup.string().required('Required'),
      label: Yup.string().required('Required'),
      type: Yup.string().required('Required'),
      status: Yup.boolean(),
      required: Yup.boolean(),
      value: Yup.string(),
      section: Yup.string(),
    })).min(1),
  }),
});

const EventFormForm = () => {
  const dispatch = useDispatch();
  const eventFormDobDialog = useSelector((state) => state.events.eventFormDobDialog);
  const {
    values, handleChange, handleBlur, errors, touched, setFieldValue,
  } = useFormikContext();
  const minAge = values?.form?.formData?.find((field) => field.name === 'minAge') || null;
  const maxAge = values?.form?.formData?.find((field) => field.name === 'maxAge') || null;

  const sections = useMemo(() => {
    if (!values.form || !values.form.formData) return [];

    return values.form.formData.reduce((acc, curr) => {
      if (!acc.includes(curr.section)) {
        if (curr.section !== 'validation') acc.push(curr.section);
      }

      return acc;
    }, []);
  }, [values.form]);

  useEffect(() => {
    if (!values.form) {
      setFieldValue('form.id', null);
      setFieldValue('form.formData', defaultForm);
    }
  }, []);

  if (!values.form) return null;

  return (
    <>
      {eventFormDobDialog && (
        <MinMaxDobDialog
          isOpen={eventFormDobDialog}
          formData={values.form.formData}
          onSubmit={(validationData) => {
            let updatedValues = [...values.form.formData.filter((x) => x.name !== 'minAge' && x.name !== 'maxAge')];
            if (validationData) {
              if (validationData.minAge !== null) {
                updatedValues.push({
                  name: 'minAge',
                  label: 'Minimum Age',
                  type: 'DATE',
                  status: true,
                  required: true,
                  value: validationData.minAge,
                  section: 'validation',
                });
              }

              if (validationData.maxAge !== null) {
                updatedValues.push({
                  name: 'maxAge',
                  label: 'Maximum Age',
                  type: 'DATE',
                  status: true,
                  required: true,
                  value: validationData.maxAge,
                  section: 'validation',
                });
              }

              updatedValues = updatedValues.filter(
                (item) => !(item.value === null || item.value === ''),
              );

              setFieldValue('form.formData', updatedValues);
            }
          }}
        />
      )}

      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {sections.map((section) => (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} key={section}>
            {/* Section Title */}
            <div>
              <Typography>{section}</Typography>
              <Divider />
            </div>

            {/* Columns */}
            <div style={{
              display: 'grid',
              gap: '1rem',
              gridTemplateColumns: section !== 'Custom Consent'
                ? 'repeat(5, 1fr)'
                : 'repeat(5, 1fr)',
            }}
            >
              {/* Label */}
              <div style={{
                gridColumn: section !== 'Custom Consent'
                  ? 'span 2'
                  : 'span 1',
              }}
              >
                Label
              </div>

              {/* Field Name */}
              {section === 'Custom Consent'
                ? (<div style={{ gridColumn: 'span 1' }}>Field Name</div>)
                : null}

              {/* Field Type */}
              <div>Field Type</div>

              {/* Optional */}
              {section !== 'Custom Consent' ? <div>Optional</div> : null}

              {/* Required */}
              <div>Required</div>

              {/* Delete */}
              {section === 'Custom Consent' ? <div>Delete?</div> : null}

              {/* Form Data */}
              {values.form.formData && values.form.formData.length > 0 ? (
                <>
                  {values.form.formData.filter((x) => x.section === section).map((field) => {
                    const fieldIndex = values.form.formData.findIndex((x) => x.name === field.name);

                    return (
                      <React.Fragment key={fieldIndex}>
                        {/* Label */}
                        <div style={{
                          gridColumn: section !== 'Custom Consent'
                            ? 'span 2'
                            : 'span 1',
                          alignSelf: 'center',
                        }}
                        >
                          <Typography variant="subheading">
                            {field.label}
                          </Typography>
                        </div>

                        {/* Field Name */}
                        {section === 'Custom Consent' ? (
                          <div style={{
                            gridColumn: section !== 'Custom Consent'
                              ? 'none'
                              : 'span 1',
                            alignSelf: 'center',
                          }}
                          >
                            <Typography variant="subheading">{field.name}</Typography>
                          </div>
                        ) : null}

                        {/* Consent type */}
                        <div style={{ alignSelf: 'center' }}>
                          <Typography variant="subheading">
                            {field.type === 'CONSENT'
                              ? 'Yes/No'
                              : field.type.toLowerCase()}
                          </Typography>
                        </div>

                        {section !== 'Custom Consent' && (
                        <>
                          <div style={{
                            gridColumn: 'span 1',
                            alignSelf: 'center',
                          }}
                          >
                            <div style={{ alignSelf: 'center' }}>
                              <PBInput
                                id={`form.formData.${fieldIndex}.status`}
                                type="toggle"
                                value={field.status}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={field.required}
                              />
                            </div>
                          </div>
                        </>
                        )}

                        {/* Required Toggle */}
                        <div style={{ alignSelf: 'center', display: 'flex', alignItems: 'center' }}>
                          <PBInput
                            id={`form.formData.${fieldIndex}.required`}
                            type="toggle"
                            value={field.required}
                            onChange={(e) => {
                              handleChange(e);

                              if (e.target.checked) {
                                if (field.name === 'dateOfBirth') {
                                  dispatch(toggleEventFormDobDialog(true));
                                }
                                setFieldValue(`form.formData.${fieldIndex}.status`, true);
                              }
                            }}
                            onBlur={handleBlur}
                          />
                          {field.name === 'dateOfBirth' && (
                            (minAge || maxAge) && (
                              <Tooltip title="Click here to edit DOB validation">
                                <ErrorTwoToneIcon
                                  color="secondary"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => dispatch(toggleEventFormDobDialog(true))}
                                />
                              </Tooltip>
                            )
                          )}
                        </div>

                        {/* Delete */}
                        {section === 'Custom Consent' && (
                        <div style={{ alignSelf: 'center' }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              const newFormData = [...values.form.formData];
                              newFormData.splice(fieldIndex, 1);
                              setFieldValue('form.formData', newFormData);
                            }}
                          >
                            <DeleteIcon />
                          </Button>
                        </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </>
              ) : null}
            </div>
          </div>
        ))}

        <Divider />
        <div>
          <Typography>Additional form fields</Typography>
          <Typography variant="body2">
            Field name will be used to identify the field form in the manager dashboard.
            Display text will be displayed to the user when they are completing the form.
            You cannot have two Field Names that are the same.
          </Typography>
        </div>
        <Divider />

        <CustomForm
          onSubmit={(customVal) => {
            setFieldValue('form.formData', [...values.form.formData, customVal]);
          }}
        />
      </div>
    </>
  );
};

// eslint-disable-next-line react/prop-types
const CustomForm = ({ onSubmit }) => {
  const initialValues = {
    name: '',
    label: '',
    type: 'TEXT',
    status: true,
    required: false,
    value: '',
    section: 'CUSTOM',
  };
  const validation = Yup.object().shape({
    name: Yup.string().required('Field Name is a Required Field'),
    label: Yup.string().required('Display Text is a Required Field'),
    required: Yup.boolean(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={(values, { resetForm }) => {
        onSubmit({
          ...values,
          status: true,
          // value: values.type === 'CONSENT' ? false : null,
          section: 'Custom Consent',
        });
        resetForm();
      }}
    >
      {({
        values, errors, touched, handleChange, handleBlur, setFieldValue,
      }) => (
        <Form>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <PBInput
              id="name"
              label="Field Name"
              type="text"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={touched.name && Boolean(errors.name)}
              errorMessage={touched.name && errors.name}
            />
            <PBInput
              id="label"
              label="Display Text"
              type="text"
              value={values.label}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={touched.label && Boolean(errors.label)}
              errorMessage={touched.label && errors.label}
            />
            <PBInput
              id="type"
              label="Field Type"
              type="select"
              options={[
                { value: 'TEXT', label: 'Text' },
                { value: 'CONSENT', label: 'Yes/No' },
              ]}
              value={values.type}
              onChange={(e) => setFieldValue('type', e.target.value)}
              onBlur={handleBlur}
              isError={touched.label && Boolean(errors.label)}
              errorMessage={touched.label && errors.label}
            />
            <PBInput
              id="required"
              label="Field is required?"
              type="toggle"
              value={values.required}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
              >
                Add Form Field
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EventFormForm;
