import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import HelpIcon from '@material-ui/icons/Help';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import Tooltip from '@material-ui/core/Tooltip';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { DayPickerSingleDateController, isInclusivelyBeforeDay } from 'react-dates';
import MenuHeading from '../../../../components/MenuHeading';
import { useCompany } from '../../../../hooks';

const styles = {
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
};

export const validationSchema = Yup.object().shape({
  membershipName: Yup.string().required('Name is required'),
  membershipDescription: Yup.string().required('Description is required'),
  additionalEmailInformation: Yup.string(),
  membershipFee: Yup.boolean(),
  price: Yup.number().when('membershipFee', {
    is: true,
    then: Yup.number().required('Price is required'),
  }),
  recurrence: Yup.string().when('membershipFee', {
    is: true,
    then: Yup.string().required('Recurrence is required'),
    otherwise: Yup.string().nullable(),
  }),
  costCode: Yup.string(),
  taxRate: Yup.number(),
  visible: Yup.boolean(),
  requiresApproval: Yup.boolean(),
  isForm: Yup.boolean(),
  applyValidityPeriod: Yup.boolean(),
  validFrom: Yup.date().when('applyValidityPeriod', {
    is: true,
    then: Yup.date().required('Valid From is required').max(Yup.ref('validTo'), 'Valid From must be before Valid To'),
    otherwise: Yup.date().nullable(),
  }),
  validTo: Yup.date().when('applyValidityPeriod', {
    is: true,
    then: Yup.date().required('Valid To is required').min(Yup.ref('validFrom'), 'Valid To must be after Valid From'),
    otherwise: Yup.date().nullable(),
  }),

});

export const initialValues = {
  membershipName: '',
  membershipDescription: '',
  membershipFee: false,
  additionalEmailInformation: '',
  price: 0,
  recurrence: null,
  costCode: '',
  taxRate: 0.2,
  visible: false,
  requiresApproval: false,
  isForm: false,
  applyValidityPeriod: false,
  validFrom: null,
  validTo: null,
};

const NewMembershipForm = ({
  isError,
  onValuesChanged,
}) => {
  const { products } = useCompany();
  const { paidMemberships } = products;
  const {
    values,
    errors,
    handleChange,
    touched,
    handleBlur,
    setFieldValue,
  } = useFormikContext();

  const errorRef = React.useRef(null);

  useEffect(() => {
    if (isError) {
      errorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isError]);

  const [editorState, setEditorState] = useState();
  const [isValidToFocused, setIsValidToFocused] = useState(false);
  const [isValidFromFocused, setIsValidFromFocused] = useState(false);

  return (
    <>
      <div style={styles.inputWrapper}>
        <PBInput
          id="membershipName"
          name="membershipName"
          type="text"
          label="Membership name"
          value={values?.membershipName}
          onChange={handleChange}
          onBlur={handleBlur}
          isError={touched?.membershipName && Boolean(errors.membershipName)}
          errorMessage={touched?.membershipName && errors?.membershipName}
          required
        />
      </div>

      {/* Markdown Description */}
      <>
        <div style={{ marginBottom: '1rem' }}>
          <Editor
            editorStyle={{
              border: '1px solid #ccc', minHeight: '2rem', padding: '0.5rem', borderTop: 'none', borderRadius: '0 0 0.25rem 0.25rem',
            }}
            toolbarStyle={{
              border: '1px solid #ccc', borderRadius: '0.25rem 0.25rem 0 0', margin: 0,
            }}
            editorState={editorState}
            onEditorStateChange={(editorState) => {
              setEditorState(editorState);
              setFieldValue('membershipDescription', JSON.stringify(convertToRaw(editorState.getCurrentContent())));
            }}
            toolbar={{
              options: ['inline', 'blockType', 'list', 'link', 'history'],
              inline: {
                options: ['bold', 'italic'],
              },
              blockType: {
                inDropdown: true,
                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
              },
            }}
            placeholder="Enter your description here..."
            stripPastedStyles
            required
          />
        </div>
        {errors?.membershipDescription && (
        <div style={{ display: 'flex ', color: 'red' }}>{errors?.membershipDescription}</div>
        )}
      </>

      <div style={styles.inputWrapper}>
        <PBInput
          id="additionalEmailInformation"
          name="additionalEmailInformation"
          type="textarea"
          label="Additional Email Information"
          value={values?.additionalEmailInformation}
          onChange={handleChange}
          onBlur={handleBlur}
          isError={touched?.additionalEmailInformation
            && Boolean(errors.additionalEmailInformation)}
          errorMessage={touched?.additionalEmailInformation && errors?.additionalEmailInformation}
        />
      </div>
      <PBInput
        id="applyValidityPeriod"
        name="applyValidityPeriod"
        type="toggle"
        label="Apply a Validity Period"
        value={values?.applyValidityPeriod}
        onChange={handleChange}
        onBlur={handleBlur}
        isError={touched?.applyValidityPeriod && Boolean(errors.applyValidityPeriod)}
        errorMessage={touched?.applyValidityPeriod && errors?.applyValidityPeriod}
      />
      <ConditionallyVisible condition={values?.applyValidityPeriod}>
        <>
          <div style={styles.inputWrapper}>
            <MenuHeading title="Valid From" />
            <DayPickerSingleDateController
              date={values.validFrom ? moment(values.validFrom) : moment()}
              onDateChange={(date) => {
                setFieldValue('validFrom', moment(date).format('YYYY-MM-DD HH:mm'));
              }}
              focused={isValidFromFocused}
              onFocusChange={({ focused }) => setIsValidFromFocused(focused)}
              displayFormat="ddd, DD/MM/YY"
              numberOfMonths={1}
              isOutsideRange={(day) => isInclusivelyBeforeDay(day, moment().subtract(1, 'days'))}
              fullWidth
              noBorder
            />
            <small>
              The Valid From represents when you want the membership to take effect
              {errors?.validFrom && (
                <div style={{ display: 'flex ', color: 'red' }}>{errors?.validFrom}</div>
              )}
            </small>
          </div>
          <div style={styles.inputWrapper}>
            <MenuHeading title="Valid To" />
            <DayPickerSingleDateController
              date={values.validTo ? moment(values.validTo) : moment()}
              onDateChange={(date) => {
                setFieldValue('validTo', moment(date).format('YYYY-MM-DD HH:mm'));
              }}
              focused={isValidToFocused}
              onFocusChange={({ focused }) => setIsValidToFocused(focused)}
              displayFormat="ddd, DD/MM/YY"
              numberOfMonths={1}
              isOutsideRange={(day) => isInclusivelyBeforeDay(day, moment().subtract(1, 'days'))}
              fullWidth
              noBorder
            />
            <small>
              The Valid To represents when you want the membership to end
              {errors?.validTo && (
                <div style={{ display: 'flex ', color: 'red' }}>{errors?.validTo}</div>
              )}
            </small>
          </div>
        </>
      </ConditionallyVisible>
      <div style={styles.inputWrapper}>
        <PBInput
          id="membershipFee"
          name="membershipFee"
          type="toggle"
          disabled={paidMemberships !== 'ENABLED'}
          label="Charge a Membership Fee"
          value={values?.membershipFee}
          onChange={(e) => {
            handleChange(e);
            if (e.target.checked) {
              setFieldValue('price', 0);
              setFieldValue('recurrence', 'MONTHLY');
            } else {
              setFieldValue('price', 0);
              setFieldValue('recurrence', null);
            }
          }}
          onBlur={handleBlur}
          isError={touched?.membershipFee && Boolean(errors.membershipFee)}
          errorMessage={touched?.membershipFee && errors?.membershipFee}
        />
        <Tooltip title="Utilising this feature incurs a commission charge. Please contact your account manager for more information." placement="right">
          <HelpIcon style={{
            color: '#333', marginLeft: '10px', marginRight: '5px', width: '15px',
          }}
          />
        </Tooltip>
        {paidMemberships !== 'ENABLED' && (
        <a href="product/memberships">Learn More</a>
        )}

      </div>
      <ConditionallyVisible condition={values?.membershipFee}>
        <div style={styles.inputWrapper}>
          <PBInput
            id="recurrence"
            name="recurrence"
            type="select"
            label="How often should the customer be charged?"
            value={values.recurrence}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={touched.recurrence && Boolean(errors.recurrence)}
            errorMessage={touched.recurrence && errors.recurrence}
            required={values.membershipFee}
            options={[
              { value: 'MONTHLY', label: 'Monthly' },
              { value: 'ANNUALLY', label: 'Annually' },
            ]}
          />
        </div>
        <div style={styles.inputWrapper}>
          <PBInput
            id="price"
            name="price"
            type="number"
            label={values.recurrence === 'MONTHLY' ? 'Monthly Price' : 'Annual Price'}
            value={values?.price}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={touched?.price && Boolean(errors.price)}
            errorMessage={touched?.price && errors?.price}
            required={values?.membershipFee}
          />
        </div>
        <div style={styles.inputWrapper}>
          <PBInput
            id="costCode"
            name="costCode"
            type="text"
            label="Cost Code"
            value={values?.costCode}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={touched?.costCode && Boolean(errors.costCode)}
            errorMessage={touched?.costCode && errors?.costCode}
          />
        </div>
        <div style={styles.inputWrapper}>
          <PBInput
            id="taxRate"
            name="taxRate"
            type="number"
            label="Tax Rate"
            value={values?.taxRate}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={touched?.taxRate && Boolean(errors.taxRate)}
            errorMessage={touched?.taxRate && errors?.taxRate}
          />
        </div>
      </ConditionallyVisible>
      <div style={styles.inputWrapper}>
        <PBInput
          id="visible"
          name="visible"
          type="toggle"
          label="Make Visible on Partner Page"
          value={values?.visible}
          onChange={handleChange}
          onBlur={handleBlur}
          isError={touched?.visible && Boolean(errors.visible)}
          errorMessage={touched?.visible && errors?.visible}
        />
      </div>
      <div style={styles.inputWrapper}>
        <PBInput
          id="requiresApproval"
          name="requiresApproval"
          type="toggle"
          label="Requires Approval"
          value={values?.requiresApproval}
          onChange={handleChange}
          onBlur={handleBlur}
          isError={touched?.requiresApproval && Boolean(errors.requiresApproval)}
          errorMessage={touched?.requiresApproval && errors?.requiresApproval}
        />
      </div>
      <div style={styles.inputWrapper}>
        <PBInput
          id="isForm"
          name="isForm"
          type="toggle"
          label="Include Custom Form"
          value={values?.isForm}
          onChange={(e) => {
            handleChange(e);
            onValuesChanged(e);
          }}
          onBlur={handleBlur}
          isError={touched?.isForm && Boolean(errors.isForm)}
          errorMessage={touched?.isForm && errors?.isForm}
        />
      </div>
    </>
  );
};

NewMembershipForm.propTypes = {
  isError: PropTypes.bool.isRequired,
  onValuesChanged: PropTypes.func.isRequired,
};

export default NewMembershipForm;
