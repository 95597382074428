import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as leagueActions from '../../../reducers/leaguesReducer';
import CreateLeagueStepper from './CreateLeague/CreateLeagueStepper';
import { useMobile } from '../../../hooks';

const styles = () => ({
  root: {
    minWidth: 400,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});

function CreateLeagueDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleCreateLeague = (values) => {
    const { requestLeagueCreation } = props;
    requestLeagueCreation(values);
    handleRequestClose();
  };

  const isMobile = useMobile();
  const {
    classes,
    sites,
    companyFacilities,
  } = props;
  return (
    <div>
      <Button color="primary" variant="contained" onClick={() => handleClickOpen()}>
        New League
      </Button>
      <Dialog
        classes={{ paper: classes.root }}
        fullScreen={isMobile}
        fullWidth
        onClose={handleRequestClose}
        open={open}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>Create new league</DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => handleRequestClose()} />
          </DialogActions>
        </div>
        <DialogContent>
          <CreateLeagueStepper
            sites={sites}
            facilities={companyFacilities}
            createLeague={(values) => handleCreateLeague(values)}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

CreateLeagueDialog.propTypes = {
  classes: PropTypes.shape().isRequired,
  requestLeagueCreation: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  companyFacilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

function mapStateToProps(state) {
  const { leagues, companies, facilities } = state;
  const { leagueStore } = leagues;
  const { companyInfo } = companies;
  const { sites } = companyInfo;
  const { companyFacilities } = facilities;
  return {
    leagueStore,
    sites,
    companyFacilities,
  };
}

const mapDispatchToProps = (dispatch) => ({
  requestLeagueCreation: (values) => dispatch(leagueActions.requestLeagueCreation(values)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withStyles(styles())(CreateLeagueDialog));
