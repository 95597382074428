import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function SimpleSelect(props) {
  const classes = useStyles();

  const handleChange = (event) => {
    const { onChange } = props;
    onChange(event.target.value);
  };
  const {
    propValue, arrayValue, defaultValue, label,
  } = props;

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>{label}</InputLabel>
        <Select
          id="demo-simple-select-outlined"
          value={propValue}
          defaultValue={defaultValue}
          onChange={handleChange}
          label={label}
          labelId="demo-simple-select-outlined-label"
        >
          {arrayValue.map((value) => (
            <MenuItem value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

SimpleSelect.defaultProps = {
  label: '',
};

SimpleSelect.propTypes = {
  propValue: PropTypes.string.isRequired,
  arrayValue: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};
