/* eslint-disable no-mixed-operators */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import Button from '@material-ui/core/Button';
import * as pricingActions from '../../reducers/pricingReducer';

const PricingGroupDialogActions = ({
  handleSave,
  requestPriceGroupCreation,
  handleRequestToCancel,
  editingPriceGroup,
  disabled,
  priceGroup,
}) => {
  const hasMoreThanOneDefault = useMemo(() => priceGroup.priceModifiers.reduce(
    (acc, curr) => (curr.modifierType === 'DEFAULT' ? [...acc, curr] : acc), [],
  ).length > 1, [priceGroup]);

  const hasNoDefault = useMemo(() => priceGroup.priceModifiers.reduce(
    (acc, curr) => (curr.modifierType === 'DEFAULT' ? [...acc, curr] : acc), [],
  ).length === 0, [priceGroup]);

  const membershipPg = useMemo(() => priceGroup.membershipId !== null, [priceGroup]);

  return (

    <>
      <Button
        onClick={handleRequestToCancel}
        variant="contained"
        color="secondary"
      >
        Cancel
      </Button>

      <AlternativelyVisible condition={editingPriceGroup}>
        <Button
          disabled={disabled || hasMoreThanOneDefault || hasNoDefault && !membershipPg}
          onClick={() => handleSave()}
          variant="contained"
          color="primary"
        >
          Save
        </Button>

        <Button
          disabled={disabled || hasMoreThanOneDefault || hasNoDefault && !membershipPg}
          onClick={() => requestPriceGroupCreation()}
          variant="contained"
          color="primary"
        >
          Create
        </Button>
      </AlternativelyVisible>
    </>
  );
};

PricingGroupDialogActions.propTypes = {
  editingPriceGroup: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  requestPriceGroupCreation: PropTypes.func.isRequired,
  handleRequestToCancel: PropTypes.func.isRequired,
  priceGroup: PropTypes.shape({
    priceModifiers: PropTypes.arrayOf(PropTypes.shape({})),
    membershipId: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  editingPriceGroup: state.pricing.editingPriceGroup,
});

const mapDispatchToProps = (dispatch) => ({
  requestPriceGroupCreation: () => dispatch(
    pricingActions.requestPriceGroupCreation(),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingGroupDialogActions);
