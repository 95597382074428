/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { Button, Divider, Typography } from '@material-ui/core';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

export const GoLive = ({ companyInfo, updateCompany }) => {
  const dispatch = useDispatch();
  const { id, onboarding } = companyInfo;
  const [goLiveDate, setGoLiveDate] = useState(null);
  const [includeGoLiveDate, setIncludeGoLiveDate] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setGoLiveDate(companyInfo.onboarding?.goLiveDate);
    setIncludeGoLiveDate(!!companyInfo.onboarding?.goLiveDate);
  }, [onboarding]);

  return (
    <>
      <Typography style={{ textDecoration: 'underline' }}>
        Go Live Date
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <PBInput
          id="goLiveDate"
          type="toggle"
          label="Set Go Live Date"
          value={includeGoLiveDate}
          onChange={(e) => {
            setIncludeGoLiveDate(e.target.checked);
            if (!e.target.checked) {
              setGoLiveDate(null);
            }
          }}
        />
      </div>
      {(includeGoLiveDate || goLiveDate) && (
      <SingleDatePicker
        date={goLiveDate ? moment(goLiveDate) : moment()}
        onDateChange={(date) => {
          setGoLiveDate(moment(date).format('YYYY-MM-DD HH:mm'));
        }}
        focused={isFocused}
        onFocusChange={({ focused }) => setIsFocused(focused)}
        displayFormat="ddd, DD/MM/YY"
        numberOfMonths={1}
        isOutsideRange={() => false}
        fullWidth
        noBorder
      />
      )}
      <Button
        variant="contained"
        color="primary"
        type="button"
        style={{ width: '50%', alignSelf: 'end' }}
        onClick={() => {
          dispatch(
            updateCompany(id,
              {
                onboarding: {
                  ...companyInfo.onboarding,
                  goLiveDate,
                },
              }),
          );
        }}
      >
        Save
      </Button>

      <Divider />
    </>

  );
};
