import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Button from '@material-ui/core/Button';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import TableContainer from '@material-ui/core/TableContainer';
import MaterialTable from 'material-table';
import DeleteDialog from '../accessRestrictions/components/DeleteAccessRestrictionDialog';
import EditAddonDialog from './components/EditAddonDialog';
import StockTransactionDialog from './StockTransactionDialog';

const AddonTable = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [AddonTableState, setAddonTableState] = useState(
    {
      page: 0,
      rowsPerPage: 25,
      selectedUserEmails: [],
      changePasswordOpen: false,
    },
  );

  const {
    addons,
    hideEdit,
    detailView,
  } = props;

  return (
    <>
      <div>
        <TableContainer
          style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden' }}
          component={Paper}
        >
          <MaterialTable
            data={addons}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            title="Pitchbooking Booking Modifiers"
            icons={{
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              Export: () => <SaveAlt />,
              ResetSearch: () => <Clear />,
              SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
            }}
            columns={[
              {
                title: 'Name',
                filtering: false,
                render: (row) => (
                  <div>
                    { row.isStandalone && !detailView ? (
                      <a href={`/products/${row.id}`}>
                        {row.name}
                      </a>
                    ) : (
                      <div>
                        {row.site?.name}
                        {' - '}
                        {row.name}
                      </div>
                    )}
                  </div>
                ),
              },
              {
                title: 'Current Stock',
                render: (row) => (
                  <div>
                    {(row.currentStock || row.currentStock === 0) && row.trackStock && (
                      <div>
                        {row.currentStock}
                      </div>
                    )}
                    {!row.trackStock && (
                    <div>
                      N/A
                    </div>
                    )}
                    {row.children && (
                      <div>
                        {row.children.map((child) => (
                          <div>
                            {child?.site?.name}
                            {' '}
                            -
                            {' '}
                            {child.currentStock}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ),
              },
              {
                title: 'Manage Stock',
                field: 'edit',
                filtering: false,
                hidden: !detailView,
                render: (row) => (
                  <>
                    {row.trackStock ? (
                      <div style={{ display: 'flex' }}>
                        <StockTransactionDialog addOn={row} />
                      </div>
                    ) : 'N/A'}
                  </>
                ),
              },
              {
                title: 'Edit',
                field: 'edit',
                filtering: false,
                hidden: hideEdit,
                render: (row) => (
                  <>
                    {row.isStandalone ? (
                      <div style={{ display: 'flex' }}>
                        <a href={`/products/${row.id}`}>
                          <Button variant="contained" color="secondary">Edit</Button>
                        </a>
                      </div>
                    ) : (
                      <div style={{ display: 'flex' }}>
                        <EditAddonDialog addon={row} />
                      </div>
                    )}
                  </>
                ),
              },
              {
                title: 'Delete',
                field: 'delete',
                filtering: false,
                render: (row) => (
                  <DeleteDialog
                    name={row.name}
                    bodyTag="Addon"
                    type="ADDON"
                    addonId={row.id}
                  />
                ),
              },
            ]}
            options={{
              search: false,
              selection: false,
              emptyRowsWhenPaging: false,
              tableLayout: 'auto',
              exportButton: false,
              exportFileName: 'Pitchbooking Products',
              pageSize: 50,
              pageSizeOptions: [10, 25, 50, 100],
              showFirstLastPageButtons: false,
              showTitle: false,
              headerStyle: {
                backgroundColor: '#efefef',
                fontWeight: 'bold',
              },
            }}
            localization={{
              toolbar: {
                exportTitle: 'Download',
              },
            }}
          />
        </TableContainer>
      </div>
    </>
  );
};

AddonTable.propTypes = {
  addons: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  hideEdit: PropTypes.bool.isRequired,
  detailView: PropTypes.bool,
};

AddonTable.defaultProps = {
  detailView: false,
};

const mapStateToProps = (state) => ({
  timezone: state.companies.companyInfo.timezone,
  companyFacilities: state.facilities.companyFacilities,
  addon: state.addons.addon,
  addonDialogOpen: state.addons.addonDialogOpen,
});

export default connect(
  mapStateToProps, null,
)(AddonTable);
