import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const Back = ({ history }) => history.length > 1 && (
  <Button
    variant="contained"
    color="secondary"
    onClick={history.goBack}
  >
    Back to previous page

  </Button>
);

Back.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(Back);
