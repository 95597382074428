/* eslint-disable react/prop-types */
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Dialog, DialogTitle, DialogActions, DialogContent, Button,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { DayPickerSingleDateController } from 'react-dates';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { useMobile, useToast } from '../../../../hooks';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';
import { addSubscriptionNoteService } from '../../../../services/subscriptionsServices';

const validationSchema = Yup.object().shape({
  date: Yup.string().required('Date is required'),
  note: Yup.string().min(1, 'Please provide a valid note!').required('Note is required'),
});

export const SubscriptionAddNoteDialog = ({
  isOpen, onClose, onSuccess, subscription,
}) => {
  const queryClient = useQueryClient();
  const isMobile = useMobile();
  const toast = useToast();

  const mutation = useMutation({
    mutationFn: async (data) => {
      queryClient.setQueryData(['subscription', subscription.id], (oldData) => ({
        ...oldData,
        notes: [
          ...oldData.notes,
          {
            id: Math.random(),
            date: data.date,
            value: data.note,
          },
        ],
      }));

      const res = await addSubscriptionNoteService(
        subscription.companyId,
        subscription.id,
        data.note,
        data.date,
      );

      if (res.status !== 200) {
        throw new Error(res.data);
      }

      return res.data;
    },
    onError: (error) => {
      queryClient.invalidateQueries(['subscription', subscription.id]);
      toast.trigger({
        type: 'error',
        message: 'Failed to add note to the subscription!',
      });
      console.error(error);
    },
    onSuccess: () => {
      toast.trigger({
        type: 'success',
        message: 'Subscription note savbed successfully',
      });
      queryClient.invalidateQueries(['subscription', subscription.id]);
      onClose();
      onSuccess?.();
    },
  });

  const { accessRestriction, invoices } = subscription;
  const { weekday, validFrom, validTo } = accessRestriction;

  const invalidDates = invoices?.filter((x) => x.status !== 'VOID').map((inv) => inv.invoice_subscription?.slot);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={isMobile}
      maxWidth="sm"
      fullWidth
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle>Add Note</DialogTitle>
        <DialogActions variant="none">
          <CloseDialogIcon onClick={onClose} />
        </DialogActions>
      </div>

      <Formik
        initialValues={{
          date: moment().format('YYYY-MM-DD'),
          note: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(data) => mutation.mutate(data)}
      >
        {({
          values, setFieldValue, handleSubmit, errors, getFieldProps, touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              minHeight: '380px',
            }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'center',
              }}
              >
                <DayPickerSingleDateController
                  isDayHighlighted={(day) => moment(day).format('YYYY-MM-DD') === values.date}
                  isDayBlocked={(day) => {
                    // Is the day a subscription day?
                    if (moment(day).weekday() !== weekday) return true;

                    // Is the day before the valid from date?
                    if (moment(day).isBefore(moment(validFrom).subtract(1, 'day'))) return true;

                    // Is the day after the valid to date?
                    if (moment(day).isAfter(moment(validTo).add(1, 'day'))) return true;

                    // Is the day an invalid date?
                    if (invalidDates?.some(
                      (x) => moment(x).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD'),
                    )) return true;

                    return false;
                  }}
                  onDateChange={(date) => {
                    setFieldValue('date', date.format('YYYY-MM-DD'));
                  }}
                />
              </div>
              {errors.exclusions && (
                <Typography variant="body2" color="error">
                  {errors.exclusions}
                </Typography>
              )}
              <Typography>
                {`Add a note to the subscription on ${moment(values.date).format('DD/MM/YYYY')}!`}
              </Typography>

              <PBInput
                id="note"
                type="textarea"
                label="Note"
                isError={touched.note && errors.note}
                errorMessage={touched.note && errors.note}
                {...getFieldProps('note')}
              />
            </DialogContent>

            <DialogActions>
              <Button
                type="button"
                onClick={onClose}
                color="secondary"
                autoFocus
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                autoFocus
                disabled={mutation.isLoading}
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
