import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as actions from '../../reducers/addonsReducer';
import CategoryTable from './CategoryTable';

const styles = () => ({
  dialog: {
    width: '820px',
  },
  menuHeading: {
    marginTop: 40,
  },
  textField: {
    width: '100%',
    marginLeft: 15,
  },
  fullWidth: {
    width: '100%',
  },
});

const NewCategoryDialog = ({
  classes, createCategory,
}) => {
  const [open, setOpen] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
    }),
    onSubmit: (values) => {
      createCategory(values);
      setOpen(false);
      formik.resetForm();
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <div>
      <Button onClick={handleClickOpen} variant="contained" color="primary">
        Categories
      </Button>
      <Dialog
        maxWidth="820px"
        open={open}
        onClose={handleRequestClose}
      >
        <DialogTitle>New Category</DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText>
            A category is a group of products that can be grouped together on your POS dashboard.
          </DialogContentText>
          <form onSubmit={formik.handleSubmit}>
            <div style={{ display: 'flex', gap: '15px' }}>
              <TextField
                id="name"
                label="Name"
                type="text"
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <DialogActions>
              <Button onClick={handleRequestClose} variant="outlined" color="secondary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Create
              </Button>
            </DialogActions>
          </form>
          <CategoryTable />
        </DialogContent>
      </Dialog>
    </div>
  );
};

NewCategoryDialog.propTypes = {
  classes: PropTypes.shape().isRequired,
  createCategory: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  createCategory: (reqBody) => dispatch(actions.createCategory(reqBody)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(NewCategoryDialog));
