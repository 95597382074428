/* eslint-disable import/prefer-default-export */
import {
  takeEvery, call, select, put,
} from 'redux-saga/effects';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  requestAllEnquiriesService,
  requestEnquiryService,
  requestSendContentService,
} from '../services/enquiriesServices';
import * as enquiriesActions from '../reducers/enquiriesReducer';
import * as userActions from '../reducers/usersReducer';

function* requestAllEnquiriesSaga(companyId) {
  if (companyId) {
    try {
      const response = yield call(requestAllEnquiriesService, companyId);
      if (!response.error) {
        yield put(enquiriesActions.updateAllEnquiries(response.data));
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Messages Retrieval Failed : ${error}`, true));
    }
  }
}

function* requestEnquirySaga(companyId, action) {
  const { id } = action;
  if (companyId) {
    try {
      const response = yield call(requestEnquiryService, companyId, id);
      if (!response.error) {
        yield put(enquiriesActions.updateThreadStore(response.data));
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Messages Retrieval Failed : ${error}`, true));
    }
  }
}

function* requestSendContentSaga() {
  const state = yield select();

  const reqBody = {
    content: state.enquiries.content,
    companyId: state.enquiries.companyId,
    siteId: state.enquiries.siteId,
    tagId: state.enquiry.selectedEnquiryType.value,
    type: 'MANAGER',
  };
  try {
    const response = yield call(requestSendContentService, reqBody);
    if (!response.error) {
      yield put(sessionActions.updateSuccessfulSnackbar('Message successfully sent.', true));
      yield put(enquiriesActions.resetContentStore());
      if (state.enquiries.content.threadId) {
        yield put(enquiriesActions.requestEnquiry(state.enquiries.content.threadId));
      }
    }
  } catch (error) {
    yield put(sessionActions.updateErrorSnackbar('Message failed to send.', true));
    console.log('requestSendContentSaga', error);
  }
}

/* Watchers */
export function* requestAllEnquiriesWatcher(companyId) {
  yield takeEvery(enquiriesActions.ENQUIRIES_RETRIEVAL_REQUESTED,
    requestAllEnquiriesSaga, companyId);
}
export function* requestEnquiryWatcher(companyId) {
  yield takeEvery(enquiriesActions.ENQUIRY_RETRIEVAL_REQUESTED,
    requestEnquirySaga, companyId);
}
export function* requestSendContentWatcher() {
  yield takeEvery(enquiriesActions.SEND_CONTENT_REQUESTED, requestSendContentSaga);
}
