/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions,
  Typography,
} from '@material-ui/core';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useMobile, useToast } from '../../../hooks';
import { updateFacilityService } from '../../../services/facilitiesServices';
import { getCompanyInfo } from '../../../reducers/companiesReducer';

export const FacilityDeleteModal = ({ isOpen, onClose, facility }) => {
  const isMobile = useMobile();
  const toast = useToast();
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: async () => {
      const res = await updateFacilityService(facility.companyId, facility.id, { status: 'DELETED' });
      if (res.status !== 200) {
        throw new Error('An error occurred while attempting to delete the facility!');
      }
      return res.data;
    },
    onSuccess: () => {
      toast.trigger({
        type: 'success',
        message: 'Facility has successfully been deleted!',
      });
      onClose();
      dispatch(getCompanyInfo());
    },
    onError: (err) => {
      console.error(err);
      toast.trigger({
        type: 'error',
        message: 'An error occurred while attempting to delete the facility!',
      });
    },
  });

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
      onClose={onClose}
    >
      <DialogTitle>
        {`Delete ${facility?.name}`}
      </DialogTitle>

      <DialogContent>
        <Typography>Are you sure you want to delete this facility?</Typography>
      </DialogContent>
      <DialogActions>
        <Button type="button" color="secondary" variant="contained" onClick={onClose} disabled={mutation.isLoading}>
          Close
        </Button>
        <Button color="primary" variant="contained" onClick={() => mutation.mutate()} disabled={mutation.isLoading}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
