import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import GoogleMapsAutoComplete from '../../components/GoogleMapsAutoComplete';
import { updateCompanyAddress } from '../../services/companiesServices';
import * as companyActions from '../../reducers/companiesReducer';

const ChangeCompanyAddressDialog = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.companies.companyInfo.id);
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState({});

  async function updateAddress() {
    const res = await updateCompanyAddress(companyId, address);
    if (res.status === 200) {
      dispatch(companyActions.getCompanyInfo());
      dispatch(sessionActions.updateSuccessfulSnackbar('Your company info has been updated successfully!', true));
      setOpen(false);
    } else {
      dispatch(sessionActions.updateErrorSnackbar('Something went wrong, please try again later', true));
    }
  }

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={() => setOpen(true)}
        style={{ margin: '1rem 0rem' }}
      >
        Change address
      </Button>
      <Dialog
        open={open}
        fullWidth
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>
            Change company address
          </DialogTitle>

          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => setOpen(false)} />
          </DialogActions>
        </div>

        <DialogContent>
          <GoogleMapsAutoComplete
            style={{ width: '120px !important' }}
            locationDescription="Search for new address"
            onChange={(newValue) => setAddress(
              newValue,
              {
                location: newValue.location,
                locationDescription: newValue.locationDescription,
                locationChanged: true,
              },
            )}

          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary" variant="outlined">
            Close
          </Button>
          <Button
            onClick={() => updateAddress()}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChangeCompanyAddressDialog;
