/* eslint-disable import/prefer-default-export */
import {
  takeEvery, call, put, select,
} from 'redux-saga/effects';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  createLeagueService,
  getLeaguesService,
  getLeagueService,
  confirmFixturesService,
  updateLeagueFixtureService,
  linkLeagueToFacilityService,
  inviteUserToLeagueService,
  adjustTeamPointsService,
  createNewFixtureService,
  getLeagueFixtureService,
  submitGoalScorerService,
  assignAssociateService,
  cancelFixtureService,
  removeFixtureAgentService,
  editLeagueFixtureFacilityService,
  deleteLeagueTeamService,
  fetchLeagueFixtures,
  updateLeagueTagService,
  deleteLeagueService,
  updateLeagueFixtureScore,
} from '../services/leaguesServices';
import * as leaguesActions from '../reducers/leaguesReducer';
import * as userActions from '../reducers/usersReducer';
import * as tagActions from '../reducers/tagsReducer';

function* getLeaguesSaga(companyId, action) {
  const { status } = action;
  if (companyId) {
    try {
      const response = yield call(getLeaguesService, companyId, status);
      if (!response.error) {
        yield put(leaguesActions.leaguesRequestSucceeded(response.data));
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Leagues Retrieval Failed : ${error}`, true));
    }
  }
}

function* updateLeagueTagsSaga(companyId, action) {
  const { leagueId } = action;
  const state = yield select();
  const { selectedTags } = state.tags;
  if (companyId) {
    try {
      const reqBody = {
        tags: selectedTags,
      };
      const response = yield call(updateLeagueTagService, leagueId, reqBody, companyId);
      if (!response.error) {
        yield put(leaguesActions.getLeague(leagueId));
        yield put(sessionActions.updateSuccessfulSnackbar('League tags successfully updated.', true));
      }
    } catch (error) {
      yield put(sessionActions.updateErrorSnackbar('League tags failed to update.', true));
      console.log('updateLeagueTagsSaga', error);
    }
  }
}

function* confirmFixturesSaga(companyId, action) {
  const { id, games } = action;
  if (companyId) {
    try {
      const response = yield call(confirmFixturesService, companyId, games, id);
      if (!response.error) {
        yield put(leaguesActions.getLeague(id));
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Leagues Retrieval Failed : ${error}`, true));
    }
  }
}

function* getLeagueSaga(companyId, action) {
  const { id } = action;
  if (companyId) {
    try {
      const response = yield call(getLeagueService, companyId, id);
      if (!response.error) {
        yield put(leaguesActions.leagueRequestSucceeded(response.data));
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Leagues Retrieval Failed : ${error}`, true));
    }
  }
}

function* recordLeagueResultSaga(companyId, action) {
  yield put(leaguesActions.isLeagueUpdating(true));
  const state = yield select();
  const { league } = state.leagues;
  const { fixtureId } = action;
  let fixture = league.fixtures.find((fixture) => fixture.id === fixtureId);
  fixture = {
    ...fixture,
    teamAScore: fixture.teamAScore ? fixture.teamAScore : 0,
    teamBScore: fixture.teamBScore ? fixture.teamBScore : 0,
    costPerFixture: action.fixtureCost,
  };
  if (companyId) {
    try {
      const response = yield call(
        updateLeagueFixtureService, companyId, fixtureId, fixture,
      );
      if (!response.error) {
        yield put(leaguesActions.getLeague(league.id));
        yield put(sessionActions.updateSuccessfulSnackbar('Score Updated Successfully!', true));
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Leagues Retrieval Failed : ${error}`, true));
    }
  }
  yield put(leaguesActions.isLeagueUpdating(false));
}
function* getLeagueFixtureSaga(companyId, action) {
  const { fixtureId } = action;
  if (companyId) {
    try {
      const response = yield call(getLeagueFixtureService, companyId, fixtureId);
      if (!response.error) {
        yield put(leaguesActions.updateFixture(response.data));
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Leagues Retrieval Failed : ${error}`, true));
    }
  }
}

function* confirmFacilityLinkSaga(companyId) {
  const state = yield select();
  const { leagueStore, league } = state.leagues;
  const leagueId = league.id;
  const reqBody = {
    facilities: leagueStore.facilities.map((facility) => facility.id),
    gameLength: leagueStore.gameLength,
    startTime: leagueStore.startTime,
    startDate: leagueStore.startDate,
  };
  if (companyId) {
    try {
      const response = yield call(linkLeagueToFacilityService, companyId, leagueId, reqBody);
      if (!response.error) {
        yield put(leaguesActions.getLeague(league.id));
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Leagues Retrieval Failed : ${error}`, true));
    }
  }
}

function* inviteUserToLeagueSaga(companyId) {
  const state = yield select();
  const { league } = state.leagues;
  const { invitedUser } = league;
  const leagueId = league.id;
  if (companyId) {
    try {
      const response = yield call(inviteUserToLeagueService, companyId, leagueId, invitedUser);
      if (!response.error) {
        yield put(leaguesActions.getLeague(league.id));
        yield put(sessionActions.updateSuccessfulSnackbar('User has been invited to this league.', true));
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Leagues Retrieval Failed : ${error}`, true));
    }
  }
}
function* adjustTeamPointsSaga(companyId, action) {
  const reqBody = {
    ...action,
  };
  const { leagueId } = action;
  if (companyId) {
    try {
      const response = yield call(adjustTeamPointsService, companyId, leagueId, reqBody);
      if (!response.error) {
        yield put(leaguesActions.getLeague(leagueId));
        yield put(sessionActions.updateSuccessfulSnackbar('Points Adjustment confirmed', true));
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Leagues Retrieval Failed : ${error}`, true));
    }
  }
}

function* createNewFixtureSaga(companyId, action) {
  const reqBody = {
    ...action,
  };
  const { leagueId } = action;
  if (companyId) {
    try {
      const response = yield call(createNewFixtureService, companyId, leagueId, reqBody);
      if (!response.error) {
        yield put(leaguesActions.getLeague(leagueId));
        yield put(sessionActions.updateSuccessfulSnackbar('New Fixture Created', true));
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Leagues Retrieval Failed : ${error}`, true));
    }
  }
}
function* cancelFixtureSaga(companyId, action) {
  const reqBody = {
    ...action,
  };
  const { fixtureId } = action;
  if (companyId) {
    try {
      const response = yield call(cancelFixtureService, companyId, fixtureId, reqBody);
      if (!response.error) {
        const state = yield select();
        const { league } = state.leagues;
        const leagueId = league.id;
        yield put(leaguesActions.getLeague(leagueId));
        yield put(sessionActions.updateSuccessfulSnackbar('Fixture Updated', true));
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Leagues Retrieval Failed : ${error}`, true));
    }
  }
}

function* submitGoalScorersSaga(companyId) {
  const state = yield select();
  const { fixture } = state.leagues;
  const { goalScorers } = fixture;
  if (companyId) {
    try {
      const response = yield call(submitGoalScorerService, companyId, fixture.id, goalScorers);
      if (!response.error) {
        const updatedFixture = yield call(getLeagueFixtureService, companyId, fixture.id);
        if (!updatedFixture.error) {
          yield put(leaguesActions.updateFixture(updatedFixture.data));
        }
        yield put(sessionActions.updateSuccessfulSnackbar('Scorers have been submitted for this fixture.', true));
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Leagues Retrieval Failed : ${error}`, true));
    }
  }
}

function* createLeagueSaga(companyId, action) {
  const { values } = action;
  const state = yield select();
  const { selectedTags } = state.tags;
  const reqBody = {
    ...values,
    selectedTags,
  };
  try {
    const response = yield call(createLeagueService, companyId, reqBody);
    if (!response.error) {
      yield put(sessionActions.updateSuccessfulSnackbar('League successfully created.', true));
      yield put(leaguesActions.getLeagues('ACTIVE'));
    }
  } catch (err) {
    if (err.error === 'Unauthorized') {
      yield put(userActions.updateCurrentUser({}));
    } else {
      yield put(sessionActions.updateErrorSnackbar(`League update failed : ${err.error}`, true));
    }
    console.log('createLeagueSaga error', err);
  }
}

function* removeFixtureAgentSaga(companyId, action) {
  const { fixtureAgentId, fixtureId } = action;
  try {
    const response = yield call(
      removeFixtureAgentService, companyId, fixtureId, { fixtureAgentId },
    );
    if (!response.error) {
      const state = yield select();
      const { league } = state.leagues;
      const leagueId = league.id;
      yield put(leaguesActions.getLeague(leagueId));
      yield put(sessionActions.updateSuccessfulSnackbar('Fixture Agent Removed', true));
    }
  } catch (error) {
    if (error.error === 'Unauthorized') {
      yield put(userActions.updateCurrentUser({}));
    }
    yield put(sessionActions.updateErrorSnackbar(`Leagues Retrieval Failed : ${error}`, true));
  }
}

function* assignAssociateSaga(companyId, action) {
  const { fixtureId, leagueId, agents } = action;
  const reqBody = {
    agents,
  };
  try {
    const response = yield call(assignAssociateService, companyId, fixtureId, reqBody);
    if (!response.error) {
      yield put(sessionActions.updateSuccessfulSnackbar('Official successfully assigned.', true));
      yield put(leaguesActions.getLeague(leagueId));
      yield put(leaguesActions.assignAssociateSuccess());
    }
  } catch (err) {
    if (err.error === 'Unauthorized') {
      yield put(userActions.updateCurrentUser({}));
    } else {
      yield put(sessionActions.updateErrorSnackbar(`Fixture update failed : ${err.error}`, true));
    }
    console.log('assignAssociateSaga error', err);
  }
}

function* deleteLeagueSaga(companyId, action) {
  const { leagueId } = action;
  try {
    const response = yield call(deleteLeagueService, companyId, leagueId);
    if (!response.error) {
      yield put(sessionActions.updateSuccessfulSnackbar('League successfully deleted.', true));
      yield put(leaguesActions.getLeagues('ACTIVE'));
    }
  } catch (err) {
    if (err.error === 'Unauthorized') {
      yield put(userActions.updateCurrentUser({}));
    } else {
      yield put(sessionActions.updateErrorSnackbar(`League update failed : ${err.error}`, true));
    }
    console.log('deleteLeagueSaga error', err);
  }
}

function* updateLeagueFixtureFacilitySaga(companyId, action) {
  const { leagueId, slots, facility } = action;
  try {
    const response = yield call(editLeagueFixtureFacilityService, leagueId, slots, facility);
    if (!response.error) {
      yield put(leaguesActions.editLeagueFacility(null));
      yield put(sessionActions.updateSuccessfulSnackbar('Fixture facility has been successfully amended.', true));
      const updatedLeague = yield call(getLeagueService, companyId, response.data.fixture.leagueId);
      console.log(updatedLeague);
      if (!updatedLeague.error) {
        yield put(leaguesActions.leagueRequestSucceeded(updatedLeague.data));
      }
    }
  } catch (err) {
    if (err.error === 'Unauthorized') {
      yield put(userActions.updateCurrentUser({}));
    } else {
      yield put(sessionActions.updateErrorSnackbar(`Fixture update failed : ${err.error}`, true));
    }
    console.log('updateLeagueFixtureFacilitySaga error', err);
  }
}

function* deleteLeagueTeamSaga(action) {
  const { leagueId, teamId } = action;

  try {
    const response = yield call(deleteLeagueTeamService, leagueId, teamId);
    if (!response.error) {
      yield put(sessionActions.updateSuccessfulSnackbar('Team deleted successfully', true));
      yield put(leaguesActions.getLeague(leagueId));
    }
  } catch (err) {
    if (err.error === 'Unauthorized') {
      yield put(userActions.updateCurrentUser({}));
    } else {
      yield put(sessionActions.updateErrorSnackbar(`Fixture update failed : ${err.error}`, true));
    }
    console.log('updateLeagueFixtureFacilitySaga error', err);
    yield put(sessionActions.updateErrorSnackbar(`Failed to delete team: ${err.error}`, true));
  }
}

function* fetchLeagueFixtureSaga(action) {
  const { leagueId } = action;

  try {
    const response = yield call(fetchLeagueFixtures, leagueId);
    if (!response.error) {
      yield put(leaguesActions.fetchLeagueFixturesSuccess(response.data));
    }
  } catch (err) {
    if (err.error === 'Unauthorized') {
      yield put(userActions.updateCurrentUser({}));
    } else {
      yield put(sessionActions.updateErrorSnackbar(`Error: ${err.error}`, true));
    }
    console.log('assignAssociateSaga error', err);
  }
}

function* updateLeagueFixtureScoreSaga(action) {
  const { fixtureId, score } = action;
  try {
    const response = yield call(updateLeagueFixtureScore, fixtureId, score);
    if (!response.error) {
      yield put(sessionActions.updateSuccessfulSnackbar('Fixture score has been successfully updated.', true));
      yield put(leaguesActions.getLeague(response.data.leagueId));
    }
  } catch (err) {
    console.log(err);
    if (err) {
      yield put(sessionActions.updateErrorSnackbar(`Fixture update failed : ${err.error}`, true));
    }
    console.log('updateLeagueFixtureScore error', err);
  }
}

/* Watchers */
export function* createLeaguesWatcher(companyId) {
  yield takeEvery(
    leaguesActions.CREATE_LEAGUE_REQUESTED,
    createLeagueSaga, companyId,
  );
}

export function* getLeaguesWatcher(companyId) {
  yield takeEvery(leaguesActions.LEAGUES_RETRIEVAL_REQUESTED, getLeaguesSaga, companyId);
}
export function* getLeagueWatcher(companyId) {
  yield takeEvery(leaguesActions.LEAGUE_RETRIEVAL_REQUESTED, getLeagueSaga, companyId);
}
export function* confirmFixturesWatcher(companyId) {
  yield takeEvery(leaguesActions.CONFIRM_FIXTURES_REQUESTED, confirmFixturesSaga, companyId);
}
export function* recordLeagueResultWatcher(companyId) {
  yield takeEvery(leaguesActions.LEAGUE_SAVE_RESULT, recordLeagueResultSaga, companyId);
}
export function* confirmFacilityLinkWatcher(companyId) {
  yield takeEvery(
    leaguesActions.CONFIRM_FACILITY_LINK_REQUESTED, confirmFacilityLinkSaga, companyId,
  );
}
export function* inviteUserToLeagueWatcher(companyId) {
  yield takeEvery(
    leaguesActions.INVITE_USER_TO_LEAGUE, inviteUserToLeagueSaga, companyId,
  );
}
export function* adjustTeamPointsWatcher(companyId) {
  yield takeEvery(
    leaguesActions.ADJUST_TEAM_POINTS, adjustTeamPointsSaga, companyId,
  );
}
export function* createNewFixtureWatcher(companyId) {
  yield takeEvery(
    leaguesActions.CREATE_NEW_FIXTURE, createNewFixtureSaga, companyId,
  );
}
export function* cancelFixtureWatcher(companyId) {
  yield takeEvery(
    leaguesActions.CANCEL_FIXTURE, cancelFixtureSaga, companyId,
  );
}

export function* getLeagueFixtureWatcher(companyId) {
  yield takeEvery(
    leaguesActions.GET_TEAM_PLAYERS, getLeagueFixtureSaga, companyId,
  );
}
export function* submitGoalScorersWatcher(companyId) {
  yield takeEvery(
    leaguesActions.SUBMIT_GOALSCORERS, submitGoalScorersSaga, companyId,
  );
}

export function* assignAssociateWatcher(companyId) {
  yield takeEvery(
    leaguesActions.ASSIGN_ASSOCIATE, assignAssociateSaga, companyId,
  );
}

export function* removeFixtureAgentWatcher(companyId) {
  yield takeEvery(
    leaguesActions.REMOVE_FIXTURE_AGENT, removeFixtureAgentSaga, companyId,
  );
}

export function* updateLeagueFixtureFacilityWatcher(companyId) {
  yield takeEvery(
    leaguesActions.UPDATE_LEAGUE_FIXTURE_FACILITY,
    updateLeagueFixtureFacilitySaga,
    companyId,
  );
}

export function* deleteLeagueTeamWatcher() {
  yield takeEvery(
    leaguesActions.DELETE_LEAGUE_TEAM_REQUEST, deleteLeagueTeamSaga,
  );
}

export function* fetchLeagueFixtureSagaWatcher() {
  yield takeEvery(
    leaguesActions.GET_LEAGUE_FIXTURES_REQUEST, fetchLeagueFixtureSaga,
  );
}

export function* updateLeagueTagsWatcher(companyId) {
  yield takeEvery(
    tagActions.UPDATE_LEAGUE_TAGS, updateLeagueTagsSaga, companyId,
  );
}

export function* deleteLeagueWatcher(companyId) {
  yield takeEvery(
    leaguesActions.DELETE_LEAGUE_REQUEST, deleteLeagueSaga, companyId,
  );
}

export function* updateLeagueScoreWatcher() {
  yield takeEvery(
    leaguesActions.UPDATE_FIXTURE_SCORE, updateLeagueFixtureScoreSaga,
  );
}
