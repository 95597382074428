/* eslint-disable no-new */
/* eslint-disable no-unused-vars */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import JSON2CSV from 'json2csv';
import FileDownload from 'js-file-download';
import '../../../styles/payments.css';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CalendarToday from '@material-ui/icons/CalendarToday';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import GroupsIcon from '@material-ui/icons/People';
import { useDispatch } from 'react-redux';
import { Button, Typography, TableContainer } from '@material-ui/core';
import { useMutation } from '@tanstack/react-query';
import ViewAttendeeDialog from './ViewAttendeeDialog';
import MultipleCancelRefundDialog from '../../bookings/components/MultipleCancelRefundDialog';
import { parseAttendeeRowsForDownload } from '../../../helpers';
import ActionBar from '../../../components/ActionBar';
import UserTableSendMessageDialog from '../../users/UserTableSendEmailDialog';
import * as usersActions from '../../../reducers/usersReducer';
import * as bookerActions from '../../../reducers/bookingsReducer';
import { updateReservationNotesService } from '../../../services/reservationsServices';
import { useToast } from '../../../hooks';
import { getEvent } from '../../../reducers/eventsReducer';
import CheckInLogDialog from './CheckInLogDialog';
import { omitFormProperties } from '../../../utils';

const AttendeeTable = ({
  reservations, requestMultipleCancel, event, company,
}) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [showActionBar, setShowActionBar] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);
  const [checkInLogDialog, setCheckInLogDialog] = useState({
    isOpen: false,
    checkInLogs: null,
  });

  const { mutate: checkInMutation, isLoading: checkInLoading } = useMutation({
    mutationFn: async ({ reservationId, note, type }) => {
      const res = await updateReservationNotesService(company.id, reservationId, { note, type });

      if (res.status !== 200) {
        throw new Error('Error updating reservation notes');
      }

      return res.data;
    },
    onSuccess: (data, vars) => {
      const toastMessage = vars.type === 'CHECK_IN' ? 'Checked in successfully!' : 'Checked out successfully!';
      toast.trigger({
        type: 'success',
        message: toastMessage,
      });
      dispatch(getEvent(event.id));
    },
    onError: (error) => {
      toast.trigger({
        type: 'error',
        message: 'An error occurred. Please try again.',
      });
    },
  });

  const filteredReservations = useMemo(() => {
    if (!reservations) return [];

    const filtered = reservations.map((x) => {
      const checkInLogs = (x.reservation_notes ?? [])
        .filter((x) => x.type === 'CHECK_IN' || x.type === 'CHECK_OUT')
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      const hasPreviouslyCheckedIn = checkInLogs.length > 0;
      const isCheckedIn = checkInLogs?.[0]?.type === 'CHECK_IN' ?? false;

      return {
        ...x,
        isCheckedIn,
        hasPreviouslyCheckedIn,
        checkInLogs,
      };
    });

    return filtered;
  }, [reservations]);

  const [attendeeTableState, setAttendeeTableState] = useState(
    {
      page: 0,
      rowsPerPage: 25,
      selectedUserEmails: [],
      sendMessageRequested: false,
      changePasswordOpen: false,
    },
  );
  const {
    selectedUserEmails, sendMessageRequested, selectedUserIds,
  } = attendeeTableState;
  const columns = [
    {
      title: 'Attendee',
      field: 'Attendee',
      align: 'center',
      sorting: false,
      customFilterAndSearch: (term, row) => {
        const eventAllocations = row.allocations.filter((allocation) => allocation.type === 'EVENT');
        const form = eventAllocations[0].formData;
        const formString = JSON.stringify(form);
        return formString.includes(term.toLowerCase());
      },
      render: (row) => {
        const eventAllocations = row.allocations.filter((allocation) => allocation.type === 'EVENT');
        return (

          <AlternativelyVisible condition={eventAllocations[0].formData !== null}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            >
              <ViewAttendeeDialog formData={omitFormProperties(eventAllocations[0]?.formData)} />
              {eventAllocations[0]?.formData && eventAllocations[0]?.formData.attendeeFirstName
               && eventAllocations[0]?.formData.attendeeLastName && (
                `${eventAllocations[0]?.formData && eventAllocations[0]?.formData.attendeeFirstName} ${eventAllocations[0]?.formData && eventAllocations[0]?.formData.attendeeLastName}`
              )}
            </div>
            <div>
              No Form details
            </div>
          </AlternativelyVisible>
        );
      },
    },
    {
      title: 'Booked By',
      field: 'Booked By',
      customFilterAndSearch: (term, row) => {
        const bookerName = `${row.user.firstName} ${row.user.lastName}`;
        const bookerNameString = bookerName.toString().toLowerCase();
        return bookerNameString.includes(term.toLowerCase());
      },
      render: (row) => (
        <>
          {row.user.firstName}
          {' '}
          {row.user.lastName}
          {' '}
          <div>
            {row.user.email}
          </div>
        </>
      ),
    },
    {
      title: 'Time Booked',
      field: 'createdAt',
      render: (row) => (<>{moment(row.createdAt).format('LLL')}</>),
    },
    {
      title: 'Booking Modifier',
      field: 'eventBookingModifier',
      render: (row) => (row.eventBookingModifier ? row.eventBookingModifier?.name : null),
    },
    {
      title: 'Addons',
      field: 'createdAt',
      render: (row) => {
        const addonAllocations = [];
        row.allocations.forEach((allocation) => {
          if (allocation.type === 'ADDON') {
            addonAllocations.push(allocation.addon.name);
          }
        });
        return (
          <AlternativelyVisible condition={addonAllocations.length > 0}>
            {addonAllocations.map((addon) => (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #eee',
              }}
              >
                {addon}
              </div>
            ))}
            <div>
              No Addons
            </div>
          </AlternativelyVisible>
        );
      },
    },
    // {
    //   title: 'Edit',
    //   field: 'edit',
    //   filtering: false,
    //   render: (row) => (
    //     <MultipleCancelRefundDialog
    //       variant="contained"
    //       buttonTitle="Cancel / Refund Booking"
    //       dialogTitle="Cancel Booking"
    //       multiple={false}
    //       selectedRows={[row]}
    //       // dialogBody="Are you sure you wish to cancel these reservations?"
    //       requestCancel={() => requestMultipleCancel(row)}
    //       event
    //       eventId={event.id}
    //     />
    //   ),
    // },
    {
      title: 'Status',
      field: 'status',
      render: (row) => (
        <>
          <Typography style={row.status === 'CONFIRMED' ? { color: 'black' } : { color: 'red' }}>
            {row.status}
          </Typography>
          {row.hasPreviouslyCheckedIn && row.isCheckedIn ? (
            <Typography style={{ color: 'green' }}>
              On Site
            </Typography>
          ) : (
            <Typography style={{ color: 'red' }}>
              Checked Out
            </Typography>
          )}
        </>
      ),
    },
    {
      title: 'Check In/Out',
      render: (row) => (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          {row.isCheckedIn ? (
            <Button
              variant="outlined"
              color="secondary"
              disabled={checkInLoading}
              onClick={() => checkInMutation({
                reservationId: row.id,
                note: 'Checked Out',
                type: 'CHECK_OUT',
              })}
            >
              Check Out
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={checkInLoading}
              onClick={() => checkInMutation({
                reservationId: row.id,
                note: 'Checked In',
                type: 'CHECK_IN',
              })}
            >
              Check In
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={() => setCheckInLogDialog(
              { isOpen: true, checkInLogs: row.checkInLogs },
            )}
          >
            Logs
          </Button>
        </div>
      ),
    },
  ];
  function percentage(partialValue, totalValue) {
    return ((100 * partialValue) / totalValue).toFixed(2);
  }
  function totalRevenue(reservations) {
    let revenue = 0;
    // eslint-disable-next-line no-return-assign
    reservations.forEach((res) => revenue += res.total);
    return (revenue).toFixed(2);
  }

  const handleSelectedRows = (rows) => {
    if (rows.length > 0) {
      setShowActionBar(true);
      setSelectedRows(rows);

      const updatedSelectedUserEmails = [];
      const updatedSelectedUserIds = [];

      const selectedUserEmails = rows.map((row) => row?.user?.email);
      updatedSelectedUserEmails.push(...selectedUserEmails);

      const selectedUserIds = rows.map((row) => row?.user?.id);
      updatedSelectedUserIds.push(...selectedUserIds);

      setAttendeeTableState({
        ...attendeeTableState,
        selectedUserEmails: updatedSelectedUserEmails,
        selectedUserIds: updatedSelectedUserIds,
      });
    } else {
      setShowActionBar(false);
      setSelectedRows(null);
    }
  };

  const handleSendMessageRequestedToggle = () => {
    setAttendeeTableState(
      {
        ...attendeeTableState,
        sendMessageRequested: !attendeeTableState.sendMessageRequested,
      },
    );
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div className="total-figures">
          <div className="total-amount">
            <GroupsIcon style={{ fill: '#47FEB4', fontSize: '48px' }} />
            <div>
              {percentage(reservations.length, event.maximumCapacity)}
              {'%'}
            </div>
            <div className="total-amount" style={{ fontSize: '12px', color: '#515459' }}>
              Capacity filled*
            </div>
          </div>
        </div>
        <div className="total-figures">
          <div className="total-amount">
            <AccountBalanceIcon style={{ fill: '#47FEB4', fontSize: '32px' }} />
            <div>
              {getCurrencySymbol(company.currency)}
              {totalRevenue(reservations)}
            </div>
            <div className="total-amount" style={{ fontSize: '12px', color: '#515459' }}>
              Total Revenue*
            </div>
          </div>
        </div>
        <div className="total-figures">
          <div className="total-amount">
            <GroupsIcon style={{ fill: '#47FEB4', fontSize: '48px' }} />
            <div>
              {reservations.length}
            </div>
            <div className="total-amount" style={{ fontSize: '12px', color: '#515459' }}>
              Total Attendees*
            </div>
          </div>
        </div>
        <ConditionallyVisible condition={event.closeDate}>
          <div className="total-subscriptions-inactive-figures">
            <div className="total-subscriptions-inactive-amount">
              <CalendarToday style={{ fill: '#f9c0c0', fontSize: '48px' }} />
              <div>
                {moment(event.closeDate).format('LL')}
              </div>
              <div className="total-amount" style={{ fontSize: '12px', color: '#515459' }}>
                Close Date*
              </div>
            </div>
          </div>
        </ConditionallyVisible>
      </div>

      <ConditionallyVisible condition={showActionBar}>
        <ActionBar>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            {/* <MultipleCancelRefundDialog
              variant="contained"
              buttonTitle="Cancel/Refund Bookings"
              dialogTitle="Cancel/Refund Bookings"
              multiple={selectedRows?.length > 1}
              selectedRows={[selectedRows]}
              requestCancel={() => requestMultipleCancel(selectedRows)}
              event
              eventId={event.id}
            /> */}
            <MultipleCancelRefundDialog
              variant="contained"
              buttonTitle="Cancel / Refund Bookings"
              dialogTitle={`Cancel / Refund ${selectedRows?.length} Booking${selectedRows?.length > 1 ? 's' : ''}`}
              multiple={selectedRows?.length > 1}
              selectedRows={selectedRows}
              requestCancel={() => requestMultipleCancel(selectedRows)}
              resetSelectedRows={() => dispatch(bookerActions.resetSelectedRows())}
              // deselectAll={() => dispatch(bookerActions.deselectAll())}
            />

            <Button onClick={handleSendMessageRequestedToggle} variant="contained" color="primary">
              Send Message to Customer(s)
            </Button>

            <UserTableSendMessageDialog
              open={sendMessageRequested}
              userEmails={selectedUserEmails}
              onClose={handleSendMessageRequestedToggle}
              userIds={selectedUserIds}
            />
          </div>
        </ActionBar>
      </ConditionallyVisible>

      <div className="table-section">
        <TableContainer
          style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden' }}
          component={Paper}
        >
          <MaterialTable
            data={filteredReservations}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            title="Attendees"
            icons={{
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              Export: () => <SaveAlt />,
              ResetSearch: () => <Clear />,
              SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
            }}
            columns={columns}
            options={{
              search: true,
              selection: true,
              exportButton: {
                csv: true,
                pdf: false,
              },
              emptyRowsWhenPaging: false,
              tableLayout: 'auto',
              exportFileName: 'Pitchbooking Attendees',
              exportCsv: (columns, data) => {
                const { colHeaders, rows } = parseAttendeeRowsForDownload(data, company);
                const csv = JSON2CSV({ data: rows, fields: colHeaders, excelStrings: false });
                const timestamp = moment(new Date()).format('YYYY-MM-DD');
                const filename = `Pitchbooking Attendee Export (${timestamp}).csv`;
                FileDownload(csv, filename);
              },
              pageSize: 50,
              pageSizeOptions: [10, 25, 50, 100],
              showFirstLastPageButtons: false,
              showTitle: false,
              headerStyle: {
                backgroundColor: '#efefef',
                fontWeight: 'bold',
              },
            }}
            onSelectionChange={(rows) => {
              handleSelectedRows(rows);
            }}
            localization={{
              toolbar: {
                exportTitle: 'Download',
              },
            }}
          />
        </TableContainer>
        <CheckInLogDialog
          isOpen={checkInLogDialog.isOpen}
          onClose={() => setCheckInLogDialog({ isOpen: false, checkInLogs: null })}
          checkInLogs={checkInLogDialog.checkInLogs}
        />
      </div>
    </>
  );
};

AttendeeTable.propTypes = {
  reservations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  event: PropTypes.shape().isRequired,
  company: PropTypes.shape().isRequired,
  requestMultipleCancel: PropTypes.func.isRequired,
};

export default (AttendeeTable);
