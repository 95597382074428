import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { MultiStepForm } from '@pitchbooking-dev/pb-shared/lib/components/MultiStepForm';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { createCompanyService } from '../../services/companiesServices';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import useMobile from '../../hooks/useMobile';
import CompanyDetailsForm, { initialValues as companyDetailsValues, validation as companyDetailsValidation } from './components/forms/CompanyDetailsForm';
import PresetVenueForm, { initialValues as presetVenueValues, validation as presetVenueValidation } from './components/forms/PresetVenueForm';
import SiteDetailsForm, { initialValues as siteDetailsValues, validation as siteDetailsValidation } from './components/forms/SiteDetailsForm';

const steps = [
  {
    label: 'Company Details',
    component: CompanyDetailsForm,
    validation: companyDetailsValidation,
  },
  {
    label: 'Choose Pre-Set Venue Type',
    component: PresetVenueForm,
    validation: presetVenueValidation,
  },
  {
    label: 'Specify Site & Facility Types',
    component: SiteDetailsForm,
    validation: siteDetailsValidation,
  },
];

const createCompany = async (id, values, headers, closeDialog, setServiceError) => {
  try {
    await createCompanyService(id, values, headers);
    closeDialog();
  } catch (error) {
    console.log(error);
    setServiceError(error.response?.data?.data?.error || 'There was an issue creating this company');
  }
};

const CreateNewDemoDialog = () => {
  const { id } = useSelector((state) => state.companies.companyInfo);
  const [open, setOpen] = useState(false);
  const [serviceError, setServiceError] = useState(null);
  const isMobile = useMobile();

  return (
    <div>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => setOpen(true)}
        style={{ margin: '1rem 0rem' }}
      >
        Create Demo
      </Button>
      <Dialog
        open={open}
        fullScreen={isMobile}
        maxWidth="md"
        fullWidth
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>
            Create a Demo Account
          </DialogTitle>

          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => setOpen(false)} />
          </DialogActions>
        </div>

        <DialogContent>

          <MultiStepForm
            initialValues={{
              ...companyDetailsValues,
              ...presetVenueValues,
              ...siteDetailsValues,
            }}
            steps={steps}
            onSubmit={async (values) => {
              await createCompany(
                id,
                values,
                {
                  'Content-Type': 'multipart/form-data',
                },
                () => setOpen(false),
                setServiceError,
              );
            }}
          />
          <ConditionallyVisible condition={serviceError}>
            <p style={{ color: 'red', textAlign: 'center' }}>{serviceError}</p>
          </ConditionallyVisible>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateNewDemoDialog;
