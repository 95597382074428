import {
  takeEvery, call, put,
} from 'redux-saga/effects';

import * as actions from '../reducers/formsReducer';
import { updateFormService, updateResourceFormService, getFormsService } from '../services/formsServices';
import { requestFacilityRetrieval } from '../reducers/facilitiesReducer';
import { getEvent } from '../reducers/eventsReducer';
import { handleSagaError } from './helperSaga';

function* updateFormSaga(companyId, action) {
  const {
    formId, formData, name, facilityId,
  } = action;
  const reqBody = {
    name,
    formData,
    facilityId,
  };

  try {
    let response;
    if (formId) {
      response = yield call(updateFormService, companyId, formId, reqBody);
    } else {
      response = yield call(updateFormService, companyId, null, reqBody);
    }
    if (!response.error) {
      yield put(actions.updateFormSucceeded(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'updateFormSaga');
  }
}

function* updateResourceFormSaga(companyId, action) {
  const {
    formId, resourceType, resourceId,
  } = action;
  const reqBody = {
    formId,
    resourceType,
    resourceId,
  };

  try {
    const response = yield call(updateResourceFormService, companyId, reqBody);
    if (!response.error) {
      if (resourceType === 'FACILITY') {
        yield put(requestFacilityRetrieval(resourceId));
      } else if (resourceType === 'EVENT') {
        yield put(getEvent(resourceId));
      }
      yield put(actions.updateFormSucceeded(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'updateResourceFormSaga');
  }
}

function* getCompanyFormsSaga(companyId) {
  try {
    const response = yield call(getFormsService, companyId);
    if (!response.error) {
      yield put(actions.getCompanyFormsSucceeded(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getCompanyFormsSaga');
  }
}

export function* updateFormSagaWatcher(companyId) {
  yield takeEvery(actions.UPDATE_FORM_REQUEST, updateFormSaga, companyId);
}
export function* updateResourceFormSagaWatcher(companyId) {
  yield takeEvery(actions.UPDATE_RESOURCE_FORM_REQUEST, updateResourceFormSaga, companyId);
}
export function* getCompanyFormsSagaWatcher(companyId) {
  yield takeEvery(actions.GET_COMPANY_FORMS_REQUEST, getCompanyFormsSaga, companyId);
}
