import React from 'react';
import PropTypes from 'prop-types';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import { Tooltip } from '@material-ui/core';
import PayoutReconciliationDialog from './PayoutReconciliationDialog';
import PitchbookingUserOnly from '../../helpers/PitchbookingUserOnly';
import RecalculatePayoutButton from './RecalculatePayoutButton';

const payoutReconciliationButton = (props) => {
  const { payout } = props;
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <AlternativelyVisible condition={payout.reconciled}>
        <>
          <PayoutReconciliationDialog payout={payout} />
        </>
        <>
          <AlternativelyVisible condition={payout.reconciled === false}>
            <>
              <Tooltip title="Payout is pending. Your paid breakdown will be available soon,
              please contact your account manager if you have any queries."
              >
                <div className="pendingTag">Pending</div>
              </Tooltip>
              <div style={{ width: '5px' }} />
              <PitchbookingUserOnly>
                <RecalculatePayoutButton payoutId={payout.id} />
              </PitchbookingUserOnly>
            </>
            <>
            </>
          </AlternativelyVisible>
        </>
      </AlternativelyVisible>
    </div>
  );
};

payoutReconciliationButton.propTypes = {
  payout: PropTypes.number.isRequired,
};

export default payoutReconciliationButton;
