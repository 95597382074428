import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';

export class SettingsModalTimeframesDateRow extends React.Component {
  render() {
    const { analyticsEventTimeframe, timeframe, handleTimeframePress } = this.props;
    const isChecked = analyticsEventTimeframe === timeframe;
    return (
      <div className="dateButton">
        {timeframe}
        <IconButton onClick={() => handleTimeframePress(timeframe)}>
          <AlternativelyVisible condition={isChecked}>
            <CheckCircleOutlineIcon color="secondary" />
            <RadioButtonUncheckedIcon color="disabled" />
          </AlternativelyVisible>
        </IconButton>
      </div>
    );
  }
}

SettingsModalTimeframesDateRow.propTypes = {
  analyticsEventTimeframe: PropTypes.string.isRequired,
  timeframe: PropTypes.string.isRequired,
  handleTimeframePress: PropTypes.func.isRequired,
};
