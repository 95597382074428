import React from 'react';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { currencies } from '@pitchbooking-dev/pb-shared/lib/helpers';
import moment from 'moment-timezone';
import * as Yup from 'yup';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  ExpansionPanel, ExpansionPanelSummary, Typography,
  ExpansionPanelDetails, FormControl, FormHelperText,
} from '@material-ui/core';
import { useFormikContext } from 'formik';

export const initialValues = {
  logo: '',
  name: '',
  companyAddress: '',
  fullAddress: {
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    county: '',
    postcode: '',
  },
  location: {
    lat: null,
    lng: null,
  },
  hubspotCompanyId: null,
  currency: 'gbp',
  timezone: 'Europe/Belfast',
  taxRate: 0.2,
};

export const validation = Yup.object({
  logo: Yup.mixed().required('Company logo is required'),
  name: Yup.string().required('Company name is required'),
  companyAddress: Yup.string().required('Company address is required'),
  fullAddress: Yup.object({
    addressLineOne: Yup.string(),
    addressLineTwo: Yup.string(),
    city: Yup.string(),
    country: Yup.string(),
    postcode: Yup.string(),
  }),
  location: Yup.object({
    lat: Yup.number().required(),
    lng: Yup.number().required(),
  }),
  hubspotCompanyId: Yup.number().required('Hubspot company ID is required'),
  currency: Yup.string().required('Currency is required'),
  timezone: Yup.string().required('Timezone is required'),
  taxRate: Yup.string().required('Tax rate is required'),
});

const CompanyDetailsForm = () => {
  const {
    values,
    errors,
    handleChange,
    touched,
    setFieldValue,
    handleBlur,
  } = useFormikContext();
  const timezones = moment.tz.names();
  const hubspotLink = `https://app.hubspot.com/contacts/4272364/record/0-2/${values.hubspotCompanyId}`;
  return (
    <>
      {/* TODO: Could create a PBInput from this */}
      {/* File upload */}
      <FormControl
        error={touched.logo && errors.logo}
        fullWidth
        style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <label
          htmlFor="logo"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid rgb(163, 162, 160)',
              borderRadius: '9999px',
              height: '10rem',
              width: '10rem',
              overflow: 'hidden',
            }}
          >
            {values.logo ? (
              <img
                src={URL.createObjectURL(values.logo)}
                alt="Company Logo"
                height="100%"
                width="100%"
              />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <AddIcon color="primary" />
                <span style={{ fontWeight: 800, color: 'rgb(163, 162, 160)' }}>Upload Logo</span>
              </div>
            )}

            <input
              type="file"
              id="logo"
              name="logo"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={(e) => {
                if (e.target.files?.length > 0) {
                  setFieldValue('logo', e.target.files[0]);
                }
              }}
              onBlur={handleBlur}
            />
          </div>
        </label>
        {touched.logo && errors.logo && <FormHelperText>{errors.logo}</FormHelperText>}
      </FormControl>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {/* Address */}
        <PBInput
          id="companyAddress"
          type="location"
          locationDescription="Company Address"
          value={values.companyAddress}
          onChange={(newValue) => {
            setFieldValue('companyAddress', newValue.placeName);
            setFieldValue('location', newValue.location);
            setFieldValue('fullAddress', newValue.fullAddress);

            if (!values.name) {
              setFieldValue('name', newValue.placeName);
            }
          }}
          onBlur={handleBlur}
          errorMessage={touched.companyAddress && 'Company Address is required'}
          isError={touched.companyAddress && Boolean(errors.companyAddress)}
        />

        {/* Company name */}
        <PBInput
          id="name"
          type="text"
          label="Company name"
          value={values.name}
          onChange={handleChange}
          errorMessage={touched.name && errors.name}
          isError={touched.name && Boolean(errors.name)}
          onBlur={handleBlur}
        />

        {/* hubspotCompanyId */}
        <PBInput
          id="hubspotCompanyId"
          type="text"
          label="Hubspot Company ID"
          value={values.hubspotCompanyId}
          onChange={handleChange}
          errorMessage={touched.hubspotCompanyId && errors.hubspotCompanyId}
          isError={touched.hubspotCompanyId && Boolean(errors.hubspotCompanyId)}
          onBlur={handleBlur}
        />
        <Typography variant="caption">
          Test Hubspot Link: &nbsp;
          <a
            href={hubspotLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            {hubspotLink}
          </a>
        </Typography>

        <ExpansionPanel className="expansionPanel" style={{ boxShadow: 'none', padding: '0', margin: 0 }}>
          <ExpansionPanelSummary style={{ padding: 0 }} expandIcon={<ExpandMoreIcon />}>
            <Typography>Advanced settings</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{
            padding: 0, display: 'flex', flexDirection: 'column', gap: '1rem',
          }}
          >
            {/* Currency */}
            <PBInput
              id="currency"
              type="select"
              label="Please select a currency"
              value={values.currency}
              options={currencies.map((c) => ({ value: c.code, label: c.codeCaps }))}
              onChange={handleChange}
              errorMessage={touched.currency && errors.currency}
              isError={touched.currency && Boolean(errors.currency)}
              onBlur={handleBlur}
            />

            {/* Timezone */}
            <PBInput
              id="timezone"
              type="select"
              label="Please select a timezone"
              value={values.timezone}
              options={timezones.map((t) => ({ value: t, label: t }))}
              onChange={handleChange}
              errorMessage={touched.timezone && errors.timezone}
              isError={touched.timezone && Boolean(errors.timezone)}
              onBlur={handleBlur}
            />

            {/* Tax rate */}
            <PBInput
              type="number"
              id="taxRate"
              name="taxRate"
              label="Tax Rate"
              variant="outlined"
              margin="normal"
              value={values.taxRate}
              onChange={handleChange}
              errorMessage={touched.taxRate && errors.taxRate}
              isError={touched.taxRate && Boolean(errors.taxRate)}
              onBlur={handleBlur}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>

      </div>
    </>
  );
};

export default CompanyDetailsForm;
