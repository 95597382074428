// @flow weak

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { triggerCronJob, changeCronJob } from '../reducers/utilsReducer';

const cronJobsOptions = [{
  buttonTitle: 'checkSubscriptionStripePayments',
  buttonValue: 'checkSubscriptionStripePayments',
},
{
  buttonTitle: 'checkSubscriptionCancelAt',
  buttonValue: 'checkSubscriptionCancelAt',
},
{
  buttonTitle: 'checkSubscriptionStatusMatchInStripe',
  buttonValue: 'checkSubscriptionStatusMatchInStripe',
},
{
  buttonTitle: 'checkPayouts',
  buttonValue: 'checkPayouts',
},
{
  buttonTitle: 'checkStripeConnections',
  buttonValue: 'checkStripeConnections',
},
{
  buttonTitle: 'recalculatePayoutReconciliations',
  buttonValue: 'recalculatePayoutReconciliations',
},
{
  buttonTitle: 'customerStats',
  buttonValue: 'customerStats',
},
{
  buttonTitle: 'populateGoogleSheets',
  buttonValue: 'populateGoogleSheets',
},
{
  buttonTitle: 'populateCompaniesCustomerSuccessStatsGoogleSheets',
  buttonValue: 'populateCompaniesCustomerSuccessStatsGoogleSheets',
},
{
  buttonTitle: 'populateBookingStatsGoogleSheets',
  buttonValue: 'populateBookingStatsGoogleSheets',
},
{
  buttonTitle: 'refreshCompanyAccounting',
  buttonValue: 'refreshCompanyAccounting',
},
{
  buttonTitle: 'syncXeroLineItems',
  buttonValue: 'syncXeroLineItems',
},
{
  buttonTitle: 'syncHubspotCompanies',
  buttonValue: 'syncHubspotCompanies',
},
{
  buttonTitle: 'syncHubspotEngagements',
  buttonValue: 'syncHubspotEngagements',
},
{
  buttonTitle: 'sendUnsentKeycodes',
  buttonValue: 'sendUnsentKeycodes',
},
{
  buttonTitle: 'runUpdateMissingStripeFees',
  buttonValue: 'runUpdateMissingStripeFees',
},
{
  buttonTitle: 'runUpdateMissingStripeFeesForCompany',
  buttonValue: 'runUpdateMissingStripeFeesForCompany',
},
{
  buttonTitle: 'populateCommissionSumsGoogleSheets',
  buttonValue: 'populateCommissionSumsGoogleSheets',
},
{
  buttonTitle: 'populateCumulativeCommissionSumsGoogleSheets',
  buttonValue: 'populateCumulativeCommissionSumsGoogleSheets',
},
{
  buttonTitle: 'sendBillingFirstPaymentReminders',
  buttonValue: 'sendBillingFirstPaymentReminders',
},
{
  buttonTitle: '[ONE TIME USE] migrateDirectoryTableToFacilities',
  buttonValue: 'migrateDirectoryTableToFacilities',
},
{
  buttonTitle: 'generateAIDescriptionsForDirectoryFacilities',
  buttonValue: 'generateAIDescriptionsForDirectoryFacilities',
},
];

// eslint-disable-next-line react/prefer-stateless-function
class RunCronJobSelectorAndButton extends Component {
  render() {
    const { doTriggerCronJob, doChangeCronJob, cronJobName } = this.props;
    return (
      <div style={{ display: 'flex', alignItems: 'center', 'flex-direction': 'row' }}>
        <FormControl variant="outlined">
          <Select
            // displayEmpty
            value={cronJobName}
            onChange={(event) => doChangeCronJob(event.target.value)}
            name="Select Cron Job"
          >
            {cronJobsOptions.map((reason) => (
              <MenuItem value={reason.buttonValue}>{reason.buttonTitle}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          onClick={() => doTriggerCronJob()}
          color="secondary"
          variant="contained"
          style={{ margin: '20px' }}
        >
          Run
        </Button>
      </div>
    );
  }
}

RunCronJobSelectorAndButton.propTypes = {
  doTriggerCronJob: PropTypes.func.isRequired,
  doChangeCronJob: PropTypes.func.isRequired,
  cronJobName: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  cronJobName: state.utils.cronJobName,
});

const mapDispatchToProps = (dispatch) => ({
  doChangeCronJob: (name) => dispatch(changeCronJob(name)),
  doTriggerCronJob: () => dispatch(triggerCronJob()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RunCronJobSelectorAndButton);
