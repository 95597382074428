/* eslint-disable react/prop-types */
import React from 'react';
import {
  convertToRaw, convertFromRaw,
} from 'draft-js';
import MD from 'react-markdown';
import draftToMarkdown from 'draftjs-to-markdown';
import styles from './Markdown.module.css';

export const Markdown = ({ children }) => {
  let markdown = children;

  try {
    const contentState = convertFromRaw(JSON.parse(markdown));
    markdown = draftToMarkdown(convertToRaw(contentState));
  } catch (e) {
    console.warn('Unable to convert raw to markdown');
  }

  return (
    <div className={styles.markdown}>
      <MD>
        {markdown}
      </MD>
    </div>
  );
};
