/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import {
  priceTaxCalculator, taxCalculator, generateTotalWithTaxText,
} from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import {
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TextField,
  Checkbox,
} from '@material-ui/core';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import PBButton from '../../../components/Button';
import * as actions from '../../../reducers/invoicesReducer';

const initialState = {
  description: '',
  totalExTax: 0,
  taxRate: 20,
  taxInclusive: true,
  total: 0,
};

const InvoiceStepperAdditionalItemField = ({
  item,
  doAddInvoiceAdditionalItem,
  removeInvoiceAdditionalItem,
}) => {
  const [state, setState] = useState({ ...initialState });
  const currency = useSelector((state) => state.companies.companyInfo.currency);
  const currencySym = getCurrencySymbol(currency);

  const handleTextChange = (event) => {
    const { id, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const addInvoiceAdditionalItem = () => {
    const {
      description,
      taxRate,
      taxInclusive,
    } = state;

    let totalExTax = parseFloat(state.totalExTax);
    let tax = 0;
    let total = 0;
    const thisTaxRate = parseFloat(taxRate);

    if (taxInclusive) {
      total = parseFloat(totalExTax);
      const taxAmounts = priceTaxCalculator(totalExTax, false, thisTaxRate / 100);
      tax = parseFloat(taxAmounts.tax);
      totalExTax = total - tax;
    } else {
      tax = taxCalculator(totalExTax, thisTaxRate / 100);
      total = parseFloat(totalExTax) + parseFloat(tax);
    }

    const reset = () => {
      setState({ ...initialState });
    };

    const newItem = {
      description,
      totalExTax,
      thisTaxRate,
      total,
      tax,
      taxInclusive,
      taxRate,
    };

    doAddInvoiceAdditionalItem(newItem);
    reset();
  };

  let {
    description,
    totalExTax,
    taxRate,
    taxInclusive,
  } = state;
  if (item) {
    description = item.description;
    totalExTax = item.totalExTax;
    taxRate = item.taxRate;
    taxInclusive = item.taxInclusive;
  }

  let totalSummed = totalExTax;
  if (!taxInclusive) {
    const tax = taxCalculator(totalExTax, parseFloat(taxRate) / 100);
    totalSummed = parseFloat(totalExTax) + parseFloat(tax);
  }

  return (
    <div>
      <br />
      <TableContainer>
        <Table size="small">
          <TableBody style={{ width: '80%' }}>
            <TableRow>
              <TableCell align="left" style={{ fontWeight: 800 }}>Item Description</TableCell>
              <TableCell align="left" style={{ fontWeight: 800 }}>{!item ? 'Unit Price' : 'Total ex Tax'}</TableCell>
              <TableCell align="left" style={{ fontWeight: 800 }}>Tax Rate</TableCell>
              <TableCell align="center" style={{ fontWeight: 800 }}>Tax Inclusive</TableCell>
              <TableCell align="left" style={{ fontWeight: 800 }}>Total</TableCell>
              <TableCell align="left" style={{ fontWeight: 800 }} />
            </TableRow>
            <TableRow>
              <TableCell align="left" style={{ fontWeight: 800 }}>
                <TextField
                  id="description"
                  type="text"
                  rows={!item ? 2 : 1}
                  InputProps={{
                    readOnly: !!item,
                  }}
                  multiline
                  variant="outlined"
                  value={description}
                  onChange={handleTextChange}
                />
              </TableCell>
              <TableCell align="left">
                <TextField
                  id="totalExTax"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{currencySym}</InputAdornment>,
                    readOnly: !!item,
                  }}
                  type="number"
                  variant="outlined"
                  value={totalExTax}
                  onChange={handleTextChange}
                />
              </TableCell>
              <TableCell align="left">
                <TextField
                  id="taxRate"
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: !!item,
                  }}
                  variant="outlined"
                  value={taxRate}
                  onChange={(event) => {
                    const value = parseFloat(event.target.value);
                    if (!isNaN(value)) {
                      handleTextChange({ target: { id: 'taxRate', value } });
                    }
                  }}
                />
              </TableCell>
              <TableCell align="center">
                <Checkbox
                  checked={taxInclusive}
                  InputProps={{ readOnly: !!item }}
                  tabIndex={-1}
                  disableRipple
                  onClick={() => setState((prevState) => ({
                    ...prevState, taxInclusive: !taxInclusive,
                  }))}
                />
              </TableCell>
              <TableCell align="left">
                <AlternativelyVisible condition={!item}>
                  <TextField
                    id="total"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{currencySym}</InputAdornment>,
                      readOnly: true,
                    }}
                    variant="outlined"
                    value={!item
                      ? parseFloat(totalSummed).toFixed(2)
                      : parseFloat(item.total).toFixed(2)}
                    onChange={handleTextChange}
                  />
                  {`${currencySym}${(item?.total)?.toFixed(2)} ${generateTotalWithTaxText(item, currencySym)}`}
                </AlternativelyVisible>
              </TableCell>
              <TableCell align="left">
                <PBButton onClick={!item ? addInvoiceAdditionalItem : () => removeInvoiceAdditionalItem(item)} color={!item ? 'primary' : 'error'} variant="contained">
                  {!item ? 'Add' : 'Remove'}
                </PBButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

InvoiceStepperAdditionalItemField.propTypes = {
  item: PropTypes.shape(),
  doAddInvoiceAdditionalItem: PropTypes.func.isRequired,
  removeInvoiceAdditionalItem: PropTypes.func.isRequired,
};

InvoiceStepperAdditionalItemField.defaultProps = {
  item: false,
};

const mapStateToProps = (state) => ({
  currency: state.companies.companyInfo.currency.toUpperCase(),
});

const mapDispatchToProps = (dispatch) => ({
  doAddInvoiceAdditionalItem: (item) => dispatch(actions.addInvoiceAdditionalItem(item)),
  removeInvoiceAdditionalItem: (item) => dispatch(actions.removeInvoiceAdditionalItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceStepperAdditionalItemField);
