import React from 'react';
import PropTypes from 'prop-types';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';

export class PercentChange extends React.Component {
  render() {
    const { percentChange } = this.props;
    return (
      <AlternativelyVisible condition={percentChange >= 0}>
        <div className="percentChangeUp">
          &#8593;
          {percentChange?.toFixed(2)}
          %
        </div>
        <div className="percentChangeDown">
          &#8595;
          {Math.abs(percentChange)?.toFixed(2)}
          %
        </div>
      </AlternativelyVisible>
    );
  }
}

PercentChange.propTypes = {
  percentChange: PropTypes.string.isRequired,
};
