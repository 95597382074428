import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';
import * as siteActions from '../../../../reducers/sitesReducer';

const styles = () => ({
  root: {
    minWidth: 670,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});

class CreateSiteDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      step: 0,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    // const { resetEventStore } = this.props;
    this.setState({ open: false });
    // resetEventStore();
  };

  handleCreateSite = () => {
    const { requestSiteCreation, resetNewSiteStore } = this.props;
    requestSiteCreation();
    resetNewSiteStore();
    this.handleRequestClose();
  }

  handleResumesAtToggle = (option) => {
    const { updateNewSiteStore } = this.props;
    if (option === 'PUBLIC') {
      updateNewSiteStore(
        { live: true, visibleStatus: option },
      );
    } else if (option === 'INTERNAL') {
      updateNewSiteStore(
        { live: false, visibleStatus: option },
      );
    }
  }

  render() {
    const isMobile = window.innerWidth < 768;
    const {
      classes, newSite, updateNewSiteStore,
    } = this.props;
    const { open, step } = this.state;
    const {
      name,
      addressLine1,
      addressLine2,
      postcode,
    } = newSite;

    return (
      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={() => this.handleClickOpen()}
        >
          Create a site
        </Button>
        <Dialog
          open={open}
          fullScreen={isMobile}
          fullWidth
          classes={{ paper: classes.root }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>Create a new site</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <DialogContent>
            <div style={{ display: 'grid' }}>
              <TextField
                id="name"
                type="text"
                variant="outlined"
                margin="normal"
                label="Site name"
                value={name}
                onChange={(event) => updateNewSiteStore(
                  { name: event.target.value },
                )}
              />
              <TextField
                id="addressLine1"
                type="text"
                variant="outlined"
                margin="normal"
                label="Site AddressLine 1"
                value={addressLine1}
                onChange={(event) => updateNewSiteStore(
                  { addressLine1: event.target.value },
                )}
              />
              <TextField
                id="addressLine2"
                type="text"
                variant="outlined"
                margin="normal"
                label="Site AddressLine 2"
                value={addressLine2}
                onChange={(event) => updateNewSiteStore(
                  { addressLine2: event.target.value },
                )}
              />
              <TextField
                id="postcode"
                type="text"
                variant="outlined"
                margin="normal"
                label="Site Postcode"
                value={postcode}
                onChange={(event) => updateNewSiteStore(
                  { postcode: event.target.value },
                )}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary" variant="outlined">
              Close
            </Button>
            <ConditionallyVisible condition={step === 1}>
              <Button onClick={() => this.decreaseStep()} color="secondary" variant="outlined">
                Back
              </Button>
            </ConditionallyVisible>
            <Button
              onClick={() => this.handleCreateSite()}
              color="primary"
              variant="contained"
            >
              CREATE
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
CreateSiteDialog.propTypes = {
  classes: PropTypes.shape().isRequired,
  newSite: PropTypes.shape().isRequired,
  resetNewSiteStore: PropTypes.func.isRequired,
  requestSiteCreation: PropTypes.func.isRequired,
  updateNewSiteStore: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sites } = state;
  const { newSite } = sites;
  return {
    newSite,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateNewSiteStore: (value) => dispatch(siteActions.updateNewSiteStore(
    value,
  )),
  requestSiteCreation: () => dispatch(siteActions.requestSiteCreation()),
  resetNewSiteStore: () => dispatch(siteActions.resetNewSiteStore()),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withStyles(styles())(CreateSiteDialog));
