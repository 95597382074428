import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import Select from 'react-select';
import * as userActions from '../../../reducers/usersReducer';
import TabbedNavigation from '../../../shared-components/TabbedNavigation';
import PreviousBookerUserSearch from '../../users/components/PreviousBookerUserSearch';
import NewBookerUserCreation from '../../users/components/NewBookerUserCreation';

const InviteAssociate = (props) => {
  const [open, setOpen] = useState(false);
  const tabs = [];
  const {
    user,
    updateAssociateUserDetails,
    resetAssociateUserDetails,
    inviteUserAsAssociate,
    associateDetails,
  } = props;
  const options = [
    { label: 'Referee', value: 'REFEREE' },
    { label: 'Umpire', value: 'UMPIRE' },
    { label: 'Coach', value: 'COACH' },
  ];

  useEffect(() => {
    updateAssociateUserDetails({ role: 'REFEREE' });
  }, []);

  tabs.push({
    id: 0,
    name: 'Existing User',
    body: (<PreviousBookerUserSearch
      updateUserDetails={(updatedUserDetails) => updateAssociateUserDetails(updatedUserDetails)}
      user={user}
    />),
  });

  tabs.push({
    id: 1,
    name: 'New User',
    onClick: () => {
      resetAssociateUserDetails();
      updateAssociateUserDetails({ role: 'REFEREE' });
    },
    body: (<NewBookerUserCreation
      associate
      updateUserDetails={(updatedUserDetails) => {
        updateAssociateUserDetails(updatedUserDetails);
      }}
      user={user}
    />),
  });

  return (
    <>
      <Button color="primary" variant="contained" onClick={() => setOpen(!open)}>
        Invite Associate
      </Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <h2>Invite Associate</h2>
          <div className="reservation-creation-user-selecton">
            <TabbedNavigation tabsToNavigate={tabs} />
            <div style={{
              textAlign: 'center',
              margin: 'auto',
              maxWidth: 400,
              marginTop: 20,
              marginBottom: 20,
            }}
            >
              <Typography gutterBottom type="subheading">
                Select Role:
              </Typography>
              <Select
                onChange={(e) => {
                  updateAssociateUserDetails({ role: e.value });
                }}
                options={options}
                defaultValue={options[0]}
                menuPosition="fixed"
              />
            </div>

          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => resetAssociateUserDetails && setOpen(false)} color="secondary" variant="outlined">
            Close
          </Button>
          <Button onClick={() => inviteUserAsAssociate(associateDetails) && setOpen(false)} color="primary" variant="contained">
            Invite User
          </Button>
        </DialogActions>
      </Dialog>
    </>

  );
};

InviteAssociate.propTypes = {
  user: PropTypes.shape().isRequired,
  updateAssociateUserDetails: PropTypes.func.isRequired,
  resetAssociateUserDetails: PropTypes.func.isRequired,
  inviteUserAsAssociate: PropTypes.func.isRequired,
  associateDetails: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  user: state.reservation.userDetailsForReservation,
  associateDetails: state.users.associate,
});

const mapDispatchToProps = (dispatch) => ({
  updateAssociateUserDetails: (keyWithValue) => dispatch(
    userActions.updateAssociateUserDetails(keyWithValue),
  ),
  resetAssociateUserDetails: () => dispatch(
    userActions.resetAssociateUserDetails(),
  ),
  inviteUserAsAssociate: (role) => dispatch(
    userActions.inviteUserAsAssociate(role),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteAssociate);
