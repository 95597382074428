/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Tabs,
  Tab,
  Typography,
  Box,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import NewStripeAccount from './StripeRequiredComponents/NewStripeAccount';
import ExistingStripeAccount from './StripeRequiredComponents/ExistingStripeAccount';

const PaymentConfigDialog = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = ({ children, value, index }) => (
    <div hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );

  return (
    <>
      <div style={{ margin: '0rem 2rem' }}>
        <Typography variant="h5" style={{ marginBottom: '2rem' }}>Set Up Your Stripe Account</Typography>

        <Alert severity="warning" style={{ marginBottom: '2rem' }}>
          <Typography variant="body2">
            Thank you for completing your Partnership Agreement.
            The next step is now to connect your Stripe account to Pitchbooking.
          </Typography>
          <Typography variant="body2">
            Please choose from either option below to begin this process.
            Once complete, your Pitchbooking Dashboard will open up in
            full and you will be able to access your bookings, calendar etc.
            You will not be able to access the full dashboard
            until your Stripe account has been connected.
          </Typography>
        </Alert>

        <div style={{ display: 'flex' }}>
          <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
            <Tab label="Create New Stripe Account" />
            <Tab label="Add Existing Stripe Account" />
          </Tabs>
        </div>

        <TabPanel value={value} index={0}>
          <NewStripeAccount />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <ExistingStripeAccount />
        </TabPanel>
      </div>
    </>
  );
};

export default PaymentConfigDialog;
