/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import * as sitesActions from '../../reducers/sitesReducer';
import * as companyActions from '../../reducers/companiesReducer';
import * as schedulesActions from '../../reducers/schedulesReducer';
import FacilityToolbar from './components/FacilityToolBar';
import SitesWithFacilities from './components/SitesWithFacilities';
import 'rc-time-picker/assets/index.css';
import { usePendingChanges } from '../../hooks';

const FacilitiesPage = ({
  requestSitesWithFacilitiesRetrieval,
  getCompanyInfo, getSchedules,
}) => {
  const pending = usePendingChanges();

  let sitesFacilities = useSelector((state) => state.sites.sitesFacilities);

  useEffect(() => {
    requestSitesWithFacilitiesRetrieval();
    getCompanyInfo();
    getSchedules();
  }, []);

  if (sitesFacilities && pending?.sites) {
    const merged = [];

    for (const siteId of Object.keys(pending.sites)) {
      const site = pending.sites[siteId];
      if (!site.facilities) continue;

      const existing = sitesFacilities.find((s) => s.id === siteId);
      const facilities = Object.values(pending.facilities).filter((x) => x.siteId === siteId);

      if (existing) {
        merged.push({
          ...existing,
          facilities: [
            ...existing.facilities,
            ...facilities,
          ],
        });
      } else {
        const newSite = {
          ...site,
          facilities,
        };
        merged.push(newSite);
      }
    }

    for (const site of sitesFacilities) {
      if (!merged.find((s) => s.id === site.id)) {
        merged.push(site);
      }
    }

    sitesFacilities = merged;
  }

  return (
    <div style={{ backgroundColor: '#fafbff', minHeight: '93vh' }}>
      <FacilityToolbar sitesFacilities={sitesFacilities} />
      <SitesWithFacilities sites={sitesFacilities} />
    </div>
  );
};

FacilitiesPage.propTypes = {
  requestSitesWithFacilitiesRetrieval: PropTypes.func.isRequired,
  getCompanyInfo: PropTypes.func.isRequired,
  getSchedules: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyInfo: () => dispatch(companyActions.getCompanyInfo()),
  getSchedules: () => dispatch(schedulesActions.getSchedules()),
  requestSitesWithFacilitiesRetrieval: () => dispatch(
    sitesActions.requestSitesWithFacilitiesRetrieval(),
  ),
});

export default connect(null, mapDispatchToProps)(FacilitiesPage);
