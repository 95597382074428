// @flow weak

import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { invoiceNumberFormatted } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import * as actions from '../../reducers/invoicesReducer';

class VoidInvoiceDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      buttonDisabled: false,
    };
  }

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  performAction = (invoiceId) => {
    const { voidInvoice, getCompanyInvoices } = this.props;
    voidInvoice(invoiceId);
    getCompanyInvoices('UNPAID');
    this.setState({ open: false, buttonDisabled: true });
  }

  render() {
    const isMobile = window.innerWidth < 768;
    const {
      invoice,
    } = this.props;
    const { buttonDisabled, open } = this.state;
    // const { reservationsPaymentCreationSuccess, reservationsCreationError } = invoice;
    const dialogueActionSuccess = null;

    return (
      <div>
        <Button
          variant="contained"
          style={{
            marginRight: '10px',
          }}
          onClick={() => this.setState({ open: true })}
        >
          Void
        </Button>
        <Dialog open={open} fullScreen={isMobile} maxWidth="md">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>{`Void invoice ${invoiceNumberFormatted(invoice.invoiceNumber)}`}</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <ConditionallyVisible condition={dialogueActionSuccess === null}>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to void this invoice? This cannot be undone.
              </DialogContentText>
              <DialogContentText>
                All reservations on this invoice will remain unpaid.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={() => this.handleRequestClose()} color="secondary">
                Go back
              </Button>
              <Button id="manager-bookings-void-invoice" disabled={buttonDisabled} variant="contained" onClick={() => this.performAction(invoice.id)} color="primary">
                Confirm Void
              </Button>
            </DialogActions>
          </ConditionallyVisible>
          {/* <ConditionallyVisible condition={reservationsPaymentCreationSuccess === false}>
            <DialogContent>
              <DialogContentText>
                <div style={{ color: '#ac372f', padding: '45px' }}>
                Error! There was a problem recording payment for these reservations.</div>
                <ConditionallyVisible condition={reservationsCreationError !== null}>
                  <div style={{ color: '#ac372f', padding: '45px' }}>
                  {reservationsCreationError}</div>
                </ConditionallyVisible>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined"
              onClick={() => this.handleRequestClose()}
              color="secondary">
                Close
              </Button>
            </DialogActions>
          </ConditionallyVisible>
          <ConditionallyVisible
            condition={reservationsPaymentCreationSuccess
              !== null && reservationsPaymentCreationSuccess}
          >
            <DialogContent>
              <DialogContentText>
                <div style={{ color: '#47FEB4', padding: '45px' }}>Success! Payment recorded</div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined"
              onClick={() => this.handleRequestClose()}
              color="secondary">
                Close
              </Button>
            </DialogActions>
          </ConditionallyVisible> */}
        </Dialog>
      </div>
    );
  }
}

VoidInvoiceDialog.propTypes = {
  getCompanyInvoices: PropTypes.func.isRequired,
  voidInvoice: PropTypes.func.isRequired,
  invoice: PropTypes.shape().isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyInvoices: (a, b) => dispatch(actions.getCompanyInvoices(a, b)),
  voidInvoice: (invoiceId) => dispatch(actions.voidInvoice(invoiceId)),
});

export default connect(null, mapDispatchToProps)(VoidInvoiceDialog);
