// @flow weak

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { requestXeroConsentUrl } from '../reducers/utilsReducer';

// eslint-disable-next-line react/prefer-stateless-function
class ReauthoriseXeroButton extends Component {
  render() {
    const { doRequestXeroConsentUrl } = this.props;
    return (
      <Button
        onClick={() => doRequestXeroConsentUrl()}
        color="secondary"
        variant="contained"
        style={{ margin: '20px' }}
      >
        Reauthorise Xero
      </Button>
    );
  }
}

ReauthoriseXeroButton.propTypes = {
  doRequestXeroConsentUrl: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  doRequestXeroConsentUrl: () => dispatch(requestXeroConsentUrl()),
});

export default connect(null, mapDispatchToProps)(ReauthoriseXeroButton);
