import React from 'react';
import BookingStepperTimeslotPicker from './BookingStepperTimeslotPicker';
import BookingStepperUserInfoPicker from './BookingStepperUserInfoPicker';
import BookingStepperPaymentInfoPicker from './BookingStepperPaymentInfoPicker';
import BookingStepperBasket from './BookingStepperBasket';

const BookingStepperSteps = () => [
  {
    title: 'Booker Information Selection',
    view: <BookingStepperUserInfoPicker />,
  },
  {
    title: 'Timeslot Selection:',
    view: <BookingStepperTimeslotPicker />,
  },
  {
    title: 'Basket',
    view: <BookingStepperBasket />,
  },
  {
    title: 'Payment Status Selection:',
    view: <BookingStepperPaymentInfoPicker />,
  },
];

export default BookingStepperSteps;
