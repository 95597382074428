/**
 * Returns the origin of the booker and manager apps based on the current URL.
 * @returns {{ bookerUrl: string, managerUrl: string }} - { bookerUrl, managerUrl }
 */
const useOrigin = () => {
  const { origin } = window.location;

  let bookerUrl = 'https://pitchbooking.com';
  let managerUrl = 'https://manager.pitchbooking.com';
  let env = 'PROD';

  if (origin.includes('manager-demo.')) {
    bookerUrl = 'https://demo.pitchbooking.com';
    managerUrl = 'https://manager-demo.pitchbooking.com';
    env = 'DEMO';
  } else if (origin.includes('localhost:4999')) {
    bookerUrl = 'http://localhost:4444';
    managerUrl = 'http://localhost:4999';
    env = 'LOCAL';
  }

  return {
    bookerUrl,
    managerUrl,
    env,
  };
};

export default useOrigin;
