/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import '../../styles/billing.css';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import PriceTable from './priceTable';
import PriceBreakdownTable from './priceBreakdownTable';
import pbLogo from '../../images/pbLogo.svg';
import AddTeamMemberDialog from '../teamMembers/components/AddTeamMemberDialog';
import EditBillingDialog from './editBillingDialog';

const formatTextWithBoldAndLineBreaks = (text) => {
  // Check if text is null or undefined
  if (!text) return null;

  // Proceed with your existing logic
  return text.split('\\n\\n').flatMap((segment, index) => {
    // Split the segment by '**' for bold formatting
    const parts = segment.split('**').map((part, partIndex) => (
      partIndex % 2 === 1 ? <strong>{part}</strong> : part
    ));

    // Combine the parts into a single array, adding double line breaks as necessary
    return [
      ...parts,
      ...(index < text.split('\\n\\n').length - 1 ? [<br key={`br1-${index}`} />, <br key={`br2-${index}`} />] : []),
    ];
  });
};

const offerNotice = (offerValid, billing) => {
  if (!offerValid) return null;

  return (
    <Alert severity="success" icon={false} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <AlertTitle style={{ fontSize: 'xx-large' }}>
          {billing.offerTitle ? `🌟 ${billing.offerTitle} Offer 🌟` : '🌟 Special Offer 🌟'}
        </AlertTitle>

        {billing.offerNote && (
          <div style={{ fontSize: 'larger' }}>
            {billing.offerNote}
            {' '}
            —
            {' '}
            <strong>Save now!</strong>
            <br />
          </div>
        )}

        <span style={{ color: 'red', fontWeight: 'bold' }}>
          Offer expires on:
          {' '}
          {moment(billing.offerExpiresAt).format('DD MMM YYYY')}
        </span>
        <br />
        <br />

        <span style={{ textDecoration: 'line-through', marginRight: '10px' }}>
          {getCurrencySymbol(billing.company.currency)}
          {billing.amount.toFixed(2)}
          {' + VAT '}
          per month
        </span>
        <div style={{ fontSize: 'larger' }}>
          <span style={{
            color: '#000000', fontWeight: 'bold', marginRight: '10px', fontSize: '1.2em',
          }}
          >
            {getCurrencySymbol(billing.company.currency)}
            {billing.offerAmount.toFixed(2)}
            {' + VAT '}
            per month
          </span>
        </div>
        <span style={{ fontSize: '0.8em', fontStyle: 'italic' }}>
          {' '}
          (Save
          {' '}
          {getCurrencySymbol(billing.company.currency)}
          {(billing.amount - billing.offerAmount).toFixed(2)}
          {' + VAT '}
          per month)
        </span>
        <br />
        <br />
        <a
          href="#billingConfirmationSection"
          style={{
            color: '#4ee7ad',
            textDecoration: 'underline',
            fontWeight: 'bold',
            cursor: 'pointer',
            fontSize: '1.1em',
          }}
        >
          Get Your Special Offer Now
        </a>
      </div>
    </Alert>
  );
};

const BillingAgreementInfo = ({
  billing, isPBAdmin, updateBilling,
}) => {
  if (!billing) {
    return (
      <div />
    );
  }
  if (!billing.company) {
    return (
      <div />
    );
  }

  const currencySym = getCurrencySymbol(billing.currencyOverride || billing.company.currency);
  const { country } = billing.company;
  let taxRate = currencySym === '£' ? 0.2 : 0;
  if (country && country !== 'United Kingdom') {
    taxRate = 0;
  }
  const offerValid = billing?.offerExpiresAt
        && moment().isBefore(moment(billing.offerExpiresAt).endOf('day'))
        && billing?.offerAmount
        && billing?.offerAmount < billing?.amount;

  const [breakdownOpen, setBreakdownOpen] = React.useState(false);
  const { showBreakdown } = billing;
  return (

    <div>
      {offerNotice(offerValid, billing)}
      <br />
      <div className="billingLogosBox">
        {billing.company.logoUrl && (<img src={billing.company.logoUrl} alt="" className="billingLogo" />)}
        <img src={pbLogo} alt="" className="billingLogo" />
      </div>
      <br />
      <br />
      <Typography variant="h4">
        {billing.company.name}
        {' '}
        - Pitchbooking Ltd
      </Typography>
      <br />
      <Typography variant="h4" color="textSecondary">
        Partnership Agreement
      </Typography>
      <br />
      <Typography align="left" variant="h5" color="primary">
        Agreement summary
      </Typography>
      <br />
      <ConditionallyVisible condition={!billing.gameOn}>
        <Typography align="left" variant="body1" style={{ lineHeight: '2' }}>
          Pitchbooking Ltd will host the
          {' '}
          {billing.company.name}
          {' '}
          listings on pitchbooking.com for them to be booked and paid for in
          real-time by the public. In addition, nominated administrators from
          {' '}
          {billing.company.name}
          {' '}
          will get access to the Pitchbooking Manager Dashboard in order to
          facilitate and monitor bookings and payments.
        </Typography>
      </ConditionallyVisible>
      <ConditionallyVisible condition={billing.gameOn}>
        <Typography align="left" variant="body1" style={{ lineHeight: '2' }}>
          {'Thank you for your interest in the Pitchbooking Game On! program. You can help facilitate more sessions for women and girls sport in your community. List any women and girls event - classes, coaching sessions, tournaments and camps - for free! By clicking the button below you will be prompted to create your first women and girls event. Once created, make sure to click \u0027Activate Promotiont\u0027 to avail of the Game On! program.'}
        </Typography>
        <p>*Standard Stripe online transaction fees apply.</p>
      </ConditionallyVisible>
      <br />
      <br />
      <>
        <ConditionallyVisible condition={billing.amount > 0}>
          <Typography align="left" variant="h5" color="primary">
            Price summary
          </Typography>
          <br />
        </ConditionallyVisible>

        <EditBillingDialog
          billing={billing}
          currencySym={currencySym}
          updateBilling={(billingParams) => updateBilling(billingParams)}
        />
        <ConditionallyVisible condition={billing.amount > 0}>

          <ConditionallyVisible condition={
        (billing?.externalFacilities || billing?.internalFacilities) && billing.status === 'PENDING' && showBreakdown
        }
          >
            <PriceBreakdownTable
              billing={billing}
              currencySym={currencySym}
              taxRate={taxRate}
              isPBAdmin={isPBAdmin}
              offerValid={offerValid}
              offerAmount={billing.offerAmount}
              offerExpiresAt={billing.offerExpiresAt}
              updateBilling={(billingParams) => updateBilling(billingParams)}
              open={breakdownOpen}
              setOpen={setBreakdownOpen}
            />
            <br />
          </ConditionallyVisible>
          <ConditionallyVisible condition={!breakdownOpen}>
            <PriceTable
              amount={billing.amount}
              currencySym={currencySym}
              taxRate={taxRate}
              offerValid={offerValid}
              offerAmount={billing.offerAmount}
              offerExpiresAt={billing.offerExpiresAt}
            />
          </ConditionallyVisible>
          <br />
        </ConditionallyVisible>

        <Typography align="left" variant="body1" style={{ lineHeight: '2', whiteSpace: 'pre-line' }}>
          {formatTextWithBoldAndLineBreaks(billing?.note)}
        </Typography>
        <br />
        <ConditionallyVisible condition={billing.status === 'PENDING'}>
          <Typography align="left" variant="body1" style={{ lineHeight: '2', whiteSpace: 'pre-line', fontStyle: 'italic' }}>
            If you are not the correct person to complete this agreement,
            please add the correct person as a Finance Officer below.
          </Typography>
          <AddTeamMemberDialog title="Add Finance Officer" />
        </ConditionallyVisible>
      </>
      <br />
    </div>
  );
};

BillingAgreementInfo.propTypes = {
  billing: PropTypes.shape().isRequired,
  isPBAdmin: PropTypes.bool.isRequired,
  updateBilling: PropTypes.func.isRequired,
};

export default (BillingAgreementInfo);
