import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../../../styles/payments.css';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible/alternativelyVisible';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Menu from '@material-ui/core/Menu';
import { useDispatch, useSelector } from 'react-redux';
import PBButton from '../../../components/Button';
import AddGoalScorersDialog from './AddGoalScorersDialog';
import ViewGoalScorersDialog from './ViewGoalScorersDialog';
import AssignedAssociateCell from './AssignedAssociateCell';
import ToggleButtons from '../../../components/ToggleButtons';
import { PrintDiv } from '../../../helpers';
import ScoreInput from './ScoreInput';
import CancelFixtureDialog from './CancelFixtureDialog';
import { updateLeagueFixtureFacility, editLeagueFacility } from '../../../reducers/leaguesReducer';
import FacilityUpdateDialog from '../../../components/FacilityUpdateDialog';
import EditFixtureResultDialog from './EditFixtureResultDialog';
import FixtureCostDialog from './FixtureCostDialog';

const FixtureList = ({
  fixtures,
  updateScore,
  generated,
  saveResult,
  teams,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { isLeagueUpdating } = useSelector((state) => state.leagues);
  const accessRestrictions = useSelector((state) => state.leagues.league.accessRestrictions);
  const dispatch = useDispatch();
  let displayFixtures = fixtures;
  const fixturesLength = fixtures.length;
  const [selectedRound, setSelectedRound] = useState(1);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [viewFixturesBy, setViewFixturesBy] = useState('ROUND');

  const fixtureViewData = [{
    buttonTitle: 'By Round',
    buttonValue: 'ROUND',
  },
  {
    buttonTitle: 'By Team',
    buttonValue: 'TEAM',
  },
  {
    buttonTitle: 'View All',
    buttonValue: 'ALL',
  },
  ];

  const updateLeagueFacility = useSelector((state) => state.leagues.editLeagueFacility);

  if (generated && viewFixturesBy === 'ROUND') {
    displayFixtures = fixtures.filter((round) => round.name === selectedRound);
  }
  if (generated && viewFixturesBy === 'TEAM' && selectedTeam) {
    displayFixtures = [];
    fixtures.forEach(
      (round) => {
        const game = round.value.filter(
          (e) => e.teamA.id === selectedTeam
          || e.teamB.id === selectedTeam,
        );
        displayFixtures.push({
          name: round.name, value: game,

        });
      },
    );
  }

  return (
    <>
      <Paper style={{ margin: '1rem 0' }}>
        <div style={{ padding: '1rem' }}>
          <ConditionallyVisible
            condition={generated}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.75rem' }}>
              <ToggleButtons
                buttonsData={fixtureViewData}
                changeOption={(option) => setViewFixturesBy(option)}
                value={viewFixturesBy}
              />
              <PBButton
                color="primary"
                variant="contained"
                onClick={() => PrintDiv('fixtureList', 'Fixtures')}
                buttonRole="pbAdmin"
              >
                Print Fixtures
              </PBButton>
            </div>
          </ConditionallyVisible>
          <div id="fixtureList">
            <ConditionallyVisible
                // eslint-disable-next-line eqeqeq
              condition={generated && viewFixturesBy === 'TEAM'}
            >
              <Select
                displayEmpty
                value={selectedTeam}
                label="Selected Team"
                onChange={(event) => setSelectedTeam(
                  event.target.value,
                )}
              >
                <MenuItem value={null}>Select Team</MenuItem>
                {teams && teams.map((team) => (
                  <MenuItem value={team.id}>{team.name}</MenuItem>
                ))}
              </Select>
            </ConditionallyVisible>
            {displayFixtures.length > 0 && displayFixtures.map((round) => (
              <div>
                <div style={{ display: 'flex', alignSelf: 'center' }}>
                  <ConditionallyVisible
                    condition={generated && round.name !== 1 && viewFixturesBy === 'ROUND'}
                  >
                    <ChevronLeft
                      style={{ marginTop: 14 }}
                      onClick={() => setSelectedRound(selectedRound - 1)}
                    />
                  </ConditionallyVisible>
                  <h5>
                    {`Round ${round.name}`}
                  </h5>
                  <ConditionallyVisible
                // eslint-disable-next-line eqeqeq
                    condition={generated && parseInt(fixturesLength, 10) !== round.name
                     && viewFixturesBy === 'ROUND'}
                  >
                    <ChevronRight
                      style={{ marginTop: 14 }}
                      onClick={() => setSelectedRound(selectedRound + 1)}
                    />
                  </ConditionallyVisible>

                </div>
                <Table>
                  <TableBody>
                    {round.value && round.value.map((game) => (
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {game.teamA?.name || 'Free Week'}
                        </TableCell>
                        <ConditionallyVisible condition={generated}>
                          <TableCell align="center" className="excludeFromPrint">
                            <ConditionallyVisible condition={game.teamA && game.teamB}>
                              <ScoreInput
                                id={`${game.id}A`}
                                teamScore={game.teamAScore}
                                disabled={game.status !== 'ACTIVE' || game.teamAScore !== null}
                                updateScore={(event) => updateScore(event.target.value, 'teamAScore', game.id)}
                              />
                            </ConditionallyVisible>
                          </TableCell>
                        </ConditionallyVisible>
                        <TableCell align="center">vs</TableCell>
                        <ConditionallyVisible condition={generated}>
                          <TableCell align="center" className="excludeFromPrint">
                            <ConditionallyVisible condition={game.teamA && game.teamB}>
                              <ScoreInput
                                id={game.id}
                                disabled={game.status !== 'ACTIVE' || game.teamAScore !== null}
                                teamScore={game.teamBScore}
                                updateScore={(event) => updateScore(event.target.value, 'teamBScore', game.id)}
                              />
                            </ConditionallyVisible>
                          </TableCell>
                        </ConditionallyVisible>
                        <TableCell align="right">
                          {game.teamB?.name || 'Free Week'}
                        </TableCell>
                        <ConditionallyVisible
                          condition={generated && game.startTime && game.status !== 'VOID'}
                        >
                          <TableCell align="right">
                            <div>
                              {`${moment(game.startTime).format('DD/MM/YYYY HH:mm')} - ${moment(game.endTime).format('HH:mm')}`}
                            </div>
                            <div>
                              {game.facilityName}
                            </div>
                            <Menu />
                          </TableCell>
                        </ConditionallyVisible>

                        <ConditionallyVisible condition={generated}>
                          <ConditionallyVisible condition={game.status === 'ACTIVE'}>
                            {/* <TableCell className="excludeFromPrint">
                              <ConditionallyVisible condition={game.teamA && game.teamB}>
                                <AssignedAssociateCell fixture={game} />
                              </ConditionallyVisible>
                            </TableCell> */}
                            <AlternativelyVisible
                              condition={game.teamAScore === null && game.teamBScore === null}
                            >
                              <>
                                <TableCell className="excludeFromPrint">
                                  <ConditionallyVisible condition={game.teamA && game.teamB}>
                                    {/* <PBButton
                                      onClick={() => saveResult(game.id)}
                                      color="secondaryIcon"
                                      disabled={isLeagueUpdating}
                                    >
                                      Save Result
                                    </PBButton> */}
                                    <FixtureCostDialog
                                      saveResult={saveResult}
                                      fixtureId={game.id}
                                    />
                                  </ConditionallyVisible>
                                </TableCell>
                                <TableCell>
                                  <ConditionallyVisible condition={game.teamA && game.teamB}>
                                    <CancelFixtureDialog game={game} />
                                  </ConditionallyVisible>
                                </TableCell>
                              </>
                              <>
                                <TableCell className="excludeFromPrint">
                                  <ConditionallyVisible condition={game.teamA && game.teamB}>
                                    <EditFixtureResultDialog
                                      fixtureId={game.id}
                                      teamA={{
                                        name: game.teamA?.name ?? 'BYE',
                                        id: game.teamA?.id ?? null,
                                        score: game.teamAScore,
                                      }}
                                      teamB={{
                                        name: game.teamB?.name ?? 'BYE',
                                        id: game.teamB?.id ?? null,
                                        score: game.teamBScore,
                                      }}
                                    />
                                  </ConditionallyVisible>
                                </TableCell>
                                <TableCell className="excludeFromPrint">
                                  <ConditionallyVisible
                                    condition={game.user_fixtures
                                       && game.user_fixtures.length === 0}
                                  >
                                    <AddGoalScorersDialog fixtureId={game.id} />
                                  </ConditionallyVisible>
                                  <ConditionallyVisible
                                    condition={game.user_fixtures && game.user_fixtures.length > 0}
                                  >
                                    <ViewGoalScorersDialog
                                      fixtureId={game.id}
                                      goalScorers={game.user_fixtures}
                                    />
                                  </ConditionallyVisible>
                                </TableCell>
                              </>
                            </AlternativelyVisible>
                          </ConditionallyVisible>
                          <ConditionallyVisible condition={game.status === 'FORFEIT'}>
                            <TableCell className="excludeFromPrint">
                              This game has been forfeited
                            </TableCell>
                          </ConditionallyVisible>
                          <ConditionallyVisible condition={game.status === 'CANCELLED'}>
                            <TableCell className="excludeFromPrint">
                              This game has been cancelled
                            </TableCell>
                          </ConditionallyVisible>
                          <ConditionallyVisible condition={game.status === 'VOID'}>
                            <TableCell className="excludeFromPrint">
                              This game has been voided
                            </TableCell>
                          </ConditionallyVisible>
                          <ConditionallyVisible condition={accessRestrictions.length > 0 && game.status === 'ACTIVE'}>
                            <TableCell className="excludeFromPrint">
                              <Button onClick={() => {
                                dispatch(editLeagueFacility(game));
                              }}
                              >
                                Edit Facility
                              </Button>
                            </TableCell>
                          </ConditionallyVisible>
                        </ConditionallyVisible>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ))}
          </div>

          <FacilityUpdateDialog
            allocation={updateLeagueFacility?.allocation}
            onClose={() => dispatch(editLeagueFacility(null))}
            onChange={async (facility, timeslots) => {
              dispatch(
                updateLeagueFixtureFacility(
                  updateLeagueFacility?.id,
                  timeslots.map((x) => ({
                    ...x,
                    endTime: moment(x.endTime).add(1, 's'),
                  })),
                  facility,
                ),
              );
            }}
          />
        </div>
      </Paper>
    </>
  );
};

FixtureList.propTypes = {
  fixtures: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateScore: PropTypes.func.isRequired,
  saveResult: PropTypes.func.isRequired,
  generated: PropTypes.bool.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default (FixtureList);
