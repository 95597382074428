import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import PayoutsTable from './PayoutsTable';
import * as actions from '../../reducers/payoutsReducer';

class Payouts extends React.Component {
  componentDidMount() {
    const {
      requestPayouts,
    } = this.props;
    requestPayouts();
  }

  render() {
    const {
      payouts, currency, timezone, stripeCompanyId,
    } = this.props;
    const currencySym = getCurrencySymbol(currency);

    return (
      <>
        <div style={{
          display: 'flex', flexDirection: 'column', padding: '1.5rem', gap: '1rem',
        }}
        >
          <Alert severity="warning">
            <AlertTitle style={{ fontWeight: 600 }}>Please Note</AlertTitle>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Typography variant="body2">
                {`Please be aware that making manual edits directly in the Stripe dashboard may result in inconsistencies 
                between your Stripe payouts and the reporting within the Pitchbooking Manager Dashboard. 
                This includes potential discrepancies in payout reconciliation.`}
              </Typography>
              <Typography variant="body2">
                {`For accurate reporting and to avoid such issues,
                we recommend managing all transactions and edits through the Pitchbooking platform.`}
              </Typography>
            </div>
          </Alert>

          <PayoutsTable
            payouts={payouts}
            currencySym={currencySym}
            timezone={timezone}
            stripeCompanyId={stripeCompanyId}
          />
        </div>
      </>
    );
  }
}

Payouts.propTypes = {
  requestPayouts: PropTypes.func.isRequired,
  payouts: PropTypes.shape(),
  currency: PropTypes.string,
  timezone: PropTypes.string,
  stripeCompanyId: PropTypes.string.isRequired,
};

Payouts.defaultProps = {
  payouts: [],
  currency: 'gbp',
  timezone: 'Europe/London',
};

const mapStateToProps = (state) => ({
  payouts: state.payouts.payouts,
  currency: state.companies.companyInfo.currency,
  timezone: state.companies.companyInfo.timezone,
  stripeCompanyId: state.companies.companyInfo.stripeId,
});

const mapDispatchToProps = (dispatch) => ({
  requestPayouts: () => dispatch(actions.requestPayouts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payouts);
