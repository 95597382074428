import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible/alternativelyVisible';
import * as userActions from '../../../reducers/usersReducer';
import MenuHeading from '../../../components/MenuHeading';
import InvoiceReservationsTable from './InvoiceReservationsTable';
import LoadingSection from '../../../components/LoadingComponents/LoadingSection';

class InvoiceStepperReservationsPicker extends Component {
  componentDidMount() {
    const { getUsersBookings, invoice } = this.props;
    const { user } = invoice;
    const userId = user.id;
    getUsersBookings(userId, 'UNPAID');
  }

  render() {
    const {
      userBookings, userSubscriptionReservations, invoice, userBookingsLoading,
    } = this.props;

    const potentialBookings = [].concat(userBookings).concat(...userSubscriptionReservations)
      .sort((a, b) => {
        const keyA = b.startTime;
        const keyB = a.startTime;
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });

    let potentialBookingsUnpaid = potentialBookings.filter(
      (res) => !res.paid && !res.invoicePending && (res.paymentStatusText !== 'Stripe payment scheduled'),
    );
    potentialBookingsUnpaid = potentialBookingsUnpaid.filter((res) => !res.invoices?.some((i) => i.status === 'PENDING'));

    return (
      <>
        <MenuHeading title="Select the bookings you want to charge for:" />
        <MenuHeading title="*If you do not want to charge for a specific booking(s), click NEXT without making a selection. This will enable you to create a standalone or miscellaneous invoice/payment link without a booking attached." />
        <AlternativelyVisible condition={userBookingsLoading}>
          <>
            <LoadingSection loadingText="Retrieving applicable bookings for this user." />
          </>
          <>
            <AlternativelyVisible condition={potentialBookingsUnpaid.length > 0}>
              <InvoiceReservationsTable
                invoice={invoice}
                selectable
                showType
                showSumRow={false}
                reservations={potentialBookingsUnpaid}
              />
              <>
                <br />
                <div>No unpaid bookings available for this user.</div>
              </>
            </AlternativelyVisible>
          </>
        </AlternativelyVisible>
      </>
    );
  }
}

InvoiceStepperReservationsPicker.propTypes = {
  getUsersBookings: PropTypes.func.isRequired,
  invoice: PropTypes.shape.isRequired,
  user: PropTypes.shape.isRequired,
  userBookings: PropTypes.shape.isRequired,
  userSubscriptionReservations: PropTypes.shape.isRequired,
  userBookingsLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  invoice: state.invoices.createInvoice,
  userBookings: state.users.bookings,
  userBookingsLoading: state.users.userBookingsLoading,
  userSubscriptionReservations: state.users.subscriptionReservations,
});

const mapDispatchToProps = (dispatch) => ({
  getUsersBookings: (userId, type) => dispatch(userActions.getUsersBookings(userId, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceStepperReservationsPicker);
