import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import imageSelector from '@pitchbooking-dev/pb-shared/lib/helpers/imageSelector';
import FacilityCard from './FacilityCard';
import { usePendingChanges } from '../../../hooks';
import { FacilityDeleteModal } from './FacilityDeleteModal';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
    background: theme.palette.background.paper,
    paddingTop: '30px',
    textAlign: 'left',
    backgroundColor: '#fafbff',
  },
});

function ImageGridList(props) {
  const { classes, data } = props;
  const pending = usePendingChanges()?.facilities || {};

  const [deleteFacilityModal, setDeleteFacilityModal] = useState({
    isOpen: false,
    facility: null,
  });

  const filteredData = pending ? data.filter((x) => {
    if (pending[x.id]) {
      return pending[x.id].status !== 'DELETED';
    }
    return true;
  }) : data;

  return (
    <div className={classes.root}>
      {filteredData.map((x) => {
        const tile = { ...x, ...(pending?.[x.id] || {}) };

        return (
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            backgroundColor: '#fafbff',
            paddingLeft: '20px',
          }}
          >
            <FacilityCard
              facility={tile}
              onDelete={() => {
                setDeleteFacilityModal({ isOpen: true, facility: tile });
              }}
            />
          </div>

        );
      })}

      <FacilityDeleteModal
        isOpen={deleteFacilityModal.isOpen}
        facility={deleteFacilityModal.facility}
        onClose={() => setDeleteFacilityModal({ isOpen: false, facility: null })}
      />
    </div>
  );
}

ImageGridList.propTypes = {
  classes: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
};

export default withStyles(styles)(ImageGridList);
