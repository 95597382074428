/* eslint-disable react/prop-types */
import React from 'react';
import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { bulkUserInvite } from '../../services/companiesServices';
import { useToast } from '../../hooks';

const validationSchema = Yup.object().shape({
  users: Yup.mixed().required('File is required'),
});

export const BulkInsertUserForm = ({ onSuccess }) => {
  const companyId = useSelector((state) => state.companies.companyInfo.id);
  const toast = useToast();

  return (
    <Formik
      initialValues={{
        users: null,
      }}
      onSubmit={async (values, { resetForm }) => {
        try {
          await bulkUserInvite(companyId, values, {
            'Content-Type': 'multipart/form-data',
          });
          resetForm();
          onSuccess();
          toast.trigger({
            message: 'Users invited successfully',
            type: 'success',
          });
        } catch (err) {
          console.error(err);
          toast.trigger({
            message: 'Unable to invite users. Please check your file follows the correct format.',
            type: 'error',
          });
        }
      }}
      validationSchema={validationSchema}
    >
      {({
        setFieldValue, isSubmitting,
      }) => (
        <Form>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <input
              id="users"
              name="users"
              type="file"
              onChange={(e) => {
                if (e.target.files?.length > 0) {
                  setFieldValue('users', e.target.files[0]);
                }
              }}
              accept="text/csv, application/json"
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                Invite Users
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
