export const GET_REFERRALS_REQUESTED = 'GET_REFERRALS_REQUESTED';
export const GET_REFERRALS_SUCCEEDED = 'GET_REFERRALS_SUCCEEDED';
export const GET_REFERRALS_FAILED = 'GET_REFERRALS_FAILED';

export const CREATE_REFERRALS_REQUESTED = 'CREATE_REFERRALS_REQUESTED';
export const CREATE_REFERRALS_SUCCEEDED = 'CREATE_REFERRALS_SUCCEEDED';
export const CREATE_REFERRALS_FAILED = 'CREATE_REFERRALS_FAILED';

export const initialState = {
  referrals: [],
  referralsLoading: false,
  referralsError: null,
};

export const getReferrals = () => ({
  type: GET_REFERRALS_REQUESTED,
});
export const getReferralsSucceeded = (referrals) => ({
  type: GET_REFERRALS_SUCCEEDED, referrals,
});
export const getReferralsFailed = () => ({
  type: GET_REFERRALS_FAILED,
});

export const createReferral = (values) => ({
  type: CREATE_REFERRALS_REQUESTED, values,
});
export const createReferralSucceeded = () => ({
  type: CREATE_REFERRALS_SUCCEEDED,
});
export const createReferralFailed = () => ({
  type: CREATE_REFERRALS_FAILED,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REFERRALS_REQUESTED:
      return {
        ...state,
        referralsLoading: true,
        referralsError: false,
      };
    case GET_REFERRALS_SUCCEEDED:
      return {
        ...state,
        referrals: action.referrals,
        referralsLoading: false,
        referralsError: false,
      };
    case GET_REFERRALS_FAILED:
      return {
        ...state,
        referralsLoading: true,
        referralsError: true,
      };
    case CREATE_REFERRALS_REQUESTED:
      return {
        ...state,
        referralsLoading: true,
        referralsError: false,
      };
    case CREATE_REFERRALS_SUCCEEDED:
      return {
        ...state,
        referralsLoading: false,
        referralsError: false,
      };
    case CREATE_REFERRALS_FAILED:
      return {
        ...state,
        referralsLoading: false,
        referralsError: true,
      };
    default:
      return state;
  }
};
