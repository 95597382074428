import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import facilityTemplates from '@pitchbooking-dev/pb-shared/lib/helpers/newFacilityTemplates';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  amount: Yup.number().required('Base Price is required'),
  templateName: Yup.string().required('Template Name is required'),
  template: Yup.object(),
  unitName: Yup.string().when('template', {
    is: (template) => template?.splitType !== 'NONE',
    then: Yup.string().required('Unit Name is required'),
  }),
  quantity: Yup.number().min(1, 'You must have at least 1 facility').when('template', {
    is: (template) => template?.splitType !== 'NONE',
    then: Yup.number().required('Number of Units is required'),
  }),
  subUnits: Yup.number().min(0).required('Subunits is required'),
});

const AddFacility = ({
  onSubmit, onClose, values,
}) => {
  const [initialValues, setInitialValues] = useState(values);

  useEffect(() => {
    if (values) {
      setInitialValues(values);
    }
  }, [values]);

  return (
    <Dialog
      open={values}
      fullWidth
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {
            ({
              values, errors, touched, handleChange, handleBlur, setFieldValue,
            }) => (
              <Form>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <DialogTitle>
                    {typeof values.facilityIndex === 'number' ? 'Edit Facility' : 'Add Facility'}
                  </DialogTitle>

                  <DialogActions variant="none">
                    <CloseDialogIcon onClick={onClose} />
                  </DialogActions>
                </div>

                <DialogContent>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <PBInput
                      id="name"
                      type="text"
                      label="Name"
                      value={values.name}
                      onChange={handleChange}
                      isError={touched.name && Boolean(errors.name)}
                      errorMessage={touched.name && errors.name}
                      onBlur={handleBlur}
                    />

                    <PBInput
                      id="amount"
                      type="currency"
                      label="Base Price"
                      value={values.amount}
                      onChange={handleChange}
                      isError={touched.amount && Boolean(errors.amount)}
                      errorMessage={touched.amount && errors.amount}
                      onBlur={handleBlur}
                    />

                    <PBInput
                      id="templateName"
                      type="select"
                      label="Template Name"
                      value={values.templateName}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('template', facilityTemplates.find((x) => x.templateName === e.target.value));
                      }}
                      isError={touched.templateName && Boolean(errors.templateName)}
                      errorMessage={touched.templateName && errors.templateName}
                      options={facilityTemplates.map((template) => ({
                        label: template.templateName,
                        value: template.templateName,
                      }))}
                      onBlur={handleBlur}
                    />

                    <PBInput
                      id="subUnits"
                      type="number"
                      label="Number of Sub-Units"
                      value={values.subUnits}
                      onChange={handleChange}
                      isError={touched.subUnits && Boolean(errors.subUnits)}
                      errorMessage={touched.subUnits && errors.subUnits}
                      onBlur={handleBlur}
                    />

                    <PBInput
                      id="quantity"
                      type="number"
                      label="Number of Units"
                      value={values.quantity}
                      onChange={handleChange}
                      isError={touched.quantity && Boolean(errors.quantity)}
                      errorMessage={touched.quantity && errors.quantity}
                      onBlur={handleBlur}
                    />

                    <ConditionallyVisible condition={values.template && values.template?.splitType !== 'NONE'}>
                      <PBInput
                        id="unitName"
                        type="text"
                        label="Unit Name"
                        value={values.unitName}
                        onChange={handleChange}
                        isError={touched.unitName && Boolean(errors.unitName)}
                        errorMessage={touched.unitName && errors.unitName}
                        onBlur={handleBlur}
                      />

                    </ConditionallyVisible>
                  </div>

                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color="secondary" variant="outlined">
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    {typeof values.facilityIndex === 'number' ? 'Save Changes' : 'Add'}
                  </Button>
                </DialogActions>
              </Form>
            )
          }
      </Formik>
    </Dialog>
  );
};

AddFacility.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  values: PropTypes.shape({
    siteIndex: PropTypes.number,
    facilityIndex: PropTypes.number,
    name: PropTypes.string,
    amount: PropTypes.number,
    templateName: PropTypes.string,
    template: PropTypes.shape(),
    unitName: PropTypes.string,
    quantity: PropTypes.number,
    subUnits: PropTypes.number,
  }),
};

AddFacility.defaultProps = {
  values: null,
};

export default AddFacility;
