import React from 'react';
import InvoiceStepperReservationsPicker from './InvoiceStepperReservationsPicker';
import InvoiceStepperUserPicker from './InvoiceStepperUserPicker';
import InvoiceStepperDueDate from './InvoiceStepperDueDate';
import InvoiceStepperPreview from './InvoiceStepperPreview';
import InvoiceStepperAdditionalItems from './InvoiceStepperAdditionalItems';
import InvoiceStepperConfirmation from './InvoiceStepperConfirmation';

const InvoiceStepperSteps = () => [
  {
    title: 'Booker Information Selection',
    view: <InvoiceStepperUserPicker />,
  },
  {
    title: 'Timeslot Selection:',
    view: <InvoiceStepperReservationsPicker />,
  },
  {
    title: 'Additional Items:',
    view: <InvoiceStepperAdditionalItems />,
  },
  {
    title: 'Reccurence Selection:',
    view: <InvoiceStepperDueDate />,
  },
  {
    title: 'Payment Status Selection:',
    view: <InvoiceStepperPreview />,
  },
  {
    title: 'Sending Invoice:',
    view: <InvoiceStepperConfirmation />,
  },
];

export default InvoiceStepperSteps;
