/* eslint-disable react/prop-types */
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { Typography, Avatar, Chip } from '@material-ui/core';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import { getUserMembershipService } from '../../../services/membershipsServices';
import { Loading } from './_Loading';
import { Error } from './_Error';
import { ExclusionTable, PaymentHistoryTable } from './components';
import { MaterialTable } from '../../../components/MaterialTable';
import ViewMembershipFormDialog from '../components/ViewMembershipFormDialog';
import { statusConverter } from '../utils/statusConverter';
import { Markdown } from '../../../helpers/Markdown';
import styles from './styles.module.css';

export const MembershipUserView = ({ match }) => {
  const { membershipId, userId } = match.params;

  const { data, isLoading, isError } = useQuery({
    queryKey: ['userMembership', membershipId, userId],
    queryFn: async () => {
      const { data, error } = await getUserMembershipService(membershipId, userId);
      if (error) {
        throw new Error(error);
      }
      return data;
    },
    enabled: !!(membershipId && userId),
  });

  if (isLoading) return <Loading />;
  if (isError || !data.userMemberships || data.userMemberships.length === 0) return <Error />;
  if (!data.userMemberships || data.userMemberships.length === 0) return <Error message="User membership not found." />;

  const { userMemberships, subscriptions } = data;
  const { membership } = userMemberships[0];
  const { company } = membership;
  const currencySymbol = getCurrencySymbol(company.currency);
  const tableData = userMemberships.map((userMembership) => {
    const subscription = subscriptions.find((sub) => sub.id === userMembership.subscriptionId);
    return {
      ...userMembership,
      ...subscription,
    };
  });
  const today = moment();
  const hideFormData = tableData.some((rowData) => rowData.formData);

  if (!userMemberships) return <Error message="User membership not found." />;
  if (!subscriptions) return <Error message="Subscription not found." />;

  return (
    <div className={styles.container}>
      <div style={{
        margin: '1rem',
        border: '1px solid #ccc',
        borderRadius: '1rem',
        backgroundColor: 'white',
        padding: '1rem',
      }}
      >
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          {`Membership: ${membership.name || ''}`}
        </Typography>

        <Markdown>
          {membership.description}
        </Markdown>
      </div>

      <MaterialTable
        data={tableData}
        title="Membership Details"
        columns={[
          {
            title: 'Subscription Id',
            render: (rowData) => (
              <a
                href={`https://dashboard.stripe.com/${company.stripeId}/subscriptions/${rowData.stripeSubscriptionId}`}
                target="_blank"
                rel="noreferrer"
              >
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                  {rowData.stripeSubscriptionId}
                </Typography>
              </a>
            ),
          },
          {
            title: 'Membership Start Date',
            align: 'center',
            render: (rowData) => moment(rowData.validFrom).format('Do MMMM YYYY'),
          },
          {
            title: 'Membership End Date',
            align: 'center',
            render: (rowData) => moment(rowData.validTo).format('Do MMMM YYYY'),
          },
          {
            title: 'Membership Price',
            align: 'center',
            render: (rowData) => `${currencySymbol}${rowData.membership?.price.toFixed(2) || null}`,
          },
          {
            title: 'Membership Status',
            align: 'center',
            field: 'membership.status',
            render: (rowData) => (
              <Chip
                avatar={(
                  <Avatar style={{
                    backgroundColor: statusConverter(
                      rowData.status,
                      !moment(rowData.validTo).isBefore(today),
                    ).backgroundColor,
                    display: 'flex',
                    justifyContent: 'space-around',
                    color: 'white',
                  }}
                  >
                    {statusConverter(
                      rowData.status,
                      !moment(rowData.validTo).isBefore(today),
                    ).icon}
                  </Avatar>
                )}
                label={statusConverter(
                  rowData.status,
                  !moment(rowData.validTo).isBefore(today),
                ).text}
                style={{
                  backgroundColor: statusConverter(
                    rowData.status,
                    !moment(rowData.validTo).isBefore(today),
                  ).backgroundColor,
                  color: 'black',
                  fontWeight: 'bold',
                  border: `2px solid ${statusConverter(
                    rowData.status,
                    !moment(rowData.validTo).isBefore(today),
                  ).borderColor}`,
                }}
              />
            ),
          },
          {
            title: 'Form Data',
            align: 'center',
            hidden: !hideFormData,
            render: (rowData) => (
              rowData.formData
                ? (
                  <ViewMembershipFormDialog
                    formData={rowData.formData}
                  />
                ) : null
            ),
          },
        ]}
        options={{
          search: false,
          paging: false,
          sorting: false,
          exportButton: false,
        }}
      />

      {subscriptions && (
        <>
          <PaymentHistoryTable
            orders={subscriptions.map((sub) => sub.orders).flat()}
            currency={currencySymbol}
            style={{
              maxWidth: '100%',
            }}
            userMembership={userMemberships.map((userMembership) => userMembership)}
          />
          <ExclusionTable
            exclusions={userMemberships.map((userMembership) => userMembership.exclusions)}
            userMembership={userMemberships.map((userMembership) => userMembership)}
            style={{
              maxWidth: '100%',
            }}
          />
        </>
      )}
    </div>
  );
};
