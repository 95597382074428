import {
  takeEvery, call, put, select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  getEventsService,
  createEventService,
  getEventService,
  updateEventServices,
  requestSaveEventAddonsService,
  requestRemoveEventAddonsService,
  assignEventAgentService,
  removeEventAgentService,
  updateEventTagService,
  deleteEventService,
} from '../services/eventsServices';
import * as eventsActions from '../reducers/eventsReducer';
import * as userActions from '../reducers/usersReducer';
import * as tagsActions from '../reducers/tagsReducer';

function* getEventsSaga(companyId, action) {
  const { status, orderBy } = action;
  if (companyId) {
    try {
      const response = yield call(getEventsService, companyId, status, orderBy);
      if (!response.error) {
        yield put(eventsActions.eventsRequestSucceeded(response.data));
      } else {
        yield put(eventsActions.eventsRequestFailed());
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Events Retrieval Failed : ${error}`, true));
    }
  }
}

function* getEventSaga(companyId, action) {
  const { id } = action;
  if (companyId) {
    try {
      const response = yield call(getEventService, companyId, id);
      if (!response.error) {
        yield put(eventsActions.eventRequestSucceeded(response.data));
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Event Retrieval Failed : ${error}`, true));
    }
  }
}

function* updateEventTagsSaga(companyId, action) {
  const { eventId } = action;
  const state = yield select();
  const { selectedTags } = state.tags;
  if (companyId) {
    try {
      const response = yield call(updateEventTagService, companyId, eventId, selectedTags);
      if (!response.error) {
        yield put(eventsActions.getEvents('ACTIVE'));
        yield put(sessionActions.updateSuccessfulSnackbar('Event tags successfully updated.', true));
      }
    } catch (error) {
      yield put(sessionActions.updateErrorSnackbar('Event tags failed to update.', true));
      console.log('updateEventTagsSaga', error);
    }
  }
}

function* assignEventAgentSaga(companyId, action) {
  const { eventId, agents } = action;
  const state = yield select();
  const { event } = state.events;
  if (companyId) {
    try {
      const response = yield call(assignEventAgentService, companyId, eventId, agents);
      if (!response.error) {
        yield put(eventsActions.getEvent(event.id));
        yield put(sessionActions.updateSuccessfulSnackbar('Event agent successfully assigned.', true));
      }
    } catch (error) {
      yield put(sessionActions.updateErrorSnackbar('Event agent failed to assign.', true));
      console.log('assignEventAgentSaga', error);
    }
  }
}

function* removeEventAgentSaga(companyId, action) {
  const { eventAgentId, eventId } = action;
  try {
    const response = yield call(
      removeEventAgentService, companyId, eventId, { eventAgentId },
    );
    if (!response.error) {
      const state = yield select();
      const { event } = state.events;
      const eventId = event.id;
      yield put(eventsActions.getEvent(eventId));
      yield put(sessionActions.updateSuccessfulSnackbar('Event Staff Removed', true));
    }
  } catch (error) {
    if (error.error === 'Unauthorized') {
      yield put(userActions.updateCurrentUser({}));
    }
    yield put(sessionActions.updateErrorSnackbar(`Leagues Retrieval Failed : ${error}`, true));
  }
}

function* requestSaveEventAddonsSaga(companyId, action) {
  const state = yield select();
  const { selectedAddons } = state.addons;
  const { eventId } = action;
  const reqBody = {
    addons: selectedAddons,
  };
  if (companyId) {
    try {
      const response = yield call(requestSaveEventAddonsService, companyId, eventId, reqBody);
      if (!response.error) {
        yield put(eventsActions.saveEventAddonsSucceeded(response.data));
        yield put(sessionActions.updateSuccessfulSnackbar('Addons successfully added.', true));
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Save Event Addons Failed : ${error}`, true));
    }
  }
}
function* requestRemoveEventAddonsSaga(companyId, action) {
  const { eventId, addonId } = action;
  const reqBody = {
    addonId,
    eventId,
  };
  if (companyId) {
    try {
      const response = yield call(requestRemoveEventAddonsService, companyId, reqBody);
      if (!response.error) {
        yield put(eventsActions.removeEventAddonsSucceeded(response.data));
        yield put(sessionActions.updateSuccessfulSnackbar('Addons successfully removed.', true));
      }
    } catch (error) {
      if (error.error === 'Unauthorized') {
        yield put(userActions.updateCurrentUser({}));
      }
      yield put(sessionActions.updateErrorSnackbar(`Remove Event Addons Failed : ${error}`, true));
    }
  }
}

function* updateEventSaga(companyId, eventsAction) {
  try {
    const state = yield select();
    const { eventForm } = state.events;
    const reqBody = { ...eventsAction.reqBody };
    reqBody.eventForm = eventForm;
    const updateBookingResponse = yield call(
      updateEventServices,
      companyId,
      eventsAction.eventId,
      reqBody,
    );

    if (updateBookingResponse.error) throw updateBookingResponse.error;

    yield put(eventsActions.getEvents('ACTIVE'));
    yield put(sessionActions.updateSuccessfulSnackbar('Event successfully updated.', true));
    yield put(eventsActions.resetEventStore());
  } catch (error) {
    if (error.error === 'Unauthorized') {
      yield put(userActions.updateCurrentUser({}));
    }
    yield put(sessionActions.updateErrorSnackbar(`Event Update Failed : ${error}`, true));
  }
}

function* deleteEventSaga(companyId, action) {
  const { id } = action;
  if (companyId) {
    try {
      const response = yield call(deleteEventService, companyId, id);
      if (!response.error) {
        if (response.data?.updatedEvent?.parentEventId) {
          yield put(eventsActions.getEvent(response.data.updatedEvent.parentEventId));
        } else {
          yield put(eventsActions.getEvents('ACTIVE'));
        }
        yield put(sessionActions.updateSuccessfulSnackbar('Event successfully deleted.', true));
      } else {
        yield put(sessionActions.updateErrorSnackbar(`Event failed to delete. ${response.data}`, true));
      }
    } catch (error) {
      yield put(sessionActions.updateErrorSnackbar('Event failed to delete.', true));
      console.log('deleteEventSaga', error);
    }
  }
}

function* createEventSaga(companyId, action) {
  const state = yield select();
  const { eventStore, eventForm, consentForms } = state.events;
  const { parentEventId, eventType } = action;
  const event = eventStore;
  event.parentEventId = parentEventId;
  event.type = eventType;
  const reqBody = {
    event,
  };
  if (event.customForm) {
    reqBody.eventForm = {
      ...eventForm,
      ...consentForms,
    };
  }
  try {
    const response = yield call(createEventService, companyId, reqBody);

    if (!response.error) {
      if (eventType === 'SUB_EVENT') {
        yield put(eventsActions.getEvent(parentEventId));
      } else if (eventType === 'EVENT') {
        yield put(eventsActions.eventRequestSucceeded(response.data));
        const eventId = response.data.id;
        yield put(push(`/events/${eventId}`));
      }
      yield put(eventsActions.resetEventStore());
      yield put(sessionActions.updateSuccessfulSnackbar('Event successfully created.', true));
    } else {
      throw response;
    }
  } catch (err) {
    if (err.error === 'Unauthorized') {
      yield put(userActions.updateCurrentUser({}));
    } else {
      yield put(sessionActions.updateErrorSnackbar(`Event update failed : ${err.error}`, true));
    }
    console.log('createEventSaga error', err);
  }
}

/* Watchers */
export function* getEventsWatcher(companyId) {
  yield takeEvery(eventsActions.EVENTS_RETRIEVAL_REQUESTED, getEventsSaga, companyId);
  yield takeEvery(eventsActions.TOGGLE_SELECTED_EVENTS_VIEW, getEventsSaga, companyId);
}
export function* getEventWatcher(companyId) {
  yield takeEvery(eventsActions.EVENT_RETRIEVAL_REQUESTED, getEventSaga, companyId);
}
export function* requestSaveEventAddonsWatcher(companyId) {
  yield takeEvery(eventsActions.SAVE_EVENT_ADDONS_REQUESTED, requestSaveEventAddonsSaga, companyId);
}
export function* requestRemoveEventAddonsWatcher(companyId) {
  yield takeEvery(eventsActions.REMOVE_EVENT_ADDONS_REQUESTED,
    requestRemoveEventAddonsSaga, companyId);
}
export function* updateEventWatcher(companyId) {
  yield takeEvery(eventsActions.UPDATE_EVENT_REQUESTED, updateEventSaga, companyId);
}
export function* createEventsWatcher(companyId) {
  yield takeEvery(
    eventsActions.CREATE_EVENT_REQUESTED,
    createEventSaga, companyId,
  );
}
export function* assignEventAgentWatcher(companyId) {
  yield takeEvery(eventsActions.ASSIGN_EVENT_AGENT,
    assignEventAgentSaga, companyId);
}

export function* removeEventAgentWatcher(companyId) {
  yield takeEvery(eventsActions.REMOVE_EVENT_AGENT,
    removeEventAgentSaga, companyId);
}

export function* updateEventTagsWatcher(companyId) {
  yield takeEvery(tagsActions.UPDATE_EVENT_TAGS,
    updateEventTagsSaga, companyId);
}

export function* deleteEventWatcher(companyId) {
  yield takeEvery(eventsActions.DELETE_EVENT_REQUESTED,
    deleteEventSaga, companyId);
}
