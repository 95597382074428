import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const mapTabs = (tabsToNavigate) => tabsToNavigate.map((tabToNavigate) => (
  <Tab onClick={() => (tabToNavigate.onClick ? tabToNavigate.onClick() : console.log())} label={tabToNavigate.name} variant="none" />
));

class TabbedNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
    };
  }

  render() {
    const { tabsToNavigate } = this.props;
    const { currentTab } = this.state;

    return (
      <div className="tabbed-navigation-tabs-wrapper">
        <Tabs
          value={currentTab}
          className="tabbed-navigation-tabs"
          onChange={(event, newTab) => this.setState({ currentTab: newTab })}
        >
          {mapTabs(tabsToNavigate)}
        </Tabs>
        <div className="tabbed-navigation-tabs-body">
          {tabsToNavigate[currentTab].body}
        </div>
      </div>
    );
  }
}

TabbedNavigation.propTypes = {
  tabsToNavigate: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    body: PropTypes.shape().isRequired,
  })).isRequired,
};

export default TabbedNavigation;
