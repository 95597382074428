import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import { Alert } from '@material-ui/lab';
import LoadingSkeletonBarChart from '../../../../components/LoadingComponents/LoadingSkeletonBarChart';
import FailedSection from '../../../../components/LoadingComponents/FailedSection';
import { CategoryChartBaseBarHorizontal } from './bases/CategoryChartBaseBarHorizontal';
import { CategoryHeaderBase } from './bases/CategoryTitleBase';

function CategoryRevenueByEvent(props) {
  const {
    analyticsEventBookings,
    analyticsEvents,
    analyticsEventTimeframe,
    analyticsEventTimeframeCustom,
    currencySym,
    analyticsEventLoading,
    analyticsEventFailed,
  } = props;

  const { bookings, eventIDsArray } = analyticsEventBookings;
  const { formattedEvents } = analyticsEvents;

  // Filter out unselected events
  const selectedEvents = formattedEvents.filter((event) => eventIDsArray.includes(event.id));

  const ids = selectedEvents.map((event) => event.id);

  const zip = {};
  for (let j = 0; j < ids.length; j += 1) {
    zip[ids[j]] = 0;
  }

  for (let i = 0; i < bookings.length; i += 1) {
    zip[selectedEvents.find((event) => event.id === bookings[i].eventId)?.id]
     += bookings[i].price;
  }

  const items = Object
    .keys(zip).map((key) => [selectedEvents.find((event) => event.id === key)?.name, zip[key]]);
  items.sort((first, second) => second[1] - first[1]);

  const yAxisRevenueByEvent = items.map((item) => item[0]);
  const xAxisRevenueByEvent = items.map((item) => item[1]);

  const [currentPage, setCurrentPage] = useState(0);
  const [slicedXAxisData, setXAxisData] = useState([]);
  const [slicedYAxisData, setYAxisData] = useState([]);
  const rowsPerPage = 20;

  let noData = false;

  if (xAxisRevenueByEvent?.length === 0 || yAxisRevenueByEvent?.length === 0) {
    noData = true;
  }

  useEffect(() => {
    let startIndex = currentPage * rowsPerPage;
    let endIndex = startIndex + rowsPerPage;

    if (endIndex > xAxisRevenueByEvent.length && startIndex !== 0) {
      endIndex = xAxisRevenueByEvent.length;
      startIndex = endIndex - rowsPerPage;
    }

    if (yAxisRevenueByEvent[0] !== undefined) {
      setXAxisData(xAxisRevenueByEvent.slice(startIndex, endIndex));
      setYAxisData(yAxisRevenueByEvent.slice(startIndex, endIndex));
    }
  }, [currentPage, analyticsEvents, analyticsEventBookings]);

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if ((currentPage + 1) * rowsPerPage < xAxisRevenueByEvent.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="category" id="RevenueByEvent" style={{ width: '100%', height: '100vh' }}>
      <CategoryHeaderBase categoryName="Revenue By Event" />
      <AlternativelyVisible condition={noData}>
        <></>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePreviousPage}
            disabled={currentPage === 0}
            style={{ margin: '0px 20px' }}
          >
            {String.fromCharCode(8592)}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNextPage}
            disabled={(currentPage + 1) * rowsPerPage >= xAxisRevenueByEvent.length}
            style={{ margin: '0px 20px' }}
          >
            {String.fromCharCode(8594)}
          </Button>
        </div>
      </AlternativelyVisible>
      <AlternativelyVisible condition={analyticsEventLoading}>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: '1',
        }}
        >
          <LoadingSkeletonBarChart />
        </div>
        <AlternativelyVisible condition={analyticsEventFailed}>
          <FailedSection />
          <AlternativelyVisible condition={noData}>
            <div style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: '1',
            }}
            >
              <Alert severity="warning">
                No Active Events
              </Alert>
            </div>
            <CategoryChartBaseBarHorizontal
              backgroundColor="#90EE9030"
              bookings={bookings}
              borderColor="#00000020"
              categoryName="RevenueByEvent"
              analyticsEventTimeframe={analyticsEventTimeframe}
              analyticsEventTimeframeCustom={analyticsEventTimeframeCustom}
              xAxis={slicedXAxisData}
              xAxisLabel={`Revenue in ${currencySym}`}
              yAxis={slicedYAxisData}
            />
          </AlternativelyVisible>
        </AlternativelyVisible>
      </AlternativelyVisible>
    </div>
  );
}

CategoryRevenueByEvent.propTypes = {
  analyticsEventBookings: PropTypes.shape().isRequired,
  analyticsEvents: PropTypes.shape().isRequired,
  analyticsEventTimeframe: PropTypes.shape().isRequired,
  analyticsEventTimeframeCustom: PropTypes.shape().isRequired,
  currencySym: PropTypes.string.isRequired,
  analyticsEventLoading: PropTypes.bool.isRequired,
  analyticsEventFailed: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    analyticsEventBookings: state.analytics.analyticsEventBookings,
    analyticsEvents: state.analytics.analyticsEvents,
    analyticsEventTimeframe: state.analytics.analyticsEventTimeframe,
    analyticsEventTimeframeCustom: state.analytics.analyticsEventTimeframeCustom,
    currencySym: state.companies.companyInfo.currencySym,
    analyticsEventLoading: state.analytics.analyticsEventLoading,
    analyticsEventFailed: state.analytics.analyticsEventFailed,
  };
}

export default connect(mapStateToProps)(CategoryRevenueByEvent);
