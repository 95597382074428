import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TabbedNavigation from '../../shared-components/TabbedNavigation';
import PreviousBookerUserSearch from '../users/components/PreviousBookerUserSearch';
import NewBookerUserCreation from '../users/components/NewBookerUserCreation';

const UserSelection = ({ userSelected }) => {
  const tabs = [];
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const isMobile = window.innerWidth < 768;
  const handleUserSelection = (user) => {
    setSelectedUser(user);
  };

  const handleClose = () => {
    setOpen(false);
    userSelected(selectedUser);
    setSelectedUser({});
  };

  const handleUserPropertyUpdate = (property) => {
    setSelectedUser({
      ...selectedUser,
      ...property,
    });
  };

  tabs.push({
    id: 0,
    name: 'Existing Customer',
    body: (<PreviousBookerUserSearch
      updateUserDetails={(updatedUserDetails) => handleUserSelection(updatedUserDetails)}
      user={selectedUser}
    />),
  });

  tabs.push({
    id: 1,
    name: 'New Customer',
    body: (<NewBookerUserCreation
      updateUserDetails={(updatedUserDetails) => handleUserPropertyUpdate(updatedUserDetails)}
      user={selectedUser}
    />),
  });

  return (
    <>
      <Button variant="contained" color="secondary" style={{ width: '100%', margin: 0 }} onClick={() => setOpen(true)}>Select Customer</Button>
      <Dialog
        maxWidth="lg"
        fullWidth
        style={{
          minHeight: 300,
          maxWidth: 800,
          margin: 'auto',
        }}
        open={open}
        onRequestClose={() => setOpen(false)}
      >
        <DialogContent>
          <div style={{ margin: '20px' }}>
            <div className="reservation-creation-user-selecton">
              <TabbedNavigation tabsToNavigate={tabs} />
              <div className="tabbed-navigation-tabs-wrapper" />
              <div style={{
                display: 'flex', flexDirection: 'row-reverse', marginTop: '20px', gap: 5,
              }}
              >
                <Button
                  disabled={
                  !selectedUser?.email
                   && !selectedUser?.firstName
                   && !selectedUser?.lastName
}
                  variant="contained"
                  color="primary"
                  onClick={() => handleClose()}
                >
                  Select Customer
                </Button>
                <Button style={{ float: 'right' }} variant="contained" color="secondary" onClick={() => setOpen(false)}>Cancel</Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

UserSelection.propTypes = {
  userSelected: PropTypes.func.isRequired,
};

export default UserSelection;
