/* eslint-disable no-constant-condition */
import { put } from 'redux-saga/effects';
import http from 'http-status-codes';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import * as actions from '../reducers/usersReducer';

export function* handleSagaError(error, sagaName) {
  if (error.status === http.UNAUTHORIZED) {
    yield put(actions.updateCurrentUser({}));
  } else if (error.status === http.FORBIDDEN) {
    yield put(sessionActions.updateErrorSnackbar('Your access to the features on this tab have been restricted. In some cases, you may only be able to view data because the edit function has been disabled.', true));
  } else if (error.status === http.INTERNAL_SERVER_ERROR) {
    yield put(sessionActions.updateErrorSnackbar('Internal Server Error.', true));
  } else if (error.status === http.GATEWAY_TIMEOUT || error.status === http.REQUEST_TIMEOUT) {
    yield put(sessionActions.updateErrorSnackbar('Timeout. Contact info@pitchbooking.com', true));
  }
  console.log(`${sagaName} error`, error);
}
