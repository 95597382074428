import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
  Select,
} from '@material-ui/core';
import { useFormik } from 'formik';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import TableContainer from '@material-ui/core/TableContainer';
import MaterialTable from 'material-table';
import MenuHeading from '../../components/MenuHeading';
import * as actions from '../../reducers/addonsReducer';

const styles = () => ({
  dialog: {
    width: '820px',
  },
  menuHeading: {
    marginTop: 40,
  },
  textField: {
    width: '100%',
    marginLeft: 15,
  },
  fullWidth: {
    width: '100%',
  },
});

const StockTransactionDialog = ({
  classes, createStockTransaction, addOn, companySites,
}) => {
  const [open, setOpen] = React.useState(false);
  const [modalFocus, setModalFocus] = React.useState('NEW');
  const formik = useFormik({
    initialValues: {
      quantity: '',
      transactionType: 'ADD',
      siteId: null,
      note: '',
    },
    validationSchema: Yup.object({
      quantity: Yup.number().required('Quantity is required').min(1, 'Quantity must be more than 0'),
      transactionType: Yup.string().required('Transaction type is required'),
      siteId: Yup.string().when('transactionType', {
        is: 'TRANSFER',
        then: Yup.string().required('Site is required'),
        otherwise: Yup.string().nullable(),
      }),
    }),
    onSubmit: (values) => {
      const dataValues = {
        ...values,
        addonId: addOn.id,
        parentAddonId: addOn.parentAddOnId,
      };
      if (values.transactionType !== 'TRANSFER') {
        delete dataValues.siteId;
      }
      createStockTransaction(dataValues);
      setOpen(false);
      formik.resetForm();
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
    setModalFocus('NEW');
    formik.resetForm();
  };

  return (
    <div>
      <Button onClick={handleClickOpen} variant="contained" color="primary">
        Manage Stock
      </Button>
      <Dialog
        maxWidth="820px"
        open={open}
        onClose={handleRequestClose}
      >
        <DialogTitle>Stock Transactions</DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText>
            A stock transaction is used to record the movement of stock in and out of the system.
          </DialogContentText>
          <Select
            native
            value={modalFocus}
            onChange={(e) => setModalFocus(e.target.value)}
          >
            <option value="NEW">New Stock Transaction</option>
            <option value="VIEW">View Stock History</option>
          </Select>
          {modalFocus === 'NEW' && (
            <>
              <h3>
                Record a stock transaction for
                {' '}
                {addOn.site ? `${addOn.site?.name} - ` : ''}
                {addOn.name}
              </h3>
              <form onSubmit={formik.handleSubmit}>
                <MenuHeading title="Transaction Type" />
                <Select
                  native
                  value={formik.values.transactionType}
                  onChange={formik.handleChange}
                  inputProps={{
                    id: 'transactionType',
                  }}
                >
                  <option value="ADD">Add Stock</option>
                  <option value="REMOVE">Remove Stock</option>
                  <option value="TRANSFER">Transfer Stock To Another Site</option>
                </Select>
                {formik.values.transactionType !== 'TRANSFER' && (
                <div style={{ display: 'flex', gap: '15px' }}>
                  <TextField
                    id="quantity"
                    label="Quantity"
                    type="number"
                    fullWidth
                    value={formik.values.quantity}
                    onChange={formik.handleChange}
                    error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                    helperText={formik.touched.quantity && formik.errors.quantity}
                  />
                  <TextField
                    id="note"
                    label="Note"
                    type="string"
                    fullWidth
                    value={formik.values.note}
                    onChange={formik.handleChange}
                    error={formik.touched.note && Boolean(formik.errors.note)}
                    helperText={formik.touched.note && formik.errors.note}
                  />
                </div>
                )}
                {formik.values.transactionType === 'TRANSFER' && (
                <>
                  <div style={{ display: 'flex', gap: '15px' }}>
                    <TextField
                      id="quantity"
                      label="Quantity"
                      type="number"
                      fullWidth
                      value={formik.values.quantity}
                      onChange={formik.handleChange}
                      error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                      helperText={formik.touched.quantity && formik.errors.quantity}
                    />
                    <Select
                      native
                      value={formik.values.siteId}
                      onChange={formik.handleChange}
                      inputProps={{
                        id: 'siteId',
                      }}
                    >
                      <option value={null}>Select Site</option>
                      {companySites.map((site) => (
                        <>
                          {site.id !== addOn.siteId && (
                          <option value={site.id}>{site.name}</option>

                          ) }
                        </>
                      ))}
                    </Select>
                    <br />
                  </div>
                  <div style={{ display: 'flex', gap: '15px' }}>
                    <TextField
                      id="note"
                      label="Note"
                      type="string"
                      fullWidth
                      value={formik.values.note}
                      onChange={formik.handleChange}
                      error={formik.touched.note && Boolean(formik.errors.note)}
                      helperText={formik.touched.note && formik.errors.note}
                    />
                  </div>
                </>
                )}
                <DialogActions>
                  <Button onClick={handleRequestClose} variant="outlined" color="secondary">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Create
                  </Button>
                </DialogActions>
              </form>
            </>
          )}
          {modalFocus === 'VIEW' && (
            <>
              <MaterialTable
                data={addOn.stockTransactions}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
                title="Pitchbooking Booking Modifiers"
                icons={{
                  NextPage: () => <ChevronRight />,
                  PreviousPage: () => <ChevronLeft />,
                  Search: () => <Search />,
                  Export: () => <SaveAlt />,
                  ResetSearch: () => <Clear />,
                  SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
                }}
                columns={[
                  {
                    title: 'Created At',
                    filtering: false,
                    render: (row) => (
                      <div>
                        {moment(row.createdAt).format('DD/MM/YYYY HH:mm')}
                      </div>
                    ),
                  },
                  {
                    title: 'Transaction Type',
                    field: 'transactionType',
                  },
                  {
                    title: 'Quantity',
                    field: 'quantity',
                  },
                  {
                    title: 'Note',
                    field: 'note',
                  },
                  {
                    title: 'Was a transfer',
                    field: 'isTransfer',
                    render: (row) => (
                      <div>
                        {row.isTransfer ? 'Yes' : 'No'}
                      </div>
                    ),
                  },
                ]}
                options={{
                  search: false,
                  selection: false,
                  emptyRowsWhenPaging: false,
                  tableLayout: 'auto',
                  exportButton: false,
                  exportFileName: 'Pitchbooking Products',
                  pageSize: 50,
                  pageSizeOptions: [10, 25, 50, 100],
                  showFirstLastPageButtons: false,
                  showTitle: false,
                  headerStyle: {
                    backgroundColor: '#efefef',
                    fontWeight: 'bold',
                  },
                }}
                localization={{
                  toolbar: {
                    exportTitle: 'Download',
                  },
                }}
              />
              <br />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={handleRequestClose} variant="outlined" color="secondary">
                  Close
                </Button>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

StockTransactionDialog.propTypes = {
  classes: PropTypes.shape().isRequired,
  createStockTransaction: PropTypes.func.isRequired,
  addOn: PropTypes.shape().isRequired,
  companySites: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = (state) => ({
  companySites: state.sites.companySites,
});

const mapDispatchToProps = (dispatch) => ({
  createStockTransaction: (reqBody) => dispatch(actions.createStockTransaction(reqBody)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withStyles(styles)(StockTransactionDialog));
