import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  divider: {
    alignSelf: 'stretch',
    height: 'auto',
    margin: theme.spacing(1, 0.5),
  },
  dayButton: {
    width: '50px',
    height: '50px',
    border: 'solid 1px #4581e2 !important',
    borderRadius: '25px !important',
  },
  dayButtonSlim: {
    width: '30px',
    height: '30px',
    border: 'solid 1px #4581e2 !important',
    borderRadius: '25px !important',
  },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(2),
    border: 'none',
    padding: theme.spacing(0, 1),
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

const days = [
  { value: 1, label: 'Monday', shortLabel: 'M' },
  { value: 2, label: 'Tuesday', shortLabel: 'T' },
  { value: 3, label: 'Wednesday', shortLabel: 'W' },
  { value: 4, label: 'Thursday', shortLabel: 'T' },
  { value: 5, label: 'Friday', shortLabel: 'F' },
  { value: 6, label: 'Saturday', shortLabel: 'S' },
  { value: 0, label: 'Sunday', shortLabel: 'S' },
];

function DayPicker({
  onUpdate,
  choosenWeekday,
  slim,
  disabled,
  isMultiple,
}) {
  const classes = useStyles();
  const buttonStyle = slim ? classes.dayButtonSlim : classes.dayButton;
  const [daysArray, setDaysArray] = useState([1]);

  const handleAlignment = (event, newAlignment) => {
    if (isMultiple) {
      if (daysArray.includes(newAlignment)) {
        const newArray = daysArray.filter((day) => day !== newAlignment);
        setDaysArray(newArray);
        onUpdate({ days: newArray });
      } else {
        const newArray = [...daysArray, newAlignment];
        setDaysArray(newArray);
        onUpdate({ days: newArray });
      }
    } else {
      onUpdate({ weekday: newAlignment, day: newAlignment, days: newAlignment });
    }
  };

  useEffect(() => {
    if (choosenWeekday && choosenWeekday.length > 1) {
      setDaysArray(choosenWeekday);
    }
  }, []);

  return (
    <div>
      <Paper elevation={0} className={classes.paper}>
        <StyledToggleButtonGroup
          size="small"
          value={isMultiple ? daysArray : Number(choosenWeekday)}
          exclusive
          onChange={handleAlignment}
          aria-label="recurrence"
          disabled={disabled}
        >
          {days.map((day) => (
            <ToggleButton
              key={day.value}
              disabled={disabled}
              className={buttonStyle}
              value={day.value}
              aria-label={day.label}
            >
              {day.shortLabel}
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
}

DayPicker.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  choosenWeekday: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  slim: PropTypes.bool.isRequired,
  isMultiple: PropTypes.bool,
};

DayPicker.defaultProps = {
  isMultiple: false,
};

export default (DayPicker);
