export const omitFormProperties = (formData) => {
  if (!formData) {
    console.error('formData is required');
    return null;
  }

  try {
    // These fields are for PB internal use only and should not be displayed to the user
    const fieldsToRemove = ['formId', 'formName', 'updateFormData', 'saveFormData', 'userFormId'];
    const filteredData = { ...formData };

    fieldsToRemove.forEach((field) => {
      if (field in filteredData) {
        delete filteredData[field];
      }
    });

    return filteredData;
  } catch (error) {
    console.error(`Error filtering form data: ${error.message}`);
    return null;
  }
};
