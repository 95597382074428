/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import Switch from '@material-ui/core/Switch';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import Select from 'react-select';
import * as facilitiesActions from '../../../../reducers/facilitiesReducer';
import AllowedStartingQuartersPicker from './AllowedStartingQuartersPicker';
import DayPicker from '../../../accessRestrictions/components/DayPicker';
import SimpleSelect from '../../../../components/SimpleSelect';

const minDurationTooltipString = 'Set the minimum duration of a bookable time slot in minutes.';
const maxBookingsPerDay = 'Set the maximum number of bookings that can be made per day.';
const minHoursToBookingTooltipString = 'Set a time limit upon where the last possible booking can be made prior to the booking taking place. A 24 hour value here would mean you cannot make a booking on that facility up to 24 hours prior to the slot you are booking. i.e. 6pm Friday is the cut off point for a 6pm Saturday booking.';
const maxDaysToBookingTooltipString = 'Set a limit on how far in advance customers can book. For example, if you accept bookings 6 months in advance, set the maximum days available for booking to 182 days.';
const allowedStartQuartersTooltipString = 'Select when past the hour a booking can begin. As a guide, bookings that start on the hour should be set at 0. Bookings that start on the half hour should be set at 30. Bookings that start at 15/45 minutes past each our should be set at 15 and 45 respectively.';
const restrictedStartTimesTooltipString = 'Use this to override the \'Booking Slot Start Times\' then add the individual start times to this section. You will need to add each individual start-time throughout the day. To revert to the \'Booking Slot Start Times\' then clear all the \'Restricted start times\' below.';
const combineSlotsTooltipString = 'Combining slots will join the selected slots together to form one reservation. For example, if a customer books 10am to 11am and then 11am to 12pm, if you choose to combine slots, the reservation will be for 10am to 12pm. If you do not combine slots then this would create 2 separate bookings.';
const weekendCutOffTooltipString = 'This is a set day and time during the week where once it is passed, all slots for the upcoming weekend are closed off, and overrides the value in  Minimum Hours to Booking. For example, if you set the weekend cutoff to Thursday at 12pm, no bookings can be placed for that weekend. If you frequently have a high volume of bookings at the weekend, set a Weekend Cutoff to allow your grounds maintenance team time to prepare.';

const TimingConfiguration = ({
  facilities,
  updateSelectedFacilityStore,
}) => {
  const { selectedFacility } = facilities;
  const {
    minDuration,
    minHoursToBooking,
    allowedStartQuarters,
    maxDaysToBooking,
    combineSlots,
    weekendCutoff,
    weekday,
    weekendCutoffTime,
    definedStartTimes,
    newTime,
    weekendCutoffFlag,
    maxBookingsPerDay,
    allowedDurations,
  } = selectedFacility;

  let durationOptions = [30, 60, 90, 120];
  if (allowedDurations) {
    durationOptions = [...new Set([...durationOptions, ...allowedDurations])];
  }

  let newRestrictedStartTimes = [];
  if (definedStartTimes) {
    newRestrictedStartTimes = definedStartTimes;
  }

  let weekendCutoffMessage = true;

  if (weekendCutoff) {
    weekendCutoffMessage = false;
  }
  if (weekendCutoffTime) {
    weekendCutoffMessage = false;
  }

  const addRestrictedStartTime = (newTime1) => {
    const formattedNewTime = moment(newTime1).startOf('minute').format('HH:mm');

    if (newTime1 && formattedNewTime.length === 5) {
      newRestrictedStartTimes.push(formattedNewTime);
      updateSelectedFacilityStore({ definedStartTimes: newRestrictedStartTimes });
    }
  };
  const removeRestrictedStartTime = (newTime1) => {
    const index = definedStartTimes.indexOf(newTime1);
    if (index > -1) {
      definedStartTimes.splice(index, 1);
      updateSelectedFacilityStore({ definedStartTimes });
    }
  };

  const minimumBookingDuration = [
    15, 20, 30, 40, 45, 50, 60, 75, 90, 120, 135, 150, 180, 240, 300, 360, 420, 480, 540, 600,
  ];

  const handleWeekendCutOffChange = (event) => {
    if (event.target.checked) {
      updateSelectedFacilityStore({
        weekendCutoffFlag: event.target.checked,
        weekday: '4',
        weekendCutoffTime: '2020-10-08T12:00',
      });
    } else {
      updateSelectedFacilityStore({
        weekendCutoffFlag: event.target.checked,
        weekday: null,
        weekendCutoffTime: null,
        weekendCutoff: null,
      });
    }
  };

  const handleAllowedDurationsChange = (selectedDurations) => {
    const newDurations = selectedDurations.map((duration) => duration.value);
    updateSelectedFacilityStore({ allowedDurations: newDurations });
  };

  return (
    <>
      <>
        <div className="facility-wrapper">
          <div className="facility-details-section-1">
            <Typography variant="h6">Facility timing configuration</Typography>
            <div style={{ marginBottom: '0.5rem' }}>
              <div style={{ display: 'flex' }}>
                <Typography variant="caption">Minimum Booking Duration</Typography>
                <Tooltip title={minDurationTooltipString} placement="right">
                  <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '20px' }} />
                </Tooltip>
              </div>
              <SimpleSelect
                arrayValue={minimumBookingDuration}
                propValue={minDuration}
                onChange={(value) => updateSelectedFacilityStore({ minDuration: value })}
              />
            </div>

            <div style={{ marginBottom: '0.5rem' }}>
              <div style={{ display: 'flex' }}>
                <Typography variant="caption">Max Bookings Per Day</Typography>
                <Tooltip title={maxBookingsPerDay} placement="right">
                  <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '20px' }} />
                </Tooltip>
              </div>
              <TextField
                id="name"
                value={maxBookingsPerDay}
                margin="normal"
                variant="outlined"
                fullWidth
                type="number"
                onChange={(event) => updateSelectedFacilityStore(
                  { maxBookingsPerDay: event.target.value || null },
                )}
                style={{
                  marginTop: 0,
                }}
              />
            </div>

            <div style={{ marginBottom: '0.5rem' }}>
              <div style={{ display: 'flex' }}>
                <Typography variant="caption">Minimum hours to booking</Typography>
                <Tooltip title={minHoursToBookingTooltipString} placement="right">
                  <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '20px' }} />
                </Tooltip>
              </div>
              <TextField
                id="name"
                value={minHoursToBooking}
                margin="normal"
                variant="outlined"
                fullWidth
                type="number"
                onChange={(event) => updateSelectedFacilityStore(
                  { minHoursToBooking: event.target.value },
                )}
                style={{
                  marginTop: 0,
                }}
              />
            </div>

            <div style={{ marginBottom: '0.5rem' }}>
              <div style={{ display: 'flex' }}>
                <Typography variant="caption">Maximum days to booking</Typography>
                <Tooltip title={maxDaysToBookingTooltipString} placement="right">
                  <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '20px' }} />
                </Tooltip>
              </div>
              <TextField
                id="name"
                value={maxDaysToBooking}
                margin="normal"
                variant="outlined"
                fullWidth
                type="number"
                onChange={(event) => updateSelectedFacilityStore(
                  { maxDaysToBooking: event.target.value },
                )}
                style={{
                  marginTop: 0,
                }}
              />
            </div>

            <div style={{ display: 'flex', width: '100%' }}>
              <Typography variant="caption">Booking Slot Start Time</Typography>
              <Tooltip title={allowedStartQuartersTooltipString} placement="right">
                <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '20px' }} />
              </Tooltip>
            </div>
            <AllowedStartingQuartersPicker
              defaultValue={allowedStartQuarters}
              updateSelectedFacilityStore={updateSelectedFacilityStore}
              selectedFacility={selectedFacility}
            />
          </div>
          <div className="facility-details-section-2">
            <div className="facility-details">
              <div style={{ display: 'flex', width: '100%' }}>
                <Typography variant="caption">Restricted Start Times</Typography>
                <Tooltip title={restrictedStartTimesTooltipString} placement="right">
                  <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '20px' }} />
                </Tooltip>
              </div>
              <div style={{ margin: '10px' }}>
                <TimePicker
                  value={newTime ? moment(newTime) : newTime}
                  showSecond={false}
                  minuteStep={5}
                  clearIcon="x"
                  allowEmpty
                  onChange={(value) => updateSelectedFacilityStore(
                    { newTime: moment(value).startOf('minute').format() },
                  )}
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginLeft: '10px', marginBottom: '10px',
                }}
                onClick={() => addRestrictedStartTime(newTime)}
              >
                +
              </Button>
              <div style={{
                display: 'flex', flexWrap: 'wrap', width: '100%', marginBottom: '15px', padding: '10px',
              }}
              >
                {definedStartTimes ? definedStartTimes.map((restrictedStartTime) => (
                  <div style={{
                    display: 'flex',
                    width: '100%',
                    marginBottom: '5px',
                    backgroundColor: '#eee',
                    borderRadius: '5px',
                    padding: '5px',
                  }}
                  >
                    <div style={{ paddingTop: '6px', paddingLeft: '10px', width: '210px' }}>
                      {restrictedStartTime.day || restrictedStartTime.day === 0 ? moment().day(restrictedStartTime.day).format('dddd') : ''}
                      {' '}
                      {restrictedStartTime.time ? restrictedStartTime.time : restrictedStartTime}
                    </div>
                    <ConditionallyVisible condition={!restrictedStartTime.time}>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: '#F9C0C0' }}
                        onClick={() => removeRestrictedStartTime(restrictedStartTime)}
                      >
                        -
                      </Button>
                    </ConditionallyVisible>
                  </div>
                )) : (<p>No restricted start time found</p>)}
              </div>
              <div style={{
                display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '15px',
              }}
              >
                <div>
                  <Typography variant="caption">Combine slots</Typography>
                  <Tooltip title={combineSlotsTooltipString} placement="right">
                    <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '20px' }} />
                  </Tooltip>
                </div>
                <div>
                  <Switch
                    checked={combineSlots}
                    onChange={(event) => updateSelectedFacilityStore({
                      combineSlots: event.target.checked,
                    })}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', width: '100%', paddingTop: '6px' }}>
                  <Typography variant="caption">Weekend cut off</Typography>
                  <Tooltip title={weekendCutOffTooltipString} placement="right">
                    <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '20px' }} />
                  </Tooltip>
                </div>
                <Switch
                  checked={weekendCutoffFlag}
                  onChange={(event) => handleWeekendCutOffChange(event)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
              {weekendCutoffMessage && <Typography variant="caption">No weekend cut off currently set, pick a day and time below to set this up.</Typography>}
              {weekendCutoffFlag ? (
                <>
                  <DayPicker
                    choosenWeekday={weekday}
                    onUpdate={updateSelectedFacilityStore}
                    slim
                    disabled={!weekendCutoffFlag}

                  />
                  <TimePicker
                    disabled={!weekendCutoffFlag}
                    style={{ width: '200px' }}
                    value={weekendCutoffTime ? moment(weekendCutoffTime) : weekendCutoffTime}
                    showSecond={false}
                    minuteStep={15}
                    clearIcon={null}
                    allowEmpty
                    onChange={(value) => updateSelectedFacilityStore({ weekendCutoffTime: moment(value).startOf('minute').format() })}
                  />
                </>
              ) : <> </>}
            </div>

            {/* Allowed durations */}
            <div>
              <Typography variant="body1">Set Allowed Durations</Typography>
              <Select
                defaultValue={allowedDurations ? allowedDurations.map((duration) => ({ value: duration, label: `${duration} minutes` })) : []}
                isMulti
                name="allowedDurations"
                de
                options={durationOptions.map((duration) => ({ value: duration, label: `${duration} minutes` }))}
                onChange={(value) => handleAllowedDurationsChange(value)}
              />
            </div>

          </div>
        </div>
      </>
    </>
  );
};

TimingConfiguration.propTypes = {
  facilities: PropTypes.object.isRequired,
  updateSelectedFacilityStore: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  facilities: state.facilities,
});

export default connect(mapStateToProps, facilitiesActions)(TimingConfiguration);
