// @flow weak

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import InvoiceStepperWrapper from './Stepper/InvoiceStepperWrapper';
import * as actions from '../../reducers/invoicesReducer';

const InvoiceCreationDialog = ({
  createInvoiceDialogOpen,
  toggleInvoiceCreationPopup,
}) => (
  <div>
    <Button
      id="manager-bookings-create-invoice"
      variant="contained"
      color="primary"
      style={{ width: 200 }}
      onClick={() => toggleInvoiceCreationPopup()}
    >
      New Invoice / Payment Link
    </Button>
    <Dialog
      className="reservation-creation-modal-wrapper"
      open={createInvoiceDialogOpen}
      onRequestClose={() => toggleInvoiceCreationPopup()}
      fullWidth
      fullScreen
    >
      <DialogContent style={{ width: 'flex' }}>
        <InvoiceStepperWrapper handleClose={() => toggleInvoiceCreationPopup()} />
      </DialogContent>
    </Dialog>
  </div>
);

InvoiceCreationDialog.propTypes = {
  createInvoiceDialogOpen: PropTypes.bool.isRequired,
  toggleInvoiceCreationPopup: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  createInvoiceDialogOpen: state.invoices.createInvoiceDialogOpen,
});

const mapDispatchToProps = (dispatch) => ({
  toggleInvoiceCreationPopup: () => dispatch(
    actions.toggleInvoiceCreationPopup(),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceCreationDialog);
