/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/dashboard.css';
import BookingsCard from './bookingsCard';
import SubscriptionsCard from './subscriptionsCard';

const LiveBookingsAndSubs = ({
  booking,
}) => {
  const {
    type,
  } = booking;

  let LiveCard = <></>;
  switch (type) {
    case 'RESERVATION':
      LiveCard = <BookingsCard booking={booking} />;
      break;
    case 'SUBSCRIPTION':
      LiveCard = <SubscriptionsCard booking={booking} />;
      break;
    default:
      LiveCard = <></>;
      break;
  }

  return LiveCard;
};

LiveBookingsAndSubs.propTypes = {
  booking: PropTypes.shape().isRequired,
};

export default (LiveBookingsAndSubs);
