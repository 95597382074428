import React from 'react';
import { Button } from '@material-ui/core';
import moment from 'moment';
import JSON2CSV from 'json2csv';
import FileDownload from 'js-file-download';
import PropTypes from 'prop-types';

export default class DownloadCSVButton extends React.Component {
  downloadData = (fieldsAndData, filename) => {
    const { data, fields } = fieldsAndData;
    const csv = JSON2CSV({ data, fields, excelStrings: false });
    FileDownload(csv, filename);
  }

  render() {
    const { fieldsAndData, filename } = this.props;
    return (
      <Button
        id="manager-bookings-export-csv"
        variant="contained"
        color="secondary"
        onClick={() => this.downloadData(fieldsAndData, filename)}
      >
        Export CSV
      </Button>
    );
  }
}

DownloadCSVButton.propTypes = {
  fieldsAndData: PropTypes.shape().isRequired,
  filename: PropTypes.string,
};

DownloadCSVButton.defaultProps = {
  filename: `Pitchbooking Export (${moment().format('YYYY-MM-DD')}).csv`,
};
