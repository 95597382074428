/* eslint-disable camelcase */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Alert } from '@material-ui/lab';
import * as pricingActions from '../../reducers/pricingReducer';
import PricingAddRuleDialogSection from './PricingAddRuleDialogSection';
import ToggleButtons from '../../components/ToggleButtons';
import { createDateWithTime } from '../../helpers/index';

const pricePerButtonsData = [{
  buttonTitle: 'Reservation',
  buttonValue: 'RESERVATION',
},
{
  buttonTitle: 'Hour',
  buttonValue: 'HOUR',
}];

const priceTypeButtonsData = [{
  buttonTitle: 'Actual price',
  buttonValue: 'ACTUAL',
},
{
  buttonTitle: 'Fixed alteration',
  buttonValue: 'ABSOLUTE',
},
{
  buttonTitle: 'Percentage',
  buttonValue: 'PERCENTAGE',
}];

const EditPriceRuleDialog = (props) => {
  const dispatch = useDispatch();
  const { priceModifier } = useSelector((state) => state.pricing);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [alert, setAlert] = useState(false);

  const { getAllCompanyBookingModifiers, pM, parameter } = props;
  const { pricePer, priceType } = pM;

  useEffect(() => {
    if (parameter === 'ageGroupBookingModifierId' || parameter === 'eventBookingModifierId') {
      getAllCompanyBookingModifiers();
    }
  }, [props]);

  const updatePriceModifierStore = (keyWithValue) => {
    if (keyWithValue.type === 'PERCENTAGE' && keyWithValue.value <= 0 || keyWithValue.value > 100) {
      setAlert(true);
    } else {
      setAlert(false);
    }
    dispatch(
      pricingActions.updatePriceModifierStore(keyWithValue),
    );
  };
  const resetPriceGroupPriceModifierStore = () => dispatch(
    pricingActions.resetPriceGroupPriceModifierStore(),
  );

  const { value, subFacility_quantity } = priceModifier;

  const [displayedValue, setDisplayedValue] = useState(
    () => (subFacility_quantity && subFacility_quantity > 0
      ? value * subFacility_quantity
      : value),
  );

  useEffect(() => {
    if (subFacility_quantity && subFacility_quantity > 0) {
      setDisplayedValue(value * subFacility_quantity);
    } else {
      setDisplayedValue(value);
    }
  }, [value, subFacility_quantity]);
  // Handler to update value in Redux and local displayed value
  const handleValueChange = (event) => {
    const newValue = event.target.value;
    const originalValue = subFacility_quantity ? newValue / subFacility_quantity : newValue;
    dispatch(pricingActions.updatePriceModifierStore({ type: priceType, value: originalValue }));
    setDisplayedValue(newValue);
  };

  const handleOpen = (id) => {
    let newStartTime;
    if (pM.startTime) {
      newStartTime = moment(createDateWithTime(moment(), pM.startTime)).startOf('minute').format('YYYY-MM-DD HH:mm');
    }
    let newEndTime;
    if (pM.endTime) {
      newEndTime = moment(createDateWithTime(moment(), pM.endTime)).startOf('minute').format('YYYY-MM-DD HH:mm');
    }
    updatePriceModifierStore({ id });
    updatePriceModifierStore({ ...pM, startTime: newStartTime, endTime: newEndTime });

    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    resetPriceGroupPriceModifierStore();
  };

  const handlePriceRuleSave = () => {
    dispatch(pricingActions.requestPriceRuleSave());
    handleClose();
  };

  const handleToggle = (key, option) => {
    updatePriceModifierStore({ [key]: option });
  };

  let amountAdornment = '';
  if (priceType === 'PERCENTAGE') {
    amountAdornment = '%';
  } else if (pricePer === 'HOUR') {
    amountAdornment = '/ hour';
  } else if (pricePer === 'RESERVATION') {
    amountAdornment = '/ reservation';
  }

  const priceRules = [
    {
      title: 'Start Time',
      parameter: 'startTime',
      value: priceModifier.startTime,
      defaultValue: priceModifier.startTime,

    },
    {
      title: 'End Time',
      parameter: 'endTime',
      value: priceModifier.endTime,
      defaultValue: priceModifier.endTime,
    },
    {
      title: 'Weekday',
      parameter: 'day',
      value: priceModifier.day,
      defaultValue: pM.day,
    },
    {
      title: 'Fractional quantity',
      parameter: 'subFacility_quantity',
      value: priceModifier.subFacility_quantity,
      defaultValue: pM.subFacility_quantity,
    },
    {
      title: 'Quantity',
      parameter: 'quantity',
      value: priceModifier.quantity,
      defaultValue: pM.quantity,
    },
    {
      title: 'Duration',
      parameter: 'duration',
      value: priceModifier.duration,
      defaultValue: pM.duration,
    },
    {
      title: 'Age Group',
      parameter: 'ageGroupBookingModifierId',
      value: priceModifier.ageGroupBookingModifierId,
      defaultValue: pM.ageGroupBookingModifierId,
    },
    {
      title: 'Event',
      parameter: 'eventBookingModifierId',
      value: priceModifier.eventBookingModifierId,
      defaultValue: pM.eventBookingModifierId,
    },
    {
      title: 'Applies when booked as an amenity',
      parameter: 'bookedAsAmenity',
      value: priceModifier.bookedAsAmenity,
      defaultValue: pM.bookedAsAmenity,
    },
    {
      title: 'Floodlights',
      parameter: 'floodlights',
      value: priceModifier.floodlights,
      defaultValue: pM.floodlights,
    },
  ];

  return (
    <>
      <Button variant="contained" color="secondary" size="small" aria-label="edit" onClick={() => handleOpen(pM.id)}>
        EDIT
      </Button>
      <Dialog
        open={dialogOpen}
      >
        <DialogTitle>Edit selected price rule</DialogTitle>
        <DialogContent style={{ width: '600px' }}>

          {priceModifier.modifierType === 'DEFAULT' && (
            <div style={{ marginBottom: '1rem' }}>
              <Alert severity="warning">
                {'You are currently editing a '}
                <Typography variant="body" style={{ fontWeight: 'bold' }}>
                  Default Price Rule
                </Typography>
              </Alert>
            </div>
          )}

          {priceModifier.modifierType !== 'DEFAULT' && (
            priceRules.map((pr) => (
              <>
                <PricingAddRuleDialogSection
                  title={pr.title}
                  parameter={pr.parameter}
                  value={pr.value}
                  defaultValue={pr.defaultValue}
                  onUpdate={updatePriceModifierStore}
                />
                <Divider />
              </>
            ))
          )}

          {priceModifier.modifierType !== 'DEFAULT' && (
          <>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
            }}
            >

              <Typography variant="subtitle1" noWrap style={{ minWidth: 'max-content' }}>
                Price Type:
              </Typography>

              <div style={{ width: '20px' }} />
              <ToggleButtons
                buttonsData={priceTypeButtonsData}
                changeOption={(option) => handleToggle('priceType', option)}
                value={priceModifier.priceType}
                defaultValue={pM.priceType}
              />
            </div>
          </>
          )}

          <Divider />

          {priceModifier.priceType === 'PERCENTAGE' && alert && (
            <Alert severity="error" style={{ marginTop: '0.75rem' }}>
              Percentage cannot be less than 1% or more than 100%
            </Alert>
          )}

          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
          }}
          >
            <Typography variant="subtitle1" nowrap>
              Amount:
            </Typography>
            <div style={{ width: '20px' }} />
            <TextField
              value={displayedValue}
              margin="normal"
              variant="outlined"
              type="number"
              onChange={handleValueChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">{amountAdornment}</InputAdornment>,
              }}
            />
          </div>
          <Divider />
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
          }}
          >
            <Typography variant="subtitle1" noWrap>
              Price Per:
            </Typography>
            <div style={{ width: '20px' }} />
            <ToggleButtons
              buttonsData={pricePerButtonsData}
              changeOption={(option) => handleToggle('pricePer', option)}
              value={priceModifier.pricePer}
              defaultValue={pM.pricePer}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="secondary" variant="contained">
            Close
          </Button>
          <Button
            onClick={() => handlePriceRuleSave()}
            color="primary"
            variant="contained"
            disabled={alert}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

EditPriceRuleDialog.propTypes = {
  parameter: PropTypes.string.isRequired,
  getAllCompanyBookingModifiers: PropTypes.func.isRequired,
  pM: PropTypes.shape().isRequired,
};

export default EditPriceRuleDialog;
