import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AccessRestrictionsTable from './components/AccessRestrictionsTable';
import * as accessRestrictionActions from '../../reducers/accessRestrictionsReducer';
import * as facilitiesActions from '../../reducers/facilitiesReducer';
import * as companyActions from '../../reducers/companiesReducer';
import 'rc-time-picker/assets/index.css';

class AccessRestrictionsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ARDisplay: 'CURRENT',
    };
  }

  componentDidMount() {
    const {
      getAccessRestrictions,
      requestFacilitiesRetrieval,
      getCompanyInfo,
      match,
    } = this.props;
    const { ARDisplay } = this.state;
    let bool;
    if (ARDisplay === 'CURRENT') {
      bool = true;
    } else {
      bool = false;
    }
    if (match.url.includes('closures')) {
      this.setState({ ARtitle: 'Closure', getOptions: { showLiveOnly: bool, arType: 'HOLIDAY' } });
      getAccessRestrictions(bool, 'HOLIDAY');
    } else {
      this.setState({ ARtitle: 'Priority Access', getOptions: { showLiveOnly: bool, arType: 'MEMBER_ACCESS' } });
      getAccessRestrictions(bool, 'MEMBER_ACCESS');
    }
    requestFacilitiesRetrieval();
    getCompanyInfo();
  }

  updateCheck = (value) => {
    if (value !== null) {
      this.setState({
        ARDisplay: value,
      }, () => {
        const showLiveOnly = (value === 'CURRENT');
        if (value === 'CURRENT') {
          this.setState({ getOptions: { showLiveOnly: true, arType: 'HOLIDAY' } });
        } else {
          this.setState({ getOptions: { showLiveOnly: false, arType: 'HOLIDAY' } });
        }
        const { getAccessRestrictions, match } = this.props;
        if (match.url.includes('closures')) {
          getAccessRestrictions(showLiveOnly, 'HOLIDAY');
        } else {
          getAccessRestrictions(showLiveOnly, 'MEMBER_ACCESS');
        }
      });
    }
  }

  render() {
    const {
      facilities, accessRestrictions, companies,
    } = this.props;
    const { ARDisplay, ARtitle, getOptions } = this.state;
    return (
      <div>
        <div style={{ backgroundColor: '#fff' }} className="App" />
        <AccessRestrictionsTable
          facilities={facilities.companyFacilities}
          accessRestrictions={accessRestrictions.existingAccessRestrictions}
          companyMemberships={companies.memberships}
          ARDisplay={ARDisplay}
          ARtitle={ARtitle}
          updateCheck={this.updateCheck}
          isLoading={accessRestrictions.isLoading}
          getOptions={getOptions}
        />
      </div>
    );
  }
}

AccessRestrictionsPage.propTypes = {
  facilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getAccessRestrictions: PropTypes.func.isRequired,
  getCompanyInfo: PropTypes.func.isRequired,
  requestFacilitiesRetrieval: PropTypes.func.isRequired,
  accessRestrictions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  companies: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
};

function mapStateToProps(state) {
  const {
    facilities, companies, accessRestrictions, pricing,
  } = state;
  const { pricePackages } = pricing;
  return {
    facilities,
    companies,
    accessRestrictions,
    pricePackages,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getCompanyInfo: () => dispatch(companyActions.getCompanyInfo()),
  getAccessRestrictions: (showLiveOnly, ARtype) => dispatch(
    accessRestrictionActions.getAccessRestrictions(showLiveOnly, ARtype),
  ),
  requestFacilitiesRetrieval: () => dispatch(facilitiesActions.requestFacilitiesRetrieval()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessRestrictionsPage);
