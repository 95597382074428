import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import { getAddon, getCompanyAddons } from '../../reducers/addonsReducer';
import AddonTable from './AddonTable';
import EditPOSDialog from './components/EditPOSDialog';
import { requestSitesWithFacilitiesRetrieval } from '../../reducers/sitesReducer';

const AddonView = ({ match }) => {
  const dispatch = useDispatch();
  const addon = useSelector((state) => state.addons.companyAddons);
  const currency = useSelector((state) => state.companies.companyInfo.currency);

  const { id } = match.params;
  const addonPrice = addon?.priceModifiers?.filter((pm) => pm.modifierType === 'DEFAULT')[0]?.value;

  useEffect(() => {
    dispatch(getCompanyAddons());
    dispatch(getAddon(id));
    dispatch(requestSitesWithFacilitiesRetrieval());
  }, []);
  return (
    <div>
      <div style={{
        display: 'flex', flexDirection: 'column', gap: '0.5rem', backgroundColor: '#fff', padding: 20,
      }}
      >
        <div style={{ display: 'flex', gap: 15 }}>
          <Button variant="contained" color="secondary" onClick={() => dispatch(push('/products'))}>Back</Button>
          <Button variant="contained" color="secondary" onClick={() => dispatch(push(`/products/${id}/media`))}>Set Media</Button>
          <EditPOSDialog />
        </div>
        <h3>Product Details</h3>
        <div>
          <div>
            <strong>Name:</strong>
            {' '}
            {addon.name}
          </div>
          <div>
            <strong>Price:</strong>
            {' '}
            {getCurrencySymbol(currency)}
            {addonPrice?.toFixed(2)}
          </div>
          <div>
            <strong>Category:</strong>
            {' '}
            {addon.category?.name ? addon.category?.name : 'Uncategorised'}
          </div>
          <div>
            <strong>Description:</strong>
            {' '}
            {addon.description}
          </div>
        </div>
      </div>
      <AddonTable
        addons={addon?.children?.length > 0 ? addon.children : [addon]}
        detailView
        hideEdit
      />
    </div>
  );
};

AddonView.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default AddonView;
