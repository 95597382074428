import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function updateCompanyUserNoteService(companyId, userId, reqBody) {
  const url = `/api/companies/${companyId}/notes/${userId}`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

// Library Note Services
export async function getLibraryNotes(companyId) {
  const url = `/api/companies/${companyId}/library/notes`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function createLibraryNote(companyId, values) {
  const url = `/api/companies/${companyId}/library/createNote`;
  const response = await httpHelpers.authenticatedPostRequest(url, values);
  return response;
}

export async function updateLibraryNotes(companyId, values) {
  const url = `/api/companies/${companyId}/library/updateNote`;
  const response = await httpHelpers.authenticatedPutRequest(url, values);
  return response;
}
