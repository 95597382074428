import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';

class FacilityAndSubFacilityPickerList extends React.Component {
  constructor(props) {
    super(props);
    const { checkedFacilities } = this.props;
    this.state = {
      checkedFacilities: checkedFacilities || [],
    };
  }

  toggleItem = (facility) => {
    let { checkedFacilities } = this.state;
    const { updateFacilities } = this.props;
    const isChecked = checkedFacilities.some((checkedFac) => checkedFac.id === facility.id);

    if (isChecked) { // Remove facility
      checkedFacilities = checkedFacilities.filter((checkedFac) => checkedFac.id !== facility.id);
    } else { // Add facility
      checkedFacilities = [...checkedFacilities, facility];
    }

    this.setState({ checkedFacilities }, () => updateFacilities(checkedFacilities));
  }

  render() {
    const { toggleItem, state } = this;
    const { checkedFacilities } = state;
    const { facilities, showSubFacilities } = this.props;

    return (
      <List className="FAS-root" subheader={<li />}>
        {facilities.map((facility) => (
          <li key={`section-${facility.name}-${facility.id}`} className="FAS-li">
            <ul className="FAS-ul">
              <ListItem
                onClick={() => toggleItem(facility)}
              >
                <Checkbox
                  edge="start"
                  checked={checkedFacilities.some(
                    (checkedFacility) => checkedFacility.id === facility.id,
                  )}
                  tabIndex={-1}
                  disableRipple
                />
                {`${facility.name}`}
              </ListItem>
              <ConditionallyVisible condition={showSubFacilities && facility.subFacilities}>
                {facility.subFacilities.map((subFacility) => (
                  <ListItem
                    style={{ marginLeft: '20px' }}
                    key={subFacility.name}
                    onClick={() => toggleItem(subFacility)}
                  >
                    <Checkbox
                      edge="start"
                      checked={checkedFacilities.some(
                        (checkedFacility) => checkedFacility.id === subFacility.id,
                      )}
                      tabIndex={-1}
                      disableRipple
                    />
                    <ListItemText
                      primary={`${subFacility.name}`}
                    />
                  </ListItem>
                ))}
              </ConditionallyVisible>
            </ul>
          </li>
        ))}
      </List>
    );
  }
}

FacilityAndSubFacilityPickerList.propTypes = {
  updateFacilities: PropTypes.func.isRequired,
  checkedFacilities: PropTypes.arrayOf(PropTypes.shape()),
  facilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showSubFacilities: PropTypes.bool,
};

FacilityAndSubFacilityPickerList.defaultProps = {
  showSubFacilities: true,
  checkedFacilities: [],
};

export default FacilityAndSubFacilityPickerList;
