import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

class EventPickerList extends React.Component {
  constructor(props) {
    super(props);
    const { checkedEvents } = this.props;
    this.state = {
      checkedEvents: checkedEvents || [],
    };
  }

  toggleItem = (facility) => {
    let { checkedEvents } = this.state;
    const { updateEvents } = this.props;
    const isChecked = checkedEvents.some((checkedFac) => checkedFac.id === facility.id);

    if (isChecked) {
      checkedEvents = checkedEvents.filter((checkedFac) => checkedFac.id !== facility.id);
    } else {
      checkedEvents = [...checkedEvents, facility];
    }

    this.setState({ checkedEvents }, () => updateEvents(checkedEvents));
  }

  render() {
    const { toggleItem, state } = this;
    const { checkedEvents } = state;
    const { events } = this.props;

    return (
      <List className="FAS-root" subheader={<li />}>
        {events.map((event) => (
          <li key={`section-${event.name}-${event.id}`} className="FAS-li">
            <ul className="FAS-ul">
              <ListItem
                onClick={() => toggleItem(event)}
              >
                <Checkbox
                  edge="start"
                  checked={checkedEvents.some(
                    (checkedEvent) => checkedEvent.id === event.id,
                  )}
                  tabIndex={-1}
                  disableRipple
                />
                {`${event.name}`}
              </ListItem>
              {event.children.map((child) => {
                const { id, name } = child;
                return (
                  <ListItem
                    key={`child-${name}-${id}`}
                    onClick={() => toggleItem(child)}
                    style={{ paddingLeft: '2rem' }}
                  >
                    <Checkbox
                      edge="start"
                      checked={checkedEvents.some(
                        (checkedEvent) => checkedEvent.id === child.id,
                      )}
                      tabIndex={-1}
                      disableRipple
                    />
                    {`${child.name}`}
                  </ListItem>
                );
              })}
            </ul>
          </li>
        ))}
      </List>
    );
  }
}

EventPickerList.propTypes = {
  updateEvents: PropTypes.func.isRequired,
  checkedEvents: PropTypes.arrayOf(PropTypes.shape()),
  events: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

EventPickerList.defaultProps = {
  checkedEvents: [],
};

export default EventPickerList;
