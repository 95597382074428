import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export const requestAllEnquiriesService = async (companyId) => {
  const url = `/api/companies/${companyId}/enquiries`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
};
export const requestEnquiryService = async (companyId, id) => {
  const url = `/api/companies/${companyId}/enquiry/${id}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
};
export async function requestSendContentService(body) {
  const url = '/api/enquiries/content';
  const response = await httpHelpers.authenticatedPostRequest(url, body);
  return response;
}
