import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export const getBookingsService = async (companyId, dates, status = 'ACTIVE', facilities = []) => {
  const { fromDate, toDate } = dates;
  const url = `/api/companies/${companyId}/reservations?from=${fromDate}&to=${toDate}&status=${status}&facilities=${facilities}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
};

export const getBookingService = async (companyId, reservationId) => {
  const url = `/api/companies/${companyId}/reservations/${reservationId}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
};

export async function createManagerAllocationsService(
  companyId,
  facilityId,
  slots,
  userEmailFor,
  userDetails,
  ageGroupBookingModifierId,
  eventBookingModifierId,
) {
  const endPoint = `/api/companies/${companyId}/allocations`;
  const body = {
    facilityId,
    userEmailFor,
    userDetails,
    slots,
    ageGroupBookingModifierId,
    eventBookingModifierId,
  };
  const response = await httpHelpers.authenticatedPostRequest(endPoint, body);
  return response;
}

export async function updateBookingServices(companyId, reservationId, reqBody) {
  const url = `/api/companies/${companyId}/reservations/${reservationId}`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function editBookingAndCreationService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/edit-reservation`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function swapBookingsService(bookings) {
  const url = '/api/allocations/swap-bookings';
  const response = await httpHelpers.authenticatedPostRequest(url, { bookings });
  return response;
}

export async function updateBookingAllocationServices(companyId, reservationId, reqBody) {
  const url = `/api/companies/${companyId}/reservations/${reservationId}/allocation`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}
