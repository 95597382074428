import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function sendManagerEmailToUsersService(companyId, body) {
  const url = `/api/companies/${companyId}/send-manager-email-to-users`;
  const response = await httpHelpers.authenticatedPostRequest(url, body);
  return response;
}

export async function getUsersService(companyId, page = 0, term, orderBy, orderDirection) {
  const termAppend = term ? `&term=${term}` : '';
  const orderByAppend = orderBy ? `&orderBy=${orderBy}` : '';
  const orderDirectionAppend = orderDirection ? `&orderDirection=${orderDirection}` : '';
  const url = `/api/companies/${companyId}/users?page=${page}${termAppend}${orderByAppend}${orderDirectionAppend}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function getUsersBookingsService(companyId, userId, status) {
  const url = `/api/companies/${companyId}/users/${userId}?status=${status}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}
export async function getUserBookingsBySearchService(companyId, userId,
  dates, status, facilities = []) {
  const { fromDate, toDate } = dates;
  const url = `/api/companies/${companyId}/users-bookings?userId=${userId}&from=${fromDate}&to=${toDate}&status=${status}&facilities=${facilities}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}
export async function inviteUser(user, companyId) {
  const url = `/api/companies/${companyId}/invite-user`;
  const body = { user };
  const response = await httpHelpers.authenticatedPostRequest(url, body);
  return response;
}

export async function inviteAssociateUser(body, companyId) {
  const url = `/api/companies/${companyId}/agents`;
  const response = await httpHelpers.authenticatedPostRequest(url, body);
  return response;
}
export async function getAssociateUsers(companyId, siteId) {
  const url = `/api/companies/${companyId}/agents${siteId ? `/${siteId}` : ''}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function snoozeUserAlert(companyId, snoozeUntil) {
  const url = '/api/users/snooze-alert';
  const body = { companyId, snoozeUntil };
  const response = await httpHelpers.authenticatedPostRequest(url, body);
  return response;
}

export async function updateCompanyUser(companyId, userId, data) {
  const url = `/api/companies/${companyId}/users/${userId}`;
  const body = { data };
  const response = await httpHelpers.authenticatedPutRequest(url, body);
  return response;
}

export async function resendUserInvite(companyId, userId, emailType) {
  const url = `/api/companies/${companyId}/users/${userId}/resendInvite`;
  const body = { emailType };
  const response = await httpHelpers.authenticatedPostRequest(url, body);
  return response;
}

export async function validateUserEmail(email, source) {
  const url = '/api/users/validate-email';
  const body = { email, source };
  const response = await httpHelpers.authenticatedPostRequest(url, body);
  return response;
}

export async function resendUserEmail(companyId, body) {
  const url = `/api/companies/${companyId}/resendUserEmail`;
  const response = await httpHelpers.authenticatedPostRequest(url, body);
  return response;
}
