import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as scheduleActions from '../../../reducers/schedulesReducer';
import WeekdayTimesList from './WeekdayTimesList';
import 'rc-time-picker/assets/index.css';

class ScheduleAddNewSetOpeningTimesDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleGroupDialogOpen: false,
      checkedB: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ scheduleGroupDialogOpen: true });
  };

  handleChange = () => {
    const { checkedB } = this.state;
    this.setState({ checkedB: !checkedB });
  };

  handleRequestClose = () => {
    this.setState({ scheduleGroupDialogOpen: false });
  };

  handleRequestToCancel = () => {
    const { getSchedules } = this.props;
    this.handleRequestClose();
    getSchedules();
  };

  handleSave = () => {
    const { toggleSavedNewOpeningTimes } = this.props;
    toggleSavedNewOpeningTimes(true);
    this.handleRequestClose();
  }

  render() {
    const {
      fullWeekSchedules,
      updateFullWeekSchedulesStore,
      selectedSchedule,
    } = this.props;

    const openingTimes = selectedSchedule?.schedules;

    const {
      scheduleGroupDialogOpen,
    } = this.state;

    const isMobile = window.innerWidth < 768;

    return (
      <div>
        <div>
          <Button
            id="manager-schedules-new-times"
            onClick={this.handleClickOpen}
            variant="contained"
            color="primary"
          >
            Add new set of opening times
          </Button>
        </div>
        <Dialog
          open={scheduleGroupDialogOpen}
          fullScreen={isMobile}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>
              Add a new set of opening times
            </DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestToCancel()} />
            </DialogActions>
          </div>
          <DialogContent>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <WeekdayTimesList
                fullWeekSchedules={fullWeekSchedules}
                onChange={updateFullWeekSchedulesStore}
                openingTimes={openingTimes}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestToCancel} variant="contained" color="secondary">
              Cancel
            </Button>
            <Button onClick={() => this.handleSave()} variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ScheduleAddNewSetOpeningTimesDialog.propTypes = {
  fullWeekSchedules: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getSchedules: PropTypes.func.isRequired,
  updateFullWeekSchedulesStore: PropTypes.func.isRequired,
  toggleSavedNewOpeningTimes: PropTypes.func.isRequired,
  selectedSchedule: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  schedule: state.schedules.schedule,
  scheduleGroup: state.schedules.scheduleGroup,
  scheduleGroupDialogOpen: state.schedules.scheduleGroupDialogOpen,
  createFullWeekSchedules: state.schedules.createFullWeekSchedules,
  fullWeekSchedules: state.schedules.fullWeekSchedules,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getSchedules: () => scheduleActions.getSchedules(),
  toggleSavedNewOpeningTimes: (bool) => scheduleActions.toggleSavedNewOpeningTimes(bool),
  updateFullWeekSchedulesStore: (value) => scheduleActions.updateFullWeekSchedulesStore(
    value,
  ),
}, dispatch);

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ScheduleAddNewSetOpeningTimesDialog);
