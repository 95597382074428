/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { Button, Divider, Typography } from '@material-ui/core';
import { writeToSlack } from '@pitchbooking-dev/pb-shared/lib/utils/slack';
import { companyStatuses } from '../constants';
import { useOrigin } from '../../../hooks';

export const CompnayStatus = ({ companyInfo, updateCompany }) => {
  const dispatch = useDispatch();
  const { managerUrl } = useOrigin();
  const { onboarding, id: companyId } = companyInfo;
  const [companyStatus, setCompanyStatus] = useState(null);

  useEffect(() => {
    setCompanyStatus(companyInfo?.status);
  }, [onboarding]);

  return (
    <>
      <Typography style={{ textDecoration: 'underline' }}>
        Company Status
      </Typography>
      <PBInput
        id="companyStatus"
        type="select"
        value={companyStatus}
        options={companyStatuses}
        onChange={(e) => setCompanyStatus(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        type="button"
        style={{ width: '50%', alignSelf: 'end' }}
        onClick={async () => {
          if (managerUrl.includes('manager.pitchbooking.com')) {
            await writeToSlack({
              type: 'UPDATE_COMPANY_STATUS',
              previousStatus: companyInfo.status,
              status: companyStatus,
              companyName: companyInfo.name,
            });
          }
          dispatch(
            updateCompany(
              companyId,
              { status: companyStatus },
            ),
          );
        }}
      >
        Save
      </Button>

      <Divider />
    </>
  );
};
