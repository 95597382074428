import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import Button from '@material-ui/core/Button';
import moment from 'moment';

import StripeCheckout from '@pitchbooking-dev/pb-shared/lib/components/Stripe/StripeCheckout';
import { Card } from '@pitchbooking-dev/pb-shared/lib/components/common';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import * as actions from '../../reducers/companiesReducer';
import '../../styles/billing.css';
import BillingAgreementInfo from './billingAgreementInfo';
import BillingLoadingSection from './billingLoadingSection';
import * as companyServices from '../../services/companiesServices';

class Billing extends React.Component {
  componentDidMount = () => {
    const {
      match,
      getBillingData,
      companyInfo,
      currentUser,
    } = this.props;
    let { billingId } = match.params;

    if (!billingId) {
      billingId = companyInfo.billings.filter((billing) => (billing.type === 'STRIPE' || billing.gameOn) && billing.status === 'PENDING' && billing.required)?.[0].id;
    }
    getBillingData(billingId);

    const pbUser = currentUser?.email?.includes('@pitchbooking.com');

    // if company id is available and users is not pbUser, track the billing view
    if (companyInfo?.id && !pbUser) {
      const data = {
        billingId,
        viewedByUserId: currentUser?.userId,
        timeViewed: moment().format('YYYY-MM-DD HH:mm:ss'),
      };

      companyServices.trackCompanyBilling(companyInfo?.id, data);
    }
  }

  stripeBillingCustomer = () => {
    const {
      match,
      setStripeBilling,
      companyInfo,
      currentUser,
    } = this.props;
    let { billingId } = match.params;
    if (!billingId) {
      billingId = companyInfo.billings.filter((billing) => (billing.type === 'STRIPE' || billing.gameOn) && billing.status === 'PENDING')[0].id;
    }

    setStripeBilling(
      {
        setupIntent: {
          metadata: {
            transactionId: billingId,
            userId: currentUser.id,
          },
        },
      },
    );
  }

  render() {
    const {
      billing, billingCreationStatus, currentUser, updateBilling,
    } = this.props;
    const isPBAdmin = currentUser && currentUser.managerAccess.some((access) => access.role.type === 'PITCHBOOKING_INTERNAL');
    if (!billing) {
      return (
        <div />
      );
    }
    if (!billing.company) {
      return (
        <div />
      );
    }
    let billingCreationStatusErrorMessage = '';
    if (billingCreationStatus.error && billingCreationStatus.error) {
      billingCreationStatusErrorMessage = `${billingCreationStatus.error.message} Try again.`;
    }

    return (
      <div className="billingContainer">
        <BillingAgreementInfo
          billing={billing}
          isPBAdmin={isPBAdmin}
          updateBilling={(params) => updateBilling(billing.id, params)}
        />
        <BillingLoadingSection />

        <div id="billingConfirmationSection" />
        <AlternativelyVisible condition={billing.status === 'PENDING'}>

          <>
            <AlternativelyVisible condition={billing.amount > 0}>
              <>
                <ConditionallyVisible condition={billingCreationStatus.error}>
                  <p style={{ color: '#AC372F' }}>{billingCreationStatusErrorMessage}</p>
                </ConditionallyVisible>

                <Card style={{ textAlign: 'left' }}>
                  <Card.Header>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                      <CreditCardIcon />
                      <span style={{ fontWeight: 600, fontSize: '1rem' }}>Payment Method</span>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {/* TODO: Pull the stripe account number from the database */}
                    {/* TODO: This will create a setup intent, which can be
                   used to bill at any time */}
                    <StripeCheckout
                      companyStripeId="acct_1CS6MjD87o6fmXTg"
                      mode="SETUP_INTENT"
                      transactionType="BILLING"
                      transactionId={billing.id}
                      redirectUrl={`${window.location.origin}/agreement/${billing.id}/processing`}
                      buttonText="Confirm Details"
                    />
                  </Card.Body>
                </Card>
              </>
              <>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.stripeBillingCustomer()}
                  >
                    Complete Agreement
                  </Button>
                </div>
              </>
            </AlternativelyVisible>
          </>
          <>
            <Button
              disabled
              variant="contained"
              style={{
                margin: '0 auto', display: 'block', backgroundColor: 'white', color: '#24dc89',
              }}
            >
              {(billing.status === 'PROCESSING' || billingCreationStatus.processing) ? 'Billing Processing' : 'Agreement Confirmed'}
            </Button>
          </>
        </AlternativelyVisible>
      </div>
    );
  }
}

Billing.propTypes = {
  billing: PropTypes.shape().isRequired,
  companyInfo: PropTypes.shape().isRequired,
  getBillingData: PropTypes.func.isRequired,
  setStripeBilling: PropTypes.func.isRequired,
  updateBilling: PropTypes.func.isRequired,
  // setBillingAsProcessing: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired,
  billingCreationStatus: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  billing: state.companies.billing,
  companyInfo: state.companies.companyInfo,
  currentUser: state.users.currentUser,
  billingCreationStatus: state.companies.billingCreationStatus,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getBillingData: (billingId) => actions.getBillingData(billingId),
    setStripeBilling: (source) => actions.setStripeBilling(source),
    updateBilling: (billingId, billingParams) => actions.updateBilling(billingId, billingParams),
    // setBillingAsProcessing: (billingId) => actions.setBillingAsProcessing(billingId),
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Billing);
