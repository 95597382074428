const config = {
  type: 'pie',
  data: {
    labels: ['Booked', 'Not Booked'],
  },
  options: {
    title: {
      display: true,
      position: 'bottom',
      font: 'Montserrat',
    },
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: '#FFFFFF',
      bodyAlign: 'left',
      bodyFontColor: '#000000',
      bodyFontSize: 20,
      enabled: true,
      titleFontColor: '#00000060',
    },
    maintainAspectRatio: false,
    elements: {
      arc: {
        borderWidth: 0,
        borderColor: '#C0C0C0',
      },
    },
  },
};

export default config;
