// templateName is taken from newFacilityTemplates in pb-shared

const createDemoTemplates = [
  {
    label: 'Commercial Centre',
    value: 'COMMERCIAL_CENTRE',
    description: 'A commercial centre with multiple facilities. Two sites, each with a set of 3G Cages, Floodlights, Changing Rooms, Kids Party Hire, and Bib hire.',
    configuration: {
      sites: [
        {
          name: 'Main Arena',
          siteAddress: '',
          facilities: [
            {
              name: '3G Cages',
              templateName: '3G Pitch (Multiple)',
              amount: 50,
              subUnits: 6,
              unitName: 'Pitch',
              quantity: 1,
            },
            {
              name: 'Full Size 3G Pitch',
              templateName: '3G Pitch (Divisible)',
              amount: 45,
              subUnits: 3,
              unitName: 'Third',
              quantity: 1,
            },
          ],
          addons: [
            {
              name: 'Floodlights',
              price: 15,
              type: 'RESOURCE',
            },
            {
              name: 'Bibs',
              price: 1,
              type: 'CONSUMABLE',
            },
          ],
          amenities: [
            {
              name: 'Changing Rooms',
              price: 5,
              quantity: 1,
            },
          ],
        },
        {
          name: 'Training Complex',
          facilities: [
            {
              name: 'Grass Football Pitch',
              templateName: 'Grass Football Pitch',
              unitName: 'Pitch',
              amount: 40,
              quantity: 3,
              subUnits: 0,
            },
          ],
          addons: [],
          amenities: [
            {
              name: 'Changing Rooms',
              price: 5,
              quantity: 1,
            },
          ],
        },
      ],
    },
  },

  {
    label: 'Council',
    value: 'COUNCIL',
    description: 'A council with multiple sites. Each site has a range of grass pitches, cricket fields, and changing rooms.',
    configuration: {
      sites: [
        {
          name: 'Site 1',
          siteAddress: '',
          facilities: [
            {
              name: 'Grass Football Pitch',
              templateName: 'Grass Football Pitch',
              unitName: 'Pitch',
              amount: 42,
              quantity: 3,
              subUnits: 0,
            },
            {
              name: 'Cricket Field',
              templateName: 'Cricket Pitch',
              unitName: 'Pitch',
              amount: 55,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: 'Rugby Pitch',
              templateName: 'Rugby Pitch',
              unitName: 'Pitch',
              amount: 42,
              quantity: 2,
              subUnits: 0,
            },
            {
              name: 'Pavilion',
              templateName: 'Room / Pavilion',
              unitName: 'Room',
              amount: 25,
              quantity: 1,
              subUnits: 0,
            },
          ],
          addons: [],
          amenities: [
            {
              name: 'Changing Rooms',
              price: 5,
              quantity: 1,
            },
          ],
        },
        {
          name: 'Site 2',
          facilities: [
            {
              name: 'Grass Football Pitch',
              templateName: 'Grass Football Pitch',
              unitName: 'Pitch',
              amount: 42,
              quantity: 2,
              subUnits: 0,
            },
            {
              name: 'Athletics Track',
              templateName: 'Athletics',
              unitName: 'Track',
              amount: 54,
              quantity: 1,
              subUnits: 0,
            },

            {
              name: 'Astroturf Hockey Pitch',
              templateName: 'Hockey Pitch',
              unitName: 'Pitch',
              amount: 75,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: 'Pavilion',
              templateName: 'Room / Pavilion',
              unitName: '',
              amount: 25,
              quantity: 1,
              subUnits: 0,
            },
          ],
          addons: [],
          amenities: [
            {
              name: 'Changing Rooms',
              price: 5,
              quantity: 1,
            },
          ],
        },
        {
          name: 'Site 3',
          facilities: [
            {
              name: 'Grass Football Pitch',
              templateName: 'Grass Football Pitch',
              unitName: 'Pitch',
              amount: 42,
              quantity: 4,
              subUnits: 0,
            },
            {
              name: 'Full Size 3G Pitch',
              templateName: '3G Pitch (Divisible)',
              amount: 45,
              subUnits: 3,
              unitName: 'Third',
              quantity: 1,
            },
          ],
          addons: [],
          amenities: [
            {
              name: 'Changing Rooms',
              price: 5,
              quantity: 1,
            },
          ],
        },
      ],
    },
  },

  {
    label: 'Club',
    value: 'CLUB',
    description: 'A club with one site. The site has a range of grass pitches, 3G Pitch, Clubhouse, and changing rooms.',
    configuration: {
      sites: [
        {
          name: 'Headquarters',
          siteAddress: '',
          facilities: [
            {
              name: 'Main Grass Pitch',
              templateName: 'Grass Football Pitch',
              unitName: 'Pitch',
              amount: 120,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: 'Training Pitch',
              templateName: 'Grass Football Pitch',
              unitName: 'Pitch',
              amount: 45,
              quantity: 3,
              subUnits: 0,
            },
            {
              name: 'Clubhouse',
              templateName: 'Room / Pavilion',
              unitName: 'Room',
              amount: 40,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: '3G Cages',
              templateName: '3G Pitch (Multiple)',
              amount: 50,
              subUnits: 6,
              unitName: 'Pitch',
              quantity: 1,
            },
            {
              name: 'Full Size 3G Pitch',
              templateName: '3G Pitch (Divisible)',
              amount: 45,
              subUnits: 3,
              unitName: 'Third',
              quantity: 1,
            },
          ],
          addons: [
            {
              name: 'Floodlights',
              price: 15,
              type: 'RESOURCE',
            },
            {
              name: 'Bibs',
              price: 1,
              type: 'CONSUMABLE',
            },
          ],
          amenities: [
            {
              name: 'Changing Rooms',
              price: 5,
              quantity: 1,
            },
          ],
        },
      ],
    },
  },

  {
    label: 'School',
    value: 'SCHOOL',
    description: 'A school with one site. The site has a range of 3G Pitch, Hockey Pitch, Tennis Courts, and a range of Classrooms.',
    configuration: {
      sites: [
        {
          name: 'Main School',
          siteAddress: '',
          facilities: [
            {
              name: 'Grass Pitch',
              templateName: 'Grass Football Pitch',
              unitName: 'Pitch',
              amount: 120,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: 'Assembly Hall',
              templateName: 'Room / Pavilion',
              unitName: 'Room',
              amount: 80,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: 'Classroom',
              templateName: 'Room / Pavilion',
              unitName: 'Room',
              amount: 15,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: 'Netball Court',
              templateName: 'Basketball / Netball',
              unitName: 'Court',
              amount: 35,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: 'Tennis Court',
              templateName: 'Tennis / Racket Sports',
              unitName: 'Court',
              amount: 25,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: 'Sports Hall',
              templateName: 'Indoor Hall',
              unitName: 'Room',
              amount: 60,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: 'Full Size 3G Pitch',
              templateName: '3G Pitch (Divisible)',
              amount: 45,
              subUnits: 2,
              unitName: 'Half',
              quantity: 1,
            },
          ],
          addons: [
            {
              name: 'Floodlights',
              price: 15,
              type: 'RESOURCE',
            },
            {
              name: 'Bibs',
              price: 1,
              type: 'CONSUMABLE',
            },
          ],
          amenities: [
            {
              name: 'Changing Rooms',
              price: 5,
              quantity: 1,
            },
          ],
        },
      ],
    },
  },

  {
    label: 'Football',
    value: 'FOOTBALL',
    description: 'A football club with one site. The site has a range of grass pitches, 3G Pitch, Clubhouse, and changing rooms.',
    configuration: {
      sites: [
        {
          name: 'Football Headquarters',
          siteAddress: '',
          facilities: [
            {
              name: 'Main Grass Pitch',
              templateName: 'Grass Football Pitch',
              unitName: 'Pitch',
              amount: 120,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: 'Training Pitch',
              templateName: 'Grass Football Pitch',
              unitName: 'Pitch',
              amount: 45,
              quantity: 3,
              subUnits: 0,
            },
            {
              name: 'Clubhouse',
              templateName: 'Room / Pavilion',
              unitName: 'Room',
              amount: 40,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: '3G Cages',
              templateName: '3G Pitch (Multiple)',
              amount: 50,
              subUnits: 6,
              unitName: 'Pitch',
              quantity: 1,
            },
            {
              name: 'Full Size 3G Pitch',
              templateName: '3G Pitch (Divisible)',
              amount: 45,
              subUnits: 3,
              unitName: 'Third',
              quantity: 1,
            },
          ],
          addons: [
            {
              name: 'Floodlights',
              price: 15,
              type: 'RESOURCE',
            },
            {
              name: 'Bibs',
              price: 1,
              type: 'CONSUMABLE',
            },
          ],
          amenities: [
            {
              name: 'Changing Rooms',
              price: 5,
              quantity: 1,
            },
          ],
        },
      ],
    },
  },

  {
    label: 'Rugby',
    value: 'RUGBY',
    description: 'A rugby club with one site. The site has a range of grass pitches, 3G Pitch, Clubhouse, and changing rooms and a gym.',
    configuration: {
      sites: [
        {
          name: 'Rugby Headquarters',
          siteAddress: '',
          facilities: [
            {
              name: 'Main Grass Pitch',
              templateName: 'Rugby Pitch',
              unitName: 'Pitch',
              amount: 120,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: 'Training Pitch',
              templateName: 'Rugby Pitch',
              unitName: 'Pitch',
              amount: 45,
              quantity: 3,
              subUnits: 0,
            },
            {
              name: 'Clubhouse',
              templateName: 'Room / Pavilion',
              unitName: 'Room',
              amount: 40,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: '3G Cages',
              templateName: '3G Pitch (Multiple)',
              amount: 50,
              subUnits: 6,
              unitName: 'Pitch',
              quantity: 1,
            },
            {
              name: 'Full Size 3G Pitch',
              templateName: '3G Pitch (Divisible)',
              amount: 45,
              subUnits: 3,
              unitName: 'Third',
              quantity: 1,
            },
            {
              name: 'Gym',
              templateName: 'Room / Pavilion',
              amount: 50,
              subUnits: 0,
              unitName: 'Gym',
              quantity: 1,
            },
          ],
          addons: [
            {
              name: 'Floodlights',
              price: 15,
              type: 'RESOURCE',
            },
            {
              name: 'Bibs',
              price: 1,
              type: 'CONSUMABLE',
            },
          ],
          amenities: [
            {
              name: 'Changing Rooms',
              price: 5,
              quantity: 1,
            },
          ],
        },
      ],
    },
  },

  {
    label: 'GAA',
    value: 'GAA',
    description: 'A GAA club with one site. The site has a range of grass pitches, 3G Pitch, Clubhouse, and changing rooms.',
    configuration: {
      sites: [
        {
          name: 'GAA Headquarters',
          siteAddress: '',
          facilities: [
            {
              name: 'Main Grass Pitch',
              templateName: '3G Pitch',
              unitName: 'Pitch',
              amount: 120,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: 'Training Pitch',
              templateName: '3G Pitch',
              unitName: 'Pitch',
              amount: 45,
              quantity: 3,
              subUnits: 0,
            },
            {
              name: 'Clubhouse',
              templateName: 'Room / Pavilion',
              unitName: 'Room',
              amount: 40,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: 'Wall Ball',
              templateName: 'Room / Pavilion',
              amount: 39,
              subUnits: 0,
              unitName: 'Pitch',
              quantity: 1,
            },
            {
              name: 'Gym',
              templateName: 'Room / Pavilion',
              amount: 45,
              subUnits: 0,
              unitName: 'Gym',
              quantity: 1,
            },
          ],
          addons: [
            {
              name: 'Floodlights',
              price: 15,
              type: 'RESOURCE',
            },
            {
              name: 'Bibs',
              price: 1,
              type: 'CONSUMABLE',
            },
          ],
          amenities: [
            {
              name: 'Changing Rooms',
              price: 5,
              quantity: 1,
            },
          ],
        },
      ],
    },
  },

  {
    label: 'Hockey',
    value: 'HOCKEY',
    description: 'A Hockey club with one site. The site has a range of grass pitches, 3G Pitch, Clubhouse, and changing rooms.',
    configuration: {
      sites: [
        {
          name: 'Hockey Headquarters',
          siteAddress: '',
          facilities: [
            {
              name: 'Astro Pitch',
              templateName: '3G Pitch',
              unitName: 'Pitch',
              amount: 120,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: 'Sand Pitch',
              templateName: 'Not Applicable',
              unitName: 'Pitch',
              amount: 45,
              quantity: 3,
              subUnits: 0,
            },
            {
              name: 'Water Based Pitch',
              templateName: 'Not Applicable',
              unitName: 'Pitch',
              amount: 45,
              quantity: 3,
              subUnits: 0,
            },
            {
              name: 'Clubhouse',
              templateName: 'Room / Pavilion',
              unitName: 'Room',
              amount: 40,
              quantity: 1,
              subUnits: 0,
            },
          ],
          addons: [
            {
              name: 'Floodlights',
              price: 15,
              type: 'RESOURCE',
            },
            {
              name: 'Bibs',
              price: 1,
              type: 'CONSUMABLE',
            },
          ],
          amenities: [
            {
              name: 'Changing Rooms',
              price: 5,
              quantity: 1,
            },
          ],
        },
      ],
    },
  },

  {
    label: 'Cricket Club',
    value: 'CRICKET',
    description: 'A Cricket club with one site. The site has a range of grass pitches, 3G Pitch, Clubhouse, and changing rooms.',
    configuration: {
      sites: [
        {
          name: 'Cricket Headquarters',
          siteAddress: '',
          facilities: [
            {
              name: 'cricket Nets',
              templateName: 'Cricket Nets',
              unitName: 'Pitch',
              amount: 120,
              quantity: 1,
              subUnits: 0,
            },
            {
              name: 'cricket Pitch',
              templateName: 'Cricket Pitch',
              unitName: 'Pitch',
              amount: 45,
              quantity: 3,
              subUnits: 0,
            },
            {
              name: 'Clubhouse',
              templateName: 'Room / Pavilion',
              unitName: 'Pavilion',
              amount: 40,
              quantity: 1,
              subUnits: 0,
            },
          ],
          addons: [
            {
              name: 'Floodlights',
              price: 15,
              type: 'RESOURCE',
            },
            {
              name: 'Bibs',
              price: 1,
              type: 'CONSUMABLE',
            },
          ],
          amenities: [
            {
              name: 'Changing Rooms',
              price: 5,
              quantity: 1,
            },
          ],
        },
      ],
    },
  },
];

export default createDemoTemplates;
