/**
 * @fileoverview The CategoryChartBase component
 * represents and holds the actual chart itself.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'chart.js';

import config from './configs/CategoryChartBaseConfigBarHorizontal';

export class CategoryChartBaseBarHorizontal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartInstance: null,
    };

    this.bookingsDataIsDifferent = this.bookingsDataIsDifferent.bind(this);
    this.updateChart = this.updateChart.bind(this);
  }

  componentDidMount() {
    // Set global font.
    Chart.defaults.global.defaultFontFamily = 'Montserrat';
    const {
      bookings, categoryName, xAxisLabel,
    } = this.props;

    const context = document.getElementById(`${categoryName}Chart`).getContext('2d');

    // Configure canvas.
    const configCopy = { ...config };
    configCopy.options.scales.xAxes[0].scaleLabel.labelString = xAxisLabel;
    const newChartInstance = new Chart(context, configCopy);

    this.setState({
      chartInstance: newChartInstance,
    }, () => {
      // Following chart updates only triggered if user has
      // navigated away and back to the analytics tab without
      // a hard refresh, thus implying we have real data in the
      // Redux store.

      if (bookings.length === 0) {
        // Update chart if we have no data.
        this.updateChart();
      } else if (bookings[0]?.id !== '') {
        // Update chart if we have non initial-state data.
        this.updateChart();
      }
    });
  }

  componentDidUpdate(previousProps) {
    const { xAxis, yAxis } = this.props;
    // Update chart if new bookings data is
    // received from the Redux store.
    if (this.bookingsDataIsDifferent(previousProps) || previousProps.xAxis !== xAxis
    || previousProps.yAxis !== yAxis) {
      this.updateChart();
    }
  }

  /*
          bookingsDataIsDifferent is a naive method that (attempts to)
          determine if the bookings data in the previous props is
          different from the bookings data in the current props.
      */
  bookingsDataIsDifferent(prevProps) {
    const { bookings } = this.props;
    const currentBookings = bookings;
    const currentBookingsLength = currentBookings.length;
    const previousBookings = prevProps.bookings;
    const previousBookingsLength = previousBookings.length;

    if (previousBookingsLength === currentBookingsLength) {
      return false;
    }

    if (previousBookingsLength === 0 || currentBookingsLength === 0) {
      return true;
    }

    if (previousBookingsLength !== currentBookingsLength) {
      return true;
    }

    if (previousBookings[0].id === currentBookings[0].id
      && previousBookings[previousBookingsLength - 1].id
      === currentBookings[currentBookingsLength - 1].id) {
      return false;
    }

    return true;
  }

  /*
          updateChart is a method that carries out all of the work involved with
          actually updating the chart. Namely, it determines the visual axis
          granularity. It then updates the chart using the chart instance
          stored in component state.

          See chart.js documentation for more information on manually updating charts.
          https://www.chartjs.org/docs/latest/developers/updates.html
      */
  updateChart() {
    const { xAxis, yAxis, backgroundColor } = this.props;
    const { chartInstance } = this.state;
    const currentChartInstance = chartInstance;

    const yAxisRounded = yAxis.map((total) => Math.round((total + Number.EPSILON) * 100) / 100);

    currentChartInstance.data.labels = xAxis;

    currentChartInstance.data.datasets = [{
      data: yAxisRounded,
      backgroundColor,
      borderColor: 'rgb(0, 0, 0, 0)',
      borderWidth: 2,
      lineTension: 0,
      pointBackgroundColor: 'rgb(0, 0, 0, 0)',
      pointBorderColor: 'rgb(0, 0, 0, 0)',
    }];

    currentChartInstance.update();
  }

  render() {
    const { categoryName } = this.props;
    return (
      <div className="chartContainer">
        <canvas id={`${categoryName}Chart`} />
      </div>
    );
  }
}

CategoryChartBaseBarHorizontal.propTypes = {
  bookings: PropTypes.shape().isRequired,
  categoryName: PropTypes.string.isRequired,
  xAxis: PropTypes.string.isRequired,
  yAxis: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  xAxisLabel: PropTypes.string.isRequired,
};
