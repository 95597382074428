import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import LoadingSkeletonBarChart from '../../../../components/LoadingComponents/LoadingSkeletonBarChart';
import FailedSection from '../../../../components/LoadingComponents/FailedSection';
import { CategoryChartBaseBarVerticalStacked } from './bases/CategoryChartBaseBarVerticalStacked';
import { CategoryHeaderBase } from './bases/CategoryTitleBase';

class CategoryUtilisation extends React.Component {
  render() {
    const {
      analyticsBookings,
      analyticsTimeframe,
      analyticsTimeframeCustom,
      analyticsLoading,
      analyticsFailed,
    } = this.props;

    const { bookings, xAxisUtilisation, yAxisUtilisation } = analyticsBookings;
    return (
      <div className="category" id="Utilisation" style={{ width: '100%' }}>
        <CategoryHeaderBase categoryName="Utilisation by Day of Week" />
        <AlternativelyVisible condition={analyticsLoading}>
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: '1',
          }}
          >
            <LoadingSkeletonBarChart />
          </div>
          <AlternativelyVisible condition={analyticsFailed}>
            <FailedSection />
            <CategoryChartBaseBarVerticalStacked
              backgroundColorPrimary="#87CEFA60"
              backgroundColorSecondary="#00000008"
              bookings={bookings}
              borderColor="#00000020"
              categoryName="Utilisation by Day of Week"
              analyticsTimeframe={analyticsTimeframe}
              analyticsTimeframeCustom={analyticsTimeframeCustom}
              xAxis={xAxisUtilisation}
              yAxis={yAxisUtilisation}
              yAxisLabel="% Utilisation"
            />
          </AlternativelyVisible>
        </AlternativelyVisible>
      </div>
    );
  }
}

CategoryUtilisation.propTypes = {
  analyticsBookings: PropTypes.shape().isRequired,
  analyticsTimeframe: PropTypes.shape().isRequired,
  analyticsTimeframeCustom: PropTypes.shape().isRequired,
  analyticsLoading: PropTypes.bool.isRequired,
  analyticsFailed: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    analyticsBookings: state.analytics.analyticsBookings,
    analyticsTimeframe: state.analytics.analyticsTimeframe,
    analyticsTimeframeCustom: state.analytics.analyticsTimeframeCustom,
    analyticsLoading: state.analytics.analyticsLoading,
    analyticsFailed: state.analytics.analyticsFailed,
  };
}

export default connect(mapStateToProps)(CategoryUtilisation);
