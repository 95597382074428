import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as eventsActions from '../../../reducers/eventsReducer';

import '../../../styles/payments.css';
import Back from '../../../components/Back';
import ActionBar from '../../../components/ActionBar';
import MediaConfiguration from '../../../components/MediaConfiguration';

const EventMedia = ({ match }) => {
  const { id } = match.params;
  const {
    event,
  } = useSelector((state) => ({
    event: state.events.event,
  }));
  const dispatch = useDispatch();
  return (
    <div style={{ backgroundColor: '#fafbff' }}>
      <ActionBar>
        <Back />
      </ActionBar>

      <div style={{
        height: '95.5vh',
        marginLeft: '20px',
        marginRight: '40px',
      }}
      >
        <MediaConfiguration type="EVENT" resourceId={id} initialImage={event.imageUrl} reloadResource={() => dispatch(eventsActions.getEvent(id))} />
      </div>
    </div>
  );
};

EventMedia.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default EventMedia;
