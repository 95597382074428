import React, { useState, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import LoadingButton from '@pitchbooking-dev/pb-shared/lib/components/LoadingButton';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import { useMobile } from '../../../hooks';
import { confirmWaitingTeam } from '../../../services/leaguesServices';
import * as leagueActions from '../../../reducers/leaguesReducer';

const ConfirmTeamDialog = ({
  leagueId, team,
}) => {
  const dispatch = useDispatch();
  const { teams } = useSelector((state) => state.leagues.league);

  const [open, setOpen] = useState(false);
  const isMobile = useMobile();
  const replacableTeams = useMemo(() => teams.filter((x) => x.league_team.status === 'DELETED'), [teams]);

  const validationSchema = Yup.object().shape({
    replacementTeam: Yup.string(),
    inheritPoints: Yup.boolean(),
    inheritFixtures: Yup.boolean(),
  });

  const onSubmit = async ({ replacementTeam, inheritPoints, inheritFixtures }, actions) => {
    actions.setSubmitting(true);

    try {
      const res = await confirmWaitingTeam(
        leagueId, team.id, replacementTeam, inheritPoints, inheritFixtures,
      );

      if (res.error) throw new Error(res.error);
      dispatch(leagueActions.getLeague(leagueId));
      dispatch(sessionActions.updateSuccessfulSnackbar('Team successfully confirmed!', true));
      setOpen(false);
    } catch (err) {
      console.error(err);
      dispatch(sessionActions.updateErrorSnackbar(`Failed to confirm team: ${err}`, true));
    }

    actions.setSubmitting(false);
  };

  return (
    <div>
      <Button color="primary" variant="contained" onClick={() => setOpen(true)}>
        Confirm Team
      </Button>

      <Dialog
        open={open}
        fullScreen={isMobile}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">{`Confirm ${team.name}`}</Typography>

            <CloseDialogIcon onClick={() => setOpen(!open)} />
          </div>
        </DialogTitle>

        <DialogContent>

          <Formik
            initialValues={{
              replacementTeam: '',
              inheritPoints: true,
              inheritFixtures: true,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values, errors, touched, handleChange,
              handleSubmit, resetForm, isSubmitting, setFieldValue,
            }) => (
              <form
                onSubmit={handleSubmit}
                onReset={resetForm}
                style={{
                  display: 'flex', flexDirection: 'column', gap: '0.75rem', minWidth: !isMobile ? 500 : 0,
                }}
              >
                <ConditionallyVisible condition={replacableTeams.length > 0}>
                  <>
                    <Typography>
                      {`There has previously been a team/teams that have been deleted from this league. ${team.name} can inherit their points and/or
                    their fixtures.`}
                    </Typography>
                    <Typography variant="small">If you want to inherit from another team, select them from below</Typography>

                    <PBInput
                      id="replacementTeam"
                      type="select"
                      label="Replacement Team"
                      value={values.replacementTeam}
                      onChange={handleChange}
                      options={
                        replacableTeams.map((x) => ({
                          label: x.name,
                          value: x.id,
                        }))
                      }
                      isError={touched.replacementTeam && Boolean(errors.replacementTeam)}
                      errorMessage={touched.replacementTeam && errors.replacementTeam}
                    />

                    <ConditionallyVisible condition={values.replacementTeam}>
                      <Typography>What would you like to inherit?</Typography>

                      <PBInput
                        id="inheritPoints"
                        type="toggle"
                        label="Points?"
                        value={values.inheritPoints}
                        onChange={handleChange}
                        isError={touched.inheritPoints && Boolean(errors.inheritPoints)}
                        errorMessage={touched.inheritPoints && errors.inheritPoints}
                      />

                      <PBInput
                        id="inheritFixtures"
                        type="toggle"
                        label="Fixtures?"
                        value={values.inheritFixtures}
                        onChange={handleChange}
                        isError={touched.inheritFixtures && Boolean(errors.inheritFixtures)}
                        errorMessage={touched.inheritFixtures && errors.inheritFixtures}
                      />

                      <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => setFieldValue('replacementTeam', null)}
                        >
                          Cancel Inheritance
                        </Button>
                      </div>
                    </ConditionallyVisible>
                  </>
                </ConditionallyVisible>

                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <LoadingButton
                    buttonText="Confirm Team"
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                    color="primary"
                  />
                </div>

              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ConfirmTeamDialog.propTypes = {
  leagueId: PropTypes.string.isRequired,
  team: PropTypes.shape().isRequired,
};

export default ConfirmTeamDialog;
