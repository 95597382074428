/*
    The HeaderBookings component represents the bookings statistic
    at the top of the analytics. It is composed of 3 items:
        1. A label (StatisticLabel) that identifies the statistic.
        2. Absolute number of bookings over the past X days.
        3. An icon (AssignmentTurnedInIcon) that helps further
           identify the statistic.
*/

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import LoadingSkeletonThreeDots from '../../../../components/LoadingComponents/LoadingSkeletonThreeDots';
import FailedSection from '../../../../components/LoadingComponents/FailedSection';
import { StatisticLabel } from './StatisticLabel';
import { PercentChange } from './PercentChange';

import '../../styles/eventAnalytics.css';

class HeaderBookings extends React.Component {
  render() {
    const {
      numberOfBookingsPercentChange,
      numberOfBookings,
      analyticsEventLoading,
      analyticsEventFailed,
    } = this.props;
    return (
      <div className="headerItem">

        <AlternativelyVisible condition={analyticsEventLoading}>
          <LoadingSkeletonThreeDots />
          <AlternativelyVisible condition={analyticsEventFailed}>
            <FailedSection />
            <>
              <div className="statistic">
                <StatisticLabel labelString="Total Bookings" />
                <div className="statisticContent">
                  <NumberFormat displayType="text" thousandSeparator value={numberOfBookings.toFixed(0)} />
                  <PercentChange percentChange={numberOfBookingsPercentChange} />
                </div>
              </div>
            </>
          </AlternativelyVisible>
        </AlternativelyVisible>

      </div>
    );
  }
}

HeaderBookings.propTypes = {
  numberOfBookingsPercentChange: PropTypes.string.isRequired,
  numberOfBookings: PropTypes.string.isRequired,
  analyticsEventLoading: PropTypes.bool.isRequired,
  analyticsEventFailed: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  numberOfBookings: state.analytics.analyticsEventBookings.numberOfBookings,
  numberOfBookingsPercentChange:
    state.analytics.analyticsEventBookings.numberOfBookingsPercentChange,
  analyticsEventLoading: state.analytics.analyticsEventLoading,
  analyticsEventFailed: state.analytics.analyticsEventFailed,
});

export default connect(mapStateToProps)(HeaderBookings);
