import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CentralisedContainer from '@pitchbooking-dev/pb-shared/lib/components/common/CentralisedContainer';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import { toggleSwapBookingDialog, swapBookingsRequested } from '../../../reducers/bookingsReducer';

const SwapBookingsDialog = ({ isMobile, options }) => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.bookings.swapBookingDialogOpen);
  const selectedBookings = useSelector((state) => state.bookings.selectedRows);

  return (
    <div>
      <Button
        variant="outlined"
        color="secondary"
        style={{
          marginRight: '10px',
          marginTop: '10px',
          width: 175,
        }}
        onClick={() => dispatch(toggleSwapBookingDialog())}
      >
        Swap Bookings
      </Button>
      <Dialog open={open} fullScreen={isMobile} maxWidth="md">
        <DialogActions variant="none">
          <CloseDialogIcon onClick={() => dispatch(toggleSwapBookingDialog())} />
        </DialogActions>
        <DialogContent>
          <CentralisedContainer>
            <Typography variant="h5">Swap Bookings</Typography>
            <div className="pb-flex pb-space-between pb-gap-2 pb-mt-4 pb-mb-4">
              {selectedBookings.map((booking, index) => (
                <div key={booking.id}>
                  <Typography variant="h6">{`Booking ${index + 1}`}</Typography>
                  <div>
                    <div className="pb-font-size-2 pb-text-muted pb-font-semibold">Booker</div>
                    {booking.fullName}
                  </div>
                  <div>
                    <div className="pb-font-size-2 pb-text-muted pb-font-semibold">Facility</div>
                    {booking.facilityName}
                  </div>
                  <div>
                    <div className="pb-font-size-2 pb-text-muted pb-font-semibold">Date & Time</div>
                    {`${moment(booking.startTime).format('DD-MM-YYYY, HH:mm')} - ${moment(booking.endTime).add(1, 'second').format('HH:mm')}`}
                  </div>
                  <div>
                    <div className="pb-font-size-2 pb-text-muted pb-font-semibold">Price</div>
                    {booking.total.toFixed(2)}
                  </div>
                  <div>
                    <div className="pb-font-size-2 pb-text-muted pb-font-semibold">Payment Status</div>
                    {booking.paymentStatus}
                  </div>
                </div>
              ))}
            </div>

            <div style={{
              padding: '1rem',
              marginBottom: '1rem',
              backgroundColor: 'rgba(0,0,0,0.05)',
              border: '1px rgba(0,0,0,0.4) solid',
              borderRadius: '0.5rem',
            }}
            >
              <Typography variant="p">Before you continue please consider the following issues:</Typography>
              <ul>
                <li>The start and end time of both bookings may not match.</li>
                <li>The total price of both bookings do not equate.</li>
                <li>Payment statuses may not be the same.</li>
              </ul>
            </div>

            <Button
              variant="contained"
              color="primary"
              onClick={() => dispatch(swapBookingsRequested(selectedBookings, options))}
            >
              Swap Bookings
            </Button>

          </CentralisedContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};

SwapBookingsDialog.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  options: PropTypes.shape({}).isRequired,
};

export default SwapBookingsDialog;
