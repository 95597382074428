import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function getMembershipsService(companyId) {
  const url = `/api/companies/${companyId}/memberships`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function getAccessRestrictionsService(companyId, showLiveOnly = true, arType) {
  const url = `/api/companies/${companyId}/accessRestrictions?showLiveOnly=${showLiveOnly}&type=${arType}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function createAccessRestrictionsService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/accessRestrictions`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function updateAccessRestrictionService(accessRestrictionId, reqBody, companyId) {
  const url = `/api/companies/${companyId}/accessRestrictions/${accessRestrictionId}`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function getMembershipUsersService(membershipId) {
  const url = `/api/memberships/${membershipId}/users`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function inviteUserToMembershipService(membershipId, reqBody) {
  const url = `/api/memberships/${membershipId}/inviteUser`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function addUsersToMembershipService(membershipId, reqBody) {
  const url = `/api/memberships/${membershipId}/users`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function removeUserFromMembershipService(membershipId, reqBody) {
  const url = `/api/memberships/${membershipId}/removeUser`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function updateMembershipServices(membershipId, reqBody) {
  const url = `/api/memberships/${membershipId}`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}
