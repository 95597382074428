export const SET_COMPANY_TAGS = 'SET_COMPANY_TAGS';
export const ADD_TO_SELECTED_TAGS = 'ADD_TO_SELECTED_TAGS';
export const REMOVE_FROM_SELECTED_TAGS = 'REMOVE_FROM_SELECTED_TAGS';
export const UPDATE_EVENT_TAGS = 'UPDATE_EVENT_TAGS';
export const UPDATE_SELECTED_TAGS = 'UPDATE_SELECTED_TAGS';
export const UPDATE_LEAGUE_TAGS = 'UPDATE_LEAGUE_TAGS';

export const initialState = {
  companyTags: [],
  selectedTags: [],
};

export const setCompanyTags = (tags) => (
  { type: SET_COMPANY_TAGS, tags }

);
export const addToSelectedTags = (tag) => (
  { type: ADD_TO_SELECTED_TAGS, tag }
);
export const removeFromSelectedTags = (tag) => (
  { type: REMOVE_FROM_SELECTED_TAGS, tag }
);
export const updateEventTags = (eventId) => (
  { type: UPDATE_EVENT_TAGS, eventId }
);
export const updateLeagueTags = (leagueId) => (
  { type: UPDATE_LEAGUE_TAGS, leagueId }
);

export const updateSelectedTags = (tags) => (
  { type: UPDATE_SELECTED_TAGS, tags }
);
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_TAGS:
      return {
        ...state,
        companyTags: action.tags,
      };
    case ADD_TO_SELECTED_TAGS:
      state.selectedTags.push(action.tag);
      return { ...state, selectedTags: [...state.selectedTags] };
    case REMOVE_FROM_SELECTED_TAGS:
      // eslint-disable-next-line no-case-declarations
      const index = state.selectedTags.indexOf(action.tag);
      if (index > -1) {
        state.selectedTags.splice(index, 1);
      }
      return { ...state, selectedTags: [...state.selectedTags] };
    case UPDATE_SELECTED_TAGS:
      return { ...state, selectedTags: action.tags };
    default:
      return state;
  }
};
