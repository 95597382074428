import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import DescriptionIcon from '@material-ui/icons/Description';
import moment from 'moment';
import GeneratePayoutCSVData from '@pitchbooking-dev/pb-shared/lib/helpers/GeneratePayoutCSVData';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import * as actions from '../../reducers/invoicesReducer';
import DownloadCSVButton from '../../components/DownloadCSVButton';

import PayoutReconciliationTable from './payoutReconciliationTable';

class PayoutReconciliationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    const isMobile = window.innerWidth < 768;
    const { payout, currencySym } = this.props;
    const { open } = this.state;
    const fieldsAndData = GeneratePayoutCSVData(payout);
    const csvFilename = `PB-Stripe-${moment(payout.arrivalDate).format('YYYY-MM-DD')}-${payout.stripeId}.csv`;

    return (
      <div>
        <Button
          onClick={() => {
            this.setState({ open: true });
          }}
          variant="outlined"
        >
          <DescriptionIcon />
        </Button>
        <Dialog
          open={open}
          fullScreen={isMobile}
          fullWidth
          maxWidth="sm"
          onClose={() => this.setState({ open: false })}
        >

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>{`Payout: ${payout.stripeId}`}</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <DialogContent>
            <PayoutReconciliationTable payout={payout} currencySym={currencySym} />
          </DialogContent>
          <DialogActions variant="none">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <DownloadCSVButton fieldsAndData={fieldsAndData} filename={csvFilename} />
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

PayoutReconciliationDialog.propTypes = {
  payout: PropTypes.shape().isRequired,
  currencySym: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  currencySym: state.companies.companyInfo.currencySym,
});

const mapDispatchToProps = (dispatch) => ({
  resetInvoiceInformationStore: () => dispatch(actions.resetInvoiceInformationStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayoutReconciliationDialog);
