import React from 'react';
import AccountWrapper from '@pitchbooking-dev/pb-shared/lib/components/Account/AccountWrapper';

function Profile() {
  return (
    <>
      <div style={{
        marginLeft: '40px', marginTop: '40px', marginBottom: '20px', fontSize: '22px', fontWeight: 900,
      }}
      >
        User profile
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '40px',
        marginBottom: '40px',
      }}
      >
        <AccountWrapper />
      </div>
    </>
  );
}

export default (Profile);
