import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function getCompanyAddonsService(
  companyId, facilityId, eventId, includeSubAddons = false,
) {
  let url = `/api/companies/${companyId}/addons`;
  if (facilityId) { url = `${url}?facilityId=${facilityId}`; }
  if (eventId) { url = `${url}?eventId=${eventId}`; }
  if (includeSubAddons) { url = `${url}?includeSubAddons=${includeSubAddons}`; }
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}
export async function createCompanyAddonsService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/addons`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
export async function getPOSReservationsService(companyId, reportType = 'ORDER_HISTORY', startDate, endDate) {
  let url = `/api/companies/${companyId}/pos-reservations?reportType=${reportType}`;
  if (startDate && endDate) {
    url = `${url}&startDate=${startDate}&endDate=${endDate}`;
  }
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function createCompanyAddonsReservationService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/addons/reservations`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function getAddonsService(companyId) {
  const url = `/api/companies/${companyId}/addons`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function createStockTransactionService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/stock-transactions`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function getAddonService(companyId, addonId) {
  const url = `/api/companies/${companyId}/addons/${addonId}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function createFacilityAddonsService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/facility-addons`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
export async function deleteFacilityAddonsService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/delete-facility-addons`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
export async function requestAddonUpdateService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/addon`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
export async function deleteAddonsService(companyId, addonId) {
  const url = `/api/companies/${companyId}/addons/${addonId}`;
  const response = await httpHelpers.authenticatedDeleteRequest(url);
  return response;
}

export async function createCompanyCategoryService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/categories`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function deleteCategoryService(companyId, categoryId) {
  const url = `/api/companies/${companyId}/categories/${categoryId}`;
  const response = await httpHelpers.authenticatedDeleteRequest(url);
  return response;
}
