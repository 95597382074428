const config = {
  type: 'horizontalBar',
  options: {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
        },
        scaleLabel: {
          display: true,
          labelString: '',
        },
        ticks: {
          beginAtZero: true,
        },
      }],
      yAxes: [{
        gridLines: {
          borderDash: [10, 10],
        },
        scaleLabel: {
          display: true,
        },
      }],
    },
    tooltips: {
      backgroundColor: '#FFFFFF',
      bodyAlign: 'left',
      bodyFontColor: '#000000',
      bodyFontSize: 20,
      enabled: true,
      titleFontColor: '#00000060',
    },
    maintainAspectRatio: false,
  },
};

export default config;
