import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox, Typography, TextField, InputAdornment, FormControlLabel,
} from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import { DayPickerSingleDateController } from 'react-dates';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import * as bookingModifiersActions from '../../reducers/bookingModifiersReducer';
import DayPicker from '../accessRestrictions/components/DayPicker';

const PricingAddRuleDialogSection = ({
  parameter, value, defaultValue, onUpdate, ...rest
}) => {
  const {
    title, membership,
  } = rest;
  const dispatch = useDispatch();
  const {
    ageGroupBookingModifiers,
    eventBookingModifiers,
  } = useSelector((state) => state.bookingModifiers);
  const { memberships } = useSelector((state) => state.companies);
  const priceModifier = useSelector((state) => state.pricing.priceModifier);
  const [active, setActive] = useState(value || defaultValue);
  const [inputValue, setInputValue] = useState('');
  const currency = useSelector((state) => state.companies.companyInfo.currency);
  const currencySymbol = getCurrencySymbol(currency);

  const getAllCompanyBookingModifiers = () => dispatch(
    bookingModifiersActions.getAllCompanyBookingModifiers(),
  );

  const toggleParameter = () => {
    if (active) {
      onUpdate({ [parameter]: null });
      if (parameter === 'membership') {
        onUpdate({ membershipId: null, editMembership: true });
      }
    } else if (parameter === 'membership') {
      onUpdate({ editMembership: true });
    } else if (parameter === 'floodlights') {
      onUpdate({
        floodlights: {
          price: 0,
          offset: 0,
          prorata: false,
        },
      });
    } else if (parameter === 'day') {
      onUpdate({ day: 1, weekday: 1 });
    }
    setActive(!active);
  };

  const parseMemberships = (memberships) => memberships.map((membership) => {
    const label = `${membership.name}`;
    return ({
      ...membership,
      label,
    });
  });

  useEffect(() => {
    if (parameter === 'ageGroupBookingModifierId' || parameter === 'eventBookingModifierId') {
      getAllCompanyBookingModifiers();
    }
  }, []);

  useEffect(() => {
    if (parameter === 'ageGroupBookingModifierId') {
      setInputValue(ageGroupBookingModifiers.find((x) => x.id === value)?.name || '');
    }

    if (parameter === 'eventBookingModifierId') {
      setInputValue(eventBookingModifiers.find((x) => x.id === value)?.name || '');
    }
  }, [parameter, ageGroupBookingModifiers, eventBookingModifiers]);

  const pm = useSelector((state) => state.pricing.priceModifier);
  const calculateFractionalPrice = () => {
    if (pm.value && pm.subFacility_quantity) {
      return (
        <Typography variant="body2" style={{ color: 'rgba(0,0,0,0.4)' }}>
          {`Total price per unit: ${currencySymbol}${(pm.value).toFixed(2)}`}
        </Typography>
      );
    }

    if (pm.value || pm.subFacility_quantity) {
      return (
        <Typography variant="body2" style={{ color: 'rgba(0,0,0,0.4)' }}>
          Please enter both the Fractional Quantity here and the Amount below in order to
          calculate the price per unit.
        </Typography>
      );
    }

    return null;
  };

  const renderParameter = () => {
    switch (parameter) {
      case 'startTime':
      case 'endTime':
        return (
          <TimePicker
            defaultValue={moment(defaultValue)}
            value={value ? moment(value) : value}
            showSecond={false}
            minuteStep={5}
            clearIcon={null}
            allowEmpty
            onChange={(x) => onUpdate({
              [parameter]: moment(x).startOf('minute').format('YYYY-MM-DD HH:mm'),
            })}
          />
        );
      case 'day':
        return (
          <DayPicker
            choosenWeekday={priceModifier.days || value}
            onUpdate={(e) => onUpdate({ days: e.days })}
            slim
            isMultiple
          />
        );
      case 'duration':
        return (
          <TextField
            defaultValue={defaultValue}
            value={value}
            margin="normal"
            variant="outlined"
            type="number"
            onChange={(e) => onUpdate(
              { [parameter]: Math.abs(e.target.value) },
            )}
            inputProps={{ min: '0', step: '0.25' }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              endAdornment: <InputAdornment position="end">hours</InputAdornment>,
            }}
          />
        );
      case 'subFacility_quantity':
        return (
          <>
            <TextField
              defaultValue={defaultValue}
              value={value}
              margin="normal"
              variant="outlined"
              type="number"
              onChange={(e) => {
                onUpdate(
                  { [parameter]: Math.round(Math.abs(e.target.value)) },
                );
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">units</InputAdornment>,
              }}
            />
            {calculateFractionalPrice()}
          </>

        );
      case 'quantity':
        return (
          <TextField
            value={value}
            margin="normal"
            variant="outlined"
            type="number"
            onChange={(e) => onUpdate(
              { [parameter]: Math.round(Math.abs(e.target.value)) },
            )}
            InputProps={{
              endAdornment: <InputAdornment position="end">bookings</InputAdornment>,
            }}
          />
        );
      case 'bookedAsAmenity':
        return (
          <FormControlLabel
            control={(
              <Checkbox
                checked={value}
                onClick={() => onUpdate({ [parameter]: !value })}
                tabIndex={-1}
                disableRipple
              />
            )}
            label="Only apply when being added as an amenity."
          />
        );
      case 'ageGroupBookingModifierId':
        return (
          <Autocomplete
            disableClearable
            value={ageGroupBookingModifiers.find((bm) => bm.id === value) ?? ''}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onChange={(event, option) => onUpdate({ [parameter]: option.id })}
            options={ageGroupBookingModifiers}
            renderInput={(params) => <TextField {...params} label="Select Property" variant="outlined" fullWidth />}
            getOptionLabel={(option) => option.name}
          />
        );
      case 'eventBookingModifierId':
        return (
          <Autocomplete
            disableClearable
            value={eventBookingModifiers.find((bm) => bm.id === value) ?? ''}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onChange={(event, option) => onUpdate({ [parameter]: option.id })}
            options={eventBookingModifiers}
            renderInput={(params) => (
              <TextField {...params} label="Select Property" variant="outlined" fullWidth />
            )}
            getOptionLabel={(option) => option.name}
          />
        );
      case 'membership':
        return (
          <Autocomplete
            className="auto-complete-combo"
            options={parseMemberships(memberships)}
            getOptionLabel={(option) => option.label}
            value={membership && parseMemberships([membership])[0]}
            onChange={(event, option) => onUpdate(
              { membership: option, membershipId: option.id },
            )}
            disableClearable
            renderInput={(params) => (
              <TextField {...params} label="Select Membership" variant="outlined" fullWidth />
            )}
          />
        );
      case 'startDate':
        return (
          <DayPickerSingleDateController
            initialVisibleMonth={() => (value !== null ? moment(value) : moment())}
            focused
            onDateChange={(date) => onUpdate({ [parameter]: date.startOf('day').format() })}
            date={value !== null ? moment(value) : undefined}
            noBorder
          />
        );
      case 'endDate':
        return (
          <DayPickerSingleDateController
            initialVisibleMonth={() => (value !== null ? moment(value) : moment())}
            focused
            onDateChange={(date) => onUpdate({ [parameter]: date.endOf('day').format() })}
            date={value !== null ? moment(value) : undefined}
            noBorder
          />
        );
      case 'floodlights':
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <PBInput
              id="price"
              label="Price"
              type="number"
              value={value?.price}
              onChange={(e) => {
                onUpdate({
                  floodlights: {
                    ...value,
                    price: e.target.valueAsNumber,
                  },
                });
              }}
            />
            <PBInput
              id="offset"
              label="Offset (minutes)"
              value={value?.offset}
              onChange={(e) => {
                onUpdate({
                  floodlights: {
                    ...value,
                    offset: Number(e.target.value),
                  },
                });
              }}
            />

            <div>
              <PBInput
                id="prorata"
                label="Do you want to use pro-rata based pricing?"
                type="toggle"
                value={value?.prorata}
                onChange={(e) => {
                  onUpdate({
                    floodlights: {
                      ...value,
                      prorata: e.target.checked,
                    },
                  });
                }}
              />
              {value?.prorata && (
              <Typography variant="body2" style={{ color: 'rgba(0,0,0,0.4)' }}>
                Pro-rata based pricing means the customer will only pay for
                the portion of their slot that falls after sunset or before sunrise
              </Typography>
              )}
            </div>
          </div>
        );
      default:
        return 'Not available';
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
    }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
      }}
      >
        <Checkbox
          checked={active}
          onClick={() => toggleParameter()}
          tabIndex={-1}
          disableRipple
          iconStyle={{ color: (active ? 'inherit' : 'textSecondary') }}
        />
        <div style={{ width: '20px' }} />
        <Typography variant="subtitle1" color={active ? 'inherit' : 'textSecondary'}>
          {title}
        </Typography>
      </div>
      <ConditionallyVisible condition={active}>
        <div style={{
          marginLeft: '65px',
          paddingBottom: '20px',
        }}
        >
          {renderParameter()}
        </div>
      </ConditionallyVisible>
    </div>
  );
};

PricingAddRuleDialogSection.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
  parameter: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  membership: PropTypes.shape().isRequired,
};

PricingAddRuleDialogSection.defaultProps = {
  value: null,
};

export default PricingAddRuleDialogSection;
