export const parkingOptions = [
  { value: 'free', label: 'Free' },
  { value: 'paid', label: 'Paid' },
  { value: 'none', label: 'None' },
];

export const additonalOptionsShape = {
  changingFacilities: 'Changing Facilities',
  showers: 'Showers',
  toilets: 'Toilets',
  lockers: 'Lockers',
  parking: 'Parking',
  bar: 'Bar',
  wifi: 'Wifi',
  wheelchairAccessible: 'Wheelchair Accessible',
  paraSport: 'Para Sport',
  bikeRack: 'Bike Rack',
  birthdayParties: 'Birthday Parties',
  events: 'Events',
  leagues: 'Leagues',
  cashlessFacility: 'Cashless Facility',
  blockBookings: 'Block Bookings',
  meetingRooms: 'Meeting Rooms',
  floodlights: 'Floodlights',
  indoorStudio: 'Indoor Studio',
  pavilion: 'Pavilion',
  bubbleFootball: 'Bubble Football',
  cafe: 'Cafe',
  gym: 'Gym',
  keyCodeAccess: 'Key Code Access',
};
export const additonalOptions = Object.values(additonalOptionsShape);

export const surfaceTypeShape = {
  '2G': '2G',
  '3G': '3G',
  '4G': '4G',
  fifaPro: 'FIFA Pro',
  astroWater: 'Astro Water',
  astroSand: 'Astro Sand',
  grass: 'Grass',
  sportsHall: 'Sports Hall',
  indoor: 'Indoor',
};
export const surfaceTypes = Object.values(surfaceTypeShape);

export const sportShape = {
  americanFootball: 'American Football',
  athletics: 'Athletics',
  badminton: 'Badminton',
  baseball: 'Baseball',
  basketball: 'Basketball',
  bowling: 'Bowling',
  boxing: 'Boxing',
  camogie: 'Camogie',
  cricket: 'Cricket',
  croquet: 'Croquet',
  curling: 'Curling',
  dance: 'Dance',
  football: 'Football',
  gaelicFootball: 'Gaelic Football',
  golf: 'Golf',
  gymnastics: 'Gymnastics',
  hockey: 'Hockey',
  hurling: 'Hurling',
  lacrosse: 'Lacrosse',
  martialArts: 'Martial Arts',
  netball: 'Netball',
  padel: 'Padel',
  pickleball: 'Pickleball',
  paraSport: 'Para Sport',
  rugby: 'Rugby',
  softball: 'Softball',
  squash: 'Squash',
  swimming: 'Swimming',
  tableTennis: 'Table Tennis',
  tennis: 'Tennis',
  volleyball: 'Volleyball',
  waterPolo: 'Water Polo',
  yoga: 'Yoga',
};
export const sports = Object.values(sportShape);

export const footballShape = {
  '5aside': '5-a-side',
  '7aside': '7-a-side',
  '9aside': '9-a-side',
  fullSize: 'Full Size',
  futsal: 'Futsal',
};
export const football = Object.values(footballShape);
