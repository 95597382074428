import React from 'react';
import { Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import styles from './unlock.module.css';

export const Unlock = () => (
  <div className={styles.container}>
    <div className={styles.card}>
      <LockIcon fontSize="large" style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h5">This feature is locked.</Typography>
        <Typography>
          If you would like to learn more about this feature please get in contact
          with us and we can explain how you could benefit from this feature.
        </Typography>
      </div>
    </div>
  </div>
);
