import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function MultipleSelect(props) {
  const classes = useStyles();
  const {
    propValue, arrayValue, defaultValue, label,
  } = props;

  const handleChange = (event) => {
    const { onChange } = props;
    onChange(event.target.value);
  };
  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>{label}</InputLabel>
        <Select
          labelId="mutiple-checkbox-label"
          id="mutiple-checkbox"
          label={label}
          multiple
          value={propValue}
          defaultValue={defaultValue}
          onChange={handleChange}
          renderValue={(selected) => (selected.length > 0 ? `${selected.length} Selected` : 'All')}
        >
          {arrayValue.map((value) => (
            <MenuItem key={value} value={value}>
              <Checkbox checked={propValue.indexOf(value) > -1} />
              <ListItemText primary={value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

MultipleSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  propValue: PropTypes.shape().isRequired,
  arrayValue: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.shape().isRequired,
};
