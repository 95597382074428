import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import Button from '@material-ui/core/Button';
import * as actions from '../../../../reducers/accessRestrictionsReducer';
import accessRestrictionIsValidSelector from './selectors/accessRestrictionValiditySelector';

const AccessRestrictionsDialogActions = ({
  handleSave,
  createAccessRestriction,
  handleRequestClose,
  editingAccessRestriction,
  accessRestrictionIsValidity,
  accessRestrictionId,
}) => (
  <>
    <span style={{ color: 'red' }}>{accessRestrictionIsValidity.errorMessage}</span>
    <Button onClick={handleRequestClose} variant="outlined" color="secondary">
      Cancel
    </Button>
    <AlternativelyVisible condition={editingAccessRestriction}>
      <Button disabled={!accessRestrictionIsValidity.isValid} onClick={() => handleSave(accessRestrictionId)} variant="contained" color="primary">
        Save
      </Button>
      <Button disabled={!accessRestrictionIsValidity.isValid} onClick={createAccessRestriction} variant="contained" color="primary">
        Create
      </Button>
    </AlternativelyVisible>
  </>
);

AccessRestrictionsDialogActions.propTypes = {
  accessRestrictionId: PropTypes.string,
  editingAccessRestriction: PropTypes.bool.isRequired,
  accessRestrictionIsValidity: PropTypes.shape().isRequired,
  handleSave: PropTypes.func.isRequired,
  createAccessRestriction: PropTypes.func.isRequired,
  handleRequestClose: PropTypes.func.isRequired,
};

AccessRestrictionsDialogActions.defaultProps = {
  accessRestrictionId: null,
};

const mapStateToProps = (state) => ({
  accessRestrictionId: state.accessRestrictions.newAccessRestriction.id,
  editingAccessRestriction: state.accessRestrictions.editingAccessRestriction,
  accessRestrictionIsValidity: accessRestrictionIsValidSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateAccessRestrictionCreation: (keyWithValue) => dispatch(
    actions.updateAccessRestrictionCreation(keyWithValue),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessRestrictionsDialogActions);
