import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Tabs,
  Tab,
  Typography,
  Button,
} from '@material-ui/core';
import moment from 'moment';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { useDispatch, useSelector } from 'react-redux';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import EditCurrentBooking from './EditCurrentBooking';
import ChangeAllocation from './ChangeAllocation';
import { requestFacilityRetrieval, requestFacilitiesRetrieval } from '../../reducers/facilitiesReducer';
import { updateBookingsEditSuccess, resetSelectedRows, updateBookingEditFailed } from '../../reducers/bookingsReducer';

// Modals open trigger is when allocation is set
const FacilityUpdateDialog = ({
  allocation,
  onChange,
  onClose,
}) => {
  const dispatch = useDispatch();

  const editSuccess = useSelector((state) => state.bookings.editSuccess);
  const editFailed = useSelector((state) => state.bookings.editFailed);
  const [tab, setTab] = useState(0);
  const [activeAllocation, setActiveAllocation] = useState(allocation);

  const activeFacility = useMemo(() => {
    if (!allocation) return null;
    if (allocation.allocations && allocation.allocations.length > 0) {
      return allocation.allocations.find((x) => x.status === 'ACTIVE' && x.type === 'FACILITY' && !x.forcedBooking)?.facility;
    }
    return allocation.facility;
  }, [activeAllocation]);

  const handleClose = () => {
    onClose();
    setActiveAllocation(null);
    dispatch(updateBookingsEditSuccess(null));
    dispatch(updateBookingEditFailed(false));
    dispatch(resetSelectedRows());
  };

  useEffect(() => {
    if (activeFacility) {
      dispatch(
        requestFacilityRetrieval(
          activeFacility.parentFacilityId ? activeFacility.parentFacilityId : activeFacility?.id,
        ),
      );
    }
  }, [activeFacility]);

  useEffect(() => {
    dispatch(
      requestFacilitiesRetrieval(),
    );
  }, []);

  useEffect(() => {
    if (allocation) {
      setActiveAllocation(allocation);
    }
  }, [allocation]);

  return (
    <Dialog
      open={activeAllocation}
      onRequestClose={handleClose}
      fullScreen
      disableEnforceFocus
      style={{
        padding: '2rem',
      }}
    >
      <DialogActions variant="none">
        <CloseDialogIcon onClick={handleClose} />
      </DialogActions>
      <DialogContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        {editFailed && (
          <Typography variant="h5" color="error">
            Edit Booking Failed
          </Typography>
        )}
        {editSuccess ? (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
              <Typography variant="h5">
                {editSuccess.message}
              </Typography>

              <div>
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  Original booking
                </Typography>

                <div>
                  <Typography variant="h5">{activeAllocation.facilityName}</Typography>
                  <Typography variant="h5">
                    {`
                      ${moment(activeAllocation.startTime).format('DD/MM/YYYY')}, 
                      ${moment(activeAllocation.startTime).format('HH:mm')} - ${moment(activeAllocation.endTime).add(1, 's').format('HH:mm')}
                    `}
                  </Typography>
                </div>
              </div>

              <div>
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  Editted Booking
                </Typography>

                <div>
                  <Typography variant="h5">{editSuccess.facility}</Typography>
                  <Typography variant="h5">
                    {`
                      ${editSuccess.date}, 
                      ${editSuccess.startTime} - ${editSuccess.endTime}
                    `}
                  </Typography>
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </>
        ) : (
          <>
            {activeAllocation && (
            <div className="current_allocations">
              <div>
                <Typography variant="h5">
                  {activeFacility?.name}
                </Typography>
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  {`${moment(activeAllocation.startTime).format('DD/MM/YYYY HH:mm')} - ${moment(activeAllocation.endTime).add(1, 'second').format('HH:mm')}`}
                </Typography>
              </div>
            </div>
            )}

            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Tabs value={tab} onChange={(e, index) => setTab(index)}>
                <Tab label="Edit Date/Time" />
                <Tab label="Change Facility" />
              </Tabs>

              <>
                <ConditionallyVisible condition={tab === 0}>
                  <EditCurrentBooking
                    facility={activeFacility}
                    updateFacility={onChange}
                    date={moment(allocation?.startTime).format()}
                    allocation={allocation}
                  />
                </ConditionallyVisible>

                <ConditionallyVisible condition={tab === 1}>
                  <ChangeAllocation
                    updateFacility={onChange}
                  />
                </ConditionallyVisible>
              </>

              {editFailed && (
                <div style={{ textAlign: 'right' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </div>
              )}
            </div>
          </>
        )}

      </DialogContent>
    </Dialog>
  );
};

FacilityUpdateDialog.propTypes = {
  allocation: PropTypes.shape.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FacilityUpdateDialog;
