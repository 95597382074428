import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import * as facilitiesActions from '../../../../reducers/facilitiesReducer';
import {
  parkingOptions,
  additonalOptions,
} from './FacilityAttributeValues';
import SimpleMultipleSelect from '../../../../components/SimpleMultipleSelect';
import { useOrigin, usePendingChanges } from '../../../../hooks';

const FacilityAttributesConfiguration = () => {
  const dispatch = useDispatch();
  const pendingChanges = usePendingChanges();

  const facilities = useSelector((state) => state.facilities);
  const { selectedFacility } = facilities;
  const { attributes } = selectedFacility;
  const { bookerUrl } = useOrigin();

  const updateFacility = async (id, values) => {
    dispatch(facilitiesActions.requestSelectedFacilityUpdate(id, values));
    dispatch(sessionActions.updateSuccessfulSnackbar('Your info has been updated successfully!', true));
  };

  const resetDetailsForm = (resetForm) => {
    resetForm();
  };

  return (
    <>
      {/* If the user has pending changes */}
      {Object.keys(pendingChanges ?? {}).length > 0 && (
      <div style={{
        backgroundColor: '#efefef', padding: '1rem', borderRadius: '10px', marginBottom: '1rem',
      }}
      >
        <Typography variant="h5">Pending Changes</Typography>
        <Typography variant="body2">
          The changes you have made to this facility will not be visible to the public until your
          application to claim the facility has been approved.
        </Typography>
      </div>
      )}
      <Formik
        initialValues={{
          attributes: {
            parking: pendingChanges?.attributes?.parking || attributes?.parking || '',
            additionalOptions: pendingChanges?.attributes?.additionalOptions
            || attributes?.additionalOptions || [],
          },
        }}
        enableReinitialize
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const form = {
            ...values,
          };
          setSubmitting(true);
          try {
            updateFacility(selectedFacility.id, form);
            resetForm();
          } catch (err) {
            dispatch(sessionActions.updateErrorSnackbar(
              'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us at info@pitchbooking.com.',
              true,
            ));
          }
        }}
      >
        {({
          values, errors, touched, handleSubmit, resetForm, setFieldValue,
        }) => (
          <form
            onSubmit={handleSubmit}
            onReset={resetForm}
            style={{
              display: 'flex', flexDirection: 'column',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {/* Title */}
              <div>
                <Typography variant="h6" style={{ marginBottom: '1rem' }}>Facility Attributes</Typography>
              </div>

              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {/* Parking */}
                    <div style={{ flex: 1, margin: '1rem' }}>
                      <Typography variant="subtitle1" style={{ marginBottom: '1rem' }}>Parking</Typography>
                      <PBInput
                        id="parking"
                        label="Parking"
                        type="select"
                        options={parkingOptions}
                        value={values.attributes.parking}
                        onChange={(e) => setFieldValue('attributes.parking', e.target.value)}
                        errorMessage={touched.parking && errors.parking}
                        isError={touched.parking && Boolean(errors.parking)}
                      />
                    </div>

                    {/* Addons  */}
                    <div style={{ flex: 1, margin: '1rem' }}>
                      <Typography variant="subtitle1" style={{ marginBottom: '1rem' }}>Addons</Typography>
                      <SimpleMultipleSelect
                        propValue={values.attributes.additionalOptions}
                        arrayValue={additonalOptions}
                        label="Additonal Options"
                        onChange={(e) => setFieldValue('attributes.additionalOptions', e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* From actions */}
            <div style={{
              display: 'flex', justifyContent: 'flex-start', marginTop: '3rem', gap: '1rem',
            }}
            >
              <Button variant="contained" color="primary" type="submit" style={{ width: '200px' }}>Save</Button>
              <Button variant="contained" onClick={() => resetDetailsForm(resetForm)} style={{ width: '200px' }}>Cancel</Button>
              <a
                href={`${bookerUrl}/book/facility/${selectedFacility.id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <Button variant="contained" color="secondary" style={{ width: '200px' }}>View Booking Page</Button>
              </a>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default FacilityAttributesConfiguration;
