import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../../reducers/accessRestrictionsReducer';
import DayPicker from '../DayPicker';

const AccessRestrictionsDayPicker = ({ accessRestriction, updateAccessRestrictionCreation }) => (
  <>
    <DayPicker
      choosenWeekday={accessRestriction.weekday}
      onUpdate={(e) => updateAccessRestrictionCreation({ weekday: e.weekday })}
      value={accessRestriction.weekday}
    />
  </>
);

AccessRestrictionsDayPicker.propTypes = {
  accessRestriction: PropTypes.shape().isRequired,
  updateAccessRestrictionCreation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  accessRestriction: state.accessRestrictions.newAccessRestriction,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccessRestrictionCreation: (keyWithValue) => dispatch(
    actions.updateAccessRestrictionCreation(keyWithValue),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessRestrictionsDayPicker);
