import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import GroupIcon from '@material-ui/icons/Group';

const UserInformationCard = ({
  user,
}) => {
  const {
    firstName, lastName, email, phone, teamName, dialCode,
  } = user;
  return (
    <div>
      <Card className="user-info-card">
        <CardContent>
          <div>
            <AccountCircleIcon style={{
              fill: '#24dc89',
              fontSize: '5rem',
              alignSelf: 'center',
            }}
            />
            <div>
              <h2>
                {firstName}
                {' '}
                {lastName}
              </h2>
            </div>
            <hr />

          </div>
          <br />
          <div className="user-info-card-teamname-phone">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <GroupIcon style={{ fill: '#24dc89', marginRight: '10PX' }} />
              <Typography color="textPrimary" component="h2" gutterBottom>
                {teamName && teamName.length > 0 ? teamName : 'Not supplied'}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <EmailIcon style={{ fill: '#24dc89', marginRight: '10PX' }} />
              <Typography color="textPrimary" component="h2" gutterBottom>
                {email && email.length > 0 ? email : 'Not supplied'}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <PhoneIcon style={{ fill: '#24dc89', marginRight: '10PX' }} />
              <Typography color="textPrimary" component="h2" gutterBottom>
                {phone && phone.length > 0 ? `+${dialCode}${phone}` : 'Not supplied'}
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

UserInformationCard.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    teamName: PropTypes.string,
    phone: PropTypes.number,
    dialCode: PropTypes.number.isRequired,
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  user: ownProps.user,
  subscriptionUser: state.subscriptions.userDetailsForSubscription,
});

export default connect(mapStateToProps, null)(UserInformationCard);
