import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  Paper,
} from '@material-ui/core';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import { getBooking, resetSelectedRows } from '../../reducers/bookingsReducer';
import MultipleCancelRefundDialog from '../bookings/components/MultipleCancelRefundDialog';
import { recordMultipleReservationPayment, cancelReservations } from '../../reducers/reservationsReducer';
import RecordPaymentDialog from '../bookings/components/RecordPaymentDialog';

function PosPayment({ match }) {
  const dispatch = useDispatch();
  const { reservationId } = match.params;
  const reservation = useSelector((state) => state.bookings.reservation);
  const currency = useSelector((state) => state.companies.companyInfo.currency);
  const cancelReservationsState = useSelector((state) => state.reservation.cancelReservations);
  const stripeOrder = reservation?.orders?.find((order) => order.paymentMethod === 'STRIPE');
  const { company } = reservation;
  const requestMultipleCancel = () => {
    const singularBooking = true;
    let bookingArray = [reservation];

    if (bookingArray[0].type !== 'SUBSCRIPTION') {
      bookingArray = bookingArray.map((row) => row.id);
    }
    dispatch(cancelReservations([reservationId], cancelReservationsState, true, singularBooking));
  };
  useEffect(() => {
    dispatch(getBooking(reservationId));
  }, []);
  return (
    <div>
      <Paper
        style={{
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          overflowX: 'hidden',
          width: 'fit-content',
          margin: 10,
          padding: 10,
        }}
        component={Paper}
      >
        <Button variant="contained" color="primary" onClick={() => window.history.back()}>Back</Button>
        <h2>
          Point of Sale Order:
          {' '}
          {''}
          {moment(reservation?.createdAt).format('DD/MM/YYYY HH:mm')}
        </h2>
        <h3>
          Payment Status:
          {' '}
          {reservation?.paymentStatus}
          <br />
          {reservation?.paymentStatus === 'STRIPE' && (
          <a target="_blank" href={`https://dashboard.stripe.com/${company?.stripeId}/payments/${stripeOrder?.chargeId}`} rel="noreferrer">View in Stripe</a>
          )}
        </h3>
        <h3>
          Status:
          {' '}
          {reservation?.status}
        </h3>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservation && reservation.allocations?.map((item) => (
                <TableRow key={item?.id}>
                  <TableCell>{item?.addon?.name}</TableCell>
                  <TableCell>
                    {getCurrencySymbol(currency)}
                    {item?.price?.toFixed(2)}
                  </TableCell>
                  <TableCell>{item?.status === 'ACTIVE' ? 'Valid' : 'Cancelled' }</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  Total
                  {' '}
                  {getCurrencySymbol(currency)}
                  {reservation?.total?.toFixed(2)}
                </TableCell>
                <TableCell />
                <TableCell>
                  {reservation?.paymentStatus === 'UNPAID' && (
                  <div>
                    <RecordPaymentDialog
                      variant="contained"
                      style={{
                        marginRight: '20px',
                        marginTop: '10px',
                        width: 175,
                        backgroundColor: '#ac372f',
                        color: '#fff',
                      }}
                      singularBooking
                      buttonTitle="Record Payment"
                      dialogTitle="Record payment for POS Transaction"
                      requestMultipleRecordPayment={
                        () => dispatch(recordMultipleReservationPayment([reservation], true, null))
                        }
                      selectedReservations={[reservation]}
                    />
                  </div>
                  )}
                  {reservation.status !== 'CANCELLED' && (
                    <MultipleCancelRefundDialog
                      variant="contained"
                      buttonTitle="Cancel / Refund Bookings"
                      dialogTitle={`Cancel / Refund ${[reservation].length} Booking${[reservation].length > 1 ? 's' : ''}`}
                      selectedRows={[reservation]}
                      singularBooking
                      requestCancel={() => requestMultipleCancel()}
                      resetSelectedRows={() => dispatch(
                        resetSelectedRows(),
                      )}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

PosPayment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      reservationId: PropTypes.string,
    }),
  }).isRequired,
};

export default PosPayment;
