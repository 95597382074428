/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardHeader } from '@material-ui/core';
import * as actions from '../../../reducers/usersReducer';
import * as notesActions from '../../../reducers/notesReducer';
import * as membershipsActions from '../../../reducers/accessRestrictionsReducer';
import UserMembershipsTable from './UserMembershipsTable';
import UserSubscriptionsTable from './UserSubscriptionsTable';
import '../../../styles/payments.css';
import UserInformationCard from '../components/UserInformationCard';
import NotesCard from '../components/NotesCard';
import Back from '../../../components/Back';
import OrderTable from '../../payments/OrderTable';
import DesktopBookingsTable from '../../bookings/desktopTable';
import { getIndividualUserEvents } from '../../../services/eventsServices';
import EventsTable from '../../events/EventsTable';

const UserView = ({
  users,
  companyInfo,
  getUsersBookingsBySearch,
  updateUserNote,
  match,
  searchOptions,
}) => {
  const [selectedDates] = useState({
    startDate: searchOptions.validFrom,
    endDate: searchOptions.validTo,
  });
  const [eventData, setEventData] = useState([]);

  const getEvents = async (userId) => {
    if (!companyInfo.id) return;
    await getIndividualUserEvents(userId, companyInfo.id).then((res) => {
      if (res.status === 200) {
        const eventData = res.data?.map((event) => event.events[0]);
        const filteredEventData = eventData.filter((event) => event !== null);
        setEventData(filteredEventData);
      } else {
        setEventData('Error getting event data. Please try again.');
      }
    });
  };

  useEffect(() => {
    const { startDate, endDate } = selectedDates;
    const userId = match.params.id;
    const options = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      status: 'ACTIVE',
      facilities: [],
    };
    getUsersBookingsBySearch(options, userId);
    getEvents(userId);
  }, [selectedDates, getUsersBookingsBySearch, match.params.id, companyInfo]);

  const {
    subscriptions,
    membership,
    orders,
    notes,
    id,
  } = users.selectedUser;

  const { stripeId, currencySym } = companyInfo;

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div>
          <UserInformationCard user={users.selectedUser} />
        </div>
        <div style={{ flex: 1, margin: '1rem' }}>
          <Card style={{ padding: '1rem' }}>
            <CardHeader title="Notes" />
            <NotesCard userId={id} notes={notes} updateNote={updateUserNote} />
          </Card>
          {/* Action buttons */}
          <div style={{ display: 'flex', marginTop: '1.5rem', gap: '1.5rem' }}>
            <Back />
          </div>
        </div>
      </div>

      {/* Bookings */}
      <div style={{ margin: '1rem' }}>
        <Card style={{ padding: '1rem' }}>
          <CardHeader title="Bookings" />
          <DesktopBookingsTable
            bookings={users.bookings}
            searchFunction={getUsersBookingsBySearch}
            selectedUser={users.selectedUser}
          />
        </Card>
      </div>

      {/* Subscriptions */}
      <div style={{ margin: '1rem' }}>
        <Card style={{ padding: '1rem' }}>
          <CardHeader title="Subscriptions" />
          <UserSubscriptionsTable subscriptions={subscriptions} />
        </Card>
      </div>

      {/* Memberships */}
      <div style={{ margin: '1rem' }}>
        <Card style={{ padding: '1rem' }}>
          <CardHeader title="Memberships" />
          <UserMembershipsTable memberships={membership} />
        </Card>
      </div>

      {/* Orders */}
      <div style={{ margin: '1rem' }}>
        <Card style={{ padding: '1rem' }}>
          <CardHeader title="Orders" />
          <OrderTable
            orders={orders}
            stripeId={stripeId}
            currencySym={currencySym}
            showUser={false}
          />
        </Card>
      </div>

      {/* Events */}
      <div style={{ margin: '1rem' }}>
        <Card style={{ padding: '1rem' }}>
          <CardHeader title="Events" />
          <EventsTable events={eventData} readOnly />
        </Card>
      </div>
    </>
  );
};

UserView.propTypes = {
  users: PropTypes.shape().isRequired,
  companyInfo: PropTypes.shape().isRequired,
  getUsersBookingsBySearch: PropTypes.func.isRequired,
  getUsersBookings: PropTypes.func.isRequired,
  updateUserNote: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  searchOptions: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => {
  const { users, companies } = state;
  const { companyInfo } = companies;
  return {
    users,
    companyInfo,
    searchOptions: state.bookings.searchOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUsersBookingsBySearch: (options, userId) => dispatch(
    actions.getUsersBookingsBySearch(options, userId),
  ),
  getMembershipUsers: (membershipId) => dispatch(
    membershipsActions.getMembershipUsers(membershipId),
  ),
  getUsersBookings: (userId, type) => dispatch(actions.getUsersBookings(userId, type)),
  updateMembership: () => dispatch(membershipsActions.updateMembership()),
  updateUserNote: (userId, note) => dispatch(notesActions.updateCompanyUserNote(userId, note)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserView);
