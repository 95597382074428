import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import {
  Typography,
  ListItem,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import moment from 'moment';
import * as actions from '../../../../reducers/subscriptionsReducer';
import MenuHeading from '../../../../components/MenuHeading';
import * as helper from '../../../../helpers/index';

class BookingStepperConflicts extends React.Component {
  componentDidMount() {
    const {
      doClearSubscriptionCreationConflicts,
      doRequestSubscriptionCreation,
      updateSubscriptionStoreFacilityIds,
    } = this.props;
    doClearSubscriptionCreationConflicts();
    updateSubscriptionStoreFacilityIds([]);
    doRequestSubscriptionCreation(true);
  }

  handleToggle(facilityId) {
    const { updateSubscriptionStoreFacilityIds, subscription } = this.props;
    const { facilityIds } = subscription;
    if (facilityIds.includes(facilityId)) {
      const existingIndex = facilityIds.indexOf(facilityId);
      facilityIds.splice(existingIndex, 1);
    } else {
      facilityIds.push(facilityId);
    }
    updateSubscriptionStoreFacilityIds(facilityIds);
  }

  handleApplyExclusions() {
    const {
      applyExclusions,
      conflicts,
      updateApplyExclusions,
    } = this.props;
    if (applyExclusions) {
      updateApplyExclusions(null);
    } else {
      const confilictingFacilityIds = conflicts.conflictingAllocations.map((al) => al.facilityId);
      const uniqueFacilityIds = [...new Set(confilictingFacilityIds)];
      updateApplyExclusions(uniqueFacilityIds);
    }
  }

  render() {
    const {
      conflicts,
      selectedFacility,
      subscription,
      isLoadingConflicts,
      updateApplyExclusions,
      applyExclusions,
      pitchSplit,
      exclusionsAppliedFacility,
    } = this.props;
    const selectedFacilities = subscription.facilityIds;
    const conflictingFacilities = [];
    if (conflicts) {
      conflicts.conflictingFacilitySubscriptions.forEach((sub) => {
        sub.accessRestriction.facilities.forEach((facility) => {
          conflictingFacilities.push(facility.id);
        });
      });
      conflicts.conflictingAllocations.forEach((al) => {
        conflictingFacilities.push(al.facilityId);
      });
    }

    let conflictsSection;
    let subConflictsSection;
    let arConflictsSection;
    if (isLoadingConflicts) {
      subConflictsSection = (
        <Typography variant="caption">Loading Conflicts...</Typography>
      );
      conflictsSection = (
        <div />
      );
    }
    if (conflicts && conflicts.conflictingAllocations.length > 0) {
      conflictsSection = (
        <div>
          <Typography variant="caption">The following booking conflicts were found:</Typography>
          <p>
            {conflicts.conflictingAllocations.map((conflict) => (
              <p>
                {moment(conflict.startTime).format('LL')}
                {' '}
                at
                {' '}
                {moment(conflict.startTime).format('LT')}
                {' '}
                -
                {' '}
                {moment(conflict.endTime).add(1, 'seconds').format('LT')}
                <br />
                <strong>
                  {conflict.firstName}
                  {' '}
                  {conflict.lastName}
                </strong>
                <br />
                <strong>{conflict.facilityName}</strong>
              </p>
            ))}
          </p>
          <ListItem
            dense
          >
            <Checkbox
              checked={applyExclusions}
              tabIndex="-1"
              disableRipple
              onClick={() => this.handleApplyExclusions()}
            />
            <ListItemText primary="Apply exclusions to the subscription for these dates?" />
          </ListItem>

        </div>
      );
    } else {
      conflictsSection = (
        <Typography variant="caption">No reservation conflicts were found.</Typography>
      );
    }
    if (conflicts && conflicts.conflictingFacilitySubscriptions.length > 0) {
      subConflictsSection = (
        <div id="subSectionConflicts">
          <Typography variant="caption">The following subscription conflicts were found:</Typography>
          <p>
            {conflicts.conflictingFacilitySubscriptions.map((conflict) => (
              <p>
                {' '}
                {moment(conflict.accessRestriction.startTime, 'HH:mm:ss').format('LT')}
                {' '}
                -
                {' '}
                {moment(conflict.accessRestriction.endTime, 'HH:mm:ss').add(1, 'seconds').format('LT')}
                {' ('}
                {helper.generateValidityText(conflict.accessRestriction,
                  conflict.accessRestriction?.facilities[0].timezone)}
                {')'}
                <br />
                <strong>
                  {conflict.subscriptionName}
                </strong>
                <br />
                {conflict.accessRestriction.facilities.map((facility) => (
                  <p>
                    <strong>{facility.name}</strong>
                  </p>
                ))}

              </p>
            ))}
          </p>
          <ConditionallyVisible condition={
            conflicts.conflictingFacilitySubscriptions.every((sub) => sub.accessRestriction.validTo)
            }
          >
            <ListItem
              dense
            >
              <Checkbox
                checked={applyExclusions}
                tabIndex="-1"
                disableRipple
                onClick={() => updateApplyExclusions(null)}
              />
              <ListItemText primary="Apply exclusions to the new subscription for these dates?" />
            </ListItem>
          </ConditionallyVisible>
        </div>
      );
    } else {
      subConflictsSection = (
        <Typography variant="caption">No subscription conflicts were found.</Typography>
      );
    }
    if (conflicts && conflicts.conflictingAccessRestrictions.length > 0) {
      const closureARConflicts = conflicts.conflictingAccessRestrictions.filter(
        (ar) => ar.type === 'HOLIDAY',
      );
      const uniqueClosureARConflicts = [];
      closureARConflicts?.forEach((ar) => {
        if (uniqueClosureARConflicts.findIndex((conflict) => conflict.id === ar.id) === -1) {
          uniqueClosureARConflicts.push(ar);
        }
      });
      arConflictsSection = (
        <div>
          <ConditionallyVisible condition={uniqueClosureARConflicts?.length > 0}>
            <>
              <Typography variant="caption">The following additional closure conflicts were found:</Typography>
              <p>
                {Array.from(uniqueClosureARConflicts).map((conflict) => (
                  <p>
                    {' '}
                    {moment(conflict.startTime, 'HH:mm:ss').format('LT')}
                    {' '}
                    -
                    {' '}
                    {moment(conflict.endTime, 'HH:mm:ss').add(1, 'seconds').format('LT')}
                    {' ('}
                    {helper.generateValidityText(
                      { validFrom: conflict.validFrom, validTo: conflict.validTo },
                      conflict.accessRestriction?.facilities[0].timezone,
                    )}
                    {')'}
                    <br />
                    <strong>
                      {conflict.description}
                    </strong>
                    <br />
                    <strong>{conflict.name}</strong>
                  </p>
                ))}
              </p>
            </>

            <ConditionallyVisible condition={
            conflicts.conflictingFacilitySubscriptions.every((sub) => sub.accessRestriction.validTo)
            }
            >
              <ListItem
                dense
              >
                <Checkbox
                  checked={applyExclusions}
                  tabIndex="-1"
                  disableRipple
                  onClick={() => updateApplyExclusions(null)}
                />
                <ListItemText primary="Apply exclusions to the new subscription for these dates?" />
              </ListItem>
            </ConditionallyVisible>
          </ConditionallyVisible>
        </div>
      );
    } else {
      arConflictsSection = (
        <Typography variant="caption">No additional conflicts were found.</Typography>
      );
    }

    return (
      <div>
        <MenuHeading title="Conflicts:" />
        {conflictsSection}
        <br />
        {subConflictsSection}
        <br />
        {arConflictsSection}
        <ConditionallyVisible condition={(selectedFacility.splitType === 'USER_SELECTION' || selectedFacility.splitType === 'MULTIPLE_SELECTION') && isLoadingConflicts === false}>
          <p>
            Please select the facilities that you wish to assign to this subscription. (
            {pitchSplit}
            {' '}
            required)
          </p>
          {selectedFacility?.subFacilities?.map((facility) => (
            <ConditionallyVisible condition={(!conflictingFacilities.includes(
              facility.id,
            ) && !conflictingFacilities.includes(
              facility.parentFacilityId,
            ))
             || !selectedFacility.subFacilities.map(
               (facility) => facility.id,
             ).includes(conflictingFacilities[0])
             || (exclusionsAppliedFacility.includes(facility.id)
             || exclusionsAppliedFacility.includes(facility.parentFacilityId))}
            >
              <ListItem
                dense
                key={facility.id}
                onClick={() => this.handleToggle(facility.id)}
              >
                <Checkbox
                  checked={selectedFacilities.includes(facility.id)}
                  tabIndex="-1"
                  disableRipple
                />
                <ListItemText primary={facility.name} />
              </ListItem>
            </ConditionallyVisible>
          ))}
        </ConditionallyVisible>
      </div>
    );
  }
}

BookingStepperConflicts.propTypes = {
  conflicts: PropTypes.shape().isRequired,
  selectedFacility: PropTypes.shape().isRequired,
  subscription: PropTypes.shape().isRequired,
  doClearSubscriptionCreationConflicts: PropTypes.func.isRequired,
  doRequestSubscriptionCreation: PropTypes.func.isRequired,
  updateSubscriptionStoreFacilityIds: PropTypes.func.isRequired,
  updateApplyExclusions: PropTypes.func.isRequired,
  isLoadingConflicts: PropTypes.bool.isRequired,
  applyExclusions: PropTypes.bool.isRequired,
  pitchSplit: PropTypes.bool.isRequired,
  exclusionsAppliedFacility: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  conflicts: state.subscriptions.conflicts,
  isLoadingConflicts: state.subscriptions.isLoadingConflicts,
  selectedFacility: state.facilities.selectedFacility,
  subscription: state.subscriptions.subscription,
  applyExclusions: state.subscriptions.applyExclusions,
  pitchSplit: state.booking.selectedPitchSplit,
  exclusionsAppliedFacility: state.subscriptions.exclusionsAppliedFacility,
});

const mapDispatchToProps = (dispatch) => ({
  doRequestSubscriptionCreation: (checkForConflicts) => dispatch(
    actions.requestSubscriptionCreation(checkForConflicts),
  ),
  updateApplyExclusions: (facilityIds) => dispatch(
    actions.updateApplyExclusions(facilityIds),
  ),
  doClearSubscriptionCreationConflicts: () => dispatch(
    actions.clearSubscriptionCreationConflicts(),
  ),
  updateSubscriptionStoreFacilityIds: (facilityIds) => dispatch(
    actions.updateSubscriptionStoreFacilityIds(facilityIds),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingStepperConflicts);
