import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { push } from 'react-router-redux';
import * as actions from '../../reducers/membershipsReducer';
import Back from '../../components/Back';
import UserTable from '../users/UserTable';
import MembersDialog from './components/MembersDialog';
import ActionBar from '../../components/ActionBar';
import { useOrigin } from '../../hooks';
import { Markdown } from '../../helpers/Markdown';

const MembershipView = ({ match }) => {
  const dispatch = useDispatch();
  const { membershipId } = match.params;
  const { bookerUrl } = useOrigin();
  const membership = useSelector((state) => state.memberships.membership);
  const getMembership = (id) => dispatch(actions.requestMembership(id));

  useEffect(() => {
    getMembership(membershipId);
  }, []);

  return (
    <>
      <div style={{
        margin: '1rem',
        border: '1px solid #ccc',
        borderRadius: '1rem',
        backgroundColor: 'white',
        padding: '1rem',
      }}
      >
        {/* Title */}
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          {`Membership: ${membership.name || ''}`}
        </Typography>

        {/* Description */}
        <Markdown>
          {membership.description}
        </Markdown>
      </div>

      {/* Buttons */}
      <ActionBar>
        <div style={{ marginRight: '1rem' }}>
          <Back />
        </div>

        <MembersDialog
          members={membership.users}
          membershipName={membership.name}
          membershipID={membership.id}
        />

        <div style={{ marginLeft: '1rem' }}>
          <a href={`${bookerUrl}/membership/${membership.id}/signup`} target="_blank" rel="noreferrer">
            <Button variant="outlined" color="secondary">View Membership Page</Button>
          </a>
        </div>

        <Button
          variant="outlined"
          color="secondary"
          style={{ marginLeft: '1rem' }}
          onClick={() => dispatch(push(`/memberships/${membership.id}/media`))}
        >
          Add Membership Media
        </Button>
      </ActionBar>

      {/* Mambership Table */}
      <UserTable
        membershipUsers={membership.user_memberships}
        isMembersTable
        membership={membership}
      />
    </>
  );
};

MembershipView.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default MembershipView;
