import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { useCompany } from '../../hooks';

const BasicSiteSelector = ({
  selectedSiteId, sites, changeSite, disabled,
}) => {
  const { products } = useCompany();
  const page = window.location.pathname.split('/')[1];

  return (
    <FormControl
      disabled={disabled}
    >
      <Select
        displayEmpty
        value={selectedSiteId}
        onChange={(event) => changeSite(page === 'payments' ? event : event.target.value)}
        name="Site"
        variant={page === 'payments' ? 'outlined' : 'standard'}
        style={{ backgroundColor: 'white' }}
      >
        {sites.filter((site) => {
          if (products?.pos?.toLowerCase() === 'enabled') {
            return true;
          }
          return site.name.toLowerCase() !== 'pos';
        }).map((site) => (
          <MenuItem value={site.id}>{site.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

BasicSiteSelector.propTypes = {
  selectedSiteId: PropTypes.string.isRequired,
  sites: PropTypes.shape().isRequired,
  changeSite: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

BasicSiteSelector.defaultProps = {
  disabled: false,
};

export default (BasicSiteSelector);
