import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import LoadingSkeletonBarChart from '../../../../components/LoadingComponents/LoadingSkeletonBarChart';
import FailedSection from '../../../../components/LoadingComponents/FailedSection';
import { CategoryChartBaseLine } from './bases/CategoryChartBaseLine';
import { CategoryHeaderBase } from './bases/CategoryTitleBase';

class CategoryBookings extends React.Component {
  render() {
    const {
      analyticsBookings,
      analyticsTimeframe,
      analyticsTimeframeCustom,
      analyticsLoading,
      analyticsFailed,
    } = this.props;
    const { bookings, xAxisBookings, yAxisBookings } = analyticsBookings;

    return (
      <div className="category" id="Bookings">
        <CategoryHeaderBase categoryName="Bookings" />
        <AlternativelyVisible condition={analyticsLoading}>
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: '1',
          }}
          >
            <LoadingSkeletonBarChart />
          </div>
          <AlternativelyVisible condition={analyticsFailed}>
            <FailedSection />
            <CategoryChartBaseLine
              backgroundColor="#87CEFA10"
              bookings={bookings}
              borderColor="#87CEFA"
              categoryName="Bookings"
              analyticsTimeframe={analyticsTimeframe}
              analyticsTimeframeCustom={analyticsTimeframeCustom}
              xAxis={xAxisBookings}
              yAxis={yAxisBookings}
              yAxisLabel="Bookings"
            />
          </AlternativelyVisible>
        </AlternativelyVisible>
      </div>
    );
  }
}

CategoryBookings.propTypes = {
  analyticsBookings: PropTypes.shape().isRequired,
  analyticsTimeframe: PropTypes.shape().isRequired,
  analyticsTimeframeCustom: PropTypes.shape().isRequired,
  analyticsLoading: PropTypes.bool.isRequired,
  analyticsFailed: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    analyticsBookings: state.analytics.analyticsBookings,
    analyticsTimeframe: state.analytics.analyticsTimeframe,
    analyticsTimeframeCustom: state.analytics.analyticsTimeframeCustom,
    analyticsLoading: state.analytics.analyticsLoading,
    analyticsFailed: state.analytics.analyticsFailed,
  };
}

export default connect(mapStateToProps)(CategoryBookings);
