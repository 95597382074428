import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as addonActions from '../../../reducers/addonsReducer';
import PBButton from '../../../components/Button';
import MenuHeading from '../../../components/MenuHeading';
import FacilityAndSubFacilityPicker from '../../facilities/components/FacilityAndSubFacilityPicker';
import EventPicker from '../../../components/EventPicker';

const styles = () => ({
  root: {
    minWidth: 670,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});
class EditEventDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showAlert: false,
      showTypeAlert: false,
    };
  }

  componentDidMount = () => {
    const {
      addon,
      updateAddonStore,
    } = this.props;
    updateAddonStore({
      facilities: addon.facilities,
      events: addon.events,
    });
  }

  updatefacilities = (facilities) => {
    const { updateAddonStore } = this.props;
    const facilityIds = facilities.facilities.map((fac) => fac.id);
    updateAddonStore({
      facilityIds,
      facilities: facilities.facilities,
    });
  }

  updateEvents = (events) => {
    const eventIds = events.events.map((eve) => eve.id);
    const { updateAddonStore } = this.props;
    updateAddonStore({
      eventIds,
      events: events.events,
    });
  }

  handleClickOpen = () => {
    const {
      addon,
      updateAddonStore,
    } = this.props;

    let unlimited = false;

    if (addon.quantity === 0 || addon.quantity === null) {
      unlimited = true;
    }
    updateAddonStore(
      { ...addon, unlimited },
    );
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
    const {
      resetAddonStore,
    } = this.props;
    resetAddonStore();
  };

  handleSaveEvent = () => {
    const { requestAddonUpdate } = this.props;
    requestAddonUpdate();
    this.handleRequestClose();
  }

  render() {
    const isMobile = window.innerWidth < 768;
    const {
      classes,
      addon,
      updateAddonStore,
      companyEvents,
      companyFacilities,
      addonStore,
    } = this.props;

    const {
      name,
      quantity,
      status,
      type,
      visible,
      unlimited,
      facilities,
      events,
      costCode,
    } = addonStore;

    let { price } = addonStore;
    if (!price && addonStore.priceModifiers && addonStore.priceModifiers.length > 0) {
      price = addonStore.priceModifiers[0].value;
    }

    const statusOptions = [
      { value: 'ACTIVE', label: 'Active' },
      { value: 'INACTIVE', label: 'Inactive' },
      { value: 'ARCHIVED', label: 'Archived' },
    ];
    const typeOptions = [
      { value: 'RESOURCE', label: 'Resource' },
      { value: 'CONSUMABLE', label: 'Consumable' },
    ];

    const { open, showAlert, showTypeAlert } = this.state;
    return (
      <div>
        <PBButton
          id="manager-edit-event-dialog"
          onClick={() => this.handleClickOpen()}
          color="secondaryIcon"
        >
          <EditIcon />
        </PBButton>
        <Dialog
          open={open}
          fullScreen={isMobile}
          fullWidth
          classes={{ paper: classes.root }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>Edit Product</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <DialogContent>
            <MenuHeading title="Facilities" />
            <FacilityAndSubFacilityPicker
              facilities={companyFacilities}
              listName="Facilities"
              onUpdate={(updatedFacilities) => this.updatefacilities(
                { facilities: updatedFacilities },
              )}
              checkedFacilities={facilities}
            />
            <MenuHeading title="Events" />
            <EventPicker
              events={companyEvents}
              listName="Events"
              onUpdate={(updatedEvents) => this.updateEvents(
                { events: updatedEvents },
              )}
              checkedEvents={events}
            />
            <MenuHeading title="Name" />
            <TextField
              id="name"
              type="text"
              variant="outlined"
              value={name}
              onChange={(event) => updateAddonStore(
                { name: event.target.value },
              )}
            />
            <MenuHeading title="Price" />
            <TextField
              id="price"
              type="text"
              variant="outlined"
              value={price}
              onChange={(event) => updateAddonStore(
                { price: event.target.value },
              )}
            />
            <MenuHeading title="Unlimited Quantity?" />
            <Switch
              checked={unlimited}
              value={unlimited}
              onChange={(event) => updateAddonStore(
                { unlimited: !!event.target.checked },
              )}
            />
            <ConditionallyVisible condition={!unlimited}>
              <MenuHeading title="Quantity" />
              <TextField
                id="quantity"
                type="number"
                variant="outlined"
                value={quantity}
                onChange={(event) => updateAddonStore(
                  { quantity: event.target.value },
                )}
              />
            </ConditionallyVisible>
            <MenuHeading title="Status" />
            <PBInput
              id="status"
              type="select"
              value={status}
              options={statusOptions}
              onChange={(value) => updateAddonStore(
                { status: value },
              )}
            />
            <div style={{
              display: 'flex', gap: '1rem', alignItems: 'flex-end', marginBottom: '0.5rem',
            }}
            >
              <MenuHeading title="Type" />
              <HelpTwoToneIcon
                onClick={() => this.setState({ showTypeAlert: !showTypeAlert })}
                style={{
                  fontSize: '1.5rem', cursor: 'pointer', color: !showTypeAlert ? 'var(--accent)' : '', marginBottom: '0.2rem',
                }}
              />
            </div>
            {showTypeAlert && (
            <Alert severity="info" style={{ margin: '1rem 0' }}>
              <AlertTitle style={{ fontWeight: '600' }}>Type</AlertTitle>
              A Resource type addon is a fixed resource e.g
              A Floodlight. A consumbale has a quantity e.g birthday cake
            </Alert>
            )}
            <PBInput
              id="type"
              type="select"
              value={type}
              options={typeOptions}
              onChange={(e) => updateAddonStore(
                { type: e.target.value },
              )}
            />

            {/* Cost Code */}
            <div style={{
              display: 'flex', gap: '1rem', alignItems: 'flex-end', marginBottom: '0.5rem',
            }}
            >
              <MenuHeading title="Cost Code" />
              <HelpTwoToneIcon
                onClick={() => this.setState({ showAlert: !showAlert })}
                style={{
                  fontSize: '1.5rem', cursor: 'pointer', color: !showAlert ? 'var(--accent)' : '', marginBottom: '0.2rem',
                }}
              />
            </div>
            {showAlert && (
            <Alert severity="info" style={{ margin: '1rem 0' }}>
              <AlertTitle style={{ fontWeight: '600' }}>Cost Code</AlertTitle>
              Please note, this code will apply to the addon only.
              The facility/event will have its own code.
            </Alert>
            )}
            <TextField
              id="costCode"
              type="text"
              variant="outlined"
              value={costCode}
              onChange={(event) => updateAddonStore(
                { costCode: event.target.value },
              )}
            />
            {/* <MenuHeading title="Tax Exempt" />
            <Switch
              checked={taxExempt}
              value={taxExempt}
              onChange={(event) => updateAddonStore(
                { taxExempt: !!event.target.checked },
              )}
            /> */}
            <MenuHeading title="Visible" />
            <Switch
              checked={visible}
              value={visible}
              onChange={(event) => updateAddonStore(
                { visible: !!event.target.checked },
              )}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary" variant="outlined">
              Close
            </Button>
            <Button
              onClick={() => this.handleSaveEvent(addon)}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
EditEventDialog.propTypes = {
  classes: PropTypes.shape().isRequired,
  addon: PropTypes.shape().isRequired,
  addonStore: PropTypes.shape().isRequired,
  companyFacilities: PropTypes.shape().isRequired,
  companyEvents: PropTypes.shape().isRequired,
  updateAddonStore: PropTypes.func.isRequired,
  resetAddonStore: PropTypes.func.isRequired,
  requestAddonUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { addonStore } = state.addons;
  const { eventStore, events } = state.events;
  const { companyFacilities } = state.facilities;
  return {
    eventStore,
    companyFacilities,
    companyEvents: events,
    addonStore,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateAddonStore: (value) => dispatch(addonActions.updateAddonStore(
    value,
  )),
  resetAddonStore: () => dispatch(addonActions.resetAddonStore()),
  requestAddonUpdate: () => dispatch(addonActions.requestAddonUpdate()),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withStyles(styles())(EditEventDialog));
