import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import RecurringIcon from '@material-ui/icons/Autorenew';
import moment from 'moment-timezone';
import {
  Checkbox,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import * as actions from '../../../reducers/invoicesReducer';
import '../../../styles/accessRestriction.css';

const initialState = {
  invoiceSelected: false,
};

class InvoiceReservationsTableRow extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleUpdateInvoiceSelected = (reservation) => {
    const { updateCreateInvoiceSelectedReservations } = this.props;
    const { invoiceSelected } = this.state;
    updateCreateInvoiceSelectedReservations(reservation);
    this.setState({ invoiceSelected: !invoiceSelected });
  };

  render() {
    const {
      row, selectable, showType, showTypeSymbol, selectedReservations,
    } = this.props;
    let { facilityNameIncCancelled } = row;
    if (!facilityNameIncCancelled) {
      facilityNameIncCancelled = row.description || row.notes;
    }
    const {
      totalExTax, tax, total, timezone,
    } = row;
    const { invoiceSelected } = this.state;
    const className = row.type === 'RESERVATION' ? 'reservationTag' : 'subscriptionTag';
    return (
      <>
        <TableRow>
          <ConditionallyVisible condition={selectable}>
            <TableCell align="center">
              <Checkbox
                value={invoiceSelected}
                onClick={() => this.handleUpdateInvoiceSelected(row)}
                checked={selectedReservations.some(
                  (e) => (row.type === 'RESERVATION' && e.id === row.id)
                    || (
                      e.subscriptionReservationId !== undefined
                      && e.subscriptionReservationId === row.subscriptionReservationId),
                )}
                disableRipple
              />
            </TableCell>
          </ConditionallyVisible>
          <TableCell align="left">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div>
                <div style={{ fontWeight: 800 }}>
                  {facilityNameIncCancelled}
                </div>
                {row.startTime
                  && (
                  <div>
                    {`${moment(row.startTime).tz(timezone).format('DD-MMM-YYYY')}, ${moment(row.startTime).tz(timezone).format('HH:mm')} - ${moment(row.endTime).tz(timezone).add(1, 'seconds').format('HH:mm')}`}
                  </div>
                  )}
                {row.type === 'SUBSCRIPTION' && <div>{row.subscriptionName}</div>}
                {row.status === 'CANCELLED' && (
                  <div style={{ color: 'red' }}>
                    Cancelled
                    {row.orders.some((x) => x.status === 'REFUNDED') && ' & Refunded'}
                  </div>
                )}
              </div>
              <ConditionallyVisible condition={showTypeSymbol && row.type === 'SUBSCRIPTION'}>
                <Tooltip title="This symbol will not appear on the generated invoice sent to the booker." arrow>
                  <RecurringIcon />
                </Tooltip>
              </ConditionallyVisible>
            </div>
          </TableCell>
          <ConditionallyVisible condition={showType}>
            <TableCell align="center"><div className={className}>{row.type}</div></TableCell>
          </ConditionallyVisible>
          <TableCell align="center">{parseFloat(totalExTax).toFixed(2)}</TableCell>
          <TableCell align="center">{parseFloat(tax).toFixed(2)}</TableCell>
          <TableCell align="center">{parseFloat(total).toFixed(2)}</TableCell>
        </TableRow>
      </>
    );
  }
}

InvoiceReservationsTableRow.propTypes = {
  selectable: PropTypes.bool.isRequired,
  showType: PropTypes.bool.isRequired,
  showTypeSymbol: PropTypes.bool,
  row: PropTypes.shape(),
  updateCreateInvoiceSelectedReservations: PropTypes.func.isRequired,
  selectedReservations: PropTypes.arrayOf(PropTypes.shape()),
};
InvoiceReservationsTableRow.defaultProps = {
  selectedReservations: [],
  showTypeSymbol: false,
  row: {},
};

const mapStateToProps = (state) => ({
  selectedReservations: state.invoices.createInvoice.selectedReservations,
});
const mapDispatchToProps = (dispatch) => ({
  updateCreateInvoiceSelectedReservations: (reservation) => dispatch(
    actions.updateCreateInvoiceSelectedReservations(reservation),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceReservationsTableRow);
