const LEAGUE_STATUS = {
  ACTIVE: 'Active',
  CLOSED: 'Closed',
  COMPLETED: 'Completed',
  DELETED: 'Deleted',
  DRAFT: 'Draft',
  OPEN: 'Open',
};

export default LEAGUE_STATUS;
