import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import TableContainer from '@material-ui/core/TableContainer';
import MaterialTable from 'material-table';
import DeleteDialog from '../accessRestrictions/components/DeleteAccessRestrictionDialog';

const AddonTable = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [AddonTableState, setAddonTableState] = useState(
    {
      page: 0,
      rowsPerPage: 25,
      selectedUserEmails: [],
      changePasswordOpen: false,
    },
  );

  const {
    categories,
  } = props;

  return (
    <>
      <h3>Existing Categories</h3>
      <div className="table-section">
        <TableContainer
          style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden' }}
        >
          <MaterialTable
            data={categories}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            title="Pitchbooking Booking Modifiers"
            icons={{
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              Export: () => <SaveAlt />,
              ResetSearch: () => <Clear />,
              SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
            }}
            columns={[
              {
                title: 'Name',
                filtering: false,
                render: (row) => (
                  <div>
                    {row.name}
                  </div>
                ),
              },
              {
                title: 'Delete',
                field: 'delete',
                filtering: false,
                render: (row) => (
                  <DeleteDialog
                    name={row.name}
                    bodyTag="Category"
                    type="CATEGORY"
                    categoryId={row.id}
                  />
                ),
              },
            ]}
            options={{
              search: false,
              selection: false,
              emptyRowsWhenPaging: false,
              tableLayout: 'auto',
              exportButton: false,
              exportFileName: 'Pitchbooking Products',
              pageSize: 50,
              pageSizeOptions: [10, 25, 50, 100],
              showFirstLastPageButtons: false,
              showTitle: false,
              headerStyle: {
                backgroundColor: '#efefef',
                fontWeight: 'bold',
              },
            }}
            localization={{
              toolbar: {
                exportTitle: 'Download',
              },
            }}
          />
        </TableContainer>
      </div>
    </>
  );
};

AddonTable.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = (state) => ({
  timezone: state.companies.companyInfo.timezone,
  companyFacilities: state.facilities.companyFacilities,
  categories: state.addons.categories,
  addonDialogOpen: state.addons.addonDialogOpen,
});

export default connect(
  mapStateToProps, null,
)(AddonTable);
