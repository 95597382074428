import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';

const styles = {
  inputWrapper: {
    display: 'flex',
    marginTop: '1.25rem',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
  },
  submitButton: {
    marginTop: '0em !important',
    marginRight: '2em !important',
    marginBottom: '2em !important',
    marginLeft: '2em !important',
  },
  actionWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '1.25rem',
    marginTop: '1.25rem',
    alignItems: 'center',
  },
};

const InviteUserForm = (props) => {
  const {
    onHandleSubmit,
    isSubmitting,
    isError,
  } = props;
  const errorRef = React.useRef(null);

  useEffect(() => {
    if (isError) {
      errorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isError]);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    teamName: '',
    phone: '',
    dialCode: '44',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onHandleSubmit}
    >
      {({
        dirty,
        errors,
        handleBlur,
        handleChange,
        isValid,
        touched,
        values,
      }) => (
        <Form style={styles.form}>

          {isError && (
            <Alert innerRef={errorRef} severity="error">
              Sorry. There was an error sending the invitation.
            </Alert>
          )}

          <div style={styles.inputWrapper}>
            <PBInput
              id="firstName"
              name="firstName"
              type="text"
              label="First name"
              value={values?.firstName}
              isError={touched?.firstName && Boolean(errors.firstName)}
              errorMessage={touched?.firstName && errors?.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </div>

          <div style={styles.inputWrapper}>
            <PBInput
              id="lastName"
              name="lastName"
              type="text"
              label="Last name"
              value={values?.lastName}
              isError={touched?.lastName && Boolean(errors.lastName)}
              errorMessage={touched?.lastName && errors?.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </div>

          <div style={styles.inputWrapper}>
            <PBInput
              id="email"
              name="email"
              type="email"
              label="Email address"
              value={values?.email}
              isError={touched?.email && Boolean(errors.email)}
              errorMessage={touched?.email && errors?.email}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </div>

          <div style={styles.inputWrapper}>
            <PBInput
              id="teamName"
              name="teamName"
              type="text"
              label="Team name"
              value={values?.teamName}
              isError={touched?.teamName && Boolean(errors.teamName)}
              errorMessage={touched?.teamName && errors?.teamName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div style={styles.inputWrapper}>
            <PBInput
              id="phone"
              name="phone"
              type="phone"
              value={values?.phone}
              isError={touched?.phone && Boolean(errors.phone)}
              errorMessage={touched?.phone && errors?.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              onChangeDialcode={handleChange}
            />
          </div>

          <div style={styles.actionWrapper}>
            <Button
              id="manager-users-invite"
              type="submit"
              disabled={isSubmitting || !(isValid && dirty)}
              color="primary"
              variant="contained"
              style={styles.submitButton}
            >
              Invite
            </Button>
          </div>

        </Form>
      )}
    </Formik>

  );
};

InviteUserForm.propTypes = {
  onHandleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
};

export default InviteUserForm;
