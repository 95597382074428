/*
    The HeaderSettingsLegend component is a visual representation
    of the currently selected analytics settings and is used to
    help the user contextualize the data they are seeing.

    The settings are:
        1. Booking Type
        2. Events
        3. Timeframe
*/

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StatisticLabel } from './StatisticLabel';

class HeaderSettingsLegend extends React.Component {
  render() {
    const {
      analyticsEvents,
      analyticsEventBookings,
      analyticsEventTimeframe,
      analyticsEventBookingsType,
    } = this.props;

    // If number of currently selected events is equal
    // to the number of initial events, then events
    // is set to 'ALL'. Otherwise, events is set to 'SUBSET'.
    const allEventsBoolean = analyticsEvents.formattedEvents?.length
      === analyticsEventBookings.eventIDsArray?.length;
    const events = allEventsBoolean ? 'All' : 'Subset';
    const formattedAnalyticsEventTimeframe = analyticsEventTimeframe.includes(':') ? 'Custom' : analyticsEventTimeframe;
    return (
      <div id="headerSettingsLegendContainer">
        <div className="headerItem" style={{ width: 'auto' }}>
          <div className="statistic" style={{ borderRight: '1px #00000020 solid', height: '80%', width: 'auto' }}>
            <StatisticLabel labelString="Booking Type" />
            {analyticsEventBookingsType}
          </div>
          <div className="statistic" style={{ borderRight: '1px #00000020 solid', height: '80%', width: 'auto' }}>
            <StatisticLabel labelString="Events" />
            {events}
          </div>
          <div className="statistic" style={{ width: 'auto' }}>
            <StatisticLabel labelString="Timeframe" />
            {formattedAnalyticsEventTimeframe.split('_').join(' ')}
          </div>
        </div>
      </div>
    );
  }
}

HeaderSettingsLegend.propTypes = {
  analyticsEventBookingsType: PropTypes.string.isRequired,
  analyticsEventTimeframe: PropTypes.string.isRequired,
  analyticsEvents: PropTypes.shape().isRequired,
  analyticsEventBookings: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  analyticsEventBookingsType: state.analytics.analyticsEventBookingsType,
  analyticsEventTimeframe: state.analytics.analyticsEventTimeframe,
  analyticsEvents: state.analytics.analyticsEvents,
  analyticsEventBookings: state.analytics.analyticsEventBookings,
});

export default connect(mapStateToProps)(HeaderSettingsLegend);
