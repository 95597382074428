import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import DateRangePickerWrapper from './DateRangePickerWrapper';
import '../../styles/calendar.css';

const quickDateOptions = [
  {
    label: 'Today',
    value: 'TODAY',
  },
  {
    label: 'Yesterday',
    value: 'YESTERDAY',
  },
  {
    label: 'Next 7 Days',
    value: 'NEXT7',
  },
  {
    label: 'Past 7 Days',
    value: 'PAST7',
  },
  {
    label: 'This weekend',
    value: 'THISWEEKEND',
  },
  {
    label: 'Last weekend',
    value: 'LASTWEEKEND',
  },
  {
    label: 'Month remaining',
    value: 'TILLENDOFMONTH',
  },
  {
    label: 'Month to date',
    value: 'MONTHTODATE',
  },
  {
    label: 'Quarter to date',
    value: 'QUARTERTODATE',
  },
  {
    label: 'This Month',
    value: 'FULLMONTH',
  },
  {
    label: 'Next Month',
    value: 'NEXTMONTH',
  },
  {
    label: 'Last Month',
    value: 'LASTMONTH',
  },
  {
    label: 'Custom',
    value: 'CUSTOM',
  },
];

class DateRangePickerAndQuickDatesWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quickDateType: {
        label: quickDateOptions[0].label, value: quickDateOptions[0].value,
      },
    };
    this.handleDateAndQuickDateChange = this.handleDateAndQuickDateChange.bind(this);
  }

  handleDateAndQuickDateChange(options) {
    const {
      onDatesChange,
    } = this.props;
    this.setState({ quickDateType: { label: 'Custom', value: 'CUSTOM' } });
    onDatesChange(options);
  }

  handleQuickDateChange(option) {
    const { onDatesChange } = this.props;
    this.setState({ quickDateType: { ...option } });
    if (option.value === 'TODAY') {
      onDatesChange({
        startDate: moment(),
        endDate: moment(),
      });
    } else if (option.value === 'YESTERDAY') {
      onDatesChange({
        startDate: moment().subtract(1, 'd'),
        endDate: moment().subtract(1, 'd'),
      });
    } else if (option.value === 'NEXT7') {
      onDatesChange({
        startDate: moment(),
        endDate: moment().add(7, 'days'),
      });
    } else if (option.value === 'PAST7') {
      onDatesChange({
        startDate: moment().subtract(7, 'days'),
        endDate: moment(),
      });
    } else if (option.value === 'THISWEEKEND') {
      onDatesChange({
        startDate: moment().day(6),
        endDate: moment().day(7),
      });
    } else if (option.value === 'LASTWEEKEND') {
      onDatesChange({
        startDate: moment().subtract(7, 'days').day(6),
        endDate: moment().subtract(7, 'days').day(7),
      });
    } else if (option.value === 'TILLENDOFMONTH') {
      onDatesChange({
        startDate: moment(),
        endDate: moment().endOf('month'),
      });
    } else if (option.value === 'MONTHTODATE') {
      onDatesChange({
        startDate: moment().startOf('month'),
        endDate: moment(),
      });
    } else if (option.value === 'QUARTERTODATE') {
      onDatesChange({
        startDate: moment().quarter(moment().quarter()).startOf('quarter'),
        endDate: moment(),
      });
    } else if (option.value === 'FULLMONTH') {
      onDatesChange({
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
      });
    } else if (option.value === 'NEXTMONTH') {
      onDatesChange({
        startDate: moment().add(1, 'month').startOf('month'),
        endDate: moment().add(1, 'month').endOf('month'),
      });
    } else if (option.value === 'LASTMONTH') {
      onDatesChange({
        startDate: moment().subtract(1, 'month').startOf('month'),
        endDate: moment().subtract(1, 'month').endOf('month'),
      });
    } else if (option.value === 'CUSTOM') {
      onDatesChange({
        startDate: moment(),
        endDate: moment(),
      });
    }
  }

  render() {
    const {
      includeQuickOptions,
      startDate,
      endDate,
    } = this.props;
    const { quickDateType } = this.state;
    return (
      <>
        <ConditionallyVisible condition={includeQuickOptions}>
          <Autocomplete
            value={quickDateType}
            onChange={(event, option) => this.handleQuickDateChange(option)}
            options={quickDateOptions}
            getOptionLabel={(option) => option.label}
            style={{ width: 200, marginRight: 0, backgroundColor: 'white' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                variant="outlined"
              />
            )}
            disableClearable
          />
        </ConditionallyVisible>
        <div className="date-range-picker">
          <DateRangePickerWrapper
            startDate={startDate}
            endDate={endDate}
            onDatesChange={this.handleDateAndQuickDateChange}
            noBorder
            numberOfMonths={1}
            minimumNights={0}
            displayFormat="DD MMM YYYY"
            isOutsideRange={() => false}
          />
        </div>
      </>
    );
  }
}

DateRangePickerAndQuickDatesWrapper.propTypes = {
  noBorder: PropTypes.bool.isRequired,
  endDate: PropTypes.shape().isRequired,
  startDate: PropTypes.shape().isRequired,
  onDatesChange: PropTypes.func.isRequired,
  isOutsideRange: PropTypes.func.isRequired,
  displayFormat: PropTypes.number.isRequired,
  minimumNights: PropTypes.number.isRequired,
  quickDateType: PropTypes.shape().isRequired,
  numberOfMonths: PropTypes.number.isRequired,
  setSelectedDates: PropTypes.func.isRequired,
  withFullScreenPortal: PropTypes.bool.isRequired,
  includeQuickOptions: PropTypes.bool,
};

DateRangePickerAndQuickDatesWrapper.defaultProps = {
  includeQuickOptions: true,
};

export default DateRangePickerAndQuickDatesWrapper;
