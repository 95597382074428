/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import { Chip, Typography, Button } from '@material-ui/core';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@material-ui/icons/VisibilityOffTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteTwoTone } from '@material-ui/icons';
import { useMutation } from '@tanstack/react-query';
import CircularProgress from '@material-ui/core/CircularProgress';
import { bookingModifierColors } from './constants';
import { BookingModifierDialog } from './BookingModifierDialog';
import { updateBookingModifierStore } from '../../../reducers/bookingModifiersReducer';
import { usePitchbookingUser, useToast } from '../../../hooks';
import { requestBookingModifiersSaveService } from '../../../services/bookingModifiersServices';
import * as actions from '../../../reducers/bookingModifiersReducer';

export const GenerateBookingModifierTag = ({ bookingModifiers, isEditing = false }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { isPitchbookingManager } = usePitchbookingUser();
  const bookingModifierState = useSelector((state) => state.bookingModifiers.bookingModifier);
  const company = useSelector((state) => state.companies.companyInfo);

  const mutation = useMutation({
    mutationFn: async (modifier) => {
      const bm = { ...modifier, status: 'DELETED' };

      const res = await requestBookingModifiersSaveService(company.id, bm);

      if (res.status !== 200) {
        throw new Error('Error Deleting Booking Modifier');
      }

      return { ...res.data };
    },
    onSuccess: (data) => {
      toast.trigger({
        type: 'success',
        message: 'Booking Modifier Deleted Successfully',
      });
      dispatch(actions.getCompanyBookingModifiers());
      dispatch(actions.toggleEditingBookingModifier());
    },
    onError: () => {
      toast.trigger({
        type: 'error',
        message: 'Error Deleting Booking Modifier',
      });
    },
  });

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', gap: '1rem',
    }}
    >
      {bookingModifiers.map((modifier) => (
        <div style={{
          display: 'flex', alignItems: !isEditing ? '' : 'center', justifyContent: !isEditing ? 'center' : 'space-around',
        }}
        >
          <Chip
            key={modifier.id}
            label={(
              <>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: '600', display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: 'center',
                  }}
                >
                  {modifier.type === 'ageGroup' ? 'Age Group: ' : modifier.type === 'event' ? 'Event: ' : 'Misc: '}
                  {modifier.name}
                  {modifier.visible ? <VisibilityTwoToneIcon /> : <VisibilityOffTwoToneIcon />}
                </Typography>
                {isPitchbookingManager && (
                <Typography style={{ fontSize: '0.6rem', color: 'rgb(0,0,0, 0.5)' }}>
                  {modifier.id}
                </Typography>
                )}
              </>
          )}
            style={{
              backgroundColor: bookingModifierColors[modifier.type].backgroundColor,
              border: bookingModifierColors[modifier.type].border,
              color: bookingModifierColors[modifier.type].color,
              padding: isPitchbookingManager ? '1.5rem' : '0rem',
            }}
          />
          {isEditing && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
              <BookingModifierDialog
                bookingModifier={modifier}
                isEditing
                onUpdate={(data) => {
                  const updatedBookingModifiers = bookingModifiers.map((bm) => {
                    if (bm.id === data.id) {
                      return data;
                    }
                    return bm;
                  });
                  dispatch(updateBookingModifierStore({
                    ...bookingModifierState,
                    bookingModifiers: updatedBookingModifiers,
                  }));
                }}
              />

              <Button
                onClick={() => mutation.mutate(modifier)}
                disabled={mutation.isLoading}
                variant="contained"
                style={{ backgroundColor: 'var(--destructive)', color: 'white' }}
              >
                {mutation.isLoading
                  ? <CircularProgress size={24} />
                  : <DeleteTwoTone />}
              </Button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
