/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { Button, Divider, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { setAccountOwner } from '../../../reducers/companyUsersReducer';

export const AccountManager = ({ companyInfo }) => {
  const dispatch = useDispatch();
  const { onboarding } = companyInfo;
  const [accountManager, setAccountManager] = useState(null);
  const [pbAdmins, setPBAdmins] = useState([{ value: null, label: 'Loading...' }]);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    setAccountManager(companyInfo.accountOwner);

    if (companyInfo.pbAdmins && companyInfo.pbAdmins.length > 0) {
      const managerList = companyInfo.pbAdmins.map(
        (user) => ({ value: user.userId, label: `${user.firstName} ${user.lastName}` }),
      );
      setPBAdmins(managerList);
    }
  }, [onboarding]);

  return (
    <>
      <Typography style={{
        textDecoration: 'underline', display: 'flex', alignItems: 'center', gap: '0.5rem',
      }}
      >
        Account Manager
        <HelpTwoToneIcon
          onClick={() => setShowAlert(!showAlert)}
          style={{
            fontSize: '1.3rem', cursor: 'pointer', color: !showAlert ? 'var(--accent)' : '', marginBottom: '0.2rem',
          }}
        />
      </Typography>

      {showAlert && (
        <Alert severity="info">
          <Typography variant="body2" style={{ marginBottom: '0.5rem' }}>
            The
            <strong style={{ fontStyle: 'italic' }}>{' Introduction to Pitchbooking '}</strong>
            email
            will only be sent if account status is:
            <strong style={{ fontStyle: 'italic' }}>{' Onboarding or Account Manager Unassigned. '}</strong>
          </Typography>
        </Alert>
      )}

      <PBInput
        id="status"
        type="select"
        value={accountManager}
        options={pbAdmins}
        onChange={(e) => setAccountManager(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        type="button"
        style={{ width: '50%', alignSelf: 'end' }}
        onClick={() => {
          dispatch(setAccountOwner(accountManager));
        }}
      >
        Save
      </Button>

      <Divider />
    </>
  );
};
