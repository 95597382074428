import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import * as actions from '../../reducers/payoutsReducer';

class RecalculatePayoutButton extends React.Component {
  handleRecalculatePayout = (payoutId) => {
    const { recalculatePayout } = this.props;
    recalculatePayout(payoutId);
  };

  render() {
    const { payoutId } = this.props;

    return (
      <Button onClick={() => this.handleRecalculatePayout(payoutId)}>
        <ReplayIcon />
      </Button>
    );
  }
}

RecalculatePayoutButton.propTypes = {
  payoutId: PropTypes.string.isRequired,
  recalculatePayout: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  recalculatePayout: (payoutId) => dispatch(actions.recalculatePayout(payoutId)),
});

export default connect(null, mapDispatchToProps)(RecalculatePayoutButton);
