/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { Typography } from '@material-ui/core';
import defaultForm from './DefaultCustomForm.json';

export const validationSchema = Yup.object().shape({
  form: Yup.object().shape({
    id: Yup.string().nullable(),
    formData: Yup.array(Yup.object().shape({
      name: Yup.string().required('Required'),
      label: Yup.string().required('Required'),
      type: Yup.string().required('Required'),
      status: Yup.boolean(),
      required: Yup.boolean(),
      value: Yup.string(),
      section: Yup.string(),
    })).min(1),
  }),
});

export const initialValues = {
  customForm: {
    name: '',
    label: '',
    required: false,
    type: 'text',
    status: true,
    value: '',
    section: 'Custom Consent',
  },
};

const CustomMembershipForm = () => {
  const {
    values, handleChange, handleBlur, errors, touched, setFieldValue,
  } = useFormikContext();

  const sections = useMemo(() => {
    if (!values.form || !values.form.formData) return [];

    return values.form.formData.reduce((acc, curr) => {
      if (!acc.includes(curr.section)) {
        acc.push(curr.section);
      }

      return acc;
    }, []);
  }, [values.form]);

  useEffect(() => {
    if (!values.form) {
      setFieldValue('form.id', null);
      setFieldValue('form.formData', defaultForm);
    }
  }, []);

  if (!values.form) return null;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      {sections.map((section) => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} key={section}>
          <div>
            <Typography>{section}</Typography>
            <Divider />
          </div>

          <div style={{
            display: 'grid', gap: '1rem', gridTemplateColumns: section !== 'Custom Consent' ? 'repeat(6, 1fr)' : 'repeat(4, 1fr)',
          }}
          >
            <div style={{ gridColumn: 'span 2' }}>Label</div>
            {section !== 'Custom Consent' ? <div style={{ gridColumn: 'span 2' }}>Field Type</div> : null}
            {section !== 'Custom Consent' ? <div>Optional</div> : null}
            <div>Required</div>
            {section === 'Custom Consent' ? <div>Delete?</div> : null}

            {values.form.formData && values.form.formData.length > 0 ? (
              <>
                {values.form.formData.filter((x) => x.section === section).map((field) => {
                  const fieldIndex = values.form.formData.findIndex((x) => x.name === field.name);

                  return (
                    <React.Fragment key={fieldIndex}>
                      <div style={{ gridColumn: 'span 2', alignSelf: 'center' }}>
                        <Typography variant="subheading">{field.label}</Typography>
                      </div>
                      {section !== 'Custom Consent' && (
                      <>
                        <div style={{ gridColumn: 'span 2', alignSelf: 'center' }}>
                          {(
                            field.type === 'TEXT'
                            || field.type === 'TEXT_AREA'
                            || field.type === 'BOOLEAN'
                          ) && (
                          <PBInput
                            id={`form.formData.${fieldIndex}.type`}
                            type="select"
                            value={field.type}
                            options={[
                              { value: 'TEXT', label: 'Text' },
                              { value: 'TEXT_AREA', label: 'Text Area' },
                              { value: 'BOOLEAN', label: 'True/False' },
                            ]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isError={
                              errors.form
                              && errors.form[fieldIndex]
                              && touched.form
                              && touched.form[fieldIndex]
                              && errors.form[fieldIndex].status
                            }
                            errorText={
                              errors.form
                              && errors.form[fieldIndex]
                              && touched.form
                              && touched.form[fieldIndex]
                              && errors.form[fieldIndex].status
                            }
                          />
                          )}
                        </div>

                        <div style={{ alignSelf: 'center' }}>
                          <PBInput
                            id={`form.formData.${fieldIndex}.status`}
                            type="toggle"
                            value={field.status}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={field.required}
                          />
                        </div>
                      </>
                      )}
                      <div style={{ alignSelf: 'center' }}>
                        <PBInput
                          id={`form.formData.${fieldIndex}.required`}
                          type="toggle"
                          value={field.required}
                          onChange={(e) => {
                            handleChange(e);

                            if (e.target.checked) {
                              setFieldValue(`form.formData.${fieldIndex}.status`, true);
                            }
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                      {section === 'Custom Consent' && (
                      <div style={{ alignSelf: 'center' }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            const newFormData = [...values.form.formData];
                            newFormData.splice(fieldIndex, 1);
                            setFieldValue('form.formData', newFormData);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </>
            ) : null}
          </div>
        </div>
      ))}

      <Divider />
      <div>
        <Typography>Additional consent forms</Typography>
        <Typography variant="body2">Field name will be used to identify the consent form in the manager dashboard. Consent text will be displayed to the user when they register for the event.</Typography>
      </div>
      <Divider />

      <CustomForm
        onSubmit={(customVal) => {
          setFieldValue('form.formData', [...values.form.formData, customVal]);
        }}
      />
    </div>
  );
};

// TODO: handle errors
// eslint-disable-next-line react/prop-types
const CustomForm = ({ onSubmit }) => {
  // const initialValues = {
  //   name: '',
  //   label: '',
  //   type: 'TEXT',
  //   status: true,
  //   required: false,
  //   value: '',
  //   section: 'CUSTOM',
  // };

  const {
    values,
    handleChange,
    handleBlur,
  } = useFormikContext();

  const validation = Yup.object().shape({
    name: Yup.string().required('Field Name is a Required Field'),
    label: Yup.string().required('Consent Text is a Required Field'),
    required: Yup.boolean(),
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <PBInput
        id="customForm.name"
        label="Field Name"
        type="text"
        value={values.customForm.name}
        onChange={handleChange}
        onBlur={handleBlur}
        // isError={touched.customForm.name && Boolean(errors.customForm?.name)}
        // errorMessage={touched.customForm.name && errors.customForm?.name}
      />

      <PBInput
        id="customForm.label"
        label="Consent Text"
        type="text"
        value={values.customForm.label}
        onChange={handleChange}
        onBlur={handleBlur}
        // isError={touched.customForm.label && Boolean(errors.customForm?.label)}
        // errorMessage={touched.customForm.label && errors.customForm?.label}
      />
      <PBInput
        id="customForm.required"
        label="Consent is required?"
        type="toggle"
        value={values.customForm.required}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <div>
        <Button
          onClick={async () => {
            const isValid = await validation.isValid(values.customForm);
            if (isValid) {
              onSubmit(values.customForm);
            }
          }}
          variant="outlined"
          color="primary"
        >
          Add Consent Form
        </Button>
      </div>
    </div>

  );
};

export default CustomMembershipForm;
