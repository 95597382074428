import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export const getSchedulesService = async (companyId) => {
  const url = `/api/companies/${companyId}/schedules`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
};

export async function saveScheduleGroupOpeningTimesService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/schedule-group`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
export async function createFullWeekSchedulesService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/weekly-schedules`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
export async function swapFacilityScheduleService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/swap-facility-schedule`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
