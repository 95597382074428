export const UPDATE_FORM_REQUEST = 'UPDATE_FORM_REQUEST';
export const UPDATE_FORM_SUCCEEDED = 'UPDATE_FORM_SUCCEEDED';
export const UPDATE_FORM_FAILED = 'UPDATE_FORM_FAILED';

export const UPDATE_RESOURCE_FORM_REQUEST = 'UPDATE_RESOURCE_FORM_REQUEST';
export const GET_COMPANY_FORMS_REQUEST = 'GET_COMPANY_FORMS_REQUEST';
export const GET_COMPANY_FORMS_REQUEST_SUCCEEDED = 'GET_COMPANY_FORMS_REQUEST_SUCCEEDED';

const initialState = {
  formUpdateSuccess: false,
  forms: [],
};

export const updateFormRequest = (formId, formData, name, facilityId) => ({
  type: UPDATE_FORM_REQUEST,
  formId,
  formData,
  name,
  facilityId,
});
export const getCompanyFormsRequest = () => ({
  type: GET_COMPANY_FORMS_REQUEST,
});
export const getCompanyFormsSucceeded = (forms) => ({
  type: GET_COMPANY_FORMS_REQUEST_SUCCEEDED,
  forms,
});

export const updateResourceFormRequest = (formId, resourceType, resourceId) => ({
  type: UPDATE_RESOURCE_FORM_REQUEST,
  formId,
  resourceType,
  resourceId,
});

export const updateFormSucceeded = () => ({
  type: UPDATE_FORM_SUCCEEDED,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_FORMS_REQUEST_SUCCEEDED:
      return { ...state, forms: action.forms };
    case UPDATE_FORM_SUCCEEDED:
      return { ...state, formUpdateSuccess: true };
    case UPDATE_FORM_FAILED:
      return { ...state, formUpdateSuccess: false };
    default:
      return state;
  }
};
