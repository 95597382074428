import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

export const HubspotForm = ({ accountOwner }) => {
  const owner = useMemo(() => {
    switch (accountOwner) {
      case 'matt@pitchbooking.com':
        return 'matt-mallon';

      case 'kate@pitchbooking.com':
        return 'kate-mckay';

      case 'danielle@pitchbooking.com':
        return 'danielle120';

      default:
        return 'lyndon-mccullough';
    }
  }, [accountOwner]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    document.body.appendChild(script);
  }, []);

  return (
    <div className="meetings-iframe-container" data-src={`https://meetings.hubspot.com/${owner}?embed=true`} />
  );
};

HubspotForm.propTypes = {
  accountOwner: PropTypes.string.isRequired,
};
