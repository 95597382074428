import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CrossIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { updateCompanyDetails } from '../../../reducers/companiesReducer';
import { usePitchbookingUser, useToast } from '../../../hooks';

const PAYMENT_METHOD_TYPES = ['Card', 'Bacs Debit', 'Sepa Debit', 'Alipay'];
const TRANSACTION_TYPES = ['Invoice', 'Billing', 'Subscription', 'Membership'];

const CompanyPaymentMethods = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { isPitchbookingManager } = usePitchbookingUser();
  const paymentMethods = useSelector((state) => state.companies.companyInfo.paymentMethods);
  const [updatedTransactionTypes, setUpdatedTransactionTypes] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState([]);
  const [isEditing, setIsEditing] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState({ type: '', transactionTypes: [] });

  const filteredPaymentMethods = useMemo(() => PAYMENT_METHOD_TYPES.filter(
    (type) => !paymentMethods?.some((method) => method.type === type.toLowerCase().replace(' ', '_')),
  ), [paymentMethods]);

  useEffect(() => {
    setDeleteConfirmation([]);
  }, [paymentMethods]);

  const handleChange = () => {
    const data = [...paymentMethods, paymentMethod];
    setPaymentMethod({ type: '', transactionTypes: [] });
    dispatch(updateCompanyDetails({ paymentMethods: data }));
  };

  const handleDelete = (type) => {
    dispatch(updateCompanyDetails({
      paymentMethods: paymentMethods.filter((method) => method.type !== type),
    }));
    toast.trigger({ type: 'success', message: 'Payment method deleted successfully' });
  };

  const handleUpdateTransactionType = (type, selectedOptions) => {
    const updatedPaymentMethods = paymentMethods.map((method) => {
      if (method.type === type) {
        const filteredTransactionTypes = selectedOptions
          .map((option) => option.value)
          .filter((value) => value !== null);
        return { ...method, transactionTypes: filteredTransactionTypes };
      }
      return method;
    });

    setUpdatedTransactionTypes(updatedPaymentMethods);
  };

  const handleEdit = async (type) => {
    try {
      await dispatch(updateCompanyDetails({ paymentMethods: updatedTransactionTypes }));
      toast.trigger({ type: 'success', message: 'Payment method updated successfully' });
      setIsEditing((prev) => prev.filter((x) => x !== type));
    } catch (error) {
      toast.trigger({ type: 'error', message: 'An error occurred while updating payment method' });
    }
  };

  return isPitchbookingManager ? (
    <div style={{
      display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '1rem',
    }}
    >
      <Typography variant="h5">Payment Methods</Typography>

      {/* Active Payment Methods */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
        <Typography style={{ fontWeight: 600 }}>Currently Active Payment Methods</Typography>

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '.25rem' }}>
          {paymentMethods?.map((method) => (
            <div
              key={method.type}
              style={{
                display: 'flex', border: '1px solid rgba(0,0,0,0.25)', borderRadius: '.25rem', padding: '.5rem',
              }}
            >
              {/* Col 1 - Title and Select */}
              <div style={{
                display: 'flex', flexDirection: 'column', gap: '0.5rem', flexGrow: 1,
              }}
              >
                <Typography color="secondary" style={{ fontWeight: 600, textTransform: 'capitalize' }}>
                  {method.type.replace('_', ' ')}
                </Typography>
                <Select
                  defaultValue={method.transactionTypes.length === 0
                    ? { label: 'All Transaction Types', value: null }
                    : method.transactionTypes.map((type) => ({
                      label: type.charAt(0).toUpperCase() + type.slice(1).toLowerCase(),
                      value: type,
                    }))}
                  options={TRANSACTION_TYPES.map((type) => ({ label: type, value: type.toUpperCase().replace(' ', '_') }))}
                  isMulti
                  isClearable={false}
                  isDisabled={!isEditing.some((type) => type === method.type) || method.type === 'card'}
                  onChange={(e) => handleUpdateTransactionType(method.type, e)}
                />
              </div>

              {/* Col 2 - Buttons (delete and edit) */}
              <div style={{
                display: 'flex', flexDirection: 'column', gap: '0.5rem', marginLeft: '1rem',
              }}
              >
                {method.type !== 'card' && (
                //  Delete payment methods
                  <>
                    {!deleteConfirmation.some((type) => type === method.type) ? (
                      <DeleteIcon
                        style={{ color: 'var(--destructive)', cursor: 'pointer', alignSelf: 'flex-end' }}
                        onClick={() => setDeleteConfirmation((prev) => [...prev, method.type])}
                      />
                    ) : (
                      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}>
                        <DeleteForeverIcon
                          style={{ color: 'var(--destructive)', cursor: 'pointer' }}
                          onClick={() => handleDelete(method.type)}
                        />
                        <CrossIcon
                          style={{ color: 'rgba(0,0,0,0.3)', cursor: 'pointer' }}
                          onClick={() => setDeleteConfirmation(
                            (prev) => prev.filter((x) => x !== method.type),
                          )}
                        />
                      </div>
                    )}

                    {/* Edit payment methods */}
                    <div style={{
                      display: 'flex', justifyContent: 'flex-end', gap: '0.5rem', marginTop: '.5rem',
                    }}
                    >
                      {!isEditing.some((type) => type === method.type) ? (
                        <EditIcon
                          style={{ color: 'var(--primary)', cursor: 'pointer' }}
                          onClick={() => setIsEditing((prev) => [...prev, method.type])}
                        />
                      ) : (
                        <>
                          <CheckIcon
                            style={{ color: 'var(--primary)', cursor: 'pointer' }}
                            onClick={() => handleEdit(method.type)}
                          />
                          <CrossIcon
                            style={{ color: 'rgba(0,0,0,0.3)', cursor: 'pointer' }}
                            onClick={() => setIsEditing(
                              (prev) => prev.filter((x) => x !== method.type),
                            )}
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Available Payment Methods */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '.75rem' }}>
        <div>
          <Typography style={{ fontWeight: 600 }}>Add a Payment Method</Typography>
          <Typography variant="body2">
            To enable a payment method for all transaction types
            simply leave the transaction types field blank.
            Please be aware that some payment methods
            may not be availabile for the company&apos;s currency.
          </Typography>
        </div>

        <div style={{ display: 'flex', gap: '1rem' }}>
          <PBInput
            type="select"
            label="Payment Methods"
            options={filteredPaymentMethods.map((type) => ({ label: type, value: type.toLowerCase().replace(' ', '_') }))}
            value={paymentMethod.type}
            onChange={(e) => setPaymentMethod((prev) => ({ ...prev, type: e.target.value }))}
            disabled={filteredPaymentMethods.length === 0}
          />
          <PBInput
            type="select"
            label="Transaction Types"
            multiple
            value={paymentMethod.transactionTypes}
            options={TRANSACTION_TYPES.map((type) => ({ label: type, value: type.toUpperCase().replace(' ', '_') }))}
            onChange={(e) => setPaymentMethod(
              (prev) => ({ ...prev, transactionTypes: e.target.value }),
            )}
            disabled={filteredPaymentMethods.length === 0}
          />
        </div>

        <div style={{ alignSelf: 'flex-end' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleChange}
            disabled={filteredPaymentMethods.length === 0 || paymentMethod.type === ''}
          >
            Add to Payment Methods
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default CompanyPaymentMethods;
