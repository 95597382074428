/* eslint-disable import/prefer-default-export */
import { takeEvery, call, put } from 'redux-saga/effects';
import { getMembershipService } from '../services/membershipsServices';
import * as actions from '../reducers/membershipsReducer';
import { handleSagaError } from './helperSaga';

function* getMembershipSaga(companyId, action) {
  const { membershipId } = action;
  if (companyId) {
    try {
      const response = yield call(getMembershipService, companyId, membershipId);
      if (!response.error) {
        yield put(actions.succeedMembershipRetrieval(response.data));
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'getMembershipSaga');
      yield put(actions.failMembershipRetrieval());
    }
  }
}

// WATCHERS

export function* getMembershipWatcher(companyId) {
  yield takeEvery(actions.MEMBERSHIP_RETRIEVAL_REQUESTED, getMembershipSaga, companyId);
}
