/* eslint-disable react/prop-types */
/* eslint-disable prefer-template */
import React, { useState, useMemo } from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import moment from 'moment';
import * as subscriptionsActions from '../../reducers/subscriptionsReducer';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import ToggleButtons from '../ToggleButtons';
import MenuHeading from '../MenuHeading';
import EditSubscriptionEndDate from './EditSubscriptionEndDate';
import { useMobile } from '../../hooks';

const EditSubscriptionDialog = ({
  subscription, membershipTable, membershipId,
}) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const {
    editedSubscriptionSuccess,
    editedSubscriptionError,
    editedSubscriptionErrorData,
  } = useSelector((state) => state.subscriptions);

  const [open, setOpen] = useState(false);
  const [editOption, setEditOption] = useState('NAME');
  const [name, setName] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const getSubscriptions = () => {
    dispatch(subscriptionsActions.getSubscriptions());
  };

  const editSubscription = (subscriptionId, newProperty) => dispatch(
    subscriptionsActions.editSubscription(subscriptionId, newProperty),
  );

  const updateEditedSubscription = (keyValue) => dispatch(
    subscriptionsActions.updateEditedSubscription(keyValue),
  );

  const resetSubscriptionStore = () => dispatch(subscriptionsActions.resetSubscriptionStore());

  const handleRequestClose = () => {
    resetSubscriptionStore();
    getSubscriptions();
    setOpen(false);
    setName(null);
  };

  const edit = () => {
    if (subscription.subscriptionName !== name && name !== null) {
      updateEditedSubscription({ subscriptionName: name });
    }
    editSubscription(subscription.id);
  };

  const editWithExclusions = () => {
    updateEditedSubscription({ isConflictsExcluded: true, validTo: endDate });
    edit();
  };

  const {
    conflictBookings,
    conflictSubscriptions,
    conflictClosures,
  } = useMemo(() => {
    if (editedSubscriptionErrorData) {
      const { allocations, subscriptions } = editedSubscriptionErrorData;
      const closures = editedSubscriptionErrorData.accessRestrictions.filter((x) => x.type === 'HOLIDAY');

      // Sort allocations by start Time
      allocations.sort((a, b) => moment(a.startTime).valueOf() - moment(b.startTime).valueOf());

      // Sort closures by validFrom
      closures.sort((a, b) => moment(a.validFrom).valueOf() - moment(b.validFrom).valueOf());

      return {
        conflictBookings: allocations.length > 0
          ? allocations : null,
        conflictSubscriptions: subscriptions.length > 0
          ? subscriptions : null,
        conflictClosures: closures.length > 0 ? closures : null,
      };
    }

    return {};
  }, [editedSubscriptionErrorData]);

  const editPropertyOptions = [
    ...(subscription.nextSubscriptionId ? [] : [{
      buttonTitle: 'Valid Until',
      buttonValue: 'VALIDTO',
    }]),
    {
      buttonTitle: 'Name',
      buttonValue: 'NAME',
    },
  ];

  return (
    <div>
      <Button
        id="manager-subscriptions-edit-subscription"
        dense="true"
        onClick={() => {
          setOpen(true);
        }}
        variant="contained"
        color="secondary"
      >
        Edit
      </Button>

      <Dialog open={open} fullScreen={isMobile}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>Edit subscription </DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={handleRequestClose} />
          </DialogActions>
        </div>
        <AlternativelyVisible condition={editedSubscriptionSuccess === null}>
          <>
            <DialogContent>
              <MenuHeading title="Which subscription property do you want to edit?" />
              <ToggleButtons
                buttonsData={editPropertyOptions}
                changeOption={setEditOption}
                value={editOption}
              />
              <ConditionallyVisible condition={editOption === 'VALIDTO'}>
                <EditSubscriptionEndDate
                  subscription={subscription}
                  updateEditedSubscription={updateEditedSubscription}
                  onChange={(e) => setEndDate(e)}
                />
              </ConditionallyVisible>
              <ConditionallyVisible condition={editOption === 'NAME'}>
                <TextField
                  id="subscriptionName"
                  label="Name"
                  margin="normal"
                  onChange={(event) => setName(event.target.value)}
                  value={name}
                />
              </ConditionallyVisible>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRequestClose} color="primary">
                Cancel
              </Button>
              <Button onClick={edit} color="secondary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </>
          <AlternativelyVisible condition={editedSubscriptionSuccess}>
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              The subscription was successfully edited.
            </Alert>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {editedSubscriptionError === 'Conflicts found' ? (
                <>
                  <Typography variant="body1" style={{ marginBottom: '1rem' }}>
                    There are conflicts with the new subscription dates. Please resolve them before
                    making this change.
                  </Typography>

                  {
                    conflictBookings
                    && (
                      <div>
                        <Typography variant="body2">Bookings</Typography>
                        <List>
                          {conflictBookings.map((x) => (
                            <ListItem key={x.id}>
                              <ListItemText
                                primary={x.facilityName + ' (' + x.firstName + ' ' + x.lastName + ')'}
                                secondary={moment(x.startTime).format('DD/MMM/YYYY HH:mm') + ' - ' + moment(x.endTime).add(1, 's').format('HH:mm')}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    )
                  }
                  {
                    conflictSubscriptions
                    && (
                      <div>
                        <Typography variant="body2">Subscriptions</Typography>
                        <List>
                          {conflictSubscriptions.map((x) => (
                            <ListItem key={x.id}>
                              <ListItemText
                                primary={x.subscriptionName}
                                secondary={`${moment(x.accessRestriction.validFrom).format('DD/MMM/YYYY')} - ${moment(x.accessRestriction.validTo).format('DD/MMM/YYYY')}`}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    )
                  }
                  {
                    conflictClosures
                    && (
                      <div>
                        <Typography variant="body2">Closures</Typography>
                        <List>
                          {conflictClosures.map((x) => {
                            const startDate = moment(x.validFrom).format('DD/MMM/YYYY');
                            const endDate = moment(x.validTo).format('DD/MMM/YYYY');

                            return (
                              <ListItem key={x.id}>
                                <ListItemText
                                  primary={x.description}
                                  secondary={
                                    startDate === endDate
                                      ? `On ${startDate}`
                                      : `From ${startDate} to ${endDate}`
                                  }
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      </div>
                    )
                  }

                  {
                    !conflictSubscriptions ? (
                      <>
                        <hr />
                        <Typography variant="body1" style={{ marginBottom: '1rem' }}>
                          You can continue with this edit, however, it will create exclusions for
                          any conflicts found.
                        </Typography>

                        <Button variant="contained" color="secondary" onClick={editWithExclusions}>
                          Continue with Exclusions
                        </Button>
                      </>
                    ) : (
                      <>
                        <hr />
                        <Typography variant="body1" style={{ marginBottom: '1rem' }}>
                          You must manually resolve these conflicts before continuing with
                          this edit.
                        </Typography>
                      </>
                    )
                  }
                </>
              ) : (
                <>
                  <Typography variant="body1">
                    An error occurred while editing this subscription.
                  </Typography>
                  <Typography variant="body1">
                    Please contact us at info@pitchbooking.com.
                  </Typography>
                </>
              )}
            </Alert>
            <DialogActions>
              <Button onClick={handleRequestClose} color="primary">
                OK
              </Button>
            </DialogActions>
          </AlternativelyVisible>
          <ConditionallyVisible condition>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              There was an error editing the subscription.
              {editedSubscriptionError}
            </Alert>
          </ConditionallyVisible>
        </AlternativelyVisible>
      </Dialog>
    </div>
  );
};

EditSubscriptionDialog.propTypes = {
  subscription: PropTypes.shape().isRequired,
};

export default EditSubscriptionDialog;
