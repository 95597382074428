/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { useSelector } from 'react-redux';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';

const FixtureCostDialog = ({ saveResult, fixtureId }) => {
  const [open, setOpen] = useState(false);
  const currentCost = useSelector((state) => state.leagues?.league?.costPerFixture);
  const currency = useSelector((state) => state.companies?.companyInfo?.currency);
  const currencySym = getCurrencySymbol(currency);
  const [updatingCost, setUpdatingCost] = useState(false);
  const [fixtureCost, setFixtureCost] = useState(currentCost);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="secondary" variant="contained" onClick={() => handleClickOpen()}>
        Save Result
      </Button>
      <Dialog
        open={open}
        fullWidth
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>
            Save Result
          </DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => handleRequestClose()} />
          </DialogActions>
        </div>
        <DialogContent>

          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <h3>Would you like to change the cost of this fixture?</h3>
                  <h4>{`Current Fixture Cost: ${currencySym}${fixtureCost}`}</h4>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                  >
                    <h4>No</h4>
                    <PBInput
                      type="toggle"
                      options={[{ value: true }, { value: false }]}
                      onChange={(e) => setUpdatingCost(e.target.checked)}
                    />
                    <h4>Yes</h4>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <PBInput
                    id="fixtureCost"
                    name="fixtureCost"
                    type="number"
                    onChange={(e) => setFixtureCost(e.target.value)}
                    label={`Fixture Cost (${currencySym})`}
                    value={fixtureCost}
                    disabled={!updatingCost}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => saveResult(fixtureId, fixtureCost)} color="primary" variant="contained">
            Save Result
          </Button>
          <Button onClick={() => handleRequestClose()} color="secondary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FixtureCostDialog;
