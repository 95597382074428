import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import { withStyles } from '@material-ui/core/styles';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as leagueActions from '../../../reducers/leaguesReducer';

const styles = () => ({
  root: {
    minWidth: 400,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});

const GoalScorersDialog = (props) => {
  const [open, setOpen] = useState(false);
  const {
    getTeamPlayers, fixtureId, resetFixture,
    fixture, goalScorers,
  } = props;

  useEffect(() => {
    if (open) {
      getTeamPlayers(fixtureId);
    } else {
      resetFixture();
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="secondary" variant="contained" onClick={() => handleClickOpen()}>
        View Goal Scorers
      </Button>
      <Dialog
        open={open}
        fullWidth
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>
            View Goal Scorers
          </DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => handleRequestClose()} />
          </DialogActions>
        </div>
        <DialogContent>

          <h3>
            {fixture.teamA.name}
            {' '}
            {fixture.teamAScore}
            {' '}
            vs
            {' '}
            {fixture.teamBScore}
            {' '}
            {fixture.teamB.name}
          </h3>
          <h4>
            {' '}
            {fixture.teamA.name}
          </h4>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Player</TableCell>
                <TableCell align="right">Goals Scored</TableCell>
              </TableRow>
              {goalScorers.map((scorer) => (
                <TableRow>
                  <TableCell>
                    {scorer.user.firstName}
                    {' '}
                    {scorer.user.lastName}
                  </TableCell>
                  <TableCell align="right">{scorer.goals}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleRequestClose()} color="secondary" variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

GoalScorersDialog.propTypes = {
  getTeamPlayers: PropTypes.func.isRequired,
  resetFixture: PropTypes.func.isRequired,
  fixtureId: PropTypes.string.isRequired,
  fixture: PropTypes.shape().isRequired,
  goalScorers: PropTypes.shape().isRequired,
};

function mapStateToProps(state) {
  const { leagues } = state;
  const { fixture } = leagues;
  return {
    fixture,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getTeamPlayers: (fixtureId) => dispatch(leagueActions.getTeamPlayers(fixtureId)),
  resetFixture: () => dispatch(leagueActions.resetFixture()),
  addGoalScorerToFixture: (fixtureId, userId, totalGoals) => dispatch(
    leagueActions.addGoalScorerToFixture(fixtureId, userId, totalGoals),
  ),
  submitGoalScorers: () => dispatch(leagueActions.submitGoalScorers()),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withStyles(styles())(GoalScorersDialog));
