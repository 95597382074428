import React from 'react';
import Button from '@material-ui/core/Button';

class PrintButton extends React.Component {
  render() {
    return (
      <div>
        <Button
          id="manager-calendar-print"
          variant="contained"
          color="secondary"
          style={{ marginLeft: '20px', width: 175 }}
          onClick={() => window.print()}
        >
          Print
        </Button>
      </div>
    );
  }
}

export default (PrintButton);
