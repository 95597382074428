import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import { Alert } from '@material-ui/lab';
import LoadingSkeletonBarChart from '../../../../components/LoadingComponents/LoadingSkeletonBarChart';
import FailedSection from '../../../../components/LoadingComponents/FailedSection';
import { CategoryChartBaseBarHorizontal } from './bases/CategoryChartBaseBarHorizontal';
import { CategoryHeaderBase } from './bases/CategoryTitleBase';

function CategoryRevenueByFacility(props) {
  const {
    analyticsBookings,
    analyticsTimeframe,
    analyticsTimeframeCustom,
    currencySym,
    analyticsLoading,
    analyticsFailed,
  } = props;

  const { bookings, xAxisRevenueByFacility, yAxisRevenueByFacility } = analyticsBookings;

  const [currentPage, setCurrentPage] = useState(0);
  const [slicedXAxisData, setXAxisData] = useState([]);
  const [slicedYAxisData, setYAxisData] = useState([]);
  const rowsPerPage = 20;

  let noData = false;

  if (xAxisRevenueByFacility?.length === 0 || yAxisRevenueByFacility?.length === 0) {
    noData = true;
  }

  useEffect(() => {
    let startIndex = currentPage * rowsPerPage;
    let endIndex = startIndex + rowsPerPage;

    if (endIndex > xAxisRevenueByFacility.length && startIndex !== 0) {
      endIndex = xAxisRevenueByFacility.length;
      startIndex = endIndex - rowsPerPage;
    }

    if (yAxisRevenueByFacility[0] !== undefined) {
      setXAxisData(xAxisRevenueByFacility.slice(startIndex, endIndex));
      setYAxisData(yAxisRevenueByFacility.slice(startIndex, endIndex));
    }
  }, [currentPage, analyticsBookings]);

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if ((currentPage + 1) * rowsPerPage < xAxisRevenueByFacility.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="category" id="RevenueByFacility" style={{ width: '100%', height: '100vh' }}>
      <CategoryHeaderBase categoryName="Revenue By Facility" />
      <AlternativelyVisible condition={noData}>
        <></>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePreviousPage}
            disabled={currentPage === 0}
            style={{ margin: '0px 20px' }}
          >
            {String.fromCharCode(8592)}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNextPage}
            disabled={(currentPage + 1) * rowsPerPage >= xAxisRevenueByFacility.length}
            style={{ margin: '0px 20px' }}
          >
            {String.fromCharCode(8594)}
          </Button>
        </div>
      </AlternativelyVisible>
      <AlternativelyVisible condition={analyticsLoading}>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: '1',
        }}
        >
          <LoadingSkeletonBarChart />
        </div>
        <AlternativelyVisible condition={analyticsFailed}>
          <FailedSection />
          <AlternativelyVisible condition={noData}>
            <div style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: '1',
            }}
            >
              <Alert severity="warning">
                No Active Facilities
              </Alert>
            </div>
            <CategoryChartBaseBarHorizontal
              backgroundColor="#90EE9030"
              bookings={bookings}
              borderColor="#00000020"
              categoryName="RevenueByFacility"
              analyticsTimeframe={analyticsTimeframe}
              analyticsTimeframeCustom={analyticsTimeframeCustom}
              xAxis={slicedXAxisData}
              xAxisLabel={`Revenue in ${currencySym}`}
              yAxis={slicedYAxisData}
            />
          </AlternativelyVisible>
        </AlternativelyVisible>
      </AlternativelyVisible>
    </div>
  );
}

CategoryRevenueByFacility.propTypes = {
  analyticsBookings: PropTypes.shape().isRequired,
  analyticsTimeframe: PropTypes.shape().isRequired,
  analyticsTimeframeCustom: PropTypes.shape().isRequired,
  currencySym: PropTypes.string.isRequired,
  analyticsLoading: PropTypes.bool.isRequired,
  analyticsFailed: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    analyticsBookings: state.analytics.analyticsBookings,
    analyticsTimeframe: state.analytics.analyticsTimeframe,
    analyticsTimeframeCustom: state.analytics.analyticsTimeframeCustom,
    currencySym: state.companies.companyInfo.currencySym,
    analyticsLoading: state.analytics.analyticsLoading,
    analyticsFailed: state.analytics.analyticsFailed,
  };
}

export default connect(mapStateToProps)(CategoryRevenueByFacility);
