/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { getBlogAssets, getBlogEntries, attachImageToPosts } from '../../services/contentfulService';
import '../../styles/help.css';

const FAQPage = () => {
  const [blog, setBlog] = useState({ fields: {} });
  const [image, setImage] = useState('');

  useEffect(() => {
    const loadData = async () => {
      const assetsResponse = await getBlogAssets();
      const assets = assetsResponse.includes.Asset;
      let fetchedBlogPosts = await getBlogEntries({ content_type: 'faq' });
      fetchedBlogPosts = attachImageToPosts(fetchedBlogPosts, assets);

      setBlog(fetchedBlogPosts[0]);
      setImage(fetchedBlogPosts[0].fields.featuredImage.url);
    };

    loadData();
  }, []);

  const post = blog;
  if (!blog) {
    return (
      <div className="blogContainer" />
    );
  }

  return (
    <div className="helpContainer">
      <img className="knowledgeViewImage" alt="Pitchbooking Manager Guide" src={image} width="100%" />
      <br />
      <br />
      <Typography variant="h5" gutterBottom><b>{post.fields.title}</b></Typography>
      <ReactMarkdown children={post.fields.body} />
    </div>
  );
};

export default connect()(FAQPage);
