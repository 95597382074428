/* eslint-disable import/prefer-default-export */
import {
  takeEvery, call, put, select,
} from 'redux-saga/effects';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  getCalendarUsageService,
  createCalendarNoteService,
  deleteCalendarNoteService,
} from '../services/calendarServices';
import {
  getCompanySitesService,
} from '../services/companiesServices';
import * as actions from '../reducers/calendarReducer';
import * as subscriptionActions from '../reducers/subscriptionsReducer';
import { handleSagaError } from './helperSaga';

function* getCalendarUsageSaga(companyId) {
  const state = yield select();
  const { session } = state;
  const { selectedSiteId, selectedDate } = session;
  if (!selectedSiteId) {
    return;
  }
  try {
    const response = yield call(
      getCalendarUsageService,
      companyId,
      selectedSiteId,
      selectedDate,
    );
    if (!response.error) {
      yield put(actions.updateCalendarUsage(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getCalendarUsageSaga');
    yield put(actions.updateCalendarUsageFail());
    yield put(sessionActions.updateErrorSnackbar('Calendar failed to load. Ensure you selected a location from the dropdown', true));
  }
}

function* getCompanySitesSaga(companyId) {
  if (companyId) {
    try {
      const response = yield call(getCompanySitesService, companyId);
      if (!response.error) {
        yield put(actions.updateCompanySites(response.data));
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'getCompanySitesSaga');
    }
  }
}

function* createCalendarNoteSaga(companyId, action) {
  const { note, date } = action;
  try {
    const response = yield call(
      createCalendarNoteService,
      companyId,
      note,
      date,
    );
    if (!response.error) {
      yield put(actions.getCalendarUsage());
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'createCalendarNoteSaga');
  }
}

function* deleteCalendarNoteSaga(companyId, action) {
  const { noteId, subscriptionId } = action;
  try {
    const response = yield call(
      deleteCalendarNoteService,
      companyId,
      noteId,
    );
    if (!response.error) {
      yield put(subscriptionId
        ? subscriptionActions.getSubscription(subscriptionId) : actions.getCalendarUsage());
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'deleteCalendarNoteSaga');
  }
}

// WATCHERS
export function* calendarWatcher(companyId) {
  yield takeEvery(actions.CALENDAR_USAGE_REQUESTED, getCalendarUsageSaga, companyId);
}
export function* calendarSitesWatcher(companyId) {
  yield takeEvery(actions.COMPANY_SITES_REQUESTED, getCompanySitesSaga, companyId);
}

export function* calendarNoteWatcher(companyId) {
  yield takeEvery(actions.CREATE_CALENDAR_NOTE, createCalendarNoteSaga, companyId);
}

export function* calendarNoteDeleteWatcher(companyId) {
  yield takeEvery(actions.DELETE_CALENDAR_NOTE_BY_ID, deleteCalendarNoteSaga, companyId);
}
