import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import AddMemberDialog from '../memberships/components/AddMemberDialog';
import * as actions from '../../reducers/invoicesReducer';

class ResendInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
      selectUserModalOpen: false,
    };
  }

    performAction = (invoiceId, userIds = null) => {
      const { resendInvoice } = this.props;
      this.setState({
        sent: true,
      });
      resendInvoice(invoiceId, userIds);
    }

    openSelectUserModal = () => {
      this.setState({
        selectUserModalOpen: true,
      });
    }

    render() {
      const { invoiceId, toAdditionalUser, users } = this.props;
      const { sent, selectUserModalOpen } = this.state;
      let buttonLabel = 'Resend';
      let userList = [];
      if (users && users.length > 0) {
        userList = users.map((user) => ({
          label: `${user.firstName} ${user.lastName} <${user.email}>`,
          fullName: `${user.firstName} ${user.lastName}`,
          email: `${user.email}`,
          id: `${user.id}`,
        }));
      }

      if (toAdditionalUser) {
        buttonLabel = 'Send to Additional User';
      }

      return (
        <AlternativelyVisible condition={!sent}>
          <>
            <Button
              id="manager-bookings-resend-invoice"
              variant="contained"
              color="secondary"
              onClick={() => (toAdditionalUser
                ? this.openSelectUserModal() : this.performAction(invoiceId))}
              disabled={sent}
            >
              {buttonLabel}
              {selectUserModalOpen}
            </Button>
            <Dialog
              open={selectUserModalOpen}
              onRequestClose={() => this.setState({ selectUserModalOpen: false })}
            >
              <AddMemberDialog
                membershipName="Resend to Additional User"
                membershipID={null}
                selectUserForInvoice
                open={selectUserModalOpen}
                addUsers={(userIDs) => this.performAction(invoiceId, userIDs)}
                userList={userList}
                closeDialog={() => this.setState({ selectUserModalOpen: false })}
              />
            </Dialog>
          </>
          <div style={{
            color: '#47FEB4', marginRight: '20px', marginTop: '10px', minWidth: 175, alignSelf: 'center',
          }}
          >
            Success! Invoice resent.
          </div>
        </AlternativelyVisible>

      );
    }
}

ResendInvoice.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  resendInvoice: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  toAdditionalUser: PropTypes.bool,
};

ResendInvoice.defaultProps = {
  toAdditionalUser: false,
};

function mapStateToProps(state) {
  const { users } = state.users;
  return {
    users,
  };
}

const mapDispatchToProps = (dispatch) => ({
  resendInvoice: (id, userIds) => dispatch(actions.resendInvoice(id, userIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResendInvoice);
