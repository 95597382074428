import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Dialog, DialogActions, DialogTitle, DialogContent,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import * as formActions from '../../reducers/formsReducer';

const FormSelectorDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [formattedForms, setFormattedForms] = useState([]);
  const dispatch = useDispatch();

  const forms = useSelector((state) => state.forms.forms);

  useEffect(() => {
    dispatch(formActions.getCompanyFormsRequest());
  }, []);

  useEffect(() => {
    if (forms) {
      const formatted = forms.map((form) => ({
        label: form.name,
        value: form.id,
      }));
      setFormattedForms(formatted);
    }
  }, [forms]);

  const { resourceId, type } = props;

  const initialValues = {
    formId: '',
  };

  return (
    <>
      <Button variant="contained" color="secondary" aria-label="edit" onClick={() => setOpen(true)}>
        Select Existing Form
      </Button>
      <Dialog open={open}>
        <DialogTitle>Select Existing Form</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                const { form } = values;

                dispatch(formActions.updateResourceFormRequest(form, type, resourceId));

                setOpen(false);
              } catch (err) {
                console.error(err);
              }
              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              touched,
              values,
              isSubmitting,
            }) => (
              <Form>
                <PBInput
                  id="form"
                  type="select"
                  placeholder="Select Form"
                  options={formattedForms}
                  label="Select Form"
                  value={values.form}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={touched.forms && errors.forms}
                  isError={touched.forms && Boolean(errors.forms)}
                />

                <DialogActions>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                  <Button
                    onClick={() => setOpen(false)}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

FormSelectorDialog.propTypes = {
  resourceId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
export default FormSelectorDialog;
