// @flow weak

import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Tree from 'react-hierarchy-tree-graph';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';

class FacilityTreeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      buttonDisabled: false,
    };
  }

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { data } = this.props;
    const { buttonDisabled, open } = this.state;

    return (
      <div>
        <Button
          id="facility-overlaps"
          variant="contained"
          style={{
            marginRight: '10px',
            width: 200,
            backgroundColor: '#6699FF',
            color: '#fff',
          }}
          onClick={() => this.setState({ open: true })}
        >
          Facility Overlaps
        </Button>
        <Dialog open={open} fullScreen>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>Facility Overlaps</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <DialogContent>
            <div id="treeWrapper" style={{ width: '100%', height: '100%' }}>
              <Tree data={data} orientation="vertical" />
            </div>
          </DialogContent>
          <DialogActions>
            <Button disabled={buttonDisabled} variant="contained" onClick={() => this.handleRequestClose()} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

FacilityTreeDialog.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default FacilityTreeDialog;
