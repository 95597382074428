import moment from 'moment';

const errorShape = (isValid, errorMessage) => ({ isValid, errorMessage });

const facilitiesInvalid = (newAccessRestriction) => newAccessRestriction.facilities.length === 0;
const descriptionInvalid = (newAccessRestriction) => newAccessRestriction.description === '';
const startTimeInvalid = (newAccessRestriction) => (!newAccessRestriction.startTime && (newAccessRestriction.recurrence !== 'ALWAYS'));
const endTimeInvalid = (newAccessRestriction) => (!newAccessRestriction.endTime && (newAccessRestriction.recurrence !== 'ALWAYS'));
const validFromInvalid = (newAccessRestriction) => !newAccessRestriction.validFrom;
const validToInvalid = (newAccessRestriction) => !newAccessRestriction.validTo;
const weekdayInvalid = (newAccessRestriction) => !newAccessRestriction.weekday
  && newAccessRestriction.weekday !== 0;
const membershipsInvalid = (newAccessRestriction) => newAccessRestriction.type === 'MEMBER_ACCESS' && newAccessRestriction.memberships.length === 0;
const validFromSelected = (newAccessRestriction) => newAccessRestriction.startDateOption === 'YES';
const validToSelected = (newAccessRestriction) => newAccessRestriction.endDateOption === 'YES';
const typeInvalid = (newAccessRestriction) => !newAccessRestriction.type;

const validToIsBeforeValidFrom = (newAccessRestriction) => {
  const { validFrom, validTo } = newAccessRestriction;
  return moment(validTo).isBefore(validFrom);
};

const endTimeIsBeforeStartTime = (newAccessRestriction) => {
  const { startTime, endTime } = newAccessRestriction;
  return moment(endTime).isBefore(startTime);
};

const validityCorrectlyConfigured = (newAccessRestriction) => {
  const { recurrence } = newAccessRestriction;
  let correctlyConfigured = true;
  let errorMessage = '';

  const validFromSelectedButInvalid = validFromSelected(newAccessRestriction)
  && validFromInvalid(newAccessRestriction);

  const validToSelectedButInvalid = validToSelected(newAccessRestriction)
  && validToInvalid(newAccessRestriction);

  const validToSelectedAndValidButOutOfOrder = validToSelected(newAccessRestriction)
  && !validToInvalid(newAccessRestriction)
  && validToIsBeforeValidFrom(newAccessRestriction);

  switch (recurrence) {
    case 'NONE':
      if (!validFromSelected(newAccessRestriction)
      || validFromInvalid(newAccessRestriction)) {
        correctlyConfigured = false;
        errorMessage = 'Please select a valid start date.';
        break;
      }
      if (!validToSelected(newAccessRestriction)
      || validToInvalid(newAccessRestriction)) {
        correctlyConfigured = false;
        errorMessage = 'Please select a valid end date.';
        break;
      }
      if (validToIsBeforeValidFrom(newAccessRestriction)) {
        correctlyConfigured = false;
        errorMessage = 'Start date must be before end date.';
        break;
      }
      break;
    case 'DAILY':
      if (validFromSelectedButInvalid) {
        correctlyConfigured = false;
        errorMessage = 'Please choose a valid start date.';
        break;
      }
      if (validToSelectedButInvalid) {
        correctlyConfigured = false;
        errorMessage = 'Please choose a valid end date.';
        break;
      }
      if (validToSelectedAndValidButOutOfOrder) {
        correctlyConfigured = false;
        errorMessage = 'Start date must be before end date.';
        break;
      }
      break;
    case 'WEEKLY':
      if (weekdayInvalid(newAccessRestriction)) {
        correctlyConfigured = false;
        errorMessage = 'Weekday must be selected.';
        break;
      }
      if (validFromSelectedButInvalid) {
        correctlyConfigured = false;
        errorMessage = 'Please choose a valid start date.';
        break;
      }
      if (validToSelectedButInvalid) {
        correctlyConfigured = false;
        errorMessage = 'Please choose a valid end date.';
        break;
      }
      if (validToSelectedAndValidButOutOfOrder) {
        correctlyConfigured = false;
        errorMessage = 'Start date must be before end date.';
        break;
      }
      break;
    default:
  }

  return { correctlyConfigured, errorMessage };
};

export const newAccessRestrictionIsValid = (state) => {
  const { newAccessRestriction } = state.accessRestrictions;

  if (facilitiesInvalid(newAccessRestriction)) return errorShape(false, 'Please select at least one facility.');
  if (descriptionInvalid(newAccessRestriction)) return errorShape(false, 'Please input a name.');
  if (startTimeInvalid(newAccessRestriction)) return errorShape(false, 'Please input a valid start time.');
  if (endTimeInvalid(newAccessRestriction)) return errorShape(false, 'Please input a valid end time.');
  if (endTimeIsBeforeStartTime(newAccessRestriction)) return errorShape(false, 'End time must not be before start time.');

  const { correctlyConfigured, errorMessage } = validityCorrectlyConfigured(newAccessRestriction);
  if (!correctlyConfigured) return errorShape(false, errorMessage);

  if (typeInvalid(newAccessRestriction)) return errorShape(false, 'You must select a type.');
  if (membershipsInvalid(newAccessRestriction)) return errorShape(false, 'At least one membership must be selected.');

  return errorShape(true, '');
};

export default newAccessRestrictionIsValid;
