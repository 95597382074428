import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as leagueActions from '../../../reducers/leaguesReducer';
import { useMobile } from '../../../hooks';

function DeleteTeamDialog({ leagueId, teamId, teamName }) {
  const [open, setOpen] = useState(false);
  const isMobile = useMobile();
  const dispatch = useDispatch();

  return (
    <div>
      {/* Table Button */}
      <Button color="secondary" variant="contained" onClick={() => setOpen(true)}>
        Delete Team
      </Button>

      <Dialog
        open={open}
        maxWidth={isMobile ? 'xs' : 'xl'}
      >
        {/* Close Icon */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '1rem 1rem 0rem 1rem',
          }}
        >
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => setOpen(!open)} />
          </DialogActions>
        </div>

        {/* Title */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            padding: '0rem 2rem',
          }}
        >

          <DialogTitle>
            <Alert severity="info" style={{ margin: '0 1rem' }}>
              It may take some time for this change to be reflected in the league standings.
            </Alert>
            <div style={{ marginTop: '1rem' }}>
              Are you sure you want to delete
              the following team?
            </div>
          </DialogTitle>
        </div>

        {/* Body */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginLeft: '3.5rem',
          }}
        >
          <Typography variant="subtitle1">
            {`Team Name: ${teamName}`}
          </Typography>
        </div>

        {/* Action Buttons */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            margin: '1.5rem',
          }}
        >
          <Button
            variant="contained"
            endIcon={<DeleteIcon />}
            onClick={() => {
              dispatch(leagueActions.deleteLeagueTeam(leagueId, teamId));
              setOpen(false);
            }}
          >
            Delete Team
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpen(!open)}
          >
            Cancel
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

DeleteTeamDialog.propTypes = {
  leagueId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
};

export default DeleteTeamDialog;
