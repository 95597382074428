import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const CloseDialogIcon = ({ onClick, color }) => (
  <>
    <IconButton onClick={() => onClick()}>
      <HighlightOffIcon style={{ color }} />
    </IconButton>
  </>
);

export default CloseDialogIcon;

CloseDialogIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
};
CloseDialogIcon.defaultProps = {
  color: '#0000008a',
};
