/*
    The StatisticLabel component simply represents a
    label that is used in the Header<> component.
*/

import React from 'react';
import PropTypes from 'prop-types';

export class StatisticLabel extends React.Component {
  render() {
    const { labelString } = this.props;
    return (
      <div className="statisticLabel">
        {labelString}
      </div>
    );
  }
}

StatisticLabel.propTypes = {
  labelString: PropTypes.string.isRequired,
};
