/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import {
  convertFromRaw, EditorState, convertToRaw, ContentState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import { getMembershipFormData } from '../../../services/membershipsServices';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import { updateMembership } from '../../../reducers/accessRestrictionsReducer';
import { useMobile } from '../../../hooks';
import EditMembershipFormDialog from './EditMembershipFormDialog';

const EditMembershipDialog = ({
  membership,
}) => {
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const [editorState, setEditorState] = useState();
  const [open, setOpen] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      name: membership?.name,
      description: membership?.description,
      additionalEmailInformation: membership?.additionalEmailInformation,
      requiresApproval: membership?.requiresApproval,
      visible: membership?.visible,
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(updateMembership(membership.id, values));
      setSubmitting(false);
    },
  });

  const getFormData = async () => {
    try {
      const formData = await getMembershipFormData(
        membership.id, membership.formId,
      );
      return formData;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formik.values.description) {
      try {
        const contentState = convertFromRaw(JSON.parse(formik.values.description));
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      } catch (err) {
        setEditorState(
          EditorState.createWithContent(ContentState.createFromText(formik.values.description)),
        );
      }
    } else {
      setEditorState(
        EditorState.createEmpty(),
      );
    }
  }, []);

  return (
    <div>
      <Button
        id="manager-users-edit-membership"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        <Typography
          style={{
            display: 'flex', alignItems: 'center', gap: '0.5rem', fontWeight: '600',
          }}
          variant="button"
        >
          <EditTwoToneIcon />
          Edit
        </Typography>
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)} fullScreen={isMobile}>
        {formik.isSubmitting && <LinearProgress />}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>Change Membership Details</DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => setOpen(false)} />
          </DialogActions>
        </div>
        <DialogContent style={{ width: 500 }}>
          <DialogContentText>
            Modify Membership Details
          </DialogContentText>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <PBInput
              id="name"
              label="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
            />

            {/* <PBInput
              id="description"
              label="Description"
              value={formik.values.description}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
            /> */}

            {/* Markdown Description */}
            <>
              <div>
                <Editor
                  editorStyle={{
                    border: '1px solid #ccc', minHeight: '2rem', padding: '0.5rem', borderTop: 'none', borderRadius: '0 0 0.25rem 0.25rem',
                  }}
                  toolbarStyle={{
                    border: '1px solid #ccc', borderRadius: '0.25rem 0.25rem 0 0', margin: 0,
                  }}
                  editorState={editorState}
                  onEditorStateChange={(editorState) => {
                    setEditorState(editorState);
                    formik.setFieldValue('description', JSON.stringify(convertToRaw(editorState.getCurrentContent())));
                  }}
                  toolbar={{
                    options: ['inline', 'blockType', 'list', 'link', 'history'],
                    inline: {
                      options: ['bold', 'italic'],
                    },
                    blockType: {
                      inDropdown: true,
                      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                    },
                  }}
                  placeholder="Enter your description here..."
                  stripPastedStyles
                  required
                />
              </div>
              {formik.errors?.membershipDescription && (
              <div style={{ display: 'flex ', color: 'red' }}>{formik.errors?.membershipDescription}</div>
              )}
            </>

            <PBInput
              id="additionalEmailInformation"
              label="Additional Email Information"
              type="textarea"
              value={formik.values.additionalEmailInformation}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
            />
          </div>
          <div>
            <PBInput
              id="visible"
              name="visible"
              type="toggle"
              label="Make Visible on Partner Page"
              value={formik.values?.visible}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isError={formik.touched?.visible && Boolean(formik.errors.visible)}
              errorMessage={formik.touched?.visible && formik.errors?.visible}
            />
          </div>
          <div>
            <PBInput
              id="requiresApproval"
              name="requiresApproval"
              type="toggle"
              label="Requires Approval"
              value={formik.values?.requiresApproval}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isError={formik.touched?.requiresApproval && Boolean(formik.errors.requiresApproval)}
              errorMessage={formik.touched?.requiresApproval && formik.errors?.requiresApproval}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>

          <ConditionallyVisible condition={membership.formId}>
            <EditMembershipFormDialog
              getMembershipFormData={getFormData}
              membershipId={membership.id}
              membershipFormId={membership.formId}
            />
          </ConditionallyVisible>

          <Button onClick={formik.handleSubmit} color="primary" variant="contained">
            Change Details
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
};

EditMembershipDialog.propTypes = {
  membership: PropTypes.shape().isRequired,
};

export default EditMembershipDialog;
