/* eslint-disable camelcase */
import * as contentful from 'contentful';

const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

const allTags = ['testimonial', 'announcement', 'socialImpact', 'newSigning', 'blog', 'legal']; // Ordered by Priority for Primary Tag

export const getPrimaryTag = (post) => {
  const postTags = post.tags || [];
  return allTags.find((tag) => postTags.includes(tag)) || null;
};

export const getBlogEntries = async ({
  isFeatured = false,
  limit = '999',
  order = '-sys.createdAt',
  tags = [],
  excludeTags = ['legal'], // Exclude legal tags by default, unless it exists in tags
  skip,
  content_type = '2wKn6yEnZewu2SCCkus4as', // content_type is Post
  slug = null,
} = {}) => {
  const queryParameters = {
    content_type,
    limit,
    order,
  };

  if (skip) {
    queryParameters.skip = skip;
  }

  if (isFeatured) {
    queryParameters['fields.featuredPost'] = 'true';
  }
  if (tags.length > 0) {
    queryParameters['metadata.tags.sys.id[in]'] = tags.filter((tag) => allTags.includes(tag)).join(',');
  }
  const tagsToExclude = excludeTags
    .filter((tag) => allTags.includes(tag) && !tags.includes(tag));
  if (tagsToExclude.length > 0) {
    queryParameters['metadata.tags.sys.id[nin]'] = tagsToExclude.join(',');
  }

  if (slug) {
    queryParameters['fields.slug[in]'] = slug;
  }

  const contents = await client.getEntries(queryParameters);
  const items = contents.items || [];
  const posts = items.filter((post) => post.sys.contentType.sys.id);
  return posts;
};

export const getBlogAssets = async () => client.getEntries({ limit: '999' });

const defaultImageUrl = 'https://images.contentful.com/usvb7o98ifrz/6tsvDCVWecsMWKEa8yAwcQ/002d601b5314bc599fba1a1450909f4c/photo-1507104530636-1552f2a4127a.jpeg';

export const attachImageToPosts = (posts, assets) => posts.map((post) => {
  let updatedFeaturedImage = post.fields.featuredImage;
  if (updatedFeaturedImage) {
    const imageAsset = assets.find((asset) => asset.sys.id === updatedFeaturedImage.sys.id);
    updatedFeaturedImage.url = imageAsset ? imageAsset.fields.file.url : defaultImageUrl;
  } else {
    updatedFeaturedImage = { url: defaultImageUrl };
  }
  return {
    ...post,
    fields: {
      ...post.fields,
      featuredImage: updatedFeaturedImage,
    },
  };
});
