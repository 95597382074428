import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import '../../styles/billing.css';
import NumberFormat from 'react-number-format';

const payoutReconciliationTable = (props) => {
  const { payout, currencySym } = props;
  let { costCodeTotals } = payout;
  if (costCodeTotals === null) {
    costCodeTotals = [];
  }

  return (
    <div style={{ minWidth: 500 }}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="left" style={{ fontWeight: 800 }}>Cost Code</TableCell>
              <TableCell align="right" style={{ fontWeight: 800 }}>Total</TableCell>
            </TableRow>
            {costCodeTotals.map((cc) => (
              <TableRow>
                <TableCell align="left">{cc.costCode}</TableCell>
                <TableCell align="right">
                  <NumberFormat displayType="text" thousandSeparator prefix={currencySym} value={cc.total.toFixed(2)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

payoutReconciliationTable.propTypes = {
  payout: PropTypes.number.isRequired,
  currencySym: PropTypes.string,
};
payoutReconciliationTable.defaultProps = {
  currencySym: '£',
};

export default payoutReconciliationTable;
