import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import FileCopy from '@material-ui/icons/FileCopy';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import * as pricingActions from '../../reducers/pricingReducer';
import PricingAddRuleDialogSection from './PricingAddRuleDialogSection';

const DuplicatePriceGroupDialog = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [counter, setCounter] = useState(0);
  const [isDuplicatedDisabled, setIsDuplicateDisabled] = useState(false);

  const handleOpen = () => {
    const { updatePriceGroupStore, row } = props;
    setDialogOpen(true);
    updatePriceGroupStore({ ...row });
    setCounter(0);
  };

  const handleClose = () => {
    const { resetPriceGroupCreation } = props;
    resetPriceGroupCreation();
    setDialogOpen(false);
    setCounter(0);
  };

  const handleDuplicate = () => {
    const { resetPriceGroupCreation, requestPriceGroupDuplicate } = props;
    requestPriceGroupDuplicate();
    resetPriceGroupCreation();
    setDialogOpen(false);
    setCounter(0);
  };

  const {
    row,
    membershipTag,
    facilityTag,
    updatePriceGroupStore,
    companyMemberships,
    priceGroup,
  } = props;

  const {
    endDate, startDate, membership,
  } = priceGroup;

  const priceGroupStore = useSelector((state) => state.pricing.priceGroup);

  // TODO: Implement a better way to handle this - this is a temporary fix
  // ? there is a branch that implements formik to handle this
  useEffect(() => {
    if (priceGroupStore) {
      setCounter((prevCounter) => prevCounter + 1);
    }
  }, [priceGroupStore]);

  useEffect(() => {
    if (counter === 1) {
      setIsDuplicateDisabled(true);
    } else {
      setIsDuplicateDisabled(false);
    }
  }, [counter]);

  return (
    <div>
      <Button style={{ color: '#7ce8af' }} aria-label="edit" onClick={handleOpen}>
        <FileCopy />
      </Button>
      <Dialog open={dialogOpen}>
        <DialogTitle>Duplicate selected price group</DialogTitle>
        <DialogContent style={{ width: '600px' }}>
          <div style={{ marginBottom: '20px' }}>
            This dialog allows you to duplicate an existing price group
          </div>
          <DialogTitle style={{ paddingLeft: 0 }}>Facilities</DialogTitle>
          <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '40px' }}>
            {row.facilities.map((f) => facilityTag(f))}
          </div>
          <ConditionallyVisible condition={row.membership}>
            <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '40px' }}>
              <div style={{ width: '100%' }}>
                Memberships
              </div>
              {membershipTag(row)}
            </div>
          </ConditionallyVisible>
          <DialogTitle style={{ paddingLeft: 0 }}>Additional Changes</DialogTitle>
          <div>
            <PricingAddRuleDialogSection
              title="Membership"
              parameter="membership"
              value={membership}
              membership={membership}
              memberships={companyMemberships}
              onUpdate={updatePriceGroupStore}
            />
            <Divider />
            <PricingAddRuleDialogSection
              title="Start Date"
              parameter="startDate"
              value={startDate}
              onUpdate={updatePriceGroupStore}
            />
            <Divider />
            <PricingAddRuleDialogSection
              title="End Date"
              parameter="endDate"
              value={endDate}
              onUpdate={updatePriceGroupStore}
            />
          </div>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Close
          </Button>
          <Button onClick={handleDuplicate} color="primary" variant="contained" disabled={isDuplicatedDisabled}>
            Duplicate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DuplicatePriceGroupDialog.propTypes = {
  row: PropTypes.shape().isRequired,
  endDate: PropTypes.shape().isRequired,
  facilityTag: PropTypes.func.isRequired,
  startDate: PropTypes.shape().isRequired,
  membershipTag: PropTypes.func.isRequired,
  priceGroup: PropTypes.shape().isRequired,
  updatePriceGroupStore: PropTypes.func.isRequired,
  companyMemberships: PropTypes.shape().isRequired,
  resetPriceGroupCreation: PropTypes.func.isRequired,
  requestPriceGroupDuplicate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  priceGroup: state.pricing.priceGroup,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updatePriceGroupStore: (keyWithValue) => pricingActions.updatePriceGroupStore(
    keyWithValue,
  ),
  resetPriceGroupCreation: () => pricingActions.resetPriceGroupCreation(),
  requestPriceGroupDuplicate: () => pricingActions.requestPriceGroupDuplicate(),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DuplicatePriceGroupDialog);
