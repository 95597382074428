import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import LocationOn from '@material-ui/icons/LocationOn';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TocIcon from '@material-ui/icons/Toc';
import SportsIcon from '@material-ui/icons/Sports';
import LightBulbIcon from '@material-ui/icons/WbIncandescent';
import CardIcon from '@material-ui/icons/CreditCard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import InboxIcon from '@material-ui/icons/Inbox';
import Storefront from '@material-ui/icons/Storefront';
import BrandingsWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import ConfirmationNumber from '@material-ui/icons/ConfirmationNumber';
import Book from '@material-ui/icons/Book';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import LockIcon from '@material-ui/icons/Lock';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import RedeemIcon from '@material-ui/icons/Redeem';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import MenuDrawerItemLink from './MenuDrawerItemLink';
import MenuDrawerStyles from './styles';

const logoEnum = {
  assessmentIcon: 'assessmentIcon',
  dateRangeIcon: 'dateRangeIcon',
  book: 'book',
  arrowForwardIcon: 'arrowForwardIcon',
  locationOn: 'locationOn',
  peopleIcon: 'peopleIcon',
  settingsIcon: 'settingsIcon',
  dashboardIcon: 'dashboardIcon',
  confirmationNumber: 'confirmationNumber',
  inboxIcon: 'inboxIcon',
  redeemIcon: 'redeemIcon',
  leagueIcon: 'leagueIcon',
  associatesIcon: 'associatesIcon',
  lightBulbIcon: 'lightBulbIcon',
  lockIcon: 'lockIcon',
  cardIcon: 'cardIcon',
  whiteLabelIcon: 'whiteLabelIcon',
  contactUsIcon: 'contactUsIcon',
  storefrontIcon: 'storefrontIcon',
  shoppingCartIcon: 'shoppingCartIcon',
  accountBalanceIcon: 'accountBalanceIcon',
  libraryIcon: 'libraryIcon',
};

const renderIcon = (icon) => {
  switch (icon) {
    case logoEnum.dashboardIcon:
      return (<DashboardIcon />);
    case logoEnum.storefrontIcon:
      return (<Storefront />);
    case logoEnum.confirmationNumber:
      return (<ConfirmationNumber />);
    case logoEnum.assessmentIcon:
      return (<AssessmentIcon />);
    case logoEnum.dateRangeIcon:
      return (<DateRangeIcon />);
    case logoEnum.book:
      return (<Book />);
    case logoEnum.arrowForwardIcon:
      return (<ArrowForwardIcon className={MenuDrawerStyles().iconStyle} />);
    case logoEnum.locationOn:
      return (<LocationOn />);
    case logoEnum.peopleIcon:
      return (<PeopleIcon />);
    case logoEnum.settingsIcon:
      return (<SettingsIcon />);
    case logoEnum.inboxIcon:
      return (<InboxIcon />);
    case logoEnum.redeemIcon:
      return (<RedeemIcon />);
    case logoEnum.leagueIcon:
      return (<TocIcon />);
    case logoEnum.associatesIcon:
      return (<SportsIcon />);
    case logoEnum.lockIcon:
      return (<LockIcon />);
    case logoEnum.lightBulbIcon:
      return (<LightBulbIcon />);
    case logoEnum.cardIcon:
      return (<CardIcon />);
    case logoEnum.whiteLabelIcon:
      return (<BrandingsWatermarkIcon />);
    case logoEnum.contactUsIcon:
      return (<WhatsAppIcon />);
    case logoEnum.shoppingCartIcon:
      return (<ShoppingCartIcon />);
    case logoEnum.accountBalanceIcon:
      return (<AccountBalanceIcon />);
    case logoEnum.libraryIcon:
      return (<BookmarkIcon />);
    default:
      return (<AssessmentIcon />);
  }
};

const MenuDrawerItem = ({
  label,
  link,
  index,
  selectedIndex,
  handleLinkListItemClick,
  icon,
  collapsable,
  collapsed,
  className,
  disabled,
  locked,
  product,
  hidden,
}) => {
  const dispatch = useDispatch();
  const item = (
    <ListItem
      selected={selectedIndex === index}
      button
      onClick={() => {
        if (locked) {
          dispatch(push(`/product/${product.toLowerCase()}`));
        }
        handleLinkListItemClick();
      }}
      key={label}
      className={className}
    >
      <ListItemIcon>{renderIcon(icon)}</ListItemIcon>
      <ListItemText primary={label} />
      <ConditionallyVisible condition={locked}>
        <LockIcon color="disabled" />
      </ConditionallyVisible>
      <ConditionallyVisible condition={collapsable}>
        <AlternativelyVisible condition={collapsed}>
          <ExpandLess />
          <ExpandMore />
        </AlternativelyVisible>
      </ConditionallyVisible>
    </ListItem>
  );
  if (hidden) return null;
  return (
    <ConditionallyVisible condition={!disabled}>
      <AlternativelyVisible condition={!!link && !locked}>
        <MenuDrawerItemLink link={link}>
          {item}
        </MenuDrawerItemLink>
        {item}
      </AlternativelyVisible>
    </ConditionallyVisible>
  );
};
MenuDrawerItem.propTypes = {
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  selectedIndex: PropTypes.number.isRequired,
  handleLinkListItemClick: PropTypes.func.isRequired,
  icon: PropTypes.oneOf(Object.values(logoEnum)),
  collapsable: PropTypes.bool,
  collapsed: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  locked: PropTypes.bool,
  product: PropTypes.string,
  hidden: PropTypes.bool,
};

MenuDrawerItem.defaultProps = {
  collapsable: false,
  collapsed: false,
  className: '',
  disabled: false,
  hidden: false,
  icon: logoEnum.settingsIcon,
  link: '',
  locked: false,
  product: '',
};

export default MenuDrawerItem;
