import {
  takeEvery, call, put,
} from 'redux-saga/effects';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  updateCompanyUserNoteService,
} from '../services/notesServices';
import * as notesActions from '../reducers/notesReducer';
import { updateUserNotes } from '../reducers/usersReducer';
import { handleSagaError } from './helperSaga';

function* updateCompanyUserNoteSaga(companyId, action) {
  const { userId, note } = action;
  const reqBody = { value: note };
  try {
    const response = yield call(
      updateCompanyUserNoteService,
      companyId,
      userId,
      reqBody,
    );
    if (!response.error) {
      yield put(sessionActions.updateSuccessfulSnackbar('Note successfully updated.', true));
      yield put(notesActions.succeedUpdateCompanyUserNote());
      yield put(updateUserNotes(response.data));
    } else { throw response; }
  } catch (error) {
    yield put(notesActions.failUpdateCompanyUserNote());
    yield call(handleSagaError, error, 'updateCompanyUserNoteSaga');
  }
}

// Watchers
export function* updateCompanyUserNoteWatcher(companyId) {
  yield takeEvery(notesActions.UPDATE_USER_NOTE_REQUESTED,
    updateCompanyUserNoteSaga, companyId);
}
