/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { useDispatch } from 'react-redux';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as leagueActions from '../../../reducers/leaguesReducer';

const EditFixtureResultDialog = ({
  fixtureId, teamA, teamB,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [teamAScore, setTeamAScore] = useState(teamA.score);
  const [teamBScore, setTeamBScore] = useState(teamB.score);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const updateScore = () => {
    const score = {
      teamAScore,
      teamBScore,
    };

    dispatch(leagueActions.updateLeagueFixtureScore(fixtureId, score));
    setOpen(false);
  };

  return (
    <div>
      <Button color="secondary" variant="contained" onClick={() => handleClickOpen()}>
        Edit Result
      </Button>
      <Dialog
        open={open}
        fullWidth
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>
            Edit Fixture Result
          </DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => handleRequestClose()} />
          </DialogActions>
        </div>
        <DialogContent>

          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <h4>{teamA.name}</h4>

                  <PBInput
                    id="teamBScore"
                    name="teamBScore"
                    type="number"
                    onChange={(e) => setTeamAScore(e.target.value)}
                    label={teamAScore ? null : `${teamA?.name} Score`}
                    value={teamAScore}
                  />
                </TableCell>

                <TableCell>
                  <div style={{ marginTop: '4rem' }}>
                    <h3>VS</h3>
                  </div>
                </TableCell>

                <TableCell>
                  <h4>{teamB.name}</h4>
                  <PBInput
                    id="teamBScore"
                    name="teamBScore"
                    type="number"
                    onChange={(e) => setTeamBScore(e.target.value)}
                    label={teamBScore ? null : `${teamB?.name} Score`}
                    value={teamBScore}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => updateScore()} color="primary" variant="contained">
            Update
          </Button>
          <Button onClick={() => handleRequestClose()} color="secondary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditFixtureResultDialog;
