/**
 * @fileoverview
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import { CategoryChartBaseLine } from './bases/CategoryChartBaseLine';
import { CategoryHeaderBase } from './bases/CategoryTitleBase';
import FailedSection from '../../../../components/LoadingComponents/FailedSection';
import LoadingSkeletonBarChart from '../../../../components/LoadingComponents/LoadingSkeletonBarChart';

class CategoryRevenue extends React.Component {
  render() {
    const {
      analyticsBookings,
      analyticsTimeframe,
      analyticsTimeframeCustom,
      currencySym,
      analyticsLoading,
      analyticsFailed,
    } = this.props;
    const { bookings, xAxisRevenue, yAxisRevenue } = analyticsBookings;

    return (
      <div className="category" id="Revenue">
        <CategoryHeaderBase categoryName="Revenue" />
        <AlternativelyVisible condition={analyticsLoading}>
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: '1',
          }}
          >
            <LoadingSkeletonBarChart />
          </div>

          <AlternativelyVisible condition={analyticsFailed}>
            <FailedSection />
            <CategoryChartBaseLine
              backgroundColor="#90EE9010"
              bookings={bookings}
              borderColor="#90EE90B0"
              categoryName="Revenue"
              analyticsTimeframe={analyticsTimeframe}
              analyticsTimeframeCustom={analyticsTimeframeCustom}
              xAxis={xAxisRevenue}
              yAxis={yAxisRevenue}
              yAxisLabel={`Revenue in ${currencySym}`}
            />
          </AlternativelyVisible>
        </AlternativelyVisible>
      </div>
    );
  }
}

CategoryRevenue.propTypes = {
  analyticsBookings: PropTypes.shape().isRequired,
  analyticsTimeframe: PropTypes.shape().isRequired,
  analyticsTimeframeCustom: PropTypes.shape().isRequired,
  currencySym: PropTypes.string.isRequired,
  analyticsLoading: PropTypes.bool.isRequired,
  analyticsFailed: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    analyticsBookings: state.analytics.analyticsBookings,
    analyticsTimeframe: state.analytics.analyticsTimeframe,
    analyticsTimeframeCustom: state.analytics.analyticsTimeframeCustom,
    currencySym: state.companies.companyInfo.currencySym,
    analyticsLoading: state.analytics.analyticsLoading,
    analyticsFailed: state.analytics.analyticsFailed,
  };
}

export default connect(mapStateToProps)(CategoryRevenue);
