import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export const getLeaguesService = async (companyId) => {
  const url = `/api/companies/${companyId}/leagues`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
};

export const deleteLeagueService = async (companyId, leagueId) => {
  const url = `/api/companies/${companyId}/leagues/${leagueId}`;
  const response = await httpHelpers.authenticatedDeleteRequest(url);
  return response;
};

export async function createLeagueService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/leagues`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function getLeagueService(companyId, leagueId) {
  const url = `/api/companies/${companyId}/leagues/${leagueId}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function confirmFixturesService(companyId, games, leagueId) {
  const url = `/api/companies/${companyId}/leagues/${leagueId}/fixtures`;
  const reqBody = {
    games,
  };
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function updateLeagueFixtureService(companyId, fixtureId, fixture) {
  const url = `/api/companies/${companyId}/fixtures/${fixtureId}`;
  const response = await httpHelpers.authenticatedPutRequest(url, fixture);
  return response;
}
export async function getLeagueFixtureService(companyId, fixtureId, fixture) {
  const url = `/api/companies/${companyId}/fixtures/${fixtureId}`;
  const response = await httpHelpers.authenticatedGetRequest(url, fixture);
  return response;
}
export async function submitGoalScorerService(companyId, fixtureId, goalScorers) {
  const reqBody = {
    goalScorers,
  };
  const url = `/api/companies/${companyId}/fixtures/${fixtureId}/goalScorers`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
export async function linkLeagueToFacilityService(companyId, leagueId, reqBody) {
  const url = `/api/companies/${companyId}/leagues/${leagueId}/facility`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
export async function inviteUserToLeagueService(companyId, leagueId, user) {
  const url = `/api/companies/${companyId}/leagues/${leagueId}/user`;
  const reqBody = {
    user,
  };
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function adjustTeamPointsService(companyId, leagueId, reqBody) {
  const url = `/api/companies/${companyId}/leagues/${leagueId}/adjustPoints`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
export async function createNewFixtureService(companyId, leagueId, reqBody) {
  const url = `/api/companies/${companyId}/leagues/${leagueId}/fixture`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function assignAssociateService(companyId, fixtureId, reqBody) {
  const url = `/api/companies/${companyId}/fixtures/${fixtureId}/assign-official`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function inviteTeamToLeague(leagueId, team) {
  const url = `/api/leagues/${leagueId}/invite-team`;
  const response = await httpHelpers.authenticatedPostRequest(url, team);
  return response;
}

export async function cancelFixtureService(companyId, fixtureId, reqBody) {
  const url = `/api/companies/${companyId}/fixtures/${fixtureId}/cancel`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function removeFixtureAgentService(companyId, fixtureId, reqBody) {
  const url = `/api/companies/${companyId}/fixtures/${fixtureId}/remove-agent`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function confirmWaitingTeam(
  leagueId, teamId, replacementTeam, inheritPoints, inheritFixtures,
) {
  const url = `/api/leagues/${leagueId}/team/${teamId}/confirm`;
  const reqBody = {
    replacementTeam,
    inheritPoints,
    inheritFixtures,
  };
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function editLeagueFixtureFacilityService(
  fixtureId,
  slots,
  facility = null,
) {
  const url = `/api/leagues/fixture/${fixtureId}/facility`;
  const reqBody = {
    facility,
    slots,
  };
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function deleteLeagueTeamService(leagueId, teamId) {
  const url = `/api/leagues/${leagueId}/delete-team/${teamId}`;
  const response = await httpHelpers.authenticatedPutRequest(url);
  return response;
}

export async function fetchLeagueFixtures(leagueId) {
  const url = `/api/leagues/${leagueId}/fixtures`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function editLeagueDetails(leagueId, reqBody, companyId) {
  const url = `/api/companies/${companyId}/leagues/${leagueId}/edit-league`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function updateLeagueTagService(leagueId, reqBody, companyId) {
  const url = `/api/companies/${companyId}/leagues/${leagueId}/tags`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function replaceTeamInLeague(
  companyId, leagueId, teamId, replacementTeamId, inheritPoints, inheritFixtures,
) {
  const url = `/api/companies/${companyId}/leagues/${leagueId}/team/${teamId}/replace`;
  const response = await httpHelpers.authenticatedPutRequest(url, {
    replacementTeamId,
    inheritPoints,
    inheritFixtures,
  });
  return response;
}

export async function updateLeagueFixtureScore(fixtureId, score) {
  const url = `/api/leagues/updateFixtureResult/${fixtureId}`;
  const response = await httpHelpers.authenticatedPostRequest(url, score);
  return response;
}
