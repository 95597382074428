/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import Alert from '@material-ui/lab/Alert';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as leagueActions from '../../../reducers/leaguesReducer';
import ScoreInput from './ScoreInput';

class CancelFixture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      outcome: 'CANCELLED',
      teamAPayment: 'NONE',
      teamBPayment: 'NONE',
      teamAScore: null,
      teamBScore: null,
      winner: null,
      showAlert: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
      outcome: 'CANCELLED',
      teamAPayment: 'NONE',
      teamBPayment: 'NONE',
      teamAScore: null,
      teamBScore: null,
      winner: null,
      showAlert: false,
    });
  };

  handleCancelFixture = () => {
    const {
      outcome, teamAPayment, teamBPayment, winner,
    } = this.state;
    let { teamAScore, teamBScore } = this.state;
    const { cancelFixture, game, league } = this.props;
    const { goalsForForfeit } = league;
    if (winner) {
      if (winner.id === game.teamA.id) {
        teamAScore = goalsForForfeit;
        teamBScore = 0;
      } else {
        teamBScore = goalsForForfeit;
        teamAScore = 0;
      }
    }
    const fixtureId = game.id;
    const teamA = {
      id: game.teamA.id,
      payment: teamAPayment,
      score: teamAScore,
    };
    const teamB = {
      id: game.teamB.id,
      payment: teamBPayment,
      score: teamBScore,
    };

    if (outcome === 'FORFEIT' && !winner) {
      this.setState({ showAlert: true });
    } else {
      cancelFixture(fixtureId, outcome, teamA, teamB, winner);

      this.setState({
        open: false,
        outcome: 'CANCELLED',
        teamAPayment: 'NONE',
        teamBPayment: 'NONE',
        teamAScore: null,
        teamBScore: null,
        showAlert: false,
      });
    }
  }

  render() {
    const isMobile = window.innerWidth < 768;
    const { game, league } = this.props;
    const {
      outcome,
      open,
      teamAPayment,
      teamBPayment,
      teamAScore,
      teamBScore,
      winner,
    } = this.state;

    const goalsForForfeit = league.goalsForForfeit || league.parentLeague?.goalsForForfeit;
    return (
      <div>
        <Button color="primary" variant="contained" onClick={() => this.handleClickOpen()}>
          Cancel Fixture
        </Button>
        <Dialog
          open={open}
          fullScreen={isMobile}
          fullWidth
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>
              Cancel Fixture
            </DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>

          <ConditionallyVisible condition={this.state.showAlert}>
            <div style={{ margin: '1rem 2rem' }}>
              <Alert severity="error">
                Please select a winner for the forfeit
              </Alert>
            </div>
          </ConditionallyVisible>

          <DialogContent>
            <InputLabel id="currency-select-outlined-label">Fixture Outcome</InputLabel>
            <Select
              displayEmpty
              value={outcome}
              style={{ margin: 10 }}
              variant="outlined"
              label="Adjustment Type"
              onChange={(event) => this.setState(
                { outcome: event.target.value },
              )}
            >

              <MenuItem value="CANCELLED">Void</MenuItem>
              <MenuItem value="FORFEIT">Forfeit</MenuItem>
            </Select>
            <InputLabel id="currency-select-outlined-label">
              Payment For
              {' '}
              {game?.teamA?.name || 'Free Week'}
            </InputLabel>
            <Select
              displayEmpty
              value={teamAPayment}
              style={{ margin: 10 }}
              variant="outlined"
              label="Adjustment Type"
              onChange={(event) => this.setState(
                { teamAPayment: event.target.value },
              )}
            >

              <MenuItem value="NONE">No payment</MenuItem>
              <MenuItem value="NORMAL">
                {game?.teamA?.name || 'Free Week'}
                {' '}
                to pay standard

              </MenuItem>
              <MenuItem value="DOUBLE">
                {game?.teamA?.name || 'Free Week'}
                {' '}
                to pay double
              </MenuItem>
            </Select>
            <InputLabel id="currency-select-outlined-label">
              Payment For
              {' '}
              {game?.teamB?.name || 'Free Week'}

            </InputLabel>
            <Select
              displayEmpty
              value={teamBPayment}
              style={{ margin: 10 }}
              variant="outlined"
              label="Adjustment Type"
              onChange={(event) => this.setState(
                { teamBPayment: event.target.value },
              )}
            >

              <MenuItem value="NONE">No payment</MenuItem>
              <MenuItem value="NORMAL">
                {game?.teamB?.name || 'Free Week'}
                {' '}
                to pay standard
              </MenuItem>
              <MenuItem value="DOUBLE">
                {game?.teamB?.name || 'Free Week'}
                {' '}
                to pay double
              </MenuItem>
            </Select>
            <ConditionallyVisible condition={outcome === 'FORFEIT'}>
              <AlternativelyVisible condition={goalsForForfeit}>
                <>
                  <InputLabel id="currency-select-outlined-label">Fixture Winner</InputLabel>
                  <Select
                    displayEmpty
                    value={winner}
                    style={{ margin: 10 }}
                    variant="outlined"
                    label="Adjustment Type"
                    onChange={(event) => this.setState(
                      { winner: event.target.value },
                    )}
                  >
                    <MenuItem value={game?.teamA}>
                      {' '}
                      {game?.teamA?.name || 'Free Week'}
                    </MenuItem>
                    <MenuItem value={game?.teamB}>
                      {' '}
                      {game?.teamB?.name || 'Free Week'}
                    </MenuItem>
                  </Select>
                </>

                <>
                  <p>
                    {game?.teamA?.name || 'Free Week'}
                    {' '}
                    Score
                  </p>
                  <ScoreInput
                    teamScore={teamAScore}
                    disabled={false}
                    updateScore={(event) => {
                      if (event.target.value === '') {
                        this.setState({ teamAScore: null });
                      } else {
                        this.setState({ teamAScore: event.target.value });
                      }
                      this.setState({
                        winner:
                          teamAScore > teamBScore ? game?.teamA : game?.teamB,
                      });
                    }}
                  />
                  <p>
                    {game?.teamB?.name || 'Free Week'}
                    {' '}
                    Score
                  </p>
                  <ScoreInput
                    teamScore={teamBScore}
                    disabled={false}
                    updateScore={(event) => {
                      this.setState({
                        teamBScore: event.target.value,
                        winner: teamAScore > teamBScore ? game?.teamA : game?.teamB,
                      });
                    }}
                  />
                </>
              </AlternativelyVisible>
            </ConditionallyVisible>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary" variant="outlined">
              Close
            </Button>
            <Button onClick={() => this.handleCancelFixture()} color="primary" variant="contained">
              Cancel Fixture
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { leagues } = state;
  const { league } = leagues;
  return {
    league,
  };
}

const mapDispatchToProps = (dispatch) => ({
  cancelFixture: (fixtureId, outcome, teamA, teamB, winner) => dispatch(
    leagueActions.cancelFixture(fixtureId, outcome, teamA, teamB, winner),
  ),
});

CancelFixture.propTypes = {
  cancelFixture: PropTypes.func.isRequired,
  game: PropTypes.shape().isRequired,
  league: PropTypes.shape().isRequired,
};

export default connect(
  mapStateToProps, mapDispatchToProps,
)(CancelFixture);
