import {
  takeEvery, call, put, select,
} from 'redux-saga/effects';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  getCompanyUsersService,
  removeUserFromCompanyService,
  addUserToCompanyService,
  setAccountManagerService,
} from '../services/companyUsersServices';
import { getCompanyInfo } from '../reducers/companiesReducer';
import * as actions from '../reducers/companyUsersReducer';
import { handleSagaError } from './helperSaga';

function* getCompanyUsersSaga(companyId) {
  const state = yield select();
  const reqBody = {
    companyId: [state.session.selectedCompanyId],
  };
  if (companyId) {
    try {
      const response = yield call(getCompanyUsersService, companyId, reqBody);

      if (!response.error) {
        yield put(actions.companyUsersRetrievedSuccesfully(response.data));
      } else { throw response; }
    } catch (error) {
      yield put(actions.companyUsersRetrievedFailed(error.data));
      yield call(handleSagaError, error, 'getCompanyUsersSaga');
    }
  }
}

function* removeUserFromCompanySaga(companyId, companyUsersAction) {
  try {
    const body = {
      companyUserId: companyUsersAction.companyUserId,
    };
    const response = yield call(
      removeUserFromCompanyService,
      companyId,
      body,
    );
    if (!response.error) {
      yield put(actions.getCompanyUsers(companyUsersAction.companyId));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'removeUserFromCompanySaga');
  }
}

function* addUserToCompanySaga(companyId, companyUsersAction) {
  try {
    const body = {
      userDetails: companyUsersAction.userDetails,
      // TODO - Change this, and the companyUsersAction
    };
    const response = yield call(
      addUserToCompanyService,
      companyId,
      body,
    );

    if (!response.error) {
      yield put(actions.getCompanyUsers(companyId));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'addUserToCompanySaga');
    yield put(sessionActions.updateErrorSnackbar(error.error, true));
  }
}

function* setAccountManagerSaga(companyId, action) {
  try {
    const { userId } = action;
    const body = {
      userId,
    };
    const response = yield call(
      setAccountManagerService,
      companyId,
      body,
    );
    if (!response.error) {
      yield put(getCompanyInfo(true));
      yield put(sessionActions.updateSuccessfulSnackbar('Company account owner updated successfully!', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'setAccountManagerSaga');
    yield put(sessionActions.updateErrorSnackbar('Error updating company account owner'));
  }
}

// WATCHERS
export function* companyUsersWatcher(companyId) {
  yield takeEvery(actions.COMPANY_USERS_RETRIEVAL_REQUESTED, getCompanyUsersSaga, companyId);
}

export function* setAccountManagerWatcher(companyId) {
  yield takeEvery(actions.SET_ACCOUNT_MANAGER, setAccountManagerSaga, companyId);
}

export function* removeUserFromCompanyWatcher(companyId) {
  yield takeEvery(actions.REMOVE_USER_FROM_COMPANY, removeUserFromCompanySaga, companyId);
}

export function* addUserToCompanyWatcher(companyId) {
  yield takeEvery(actions.ADD_USER_TO_COMPANY, addUserToCompanySaga, companyId);
}
