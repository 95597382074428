import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../reducers/usersReducer';
import * as membershipsActions from '../../reducers/accessRestrictionsReducer';
import UserTable from './UserTable';
import '../../styles/payments.css';

class Users extends React.Component {
  componentDidMount() {
    const {
      getUsers,
    } = this.props;
    getUsers();
  }

  render() {
    const { props } = this;
    const { getUsers } = props;

    return (
      <div style={{ background: '#fafbff', marginTop: '-20px', paddingTop: '20px' }}>
        <UserTable getUsers={getUsers} />
      </div>
    );
  }
}

Users.propTypes = {
  getUsers: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    users,
  } = state;
  const { isLoading } = users;
  return {
    users, isLoading,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getUsers: (page = 0, term = null, orderBy = 'lastName', orderDirection = 'asc') => dispatch(actions.getUsers(
    page, term, orderBy, orderDirection,
  )),
  getMembershipUsers: (membershipId) => dispatch(
    membershipsActions.getMembershipUsers(membershipId),
  ),
  updateMembership: () => dispatch(membershipsActions.updateMembership()),

});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
