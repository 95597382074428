import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const DownloadPdf = (colHeaders, rows, filename) => {
  const doc = new JsPDF('landscape');
  autoTable(doc, {
    headStyles: {
      fillColor: [36, 220, 137],
      cellWidth: 'wrap',
    },
    head: [colHeaders],
    body: rows,
  });
  doc.save(filename);
};

export default DownloadPdf;
