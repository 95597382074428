import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import * as facilitiesActions from '../../../../reducers/facilitiesReducer';

const FacilityAttributesConfiguration = () => {
  const dispatch = useDispatch();
  const facilities = useSelector((state) => state.facilities);
  const { selectedFacility } = facilities;
  const {
    costCode,
    taxExemptCostCode,
    taxExempt,
    size,
    surface,
  } = selectedFacility;

  const updateFacility = async (id, values) => {
    dispatch(facilitiesActions.requestSelectedFacilityUpdate(id, values));
    dispatch(sessionActions.updateSuccessfulSnackbar('Your info has been updated successfully!', true));
  };

  const resetDetailsForm = (resetForm) => {
    resetForm();
  };

  return (
    <>
      <Formik
        initialValues={{
          costCode: costCode || null,
          taxExempt: taxExempt || false,
          taxExemptCostCode: taxExemptCostCode || null,
          applyCostCode: costCode !== null,
          applyTaxExemptCostCode: taxExemptCostCode !== null,
          size,
          surface,
        }}
        enableReinitialize
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          let { costCode, taxExemptCostCode } = values;
          if (values.applyCostCode === false || !values.costCode?.trim()) {
            costCode = null;
            taxExemptCostCode = null;
          }
          if (values.applyTaxExemptCostCode === false || !values.taxExemptCostCode?.trim()
            || values.taxExempt) {
            taxExemptCostCode = null;
          }
          const form = {
            ...values,
            costCode,
            taxExemptCostCode,
          };
          setSubmitting(true);
          try {
            updateFacility(selectedFacility.id, form);
            resetForm();
          } catch (err) {
            dispatch(sessionActions.updateErrorSnackbar(
              'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us at info@pitchbooking.com.',
              true,
            ));
          }
        }}
      >
        {({
          values, errors, touched, handleSubmit, resetForm, handleChange,
        }) => (
          <form
            onSubmit={handleSubmit}
            onReset={resetForm}
            style={{
              display: 'flex', flexDirection: 'column',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {/* Title */}
              <div>
                <Typography variant="h6" style={{ marginBottom: '1rem' }}>Facility Financial Settings</Typography>
              </div>
              <Typography variant="body2" style={{ marginBottom: '1rem' }}>Tax Exemption</Typography>
              <PBInput
                id="taxExempt"
                type="toggle"
                label="Toggle ON to make all bookings made on this facility tax exempt.
                If bookings made on this facility are NOT tax exempt, this toggle should be switched OFF"
                value={values.taxExempt}
                onChange={handleChange}
              />
              <br />
              <PBInput
                id="applyCostCode"
                type="toggle"
                label="Do you want to apply a cost code?
                Toggle ON and enter your code in the box below. For subscription bookings,
                you are able to override this code for those subscriptions that are tax exempt,
                by unchecking the ‘Subscription Tax’ box when creating your subscription.
                This can also be overridden for specific User exemptions. In order to apply a tax exempt code to these bookings,
                you must first add a cost code below, toggle Tax Exempt Cost Code ON,
                then enter your Tax Exempt Cost Code. If you have set Facility Tax Exemption Status to ON,
                then there will be no requirement for a separate Tax Exempt Cost Code."
                value={values.applyCostCode}
                onChange={handleChange}
              />
              <br />
              {/* Cost Code */}
              {values.applyCostCode && (
              <PBInput
                type="text"
                id="costCode"
                label="Cost Code"
                value={values.costCode}
                onChange={handleChange}
                isError={touched.costCode && Boolean(errors.costCode)}
                errorMessage={touched.costCode && errors.costCode}
              />
              )}
              <br />
              {values.applyCostCode && values.costCode && !values.taxExempt && (
                <>
                  <PBInput
                    id="applyTaxExemptCostCode"
                    type="toggle"
                    label="Do you want to apply a specific tax exempt cost code?
                    (Note, this will only apply in specific cases where tax is exempt,
                    this will be based on your chosen setup. If it is not set,
                    then the Cost Code value above will be used in all scenarios.)"
                    value={values.applyTaxExemptCostCode}
                    onChange={handleChange}
                  />
                  <br />
                  {values.applyTaxExemptCostCode && (
                  <PBInput
                    type="text"
                    id="taxExemptCostCode"
                    label="Tax Exempt Cost Code"
                    value={values.taxExemptCostCode}
                    onChange={handleChange}
                    isError={touched.taxExemptCostCode && Boolean(errors.taxExemptCostCode)}
                    errorMessage={touched.taxExemptCostCode && errors.taxExemptCostCode}
                  />

                  )}
                </>
              )}
            </div>

            {/* From actions */}
            <div style={{
              display: 'flex', justifyContent: 'flex-start', marginTop: '3rem', gap: '1rem',
            }}
            >
              <Button variant="contained" color="primary" type="submit" style={{ width: '200px' }}>Save</Button>
              <Button variant="contained" onClick={() => resetDetailsForm(resetForm)} style={{ width: '200px' }}>Cancel</Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default FacilityAttributesConfiguration;
