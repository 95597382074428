/* eslint-disable prefer-template */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { Typography, Button } from '@material-ui/core';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DEVICE_TYPES } from './Devices';
import { useToast } from '../../../../hooks';

export const AddDeviceForm = ({ type, onSuccess }) => {
  const PROVIDERS = DEVICE_TYPES[type];
  const toast = useToast();
  const company = useSelector((state) => state.companies.companyInfo);
  const [provider, setProvider] = useState(Object.keys(PROVIDERS)[0]);

  const mutation = useMutation({
    mutationFn: async (values) => {
      const submit = PROVIDERS[provider]?.onSubmit;
      if (!submit) throw new Error('No submit function found for provider');
      await submit(company.id, {
        provider,
        ...values,
      });
    },
    onError: (err) => {
      console.error(err);
      toast.trigger({
        type: 'error',
        message: 'An error occurred while attempting to create device!',
      });
    },
    onSuccess: () => {
      toast.trigger({
        type: 'success',
        message: 'Device created successfully!',
      });
      onSuccess();
    },
  });

  const formik = useFormik({
    initialValues: PROVIDERS[provider]?.initialValues,
    validationSchema: PROVIDERS[provider]?.validationSchema,
    onSubmit: (values) => mutation.mutate(values),
    enableReinitialize: true,
  });
  const {
    handleSubmit, errors, touched, getFieldProps,
  } = formik;

  const fields = PROVIDERS[provider];

  useEffect(() => {
    if (fields.initialise) {
      fields.initialise(formik, company);
    }
  }, [fields]);

  return (
    <form onSubmit={handleSubmit}>
      <PBInput
        id="provider"
        label="Which provider are you using?"
        type="select"
        options={Object.keys(PROVIDERS).map((key) => ({
          label: PROVIDERS[key].label,
          value: key,
        }))}
        onChange={(e) => {
          setProvider(e.target.value);
        }}
        value={provider}
      />

      {fields && (
        <>
          {fields.description && (
            <div style={{ margin: '1rem 0' }}>
              <Typography variant="body2">{fields.description}</Typography>
            </div>
          )}

          <div style={{
            display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0.75rem', margin: '1rem 0',
          }}
          >
            {fields.formFields.map((field) => {
              if (field.type === 'object') {
                return (
                  <div
                    key={field.name}
                    style={{
                      gridColumn: 'span 2', margin: '1rem 0', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0.75rem',
                    }}
                  >
                    {field.fields.map((subField) => (
                      <PBInput
                        key={`${field.name}-${subField.name}`}
                        id={`${field.name}.${subField.name}`}
                        errorMessage={
                          touched?.[field.name]?.[subField.name]
                          && errors?.[field.name]?.[subField.name]
                        }
                        isError={
                          touched?.[field.name]?.[subField.name]
                          && Boolean(errors?.[field.name]?.[subField.name])
                        }
                        {...getFieldProps(`${field.name}.${subField.name}`)}
                        {...subField}
                      />
                    ))}
                  </div>
                );
              }

              if (field.type === 'array') {
                return (
                  <div
                    key={field.name}
                    style={{
                      gridColumn: 'span 2',
                    }}
                  >
                    {formik.values?.[field.name]?.map((item, index) => {
                      const key = field.name + '.' + index;

                      return (
                        <div
                          key={key}
                          style={{
                            gridColumn: 'span 2', margin: '1rem 0', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0.75rem',
                          }}
                        >
                          <div
                            style={{
                              gridColumn: 'span 2',
                            }}
                          >
                            <Typography>
                              {field.label}
                              <span>
                                {' '}
                                {index + 1}
                              </span>

                            </Typography>
                          </div>
                          {field.fields?.map((subField) => {
                            const key = field.name + '.' + index + '.' + subField.name;
                            return (
                              <PBInput
                                key={key}
                                id={key}
                                errorMessage={
                                touched?.[field.name]?.[index]?.[subField.name]
                                && errors?.[field.name]?.[index]?.[subField.name]
                              }
                                isError={
                                touched?.[field.name]?.[index]?.[subField.name]
                                && Boolean(errors?.[field.name]?.[index]?.[subField.name])
                              }
                                {...getFieldProps(key)}
                                {...subField}
                              />
                            );
                          })}
                          {formik.values?.[field.name].length > 1 && (
                          <Button variant="outlined" color="secondary" onClick={() => field.removeItem(formik, index)}>Remove</Button>
                          )}
                        </div>
                      );
                    })}
                    <Button variant="contained" color="secondary" onClick={() => field.addItem(formik)}>Add Node</Button>
                  </div>
                );
              }

              return (
                <PBInput
                  key={field.name}
                  id={field.name}
                  {...getFieldProps(field.name)}
                  {...field}
                  errorMessage={touched[field.name] && errors[field.name]}
                  isError={touched[field.name] && Boolean(errors[field.name])}
                />
              );
            })}
          </div>

          <div style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={mutation.isLoading}
            >
              Create Device
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

AddDeviceForm.propTypes = {
  type: PropTypes.oneOf(['lock', 'light', 'terminal']).isRequired,
  onSuccess: PropTypes.func.isRequired,
};
