/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import '../../../styles/subscription.css';

const SubscriptionCards = ({
  total, totalSubscriptions, totalSubscriptionsActive, totalSubscriptionsInactive,
  totalSubscriptionsOfflinePayment, currencySym,
}) => (
  <div className="subscription-figures">
    <div className="total-figures">
      <div className="total-amount">
        <AccountBalanceIcon style={{ fill: '#47FEB4', fontSize: '48px' }} />
        <br />
        <NumberFormat displayType="text" thousandSeparator prefix={currencySym} value={total.toFixed(2)} style={{ fontSize: '1.8rem' }} />
        <br />
        <div>Weekly Revenue</div>
        {' '}

        <div className="total-amount" style={{ fontSize: '12px', color: '#515459' }}>
          Active subscriptions*
        </div>
      </div>
    </div>
    <div className="total-subscriptions-figures ">
      <div className="total-subscriptions-amount">
        <FormatListNumberedIcon style={{ fill: '#abd6eb', fontSize: '48px' }} />
        <br />
        <NumberFormat displayType="text" thousandSeparator value={totalSubscriptions} style={{ fontSize: '1.8rem' }} />
        <br />
        <div>Total Subscriptions</div>
        {' '}
        <div className="total-amount" style={{ fontSize: '12px', color: '#515459' }}>
          All subscriptions*
        </div>
      </div>
    </div>
    <div className="total-subscriptions-active-figures">
      <div className="total-subscriptions-active-amount">
        <CheckCircleIcon style={{ fill: 'rgb(84, 105, 212)', fontSize: '48px' }} />
        <br />
        <NumberFormat displayType="text" value={totalSubscriptionsActive} style={{ fontSize: '1.8rem' }} />
        <br />
        <div>Total Confirmed (Automatic)</div>
        {' '}
        <div className="total-amount" style={{ fontSize: '12px', color: '#515459' }}>
          The total of confirmed/active weekly Automatic Stripe subscriptions
        </div>
      </div>
    </div>
    <div className="total-subscriptions-inactive-figures">
      <div className="total-subscriptions-inactive-amount">
        <RemoveCircleIcon style={{ fill: '#ac372f', fontSize: '48px' }} />
        <br />
        <NumberFormat displayType="text" value={totalSubscriptionsInactive} style={{ fontSize: '1.8rem' }} />
        <br />
        <div>Total Inactive (Automatic)</div>
        {' '}
        <div className="total-amount" style={{ fontSize: '12px', color: '#515459' }}>
          Automatic Stripe Subscriptions that have not yet been confirmed by your customers
        </div>
      </div>
    </div>
    <div className="total-subscriptions-offline-figures">
      <div className="total-subscriptions-active-amount">
        <CheckCircleIcon style={{ fill: '#47FEB4', fontSize: '48px' }} />
        <br />
        <NumberFormat displayType="text" value={totalSubscriptionsOfflinePayment} style={{ fontSize: '1.8rem' }} />
        <br />
        <div>Total Invoice/Pay on Arrival</div>
        {' '}
        <div className="total-amount" style={{ fontSize: '12px', color: '#515459' }}>
          Payment for Invoice/Pay on Arrival Subscriptions
          will be taken when a customer pays via the payment link or pays on site
        </div>
      </div>
    </div>
  </div>
);

SubscriptionCards.propTypes = {
  total: PropTypes.number.isRequired,
  totalSubscriptions: PropTypes.number.isRequired,
  totalSubscriptionsActive: PropTypes.number.isRequired,
  totalSubscriptionsInactive: PropTypes.number.isRequired,
  totalSubscriptionsOfflinePayment: PropTypes.number.isRequired,
  currencySym: PropTypes.string,
};

SubscriptionCards.defaultProps = {
  currencySym: '£',
};

export default (SubscriptionCards);
