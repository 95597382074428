import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import ToggleButtons from '../../../components/ToggleButtons';

const addAddOnButtonsData = [{
  buttonTitle: 'Changing Room',
  buttonValue: 'CHANGING_ROOM',
  data: {
    name: 'Changing Room',
    price: '10',
  },
},
{
  buttonTitle: 'Physio',
  buttonValue: 'PHYSIO',
  data: {
    name: 'Physio',
    price: '25',
  },
},
{
  buttonTitle: 'Party Room',
  buttonValue: 'PARTY_ROOM',
  data: {
    name: 'Party Room',
    price: '25',
  },
}];

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  price: Yup.number().required('Price is required'),
});

const AddAmenity = ({
  onSubmit, onClose, values,
}) => {
  const [initialValues, setInitialValues] = useState(values);

  useEffect(() => {
    if (values) {
      setInitialValues(values);
    }
  }, [values]);

  return (
    <Dialog
      open={values}
      fullWidth
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        enableReinitialize
      >
        {
            ({
              values, errors, touched, handleChange,
            }) => (
              <Form>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <DialogTitle>
                    {typeof values.amenityIndex === 'number' ? 'Edit Amenity' : 'Add Amenity'}
                  </DialogTitle>

                  <DialogActions variant="none">
                    <CloseDialogIcon onClick={onClose} />
                  </DialogActions>
                </div>

                <DialogContent>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <div>
                      <span>
                        Quick Options:
                      </span>
                      <div className="options" style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                        <ToggleButtons
                          buttonsData={addAddOnButtonsData}
                          changeOption={(option) => {
                            const item = addAddOnButtonsData.find((x) => x.buttonValue === option);
                            setInitialValues((prev) => ({ ...prev, ...item.data }));
                          }}
                        />
                      </div>
                    </div>

                    <PBInput
                      id="name"
                      name="name"
                      type="text"
                      label="Name"
                      value={values.name}
                      onChange={handleChange}
                      isError={touched.name && Boolean(errors.name)}
                      errorMessage={errors.name}
                    />

                    <PBInput
                      id="price"
                      name="price"
                      type="currency"
                      label="Price"
                      value={values.price}
                      onChange={handleChange}
                      isError={touched.price && Boolean(errors.price)}
                      errorMessage={errors.price}
                    />

                  </div>

                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color="secondary" variant="outlined">
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    {typeof values.amenityIndex === 'number' ? 'Save Changes' : 'Add'}
                  </Button>
                </DialogActions>
              </Form>
            )
          }
      </Formik>
    </Dialog>
  );
};

AddAmenity.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  values: PropTypes.shape({
    siteIndex: PropTypes.number,
    amenityIndex: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.string,
  }),
};

AddAmenity.defaultProps = {
  values: null,
};

export default AddAmenity;
