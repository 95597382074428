import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as leagueActions from '../../../reducers/leaguesReducer';

class CreateAdhocFixture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      type: 'LEAGUE',
      teamA: '',
      teamB: '',
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
      type: 'LEAGUE',
      teamA: '',
      teamB: '',
      round: '',
    });
  };

  handleCreateNewFixture = () => {
    const {
      round, type, teamA,
      teamB,
    } = this.state;
    const { createNewFixture, leagueId } = this.props;
    createNewFixture(leagueId, round, type, teamB, teamA);
    this.setState({
      open: false,
      type: 'LEAGUE',
      teamA: '',
      teamB: '',
      round: '',
    });
  }

  roundsOptions = (totalRounds) => {
    const rounds = [];
    for (let index = 0; index < totalRounds; index += 1) {
      rounds.push(index + 1);
    }
    return rounds;
  }

  render() {
    const isMobile = window.innerWidth < 768;

    const {
      type,
      open,
      teamA,
      teamB,
      round,
    } = this.state;
    const { teams, totalRounds } = this.props;
    const rounds = this.roundsOptions(totalRounds);
    return (
      <div>
        <Button color="primary" variant="contained" onClick={() => this.handleClickOpen()}>
          Create New Fixture
        </Button>
        <Dialog
          open={open}
          fullScreen={isMobile}
          fullWidth
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>
              Create New Fixture
            </DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <DialogContent>
            <InputLabel id="currency-select-outlined-label">Fixture Type</InputLabel>
            <Select
              displayEmpty
              value={type}
              style={{ margin: 10 }}
              variant="outlined"
              label="Adjustment Type"
              onChange={(event) => this.setState(
                { type: event.target.value },
              )}
            >

              <MenuItem value="FRIENDLY">Friendly</MenuItem>
              <MenuItem value="LEAGUE">League</MenuItem>
            </Select>
            <InputLabel id="currency-select-outlined-label">Home Team</InputLabel>
            <Select
              displayEmpty
              style={{ margin: 10 }}
              value={teamA}
              variant="outlined"
              label="Adjustment Type"
              onChange={(event) => this.setState(
                { teamA: event.target.value },
              )}
            >
              {teams.map((team) => (
                <MenuItem value={team.id}>{team.name}</MenuItem>
              ))}

            </Select>
            <InputLabel id="currency-select-outlined-label">Away Team</InputLabel>
            <Select
              displayEmpty
              style={{ margin: 10 }}
              value={teamB}
              variant="outlined"
              label="Adjustment Type"
              onChange={(event) => this.setState(
                { teamB: event.target.value },
              )}
            >

              {teams.map((team) => (
                <MenuItem value={team.id}>{team.name}</MenuItem>
              ))}
            </Select>
            <InputLabel id="currency-select-outlined-label">Round</InputLabel>
            <Select
              displayEmpty
              style={{ margin: 10 }}
              value={round}
              variant="outlined"
              label="Adjustment Type"
              onChange={(event) => this.setState(
                { round: event.target.value },
              )}
            >

              {rounds.map((round) => (
                <MenuItem value={round}>{round}</MenuItem>
              ))}
              <MenuItem value={totalRounds + 1}>New Round</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary" variant="outlined">
              Close
            </Button>
            <Button onClick={() => this.handleCreateNewFixture()} color="primary" variant="contained">
              Add Fixture
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { events } = state;
  const { eventStore, event } = events;
  return {
    eventStore,
    event,
  };
}

const mapDispatchToProps = (dispatch) => ({
  createNewFixture: (leagueId, round, type, teamA, teamB) => dispatch(
    leagueActions.createNewFixture(leagueId, round, type, teamA, teamB),
  ),
});

CreateAdhocFixture.propTypes = {
  leagueId: PropTypes.string.isRequired,
  totalRounds: PropTypes.number.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  createNewFixture: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps, mapDispatchToProps,
)(CreateAdhocFixture);
