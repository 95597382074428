/* eslint-disable no-param-reassign */
import Axios from 'axios';

// Set the Base URL
const instance = Axios.create({
  baseURL: process.env.REACT_APP_PB_API_URL,
});

// Add auth token to each request
instance.interceptors.request.use((config) => {
  const apiKey = process.env.REACT_APP_PB_API_KEY;
  const token = localStorage.getItem('PBtoken');
  config.headers['Api-Key'] = `ApiKey ${apiKey}`;
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export default instance;
