import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import * as usersActions from '../../reducers/usersReducer';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import InviteUserForm from './InviteUserForm';

const styles = {
  dialogContent: {
    paddingRight: '2.5rem',
    paddingLeft: '2.5rem',
    paddingBottom: '2.5rem',
  },
  dialogTitle: { paddingLeft: '0rem' },
  actionWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '1rem',
  },
  triggerButton: {
    fontWeight: 800,
    marginRight: '1.25rem',
    width: 225,
  },
};

const InviteUserDialog = (props) => {
  const {
    isOpen,
    isSubmitting,
    isSuccess,
    isError,
    onToggleDialog,
    onInviteAUser,
  } = props;
  const [displaySnackbar, setDisplaySnackbar] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  const handleSubmit = (values) => {
    const reqBody = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      teamName: values.teamName,
      phone: values.phone,
      dialCode: values.dialCode,
    };
    onInviteAUser(reqBody);
  };

  const handleOpenClick = () => {
    onToggleDialog();
  };

  const handleDialogClose = () => {
    onToggleDialog();
  };

  const handleSnackbarCloseClick = () => {
    setDisplaySnackbar(false);
  };

  useEffect(() => {
    if (isSuccess) {
      handleDialogClose();
      setDisplaySnackbar(true);
    }
  }, [isSuccess]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        style={styles.triggerButton}
        onClick={handleOpenClick}
      >
        Add customer
      </Button>
      <Dialog
        open={isOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullScreen={isMobile}
      >
        <div style={styles.actionWrapper}>
          <CloseDialogIcon style={styles.closeDialogIcon} onClick={handleDialogClose} />
        </div>
        <DialogContent style={styles.dialogContent}>
          <DialogTitle style={styles.dialogTitle} color="primary">Invite A New User</DialogTitle>
          <DialogContentText>
            Send an email invitation to a new member,
            so they can sign-up to Pitchbooking and start booking your facilities!
          </DialogContentText>
          <InviteUserForm
            onHandleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            isError={isError}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={displaySnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarCloseClick}
      >
        <Alert onClose={handleSnackbarCloseClick} severity="success" sx={{ width: '100%' }}>
          Invitation has been sent!
        </Alert>
      </Snackbar>
    </>
  );
};

InviteUserDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  onToggleDialog: PropTypes.func.isRequired,
  onInviteAUser: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { memberships } = state;
  const { users } = state;
  const {
    isInviteUserDialogOpen: isOpen,
    isInvitingUserRequest: isSubmitting,
    isInviteUserSuccess: isSuccess,
    isInviteUserError: isError,
  } = users;

  return {
    memberships, isOpen, isSubmitting, isSuccess, isError,
  };
}

const mapDispatchToProps = (dispatch) => ({
  onToggleDialog: () => dispatch(usersActions.toggleInviteUserDialog()),
  onInviteAUser: (reqBody) => dispatch(usersActions.inviteAUser(reqBody)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteUserDialog);
