/* eslint-disable no-case-declarations */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import fileDownload from 'js-file-download';
import MaterialTable, {
} from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import moment from 'moment';
import IdTag from '@pitchbooking-dev/pb-shared/lib/components/IdTag';
import { generateTotalWithTaxText, invoiceNumberFormatted } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import TableFormattedName from '../../components/TableFormattedName';
import DownloadPdf from '../../components/DownloadPdf';

import '../../styles/payments.css';
import '../../styles/order.css';

export const valueToTagMap = (paymentMethod, stripeId, paymentId, subscriptions, invoice) => {
  let stripeUrl = `https://dashboard.stripe.com/${stripeId}/payments/${paymentId}`;
  if (paymentId && paymentId.startsWith('in_')) { stripeUrl = `https://dashboard.stripe.com/${stripeId}/invoices/${paymentId}`; }
  switch (paymentMethod) {
    case 'CARD':
      const cardText = invoice && invoice.length ? `Card: ${invoiceNumberFormatted(invoice[0].invoiceNumber)}` : 'Card';
      return (<div className="cardTag" style={{ margin: '0px' }}>{ cardText }</div>);
    case 'CASH':
      const cashText = invoice && invoice.length ? `Cash: ${invoiceNumberFormatted(invoice[0].invoiceNumber)}` : 'Cash';
      return (<div className="cashTag" style={{ margin: '0px' }}>{ cashText }</div>);
    case 'ZELLE':
      const zelleText = invoice && invoice.length ? `Zelle: ${invoiceNumberFormatted(invoice[0].invoiceNumber)}` : 'Zelle';
      return (<div className="zelleTag" style={{ margin: '0px' }}>{ zelleText }</div>);
    case 'BANKTRANSFER':
      const bankTransferText = invoice && invoice.length ? `Bank Transfer: ${invoiceNumberFormatted(invoice[0].invoiceNumber)}` : 'Bank Transfer';
      return (<div className="bankTransferTag" style={{ margin: '0px' }}>{bankTransferText}</div>);
    case 'STRIPE':
      const textToDisplay = invoice && invoice.length ? `Stripe Invoice: ${invoiceNumberFormatted(invoice[0].invoiceNumber)}` : 'Stripe';
      return (
        <div className="stripeTag" style={{ margin: '0px' }}>
          <a style={{ color: '#FFF', cursor: 'pointer' }} target="_blank" rel="noopener noreferrer" href={stripeUrl}>
            {textToDisplay}
          </a>
        </div>
      );
    case 'STRIPE_SUBSCRIPTION':
      let text = 'Stripe Subscription';
      let className = 'stripeSubscriptionTag';
      if (subscriptions && subscriptions.length > 0) {
        text = `Stripe Subscription - ${subscriptions[0].productType}`;
        if (subscriptions[0].productType === 'MEMBERSHIP') {
          className = 'stripeMembershipTag';
        }
      }
      return (
        (
          <div style={{ margin: '0px', whiteSpace: 'nowrap' }}>
            <a className={className} target="_blank" rel="noopener noreferrer" href={stripeUrl}>
              {text}
            </a>
          </div>
        )
      );
    default:
      return (<div />);
  }
};

export const generateOrderStatusTypeTag = (status) => {
  let orderStatusTypeTag;
  switch (status) {
    case 'CREATED':
      orderStatusTypeTag = (<div className="successTag">Success</div>);
      break;
    case 'FAILED':
      orderStatusTypeTag = (<div className="failTag">Failed</div>);
      break;
    case 'VOIDED':
      orderStatusTypeTag = (<div className="voidTag">Voided</div>);
      break;
    case 'REFUNDED': // refund
      orderStatusTypeTag = (<div className="voidTag">Refunded</div>);
      break;
    default:
      break;
  }
  return orderStatusTypeTag;
};

const OrderTable = ({
  orders, stripeId, currencySym, showUser,
}) => {
  const formatExportData = (data) => data.map((row) => ({
    id: row.id,
    booker: `${row.user.firstName} ${row.user.lastName}`,
    total: (row.total / 100).toFixed(2),
    totalExTax: row.totalExTax.toFixed(2),
    tax: row.tax.toFixed(2),
    paymentMethod: row.paymentMethod,
    status: row.status,
    paidAt: `${moment(row.paidAt).format('DD/MM/YYYY h:mm a')}`,
    invoiceNumber: row.invoice && row.invoice.length ? invoiceNumberFormatted(row.invoice[0].invoiceNumber) : '',
  }));

  const handleCsvExport = (columns, data) => {
    const newData = formatExportData(data);
    const exportData = newData.map((row) => columns.map((columnDef) => row[columnDef.field])
      .join(','));
    const csvData = [
      columns.map((columnDef) => columnDef.title).join(','),
      ...exportData,
    ].join('\n');
    fileDownload(csvData, 'Pitchbooking Orders.csv');
  };

  const handlePdfExport = (columns, data) => {
    const newData = formatExportData(data);
    const dataArray = newData.map(
      (row) => [
        row.id,
        row.booker,
        row.total,
        row.totalExTax,
        row.tax,
        row.invoiceNumber ? `PB-INV-${row.invoiceNumber}` : 'N/A',
        row.paymentMethod,
        row.status,
        row.paidAt,
      ],
    );
    const columnsArray = columns.map((columnDef) => columnDef.title);
    DownloadPdf(columnsArray, dataArray, 'Pitchbooking Orders');
  };

  return (
    <div className="table-section">
      <TableContainer
        style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden' }}
        component={Paper}
      >
        <MaterialTable
          className="table"
          aria-label="simple table"
          data={orders}
          title="Orders"
          icons={{
            NextPage: () => <ChevronRight />,
            PreviousPage: () => <ChevronLeft />,
            Search: () => <Search />,
            Export: () => <SaveAlt />,
            ResetSearch: () => <Clear />,
            SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
          }}
          columns={[
            {
              title: 'Order ID',
              field: 'id',
              render: (row) => (
                <a href={`/payments/${row.id}`}>
                  <IdTag title={row.id} />
                  {' '}
                </a>
              ),
            },
            {
              title: 'Booker',
              field: 'booker',
              render: (row) => (
                <TableFormattedName
                  user={row.user}
                  showTeam
                />
              ),
              customFilterAndSearch: (term, row) => {
                const string = `${row.user?.firstName} ${row.user?.lastName} ${row.user?.teamName}`.toLowerCase();
                return string.includes(term.toLowerCase());
              },
              hidden: !showUser,
            },
            {
              title: 'Total',
              field: 'total',
              render: (row) => (`${(row.status === 'REFUNDED') ? '-' : ''}${generateTotalWithTaxText(row, currencySym, true)}`),
            },
            {
              title: 'TotalExTax',
              field: 'totalExTax',
              hidden: true,
            },
            {
              title: 'Tax',
              field: 'tax',
              hidden: true,
            },
            {
              title: 'Invoice Number',
              field: 'invoiceNumber',
              hidden: true,
            },
            {
              title: 'Payment Method',
              field: 'paymentMethod',
              render: (row) => (
                valueToTagMap(
                  row.paymentMethod, stripeId, row.paymentId, row.subscriptions, row.invoice,
                )
              ),
            },
            {
              title: 'Status',
              field: 'status',
              render: (row) => (generateOrderStatusTypeTag(row.status)),
            },
            {
              title: 'Time Processed',
              field: 'paidAt',
              render: (row) => (
                `${moment(row.paidAt).format('D MMM YYYY, LT')}${row.createdByUser ? `,  by ${[row.createdByUser.firstName, row.createdByUser.lastName].join(' ')}` : ''}`
              ),
            },
          ]}
          options={{
            search: true,
            tableLayout: 'auto',
            exportButton: true,
            exportCsv: (columns, data) => handleCsvExport(columns, data),
            exportPdf: (columns, data) => handlePdfExport(columns, data),
            emptyRowsWhenPaging: false,
            pageSize: 25,
            pageSizeOptions: [10, 25, 50, 100],
            showFirstLastPageButtons: false,
            // selection: true,
            showTitle: false,
            headerStyle: {
              backgroundColor: '#efefef',
              fontWeight: 'bold',
            },
          }}
          localization={{
            toolbar: {
              exportTitle: 'Download',
            },

          }}
        />
      </TableContainer>
    </div>
  );
};

OrderTable.propTypes = {
  orders: PropTypes.shape().isRequired,
  stripeId: PropTypes.string.isRequired,
  currencySym: PropTypes.string.isRequired,
  showUser: PropTypes.bool,
};

OrderTable.defaultProps = {
  showUser: true,
};

export default (OrderTable);
