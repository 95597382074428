import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import React from 'react';

const NextSteps = () => (
  <div
    style={{
      margin: 'auto', paddingTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center',
    }}
  >
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/FmXYQkScU_o?si=8y-ZvA2LON0v6_EV"
      title="Pitchbooking Welcome"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    />
    <div style={{ display: 'flex', alignSelf: 'center' }}>
      <a href="https://meetings.hubspot.com/lyndon-mccullough" target="_blank" rel="noreferrer">
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '20px' }}
        >
          Schedule call
        </Button>
      </a>
    </div>
    <h3>Learn more about</h3>
    <div style={{
      display: 'flex', alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-evenly',
    }}
    >
      <a href="/product/facilities" rel="noreferrer" style={{ textDecoration: 'none' }}>
        <Card style={{
          width: '100%', marginRight: '10px', textAlign: 'center', minWidth: 200, maxWidth: 200, color: '#6699FF',
        }}
        >
          <CardContent>
            Facility Management
          </CardContent>
        </Card>
      </a>
      <a href="/product/events" rel="noreferrer" style={{ textDecoration: 'none' }}>
        <Card style={{
          width: '100%', marginLeft: '20px', marginRight: '20px', textAlign: 'center', minWidth: 200, maxWidth: 200, color: '#6699FF',
        }}
        >
          <CardContent>
            Events
          </CardContent>
        </Card>
      </a>
      <a href="/product/memberships" rel="noreferrer" style={{ textDecoration: 'none' }}>
        <Card style={{
          width: '100%', marginLeft: '10px', textAlign: 'center', minWidth: 200, maxWidth: 200, color: '#6699FF',
        }}
        >
          <CardContent>Memberships</CardContent>
        </Card>
      </a>
    </div>
  </div>
);

export default NextSteps;
