import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import ScheduleGroupDialogActions from './ScheduleGroupDialogActions';
import ScheduleOpeningTimes from './ScheduleOpeningTimes';
import * as scheduleActions from '../../../reducers/schedulesReducer';
import 'rc-time-picker/assets/index.css';

class ScheduleOpeningTimesDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClickOpen = () => {
    const { toggleScheduleGroupDialog } = this.props;
    toggleScheduleGroupDialog();
  };

  handleRequestClose = () => {
    const {
      toggledEditingScheduleGroupOpeningTimes,
      resetScheduleGroupCreation,
      toggleScheduleGroupDialog,
      editingScheduleGroupOpeningTimes,
    } = this.props;

    if (editingScheduleGroupOpeningTimes) {
      toggledEditingScheduleGroupOpeningTimes();
    }

    resetScheduleGroupCreation();
    toggleScheduleGroupDialog();
  };

  handleRequestToCancel = () => {
    const { getSchedules } = this.props;
    this.handleRequestClose();
    getSchedules();
  };

  createNewPricingGroup = () => {
    const { requestPriceGroupCreation } = this.props;
    requestPriceGroupCreation();
    this.handleRequestClose();
  }

  handleSave = () => {
    const { saveScheduleGroup } = this.props;
    saveScheduleGroup();
    this.handleRequestClose();
  }

  render() {
    const {
      scheduleGroup,
      editingScheduleGroupOpeningTimes,
      updateScheduleTimes,
    } = this.props;

    const {
      schedules,
    } = scheduleGroup;

    const title = !editingScheduleGroupOpeningTimes ? 'New Schedule Group' : 'Edit Schedule Group';

    const isMobile = window.innerWidth < 768;

    return (
      <div>
        <div />
        <Dialog
          fullWidth
          maxWidth="lg"
          open={editingScheduleGroupOpeningTimes}
          onBackdropClick={this.handleRequestToCancel}
          onEscapeKeyDown={this.handleRequestToCancel}
          fullScreen={isMobile}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestToCancel()} />
            </DialogActions>
          </div>
          <DialogContent>
            Opening Times
            <ScheduleOpeningTimes
              schedules={schedules}
              onUpdate={updateScheduleTimes}
            />
          </DialogContent>
          <DialogActions>
            <ScheduleGroupDialogActions
              handleRequestToCancel={this.handleRequestToCancel}
              handleSave={this.handleSave}
              createSchedule={this.createNewPricingGroup}
            // disabled={disabled}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ScheduleOpeningTimesDialog.propTypes = {
  scheduleGroup: PropTypes.shape().isRequired,
  editingScheduleGroupOpeningTimes: PropTypes.bool.isRequired,
  updateScheduleTimes: PropTypes.func.isRequired,
  toggleScheduleGroupDialog: PropTypes.func.isRequired,
  toggledEditingScheduleGroupOpeningTimes: PropTypes.func.isRequired,
  getSchedules: PropTypes.func.isRequired,
  saveScheduleGroup: PropTypes.func.isRequired,
  requestPriceGroupCreation: PropTypes.func.isRequired,
  resetScheduleGroupCreation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  schedule: state.schedules.schedule,
  scheduleGroup: state.schedules.scheduleGroup,
  ScheduleOpeningTimesDialogOpen: state.schedules.ScheduleOpeningTimesDialogOpen,
  editingScheduleGroupOpeningTimes: state.schedules.editingScheduleGroupOpeningTimes,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getSchedules: () => scheduleActions.getSchedules(),
  saveScheduleGroup: () => scheduleActions.saveScheduleGroupOpeningTimes(),
  toggleScheduleGroupDialog: () => scheduleActions.toggleScheduleGroupDialog(),
  toggledEditingScheduleGroupOpeningTimes: (
  ) => scheduleActions.toggledEditingScheduleGroupOpeningTimes(),
  resetScheduleGroupCreation: () => scheduleActions.resetScheduleGroupCreation(),
  updateScheduleGroupStore: (value) => scheduleActions.updateScheduleGroupStore(
    value,
  ),
  updateScheduleTimes: (value) => scheduleActions.updateScheduleTimes(
    value,
  ),
}, dispatch);

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ScheduleOpeningTimesDialog);
