/* eslint-disable no-unreachable */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Dialog, DialogTitle, DialogActions, DialogContent, Button,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import moment from 'moment';
import { useCompany, useMobile, useToast } from '../../../../hooks';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';
import { updateReservationPriceService } from '../../../../services/reservationsServices';

const validationSchema = Yup.object().shape({
  allocations: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required('ID is required'),
      price: Yup.number().required('Price is required'),
    }),
  ),
  amenities: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required('ID is required'),
      price: Yup.number().required('Price is required'),
    }),
  ),
  addons: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required('ID is required'),
      price: Yup.number().required('Price is required'),
    }),
  ),
});

// TODO: Need to manage amenities and addons

export const UpdatePriceModal = ({
  isOpen, onClose, onSuccess, reservation,
}) => {
  const queryClient = useQueryClient();
  const isMobile = useMobile();
  const toast = useToast();
  const { currencySym } = useCompany();

  const mutation = useMutation({
    mutationFn: async (data) => {
      // Only send allocations that have been changed
      const allocations = data.allocations.filter((x) => {
        const original = reservation.allocations.find((y) => y.id === x.id);
        return original.price !== x.newPrice;
      });

      if (allocations.some((a) => a.newPrice)) {
        const res = await updateReservationPriceService(
          reservation.companyId,
          reservation.id, {
            allocations: [
              ...allocations,
            ],
          },
        );

        if (res.status !== 200) {
          throw new Error(res.data);
        }

        return res.data;
      }
      throw new Error('No Price Provided');
    },
    onError: (error) => {
      // queryClient.invalidateQueries(['subscription', subscription.id]);
      toast.trigger({
        type: 'error',
        message: 'Failed to update the price of this booking!',
      });
      console.error(error);
    },
    onSuccess: () => {
      toast.trigger({
        type: 'success',
        message: 'Price of booking updated successfully',
      });
      // queryClient.invalidateQueries(['subscription', subscription.id]);
      onClose();
      onSuccess?.();
    },
  });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={isMobile}
      maxWidth="sm"
      fullWidth
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle>Update Booking Price</DialogTitle>
        <DialogActions variant="none">
          <CloseDialogIcon onClick={onClose} />
        </DialogActions>
      </div>

      <Formik
        initialValues={{
          allocations: reservation.allocations,
          amenities: reservation.amenities,
          addons: reservation.addons,
        }}
        validationSchema={validationSchema}
        onSubmit={(data) => mutation.mutate(data)}
      >
        {({
          values, handleSubmit, errors, getFieldProps, touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
            >
              {values.allocations.map((allocation, index) => (
                <PBInput
                  key={index}
                  id={`allocations.${index}.newPrice`}
                  type="number"
                  label={`${allocation.facility.name} (${moment(allocation.startTime).format('HH:mm')} - ${moment(allocation.endTime).add(1, 's').format('HH:mm')})`}
                  {...getFieldProps(`allocations.${index}.newPrice`)}
                  isError={
                  touched.allocations
                  && touched.allocations[index].newPrice
                  && errors.allocations
                  && errors.allocations[index]?.newPrice
                }
                  errorMessage={
                  touched.allocations
                  && touched.allocations[index].newPrice
                  && errors.allocations
                  && errors.allocations[index]?.newPrice
                }
                />
              ))}

              {/* TODO: This should take into account amenities and addons as well */}
              <div>
                <Typography>
                  {`Current price: ${currencySym}${reservation.total.toFixed(2)}`}
                </Typography>
                <Typography>
                  {`Updated price: ${currencySym}${
                    (
                      values.allocations.reduce((acc, curr) => acc + (curr.newPrice || 0), 0)
                      + values.amenities.reduce((acc, curr) => acc + (curr.newPrice || 0), 0)
                      + values.addons.reduce((acc, curr) => acc + (curr.newPrice || 0), 0)
                    ).toFixed(2)
                  }`}
                </Typography>
              </div>
            </DialogContent>

            <DialogActions>
              <Button
                type="button"
                onClick={onClose}
                color="secondary"
                autoFocus
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                autoFocus
                disabled={mutation.isLoading}
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
