/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Divider,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import TimePicker from 'rc-time-picker';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import { SingleDatePicker } from 'react-dates';
import { withStyles } from '@material-ui/core/styles';
import MenuHeading from '../../../components/MenuHeading';
import * as leagueActions from '../../../reducers/leaguesReducer';
import FacilityAndSubFacilityPicker from '../../facilities/components/FacilityAndSubFacilityPicker';

const styles = () => ({
  root: {
    minWidth: 400,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});

/* eslint-disable */
function LeagueTimingsForm(props) {
  const [focusedInput, setFocusedInput] = useState(null);
  const [focused, setFocused] = useState(false);
  const leagueData = useSelector((state) => state.leagues.league);
  const initialAccessRestriction = useMemo(() => leagueData.accessRestrictions.find(x => x.status === 'INACTIVE'), [leagueData]);
  
  const {
    leagueStore, updateLeagueStore,
    companyFacilities, updateFacilities, league
  } = props;
  const isFocused = focused;
  const {
   facilities, facilityRequired, startDate, gameLength, startTime,
  } = leagueStore;

  useEffect(() => {
    updateLeagueStore({
      startDate: moment(startDate).format('YYYY-MM-DD HH:mm'),
    })

    if (leagueData && leagueData.kickOffTime) {
      updateLeagueStore({
        startTime: moment(leagueData.kickOffTime, 'HH:mm'),
      })
    }

    if (leagueData && leagueData.gameLength) {
      updateLeagueStore({
        gameLength: leagueData.gameLength,
      })
    }
  } , [])

  useEffect(() => {
    if (initialAccessRestriction) {
      updateLeagueStore({
        facilities: initialAccessRestriction.facilities,
      })
    }
  }, [initialAccessRestriction]);

  const totalRounds = league ? Math.max(...league.fixtures.map((o) => o.round)) : 0;

  return (
    <div>
      <AlternativelyVisible condition={initialAccessRestriction?.facilities.length <= 0}>
        <>
          <MenuHeading title="Select Facility" />
          <FacilityAndSubFacilityPicker
            facilities={companyFacilities}
            listName="Facilities"
            onUpdate={(updatedFacilities) => updateLeagueStore(
              { facilities: updatedFacilities },
            )}
            checkedFacilities={facilities}
            flagFacilitiesChanged={() => updateLeagueStore(
              { changedFacilities: true },
            )}
          />
        </>
        <>
          <Typography>This league will run on the following facilities:</Typography>
          {facilities.map((facility) => (
            <ul key={facility.id}>
              <li>{facility.name}</li>
            </ul>
          ))}
        </>
      </AlternativelyVisible>
      
       <MenuHeading title="Start Date" />
      <SingleDatePicker
        date={moment(startDate)}
        onDateChange={(date) => updateLeagueStore({
          startDate: moment(date).format('YYYY-MM-DD HH:mm'),
        })}
        style={{width: 200}}
        focused={isFocused}
        onFocusChange={({ focused }) => setFocused(focused)}
        displayFormat="ddd, DD/MM/YY"
        numberOfMonths={1}
        isOutsideRange={() => false}
        fullWidth
        noBorder
      />

      {/* Only show if not added on league creation */}
      <ConditionallyVisible condition={!leagueData?.kickOffTime}>
      <MenuHeading title="League Kick Off Time" />
      <div style={{ display: 'flex' }}>
      <div style={{ margin: '10px' }}>
        <TimePicker
          value={startTime ? moment(startTime) : startTime}
          showSecond={false}
          minuteStep={5}
          clearIcon={null}
          allowEmpty
          onChange={(value) => updateLeagueStore({
            startTime: moment(value).startOf('minute')
              .format('YYYY-MM-DD HH:mm'),
          })}
        />
      </div>
        </div>
      </ConditionallyVisible>
      
      <ConditionallyVisible condition={!leagueData?.gameLength}>
      <MenuHeading title="Game Length" />
      <Select
        displayEmpty
        value={gameLength}
        onChange={(event) => updateLeagueStore({
          gameLength: event.target.value,
        })}
        name="gameLength"
        label="Game Length"
      >
        <MenuItem value={20}>20 Mins</MenuItem>
        <MenuItem value={30}>30 Mins</MenuItem>
        <MenuItem value={35}>35 Mins</MenuItem>
        <MenuItem value={40}>40 Mins</MenuItem>
        <MenuItem value={45}>45 Mins</MenuItem>
        <MenuItem value={50}>50 Mins</MenuItem>
        <MenuItem value={55}>55 Mins</MenuItem>
        <MenuItem value={60}>60 Mins</MenuItem>
        </Select>
        </ConditionallyVisible>
      <Divider />

 
      <div style={{ height: '20px' }} />
      <ConditionallyVisible condition={totalRounds && startTime && startDate }>
      <MenuHeading
       title={`This league will last for ${totalRounds} weeks starting at ${moment(startTime).format('HH:mm')} on ${moment(startDate).format('ddd DD MMM YYYY')}` }
       />
      </ConditionallyVisible>
    </div>
  );
}


function mapStateToProps(state) {
  const { leagues, facilities } = state;
  const { leagueStore, league } = leagues;
  const { companyFacilities } = facilities;
  return {
    leagueStore,
    companyFacilities,
    league,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateLeagueStore: (value) => dispatch(leagueActions.updateLeagueStore(
    value,
  )),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withStyles(styles())(LeagueTimingsForm));
