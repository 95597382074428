/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
import React from 'react';
import { connect } from 'react-redux';
import PricingTable from './PricingTable';
import * as companyActions from '../../reducers/companiesReducer';
import * as facilitiesActions from '../../reducers/facilitiesReducer';
import * as eventActions from '../../reducers/eventsReducer';
import 'rc-time-picker/assets/index.css';
import * as pricingAction from '../../reducers/pricingReducer';

class PricingPage extends React.Component {
  componentDidMount() {
    const {
      requestPricePackagesRetrieval,
      requestFacilitiesRetrieval,
      requestEventsRetrieval,
      getCompanyInfo,
      getMemberships,
    } = this.props;
    requestFacilitiesRetrieval();
    requestEventsRetrieval();
    requestPricePackagesRetrieval();
    getCompanyInfo();
    getMemberships();
  }

  render() {
    const {
      facilities, companies, pricePackages, pricingErrorMessages,
    } = this.props;
    return (
      <div>
        <div style={{ backgroundColor: '#fff' }} className="App" />
        <PricingTable
          pricePackages={pricePackages}
          pricingErrorMessages={pricingErrorMessages}
          facilities={facilities.companyFacilities}
          companyMemberships={companies.memberships}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  const {
    companies, pricing, facilities,
  } = state;

  const { pricePackages, pricingErrorMessages } = pricing;
  return {
    companies,
    pricePackages,
    pricingErrorMessages,
    facilities,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getCompanyInfo: () => dispatch(companyActions.getCompanyInfo()),
  getMemberships: (priceGroup) => dispatch(
    companyActions.requestCompanyMembershipRetrieval(priceGroup),
  ),
  requestPricePackagesRetrieval: () => dispatch(pricingAction.requestPricePackagesRetrieval()),
  requestFacilitiesRetrieval: () => dispatch(facilitiesActions.requestFacilitiesRetrieval()),
  requestEventsRetrieval: () => dispatch(eventActions.getEvents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingPage);
