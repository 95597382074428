import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function getAnalyticsBookingsService(companyId, query, status, refresh) {
  const { fromDate, toDate } = query.dates;
  const { fromDatePrevious, toDatePrevious } = query.datesPrevious;
  const { bookingType } = query;
  const { facilityIDs } = query;
  const url = `/api/analytics/${companyId}/reservations-analytics-data?bookingType=${bookingType}&from=${fromDate}&to=${toDate}&fromPrevious=${fromDatePrevious}&toPrevious=${toDatePrevious}&status=${status}&refresh=${refresh}`;
  const response = await httpHelpers.authenticatedPostRequest(url, { facilityIDs });
  return response;
}

export async function getAnalyticsEventBookingsService(companyId, query, status, refresh) {
  const { fromDate, toDate } = query.dates;
  const { fromDatePrevious, toDatePrevious } = query.datesPrevious;
  const { bookingType } = query;
  const { eventIDs } = query;
  const url = `/api/analytics/${companyId}/reservations-event-analytics-data?bookingType=${bookingType}&from=${fromDate}&to=${toDate}&fromPrevious=${fromDatePrevious}&toPrevious=${toDatePrevious}&status=${status}&refresh=${refresh}`;
  const response = await httpHelpers.authenticatedPostRequest(url, { eventIDs });
  return response;
}

export async function getAnalyticsFacilitiesService(companyId) {
  const url = `/api/analytics/${companyId}/analytics-facilities`;

  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function getAnalyticsEventsService(companyId) {
  const url = `/api/analytics/${companyId}/analytics-events`;

  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}
