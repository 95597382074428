import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as bookingsModifiersActions from '../../reducers/bookingModifiersReducer';
import EditBookingModifierGroupDialog from './components/EditBookingModifierGroupDialog';
import '../../styles/calendar.css';
import BookingModifiersTable from './components/BookingModifiersTable';
import ActionBar from '../../components/ActionBar';
import * as facilityActions from '../../reducers/facilitiesReducer';
import { BookingModifierDialog } from './components/BookingModifierDialog';

class BookingModifiers extends React.Component {
  constructor(props) {
    super(props);
    const { getCompanyBookingModifiers, requestFacilitiesRetrieval } = this.props;
    requestFacilitiesRetrieval();
    getCompanyBookingModifiers();
  }

  render() {
    const { companyBookingModifiers } = this.props;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', padding: '1.5rem' }}>
        <ActionBar>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <EditBookingModifierGroupDialog />
            <BookingModifierDialog />
          </div>
        </ActionBar>

        <BookingModifiersTable
          bookingModifiers={companyBookingModifiers}
        />
      </div>
    );
  }
}

BookingModifiers.propTypes = {
  getCompanyBookingModifiers: PropTypes.func.isRequired,
  requestFacilitiesRetrieval: PropTypes.func.isRequired,
  companyBookingModifiers: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => {
  const { bookingModifiers } = state;
  const { companyBookingModifiers } = bookingModifiers;
  return { companyBookingModifiers };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyBookingModifiers: () => dispatch(
    bookingsModifiersActions.getCompanyBookingModifiers(),
  ),
  requestFacilitiesRetrieval: () => dispatch(
    facilityActions.requestFacilitiesRetrieval(),
  ),

});

export default connect(mapStateToProps, mapDispatchToProps)(BookingModifiers);
