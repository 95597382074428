/* eslint-disable no-case-declarations */
// PricePackages
export const PRICE_PACKAGES_RETRIEVAL_REQUESTED = 'PRICE_PACKAGES_RETRIEVAL_REQUESTED';
export const PRICE_PACKAGES_RETRIEVAL_SUCCEEDED = 'PRICE_PACKAGES_RETRIEVAL_SUCCEEDED';

export const UPDATE_PRICING_STORE = 'UPDATE_PRICING_STORE';
export const UPDATE_PRICING_STORE_RULE = 'UPDATE_PRICING_STORE_RULE';

export const REQUEST_ADD_FACILITIES_TO_PRICE_GROUP = 'REQUEST_ADD_FACILITIES_TO_PRICE_GROUP';
export const REQUEST_ADD_RULE_TO_PRICE_GROUP = 'REQUEST_ADD_RULE_TO_PRICE_GROUP';

export const UPDATE_PRICING_GROUP_SUCCESS = 'UPDATE_PRICING_GROUP_SUCCESS';
export const UPDATE_PRICING_GROUP_FAIL = 'UPDATE_PRICING_GROUP_FAIL';

export const PRICING_STORE_RESET = 'PRICING_STORE_RESET';

export const REMOVE_PRICE_MODIFIER = 'REMOVE_PRICE_MODIFIER';
export const REMOVE_TEMP_PRICE_MODIFIER = 'REMOVE_TEMP_PRICE_MODIFIER';
export const REQUEST_EDIT_PRICE_GROUP = 'REQUEST_EDIT_PRICE_GROUP';

export const DELETE_PRICE_MODIFIER = 'DELETE_PRICE_MODIFIER';

export const TOGGLE_PRICE_GROUP_DIALOG = 'TOGGLE_PRICE_GROUP_DIALOG';
export const UPDATED_PRICE_GROUP_STORE = 'UPDATED_PRICE_GROUP_STORE';
export const PRICE_GROUP_DIALOG_RESET = 'PRICE_GROUP_DIALOG_RESET';

export const UPDATED_PRICE_MODIFIER_STORE = 'UPDATED_PRICE_MODIFIER_STORE';
export const UPDATED_PRICE_GROUP_PRICE_RULE_STORE = 'UPDATED_PRICE_GROUP_PRICE_RULE_STORE';
export const RESET_PRICE_GROUP_PRICE_MODIFIER_STORE = 'RESET_PRICE_GROUP_PRICE_MODIFIER_STORE';

// Creating a pricing group.
export const PRICE_GROUP_CREATION_REQUEST = 'PRICE_GROUP_CREATION_REQUEST';
export const PRICE_GROUP_CREATION_SUCCEEDED = 'PRICE_GROUP_CREATION_SUCCEEDED';
export const PRICE_GROUP_CREATION_FAILED = 'PRICE_GROUP_CREATION_FAILED';

// Editing a pricing group
export const TOGGLED_EDITING_PRICE_GROUP = 'TOGGLED_EDITING_PRICE_GROUP';
export const PRICE_GROUP_EDIT_ENABLED = 'PRICE_GROUP_EDIT_ENABLED';
export const SAVE_PRICE_GROUP_EDITS_REQUESTED = 'SAVE_PRICE_GROUP_EDITS_REQUESTED';
export const SAVE_PRICE_GROUP_EDITS_SUCCEEDED = 'SAVE_PRICE_GROUP_EDITS_SUCCEEDED';
export const SAVE_PRICE_GROUP_EDITS_FAILED = 'SAVE_PRICE_GROUP_EDITS_FAILED';
export const REQUEST_DELETE_PRICE_MODIFIER = 'REQUEST_DELETE_PRICE_MODIFIER';

// Save a pricing rule
export const PRICE_RULE_SAVE_REQUESTED = 'PRICE_RULE_SAVE_REQUESTED';
export const PRICE_RULE_SAVE_SUCCESSFUL = 'PRICE_RULE_SAVE_SUCCESSFUL';

// Duplidating a pricing group
export const DUPLICATE_PRICE_GROUP_REQUESTED = 'DUPLICATE_PRICE_GROUP_REQUESTED';
export const DUPLICATE_PRICE_GROUP_SUCCESSFUL = 'DUPLICATE_PRICE_GROUP_SUCCESSFUL';

// Delete price group
export const PRICE_GROUP_DELETION_REQUESTED = 'PRICE_GROUP_DELETION_REQUESTED';

const changePriceGroupProperty = (state, newPropertyWithValue) => {
  const { priceGroup } = state;
  const propertyLabel = Object.keys(newPropertyWithValue)[0];
  const oldPropertyValue = state[propertyLabel];
  if (Array.isArray(oldPropertyValue)) {
    return ({
      ...priceGroup,
      [propertyLabel]: [...newPropertyWithValue],
    });
  }
  return ({
    ...state.priceGroup,
    ...newPropertyWithValue,
  });
};

export const initialState = {
  pricePackages: [],
  pricingErrorMessages: [],
  priceGroup: {
    newFacilities: [],
    facilities: [],
    events: [],
    startDate: null,
    endDate: null,
    membership: null,
    priceModifiers: [],
  },
  priceModifiersToDelete: [],
  priceModifier: {
    startTime: null,
    endTime: null,
    days: [],
    subFacility_quantity: null,
    quantity: null,
    duration: null,
    bookedAsAmenity: null,
    ageGroupBookingModifierId: null,
    eventBookingModifierId: null,
    priceType: 'ACTUAL',
    pricePer: 'HOUR',
    value: null,
  },
  updatePricingRequested: false,
  priceGroupDialogOpen: false,
  editingPriceGroup: false,
  savePriceGroupFailedReason: '',
};

export function resetPricingStore() {
  return { type: PRICING_STORE_RESET };
}
export const requestPricePackagesRetrieval = () => (
  { type: PRICE_PACKAGES_RETRIEVAL_REQUESTED }
);
export function requestPricePackagesSuccess(pricePackages, errorMessages = []) {
  return { type: PRICE_PACKAGES_RETRIEVAL_SUCCEEDED, pricePackages, errorMessages };
}
export function updatePricingStore(updatedProperty) {
  return { type: UPDATE_PRICING_STORE, updatedProperty };
}
export function updatePricingStoreRule(updatedProperty) {
  return { type: UPDATE_PRICING_STORE_RULE, updatedProperty };
}
export function updatePricingGroupSuccess() {
  return { type: UPDATE_PRICING_GROUP_SUCCESS };
}
export function updatePricingGroupFail() {
  return { type: UPDATE_PRICING_GROUP_FAIL };
}
export function addFacilitiesToPriceGroup(priceModifiers, membershipId) {
  return { type: REQUEST_ADD_FACILITIES_TO_PRICE_GROUP, priceModifiers, membershipId };
}
export function addRuleToPriceGroup(priceGroup) {
  return { type: REQUEST_ADD_RULE_TO_PRICE_GROUP, priceGroup };
}
export function removePriceModifier(id) {
  return { type: REMOVE_PRICE_MODIFIER, id };
}
export function removeTempPriceModifier(priceModifier) {
  return { type: REMOVE_TEMP_PRICE_MODIFIER, priceModifier };
}
export function deletePriceModifier(priceModifierId) {
  return { type: DELETE_PRICE_MODIFIER, priceModifierId };
}
export function editPriceGroup(priceGroup) {
  return { type: REQUEST_EDIT_PRICE_GROUP, priceGroup };
}
export function togglePriceGroupDialog(priceGroup) {
  return { type: TOGGLE_PRICE_GROUP_DIALOG, priceGroup };
}
export function enablePriceGroupEdit(priceGroup) {
  return { type: PRICE_GROUP_EDIT_ENABLED, priceGroup };
}
export function resetPriceGroupCreation() {
  return { type: PRICE_GROUP_DIALOG_RESET };
}
export function toggledEditingPriceGroup() {
  return { type: TOGGLED_EDITING_PRICE_GROUP };
}
export function updatePriceGroupStore(priceGroup) {
  return { type: UPDATED_PRICE_GROUP_STORE, priceGroup };
}
export function updatePriceModifierStore(value) {
  return { type: UPDATED_PRICE_MODIFIER_STORE, value };
}
export function updatePriceGroupPriceRuleStore(priceModifier) {
  return { type: UPDATED_PRICE_GROUP_PRICE_RULE_STORE, priceModifier };
}
export function resetPriceGroupPriceModifierStore() {
  return { type: RESET_PRICE_GROUP_PRICE_MODIFIER_STORE };
}
export function requestPriceGroupCreation() {
  return { type: PRICE_GROUP_CREATION_REQUEST };
}
export function requestPriceGroupCreationSuccess() {
  return { type: PRICE_GROUP_CREATION_SUCCEEDED };
}
export function requestPriceGroupDuplicate() {
  return { type: DUPLICATE_PRICE_GROUP_REQUESTED };
}
export function requestPriceRuleSave(isEvent) {
  return { type: PRICE_RULE_SAVE_REQUESTED, isEvent };
}
export function requestPriceGroupDelete(priceGroup) {
  return { type: PRICE_GROUP_DELETION_REQUESTED, priceGroup };
}
export function priceGroupDuplicateSuccessful() {
  return { type: DUPLICATE_PRICE_GROUP_SUCCESSFUL };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PRICING_STORE_RESET:
      return {
        ...state,
        updatePricing: {
          ...initialState.updatePricing,
        },
      };
    case RESET_PRICE_GROUP_PRICE_MODIFIER_STORE:
      return {
        ...state,
        priceModifier: {
          ...initialState.priceModifier,
        },
      };
    case PRICE_PACKAGES_RETRIEVAL_SUCCEEDED:
      return {
        ...state,
        pricePackages: action.pricePackages,
        pricingErrorMessages: action.errorMessages,
      };
    case UPDATE_PRICING_STORE:
      return {
        ...state,
        updatePricing: {
          ...state.updatePricing,
          ...action.updatedProperty,
        },
      };
    case UPDATED_PRICE_GROUP_STORE:
      return {
        ...state,
        priceGroup: changePriceGroupProperty(state, action.priceGroup),
      };
    case UPDATED_PRICE_MODIFIER_STORE:
      return {
        ...state,
        priceModifier: {
          ...state.priceModifier,
          ...action.value,
        },
      };
    case UPDATED_PRICE_GROUP_PRICE_RULE_STORE:
      return {
        ...state,
        priceGroup: {
          ...state.priceGroup,
          priceModifiers: [...state.priceGroup.priceModifiers, {
            ...action.priceModifier,
          }],
        },
      };
    case DELETE_PRICE_MODIFIER:
      const x = state.priceModifiersToDelete;
      x.push(action.priceModifierId);
      const tempPriceModifiers = state.priceGroup.priceModifiers;
      const i = tempPriceModifiers.findIndex((item) => item.id === action.priceModifierId);
      if (i > -1) {
        tempPriceModifiers.splice(i, 1);
      }
      return {
        ...state,
        priceModifiersToDelete: x,
        priceGroup: {
          ...state.priceGroup,
          priceModifiers: tempPriceModifiers,
        },
      };
    case REMOVE_TEMP_PRICE_MODIFIER:
      const temporaryPriceModifiers = state.priceGroup.priceModifiers;
      const index = temporaryPriceModifiers.indexOf(action.priceModifier);
      if (index > -1) {
        temporaryPriceModifiers.splice(index, 1);
      }
      return {
        ...state,
        priceGroup: {
          ...state.priceGroup,
          priceModifiers: temporaryPriceModifiers,
        },

      };
    case REQUEST_ADD_RULE_TO_PRICE_GROUP:
    case REQUEST_ADD_FACILITIES_TO_PRICE_GROUP:
    case REQUEST_DELETE_PRICE_MODIFIER:
    case REQUEST_EDIT_PRICE_GROUP:
      return { ...state, updatePricingRequested: true };
    case UPDATE_PRICING_GROUP_SUCCESS:
    case UPDATE_PRICING_GROUP_FAIL:
      return {
        ...state,
        updatePricingRequested: false,
        updatePricing: {
          ...initialState.updatePricing,
        },
      };
    case TOGGLE_PRICE_GROUP_DIALOG:
      return { ...state, priceGroupDialogOpen: !state.priceGroupDialogOpen };
    case TOGGLED_EDITING_PRICE_GROUP:
      return { ...state, editingPriceGroup: !state.editingPriceGroup };
    case PRICE_GROUP_EDIT_ENABLED:
      let memId;
      if (action.priceGroup?.membership) {
        memId = action.priceGroup.membership?.id;
      }
      return {
        ...state,
        priceGroup: {
          ...state.priceGroup,
          ...action.priceGroup,
          membershipId: memId,
        },
      };
    case PRICE_GROUP_DIALOG_RESET: {
      return { ...state, priceGroup: initialState.priceGroup };
    }
    case SAVE_PRICE_GROUP_EDITS_FAILED:
      return { ...state, savePriceGroupFailedReason: action.failedReason };
    case PRICE_GROUP_CREATION_SUCCEEDED:
      return { ...state, priceGroupDialogOpen: false };
    default:
      return state;
  }
};
