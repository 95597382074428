import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import '../../../styles/payments.css';
import Back from '../../../components/Back';
import ActionBar from '../../../components/ActionBar';
import PayLaterConfiguration from '../../facilities/components/editFacility/PayLaterConfiguration';
import * as eventsActions from '../../../reducers/eventsReducer';

const EventMedia = ({ match }) => {
  const { id } = match.params;
  const dispatch = useDispatch();
  const {
    event,
  } = useSelector((state) => ({
    event: state.events.event,
  }));

  useEffect(() => {
    dispatch(eventsActions.getEvent(id));
  }, [id]);

  return (
    <div style={{ backgroundColor: '#fafbff' }}>
      <ActionBar>
        <Back />
      </ActionBar>

      <div style={{
        height: '95.5vh',
        marginLeft: '20px',
        marginRight: '40px',
      }}
      >
        <PayLaterConfiguration
          type="Event"
          eventId={id}
          settings={event?.payLaterConfigurationSetting}
        />
      </div>
    </div>
  );
};

EventMedia.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default EventMedia;
