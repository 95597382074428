/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import ConfigurationTabs from './components/ConfigurationTabs';
import '../../styles/billing.css';

class Calculators extends React.Component {
  render() {
    return (
      <div>
        <ConfigurationTabs />
      </div>
    );
  }
}

export default Calculators;
