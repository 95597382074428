/*
  SettingsModalEvents represents a list of checkboxes that
  allows the user to change the currently selected events.

  If the user makes changes, they are pushed to parent (Settings Modal)
  state using setParentState (this.props.setParentState).
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class SettingsModalEvents extends React.Component {
  constructor(props) {
    super(props);

    // Zip together eventIDs and eventNames.
    const eventIDsAndNames = [];
    const { analyticsEvents } = this.props;

    const { eventIDs, eventNames } = analyticsEvents;

    eventIDs.forEach((eventID, index) => {
      eventIDsAndNames.push(
        [eventID, eventNames[index]],
      );
    });

    this.state = {
      eventIDsAndNames,
    };
  }

  /*
    Fetch the value of selection and push to parent state.
  */
  handleEventToggle(selectedEventID, selectedEventName) {
    const { value, setParentState } = this.props;

    if (!value.eventIDs.includes(selectedEventID)) {
      const newLocalEventIDs = [...value.eventIDs, selectedEventID];
      const newLocalEventNames = [...value.eventNames, selectedEventName];
      setParentState('localEvents', {
        eventIDs: newLocalEventIDs,
        eventNames: newLocalEventNames,
      });
    } else {
      const newLocalEventIDs = [];
      const newLocalEventNames = [];
      value.eventIDs.forEach((eventID, index) => {
        if (eventID !== selectedEventID) {
          newLocalEventIDs.push(eventID);
          newLocalEventNames.push(value.eventNames[index]);
        }
      });
      setParentState('localEvents', {
        eventIDs: newLocalEventIDs,
        eventNames: newLocalEventNames,
      });
    }
  }

  render() {
    const { value } = this.props;
    const { eventIDsAndNames } = this.state;
    return (
      <div className="settingsModalCard" id="settingsModalCardEvents">
        <TableContainer style={{ boxShadow: 'none', border: '1px #00000010 solid', borderBottom: 'none' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ color: 'rgba(0,0,0,0.54)' }}>Event</TableCell>
                <TableCell align="right" style={{ color: 'rgba(0,0,0,0.54)' }}>Included</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventIDsAndNames.map((event) => (
                <TableRow key={event[1]}>
                  <TableCell component="th" scope="row">
                    {event[1]}
                  </TableCell>
                  <TableCell align="right">
                    <Checkbox
                      checked={value.eventIDs.includes(event[0])}
                      onClick={() => this.handleEventToggle(event[0], event[1])}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

SettingsModalEvents.propTypes = {
  value: PropTypes.string.isRequired,
  setParentState: PropTypes.func.isRequired,
  analyticsEvents: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  analyticsEvents: state.analytics.analyticsEvents,
});

export default connect(mapStateToProps)(SettingsModalEvents);
