import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as leagueActions from '../../../reducers/leaguesReducer';
import LeagueTimingsForm from './LeagueTimingsForm';

const styles = () => ({
  root: {
    minWidth: 400,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});
class LinkFacilityDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleConfirmFacilityLink = () => {
    const { confirmFacilityLink } = this.props;
    confirmFacilityLink();
    this.setState({ open: false });
  }

  render() {
    const isMobile = window.innerWidth < 768;
    const {
      classes,
    } = this.props;

    const { open } = this.state;

    return (
      <div>
        <Button color="primary" variant="contained" onClick={() => this.handleClickOpen()}>
          Link to a facility
        </Button>
        <Dialog
          open={open}
          fullScreen={isMobile}
          fullWidth
          classes={{ paper: classes.root }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>Link To A Facility</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <DialogContent>
            <LeagueTimingsForm />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary" variant="outlined">
              Close
            </Button>
            <Button onClick={() => this.handleConfirmFacilityLink()} color="primary" variant="contained">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { events } = state;
  const { eventStore, event } = events;
  return {
    eventStore,
    event,
  };
}

const mapDispatchToProps = (dispatch) => ({
  confirmFacilityLink: () => dispatch(leagueActions.confirmFacilityLink()),
});

LinkFacilityDialog.propTypes = {
  classes: PropTypes.shape().isRequired,
  confirmFacilityLink: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withStyles(styles())(LinkFacilityDialog));
