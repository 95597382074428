// @flow wea
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import Moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import Alert from '@material-ui/lab/Alert';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as bookingActions from '../../../reducers/bookingsReducer';
import * as reservationsActions from '../../../reducers/reservationsReducer';
import * as eventsActions from '../../../reducers/eventsReducer';
import CancelledReservation from './CancelledReservation';
import ToggleButtons from '../../../components/ToggleButtons';
import MenuHeading from '../../../components/MenuHeading';
import PartialRefundTable from './partialRefundTable';

const formatAmount = (amount, currencySym = '£') => `${currencySym}${parseFloat(amount).toFixed(2)}`;
class MultipleCancelRefundDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: false,
      ctaText: 'Cancel & Refund',
      initialRefundCalled: false,
    };
    const { selectedRows, cancelReservations } = this.props;
    let { refundOption } = cancelReservations;
    if (selectedRows.length === 1 && (selectedRows[0].paymentStatus === 'UNPAID' || selectedRows[0].paymentStatus === 'NOT_CHARGED')) { refundOption = 'NOREFUND'; }
    if (selectedRows.length === 1) {
      this.initialiseRefundTotal(refundOption, selectedRows[0]);
      if (refundOption === 'FULLREFUND' && selectedRows[0].total && selectedRows[0].total === selectedRows[0].refundedTotal) {
        this.state.buttonDisabled = true;
        this.state.ctaText = 'Already fully refunded';
      }
    }
  }

  componentDidUpdate() {
    const { selectedRows, cancelReservations } = this.props;
    let { refundOption } = cancelReservations;
    if (selectedRows.length === 1 && (selectedRows[0].paymentStatus === 'UNPAID' || selectedRows[0].paymentStatus === 'NOT_CHARGED')) { refundOption = 'NOREFUND'; }
    const { open, initialRefundCalled } = this.state;
    if (open && !initialRefundCalled) {
      this.initialiseRefundTotal(refundOption, selectedRows[0]);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ initialRefundCalled: true });
    }
  }

  handleRequestClose = () => {
    const {
      bookings,
      resetSelectedRows,
      resetCancelledReservationStore,
      singularBooking, event,
      getBooking,
      // deselectAll,
      cancelledReservationsSuccess,
      getEvent,
      eventId,
    } = this.props;
    const { reservation } = bookings;
    let { dates } = bookings;
    if (!dates.fromDate) {
      dates = {
        fromDate: Moment().format('YYYY-MM-DD'),
        toDate: Moment().add(7, 'days').format('YYYY-MM-DD'),
      };
    }
    if (event) {
      resetCancelledReservationStore();
      this.setState({ open: false });
      getEvent(eventId);
      window.location.reload();
    }
    if (!singularBooking && !event) {
      resetSelectedRows();
    } else if (singularBooking) {
      getBooking(reservation.id);
      resetSelectedRows();
    }
    resetCancelledReservationStore();
    if (cancelledReservationsSuccess === true) {
      this.setState({ open: false });
    }
    if (!event) {
      resetSelectedRows();
      this.setState({ open: false });
      // deselectAll();
    }

    const location = window.location.href;
    const path = location.split('/');
    if (path[path.length - 1] !== 'bookings') {
      window.history.back();
    }
  };

  performAction = () => {
    this.setState({ buttonDisabled: true });
    const {
      requestCancel,
    } = this.props;
    requestCancel();
  }

  initialiseRefundTotal = (refundOption, res = null) => {
    const { updateCancelReservationStore } = this.props;
    if (refundOption === 'FULLREFUND') {
      const { refundedTotal, total, totalExTax } = res;
      const totalRefundAmount = total - refundedTotal;
      let totalRefundAmountExTax = 0;
      if (total > 0 && totalRefundAmount > 0) {
        totalRefundAmountExTax = totalRefundAmount * (totalExTax / total);
      }
      updateCancelReservationStore({
        refundOption,
        cancelAllocations: [],
        totalRefundAmount,
        totalRefundAmountExTax,
      });
    } else if (refundOption === 'PARTIALREFUND') {
      const { allocations } = res;
      const newAllocations = allocations.map((al) => {
        const thisAllocation = al;
        thisAllocation.startTime = al.startTime || res.startTime;
        thisAllocation.endTime = al.endTime || res.endTime;
        thisAllocation.refundAmount = null;
        thisAllocation.cancelSelected = false;
        return thisAllocation;
      });
      updateCancelReservationStore({
        refundOption,
        cancelAllocations: newAllocations,
        totalRefundAmount: 0,
        totalRefundAmountExTax: 0,
      });
    } else {
      updateCancelReservationStore({
        refundOption,
        cancelAllocations: [],
        totalRefundAmount: 0,
        totalRefundAmountExTax: 0,
      });
    }
  }

  handleToggle = (key, value) => {
    const { selectedRows } = this.props;
    if (selectedRows.length === 1 && key === 'refundOption') {
      this.initialiseRefundTotal(value, selectedRows[0]);
    } else {
      const { updateCancelReservationStore } = this.props;
      updateCancelReservationStore({ [key]: value });
    }
    this.ctaTextChange(key, value);
  };

  handleCancellationReason = (event) => {
    const { updateCancelReservationStore } = this.props;
    updateCancelReservationStore({ cancellationReason: event.target.value });
    if (event.target.value !== 'Other') { updateCancelReservationStore({ cancellationReasonText: null }); }
  }

  handleCancellationReasonText = (event) => {
    const { updateCancelReservationStore } = this.props;
    updateCancelReservationStore({ cancellationReasonText: event.target.value });
  }

  ctaTextChange = (key, value) => {
    const { cancelReservations } = this.props;
    let { refundOption, cancelOption } = cancelReservations;
    if (key === 'refundOption') { refundOption = value; }
    if (key === 'cancelOption') { cancelOption = value; }
    if (refundOption !== 'PARTIALREFUND') {
      if (refundOption !== 'NOREFUND' && cancelOption) {
        this.setState({ ctaText: 'Cancel & Refund', buttonDisabled: false });
      } else if (refundOption !== 'NOREFUND' && !cancelOption) {
        this.setState({ ctaText: 'Refund without cancelling', buttonDisabled: false });
      } else if (refundOption === 'NOREFUND' && cancelOption) {
        this.setState({ ctaText: 'Cancel without refunding', buttonDisabled: false });
      } else {
        this.setState({ ctaText: 'Not possible', buttonDisabled: true });
      }
    } else {
      this.setState({ ctaText: 'Cancel and/or Refund', buttonDisabled: false });
    }
  }

  subscriptionNotPermittedBlock = (errorMessage) => (
    <>
      <DialogContent>
        <DialogContentText>
          <div>{errorMessage}</div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => this.handleRequestClose()} color="secondary">
          Close
        </Button>
      </DialogActions>
    </>
  );

  render() {
    const {
      buttonTitle, dialogTitle, multiple, currencySym, selectedRows,
    } = this.props;
    const isMobile = window.innerWidth < 768;
    const { reservation, cancelReservations, updateCancelReservationStore } = this.props;
    const { buttonDisabled, open, ctaText } = this.state;
    const {
      sendEmail, refundOption, cancelOption,
      totalRefundAmount, totalRefundAmountExTax, cancellationReason, cancellationReasonText,
    } = cancelReservations;
    const { cancelledReservationsSuccess, cancelledReservations } = reservation;
    let alreadyRefunded = 0;
    let fullyRefunded = 0;
    if (selectedRows.length === 1) {
      alreadyRefunded = selectedRows[0].refundedTotal;
      fullyRefunded = Math.abs(selectedRows[0].refundedTotal - selectedRows[0].total) < 0.005;
    }

    const linkedToInvoice = selectedRows?.some((el) => ((el.invoices?.length > 0
      && el.invoices?.filter((invoice) => invoice?.status !== 'VOID' && invoice?.status !== 'PAID').length > 0)) || (el.invoiceUnpaid || el.invoicePending));

    let reservationsOnly = true;
    let paidStripeSubscription = false;
    if (selectedRows.some((el) => el.type === 'SUBSCRIPTION')) {
      reservationsOnly = false;
      if (selectedRows.length === 1
        && selectedRows[0].successfulOrder
        && selectedRows[0].successfulOrder.status === 'CREATED') {
        paidStripeSubscription = true;
      }
    }

    const refundOptionsData = [{
      buttonTitle: 'Full refund',
      buttonValue: 'FULLREFUND',
    },
    {
      buttonTitle: 'Partial refund',
      buttonValue: 'PARTIALREFUND',
    },
    {
      buttonTitle: 'No refund',
      buttonValue: 'NOREFUND',
    }];

    if (multiple) { refundOptionsData.splice(1, 1); }

    const cancelOptionsData = [{
      buttonTitle: 'YES',
      buttonValue: true,
    },
    {
      buttonTitle: 'NO',
      buttonValue: false,
    }];

    const sendEmailCheckbox = (
      <div style={{ display: 'flex' }}>
        <Checkbox
          style={{ paddingTop: 0 }}
          checked={sendEmail}
          onClick={() => updateCancelReservationStore({ sendEmail: !sendEmail })}
          disableRipple
        />
        <DialogContentText>
          Send the booker an email to notify them of the cancellation and/or refund.
        </DialogContentText>
      </div>
    );

    const cancellationReasonOptionsData = [{
      buttonTitle: 'None',
      buttonValue: null,
    },
    {
      buttonTitle: 'Adverse weather conditions',
      buttonValue: 'Adverse weather conditions',
    },
    {
      buttonTitle: 'Administrative error',
      buttonValue: 'Administrative error',
    },
    {
      buttonTitle: 'No longer available',
      buttonValue: 'No longer available',
    },
    {
      buttonTitle: 'Rearrangement',
      buttonValue: 'Rearrangement',
    },
    {
      buttonTitle: 'Lockdown',
      buttonValue: 'Lockdown',
    },
    {
      buttonTitle: 'Other',
      buttonValue: 'Other',
    }];

    const cancellationReasonDropDown = (
      <div style={{ display: 'flex', alignItems: 'center', 'flex-direction': isMobile ? 'column' : 'row' }}>
        <Typography style={{ 'padding-right': '10px' }} noWrap>Cancellation Reason:</Typography>
        <FormControl variant="outlined">
          <Select
            displayEmpty
            value={cancellationReason}
            onChange={(event) => this.handleCancellationReason(event)}
            name="Reason for Cancellation"
          >
            {cancellationReasonOptionsData.map((reason) => (
              <MenuItem value={reason.buttonValue}>{reason.buttonTitle}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <ConditionallyVisible condition={cancellationReason === 'Other'}>
          <div style={{ width: '5px' }} />
          <TextField
            id="cancellationReasonText"
            type="text"
            variant="outlined"
            inputProps={{ 'max-width': '100px' }}
            value={cancellationReasonText}
            onChange={(event) => this.handleCancellationReasonText(event)}
          />
        </ConditionallyVisible>
      </div>
    );

    return (
      <div>
        <Button
          id="manager-bookings-cancel-refund"
          variant="contained"
          style={{
            backgroundColor: '#AC372F',
            color: '#fff',
            width: '100%',
          }}
          onClick={() => this.setState({ open: true })}
        >
          {buttonTitle}
        </Button>
        <Dialog open={open || cancelledReservationsSuccess} onClose={() => this.handleRequestClose()} fullWidth maxWidth="lg" style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <ConditionallyVisible condition={cancelledReservationsSuccess === null}>
            <AlternativelyVisible condition={reservationsOnly}>
              <>
                <DialogContent>
                  <ConditionallyVisible condition={!(selectedRows.length === 1 && (selectedRows[0].paymentStatus === 'UNPAID' || selectedRows[0].paymentStatus === 'NOT_CHARGED'))}>
                    <MenuHeading title={`Do you want to issue a refund for ${multiple ? 'these bookings' : 'this booking'}?`} />
                    <ToggleButtons
                      buttonsData={refundOptionsData}
                      changeOption={(option) => this.handleToggle('refundOption', option)}
                      value={refundOption}
                    />
                  </ConditionallyVisible>
                  {!linkedToInvoice && (
                    <>
                      <ConditionallyVisible condition={refundOption !== 'PARTIALREFUND'}>
                        <MenuHeading title={`Do you want to cancel ${multiple ? 'these bookings' : 'this booking'}?`} />
                        <ToggleButtons
                          buttonsData={cancelOptionsData}
                          changeOption={(option) => this.handleToggle('cancelOption', option)}
                          value={cancelOption}
                        />
                      </ConditionallyVisible>
                      <ConditionallyVisible condition={refundOption === 'PARTIALREFUND' && !multiple}>
                        <PartialRefundTable
                          currencySym={currencySym}
                          fullyRefunded={fullyRefunded}
                        />
                      </ConditionallyVisible>
                      <br />
                      <br />
                      {sendEmailCheckbox}
                      {cancellationReasonDropDown}
                      <ConditionallyVisible condition={selectedRows.length === 1}>
                        <MenuHeading title={`Total refund amount: ${formatAmount(totalRefundAmount, currencySym)} (${formatAmount(totalRefundAmountExTax, currencySym)} + ${formatAmount(totalRefundAmount - totalRefundAmountExTax, currencySym)} tax)`} />
                        <ConditionallyVisible condition={alreadyRefunded > 0}>
                          <DialogContentText>
                            (Already refunded:
                            {' '}
                            {formatAmount(alreadyRefunded, currencySym)}
                            )
                          </DialogContentText>
                        </ConditionallyVisible>
                      </ConditionallyVisible>
                    </>
                  )}
                </DialogContent>

                {selectedRows.some((row) => row.status === 'PENDING') && (
                <div style={{ padding: '2rem' }}>
                  <Alert
                    severity="warning"
                  >
                    You cannot cancel a booking that is processing payment.
                  </Alert>
                </div>
                )}

                {linkedToInvoice && (
                <div style={{ padding: '2rem' }}>
                  <Alert
                    severity="warning"
                  >
                    This booking is currently part of an invoice.
                    If you wish to cancel the booking and remove it from the invoice,
                    you must first void the invoice,
                    cancel the booking,
                    and then remake the invoice with only the required bookings on it.
                  </Alert>
                </div>
                )}
                {(selectedRows.length === 1 && totalRefundAmount > selectedRows[0].total) && (
                <div style={{ padding: '2rem' }}>
                  <Alert
                    severity="warning"
                  >
                    You cannot refund more than the reservation total:
                    {' '}
                    {formatAmount(selectedRows[0].total, currencySym)}
                  </Alert>
                </div>
                )}
                <DialogActions>
                  <Button variant="outlined" onClick={() => this.handleRequestClose()} color="secondary">
                    Go back
                  </Button>

                  {/* Booking has an invoice */}
                  {!linkedToInvoice && !selectedRows.some((row) => row.status === 'PENDING') && (
                    <>
                      <Button disabled={buttonDisabled || (selectedRows.length === 1 && totalRefundAmount > selectedRows[0].total)} variant="contained" onClick={() => this.performAction(true)} color="primary">
                        {ctaText}
                      </Button>
                    </>
                  )}
                </DialogActions>

              </>
              <>
                <AlternativelyVisible condition={selectedRows.length > 1}>
                  <>
                    {this.subscriptionNotPermittedBlock('Subscriptions must be cancelled individually. Please select a single subscription reservation to cancel.')}
                  </>
                  <>
                    <AlternativelyVisible condition={selectedRows && selectedRows.length > 0 && selectedRows[0]?.paymentStatusText === 'Stripe payment scheduled'}>
                      <>
                        {this.subscriptionNotPermittedBlock('Payment hasn\'t been taken for this subscription booking yet. To cancel payment, please do so from the subscriptions tab on the left.')}
                      </>
                      <>
                        <ConditionallyVisible condition={
                          (selectedRows && selectedRows.length > 0
                             && selectedRows[0].successfulOrder && !linkedToInvoice)
}
                        >
                          <>
                            <AlternativelyVisible condition={paidStripeSubscription}>
                              <>
                                <DialogContent>
                                  <ConditionallyVisible condition={!(selectedRows.length === 1 && (selectedRows[0].paymentStatus === 'UNPAID' || selectedRows[0].paymentStatus === 'NOT_CHARGED'))}>
                                    <MenuHeading title={`Do you want to issue a refund for ${multiple ? 'these bookings' : 'this booking'}?`} />
                                    <ToggleButtons
                                      buttonsData={refundOptionsData}
                                      changeOption={(option) => this.handleToggle('refundOption', option)}
                                      value={refundOption}
                                    />
                                  </ConditionallyVisible>
                                  <ConditionallyVisible condition={refundOption !== 'PARTIALREFUND'}>
                                    <MenuHeading title={`Do you want to cancel ${multiple ? 'these bookings' : 'this booking'}?`} />
                                    <ToggleButtons
                                      buttonsData={cancelOptionsData}
                                      changeOption={(option) => this.handleToggle('cancelOption', option)}
                                      value={cancelOption}
                                    />
                                  </ConditionallyVisible>
                                  <ConditionallyVisible condition={refundOption === 'PARTIALREFUND' && !multiple}>
                                    <PartialRefundTable
                                      currencySym={currencySym}
                                      fullyRefunded={fullyRefunded}
                                    />
                                  </ConditionallyVisible>
                                  <br />
                                  <br />
                                  {sendEmailCheckbox}
                                  {cancellationReasonDropDown}
                                  <ConditionallyVisible condition={selectedRows.length === 1}>
                                    <MenuHeading title={`Total refund amount: ${formatAmount(totalRefundAmount, currencySym)} (${formatAmount(totalRefundAmountExTax, currencySym)} + ${formatAmount(totalRefundAmount - totalRefundAmountExTax, currencySym)} tax)`} />
                                    <ConditionallyVisible condition={alreadyRefunded > 0}>
                                      <DialogContentText>
                                        (Already refunded:
                                        {' '}
                                        {formatAmount(alreadyRefunded, currencySym)}
                                        )
                                      </DialogContentText>
                                    </ConditionallyVisible>
                                  </ConditionallyVisible>
                                </DialogContent>
                                <DialogActions>
                                  <Button variant="outlined" onClick={() => this.handleRequestClose()} color="secondary">
                                    Go back
                                  </Button>

                                  <Button disabled={buttonDisabled} variant="contained" onClick={() => this.performAction(true)} color="primary">
                                    {ctaText}
                                  </Button>

                                </DialogActions>
                              </>
                              <>
                                {this.subscriptionNotPermittedBlock('Refund not possible for this booking. Please contact info@pitchbooking.com for help.')}
                              </>
                            </AlternativelyVisible>
                            {' '}

                          </>
                        </ConditionallyVisible>
                        <ConditionallyVisible condition={linkedToInvoice}>
                          <div style={{ padding: '2rem' }}>
                            <Alert
                              severity="warning"
                            >
                              This booking is currently part of an invoice.
                              If you wish to cancel the booking and remove it from the invoice,
                              you must first void the invoice,
                              cancel the booking,
                              and then remake the invoice with only the required bookings on it.
                            </Alert>
                          </div>
                        </ConditionallyVisible>
                      </>
                    </AlternativelyVisible>
                  </>
                </AlternativelyVisible>

              </>
            </AlternativelyVisible>
          </ConditionallyVisible>
          <ConditionallyVisible condition={cancelledReservationsSuccess === false
            && cancelledReservations?.length > 0}
          >
            <DialogContent>
              <DialogContentText>
                <div style={{ color: '#ac372f', padding: '45px' }}>
                  Error! There was a problem cancelling some of the reservations.

                </div>
                Please check the
                {' '}
                <a rel="noopener noreferrer" target="_blank" href="https://dashboard.stripe.com">
                  stripe dashboard
                </a>
                {cancelledReservations?.map((res) => {
                  if (res.status !== 'CANCELLED') {
                    return (
                      <CancelledReservation reservation={res} />
                    );
                  }
                  return <div />;
                })}
                <div>The following reservations have been cancelled and/or refunded.</div>
                {cancelledReservations?.map((res) => {
                  if (res.status === 'CANCELLED') {
                    return (
                      <CancelledReservation reservation={res} />
                    );
                  }
                  return <div />;
                })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={
                () => this.handleRequestClose()
}
                color="secondary"
              >
                Close
              </Button>
            </DialogActions>
          </ConditionallyVisible>
          <ConditionallyVisible
            condition={cancelledReservationsSuccess !== null && cancelledReservationsSuccess}
          >
            <DialogContent>
              <DialogContentText>
                <div style={{ color: '#47FEB4', padding: '45px' }}>
                  Success! The following reservations have been
                  {' '}
                  {refundOption === 'PARTIALREFUND' ? 'partially' : ''}
                  {' '}
                  cancelled and/or refunded:
                </div>
                {cancelledReservations?.map((res) => (<CancelledReservation reservation={res} />))}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={() => this.handleRequestClose()} color="secondary">
                Close
              </Button>
            </DialogActions>
          </ConditionallyVisible>
        </Dialog>
      </div>
    );
  }
}

MultipleCancelRefundDialog.propTypes = {
  // deselectAll: PropTypes.func.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  bookings: PropTypes.shape().isRequired,
  getBooking: PropTypes.func.isRequired,
  resetSelectedRows: PropTypes.func.isRequired,
  resetCancelledReservationStore: PropTypes.func.isRequired,
  updateCancelReservationStore: PropTypes.func.isRequired,
  requestCancel: PropTypes.func.isRequired,
  cancelReservations: PropTypes.shape().isRequired,
  reservation: PropTypes.shape().isRequired,
  eventId: PropTypes.string.isRequired,
  getEvent: PropTypes.func.isRequired,
  cancelledReservationsSuccess: PropTypes.bool.isRequired,
  multiple: PropTypes.bool,
  currencySym: PropTypes.string,
  selectedRows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  singularBooking: PropTypes.bool.isRequired,
  event: PropTypes.bool.isRequired,
};

MultipleCancelRefundDialog.defaultProps = {
  multiple: false,
  currencySym: '£',
};

function mapStateToProps(state) {
  const { reservation, bookings, companies } = state;
  const { cancelReservations } = reservation;
  const { currencySym } = companies.companyInfo;
  return {
    reservation,
    bookings,
    cancelReservations,
    currencySym,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateCancelReservationStore: (keyWithValue) => dispatch(
    reservationsActions.updateCancelReservationStore(keyWithValue),
  ),
  updateReservation: (reservationID, reqBody) => dispatch(
    reservationsActions.updateReservation(reservationID, reqBody),
  ),
  getBooking: (id) => dispatch(
    bookingActions.getBooking(id),
  ),
  getEvent: (id) => dispatch(eventsActions.getEvent(id)),
  resetCancelledReservationStore: () => dispatch(
    reservationsActions.resetCancelledReservationStore(),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MultipleCancelRefundDialog);
