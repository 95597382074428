import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import {
  Radio, RadioGroup, FormLabel, FormControl, FormControlLabel,
} from '@material-ui/core';

const styles = (theme) => ({
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
});

class RadioButtonsGroup extends Component {
  constructor(props) {
    super(props);
    const { paymentStatus } = props;
    this.state = {
      value: paymentStatus,
    };
  }

  handleChange = (event, value) => {
    const { setPaymentStatus } = this.props;
    this.setState({ value });
    setPaymentStatus(value);
  };

  render() {
    const { classes, paymentStatus, isPowerleague } = this.props;
    const { value } = this.state;
    return (
      <FormControl component="fieldset" required>
        <FormLabel component="legend">Payment Status</FormLabel>
        <RadioGroup
          aria-label="payment-status"
          name="payment-status"
          className={classes.group}
          value={value}
          onChange={this.handleChange}
        >
          <ConditionallyVisible condition={paymentStatus === 'STRIPE'}>
            <FormControlLabel value="STRIPE" control={<Radio />} label="Paid - Stripe" />
          </ConditionallyVisible>
          <AlternativelyVisible condition={isPowerleague}>
            <FormControlLabel value="EXT_POS" control={<Radio />} label="Till" />
            <>
              <FormControlLabel value="BANKTRANSFER" control={<Radio />} label="Paid - Bank Transfer" />
              <FormControlLabel value="CASH" control={<Radio />} label="Paid - Cash" />
              <FormControlLabel value="CARD" control={<Radio />} label="Paid - Card" />
            </>
          </AlternativelyVisible>
          <FormControlLabel value="NOT_CHARGED" control={<Radio />} label="Not charged" />
          <FormControlLabel value="UNPAID" control={<Radio />} label="Unpaid" />

        </RadioGroup>
      </FormControl>
    );
  }
}

RadioButtonsGroup.propTypes = {
  classes: PropTypes.shape().isRequired,
  paymentStatus: PropTypes.isRequired,
  setPaymentStatus: PropTypes.func.isRequired,
  isPowerleague: PropTypes.bool,
};

RadioButtonsGroup.defaultProps = {
  isPowerleague: false,
};

export default withStyles(styles)(RadioButtonsGroup);
