/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import { useMobile } from '../../../hooks';

const styles = () => ({
  root: {
    minWidth: 690,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});

const EventAddonDialog = ({
  onAddonsUpdated,
  classes,
  addons,
}) => {
  const { companyAddons } = useSelector((state) => state.addons);
  const isMobile = useMobile();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(addons);
  }, [addons]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
    setSelected([]);
  };

  const handleSelectAddon = (event, id) => {
    if (selected.some((x) => x.id === id)) {
      setSelected((prev) => prev.filter((x) => x.id !== id));
    } else {
      const addon = companyAddons.find((x) => x.id === id);
      setSelected((prev) => [...prev, addon]);
    }
  };

  return (
    <div>
      <Button color="primary" variant="contained" onClick={() => handleClickOpen()}>
        Add an addon
      </Button>
      <Dialog
        open={open}
        fullScreen={isMobile}
        fullWidth
        classes={{ paper: classes.root }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>Add an addon</DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => handleRequestClose()} />
          </DialogActions>
        </div>
        <DialogContent>
          <div>
            {companyAddons.length === 0
              ? (
                <TableRow>
                  <TableCell component="th" scope="row">
                    No Addons available
                  </TableCell>
                </TableRow>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Select</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Visible</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {companyAddons.map((addon) => (
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              checked={selected.some((x) => x.id === addon.id)}
                              onChange={(event) => handleSelectAddon(
                                event.target.checked, addon.id,
                              )}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </TableCell>
                          <TableCell>{addon.name}</TableCell>
                          <TableCell>{addon.type}</TableCell>
                          <TableCell>{addon.quantity === null ? 'Unlimited' : addon.quantity}</TableCell>
                          <TableCell>
                            {addon.visible === true
                              ? (<div className="keys activeKey" />)
                              : (<div className="keys inactiveKey" />)}
                          </TableCell>
                          <TableCell>
                            {addon.status}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary" variant="outlined">
            Close
          </Button>
          <Button
            onClick={() => {
              onAddonsUpdated(selected);
              handleRequestClose();
            }}
            color="primary"
            variant="contained"
            disabled={selected.length === 0}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

EventAddonDialog.propTypes = {
  classes: PropTypes.shape().isRequired,
  addons: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onAddonsUpdated: PropTypes.func.isRequired,
};

export default withStyles(styles())(EventAddonDialog);
