import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import MaterialTable from 'material-table';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import moment from 'moment';
import EditFormDialog from './EditFormDialog';
import { getFormsService } from '../../services/formsServices';
import {
  useToast,
} from '../../hooks';

const Forms = () => {
  const [forms, setForms] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { company } = useSelector((state) => (
    {
      company: state.companies.companyInfo,
    }
  ));

  const toast = useToast();

  const fetchForms = async () => {
    try {
      if (company.id) {
        const res = await getFormsService(company.id);
        setForms(res.data);
        setIsLoading(false);
      }
    } catch (err) {
      toast.trigger({
        type: 'error',
        message: 'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us',
      });
      console.error(err);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    fetchForms();
  }, [company]);

  return (
    <>
      <MaterialTable
        data={forms.sort((a, b) => b.createdAt - a.createdAt).reverse()}
        aria-labelledby="Froms"
        aria-label="Forms"
        title="Forms"
        icons={{
          NextPage: () => <ChevronRight />,
          PreviousPage: () => <ChevronLeft />,
          Search: () => <Search />,
          Export: () => <SaveAlt />,
          ResetSearch: () => <Clear />,
          SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
        }}
        columns={[
          {
            title: 'Name',
            field: 'name',
            render: (row) => (
              <>
                {row.name || 'Not Set'}
                <p style={{ fontSize: '0.7rem' }}>
                  {`ID: ${row.id}`}
                </p>
              </>
            ),
          },
          {
            title: 'Created Date',
            field: 'createdAt',
            render: (row) => moment(row.createdAt).format('DD/MM/YYYY h:mma'),
          },
          {
            title: 'Used By',
            render: (row) => (
              <>
                {row.events?.length || 0}
                {' Events, '}
                {row.facilities?.length || 0}
                {' Facilities, '}
                {row.memberships?.length || 0}
                {' Memberships'}
              </>
            ),
          },
          {
            title: 'Actions',
            render: (row) => {
              if (!row.formData) return (<></>);
              return (
                <>
                  <EditFormDialog
                    values={row.formData}
                    formId={row.id}
                    name={row.name}
                  />
                </>
              );
            },
          },
        ]}
        options={{
          search: false,
          selection: false,
          emptyRowsWhenPaging: false,
          tableLayout: 'auto',
          exportButton: false,
          exportFileName: 'Schedules',
          pageSize: 7,
          showFirstLastPageButtons: false,
          showTitle: false,
          toolbar: false,
          paging: false,
          headerStyle: {
            fontWeight: 'bold',
            zIndex: 0,
          },
        }}
      />
    </>
  );
};

export default Forms;
