import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as pricingActions from '../../reducers/pricingReducer';
import * as companyActions from '../../reducers/companiesReducer';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import PricingGroupDialogActions from './PricingGroupDialogActions';
import MenuHeading from '../../components/MenuHeading';
import PricingAddFacilityDialog from './PricingAddFacilityDialog';
import PricingAddRuleDialogSection from './PricingAddRuleDialogSection';
import PriceModifierTags from './PriceModifierTags';
import EventPicker from '../../components/EventPicker';
import '../../styles/accessRestriction.css';
import 'rc-time-picker/assets/index.css';
import { useMobile } from '../../hooks';

const facilityTag = ((row) => (
  <>
    {row.name}
    <div style={{ color: '#a2a2a2' }}>
      {/* {row.pricePackageId} */}
    </div>
  </>
));

const PricingGroupDialog = () => {
  const isMobile = useMobile();
  const dispatch = useDispatch();

  const {
    priceGroup,
    priceModifiersToDelete,
    priceGroupDialogOpen,
    editingPriceGroup,
  } = useSelector((state) => (state.pricing));
  const companyEvents = useSelector((state) => (state.events.events));

  const {
    endDate, startDate, membership,
    facilities, events, priceModifiers,
  } = priceGroup;
  const title = !editingPriceGroup ? 'New Price Group' : 'Edit Price Group';

  const disabled = (events.length === 0 && facilities.length === 0 && priceModifiers.length === 0);

  const updatePriceGroupStore = (keyWithValue) => dispatch(pricingActions.updatePriceGroupStore(
    keyWithValue,
  ));
  const removePriceModifier = (id) => dispatch(pricingActions.deletePriceModifier(id));
  const removeTempPriceModifier = (priceModifier) => dispatch(
    pricingActions.removeTempPriceModifier(priceModifier),
  );

  const handleClickOpen = () => {
    dispatch(pricingActions.togglePriceGroupDialog());
    dispatch(companyActions.requestCompanyMembershipRetrieval());
  };

  const handleRequestClose = () => {
    if (editingPriceGroup) {
      dispatch(pricingActions.toggledEditingPriceGroup());
    }

    dispatch(pricingActions.resetPriceGroupCreation());
    dispatch(pricingActions.togglePriceGroupDialog());
  };

  const handleRequestToCancel = () => {
    handleRequestClose();
    dispatch(pricingActions.requestPricePackagesRetrieval());
  };

  const createNewPricingGroup = () => {
    dispatch(pricingActions.requestPriceGroupCreation());
    handleRequestClose();
  };

  const handleSave = () => {
    dispatch(pricingActions.editPriceGroup());
    handleRequestClose();
  };

  return (
    <div>
      <div>
        <Button
          onClick={handleClickOpen}
          variant="contained"
          color="primary"
          style={{ width: 250 }}
        >
          {title}
        </Button>
      </div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={priceGroupDialogOpen}
        onBackdropClick={handleRequestToCancel}
        onEscapeKeyDown={handleRequestToCancel}
        fullScreen={isMobile}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>{title}</DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => handleRequestToCancel()} />
          </DialogActions>
        </div>
        <DialogContent>
          <MenuHeading title="Select facilities" />
          {facilities.map((f) => facilityTag(f))}
          <PricingAddFacilityDialog
            editingPriceGroup={editingPriceGroup}
            flagFacilitiesChanged={() => updatePriceGroupStore(
              { changedFacilities: true },
            )}
          />
          <MenuHeading title="Select events" />
          <EventPicker
            events={companyEvents}
            listName="Events"
            onUpdate={(updatedEvents) => updatePriceGroupStore(
              { events: updatedEvents },
            )}
            checkedEvents={events}
          />
          <div style={{ height: '20px' }} />
          <Divider />
          <PricingAddRuleDialogSection
            title="Membership"
            parameter="membership"
            value={membership}
            membership={membership}
            onUpdate={updatePriceGroupStore}
          />
          <Divider />
          <PricingAddRuleDialogSection
            title="Start Date"
            parameter="startDate"
            value={startDate}
            onUpdate={updatePriceGroupStore}
          />
          <Divider />
          <PricingAddRuleDialogSection
            title="End Date"
            parameter="endDate"
            value={endDate}
            onUpdate={updatePriceGroupStore}
          />
          <Divider />
          <MenuHeading title="Price List" />
          <PriceModifierTags
            priceGroup={priceGroup}
            showAdd
            showDelete
            removePriceModifier={removePriceModifier}
            removeTempPriceModifier={removeTempPriceModifier}
            deletedModifierIds={priceModifiersToDelete}
            editingPriceGroup={editingPriceGroup}
          />
        </DialogContent>
        <DialogActions>
          <PricingGroupDialogActions
            priceGroup={priceGroup}
            handleRequestToCancel={handleRequestToCancel}
            handleSave={handleSave}
            createAccessRestriction={createNewPricingGroup}
            disabled={disabled}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PricingGroupDialog;
