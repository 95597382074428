// @flow weak

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import * as actions from '@pitchbooking-dev/pb-shared/lib/reducers/currentUserReducer';

import accessDeniedImg from '../../../images/denied.png';

// eslint-disable-next-line react/prefer-stateless-function
class AccessDeniedDialog extends Component {
  render() {
    const { accessDeniedDialogOpen, closeAccessDeniedDialog } = this.props;
    return (
      <Dialog className="access-denied-dialog" open={accessDeniedDialogOpen} onClose={() => closeAccessDeniedDialog(false)}>
        <img className="denied-img" src={accessDeniedImg} alt="denied" />
        <DialogTitle>
          Access Denied
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            It seems you do not have a Pitchbooking manager account!
          </DialogContentText>
          <DialogContentText>
            We think the page you might be looking for is here
          </DialogContentText>
          <DialogContentText>
            <a
              style={{ textDecoration: 'none' }}
              href="https://pitchbooking.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              pitchbooking.com
            </a>
          </DialogContentText>
          <DialogContentText>
            {`If you’re interested in a manager account
              feel free to `}
            <a
              style={{ textDecoration: 'none' }}
              href="mailto:info@pitchbooking.com?subject=Intrested%20in%20a%20manager%20account&body=Hi, I am intrested in a manager account"
            >
              contact us here
            </a>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
}

AccessDeniedDialog.propTypes = {
  accessDeniedDialogOpen: PropTypes.bool.isRequired,
  closeAccessDeniedDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  accessDeniedDialogOpen: state.currentUser.accessDeniedDialogOpen,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeAccessDeniedDialog: (bool) => (actions.closeAccessDeniedDialog(bool)),

}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AccessDeniedDialog);
