import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Select, Dialog, Button,
  MenuItem, TextField, InputLabel,
  FormControl, DialogTitle, DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import newFacilityTemplates from '@pitchbooking-dev/pb-shared/lib/helpers/newFacilityTemplates';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';
import * as facilityActions from '../../../../reducers/facilitiesReducer';
import GoogleMapsAutoComplete from '../../../../components/GoogleMapsAutoComplete';
import ToggleButtons from '../../../../components/ToggleButtons';
import SiteSelector from '../../../../components/CompanySitePicker';
import SetOpeningTimesDialog from './SetOpeningTimesDialog';
import CreateSiteDialog from '../createSite/CreateSiteDialog';
import { useMobile, usePendingChanges } from '../../../../hooks';

const styles = () => ({
  root: {
    minWidth: 670,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});

const buttonData = [{
  buttonTitle: 'Public booking',
  buttonValue: 'PUBLIC',
},
{
  buttonTitle: 'Internal Bookings',
  buttonValue: 'INTERNAL',
}];

const CreateEventDialog = ({
  classes,
}) => {
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const pending = usePendingChanges();

  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [openingTimesDialogButton, setShowOpeningTimesDialogButton] = useState(false);

  const newFacility = useSelector((state) => state.facilities.newFacility);
  let companySites = useSelector((state) => state.sites.companySites);
  if (pending?.sites) {
    companySites = [...companySites, ...Object.values(pending.sites)];
  }

  const updateNewFacilityStore = (value) => dispatch(
    facilityActions.updateNewFacilityStore(
      value,
    ),
  );
  const createFacility = () => dispatch(
    facilityActions.createFacility(),
  );
  const resetNewFacilityStore = () => dispatch(
    facilityActions.resetNewFacilityStore(),
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
    setShowOpeningTimesDialogButton(false);
    resetNewFacilityStore();
  };

  const handleCreateEvent = () => {
    createFacility();
    handleRequestClose();
  };

  const handleResumesAtToggle = (option) => {
    if (option === 'PUBLIC') {
      updateNewFacilityStore(
        { live: true, visibleStatus: option },
      );
    } else if (option === 'INTERNAL') {
      updateNewFacilityStore(
        { live: false, visibleStatus: option },
      );
    }
  };

  const {
    name,
    amount,
    subUnits,
    unitName,
    location,
    templateName,
    selectedSite,
    visibleStatus,
    schedules,
  } = newFacility;

  let splitType = 'NONE';
  const selectedTemplate = newFacilityTemplates.find((t) => t.templateName === templateName);
  if (selectedTemplate && selectedTemplate.splitType) { splitType = selectedTemplate.splitType; }

  let createButtonDisabled = true;
  if (name !== '' && amount !== null && location !== null && selectedSite !== '' && templateName !== null && schedules !== undefined) {
    createButtonDisabled = false;
  }

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={handleClickOpen}
      >
        Create a facility
      </Button>

      <Dialog
        open={open}
        fullScreen={isMobile}
        fullWidth
        classes={{ paper: classes.root }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>Create a new facility</DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={handleRequestClose} />
          </DialogActions>
        </div>
        <DialogContent>
          <div>
            <TextField
              id="name"
              type="text"
              variant="outlined"
              margin="normal"
              label="Facility name"
              value={name}
              onChange={(event) => updateNewFacilityStore(
                { name: event.target.value },
              )}
            />
            <div style={{ marginTop: 7 }}>
              <GoogleMapsAutoComplete
                onChange={(newValue) => updateNewFacilityStore(
                  {
                    location: newValue.location,
                    locationDescription: newValue.locationDescription,
                    locationChanged: true,
                  },
                )}
              />
            </div>
            <SiteSelector
              selectedSite={selectedSite}
              sites={companySites}
              onChange={(event) => updateNewFacilityStore(
                { selectedSite: event.target.value },
              )}
            />
            <div style={{ marginTop: 14 }}>
              <CreateSiteDialog />
            </div>
            <TextField
              label="Base price"
              id="price"
              type="number"
              variant="outlined"
              margin="normal"
              value={amount}
              onChange={(event) => updateNewFacilityStore(
                { amount: event.target.value },
              )}
            />
            <div style={{ marginTop: 17 }}>
              <FormControl variant="outlined">
                <InputLabel id="facilityType-select-outlined-label">Facility Type</InputLabel>
                <Select
                  displayEmpty
                  value={templateName && templateName}
                  name="templateName"
                  label="Facility Type"
                  onChange={(event) => updateNewFacilityStore(
                    { templateName: event.target.value },
                  )}
                >
                  {newFacilityTemplates.map((type) => (
                    <MenuItem
                      key={type.templateName}
                      value={type.templateName}
                    >
                      {type.templateName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <ConditionallyVisible condition={splitType !== 'NONE'}>
              <div style={{ display: 'grid', marginLeft: 20 }}>
                <TextField
                  label="Number of units"
                  id="units"
                  type="number"
                  margin="normal"
                  variant="outlined"
                  value={subUnits}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      return;
                    }
                    let { value } = e.target;
                    if (value < 0) { value = 0; }
                    updateNewFacilityStore({ subUnits: value });
                  }}
                />
                <TextField
                  label="Unit name"
                  id="unitName"
                  margin="normal"
                  variant="outlined"
                  value={unitName}
                  onChange={(event) => updateNewFacilityStore(
                    { unitName: event.target.value },
                  )}
                />
              </div>
            </ConditionallyVisible>

            <div style={{ margin: '1rem 0', gap: '1rem' }}>
              <Typography variant="subtitle1" style={{ textDecoration: 'underline' }}>Opening Times</Typography>
              <Typography variant="body">
                Default opening times will be used (9:00am - 22:00pm)
                if you do not choose from an existing opening times.
              </Typography>
              <PBInput
                id="selectExistingOpeningTimes"
                type="toggle"
                label="Select from existing opening times"
                value={openingTimesDialogButton}
                onChange={(e) => setShowOpeningTimesDialogButton(e.target.checked)}
              />
              {openingTimesDialogButton && (
              <SetOpeningTimesDialog />
              )}
            </div>

            {!pending && (
              <>
                <ToggleButtons buttonData={buttonData} />
                <ToggleButtons
                  buttonsData={buttonData}
                  changeOption={(option) => handleResumesAtToggle(option)}
                  value={visibleStatus}
                />
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary" variant="outlined">
            Close
          </Button>
          <ConditionallyVisible condition={step === 1}>
            <Button onClick={() => setStep(step - 1)} color="secondary" variant="outlined">
              Back
            </Button>
          </ConditionallyVisible>
          <Button
            onClick={handleCreateEvent}
            color="primary"
            variant="contained"
            disabled={createButtonDisabled}
          >
            CREATE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

CreateEventDialog.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles())(CreateEventDialog);
