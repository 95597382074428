import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { SingleDatePicker } from 'react-dates';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import MaterialTable from 'material-table';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ErrorIcon from '@material-ui/icons/Error';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import { ButtonGroup } from '@material-ui/core';
import moment from 'moment';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import LoadingSection from '../../components/LoadingComponents/LoadingSection';
import * as companyServices from '../../services/companiesServices';
import {
  usePusher,
  useToast,
  usePitchbookingUser,
} from '../../hooks';
import { requestFacilitiesRetrieval } from '../../reducers/facilitiesReducer';
import { AddDeviceDialog } from './components/AddDeviceDialog';
import SendDeviceCommandDialog from './components/SendDeviceCommandDialog';
import EditDeviceNodeDialog from './components/EditDeviceNodeDialog';

const scheduleTypeOptions = [
  {
    value: 'NONE',
    label: 'Off',
  },
  {
    value: 'AUTOMATIC',
    label: 'Automated',
  },
  {
    value: 'TWILIGHT',
    label: 'Twilight',
  },
  // {
  //   value: 'CUSTOM',
  //   label: 'Custom',
  // },
];

const LightingConfiguration = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [lightingControls, setLightingControls] = useState([]);
  const [deviceOverrides, setdeviceOverrides] = useState([]);
  const [focused, setFocused] = useState(false);
  const [overrideDate, setOverrideDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);
  const [cancellingOverride, setCancellingOverride] = useState([]);
  const [disablingAutomation, setDisablingAutomation] = useState([]);

  const { isPitchbookingManager } = usePitchbookingUser();
  const { companyInfo: company } = useSelector((state) => (state.companies));

  const fetchDevices = async () => {
    try {
      const res = await companyServices.getDevices(company.id, { type: 'LIGHTING' });
      setLightingControls(res.data);
      setIsLoading(false);
    } catch (err) {
      toast.trigger({
        type: 'error',
        message: 'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us',
      });
      console.error(err);
    }
  };

  const fetchDeviceOverrides = async () => {
    try {
      const res = await companyServices.getDevicesOverrides(company.id, overrideDate.format('YYYY-MM-DD'));
      setdeviceOverrides(res.data);
      setIsLoading(false);
    } catch (err) {
      toast.trigger({
        type: 'error',
        message: 'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us',
      });
      console.error(err);
    }
  };

  const cancelOverride = async (node) => {
    setCancellingOverride((prev) => [...prev, node.id]);
    try {
      const res = await companyServices.updateDeviceNode(
        company.id,
        node.id,
        {
          overrideExpiry: null,
        },
      );

      if (res.error) throw new Error(res.error);

      await fetchDevices();
      await fetchDeviceOverrides();
      toast.trigger({
        type: 'success',
        message: 'Override successfully cancelled.',
      });
    } catch (err) {
      console.error(err);

      toast.trigger({
        type: 'error',
        message: 'An error occurred while attempting to cancel your override. Please try again.',
      });
    }

    setCancellingOverride((prev) => prev.filter((x) => x !== node.id));
  };

  const disableAutomation = async (node) => {
    setDisablingAutomation((prev) => [...prev, node.id]);
    try {
      const res = await companyServices.updateDeviceNode(
        company.id,
        node.id,
        {
          scheduleType: 'NONE',
        },
      );

      if (res.error) throw new Error(res.error);

      await fetchDevices();
      await fetchDeviceOverrides();

      toast.trigger({
        type: 'success',
        message: 'Automation successfully disabled.',
      });
    } catch (err) {
      console.error(err);

      toast.trigger({
        type: 'error',
        message: 'An error occurred while attempting to disable automation. Please try again.',
      });
    }
    setDisablingAutomation((prev) => prev.filter((x) => x !== node.id));
  };

  useEffect(() => {
    setIsLoading(true);
    if (company.id) {
      dispatch(requestFacilitiesRetrieval());
      fetchDevices();
      fetchDeviceOverrides();
    }
  }, [company.id]);

  useEffect(() => {
    if (company.id) {
      fetchDeviceOverrides();
    }
  }, [company.id, overrideDate]);

  usePusher('nodes', company.id, (data) => {
    setLightingControls((prev) => {
      if (prev.length > 0) {
        return prev.map((x) => {
          if (x.id === data.id) {
            if (data.device) {
              return {
                ...x,
                device: {
                  ...x.device,
                  ...data.device,
                },
              };
            }

            return {
              ...x,
              ...data,
            };
          }
          return x;
        });
      }
      return prev;
    });
  }, [company.id]);

  if (isLoading) {
    return (<LoadingSection loadingText="Loading please wait..." />);
  }

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', gap: '1rem',
    }}
    >
      <div>
        <Typography variant="h6" style={{ marginBottom: '1rem' }}>Lighting Control Configuration</Typography>

        {isPitchbookingManager && (
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <AddDeviceDialog
              type="light"
              onSuccess={fetchDevices}
            />
            <SendDeviceCommandDialog
              devices={lightingControls}
              companyId={company.id}
            />
          </div>
        )}
      </div>

      <AlternativelyVisible condition={lightingControls.length > 0}>
        <>
          {lightingControls.map((node) => (
            <Card
              key={node.id}
            >
              <CardContent>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                >
                  <div>
                    <Typography variant="h5">
                      {node.friendlyName}
                    </Typography>
                    {node.device.isOnline ? (
                      <>
                        <Typography variant="h6" color={node.state === 'OFF' ? 'error' : 'primary'} style={{ fontWeight: 600 }}>
                          {node.state}
                        </Typography>
                        <Typography>{`Scheduling: ${scheduleTypeOptions.find((x) => x.value === node.scheduleType).label}`}</Typography>
                        <Typography>{`Last Health Check: ${moment(node.device.lastHealthCheck).format('LLL')}`}</Typography>
                        {node.overrideExpiry && (
                          <Typography>{`Override Expiration: ${moment(node.overrideExpiry).format('h:mma')}`}</Typography>
                        )}
                      </>
                    ) : (
                      <>
                        <Typography color="error">Device Offline</Typography>
                      </>
                    )}

                  </div>

                  {node.device.actions?.length > 0 && (
                    <div>
                      <ButtonGroup
                        variant="contained"
                        color="secondary"
                        size="small"
                      >
                        {node.device.actions.map((x) => {
                          if (x === 'HEALTH_CHECK') {
                            if (isPitchbookingManager) {
                              return (
                                <Button
                                  onClick={() => companyServices.sendNodeCommand(
                                    company.id,
                                    node.id,
                                  )}
                                >
                                  Health Check
                                </Button>
                              );
                            }

                            return null;
                          }

                          return (
                            <Button
                              onClick={() => companyServices.sendNodeCommand(
                                company.id, node.id, x,
                              )}
                            >
                              {x.replace('_', ' ')}
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                    </div>
                  )}
                </div>
                <div style={{
                  display: 'flex', gap: '1rem', marginTop: '1rem',
                }}
                >
                  <EditDeviceNodeDialog
                    node={node}
                    onSuccess={fetchDevices}
                    scheduleTypeOptions={scheduleTypeOptions}
                  />
                  {node.scheduleType === 'AUTOMATIC' && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => disableAutomation(node)}
                      disabled={disablingAutomation.includes(node.id)}
                    >
                      Disable Automation
                    </Button>
                  )}
                  {node.overrideExpiry && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => cancelOverride(node)}
                      disabled={cancellingOverride.includes(node.id)}
                    >
                      Cancel Override
                    </Button>
                  )}
                </div>
              </CardContent>
            </Card>
          ))}
        </>

        <>
          <Card>
            <CardContent style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
            >
              <ErrorIcon style={{ fontSize: '2rem' }} />
              <Typography variant="subheading">
                You don&apos;t have any lighting controls configured.
                Please contact your account manager for more information.
              </Typography>
            </CardContent>
          </Card>
        </>
      </AlternativelyVisible>
      <ConditionallyVisible condition={lightingControls.length > 0}>
        <div style={{ marginTop: '1rem' }}>
          <div style={{ display: 'flex' }}>
            <Typography variant="h6" style={{ marginBottom: '1rem' }}>Automation Overrides</Typography>
            <div style={{ marginTop: '-5px' }}>
              <SingleDatePicker
                date={overrideDate}
                onDateChange={(date) => setOverrideDate(date)}
                focused={focused}
                style={{ zIndex: 1000 }}
                onFocusChange={({ focused }) => {
                  document.activeElement.blur();
                  setFocused(focused);
                }}
                displayFormat="ddd, DD/MM/YYYY"
                isOutsideRange={() => false}
                numberOfMonths={1}
                showDefaultInputIcon
                noBorder
                id="mobileDatePicker"
              />
            </div>
          </div>
          <div style={{ zIndex: 1 }}>
            <MaterialTable
              data={deviceOverrides.sort((a, b) => b.createdAt - a.createdAt).reverse()}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              title="Automation Overrides"
              icons={{
                NextPage: () => <ChevronRight />,
                PreviousPage: () => <ChevronLeft />,
                Search: () => <Search />,
                Export: () => <SaveAlt />,
                ResetSearch: () => <Clear />,
                SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
              }}
              columns={[
                {
                  title: 'Time',
                  field: 'createdAt',
                  render: (row) => moment(row.createdAt).format('DD/MM/YYYY h:mma'),
                },
                { title: 'State Change', render: (row) => row.stateChange },
                { title: 'Device', render: (row) => row.device_node.friendlyName },
              ]}
              options={{
                search: false,
                selection: false,
                emptyRowsWhenPaging: false,
                tableLayout: 'auto',
                exportButton: false,
                exportFileName: 'Schedules',
                pageSize: 7,
                showFirstLastPageButtons: false,
                showTitle: false,
                toolbar: false,
                paging: false,
                headerStyle: {
                  fontWeight: 'bold',
                  zIndex: 0,
                },
              }}
            />
          </div>
        </div>
      </ConditionallyVisible>
    </div>
  );
};

export default LightingConfiguration;
