import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as leagueActions from '../../../reducers/leaguesReducer';

const styles = () => ({
  root: {
    minWidth: 400,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});

const ScoreInput = (props) => {
  const { userId, updateScore, userScore } = props;
  return (
    <>
      <TextField
        key={userId}
        type="number"
        variant="outlined"
        margin="normal"
        defaultValue={0}
        value={userScore}
        disabled={userScore !== null}
        InputProps={{
          style: { width: 70 },
        }}
        onChange={updateScore}
      />
    </>

  );
};
const GoalScorersDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [showErrorA, setShowErrorA] = useState(false);
  const [showErrorB, setShowErrorB] = useState(false);
  const {
    getTeamPlayers, fixtureId, resetFixture,
    fixture, addGoalScorerToFixture, submitGoalScorers,
  } = props;

  useEffect(() => {
    if (open) {
      getTeamPlayers(fixtureId);
    } else {
      resetFixture();
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const checkAScore = (fixtureId, userId, inputScore, teamId, socre) => {
    if (inputScore > socre) {
      setShowErrorA(true);
    } else {
      setShowErrorA(false);
      addGoalScorerToFixture(fixtureId, userId, inputScore, teamId);
    }
  };

  const checkBScore = (fixtureId, userId, inputScore, teamId, socre) => {
    if (inputScore > socre) {
      setShowErrorB(true);
    } else {
      setShowErrorB(false);
      addGoalScorerToFixture(fixtureId, userId, inputScore, teamId);
    }
  };

  return (
    <div>
      <Button color="primary" variant="contained" onClick={() => handleClickOpen()}>
        Add Goal Scorers
      </Button>
      <Dialog
        open={open}
        fullWidth
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>
            Add Goal Scorers
          </DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => handleRequestClose()} />
          </DialogActions>
        </div>
        <DialogContent>

          <ConditionallyVisible condition={showErrorA || showErrorB}>
            <Alert severity="error">
              Goals scored cannot be greater than final match score!
            </Alert>
          </ConditionallyVisible>

          <h3>
            {`${fixture.teamA.name}: ${fixture.teamAScore} vs ${fixture.teamB.name}: ${fixture.teamBScore}`}
          </h3>
          <h4>
            {fixture.teamA.name}
          </h4>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Player</TableCell>
                <TableCell align="right">Goals Scored</TableCell>
              </TableRow>
              {fixture.teamA && fixture.teamA.users.map((user) => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    {`${user.firstName} ${user.lastName}`}
                  </TableCell>
                  <TableCell align="right">
                    <ScoreInput
                      userId={user.id}
                      userScore={null}
                      updateScore={
                        (event) => {
                          if (event.target.value) {
                            checkAScore(
                              fixture.id,
                              user.id,
                              event.target.value,
                              fixture.teamA.id,
                              fixture.teamAScore,
                            );
                          }
                        }
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <h4>
            {fixture.teamB.name}
          </h4>
          <Table>
            <TableBody>
              <TableCell>Player</TableCell>
              <TableCell align="right">Goals Scored</TableCell>
              {fixture.teamB && fixture.teamB.users.map((user) => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {`${user.firstName} ${user.lastName}`}
                  </TableCell>
                  <TableCell align="right">
                    <ScoreInput
                      userId={user.id}
                      userScore={null}
                      updateScore={
                        (event) => {
                          if (event.target.value && event.target.value > 0) {
                            checkBScore(
                              fixture.id,
                              user.id,
                              event.target.value,
                              fixture.teamA.id,
                              fixture.teamBScore,
                            );
                          }
                        }
}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleRequestClose()} color="secondary" variant="outlined">
            Close
          </Button>
          <Button
            onClick={() => submitGoalScorers() && setOpen(false)}
            color="primary"
            variant="contained"
            disabled={showErrorA || showErrorB}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

GoalScorersDialog.propTypes = {
  getTeamPlayers: PropTypes.func.isRequired,
  submitGoalScorers: PropTypes.func.isRequired,
  addGoalScorerToFixture: PropTypes.func.isRequired,
  resetFixture: PropTypes.func.isRequired,
  fixtureId: PropTypes.string.isRequired,
  fixture: PropTypes.shape().isRequired,
};

ScoreInput.propTypes = {
  updateScore: PropTypes.func.isRequired,
  userScore: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { leagues } = state;
  const { fixture } = leagues;
  return {
    fixture,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getTeamPlayers: (fixtureId) => dispatch(leagueActions.getTeamPlayers(fixtureId)),
  resetFixture: () => dispatch(leagueActions.resetFixture()),
  addGoalScorerToFixture: (fixtureId, userId, totalGoals, teamId) => dispatch(
    leagueActions.addGoalScorerToFixture(fixtureId, userId, totalGoals, teamId),
  ),
  submitGoalScorers: () => dispatch(leagueActions.submitGoalScorers()),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withStyles(styles())(GoalScorersDialog));
