/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Typography, Button, Dialog, DialogTitle, DialogActions, DialogContent,
} from '@material-ui/core';
import * as yup from 'yup';
import { Formik } from 'formik';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { useMutation } from '@tanstack/react-query';
import { useToast } from '../../../../hooks';
import { updateFacilityAmenityService } from '../../../../services/facilitiesServices';

const validationSchema = yup.object({
  startOffset: yup.number().required('Start offset is required'),
  endOffset: yup.number().required('End offset is required'),
  startAnchor: yup.string().oneOf(['START', 'END']).required('Start anchor is required'),
  endAnchor: yup.string().oneOf(['START', 'END']).required('End anchor is required'),
});

const AddForcibleBookingOffsetDialog = ({ companyId, forcibleBooking }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const toast = useToast();

  const mutate = useMutation({
    mutationFn: async (body) => {
      const offsets = forcibleBooking.offsets ? [...forcibleBooking.offsets, body] : [body];
      const { data, error } = await updateFacilityAmenityService(
        companyId,
        forcibleBooking.amenity.id,
        forcibleBooking.coreFacilityId,
        { offsets },
      );
      if (error) throw new Error(error);
      return data;
    },
    onSuccess: () => {
      setDialogOpen(false);
      toast.trigger({
        type: 'success',
        message: 'Offset added successfully.',
      });
    },
    onError: () => {
      toast.trigger({
        type: 'error',
        message: 'An error occurred while adding the offset. Please try again.',
      });
    },
  });

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setDialogOpen(true)}
      >
        Add Offset
      </Button>

      <Dialog
        open={dialogOpen}
      >
        <DialogTitle>Add an offset to a forcible booking</DialogTitle>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            startOffset: '',
            endOffset: '',
            startAnchor: '',
            endAnchor: '',
          }}
          onSubmit={(values) => mutate.mutate(values)}
        >
          {({
            getFieldProps, touched, errors, values, handleSubmit, setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent style={{
                width: '600px', display: 'flex', flexDirection: 'column', gap: '1rem',
              }}
              >
                <PBInput
                  id="startAnchor"
                  label="When should the offset start?"
                  type="select"
                  options={[
                    { value: 'START', label: 'At the beginning of the original booking' },
                    { value: 'END', label: 'At the end of the original booking' },
                  ]}
                  {...getFieldProps('startAnchor')}
                  onChange={(e) => {
                    setFieldValue('startAnchor', e.target.value);
                    setFieldValue('endAnchor', e.target.value);
                  }}
                  error={touched.startAnchor && errors.startAnchor}
                />

                {values.startAnchor === 'START' && (
                  <>
                    <Typography>
                      You&apos;ve selected to start the forcibile booking at the beginning
                      of the original booking. You can easily offset the time by entering a
                      number in minutes below.
                    </Typography>
                    <Typography>
                      For example, if you enter 30 the booking will
                      start 30 minutes after the original booking start time. If you enter
                      -30 the booking will start 30 minutes before the original booking start.
                    </Typography>
                  </>
                )}

                {values.startAnchor === 'END' && (
                <>
                  <Typography>
                    You&apos;ve selected to start the forcibile booking at the end
                    of the original booking. You can easily offset the time by entering a
                    number in minutes below.
                  </Typography>
                  <Typography>
                    For example, if you enter 30 the booking will
                    start 30 minutes after the original booking end time. If you enter
                    -30 the booking will start 30 minutes before the original booking end.
                  </Typography>
                </>
                )}

                <PBInput
                  id="startOffset"
                  label="How many minutes before/after the original start time?"
                  type="number"
                  {...getFieldProps('startOffset')}
                  error={touched.startOffset && errors.startOffset}
                />

                {values.startAnchor === 'START' && (
                  <PBInput
                    id="endAnchor"
                    label="When should the offset end?"
                    type="select"
                    options={[
                      { value: 'START', label: 'At the beginning of the original booking' },
                      { value: 'END', label: 'At the end of the original booking' },
                    ]}
                    {...getFieldProps('endAnchor')}
                    error={touched.endAnchor && errors.endAnchor}
                  />
                )}

                <PBInput
                  id="endOffset"
                  label={
                    values.endAnchor === 'START'
                      ? 'How many minutes before/after the original start time?'
                      : 'How many minutes before/after the original end time?'
                  }
                  type="number"
                  {...getFieldProps('endOffset')}
                  error={touched.endOffset && errors.endOffset}
                />
              </DialogContent>
              <DialogActions>
                <Button type="button" onClick={() => setDialogOpen(false)} color="secondary" variant="contained">
                  Close
                </Button>
                <Button disabled={mutate.isLoading} type="submit" color="primary" variant="contained">
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddForcibleBookingOffsetDialog;
