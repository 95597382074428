import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import '../../../styles/payments.css';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import TableContainer from '@material-ui/core/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import { fetchLeagueFixtures } from '../../../reducers/leaguesReducer';
import ConfirmTeamDialog from './ConfirmTeamDialog';

const TeamWaitList = ({ waitList, league }) => {
  const dispatch = useDispatch();
  const leagueFixtures = useSelector((state) => state.leagues.league.fixtures);

  useEffect(() => {
    dispatch(fetchLeagueFixtures(league.id));
  }, []);

  return (
    <>
      <ConditionallyVisible condition={leagueFixtures.length > 0}>
        <Alert severity="info" style={{ margin: '1rem' }}>
          Teams added/confirmed after fixtures have been autogenerated
          must have their fixtures created manually
        </Alert>
      </ConditionallyVisible>

      <div className="table-section">
        <TableContainer
          style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden' }}
          component={Paper}
        >
          <MaterialTable
            data={waitList}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            title="Attendees"
            icons={{
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              Export: () => <SaveAlt />,
              ResetSearch: () => <Clear />,
              SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
            }}
            columns={[
              {
                title: 'Name',
                render: (row) => (
                  <>
                    <div style={{
                      // display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    >
                      {row.name}
                      <div style={{ color: '#a2a2a2', fontSize: '.7rem' }}>
                        {row?.team_members[0]?.user?.email ? `Captain: ${row?.team_members[0]?.user?.firstName} ${row?.team_members[0]?.user?.lastName} (${row?.team_members[0]?.user?.email})` : ''}
                      </div>
                    </div>
                  </>
                ),

              },
              {
                title: 'Status',
                render: (row) => (
                  <>
                    {row.league_team?.status}
                  </>
                ),

              },
              {
                sorting: false,
                render: (row) => (
                  <ConditionallyVisible condition={row.league_team?.status === 'WAITING' || row.league_team?.status === 'INVITED'}>
                    <ConfirmTeamDialog
                      leagueId={league.id}
                      team={row}
                    />

                  </ConditionallyVisible>
                ),
              },
            ]}
            options={{
              search: true,
              selection: false,
              emptyRowsWhenPaging: false,
              tableLayout: 'auto',
              exportButton: false,
              exportFileName: 'Pitchbooking Attendees',
              pageSize: 50,
              pageSizeOptions: [10, 25, 50, 100],
              showFirstLastPageButtons: false,
              showTitle: false,
              headerStyle: {
                backgroundColor: '#efefef',
                fontWeight: 'bold',
              },
            }}
            localization={{
              toolbar: {
                exportTitle: 'Download',
              },
            }}
          />
        </TableContainer>
      </div>
    </>
  );
};

TeamWaitList.propTypes = {
  waitList: PropTypes.shape().isRequired,
  league: PropTypes.shape().isRequired,
};

export default (TeamWaitList);
