import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    marginTop: theme.spacing(0),
    margin: theme.spacing(2),
  },
}));

export default function CheckboxesGroup(props) {
  const classes = useStyles();
  const { updateSelectedFacilityStore, selectedFacility } = props;
  const {
    allowedStartQuarterZero, allowedStartQuarterFifteen,
    allowedStartQuarterThirty, allowedStartQuarterFortyFive,
  } = selectedFacility;

  const handleChange = (event) => {
    updateSelectedFacilityStore({ [event.target.name]: event.target.checked });
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={allowedStartQuarterZero} onChange={handleChange} name="allowedStartQuarterZero" />}
            label="0 (On the Hour)"
          />
          <FormControlLabel
            control={<Checkbox checked={allowedStartQuarterFifteen} onChange={handleChange} name="allowedStartQuarterFifteen" />}
            label="15 (15 Minutes)"
          />
          <FormControlLabel
            control={<Checkbox checked={allowedStartQuarterThirty} onChange={handleChange} name="allowedStartQuarterThirty" />}
            label="30 (On the Half Hour)"
          />
          <FormControlLabel
            control={<Checkbox checked={allowedStartQuarterFortyFive} onChange={handleChange} name="allowedStartQuarterFortyFive" />}
            label="45 (45 Minutes)"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}

CheckboxesGroup.propTypes = {
  updateSelectedFacilityStore: PropTypes.func.isRequired,
  selectedFacility: PropTypes.func.isRequired,
};
