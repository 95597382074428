import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Button, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
  },
};

const useStyles = makeStyles((theme) => ({
  submitButton: {
    backgroundColor: '#243A55',
    color: theme.palette.common.white,
    width: '50%',
    borderRadius: '25px',
    '&:disabled': {
      backgroundColor: '#B3B1B3',
      color: theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: '#243A55',
      color: theme.palette.common.white,
      opacity: 0.8,
    },
  },
  userErrorLabel: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
  },
}));

const LoginForm = (props) => {
  const {
    isLoading,
    loginError,
    onHandleSubmit,
    userError,
  } = props;
  const classes = useStyles();

  const validationSchema = Yup.object({
    email: Yup.string().email('Email is not valid').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters'),
  });

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onHandleSubmit}
    >
      {({
        dirty,
        errors,
        handleBlur,
        handleChange,
        isValid,
        touched,
        values,
      }) => (
        <Form style={styles.form}>

          <Typography className="login-enter-details" variant="caption">
            Please enter your login details below
          </Typography>

          <PBInput
            id="email"
            type="email"
            name="email"
            label="Email Address"
            value={values?.email}
            onChange={handleChange}
            isError={touched?.email && Boolean(errors?.email)}
            errorMessage={touched?.email && errors?.email}
            onBlur={handleBlur}
            required
          />

          <PBInput
            id="password"
            type="password"
            label="Password"
            name="password"
            value={values?.password}
            onChange={handleChange}
            isError={touched?.password && Boolean(errors?.password)}
            errorMessage={touched?.password && errors?.password}
            onBlur={handleBlur}
            required
            autoComplete="current-password"
          />

          <Typography className={classes.userErrorLabel}>
            {userError}
          </Typography>

          <ConditionallyVisible condition={loginError}>
            <Alert
              id="alert"
              severity="warning"
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '2em',
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              Invalid Email or Password.
            </Alert>
          </ConditionallyVisible>

          <Button
            className={classes.submitButton}
            type="submit"
            variant="contained"
            color="secondary"
            disabled={isLoading || !(isValid && dirty)}
          >
            {isLoading ? <CircularProgress size={24} color="#243b55" /> : 'Login'}
          </Button>

        </Form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  isLoading: PropTypes.bool,
  loginError: PropTypes.bool,
  onHandleSubmit: PropTypes.func.isRequired,
  userError: PropTypes.string,
};

LoginForm.defaultProps = {
  isLoading: false,
  loginError: false,
  userError: '',
};

export default LoginForm;
