/*
  SettingsModalTimeframes represents a toggle button group that
  allows the user to toggle the currently selected timeframe.
  The current choices are:
    1. Yesterday
    2. Today
    3. Last 7 Days
    4. Last 30 Days
    5. Last 90 Days
    6. Custom

  If the user makes changes, they are pushed to parent (Settings Modal)
  state using setParentState (this.props.setParentState).
*/

import React from 'react';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { SettingsModalTimeframesDateRow } from './subcomponents/SettingsModalTimeframesDateRow';
import { SettingsModalTimeframesCustom } from './subcomponents/SettingsModalTimeframesCustom';

export class SettingsModalTimeframes extends React.Component {
  constructor(props) {
    super(props);
    this.handleTimeframePress = this.handleTimeframePress.bind(this);
  }

  /*
    Fetch the value of selection and push to parent state.
  */
  handleTimeframePress(timeframe) {
    const {
      setParentState,
    } = this.props;
    setParentState('localTimeframe', timeframe);
  }

  render() {
    const {
      customSelected, value, setParentState, customValues,
    } = this.props;
    return (
      <div className="settingsModalCard" style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        <SettingsModalTimeframesDateRow
          analyticsEventTimeframe={value}
          handleTimeframePress={this.handleTimeframePress}
          timeframe="Today"
        />
        <br />
        <SettingsModalTimeframesDateRow
          analyticsEventTimeframe={value}
          handleTimeframePress={this.handleTimeframePress}
          timeframe="Yesterday"
        />
        <br />
        <SettingsModalTimeframesDateRow
          analyticsEventTimeframe={value}
          handleTimeframePress={this.handleTimeframePress}
          timeframe="Last 7 Days"
        />
        <br />
        <SettingsModalTimeframesDateRow
          analyticsEventTimeframe={value}
          handleTimeframePress={this.handleTimeframePress}
          timeframe="Last 30 Days"
        />
        <br />
        <SettingsModalTimeframesDateRow
          analyticsEventTimeframe={value}
          handleTimeframePress={this.handleTimeframePress}
          timeframe="Last 90 Days"
        />
        <SettingsModalTimeframesDateRow
          analyticsEventTimeframe={value}
          handleTimeframePress={this.handleTimeframePress}
          timeframe="This Month"
        />
        <br />
        <SettingsModalTimeframesDateRow
          analyticsEventTimeframe={value}
          handleTimeframePress={this.handleTimeframePress}
          timeframe="Custom"
        />
        <ConditionallyVisible condition={customSelected}>
          <SettingsModalTimeframesCustom
            customValues={customValues}
            setAncestorState={setParentState}
          />
        </ConditionallyVisible>
      </div>
    );
  }
}

SettingsModalTimeframes.propTypes = {
  customSelected: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  customValues: PropTypes.string.isRequired,
  setParentState: PropTypes.func.isRequired,
};
