/* eslint-disable react/destructuring-assignment */
import React from 'react';
import moment from 'moment';
import MaterialTable from 'material-table';
import '../../../styles/order.css';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import IdTag from '@pitchbooking-dev/pb-shared/lib/components/IdTag';
import { generateOrderStatusTypeTag } from '../../payments/OrderTable';

const SubscriptionStripeOrdersTable = (props) => {
  const { subscription, stripeCompanyId } = props;
  const { orders } = subscription;
  const stripeLinkBase = `https://dashboard.stripe.com/${stripeCompanyId}/payments/`;
  return (
    <div>
      <ConditionallyVisible condition={orders && orders.length > 0}>
        <br />
        <MaterialTable
          className="table"
          aria-label="simple table"
          data={orders}
          title="Subscription Payments:"
          icons={{
            NextPage: () => <ChevronRight />,
            PreviousPage: () => <ChevronLeft />,
            Search: () => <Search />,
            Export: () => <SaveAlt />,
            ResetSearch: () => <Clear />,
            SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
          }}
          columns={[
            {
              title: 'Payment ID',
              field: 'paymentId',
              render: (row) => (<IdTag title={row.paymentId} url={`${stripeLinkBase}${row.paymentId}`} />),
            },
            {
              title: 'Amount',
              field: 'total',
              render: (row) => `${(row.status === 'REFUNDED') ? '-' : ''}${(row.total / 100).toFixed(2)}`,
            },
            {
              title: 'Paid',
              field: 'paidAt',
              render: (row) => (<div>{moment(row.paidAt).format('DD MMM YY, HH:mm')}</div>),
            },
            {
              title: 'Slot',
              field: 'subscription_order.slot',
              render: (row) => (<div>{moment(row.subscription_order.slot).format('DD MMM YY, HH:mm')}</div>),
              // defaultSort: 'desc',
            },
            {
              title: 'Status',
              field: 'status',
              render: (row) => (generateOrderStatusTypeTag(row.status)),
            },
          ]}
          options={{
            search: false,
            tableLayout: 'auto',
            exportButton: true,
            emptyRowsWhenPaging: false,
            exportFileName: 'Pitchbooking Subscription Stripe Orders',
            pageSize: 25,
            pageSizeOptions: [10, 25, 50, 100],
            showFirstLastPageButtons: false,
            showTitle: true,
            headerStyle: {
              backgroundColor: '#efefef',
              fontWeight: 'bold',
            },
          }}
          localization={{
            toolbar: {
              exportTitle: 'Download',
            },

          }}
        />
      </ConditionallyVisible>
    </div>
  );
};

SubscriptionStripeOrdersTable.propTypes = {
  subscription: PropTypes.shape().isRequired,
  stripeCompanyId: PropTypes.string,
};

SubscriptionStripeOrdersTable.defaultProps = {
  stripeCompanyId: null,
};

export default SubscriptionStripeOrdersTable;
