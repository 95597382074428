import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

const CancelledAllocation = (props) => {
  const { user, allocation } = props;
  if (allocation.type === 'EVENT' || allocation.type === 'SUB_EVENT') {
    return (
      <div className="cancelledCardDetails">
        <div>
          {user.firstName}
          {' '}
          {user.lastName}
        </div>
        <div>{user.email}</div>
        <div>
          {allocation.event.startTime && allocation.event.startTime}
          {' '}
          -
          {' '}
          {allocation.event.endTime && allocation.event.endTime}
        </div>
        <div>{allocation.event.name}</div>
      </div>
    );
  }
  return (
    <div className="cancelledCardDetails">
      <div>
        {user.firstName}
        {' '}
        {user.lastName}
      </div>
      <div>{user.email}</div>
      {allocation.startTime && allocation.endTime && (
      <div>
        {Moment(allocation.startTime).tz(allocation.facility?.timezone).format('LLL')}
        {' '}
        -
        {' '}
        {Moment(allocation.endTime).tz(allocation.facility?.timezone).add(1, 'minutes').format('LT')}
      </div>
      )}
      <div>{allocation.facility?.name}</div>
    </div>
  );
};
const CancelledReservation = (props) => {
  const { reservation } = props;
  const { allocations, user } = reservation;
  const allAllocs = allocations.map((al) => <CancelledAllocation user={user} allocation={al} />);
  return (allAllocs);
};

CancelledReservation.propTypes = {
  reservation: PropTypes.shape().isRequired,
};

CancelledAllocation.propTypes = {
  user: PropTypes.shape().isRequired,
  allocation: PropTypes.shape().isRequired,
};

export default (CancelledReservation);
