/**
 * @fileoverview The CategoryTitleBase
 * component consists of the category
 * title.
 */

import React from 'react';
import PropTypes from 'prop-types';

export function CategoryHeaderBase(props) {
  const { categoryName } = props;
  return (
    <div className="categoryHeader">
      <div className="categoryLabel">
        {categoryName}
      </div>
    </div>
  );
}

CategoryHeaderBase.propTypes = {
  categoryName: PropTypes.string.isRequired,
};
