import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import Button from '@material-ui/core/Button';
import * as schedulesActions from '../../../reducers/schedulesReducer';

const ScheduleGroupDialogActions = ({
  handleSave,
  requestScheduleGroupCreation,
  handleRequestToCancel,
  editingScheduleGroupOpeningTimes,
  disabled,
}) => (
  <>
    <Button onClick={handleRequestToCancel} variant="contained" color="secondary">
      Cancel
    </Button>
    <AlternativelyVisible condition={editingScheduleGroupOpeningTimes}>
      <Button disabled={disabled} onClick={() => handleSave()} variant="contained" color="primary">
        Save
      </Button>
      <Button disabled={disabled} onClick={() => requestScheduleGroupCreation()} variant="contained" color="primary">
        Create
      </Button>
    </AlternativelyVisible>
  </>
);

ScheduleGroupDialogActions.propTypes = {
  editingScheduleGroupOpeningTimes: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  requestScheduleGroupCreation: PropTypes.func.isRequired,
  handleRequestToCancel: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  editingScheduleGroupOpeningTimes: state.schedules.editingScheduleGroupOpeningTimes,
});

const mapDispatchToProps = (dispatch) => ({
  requestScheduleGroupCreation: () => dispatch(
    schedulesActions.requestScheduleGroupCreation(),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleGroupDialogActions);
