import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ErrorIcon from '@material-ui/icons/Error';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';

import LoadingSection from '../../components/LoadingComponents/LoadingSection';
import * as companyServices from '../../services/companiesServices';
import { useToast, usePitchbookingUser } from '../../hooks';
import { AddDeviceDialog } from './components/AddDeviceDialog';
import EditDeviceNodeDialog from './components/EditDeviceNodeDialog';

const TerminalConfiguration = () => {
  const toast = useToast();
  const [terminals, setTerminals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isPitchbookingManager } = usePitchbookingUser();
  const { company } = useSelector((state) => (
    {
      company: state.companies.companyInfo,
    }
  ));

  const fetchDevices = async () => {
    try {
      const res = await companyServices.getDevices(company.id, { type: 'TERMINAL' });
      setTerminals(res.data);
      setIsLoading(false);
    } catch (err) {
      toast.trigger({
        type: 'error',
        message: 'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us',
      });
      console.error(err);
    }
  };

  useEffect(() => {
    if (company.id) {
      setIsLoading(true);
      fetchDevices();
    }
  }, [company.id]);

  if (isLoading) {
    return (<LoadingSection loadingText="Loading please wait..." />);
  }

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', gap: '1rem',
    }}
    >
      <div>
        <Typography variant="h6" style={{ marginBottom: '1rem' }}>Stripe Terminal Configuration</Typography>

        {isPitchbookingManager && (
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <AddDeviceDialog
              type="terminal"
              onSuccess={fetchDevices}
            />
          </div>
        )}
      </div>

      <AlternativelyVisible condition={terminals && terminals.length > 0}>
        <>
          {terminals.map((node) => (
            <Card
              key={node.id}
            >
              <CardContent>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                >
                  <div>
                    <Typography variant="h5">
                      {node.friendlyName}
                    </Typography>
                    <Typography>{`External ID: ${node.nodeIdentifier}`}</Typography>
                  </div>
                </div>
                <div style={{
                  display: 'flex', gap: '1rem', marginTop: '1rem',
                }}
                >
                  <EditDeviceNodeDialog
                    node={node}
                    onSuccess={fetchDevices}
                    scheduleTypeOptions={[]}
                  />
                </div>
              </CardContent>
            </Card>
          ))}
        </>

        <>
          <Card>
            <CardContent style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
            >
              <ErrorIcon style={{ fontSize: '2rem' }} />
              <Typography variant="subheading">
                You don&apos;t have any terminal devices configured.
                Please contact your account manager for more information.
              </Typography>
            </CardContent>
          </Card>
        </>
      </AlternativelyVisible>
    </div>
  );
};

export default TerminalConfiguration;
