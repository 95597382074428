import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { createStripeIntent, fetchStripeReader, updateStripeReader } from '@pitchbooking-dev/pb-shared/lib/services/stripeService';
import {
  Button,
} from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import * as companyServices from '../../services/companiesServices';
import LoadingSection from '../LoadingComponents/LoadingSection';
import { useCompany } from '../../hooks';

const Terminal = ({
  transactionType,
  transactionId,
  userId,
  handleRequestClose,
  amount,
  onSuccess,
  metadata,
}) => {
  const { id: companyId, stripeId: companyStripeId } = useCompany();

  const {
    data: terminals,
    isError: isTerminalsError,
    isLoading: isTerminalsLoading,
  } = useQuery({
    queryKey: ['company', companyId, 'TERMINAL'],
    queryFn: async () => {
      const res = await companyServices.getDevices(companyId, { type: 'TERMINAL' });
      return res.data;
    },
    enabled: !!companyId,
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [intent, setIntent] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [reader, setReader] = useState(null);

  const closeTerminal = async () => {
    if (!isSuccess && reader) {
      updateStripeReader(reader, companyStripeId, 'CANCEL');
    }
    setIsSuccess(false);
    setReader(null);
    setIntent(null);
    setErrorMessage(null);
    handleRequestClose?.();
  };

  const handleStripeIntent = async () => {
    try {
      const { data } = await createStripeIntent(
        companyStripeId,
        transactionType,
        'PAYMENT_INTENT',
        transactionId,
        'card_present',
        userId,
        reader,
        amount,
        metadata,
      );
      setIntent(data);
    } catch (err) {
      console.error(err);
      setErrorMessage('Failed to create payment intent');
    }
  };

  useEffect(() => {
    const pollInterval = 3000; // Poll every 3 seconds
    const maxDuration = 60000; // Max duration of 60 seconds
    let timeoutId;

    const poll = async (remainingTime) => {
      if (remainingTime <= 0) {
        console.log('Timeout');
        clearTimeout(timeoutId);
        setErrorMessage('Reader did not receive payment. Please try again.');
        updateStripeReader(reader, companyStripeId, 'CANCEL');
        return;
      }

      if (reader) {
        try {
          const stripeReader = await fetchStripeReader(reader, companyStripeId);

          if (!stripeReader.data.action && stripeReader.data.status === 'online') {
            throw new Error('Reader is not processing payment');
          }

          if (stripeReader.data && stripeReader.data.status === 'online') {
            setErrorMessage(null);
            if (
              stripeReader.data.action.status === 'succeeded'
              && intent?.id === stripeReader?.data?.action.process_payment_intent?.payment_intent
            ) {
              clearTimeout(timeoutId);
              setIsSuccess(true);
              onSuccess?.();
              return;
            }
            timeoutId = setTimeout(() => poll(remainingTime - pollInterval), pollInterval);
          } else {
            setErrorMessage('Reader is not online. Please check the reader is powered and has internet connection.');
            throw new Error('Reader is not online');
          }
        } catch (err) {
          console.error(err);
          clearTimeout(timeoutId);
        }
      }
    };

    if (companyStripeId) {
      setErrorMessage(null);
      const stripeApikey = process.env.VITE_STRIPE_API_KEY || process.env.REACT_APP_STRIPE_API_KEY;
      loadStripe(stripeApikey,
        { stripeAccount: companyStripeId });

      if (!intent && reader) {
        handleStripeIntent();
      }
      if (intent) {
        poll(maxDuration);
      }
    }

    // TODO: If timeoutId exists, clear the timeout
  }, [companyStripeId, intent, reader]);

  // Clean up if the component is unmounted
  useEffect(() => () => {
    if (!isSuccess && reader) {
      updateStripeReader(reader, companyStripeId, 'CANCEL');
    }
  }, [reader, isSuccess]);

  if (isTerminalsLoading) return <LoadingSection height="auto" loadingText="Loading Terminals" />;
  if (isTerminalsError) return <p>Failed to load terminals</p>;
  if (errorMessage) return <p>{errorMessage}</p>;

  return (
    <div style={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
      {isSuccess && <p>Success! Payment Processed Successfully</p>}

      {!isSuccess && !errorMessage && reader && (
        <LoadingSection
          height="auto"
          loadingText="Processing In Person Payment. Please present reader to the customer."
        />
      )}

      {!isSuccess && !errorMessage && !reader && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <h3 style={{ marginBottom: 0, textAlign: 'left' }}>Select A Terminal</h3>
          <div style={{
            display: 'flex', flexDirection: 'row', gap: '1rem', marginBottom: '2rem',
          }}
          >
            {terminals.map((terminal) => (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setReader(terminal.nodeIdentifier)}
              >
                {terminal.friendlyName}
              </Button>
            ))}
          </div>
        </div>
      )}

      {handleRequestClose && (
        <Button variant="contained" color="secondary" onClick={() => closeTerminal()}>Close</Button>
      )}
    </div>
  );
};

Terminal.propTypes = {
  transactionType: PropTypes.string.isRequired,
  transactionId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  handleRequestClose: PropTypes.func,
  amount: PropTypes.number,
  onSuccess: PropTypes.func,
  metadata: PropTypes.shape({}),
};

Terminal.defaultProps = {
  amount: null,
  onSuccess: null,
  handleRequestClose: null,
  metadata: null,
};

export default Terminal;
