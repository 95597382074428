export function createEPOSNowTransaction() {
  const eposSDK = window.EposNowTillSDK;
  // Register the event listener to publish transaction data between the app and the till
  window.create_transaction = (reservations, orderId) => {
    eposSDK.TransactionPublisher.register(window);
    /* Create Transaction Data */
    const transactionData = new eposSDK.Transaction();
    reservations.forEach((res) => {
      let productId = res.facility.EXTProductId;
      if (res.eventBookingModifier && res.eventBookingModifier.EXTProductId) {
        productId = res.eventBookingModifier.EXTProductId;
      }
      transactionData.Products.push(new eposSDK.Product(productId, 1, res.total));
    });
    transactionData.TransactionDetails.push(new eposSDK.TransactionDetail('PB_Reference', `PB_${orderId}`));
    console.log(JSON.stringify(transactionData));
    /* Publish the transaction data to the till  */
    eposSDK.TransactionPublisher.publish(transactionData);
    console.log(eposSDK.TransactionPublisher);
    console.log(eposSDK);
    eposSDK.submitForm(window);
  };
}
