/* eslint-disable no-case-declarations */
import { destructureWeekendCutoffFormat } from '../helpers';

export const FACILITIES_RETRIEVAL_REQUESTED = 'FACILITIES_RETRIEVAL_REQUESTED';
export const FACILITIES_RETRIEVAL_SUCCEEDED = 'FACILITIES_RETRIEVAL_SUCCEEDED';

export const AMENITIES_RETRIEVAL_REQUESTED = 'AMENITIES_RETRIEVAL_REQUESTED';
export const AMENITIES_RETRIEVAL_SUCCEEDED = 'AMENITIES_RETRIEVAL_SUCCEEDED';

export const CREATE_FACILITY_AMENITIES_REQUESTED = 'CREATE_FACILITY_AMENITIES_REQUESTED';
export const CREATE_FACILITY_AMENITIES_SUCCEEDED = 'CREATE_FACILITY_AMENITIES_SUCCEEDED';
export const REMOVE_FACILITY_AMENITIES_REQUESTED = 'REMOVE_FACILITY_AMENITIES_REQUESTED';

export const FACILITY_RETRIEVAL_REQUESTED = 'FACILITY_RETRIEVAL_REQUESTED';
export const FACILITY_RETRIEVAL_SUCCEEDED = 'FACILITY_RETRIEVAL_SUCCEEDED';

export const FACILITY_UPDATE_REQUESTED = 'FACILITY_UPDATE_REQUESTED';
export const FACILITY_UPDATE_SUCCEEDED = 'FACILITY_UPDATE_SUCCEEDED';
export const FACILITY_UPDATE_FAILED = 'FACILITY_UPDATE_FAILED';

export const CREATE_FACILITY_REQUESTED = 'CREATE_FACILITY_REQUESTED';
export const CREATE_FACILITY_SUCCEEDED = 'CREATE_FACILITY_SUCCEEDED';
export const CREATE_FACILITY_FAILED = 'CREATE_FACILITY_FAILED';
export const UPDATED_NEW_FACILITY_STORE = 'UPDATED_NEW_FACILITY_STORE';
export const ADDED_SELECTED_SECHEDULE_TO_FACILITY = 'ADDED_SELECTED_SECHEDULE_TO_FACILITY';
export const NEW_FACILITY_STORE_RESET = 'NEW_FACILITY_STORE_RESET';

export const CREATE_AMENITY_REQUESTED = 'CREATE_AMENITY_REQUESTED';
export const CREATE_AMENITY_SUCCEEDED = 'CREATE_AMENITY_SUCCEEDED';
export const CREATE_AMENITY_FAILED = 'CREATE_AMENITY_FAILED';
export const UPDATED_NEW_AMENITY_STORE = 'UPDATED_NEW_AMENITY_STORE';
export const NEW_AMENITY_STORE_RESET = 'NEW_AMENITY_STORE_RESET';

export const SUB_FACILITY_UPDATE_REQUESTED = 'SUB_FACILITY_UPDATE_REQUESTED';
export const SUB_FACILITY_UPDATE_SUCCEEDED = 'SUB_FACILITY_UPDATE_SUCCEEDED';
export const SUB_FACILITY_UPDATE_FAILED = 'SUB_FACILITY_UPDATE_FAILED';

export const SELECTED_FACILITY_UPDATE_REQUESTED = 'SELECTED_FACILITY_UPDATE_REQUESTED';
export const SELECTED_FACILITY_UPDATE_SUCCEEDED = 'SELECTED_FACILITY_UPDATE_SUCCEEDED';
export const SELECTED_FACILITY_UPDATE_FAILED = 'SELECTED_FACILITY_UPDATE_FAILED';
export const SELECTED_FACILITY_STORE_RESET = 'SELECTED_FACILITY_STORE_RESET';

export const FACILITY_STATUS_TOGGLED = 'FACILITY_STATUS_TOGGLED';

export const FACILITY_SELECTION_UPDATED = 'FACILITY_SELECTION_UPDATED';
export const SUB_FACILITY_SELECTION_UPDATED = 'SUB_FACILITY_SELECTION_UPDATED';
export const UPDATED_SELECTED_FACILITY_STORE = 'UPDATED_SELECTED_FACILITY_STORE';

const RESET_UPDATE_REQUEST = 'RESET_UPDATE_REQUEST';

export const initialState = {
  companyFacilities: [],
  companyAmenities: [],
  selectedFacility: {
    name: '',
    description: '',
    accessInstructions: '',
    smsAccessInstructions: '',
    sport: [],
    surface: '',
    setting: '',
    size: '',
    allowedStartQuarters: [],
    combineSlots: false,
    definedStartTimes: [],
    weekendCutoff: null,
    maxDaysToBooking: 0,
    minHoursToBooking: 0,
    minDuration: 60,
    weekday: null,
    weekendCutoffTime: null,
    weekendCutoffFlag: false,
    allowedStartQuarterZero: false,
    allowedStartQuarterFifteen: false,
    allowedStartQuarterThirty: false,
    allowedStartQuarterFortyFive: false,
    newTime: null,
    facilityTree: null,
    visibleStatus: 'INTERNAL',
    locationChanged: false,
    forcibleBookings: [],
    relatedBookables: [],
    locationDescription: '',
    attributes: {},
    taxExempt: false,
  },
  newFacility: {
    name: '',
    selectedSite: '',
    amount: null,
    subUnits: null,
    templateName: null,
    visibleStatus: 'INTERNAL',
    schedules: [],
    locationDescription: '',
  },
  facilityChanges: {},
  selectedSubFacility: null,
  createFacilityFailedMessage: '',
  createFacilityLoading: false,
  newAmenity: {
    name: '',
    selectedSite: '',
    amount: null,
  },
  createAmenityFailedMessage: '',
  createAmenityLoading: false,
  updateFacililitySubmitting: false,
  updateFacilitySuccess: false,
};

export const requestFacilitiesRetrieval = () => (
  { type: FACILITIES_RETRIEVAL_REQUESTED }
);

export const requestAmenitiesRetrieval = (facilityId) => (
  { type: AMENITIES_RETRIEVAL_REQUESTED, facilityId }
);

export const succeedAmenitiesRetrieval = (amenities) => (
  { type: AMENITIES_RETRIEVAL_SUCCEEDED, amenities }
);

export const requestFacilityRetrieval = (facilityId) => (
  { type: FACILITY_RETRIEVAL_REQUESTED, facilityId }
);

export const requestFacilityAmenitiesCreation = (facilityAmenities, forcible = false) => (
  { type: CREATE_FACILITY_AMENITIES_REQUESTED, facilityAmenities, forcible }
);

export const requestFacilityAmenitiesRemoval = (facilityAmenities) => (
  { type: REMOVE_FACILITY_AMENITIES_REQUESTED, facilityAmenities }
);

export const toggleFacilityStatus = (facilityId, facilityData) => (
  { type: FACILITY_STATUS_TOGGLED, facilityId, facilityData }
);

export const succeedFacilitiesRetrieval = (facilities) => (
  { type: FACILITIES_RETRIEVAL_SUCCEEDED, facilities }
);

export const requestFacilityUpdate = (facilityId, facilityData) => (
  { type: FACILITY_UPDATE_REQUESTED, facilityId, facilityData }
);
export const requestSelectedFacilityUpdate = (facilityId, facilityData) => (
  { type: SELECTED_FACILITY_UPDATE_REQUESTED, facilityId, facilityData }
);

export const succceedFacilityUpdate = (updatedFacilityResponse) => (
  { type: FACILITY_UPDATE_SUCCEEDED, updatedFacilityResponse }
);

export const requestSubFacilityUpdate = (facilityId, facilityData) => (
  { type: SUB_FACILITY_UPDATE_REQUESTED, facilityId, facilityData }
);

export const succceedSubFacilityUpdate = (updatedSubFacilityResponse) => (
  { type: SUB_FACILITY_UPDATE_SUCCEEDED, updatedSubFacilityResponse }
);

export const failSubFacilityUpdate = () => (
  { type: SUB_FACILITY_UPDATE_FAILED }
);

export const resetSelectedFacilityStore = () => (
  { type: SELECTED_FACILITY_STORE_RESET }
);
export const resetNewFacilityStore = () => (
  { type: NEW_FACILITY_STORE_RESET }
);

export const updateNewAmenityStore = (value) => (
  { type: UPDATED_NEW_AMENITY_STORE, value }
);
export function createAmenity() {
  return { type: CREATE_AMENITY_REQUESTED };
}
export function createAmenitySucceeded(facility) {
  return { type: CREATE_AMENITY_SUCCEEDED, facility };
}
export function createAmenityFailed(error) {
  return { type: CREATE_AMENITY_FAILED, error };
}
export const resetNewAmenityStore = () => (
  { type: NEW_AMENITY_STORE_RESET }
);

export const succceedFacilityStatusUpdate = () => (
  { type: FACILITY_UPDATE_SUCCEEDED }
);

export const failFacilityUpdate = () => (
  { type: FACILITY_UPDATE_FAILED }
);

export const updateSelectedSubFacility = (selectedSubFacility) => (
  { type: SUB_FACILITY_SELECTION_UPDATED, selectedSubFacility }
);

export const updateSelectedFacility = (selectedFacility) => (
  { type: FACILITY_SELECTION_UPDATED, selectedFacility }
);

export const updateSelectedFacilityStore = (selectedFacility) => (
  { type: UPDATED_SELECTED_FACILITY_STORE, selectedFacility }
);
export const updateNewFacilityStore = (value) => (
  { type: UPDATED_NEW_FACILITY_STORE, value }
);

export function createFacility() {
  return { type: CREATE_FACILITY_REQUESTED };
}

export function createFacilitySucceeded(facility) {
  return { type: CREATE_FACILITY_SUCCEEDED, facility };
}
export function createFacilityAmenitiesSucceeded(facility) {
  return { type: CREATE_FACILITY_AMENITIES_SUCCEEDED, facility };
}

export function createFacilityFailed(error) {
  return { type: CREATE_FACILITY_FAILED, error };
}

export function addSelectedScheduleGroupToNewFacility(value) {
  return { type: ADDED_SELECTED_SECHEDULE_TO_FACILITY, value };
}

export function resetUpdateRequest() {
  return { type: RESET_UPDATE_REQUEST };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FACILITIES_RETRIEVAL_SUCCEEDED:
      return { ...state, companyFacilities: action.facilities };
    case AMENITIES_RETRIEVAL_SUCCEEDED:
      return { ...state, companyAmenities: action.amenities };
    case FACILITY_UPDATE_REQUESTED:
      return {
        ...state,
        selectedFacility: action.facility,
        selectedSubFacility: null,
      };
    case FACILITY_SELECTION_UPDATED:
      let deformattedWeekendCutoff;
      let deformattedAllowedStartQuartersZero;
      let deformattedAllowedStartQuartersFifteen;
      let deformattedAllowedStartQuartersThirty;
      let deformattedAllowedStartQuarterFortyFive;

      if (Object.values(action.selectedFacility.allowedStartQuarters).includes(0)) {
        deformattedAllowedStartQuartersZero = true;
      }
      if (Object.values(action.selectedFacility.allowedStartQuarters).includes(15)) {
        deformattedAllowedStartQuartersFifteen = true;
      }
      if (Object.values(action.selectedFacility.allowedStartQuarters).includes(30)) {
        deformattedAllowedStartQuartersThirty = true;
      }
      if (Object.values(action.selectedFacility.allowedStartQuarters).includes(45)) {
        deformattedAllowedStartQuarterFortyFive = true;
      }
      if (action.selectedFacility.weekendCutoff) {
        deformattedWeekendCutoff = destructureWeekendCutoffFormat(
          action.selectedFacility.weekendCutoff,
        );
        return {
          ...state,
          selectedFacility: {
            ...action.selectedFacility,
            weekendCutoffFlag: true,
            weekday: deformattedWeekendCutoff[0],
            weekendCutoffTime: deformattedWeekendCutoff[1],
            allowedStartQuarterZero: deformattedAllowedStartQuartersZero,
            allowedStartQuarterFifteen: deformattedAllowedStartQuartersFifteen,
            allowedStartQuarterThirty: deformattedAllowedStartQuartersThirty,
            allowedStartQuarterFortyFive: deformattedAllowedStartQuarterFortyFive,
          },
          selectedSubFacility: null,
        };
      }
      if (!action.selectedFacility.weekendCutoff) {
        return {
          ...state,
          selectedFacility: {
            ...action.selectedFacility,
            weekendCutoffFlag: false,
            allowedStartQuarterZero: deformattedAllowedStartQuartersZero,
            allowedStartQuarterFifteen: deformattedAllowedStartQuartersFifteen,
            allowedStartQuarterThirty: deformattedAllowedStartQuartersThirty,
            allowedStartQuarterFortyFive: deformattedAllowedStartQuarterFortyFive,
          },
          selectedSubFacility: null,
        };
      }
      return {
        ...state,
        selectedFacility: {
          ...action.selectedFacility,
          allowedStartQuarterZero: deformattedAllowedStartQuartersZero,
          allowedStartQuarterFifteen: deformattedAllowedStartQuartersFifteen,
          allowedStartQuarterThirty: deformattedAllowedStartQuartersThirty,
          allowedStartQuarterFortyFive: deformattedAllowedStartQuarterFortyFive,
        },
        selectedSubFacility: null,
      };
    case SUB_FACILITY_SELECTION_UPDATED:
      return { ...state, selectedSubFacility: action.selectedSubFacility };
    case FACILITY_UPDATE_SUCCEEDED:
      return {
        ...state,
        selectedSubFacility: action.updatedFacilityResponse,
        selectedFacility: { ...initialState.selectedFacility },
        updateFacilitySuccess: true,
        updateFacililitySubmitting: false,
      };
    case UPDATED_SELECTED_FACILITY_STORE:
      return {
        ...state,
        selectedFacility:
          {
            ...state.selectedFacility,
            ...action.selectedFacility,
          },
      };
    case UPDATED_NEW_FACILITY_STORE:
      return {
        ...state,
        newFacility:
          {
            ...state.newFacility,
            ...action.value,
          },
      };
    case CREATE_FACILITY_REQUESTED:
      return {
        ...state,
        createFacilityLoading: true,
      };
    case CREATE_FACILITY_SUCCEEDED:
      return {
        ...state,
        createFacilityLoading: false,
      };
    case CREATE_FACILITY_FAILED:
      return {
        ...state,
        createFacilityLoading: false,
        createFacilityFailedMessage: action.error,
      };
    case ADDED_SELECTED_SECHEDULE_TO_FACILITY:
      return {
        ...state,
        newFacility:
            {
              ...state.newFacility,
              schedules: action.value,
            },
      };
    case SELECTED_FACILITY_STORE_RESET:
      return {
        ...state,
        selectedFacility: { ...initialState.selectedFacility },
        selectedSubFacility: null,
      };
    case NEW_FACILITY_STORE_RESET:
      return {
        ...state,
        newFacility: { ...initialState.newFacility },
      };
    case UPDATED_NEW_AMENITY_STORE:
      return {
        ...state,
        newAmenity:
            {
              ...state.newAmenity,
              ...action.value,
            },
      };
    case CREATE_AMENITY_REQUESTED:
      return {
        ...state,
        createAmenityLoading: true,
      };
    case CREATE_AMENITY_SUCCEEDED:
      return {
        ...state,
        createAmenityLoading: false,
      };
    case CREATE_AMENITY_FAILED:
      return {
        ...state,
        createAmenityLoading: false,
        createAmenityFailedMessage: action.error,
      };
    case NEW_AMENITY_STORE_RESET:
      return {
        ...state,
        newAmenity: { ...initialState.newAmenity },
      };
    case SELECTED_FACILITY_UPDATE_REQUESTED:
      return {
        ...state,
        updateRequestSubmitting: true,
      };
    case RESET_UPDATE_REQUEST: {
      return {
        ...state,
        updateFacilitySuccess: false,
        updateFacililitySubmitting: false,
      };
    }
    default:
      return state;
  }
};
