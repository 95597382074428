import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { connect } from 'react-redux';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import * as accessRestrictionActions from '../../../reducers/accessRestrictionsReducer';
import * as facilitiesActions from '../../../reducers/subscriptionsReducer';
import * as pricingActions from '../../../reducers/pricingReducer';
import * as leagueActions from '../../../reducers/leaguesReducer';
import * as addonActions from '../../../reducers/addonsReducer';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import PBButton from '../../../components/Button';

class DeleteAccessRestrictionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  delete = () => {
    const {
      accessRestrictionId, subscriptionId, priceModifierId, type,
      deleteSubscription, deletePriceModifier, updateAccessRestriction,
      getOptions, leagueId, deleteLeague, addonId, deleteAddon, categoryId, deleteCategory,
    } = this.props;
    const buttonAction = 'DELETE';

    if (type === 'SUBSCRIPTION') {
      deleteSubscription(subscriptionId);
    } else if (type === 'PRICEMODIFIER') {
      deletePriceModifier(priceModifierId);
    } else if (type === 'LEAGUE') {
      deleteLeague(leagueId);
    } else if (type === 'ADDON') {
      deleteAddon(addonId);
    } else if (type === 'CATEGORY') {
      deleteCategory(categoryId);
    } else {
      updateAccessRestriction(accessRestrictionId, buttonAction, getOptions);
    }

    this.handleRequestClose();
  }

  render() {
    const { open } = this.state;
    const { name, bodyTag, buttonType } = this.props;
    const isMobile = window.innerWidth < 768;
    return (
      <div>
        <AlternativelyVisible condition={buttonType === 'text'}>
          <>
            <PBButton
              onClick={() => this.handleClickOpen()}
              color="secondary"
              buttonRole="editAccessRestrictions"
              variant="contained"
            >
              Delete
            </PBButton>
          </>
          <>
            <PBButton
              // className={classes.button}
              onClick={this.handleClickOpen}
            >
              <RemoveCircleOutlineIcon />
            </PBButton>
          </>
        </AlternativelyVisible>
        <Dialog open={open} fullScreen={isMobile}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>{`Delete ${name}`}</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <DialogContent>
            <DialogContentText>
              {`Are you sure you wish to delete this ${bodyTag || 'special access period'}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <PBButton onClick={this.handleRequestClose} color="primary">
              Cancel
            </PBButton>
            <PBButton id="manager-delete-access-restriction" onClick={this.delete} color="secondary" autoFocus>
              Delete
            </PBButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DeleteAccessRestrictionDialog.propTypes = {
  buttonType: PropTypes.string,
  type: PropTypes.string.isRequired,
  bodyTag: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  getOptions: PropTypes.shape().isRequired,
  accessRestrictionId: PropTypes.string,
  subscriptionId: PropTypes.string,
  priceModifierId: PropTypes.string,
  leagueId: PropTypes.string,
  categoryId: PropTypes.string,
  deleteSubscription: PropTypes.func,
  deletePriceModifier: PropTypes.func,
  updateAccessRestriction: PropTypes.func,
  deleteLeague: PropTypes.func,
  addonId: PropTypes.string,
  deleteAddon: PropTypes.func,
  deleteCategory: PropTypes.func,
};
DeleteAccessRestrictionDialog.defaultProps = {
  buttonType: 'text',
  accessRestrictionId: null,
  subscriptionId: null,
  priceModifierId: null,
  leagueId: null,
  deleteSubscription: null,
  deleteCategory: null,
  deletePriceModifier: null,
  updateAccessRestriction: null,
  deleteLeague: null,
  addonId: null,
  deleteAddon: null,
  categoryId: null,
};

function mapStateToProps(state) {
  const { facilities } = state;
  return {
    facilities,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateAccessRestriction: (accessRestrictionId, buttonAction, getOptions) => dispatch(
    accessRestrictionActions.updateAccessRestriction(accessRestrictionId, buttonAction, getOptions),
  ),
  deleteSubscription: (subscriptionId) => dispatch(
    facilitiesActions.deleteSubscription(subscriptionId),
  ),
  deleteLeague: (leagueId) => dispatch(
    leagueActions.deleteLeague(leagueId),
  ),
  deletePriceModifier: (priceModifierId) => dispatch(
    pricingActions.deletePriceModifier(priceModifierId),
  ),
  deleteAddon: (addonId) => dispatch(
    addonActions.deleteAddon(addonId),
  ),
  deleteCategory: (categoryId) => dispatch(
    addonActions.deleteCategory(categoryId),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccessRestrictionDialog);
