import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export const OverlayLoader = () => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 999,
    pointerEvents: 'auto',
  }}
  >
    <CircularProgress />
  </div>
);
