import moment from 'moment';

/*
  ANALYTICS_<ATTRIBUTE>_SET action types correspond to actions that
  are dispatched when the user changes settings within the analytics.
  These actions DIRECTLY lead to UI changes.
*/
export const ANALYTICS_BOOKINGS_TYPE_SET = 'ANALYTICS_BOOKINGS_TYPE_SET';
export const ANALYTICS_EVENT_BOOKINGS_TYPE_SET = 'ANALYTICS_EVENT_BOOKINGS_TYPE_SET';
export const ANALYTICS_FACILITIES_SET = 'ANALYTICS_FACILITIES_SET';
export const ANALYTICS_EVENTS_SET = 'ANALYTICS_EVENTS_SET';
export const ANALYTICS_TIMEFRAME_SET = 'ANALYTICS_TIMEFRAME_SET';
export const ANALYTICS_TIMEFRAME_CUSTOM_SET = 'ANALYTICS_TIMEFRAME_CUSTOM_SET';
export const ANALYTICS_EVENT_TIMEFRAME_SET = 'ANALYTICS_EVENT_TIMEFRAME_SET';
export const ANALYTICS_EVENT_TIMEFRAME_CUSTOM_SET = 'ANALYTICS_EVENT_TIMEFRAME_CUSTOM_SET';

/*
  ANALYTICS_FACILITIES_<STATUS> action types correspond to actions that
  are dispatched upon INITIAL index mount. The result of these actions is
  that ALL facilities corresponding to the authenticated manager are fetched.
*/
export const ANALYTICS_FACILITIES_RETRIEVED = 'ANALYTICS_FACILITIES_RETRIEVED';
export const ANALYTICS_FACILITIES_UPDATED = 'ANALYTICS_FACILITIES_UPDATED';

export const ANALYTICS_EVENTS_RETRIEVED = 'ANALYTICS_EVENTS_RETRIEVED';
export const ANALYTICS_EVENTS_UPDATED = 'ANALYTICS_EVENTS_UPDATED';

/*
  ANALYTICS_BOOKINGS_DATA_<STATUS> action types correspond to actions that
  are dispatched upon INITIAL index mount and AFTER analytics attributes (such
  as timeframe, etc.) are changed. The result of these actions is that bookings
  data corresponding to current analytics attribute settings are fetched.
*/
export const RETRIEVE_ANALYTICS_BOOKINGS = 'RETRIEVE_ANALYTICS_BOOKINGS';
export const ANALYTICS_BOOKINGS_UPDATED = 'ANALYTICS_BOOKINGS_UPDATED';
export const RETRIEVE_ANALYTICS_FAILED = 'RETRIEVE_ANALYTICS_FAILED';

export const RETRIEVE_EVENT_ANALYTICS_BOOKINGS = 'RETRIEVE_EVENT_ANALYTICS_BOOKINGS';
export const ANALYTICS_EVENT_BOOKINGS_UPDATED = 'ANALYTICS_EVENT_BOOKINGS_UPDATED';
export const RETRIEVE_EVENT_ANALYTICS_FAILED = 'RETRIEVE_EVENT_ANALYTICS_FAILED';

export const ANALYTICS_ALERT_CLOSED = 'ANALYTICS_ALERT_CLOSED';
export const ANALYTICS_EVENT_ALERT_CLOSED = 'ANALYTICS_EVENT_ALERT_CLOSED';

export const initialState = {
  analyticsBookings: {
    numberOfBookings: 0,
    numberOfBookingsPercentChange: 0,
    totalRevenue: 0,
    totalRevenuePercentChange: 0,
    bookings: [{
      id: '',
      notes: '',
      status: '',
      paymentStatus: '',
      sport: '',
      companyId: '',
      userId: '',
      paymentId: '',
      total: 0,
      createdAt: '',
      updatedAt: '',
      orderId: '',
      allocations: [{
        facility: {
          name: '',
          sport: '',
        },
      }],
      user: {},
    }],
    xAxisBookings: [],
    xAxisRevenue: [],
    xAxisRevenueByFacility: [],
    xAxisUtilisation: [],
    yAxisBookings: [],
    yAxisRevenue: [],
    yAxisRevenueByFacility: [],
    yAxisUtilisation: [],
    lastUpdate: null,
  },
  analyticsEventBookings: {
    numberOfBookings: 0,
    numberOfBookingsPercentChange: 0,
    totalRevenue: 0,
    totalRevenuePercentChange: 0,
    bookings: [{
      id: '',
      notes: '',
      status: '',
      paymentStatus: '',
      sport: '',
      companyId: '',
      userId: '',
      paymentId: '',
      total: 0,
      createdAt: '',
      updatedAt: '',
      orderId: '',
      allocations: [{
        facility: {
          name: '',
          sport: '',
        },
      }],
      user: {},
    }],
    eventIDsArray: [],
    xAxisBookings: [],
    xAxisRevenue: [],
    yAxisBookings: [],
    yAxisRevenue: [],
    lastUpdated: null,
  },
  analyticsLoading: false,
  analyticsFailed: false,
  analyticsAlertOpen: true,
  analyticsBookingsType: 'Occurred',
  analyticsEventLoading: false,
  analyticsEventFailed: false,
  analyticsEventAlertOpen: true,
  analyticsEventBookingsType: 'Created',
  analyticsFacilities: {
    facilityIDs: [],
    facilityNames: [],
  },
  // initial value of analytics timeframe is set here
  analyticsTimeframe: 'This Month',
  analyticsTimeframeCustom: {
    toDate: moment(),
    fromDate: moment(),
  },
  analyticsEventTimeframe: 'This Month',
  analyticsEventTimeframeCustom: {
    toDate: moment(),
    fromDate: moment(),
  },
  initialAnalyticsFacilities: {
    facilityIDs: [],
    facilityNames: [],
    // Subfacilities aren't used at the moment
    // but could potentially be used later.
    facilitySubfacilities: [],
  },
  analyticsEvents: {
    formattedEvents: [{
      id: '',
    }],
    totalUtilisation: 0,
    totalRevenue: 0,
    totalAvailableSpace: 0,
    allocations: [],
    eventIDs: [],
    eventNames: [],
  },
};

export function setAnalyticsBookingsType(analyticsBookingsType) {
  return { type: ANALYTICS_BOOKINGS_TYPE_SET, analyticsBookingsType };
}

export function setAnalyticsEventBookingsType(analyticsEventBookingsType) {
  return { type: ANALYTICS_EVENT_BOOKINGS_TYPE_SET, analyticsEventBookingsType };
}

export function setAnalyticsFacilities(analyticsFacilities) {
  return { type: ANALYTICS_FACILITIES_SET, analyticsFacilities };
}

export function setAnalyticsEvents(analyticsEvents) {
  return { type: ANALYTICS_EVENTS_SET, analyticsEvents };
}

export function setAnalyticsTimeframe(analyticsTimeframe) {
  return { type: ANALYTICS_TIMEFRAME_SET, analyticsTimeframe };
}

export function setAnalyticsTimeframeCustom(analyticsTimeframeCustom) {
  return { type: ANALYTICS_TIMEFRAME_CUSTOM_SET, analyticsTimeframeCustom };
}

export function setAnalyticsEventTimeframe(analyticsEventTimeframe) {
  return { type: ANALYTICS_EVENT_TIMEFRAME_SET, analyticsEventTimeframe };
}

export function setAnalyticsEventTimeframeCustom(analyticsEventTimeframeCustom) {
  return { type: ANALYTICS_EVENT_TIMEFRAME_CUSTOM_SET, analyticsEventTimeframeCustom };
}

export function retrieveAnalyticsBookings(query, refresh = false) {
  return { type: RETRIEVE_ANALYTICS_BOOKINGS, query, refresh };
}

export function retrieveAnalyticsEventBookings(query, refresh = false) {
  return { type: RETRIEVE_EVENT_ANALYTICS_BOOKINGS, query, refresh };
}

export function retrieveAnalyticsBookingsFailed() {
  return { type: RETRIEVE_ANALYTICS_FAILED };
}

export function retrieveAnalyticsEventBookingsFailed() {
  return { type: RETRIEVE_EVENT_ANALYTICS_FAILED };
}

export function updateAnalyticsBookings(analyticsBookings) {
  return { type: ANALYTICS_BOOKINGS_UPDATED, analyticsBookings };
}

export function updateAnalyticsEventBookings(analyticsEventBookings) {
  return { type: ANALYTICS_EVENT_BOOKINGS_UPDATED, analyticsEventBookings };
}

export function retrieveAnalyticsFacilities() {
  return { type: ANALYTICS_FACILITIES_RETRIEVED };
}

export function updateAnalyticsFacilities(facilities) {
  return { type: ANALYTICS_FACILITIES_UPDATED, facilities };
}

export function retrieveAnalyticsEvents() {
  return { type: ANALYTICS_EVENTS_RETRIEVED };
}

export function updateAnalyticsEvents(events) {
  return { type: ANALYTICS_EVENTS_UPDATED, events };
}

export function closeAnalyticsAlert() {
  return { type: ANALYTICS_ALERT_CLOSED };
}

export function closeAnalyticsEventAlert() {
  return { type: ANALYTICS_EVENT_ALERT_CLOSED };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ANALYTICS_ALERT_CLOSED:
      return { ...state, analyticsAlertOpen: false };
    case ANALYTICS_EVENT_ALERT_CLOSED:
      return { ...state, analyticsEventAlertOpen: false };
    case ANALYTICS_BOOKINGS_TYPE_SET:
      return { ...state, analyticsBookingsType: action.analyticsBookingsType };
    case ANALYTICS_EVENT_BOOKINGS_TYPE_SET:
      return { ...state, analyticsEventBookingsType: action.analyticsEventBookingsType };
    case ANALYTICS_FACILITIES_SET:
      return { ...state, analyticsFacilities: action.analyticsFacilities };
    case ANALYTICS_EVENTS_SET:
      return { ...state, analyticsEvents: action.analyticsEvents };
    case ANALYTICS_TIMEFRAME_SET:
      return { ...state, analyticsTimeframe: action.analyticsTimeframe };
    case ANALYTICS_TIMEFRAME_CUSTOM_SET:
      return { ...state, analyticsTimeframeCustom: action.analyticsTimeframeCustom };
    case ANALYTICS_EVENT_TIMEFRAME_SET:
      return { ...state, analyticsEventTimeframe: action.analyticsEventTimeframe };
    case ANALYTICS_EVENT_TIMEFRAME_CUSTOM_SET:
      return { ...state, analyticsEventTimeframeCustom: action.analyticsEventTimeframeCustom };
    case RETRIEVE_ANALYTICS_BOOKINGS:
      return { ...state, analyticsLoading: true };
    case RETRIEVE_EVENT_ANALYTICS_BOOKINGS:
      return { ...state, analyticsEventLoading: true };
    case RETRIEVE_ANALYTICS_FAILED:
      return {
        ...state,
        analyticsBookings: initialState.analyticsBookings,
        analyticsLoading: false,
        analyticsFailed: true,
      };
    case RETRIEVE_EVENT_ANALYTICS_FAILED:
      return {
        ...state,
        analyticsEventBookings: initialState.analyticsEventBookings,
        analyticsEventLoading: false,
        analyticsEventFailed: true,
      };
    case ANALYTICS_BOOKINGS_UPDATED:
      return {
        ...state,
        analyticsBookings: action.analyticsBookings,
        analyticsLoading: false,
        analyticsFailed: false,
      };
    case ANALYTICS_EVENT_BOOKINGS_UPDATED:
      return {
        ...state,
        analyticsEventBookings: action.analyticsEventBookings,
        analyticsEventLoading: false,
        analyticsEventFailed: false,
      };
    case ANALYTICS_FACILITIES_UPDATED:
      return { ...state, initialAnalyticsFacilities: action.facilities };
    case ANALYTICS_EVENTS_UPDATED:
      return { ...state, analyticsEvents: action.events };
    default:
      return state;
  }
};
