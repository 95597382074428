import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function createManagerReservationService(companyId, reservationData) {
  const url = `/api/companies/${companyId}/reservations`;
  const response = await httpHelpers.authenticatedPostRequest(url, reservationData);
  return response;
}

export async function cancelReservationsServices(companyId, reqBody) {
  const url = `/api/companies/${companyId}/reservations/cancel`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function recordMultiplePaymentService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/record-reservations-payments`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function updateReservationServices(companyId, reservationId, reqBody) {
  const url = `/api/companies/${companyId}/reservations/${reservationId}`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function updateReservationPriceService(companyId, reservationId, body) {
  const url = `/api/companies/${companyId}/reservations/${reservationId}/price`;
  const response = await httpHelpers.authenticatedPutRequest(url, body);
  return response;
}

export async function updateReservationNotesService(companyId, reservationId, body) {
  const url = `/api/companies/${companyId}/reservations/${reservationId}/note`;
  const response = await httpHelpers.authenticatedPutRequest(url, body);
  return response;
}
