export const XERO_CONSENT_URL_REQUESTED = 'XERO_CONSENT_URL_REQUESTED';
export const XERO_CONSENT_URL_SUCCEEDED = 'XERO_CONSENT_URL_SUCCEEDED';
export const XERO_CONSENT_URL_FAILED = 'XERO_CONSENT_URL_FAILED';

export const XERO_EXCHANGE_CODE_FOR_TOKEN = 'XERO_EXCHANGE_CODE_FOR_TOKEN';
export const XERO_EXCHANGE_CODE_FOR_TOKEN_SUCCESS = 'XERO_EXCHANGE_CODE_FOR_TOKEN_SUCCESS';
export const XERO_EXCHANGE_CODE_FOR_TOKEN_FAILED = 'XERO_EXCHANGE_CODE_FOR_TOKEN_FAILED';

export const PROCESS_POWERLEAGUE_BACS_REQUESTED = 'PROCESS_POWERLEAGUE_BACS_REQUESTED';
export const PROCESS_POWERLEAGUE_BACS_SUCCEEDED = 'PROCESS_POWERLEAGUE_BACS_SUCCEEDED';
export const PROCESS_POWERLEAGUE_BACS_FAILED = 'PROCESS_POWERLEAGUE_BACS_FAILED';

export const CHANGE_CRON_JOB = 'CHANGE_CRON_JOB';
export const TRIGGER_CRON_JOB_REQUESTED = 'TRIGGER_CRON_JOB_REQUESTED';
export const TRIGGER_CRON_JOB_SUCCEEDED = 'TRIGGER_CRON_JOB_SUCCEEDED';
export const TRIGGER_CRON_JOB_FAILED = 'TRIGGER_CRON_JOB_FAILED';

export const initialState = {
  consentUrl: null,
  requestingXeroConsentUrl: false,
  xeroConsentUrlFailed: false,
  requestingXeroExchangeCode: false,
  xeroExchangeCodeFailed: false,
  requestingProcessPowerleagueBacs: false,
  processPowerleagueBacsFailed: false,
  cronJobName: null,
  requestingTriggerCronJob: false,
  triggerCronJobFailed: false,
};

export const requestXeroConsentUrl = () => (
  { type: XERO_CONSENT_URL_REQUESTED }
);
export const succeedXeroConsentUrl = (consentUrl) => (
  { type: XERO_CONSENT_URL_SUCCEEDED, consentUrl }
);
export const failXeroConsentUrl = () => (
  { type: XERO_CONSENT_URL_FAILED }
);

export const requestXeroExchangeCode = (code) => (
  { type: XERO_EXCHANGE_CODE_FOR_TOKEN, code }
);
export const succeedExchangeCode = () => (
  { type: XERO_EXCHANGE_CODE_FOR_TOKEN_SUCCESS }
);
export const failExchangeCode = () => (
  { type: XERO_EXCHANGE_CODE_FOR_TOKEN_FAILED }
);

export const processPowerleagueBacs = () => (
  { type: PROCESS_POWERLEAGUE_BACS_REQUESTED }
);
export const succeedProcessPowerleagueBacs = () => (
  { type: PROCESS_POWERLEAGUE_BACS_SUCCEEDED }
);
export const failProcessPowerleagueBacs = () => (
  { type: PROCESS_POWERLEAGUE_BACS_FAILED }
);

export const changeCronJob = (cronJobName) => (
  { type: CHANGE_CRON_JOB, cronJobName }
);
export const triggerCronJob = () => (
  { type: TRIGGER_CRON_JOB_REQUESTED }
);
export const succeedCronJob = () => (
  { type: TRIGGER_CRON_JOB_SUCCEEDED }
);
export const failCronJob = () => (
  { type: TRIGGER_CRON_JOB_FAILED }
);

export default (state = initialState, action) => {
  switch (action.type) {
    case XERO_CONSENT_URL_REQUESTED:
      return {
        ...state,
        requestingXeroConsentUrl: true,
      };
    case XERO_CONSENT_URL_SUCCEEDED:
      return {
        ...state,
        consentUrl: action.consentUrl,
        requestingXeroConsentUrl: false,
        xeroConsentUrlFailed: false,
      };
    case XERO_CONSENT_URL_FAILED:
      return {
        ...state,
        requestingXeroConsentUrl: false,
        xeroConsentUrlFailed: true,
      };
    case XERO_EXCHANGE_CODE_FOR_TOKEN:
      return {
        ...state,
        requestingXeroExchangeCode: true,
      };
    case XERO_EXCHANGE_CODE_FOR_TOKEN_SUCCESS:
      return {
        ...state,
        requestingXeroExchangeCode: false,
        xeroExchangeCodeFailed: false,
      };
    case XERO_EXCHANGE_CODE_FOR_TOKEN_FAILED:
      return {
        ...state,
        requestingXeroExchangeCode: false,
        xeroExchangeCodeFailed: true,
      };
    case PROCESS_POWERLEAGUE_BACS_REQUESTED:
      return {
        ...state,
        requestingProcessPowerleagueBacs: true,
        processPowerleagueBacsFailed: false,
      };
    case PROCESS_POWERLEAGUE_BACS_SUCCEEDED:
      return {
        ...state,
        requestingProcessPowerleagueBacs: false,
        processPowerleagueBacsFailed: false,
      };
    case PROCESS_POWERLEAGUE_BACS_FAILED:
      return {
        ...state,
        requestingProcessPowerleagueBacs: false,
        processPowerleagueBacsFailed: true,
      };
    case CHANGE_CRON_JOB:
      return {
        ...state,
        cronJobName: action.cronJobName,
      };
    case TRIGGER_CRON_JOB_REQUESTED:
      return {
        ...state,
        requestingTriggerCronJob: true,
        triggerCronJobFailed: false,
      };
    case TRIGGER_CRON_JOB_SUCCEEDED:
      return {
        ...state,
        requestingTriggerCronJob: false,
        triggerCronJobFailed: false,
      };
    case TRIGGER_CRON_JOB_FAILED:
      return {
        ...state,
        requestingTriggerCronJob: false,
        triggerCronJobFailed: true,
      };
    default:
      return state;
  }
};
