import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function getCompanyUsersService(companyId) {
  const url = `/api/companies/${companyId}/team`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function removeUserFromCompanyService(companyId, userCompanyId) {
  const url = `/api/companies/${companyId}/remove-team-member`;
  const response = await httpHelpers.authenticatedPostRequest(
    url,
    { companyUserId: userCompanyId },
  );
  return response;
}

export async function addUserToCompanyService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/add-team-member`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

// api/companies/:companyId/users/exportUsersToCSV
export async function exportAllUsersToCSV(companyId) {
  const url = `/api/companies/${companyId}/users/exportUsersToCSV`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function setAccountManagerService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/set-account-manager`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
