/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogActions, DialogContent, Button, Typography,
} from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Description';
import { useMobile } from '../../../../hooks';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';

export const PartialPaymentsNoteModal = ({
  note,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMobile();

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>
        <NotesIcon />
      </Button>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullScreen={isMobile}
        maxWidth="sm"
        fullWidth
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>Order Notes</DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={onClose} />
          </DialogActions>
        </div>

        <DialogContent>
          <Typography variant="body1">
            {note}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            onClick={onClose}
            color="secondary"
            autoFocus
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
