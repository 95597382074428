import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as leaguesActions from '../../reducers/leaguesReducer';
import * as facilitiesActions from '../../reducers/facilitiesReducer';
import LeaguesTable from './LeaguesTable';
import ActionBar from '../../components/ActionBar';
import CreateLeagueDialog from './components/CreateLeagueDialog';
import '../../styles/payments.css';

function Leagues({ requestFacilitiesRetrieval, getLeagues, leagues }) {
  useEffect(() => {
    getLeagues('ACTIVE');
    requestFacilitiesRetrieval();
  }, []);

  return (
    <div style={{
      backgroundColor: '#fafbff', marginTop: '-20px', paddingTop: '20px', height: '95.5vh',
    }}
    >
      <ActionBar>
        <CreateLeagueDialog />
      </ActionBar>
      <>
        <LeaguesTable leagues={leagues} />
      </>
    </div>
  );
}

Leagues.propTypes = {
  getLeagues: PropTypes.func.isRequired,
  requestFacilitiesRetrieval: PropTypes.func.isRequired,
  leagues: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

function mapStateToProps(state) {
  const {
    leagues,
    selectedToggle,
  } = state.leagues;
  return {
    leagues,
    selectedToggle,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getLeagues: (status) => dispatch(leaguesActions.getLeagues(status)),
  requestFacilitiesRetrieval: () => dispatch(facilitiesActions.requestFacilitiesRetrieval()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Leagues);
