/**
 * @fileoverview
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import { CategoryChartBaseLine } from './bases/CategoryChartBaseLine';
import { CategoryHeaderBase } from './bases/CategoryTitleBase';
import FailedSection from '../../../../components/LoadingComponents/FailedSection';
import LoadingSkeletonBarChart from '../../../../components/LoadingComponents/LoadingSkeletonBarChart';

function CategoryRevenue(props) {
  const {
    analyticsEventBookings,
    analyticsEventTimeframe,
    analyticsEventTimeframeCustom,
    currencySym,
    analyticsEventLoading,
    analyticsEventFailed,
  } = props;
  const { bookings, xAxisRevenue, yAxisRevenue } = analyticsEventBookings;

  return (
    <div className="category" id="Revenue">
      <CategoryHeaderBase categoryName="Revenue" />
      <AlternativelyVisible condition={analyticsEventLoading}>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: '1',
        }}
        >
          <LoadingSkeletonBarChart />
        </div>

        <AlternativelyVisible condition={analyticsEventFailed}>
          <FailedSection />
          <CategoryChartBaseLine
            backgroundColor="#90EE9010"
            bookings={bookings}
            borderColor="#90EE90B0"
            categoryName="Revenue"
            analyticsEventTimeframe={analyticsEventTimeframe}
            analyticsEventTimeframeCustom={analyticsEventTimeframeCustom}
            xAxis={xAxisRevenue}
            yAxis={yAxisRevenue}
            yAxisLabel={`Revenue in ${currencySym}`}
            pointBackgroundColor="#90EE90B0"
          />
        </AlternativelyVisible>
      </AlternativelyVisible>
    </div>
  );
}

CategoryRevenue.propTypes = {
  analyticsEventBookings: PropTypes.shape().isRequired,
  analyticsEventTimeframe: PropTypes.shape().isRequired,
  analyticsEventTimeframeCustom: PropTypes.shape().isRequired,
  currencySym: PropTypes.string.isRequired,
  analyticsEventLoading: PropTypes.bool.isRequired,
  analyticsEventFailed: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    analyticsEventBookings: state.analytics.analyticsEventBookings,
    analyticsEventTimeframe: state.analytics.analyticsEventTimeframe,
    analyticsEventTimeframeCustom: state.analytics.analyticsEventTimeframeCustom,
    currencySym: state.companies.companyInfo.currencySym,
    analyticsEventLoading: state.analytics.analyticsEventLoading,
    analyticsEventFailed: state.analytics.analyticsEventFailed,
  };
}

export default connect(mapStateToProps)(CategoryRevenue);
