import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import CardIcon from '@material-ui/icons/CreditCard';
import PencilIcon from '@material-ui/icons/Edit';
import ImageIcon from '@material-ui/icons/Image';
import DetailsConfiguration from './DetailsConfiguration';
import TimingConfiguration from './TimingConfiguration';
import SetupConfiguration from './SetupConfiguration';
import AmenityAddonConfiguration from './AmenityAddonConfiguration';
import FacilityFormSelector from './FacilityFormSelector';
import FacilityAttributesConfiguration from './FacilityAttributesConfiguration';
import FacilityFinancialConfiguration from './FacilityFinancialConfiguration';
import MediaConfiguration from '../../../../components/MediaConfiguration';
import PayLaterConfiguration from './PayLaterConfiguration';
import { useCompany } from '../../../../hooks';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      style={{ display: 'flex' }} // potentially issue and needs removed
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ConfigurationTabs(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { handleTabChange, reloadResource } = props;
  const companyInfo = useCompany();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleTabChange(newValue);
  };

  const { facility, pbUser } = props;
  const subFacilitiesExist = facility.subFacilities && facility.subFacilities.length > 0;
  const facilityTreeExists = facility.facilityTree && facility.facilityTree.children
   && facility.facilityTree.children.length > 0;

  if (!facility?.company) return null;

  if (facility?.company?.status === 'DIRECTORY_APPROVED' || facility?.company?.status === 'DIRECTORY_CLAIMED_PENDING_APPROVAL') {
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="secondary"
            textColor="primary"
            aria-label="scrollable force tabs example"
            style={{ backgroundColor: '#fff', display: 'flex' }}
          >
            <Tab label="Details" icon={<InfoOutlinedIcon />} />
            <Tab label="Attributes" icon={<EditLocationIcon />} />
            <Tab label="Media" icon={<ImageIcon />} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <DetailsConfiguration facility={facility} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FacilityAttributesConfiguration facility={facility} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MediaConfiguration type="FACILITY" resourceId={facility.id} initialImage={facility.photoURL} reloadResource={reloadResource} />
        </TabPanel>
        {facility?.company?.products?.payLater === 'ENABLED' && (
        <TabPanel value={value} index={3}>
          <PayLaterConfiguration />
        </TabPanel>
        )}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="secondary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          style={{ backgroundColor: '#fff', display: 'flex' }}
        >
          <Tab label="Details" icon={<InfoOutlinedIcon />} />
          <Tab label="Timing" icon={<AccessTimeIcon />} />
          <Tab label="Configuration" icon={<InfoOutlinedIcon />} style={subFacilitiesExist || (facilityTreeExists && pbUser) ? { display: 'block' } : { display: 'none' }} />
          <Tab label="Amenities" icon={<InfoOutlinedIcon />} />
          <Tab label="Attributes" icon={<EditLocationIcon />} />
          <Tab label="Financial" icon={<CardIcon />} />
          <Tab label="Media" icon={<ImageIcon />} />
          <Tab label="Forms" icon={<PencilIcon />} />
          {companyInfo?.products?.payLater === 'ENABLED' && (
          <Tab label="Pay Later" icon={<CardIcon />} />
          )}
          {/* <Tab label="Pricing" icon={<InfoOutlinedIcon />} /> */}
          {/* <Tab label="Misc" icon={<SettingsOutlinedIcon />} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DetailsConfiguration facility={facility} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TimingConfiguration facility={facility} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SetupConfiguration facility={facility} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AmenityAddonConfiguration facility={facility} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <FacilityAttributesConfiguration facility={facility} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <FacilityFinancialConfiguration facility={facility} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <MediaConfiguration type="FACILITY" resourceId={facility.id} initialImage={facility.photoURL} reloadResource={reloadResource} />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <FacilityFormSelector
          facilityId={facility.id}
          selectedForm={facility.form}
          reloadResource={reloadResource}
        />
      </TabPanel>
      {companyInfo?.products?.payLater === 'ENABLED' && (
      <TabPanel value={value} index={8}>
        <PayLaterConfiguration
          facilityId={facility.id}
          settings={facility?.payLaterConfigurationSetting}
        />
      </TabPanel>
      )}
    </div>
  );
}

ConfigurationTabs.propTypes = {
  facility: PropTypes.string.isRequired,
  pbUser: PropTypes.bool.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  reloadResource: PropTypes.func,
};

ConfigurationTabs.defaultProps = {
  reloadResource: () => {},
};
