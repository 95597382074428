/* eslint-disable import/prefer-default-export */
import { takeEvery, call, put } from 'redux-saga/effects';
import { getPayoutsService, getPayoutService, recalculatePayoutService } from '../services/payoutsServices';
import * as actions from '../reducers/payoutsReducer';
import { handleSagaError } from './helperSaga';

function* getPayoutsSaga(companyId) {
  if (companyId) {
    try {
      const response = yield call(getPayoutsService, companyId);
      if (!response.error) {
        yield put(actions.succeedPayoutsRetrieval(response.data));
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'getPayoutsSaga');
      yield put(actions.failPayoutsRetrieval());
    }
  }
}

function* getPayoutSaga(companyId, action) {
  const { payoutId } = action;
  if (companyId) {
    try {
      const response = yield call(getPayoutService, companyId, payoutId);
      if (!response.error) {
        yield put(actions.succeedPayoutRetrieval(response.data));
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'getPayoutSaga');
      yield put(actions.failPayoutRetrieval());
    }
  }
}

function* recalculatePayoutSaga(companyId, action) {
  const { payoutId } = action;
  if (companyId) {
    try {
      const response = yield call(recalculatePayoutService, companyId, payoutId);
      if (!response.error) {
        yield put(actions.succeedRecalculatePayout(response.data));
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'recalculatePayoutSaga');
      yield put(actions.failRecalculatePayout());
    }
  }
}

// WATCHERS
export function* getPayoutsWatcher(companyId) {
  yield takeEvery(actions.PAYOUTS_RETRIEVAL_REQUESTED, getPayoutsSaga, companyId);
}

export function* getPayoutWatcher(companyId) {
  yield takeEvery(actions.PAYOUT_RETRIEVAL_REQUESTED, getPayoutSaga, companyId);
}

export function* recalculatePayoutWatcher(companyId) {
  yield takeEvery(actions.RECALCULATE_PAYOUT_REQUESTED, recalculatePayoutSaga, companyId);
}
