import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import * as userActions from '../../../reducers/usersReducer';

const userPropShape = ({
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
});

const parseAssociates = (users) => users.map((user) => {
  const fullName = `${user.firstName} ${user.lastName}`;
  const { email, teamName } = user;
  let label = `${fullName} ${email}`;
  if (teamName !== '' && teamName !== null) { label = `${label} (${teamName})`; }
  return ({
    ...user,
    label,
  });
});

class AssignAssociateSelector extends Component {
  componentDidMount() {
    const {
      getCompanyAssociates,
    } = this.props;
    getCompanyAssociates();
  }

  render() {
    const {
      availableAssociates, selectedAssociate,
      updateSelectedAgent,
      currentAgent,
      pickerDiasbled,
    } = this.props;
    const availableAssociateUsers = availableAssociates.map((a) => a.user);
    const extraProps = { };
    if (selectedAssociate && selectedAssociate.label) {
      // eslint-disable-next-line prefer-destructuring
      extraProps.value = parseAssociates([selectedAssociate])[0];
    }
    let value = null;
    if (currentAgent && currentAgent.id) {
      [value] = parseAssociates([currentAgent]);
    } else if (selectedAssociate && selectedAssociate.id) {
      [value] = parseAssociates([selectedAssociate]);
    }
    return (
      <div style={{ textAlign: 'center', margin: 'auto', maxWidth: 400 }}>
        <Autocomplete
          className="auto-complete-combo"
          options={parseAssociates(availableAssociateUsers)}
          getOptionLabel={(option) => option.label}
          style={{ width: 300, margin: 'auto' }}
          value={value}
          onChange={(event, option) => updateSelectedAgent({ ...option })}
          disableClearable
          disabled={pickerDiasbled}
          renderInput={(params) => (
            <TextField {...params} label="Select Official" variant="outlined" fullWidth />
          )}
        />
      </div>
    );
  }
}

AssignAssociateSelector.propTypes = {
  availableAssociates: PropTypes.arrayOf(PropTypes.shape(userPropShape)).isRequired,
  selectedAssociate: PropTypes.shape().isRequired,
  getCompanyAssociates: PropTypes.func.isRequired,
  updateSelectedAgent: PropTypes.func.isRequired,
  currentAgent: PropTypes.shape(),
  pickerDiasbled: PropTypes.bool,
};
AssignAssociateSelector.defaultProps = {
  currentAgent: null,
  pickerDiasbled: false,
};

const mapStateToProps = (state) => ({
  availableAssociates: state.leagues.availableAssociates,
  selectedAssociate: state.leagues.selectedAssociate,
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyAssociates: () => dispatch(userActions.getCompanyAssociates()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignAssociateSelector);
