import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export const getEventsService = async (companyId, status = 'ACTIVE', orderBy) => {
  const orderByAppend = orderBy ? `&orderBy=${orderBy}` : '';
  const url = `/api/companies/${companyId}/events?status=${status}${orderByAppend}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
};

export async function createEventService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/events`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function updateEventTagService(companyId, eventId, tags) {
  const reqBody = { tags };
  const url = `/api/companies/${companyId}/events/${eventId}/tags`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function deleteEventService(companyId, eventId) {
  const url = `/api/companies/${companyId}/events/${eventId}`;
  const response = await httpHelpers.authenticatedDeleteRequest(url);
  return response;
}

export async function requestSaveEventAddonsService(companyId, eventId, reqBody) {
  const url = `/api/companies/${companyId}/events/${eventId}/saveaddon`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
export async function requestRemoveEventAddonsService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/events/removeaddon`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export const getEventService = async (companyId, id) => {
  const url = `/api/companies/${companyId}/events/${id}?isManager=${true}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
};

export async function updateEventServices(companyId, eventId, reqBody) {
  const url = `/api/companies/${companyId}/events/${eventId}`;
  const response = httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
}

export async function assignEventAgentService(companyId, eventId, agents) {
  const reqBody = { agents };
  const url = `/api/companies/${companyId}/events/${eventId}/agents`;
  const response = httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function removeEventAgentService(companyId, eventId, reqBody) {
  const url = `/api/companies/${companyId}/events/${eventId}/remove-agent`;
  const response = httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function getUserEvents(reqBody) {
  const url = '/api/events';
  const response = httpHelpers.authenticatedGetRequest(url, reqBody);
  return response;
}

export async function getIndividualUserEvents(userId, companyId) {
  const url = `/api/companies/${companyId}/events/users/${userId}`;
  const response = httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function createEventBookingModifier(companyId, eventId, reqBody) {
  const url = `/api/companies/${companyId}/events/${eventId}/booking-modifiers`;
  const response = httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function deleteEventBookingModifier(companyId, eventId, bookingModifierId) {
  const url = `/api/companies/${companyId}/events/${eventId}/booking-modifiers/${bookingModifierId}`;
  const response = httpHelpers.authenticatedDeleteRequest(url);
  return response;
}
