import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  Facebook, Twitter, Instagram, LinkedIn, Language,
} from '@material-ui/icons';
import * as Yup from 'yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import LoadingSection from '../../components/LoadingComponents/LoadingSection';
import { getCompanySocials, updateCompanySocials } from '../../services/companiesServices';
import { usePendingChanges, useToast } from '../../hooks';
import { getCompanyInfo } from '../../reducers/companiesReducer';

const validationSchema = Yup.object().shape({
  facebookHandle: Yup.string(),
  twitterHandle: Yup.string(),
  instagramHandle: Yup.string(),
  linkedInHandle: Yup.string(),
  websiteUrl: Yup.string().url('Invalid format, please ensure you have http:// or https:// at the start of the url'),
});

const SocialsConfiguration = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const toast = useToast();
  const company = useSelector((state) => state.companies.companyInfo);
  const pending = usePendingChanges()?.company?.socialMediaHandles;

  const { data: companySocials, isLoading, isError } = useQuery({
    queryKey: ['company', company.id, 'socials'],
    queryFn: async () => {
      const res = await getCompanySocials(company.id);

      if (res.status !== 200) {
        toast.trigger({
          type: 'error',
          message: 'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us',
        });
        throw new Error('Unable to fetch company socials!');
      }

      return res.data.socialMediaHandles;
    },
    enabled: !!company.id,
  });

  const mutation = useMutation({
    mutationFn: async (values) => {
      queryClient.setQueryData(['company', company.id, 'socials'], values);

      const res = await updateCompanySocials(company.id, values);

      if (res.status !== 200) {
        throw new Error('Unable to update company socials!');
      }

      return res.data.socialMediaHandles;
    },
    onError: () => {
      toast.trigger({
        type: 'error',
        message: 'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us',
      });
      queryClient.invalidateQueries(['company', company.id, 'socials', company.id]);
    },
    onSuccess: () => {
      toast.trigger({
        type: 'success',
        message: 'Your company info has been updated successfully!',
      });
      queryClient.invalidateQueries(['company', company.id, 'socials', company.id]);
      dispatch(getCompanyInfo());
    },
  });

  if (isLoading) {
    return (<LoadingSection loadingText="Loading please wait..." />);
  }

  if (isError) {
    return (
      <Typography variant="h6" style={{ marginTop: '1rem' }}>
        An error occurred while attempting to handle your request.
        Please try again, if the issues persists please contact us
      </Typography>
    );
  }

  return (
    <Formik
      initialValues={{
        facebookHandle: pending?.facebookHandle || companySocials?.facebookHandle || '',
        twitterHandle: pending?.twitterHandle || companySocials?.twitterHandle || '',
        instagramHandle: pending?.instagramHandle || companySocials?.instagramHandle || '',
        linkedInHandle: pending?.linkedInHandle || companySocials?.linkedInHandle || '',
        websiteUrl: pending?.websiteUrl || companySocials?.websiteUrl || '',
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        await mutation.mutateAsync(values);
        resetForm();
      }}
    >
      {({
        values, errors, touched, handleSubmit, handleChange, resetForm,
      }) => (
        <form
          onSubmit={handleSubmit}
          onReset={resetForm}
          style={{
            display: 'flex', flexDirection: 'column', gap: '0.75rem',
          }}
        >
          {/* Title */}
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>Company social media configuration</Typography>
          <Typography variant="h8" style={{ marginBottom: '1rem' }}>
            Please note,
            when entering your social media info below please only enter your account handle,
            not the entire url.
          </Typography>

          {/* Inputs */}
          <>
            {/* Facebook */}
            <PBInput
              type="text"
              id="facebookHandle"
              label="Facebook"
              value={values.facebookHandle}
              onChange={handleChange}
              isError={touched.facebookHandle && Boolean(errors.facebookHandle)}
              errorMessage={touched.facebookHandle && errors.facebookHandle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Facebook />
                  </InputAdornment>
                ),
              }}
            />

            {/* Twitter */}
            <PBInput
              type="text"
              id="twitterHandle"
              label="Twitter"
              value={values.twitterHandle}
              onChange={handleChange}
              isError={touched.twitterHandle && Boolean(errors.twitterHandle)}
              errorMessage={touched.twitterHandle && errors.twitterHandle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Twitter />
                  </InputAdornment>
                ),
              }}
            />

            {/* Instagram */}
            <PBInput
              type="text"
              id="instagramHandle"
              label="Instagram"
              value={values.instagramHandle}
              onChange={handleChange}
              isError={touched.instagramHandle && Boolean(errors.instagramHandle)}
              errorMessage={touched.instagramHandle && errors.instagramHandle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Instagram />
                  </InputAdornment>
                ),
              }}
            />

            {/* Linked In */}
            <PBInput
              type="text"
              id="linkedInHandle"
              label="LinkedIn"
              value={values.linkedInHandle}
              onChange={handleChange}
              isError={touched.linkedInHandle && Boolean(errors.linkedInHandle)}
              errorMessage={touched.linkedInHandle && errors.linkedInHandle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkedIn />
                  </InputAdornment>
                ),
              }}
            />

            {/* Website Url */}
            <PBInput
              type="text"
              id="websiteUrl"
              label="Website"
              value={values.websiteUrl}
              onChange={handleChange}
              isError={touched.websiteUrl && Boolean(errors.websiteUrl)}
              errorMessage={touched.websiteUrl && errors.websiteUrl}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Language />
                  </InputAdornment>
                ),
              }}
            />
          </>

          {/* Buttons */}
          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '0.75rem',
          }}
          >
            <Button
              type="reset"
              variant="contained"
              color="secondary"
              style={{ marginTop: '10px', marginBottom: '10px', width: 200 }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: '10px', marginBottom: '10px', width: 200 }}
            >
              Save
            </Button>
          </div>
        </form>

      )}
    </Formik>
  );
};

export default SocialsConfiguration;
