import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import {
  TextField,
  Checkbox,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { roundTo } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import * as reservationsActions from '../../../reducers/reservationsReducer';

const formatAmount = (amount, currencySym = '£') => `${currencySym}${parseFloat(amount).toFixed(2)}`;

const initialState = {
  cancelSelected: false,
  refundPrice: null,
  warning: false,
  alertMessage: null,
  rowStyle: null,
};

class PartialRefundTableRow extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleUpdateRefundAmount = (allocationId, value) => {
    const { updateCancelReservationStore, cancelAllocations } = this.props;
    const newAllocations = cancelAllocations;
    const i = newAllocations.map((x) => x.id).indexOf(allocationId);
    const available = Math.max(
      roundTo(newAllocations[i].price - newAllocations[i].alreadyRefunded, 2), 0,
    );
    newAllocations[i].refundAmount = Math.min(Math.abs(parseInt(value * 100, 10) / 100), available);
    let totalRefundAmount = 0;
    let totalRefundAmountExTax = 0;
    newAllocations.forEach((al) => {
      if (al.price > 0 && al.refundAmount > 0) {
        totalRefundAmount += al.refundAmount;
        totalRefundAmountExTax += al.refundAmount * (al.totalExTax / al.price);
      }
    });
    updateCancelReservationStore(
      {
        totalRefundAmount,
        totalRefundAmountExTax,
        cancelAllocations: newAllocations,
      },
    );
    this.setState({ refundPrice: newAllocations[i].refundAmount });
    this.handleWarnings(newAllocations[i]);
  };

  handleUpdateCancelSelected = (allocationId) => {
    const { updateCancelReservationStore, cancelAllocations } = this.props;
    const newAllocations = cancelAllocations;
    const i = newAllocations.map((x) => x.id).indexOf(allocationId);
    newAllocations[i].cancelSelected = !newAllocations[i].cancelSelected;
    updateCancelReservationStore({ cancelAllocations: newAllocations });
    this.setState({ cancelSelected: newAllocations[i].cancelSelected });
    this.handleWarnings(newAllocations[i]);
  };

  handleWarnings = (al) => {
    if (Math.abs(al.price - al.refundAmount - al.alreadyRefunded) > 0.005
      && al.cancelSelected) {
      this.setState({
        warning: true,
        alertMessage: 'Warning: you are cancelling this part of the booking without fully refunding.',
        rowStyle: { borderBottom: 'none' },
      });
      return;
    }
    if (al.refundAmount > 0
      && Math.abs(al.price - al.refundAmount - al.alreadyRefunded) < 0.005
      && !al.cancelSelected
      && al.status !== 'CANCELLED'
      && al.status !== 'ALTERED') {
      this.setState({
        warning: true,
        alertMessage: 'Warning: you are fully refunding this part of the booking without cancelling.',
        rowStyle: { borderBottom: 'none' },
      });
      return;
    }
    this.setState({ ...initialState });
  }

  render() {
    const { row, currencySym, fullyRefunded } = this.props;
    const {
      cancelSelected, refundPrice, warning, alertMessage, rowStyle,
    } = this.state;
    const { name } = row.facility || row.event || row.addon;
    return (
      <>
        <TableRow>
          <TableCell align="left" style={rowStyle}>
            <div style={{ fontWeight: 800 }}>
              {name}
            </div>
            <div>
              {`${moment(row.startTime).format('DD-MMM-YYYY')},`}
            </div>
            <div>
              {`${moment(row.startTime).format('HH:mm')} - ${moment(row.endTime).add(1, 'seconds').format('HH:mm')}`}
            </div>
          </TableCell>
          <TableCell align="center" style={rowStyle}>{formatAmount(row.price, currencySym)}</TableCell>
          <TableCell align="center" style={rowStyle}>{formatAmount(row.alreadyRefunded, currencySym)}</TableCell>
          <TableCell align="center" style={rowStyle}>
            <AlternativelyVisible condition={Math.abs(row.price - row.alreadyRefunded) > 0.005}>
              {formatAmount(Math.max(row.price - row.alreadyRefunded, 0), currencySym)}
              <p>--</p>
            </AlternativelyVisible>
          </TableCell>
          <TableCell align="center" style={rowStyle}>
            <TextField
              id="refundAmount"
              type="number"
              fullWidth
              value={refundPrice}
              disabled={row.price - row.alreadyRefunded === 0 || fullyRefunded}
              onChange={(event) => this.handleUpdateRefundAmount(row.id, event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currencySym}
                  </InputAdornment>
                ),
              }}
            />
          </TableCell>
          <TableCell align="center" style={rowStyle}>
            <AlternativelyVisible condition={row.status !== 'CANCELLED' && row.status !== 'ALTERED'}>
              <Checkbox
                style={{ paddingTop: 0 }}
                value={cancelSelected}
                onClick={() => this.handleUpdateCancelSelected(row.id)}
                disableRipple
              />
              <>
                {row.status === 'CANCELLED' && (
                <p>Already cancelled.</p>)}
                {row.status === 'ALTERED' && (
                <p>Previously edited.</p>)}
              </>
            </AlternativelyVisible>
          </TableCell>
        </TableRow>
        <ConditionallyVisible condition={warning}>
          <TableRow style={{ padding: 0 }}>
            <TableCell align="center" colSpan={6} style={{ paddingTop: 0 }}>
              <Alert
                id="alert"
                severity="error"
                style={{ width: '100%', marginTop: 0 }}
              >
                {alertMessage}
              </Alert>
            </TableCell>
          </TableRow>
        </ConditionallyVisible>
      </>
    );
  }
}

PartialRefundTableRow.propTypes = {
  row: PropTypes.PropTypes.shape().isRequired,
  currencySym: PropTypes.string,
  updateCancelReservationStore: PropTypes.func.isRequired,
  cancelAllocations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fullyRefunded: PropTypes.bool,
};
PartialRefundTableRow.defaultProps = {
  currencySym: '£',
  fullyRefunded: false,
};

function mapStateToProps(state) {
  const { reservation } = state;
  const { cancelReservations } = reservation;
  const { cancelAllocations } = cancelReservations;
  return {
    cancelAllocations,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateCancelReservationStore: (keyWithValue) => dispatch(
    reservationsActions.updateCancelReservationStore(keyWithValue),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PartialRefundTableRow);
