import React from 'react';
import { Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export const StoryLane = ({ storyLaneLink, isMobile }) => (
  <>
    {storyLaneLink && (
    <div style={{
      position: 'relative', paddingBottom: '56.25%', height: 0, width: '100%', maxWidth: '1240px',
    }}
    >
      {!isMobile ? (
        <iframe
          title="story-lane"
          src={storyLaneLink}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            maxWidth: '1240px',
            height: '100%',
            border: '1px solid rgba(63,95,172,0.35)',
            boxShadow: '0px 0px 18px rgba(26, 19, 72, 0.15)',
            borderRadius: '10px',
            boxSizing: 'border-box',
          }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          loading="lazy"
        />
      ) : (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            maxWidth: '1240px',
            height: '100%',
            border: '1px solid rgba(63,95,172,0.35)',
            boxShadow: '0px 0px 18px rgba(26, 19, 72, 0.15)',
            borderRadius: '10px',
            boxSizing: 'border-box',
            backgroundColor: 'rgba(0,0,0,0.65)',
          }}
        >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
            gap: '1rem',
          }}
          >
            <Typography variant="h6" style={{ fontWeight: '900', color: 'white' }}>
              Your screen size looks too small for the demo experience.
            </Typography>
            <a href={storyLaneLink} target="_blank" rel="noreferrer">
              <Button variant="contained">
                View full screen
              </Button>
            </a>
          </div>
        </div>
      )}
    </div>
    )}
  </>
);

StoryLane.propTypes = {
  storyLaneLink: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
