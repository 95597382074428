/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import { UnclaimedUserTag } from './UnclaimedTag';
import { YouUserTag } from './YouUserTag';
import { toProperCase } from '../helpers/index';

const TableFormattedName = ({
  user,
  currentUser,
  showEmail,
  showTeam,
  showTags,
  showPhone,
  userUrl,
  agentType = null,
}) => {
  const path = window.location.pathname.split('/')[1];
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>

        <AlternativelyVisible condition={userUrl !== null}>
          <>
            <a href={userUrl}>
              {agentType !== null ? `${toProperCase(agentType)}: ` : ''}
              {user?.firstName}
              {' '}
              {user?.lastName}
            </a>
          </>
          <>
            {agentType !== null ? `${toProperCase(agentType)}: ` : ''}
            {user?.firstName}
            {' '}
            {user?.lastName}
          </>
        </AlternativelyVisible>

        <ConditionallyVisible condition={showEmail}>
          <div style={{ color: '#a2a2a2' }}>
            {user?.email}
          </div>
        </ConditionallyVisible>
        <ConditionallyVisible condition={showPhone && user?.dialCode && user?.phone}>
          <div style={{ color: '#a2a2a2' }}>
            +
            {user?.dialCode}
            {user?.phone}
          </div>
        </ConditionallyVisible>
        <ConditionallyVisible condition={showTeam}>
          <div style={{ color: '#a2a2a2' }}>
            {user?.teamName}
          </div>
        </ConditionallyVisible>
      </div>
      <ConditionallyVisible condition={showTags}>
        <ConditionallyVisible condition={!user?.passwordExists}>
          <UnclaimedUserTag userId={path === 'users' ? user?.id : user?.userId} />
        </ConditionallyVisible>
        <ConditionallyVisible condition={user?.userId === currentUser}>
          <div>
            <YouUserTag />
          </div>
        </ConditionallyVisible>
      </ConditionallyVisible>
    </div>
  );
};

TableFormattedName.propTypes = {
  user: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape(),
  showEmail: PropTypes.bool,
  showPhone: PropTypes.bool,
  showTeam: PropTypes.bool,
  showTags: PropTypes.bool,
  userUrl: PropTypes.string,
  agentType: PropTypes.string,
};

TableFormattedName.defaultProps = {
  showEmail: false,
  showPhone: false,
  showTeam: false,
  showTags: false,
  currentUser: null,
  userUrl: null,
  agentType: null,
};

export default TableFormattedName;
