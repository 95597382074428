/*
  SettingsModalBookingType represents a toggle button group that
  allows the user to toggle the type of booking. The current
  choices are:
    1. Created
    2. Occurred

  If the user makes changes, they are pushed to parent (Settings Modal)
  state using setParentState (this.props.setParentState).
*/

import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';

export class SettingsModalBookingType extends React.Component {
  constructor(props) {
    super(props);
    this.handleBookingTypePress = this.handleBookingTypePress.bind(this);
  }

  /*
      Fetch the value of selection and push to parent state.
    */
  handleBookingTypePress(event) {
    const { setParentState } = this.props;
    const analyticsBookingsType = event.currentTarget.value;
    setParentState('localBookingsType', analyticsBookingsType);
  }

  render() {
    const { value } = this.props;
    return (
      <div className="settingsModalCard">
        <Card style={{ boxShadow: 'none', border: '1px #00000010 solid' }}>
          <CardContent>
            <div style={{
              alignItems: 'center',
              color: 'rgba(0,0,0,0.54)',
              display: 'flex',
              justifyContent: 'space-between',
            }}
            >
              Type
              <Radio
                checked={value === 'Created'}
                value="Created"
                onChange={this.handleBookingTypePress}
                style={{ alignSelf: 'flex-end' }}
              />
            </div>
            <Typography variant="h5">
              Created
            </Typography>
            <Typography variant="body2" component="p">
              Selecting this option is a good way to see
              {' '}
              <i>when</i>
              {' '}
              your customers like to make
              bookings. It will show bookings that have been created within your chosen timeframe
              and across selected facilities.
            </Typography>
          </CardContent>
        </Card>
        <br />
        <Card style={{ boxShadow: 'none', border: '1px #00000010 solid' }}>
          <CardContent>
            <div style={{
              alignItems: 'center',
              color: 'rgba(0,0,0,0.54)',
              display: 'flex',
              justifyContent: 'space-between',
            }}
            >
              Type
              <Radio
                checked={value === 'Occurred'}
                value="Occurred"
                onChange={this.handleBookingTypePress}
                style={{ alignSelf: 'flex-end' }}
              />
            </div>
            <Typography variant="h5">
              Occurred
            </Typography>
            <Typography variant="body2" component="p">
              Selecting this option can give insight into
              {' '}
              <i>which</i>
              {' '}
              days and times your customers like
              to book most. It will show bookings that have
              occurred within your chosen timeframe and across
              selected facilities.
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  }
}

SettingsModalBookingType.propTypes = {
  value: PropTypes.string.isRequired,
  setParentState: PropTypes.func.isRequired,
};
