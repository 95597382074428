import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const MenuDrawerItemLink = ({ link, children }) => (
  <Link
    href={link}
    style={{ color: '#333', textDecoration: 'none' }}
    to={link}
  >
    {children}
  </Link>
);

MenuDrawerItemLink.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.shape().isRequired,
};

export default MenuDrawerItemLink;
