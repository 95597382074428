import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible/alternativelyVisible';
import * as actions from '../../reducers/usersReducer';
import * as membershipsActions from '../../reducers/accessRestrictionsReducer';
import MembershipsTable from './MembershipsTable';
import LoadingSkeletonDataTable from '../../components/LoadingComponents/LoadingSkeletonDataTable';
import '../../styles/payments.css';
import NewMembershipDialog from './components/NewMembershipDialog/NewMembershipDialog';
import * as companyActions from '../../reducers/companiesReducer';
import ActionBar from '../../components/ActionBar';
import ToolTip from '../../components/Tooltip';

class Memberships extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.reload = this.reload.bind(this);
  }

  componentDidMount() {
    const {
      getUsers,
      getCompanyInfo,
      getMemberships,
    } = this.props;
    getUsers();
    getCompanyInfo();
    getMemberships();
  }

  reload() {
    const {
      getUsers,
      getCompanyInfo,
      getMemberships,
    } = this.props;
    getUsers();
    getMemberships();
    getCompanyInfo();
  }

  render() {
    const { props } = this;
    const { accessRestrictions, history } = props;
    const { membersList } = accessRestrictions;
    const { users } = props.users;
    const { memberships, isLoading } = props.companies;
    const { invoiceAccepted, currencySym } = props.companies.companyInfo;
    const selectedMembershipId = props.match.params.membershipId;
    let showInvoiceAccepted = false;
    if (invoiceAccepted === 'MEMBERS_ONLY') {
      showInvoiceAccepted = true;
    }
    return (
      <div style={{ background: '#fafbff', marginTop: '-20px', paddingTop: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '20px' }}>
          <ActionBar>
            <NewMembershipDialog />
          </ActionBar>
          <ToolTip contentfulName="membersTabToolTip" />
        </div>
        <AlternativelyVisible condition={isLoading}>
          <LoadingSkeletonDataTable />
          <MembershipsTable
            memberships={memberships}
            selectedMembershipId={selectedMembershipId}
            addUsers={(userIDs, membership) => this.addUsersToMembership(userIDs, membership)}
            userList={users && users.map((user) => ({
              label: `${user.firstName} ${user.lastName} <${user.email}>`,
              fullName: `${user.firstName} ${user.lastName}`,
              email: `${user.email}`,
              id: `${user.id}`,
            }))}
            membersList={membersList}
            reload={this.reload}
            showInvoiceAccepted={showInvoiceAccepted}
            tableRef={this.tableRef}
            history={history}
            currencySym={currencySym}
          />
          <div style={{ background: '#fafbff', height: 40 }} />
        </AlternativelyVisible>
      </div>
    );
  }
}

Memberships.propTypes = {
  users: PropTypes.shape().isRequired,
  companies: PropTypes.shape().isRequired,
  accessRestrictions: PropTypes.shape().isRequired,
  membersList: PropTypes.shape().isRequired,
  getUsers: PropTypes.func.isRequired,
  getCompanyInfo: PropTypes.func.isRequired,
  getMemberships: PropTypes.func.isRequired,
  match: PropTypes.string.isRequired,
  history: PropTypes.shape().isRequired,
};

function mapStateToProps(state) {
  const {
    users, memberships, accessRestrictions, companies, isLoading,
  } = state;
  return {
    users,
    companies,
    memberships,
    accessRestrictions,
    isLoading,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getUsers: () => dispatch(actions.getUsers()),
  getCompanyInfo: () => dispatch(companyActions.getCompanyInfo()),
  getMemberships: () => dispatch(companyActions.requestCompanyMembershipRetrieval()),
  getMembershipUsers: (membershipId) => dispatch(
    membershipsActions.getMembershipUsers(membershipId),
  ),
  updateMembership: () => dispatch(membershipsActions.updateMembership()),

});

export default connect(mapStateToProps, mapDispatchToProps)(Memberships);
