import {
  takeEvery, call, put, select,
} from 'redux-saga/effects';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  getSitesWithFacilitiesService,
  updateSiteDetailsService,
  requestSiteCreationService,
} from '../services/sitesServices';
import * as sitesActions from '../reducers/sitesReducer';
import * as companyActions from '../reducers/companiesReducer';
import { handleSagaError } from './helperSaga';

function* getSitesWithFacilitiesSaga(companyId) {
  try {
    const response = yield call(getSitesWithFacilitiesService, companyId);
    if (!response.error) {
      yield put(sitesActions.succeedSitesWithFacilitiesRetrieval(response.data));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getSitesSaga');
  }
}
function* updateSiteDetailsSaga(companyId, action) {
  const state = yield select();
  try {
    const response = yield call(
      updateSiteDetailsService,
      companyId,
      action.siteId,
      state.sites.site,
    );
    if (!response.error) {
      yield put(sitesActions.requestSitesWithFacilitiesRetrieval());
      yield put(companyActions.getCompanyInfo());
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'getSitesSaga');
  }
}

function* requestSiteCreationSaga(companyId) {
  const state = yield select();
  const { newSite } = state.sites;
  const formattedSite = {
    newSite,
  };
  if (companyId) {
    try {
      const response = yield call(requestSiteCreationService, companyId, formattedSite);
      if (!response.error) {
        yield put(sitesActions.siteCreationSucceeded(response.data));
        yield put(companyActions.getCompanyInfo());
        yield put(sitesActions.requestSitesWithFacilitiesRetrieval());
        yield put(sessionActions.updateSuccessfulSnackbar('Site Created Successfully', true));
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'requestSiteCreationSaga');
      yield put(sitesActions.siteCreationFailed(error.error));
    }
  }
}
// Watchers
export function* getSitesWatcher(companyId) {
  yield takeEvery(sitesActions.SITES_FACILITIES_RETRIEVAL_REQUESTED,
    getSitesWithFacilitiesSaga, companyId);
}
export function* updateSiteDetailsWatcher(companyId) {
  yield takeEvery(sitesActions.SITE_DETAILS_UPDATE_REQUESTED,
    updateSiteDetailsSaga, companyId);
}
export function* requestSiteCreationWatcher(companyId) {
  yield takeEvery(sitesActions.SITE_CREATION_REQUESTED,
    requestSiteCreationSaga, companyId);
}
