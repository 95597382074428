/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import TimetableInitialiser from '@pitchbooking-dev/pb-shared/lib/components/timetable/TimetableInitialiser';
import TimetableSelectedTimeslots from '@pitchbooking-dev/pb-shared/lib/components/timetable/TimetableSelectedTimeslots';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { combineTimeslots } from '../../utils';

const EditCurrentBooking = ({
  facility, updateFacility, date, allocation,
}) => {
  const selectedTimeslots = useSelector((state) => state.timetable.selectedTimeslots);
  const pitchSplit = facility?.pitchSplit === 'USER_SELECTION' || facility?.pitchSplit === 'MULTIPLE_SELECTION' ? facility?.quantity : 1;
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <div className="reservation-creation-timetable">
        <div className="reservation-creation-timetable-picker">
          <div style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          >
            <div style={{
              width: '80px',
              height: '40px',
              borderRadius: '4px',
              boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)',
              border: 'solid 2px #d4b90d',
              backgroundColor: '#ffdc00',
            }}
            />
            <span>
              Original booking
            </span>
          </div>

          {facility && (
            <TimetableInitialiser
              facilityInformation={{
                facilityId: facility.parentFacilityId ? facility.parentFacilityId : facility.id,
                subFacilityId: facility.parentFacilityId ? facility.id : null,
                companyId: facility.companyId,
                previousAllocationId: allocation?.id,
              }}
              searchDate={date}
              manager
              blockBooking
              pitchSplit={pitchSplit}
            />
          )}

        </div>
        <div className="reservation-creation-timetable-basket">
          <TimetableSelectedTimeslots combineSlots={facility?.combineSlots} />
        </div>
      </div>
      <div style={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          color="primary"
          disabled={selectedTimeslots.length <= 0 || isLoading}
          onClick={() => {
            let timeslots = selectedTimeslots;

            if (facility.combineSlots || facility?.parentFacility?.combineSlots) {
              timeslots = combineTimeslots(selectedTimeslots);
            }

            updateFacility(facility, timeslots);
            setIsLoading(true);
          }}
        >
          Update Booking
        </Button>
      </div>
    </>
  );
};

EditCurrentBooking.propTypes = {
  facility: PropTypes.shape.isRequired,
  updateFacility: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
};

export default EditCurrentBooking;
