import { useSelector } from 'react-redux';

/**
 * Custom hook that returns key information about the current user
 * @returns {{
 *  id: String,
 *  firstName: String,
 *  lastName: String,
 *  email: String,
 *  isLoggedin: Boolean,
 *  isPitchbookingManager: Boolean,
 *  userPreferences: Object
 *  siteAccess: Array<String>
 * }} - Object containing the current user's information
 */
const usePitchbookingUser = () => {
  const { isLoggedIn, currentUser, managerAccess } = useSelector((state) => state.currentUser);
  const { companyInfo, userCompanies } = useSelector((state) => state.companies);

  const preferences = userCompanies?.find(
    (company) => company.companyId === companyInfo.id,
  )?.userPreferences;

  const siteAccess = managerAccess?.map((ma) => ma.siteId).filter((siteId) => siteId !== null);

  return {
    id: currentUser?.userId,
    firstName: currentUser?.firstName,
    lastName: currentUser?.lastName,
    email: currentUser?.email,
    isLoggedIn,
    isPitchbookingManager: currentUser?.managerAccess.some((x) => x.role.type === 'PITCHBOOKING_INTERNAL'),
    preferences: preferences || {},
    siteAccess: siteAccess || [],
  };
};

export default usePitchbookingUser;
