import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  IconButton, List, ListItem, ListItemSecondaryAction, ListItemText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Block';
import * as membershipsActions from '../../../reducers/accessRestrictionsReducer';

const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    background: theme.palette.background.paper,
  },
});

class MembersList extends React.Component {
  handleRemove = (memberId) => () => {
    const { removeUserFromMembership, membershipID } = this.props;
    const reqBody = {
      userId: memberId,
    };
    removeUserFromMembership(membershipID, reqBody);
  }

  render() {
    const { classes, membersList } = this.props;
    return (
      <div className={classes.root}>
        <List>
          {membersList.map((member) => (
            <ListItem
              key={member.id}
              className={classes.listItem}
            >
              <ListItemText
                primary={`${member.firstName} ${member.lastName}`}
                secondary={`${member.email}`}
              />
              <ListItemSecondaryAction>
                <IconButton aria-label="Comments">
                  <DeleteIcon
                    onClick={this.handleRemove(member.id)}
                    color="primary"
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

MembersList.propTypes = {
  classes: PropTypes.shape().isRequired,
  membersList: PropTypes.shape().isRequired,
  removeUserFromMembership: PropTypes.func.isRequired,
  membershipID: PropTypes.shape().isRequired,
};

function mapStateToProps(state) {
  const { memberships } = state;
  return {
    memberships,
  };
}

const mapDispatchToProps = (dispatch) => ({
  removeUserFromMembership: (membershipId, reqBody) => dispatch(
    membershipsActions.removeUserFromMembership(membershipId, reqBody),
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MembersList));
