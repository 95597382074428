import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/payments.css';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import TableFormattedName from '../../components/TableFormattedName';
import TableToolbar from './components/TableToolBar';

const AssociatesTable = ({
  associates,
}) => {
  const includeSites = associates.some((t) => t.siteName !== null);
  return (
    <div className="table-section">
      <Paper
        style={{
          borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden', width: '100%',
        }}
        component={Paper}
      >
        <TableToolbar />
        <MaterialTable
          className="table"
          aria-label="simple table"
          data={associates}
          title="Pitchbooking Associates"
          icons={{
            NextPage: () => <ChevronRight />,
            PreviousPage: () => <ChevronLeft />,
            Search: () => <Search />,
            Export: () => <SaveAlt />,
            ResetSearch: () => <Clear />,
            SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
          }}
          columns={[
            {
              title: 'Associate',
              field: 'user',
              render: (teamMemberEntry) => (
                <TableFormattedName
                  user={teamMemberEntry.user}
                  showEmail
                />
              ),
            },
            {
              title: 'Site',
              field: 'siteName',
              render: (teamMemberEntry) => (
                teamMemberEntry.siteName
                  ? teamMemberEntry.siteName
                  : 'All'
              ),
              hidden: !includeSites,
            },
          ]}
          options={{
            search: true,
            tableLayout: 'auto',
            emptyRowsWhenPaging: false,
            exportButton: true,
            exportFileName: 'Pitchbooking Team Members',
            pageSize: 25,
            pageSizeOptions: [10, 25, 50, 100],
            showFirstLastPageButtons: false,
            showTitle: false,
            headerStyle: {
              backgroundColor: '#efefef',
              fontWeight: 'bold',
            },
          }}
          localization={{ toolbar: { exportTitle: 'Download' } }}
        />
      </Paper>
    </div>
  );
};

AssociatesTable.propTypes = {
  associates: PropTypes.shape().isRequired,
};

export default (AssociatesTable);
