import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../styles/App.css';

const LoadingSection = (props) => {
  const { loadingText, height } = props;
  return (
    <div className="loadingOverlay" style={height === 'auto' ? { minHeight: 'auto' } : {}}>
      <Typography style={{ fontSize: '2.5vh', paddingBottom: 10 }}>
        {loadingText}
      </Typography>
      <CircularProgress size="3rem" value={100} variant="indeterminate" />
    </div>
  );
};

LoadingSection.propTypes = {
  loadingText: PropTypes.string.isRequired,
  height: PropTypes.string,
};
LoadingSection.defaultProps = {
  height: null,
};

export default (LoadingSection);
