import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible/alternativelyVisible';
import { requestSelectedFacilityUpdate, requestFacilitiesRetrieval } from '../../../reducers/facilitiesReducer';

const FacilityInternalToggle = () => {
  const { selectedFacility: facility } = useSelector((state) => state.facilities);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const updateFacility = () => {
    dispatch(
      requestSelectedFacilityUpdate(
        facility.id,
        {
          ...facility,
          internal: !facility.internal,
          audit: {
            note: `Toggled from ${facility.internal ? 'Internal' : 'External'} to ${!facility.internal ? 'Internal' : 'External'}`,
          },
        },
      ),
    );
    dispatch(requestFacilitiesRetrieval());
    dispatch(push('/facilities'));
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        style={{
          marginLeft: '10px', marginTop: '10px', marginBottom: '10px', width: 200,
        }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        { `Toggle ${facility.internal ? 'External' : 'Internal'}` }
      </Button>

      <Dialog
        open={isOpen}
      >
        <DialogTitle>{ `Toggle Facility ${facility.internal ? 'External' : 'Internal'}` }</DialogTitle>
        <DialogContent style={{ width: '600px' }}>
          <AlternativelyVisible condition={facility.internal}>
            <p>
              This facility is currently an Internal facility and as such cannot be booked online.
              Please confirm that you would like to toggle this facility to be External.
            </p>
            <p>
              This facility is currently an External facility and as such can be booked online.
              Please confirm that you would like to toggle this facility to be Internal.
            </p>
          </AlternativelyVisible>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen((prev) => !prev)} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button
            onClick={updateFacility}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FacilityInternalToggle;
