import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CheckCircle from '@material-ui/icons/CheckCircle';

import StripeCheckout from '@pitchbooking-dev/pb-shared/lib/components/Stripe/StripeCheckout';
import { Card } from '@pitchbooking-dev/pb-shared/lib/components/common';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import * as actions from '../../reducers/companiesReducer';
import '../../styles/billing.css';

const Welcome = (props) => {
  const {
    companyInfo,
  } = props;

  return (
    <div style={{
      textAlign: 'center',
      width: '70%',
      margin: 'auto',
      maxWidth: '800px',
      padding: '20px',
      paddingTop: '40px',
    }}
    >
      <div id="billingConfirmationSection" />
      <h1>Welcome to Pitchbooking</h1>
      <p>
        Let&apos;s get started by setting up your payment method.
        This is the first step to start collecting online payments with Pitchbooking.
      </p>
      <>
        <Card style={{ textAlign: 'left' }}>
          <Card.Header>
            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
              <CreditCardIcon />
              <span style={{ fontWeight: 600, fontSize: '1rem' }}>Payment Method</span>
            </div>
          </Card.Header>
          <Card.Body>
            <StripeCheckout
              companyStripeId="acct_1CS6MjD87o6fmXTg"
              mode="SETUP_INTENT"
              transactionType="BILLING"
              transactionId={companyInfo.id}
              redirectUrl={`${window.location.origin}/welcome-confirmed`}
              buttonText="Get Started"
            />
          </Card.Body>
        </Card>
      </>
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '15px', textAlign: 'left',
      }}
      >
        <div style={{ padding: 5, display: 'flex' }}>
          <CheckCircle style={{ color: '#2fffb3', verticalAlign: 'middle' }} />
          <div>
            Enjoy peace of mind -
            {' '}
            <b>You won&apos;t be charged a penny</b>
            {' '}
            until you&apos;ve had
            a chat with your
            Pitchbooking account manager and agreed on how everything works.
          </div>
        </div>
        <div style={{ padding: 5, display: 'flex' }}>
          <CheckCircle style={{ color: '#2fffb3', verticalAlign: 'middle' }} />
          Get off to a great start with comprehensive onboarding and
          training provided by your dedicated account manager.
        </div>
        <div style={{ padding: 5, display: 'flex' }}>
          <CheckCircle style={{ color: '#2fffb3', verticalAlign: 'middle' }} />
          Fancy a chat? Easily book a call with our friendly Pitchbooking team
          to get all your queries answered.
        </div>
        <div style={{ display: 'flex', alignSelf: 'center' }}>
          <a href="https://meetings.hubspot.com/lyndon-mccullough" target="_blank" rel="noreferrer">Book a call</a>
        </div>
        <br />
        <small>
          The exact cost of your Pitchbooking subscription will be determined based on
          usage of the platform.
          This will be agreed with your account manager during onboarding and training.
          You will not be billed without your agreement and consent.
          {' '}
        </small>
      </div>

    </div>
  );
};

Welcome.propTypes = {
  companyInfo: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  companyInfo: state.companies.companyInfo,
  currentUser: state.users.currentUser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getBillingData: (billingId) => actions.getBillingData(billingId),
    setStripeBilling: (source) => actions.setStripeBilling(source),
    updateBilling: (billingId, billingParams) => actions.updateBilling(billingId, billingParams),
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Welcome);
