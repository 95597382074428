/* eslint-disable no-case-declarations */
export const ACCESS_RESTRICTIONS_RETRIEVAL_REQUESTED = 'ACCESS_RESTRICTIONS_RETRIEVAL_REQUESTED';
export const ACCESS_RESTRICTIONS_RETRIEVAL_SUCCEEDED = 'ACCESS_RESTRICTIONS_RETRIEVAL_SUCCEEDED';
export const ACCESS_RESTRICTIONS_RETRIEVAL_FAILED = 'ACCESS_RESTRICTIONS_RETRIEVAL_FAILED';

export const ACCESS_RESTRICTION_UPDATE_REQUESTED = 'ACCESS_RESTRICTION_UPDATE_REQUESTED';
export const ACCESS_RESTRICTION_UPDATE_SUCCEEDED = 'ACCESS_RESTRICTION_UPDATE_SUCCEEDED';
export const ACCESS_RESTRICTION_UPDATE_FAILED = 'ACCESS_RESTRICTION_UPDATE_FAILED';

export const UPDATED_ACCESS_RESTRICTIONS = 'UPDATE_ACCESS_RESTRICTIONS_REQUESTED';
export const ACCESS_RESTRICTION_DIALOG_UPDATED = 'ACCESS_RESTRICTION_DIALOG_UPDATED';
export const DELETE_AR_DIALOG_UPDATED = 'DELETE_AR_DIALOG_UPDATED';

export const GET_MEMBERSHIPS = 'GET_MEMBERSHIPS';
export const MEMBERSHIPS_RETRIEVAL_SUCCEEDED = 'MEMBERSHIPS_RETRIEVAL_SUCCEEDED';
export const MEMBERSHIPS_RETRIEVAL_FAILED = 'MEMBERSHIPS_RETRIEVAL_FAILED';

export const GET_MEMBERSHIP_USERS = 'GET_MEMBERSHIP_USERS';
export const UPDATE_MEMBERSHIPS = 'UPDATE_MEMBERSHIPS';
export const UPDATE_MEMBERSHIP_USERS = 'UPDATE_MEMBERSHIP_USERS';
export const RESET_MEMBERSHIP_USERS = 'RESET_MEMBERSHIP_USERS';
export const UPDATE_MEMBERSHIP = 'UPDATE_UPDATE_MEMBERSHIP';
export const INVITE_USER_TO_MEMBERSHIP = 'INVITE_USER_TO_MEMBERSHIP';
export const ADD_USERS_TO_MEMBERSHIP = 'ADD_USERS_TO_MEMBERSHIP';
export const REMOVE_USER_FROM_MEMBERSHIP = 'REMOVE_USER_FROM_MEMBERSHIP';

export const ACCESS_RESTRICTION_EDIT_ENABLED = 'ACCESS_RESTRICTION_EDIT_ENABLED';
export const ACCESS_RESTRICTION_CREATION_RESET = 'ACCESS_RESTRICTION_CREATION_RESET';
export const ACCESS_RESTRICTION_CREATION_PARTIALLY_RESET = 'ACCESS_RESTRICTION_CREATION_PARTIALLY_RESET';

// Creating a AR
export const ACCESS_RESTRICTION_CREATION_REQUEST = 'ACCESS_RESTRICTION_CREATION_REQUEST';
export const ACCESS_RESTRICTION_CREATION_SUCCEEDED = 'ACCESS_RESTRICTION_CREATION_SUCCEEDED';
export const ACCESS_RESTRICTION_CREATION_FAILED = 'ACCESS_RESTRICTION_CREATION_FAILED';
export const UPDATED_ACCESS_RESTRICTION_STORE = 'UPDATED_ACCESS_RESTRICTION_STORE';

export const TOGGLED_EDITING = 'TOGGLED_EDITING';
export const SAVE_ACCESS_RESTRICTION_REQUESTED = 'SAVE_ACCESS_RESTRICTION_REQUESTED';
export const SAVE_ACCESS_RESTRICTION_SUCCEEDED = 'SAVE_ACCESS_RESTRICTION_SUCCEEDED';
export const SAVE_ACCESS_RESTRICTION_FAILED = 'SAVE_ACCESS_RESTRICTION_FAILED';

export const DELETE_EXCLUSION = 'DELETE_EXCLUSION';

const initialState = {
  membersList: [],
  existingAccessRestrictions: [],
  newAccessRestriction: {
    description: '',
    memberships: [],
    facilities: [],
    status: 'INACTIVE',
    recurrence: 'NONE',
    startTime: null,
    endTime: null,
    validFrom: null,
    validTo: null,
    weekday: null,
    endDateOption: 'YES',
    startDateOption: 'YES',
    type: 'HOLIDAY',
    exclusions: [],
    exclusionsToDelete: [],
    exclusionsEnabled: false,
  },
  accessRestrictionDialogOpen: false,
  editingAccessRestriction: false,
  deleteARDialogOpen: false,
  failedReason: '',
  membershipsRetrievalFailedMessage: '',
  isLoading: false,
};

export function enableAccessRestrictionEdit(accessRestriction) {
  return {
    type: ACCESS_RESTRICTION_EDIT_ENABLED,
    accessRestriction,
  };
}
export function getAccessRestrictions(showLiveOnly, arType) {
  return { type: ACCESS_RESTRICTIONS_RETRIEVAL_REQUESTED, showLiveOnly, arType };
}
export function createAccessRestriction(getOptions) {
  return { type: ACCESS_RESTRICTION_CREATION_REQUEST, getOptions };
}
export function updateAccessRestrictions(accessRestrictions) {
  return { type: ACCESS_RESTRICTIONS_RETRIEVAL_SUCCEEDED, accessRestrictions };
}
export function updateAccessRestriction(id, requestedOperation, getOptions) {
  return {
    type: ACCESS_RESTRICTION_UPDATE_REQUESTED, id, requestedOperation, getOptions,
  };
}
export function updateAccessRestrictionsFailed(failedReason) {
  return { type: ACCESS_RESTRICTIONS_RETRIEVAL_FAILED, failedReason };
}
export function updateAccessRestrictionCreation(accessRestriction) {
  return { type: UPDATED_ACCESS_RESTRICTION_STORE, accessRestriction };
}
export function toggleEditing(bool) {
  return { type: TOGGLED_EDITING, bool };
}
export function updateAccessRestrictionDialog(bool) {
  return { type: ACCESS_RESTRICTION_DIALOG_UPDATED, bool };
}
export function updatedeleteARDialogDialog(bool) {
  return { type: DELETE_AR_DIALOG_UPDATED, bool };
}
export function resetAccessRestrictionCreation() {
  return { type: ACCESS_RESTRICTION_CREATION_RESET };
}
export function partiallyResetAccessRestrictionCreation() {
  return { type: ACCESS_RESTRICTION_CREATION_PARTIALLY_RESET };
}
export function getMemberships() {
  return { type: GET_MEMBERSHIPS };
}
export function membershipsRetrievedSuccesfully(memberships) {
  return { type: MEMBERSHIPS_RETRIEVAL_SUCCEEDED, memberships };
}
export function membershipsRetrievedFailed(error) {
  return { type: MEMBERSHIPS_RETRIEVAL_FAILED, error };
}
export function getMembershipUsers(membershipId) {
  return { type: GET_MEMBERSHIP_USERS, membershipId };
}
export function updateMembership(membershipId, reqBody) {
  return { type: UPDATE_MEMBERSHIP, membershipId, reqBody };
}
export function inviteUserToMembership(membershipId, reqBody) {
  return { type: INVITE_USER_TO_MEMBERSHIP, membershipId, reqBody };
}
export function addUsersToMembership(membershipId, reqBody) {
  return { type: ADD_USERS_TO_MEMBERSHIP, membershipId, reqBody };
}
export function removeUserFromMembership(membershipId, reqBody) {
  return { type: REMOVE_USER_FROM_MEMBERSHIP, membershipId, reqBody };
}
export function updateMemberships(memberships) {
  return { type: UPDATE_MEMBERSHIPS, memberships };
}
export function updateMembershipUsers(membersList) {
  return { type: UPDATE_MEMBERSHIP_USERS, membersList };
}
export function resetMembershipUsers(membersList) {
  return { type: RESET_MEMBERSHIP_USERS, membersList };
}
export function saveAccessRestriction(id, requestedOperation, getOptions) {
  return {
    type: SAVE_ACCESS_RESTRICTION_REQUESTED, id, requestedOperation, getOptions,
  };
}
export function saveAccessRestrictionFailed(failedReason) {
  return { type: SAVE_ACCESS_RESTRICTION_FAILED, failedReason };
}
export function deleteExclusion(exclusion) {
  return { type: DELETE_EXCLUSION, exclusion };
}

const changeNewAccessRestrictionProperty = (state, newPropertyWithValue) => {
  const { newAccessRestriction } = state;
  const propertyLabel = Object.keys(newPropertyWithValue)[0];
  const oldPropertyValue = state[propertyLabel];

  // if (propertyLabel === 'exclusions') {
  //   return ({
  //     ...newAccessRestriction,
  //     exclusions: [...state.newAccessRestriction.exclusions,
  //       Object.values(newPropertyWithValue)[0]],
  //   });
  // }

  if (Array.isArray(oldPropertyValue)) {
    return ({
      ...newAccessRestriction,
      [propertyLabel]: [...newPropertyWithValue],
    });
  }

  return ({
    ...state.newAccessRestriction,
    ...newPropertyWithValue,
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MEMBERSHIPS:
      return { ...state };
    case MEMBERSHIPS_RETRIEVAL_SUCCEEDED:
      return { ...state, memberships: action.memberships };
    case MEMBERSHIPS_RETRIEVAL_FAILED:
      return {
        ...state,
        membershipsRetrievalFailedMessage: action.error,
      };
    case UPDATE_MEMBERSHIPS:
      return { ...state, memberships: action.memberships };
    case UPDATE_MEMBERSHIP_USERS:
      return { ...state, membersList: action.membersList };
    case RESET_MEMBERSHIP_USERS:
      return { ...state, membersList: initialState.membersList };
    case ACCESS_RESTRICTIONS_RETRIEVAL_REQUESTED:
      return { ...state, isLoading: true };
    case ACCESS_RESTRICTIONS_RETRIEVAL_SUCCEEDED:
      return { ...state, existingAccessRestrictions: action.accessRestrictions, isLoading: false };
    case ACCESS_RESTRICTIONS_RETRIEVAL_FAILED:
      return { ...state, failedReason: action.failedReason };
    case SAVE_ACCESS_RESTRICTION_FAILED:
      return { ...state, failedReason: action.failedReason };
    case ACCESS_RESTRICTION_DIALOG_UPDATED:
      return { ...state, accessRestrictionDialogOpen: action.bool };
    case TOGGLED_EDITING:
      return { ...state, editingAccessRestriction: action.bool };
    case DELETE_AR_DIALOG_UPDATED:
      return { ...state, deleteARDialogOpen: action.bool };
    case ACCESS_RESTRICTION_CREATION_RESET: {
      return { ...state, newAccessRestriction: initialState.newAccessRestriction };
    }
    case ACCESS_RESTRICTION_CREATION_PARTIALLY_RESET: {
      const {
        validFrom, validTo, weekday, startDateOption, endDateOption, exclusionsToDelete,
      } = initialState.newAccessRestriction;
      return {
        ...state,
        newAccessRestriction: {
          ...state.newAccessRestriction,
          validFrom,
          validTo,
          weekday,
          startDateOption,
          endDateOption,
          exclusionsToDelete,
        },
      };
    }
    case UPDATED_ACCESS_RESTRICTION_STORE:
      return {
        ...state,
        newAccessRestriction: changeNewAccessRestrictionProperty(state, action.accessRestriction),
      };
    case ACCESS_RESTRICTION_EDIT_ENABLED:
      return {
        ...state,
        newAccessRestriction: {
          ...state.newAccessRestriction,
          ...action.accessRestriction,
        },
      };
    case DELETE_EXCLUSION:
      const x = state.newAccessRestriction.exclusionsToDelete;

      let tempExclusions;
      if (action.exclusion.id) {
        x.push(action.exclusion.id);
        tempExclusions = state.newAccessRestriction.exclusions
          .filter((y) => y.id !== action.exclusion.id);
      } else {
        tempExclusions = state.newAccessRestriction.exclusions
          .filter((y) => y.date !== action.exclusion.date);
      }

      return {
        ...state,
        exclusionsToDelete: x,
        newAccessRestriction: {
          ...state.newAccessRestriction,
          exclusions: tempExclusions,
        },
      };
    default:
      return state;
  }
};
