import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Responsive from 'react-responsive';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import TimetableInitialiser from '@pitchbooking-dev/pb-shared/lib/components/timetable/TimetableInitialiser';
import Alert from '@material-ui/lab/Alert';
import TimetableSelectedTimeslots from '@pitchbooking-dev/pb-shared/lib/components/timetable/TimetableSelectedTimeslots';
import * as timetableActions from '@pitchbooking-dev/pb-shared/lib/actions/timetableActions';
import { Button, Switch, Typography } from '@material-ui/core';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import MenuHeading from '../../../../components/MenuHeading';
import * as reservationsActions from '../../../../reducers/reservationsReducer';
import CompanyFacilityPicker from '../../../../components/CompanyFacilityPicker';
import AgeGroupRadio from '../../../../components/AgeGroupRadio';
import EventRadio from '../../../../components/EventRadio';
import * as facilitiesActions from '../../../../reducers/facilitiesReducer';
import { combineTimeslots } from '../../../../utils/timetable.utils';

class BookingStepperTimeslotPicker extends Component {
  constructor(props) {
    super(props);
    this.updatePitchSplitAndResetTimetable = this.updatePitchSplitAndResetTimetable.bind(this);
    this.updateSelectedFacilityHandler = this.updateSelectedFacilityHandler.bind(this);
    this.updateSelectedSubFacilityHandler = this.updateSelectedSubFacilityHandler.bind(this);
  }

  updatePitchSplitAndResetTimetable(pitchSplit) {
    const { changeSelectedPitchSplit, doResetTimetableData } = this.props;
    changeSelectedPitchSplit(pitchSplit);
    doResetTimetableData();
  }

  updateSelectedFacilityHandler(updatedFacilityId) {
    const { updateSelectedFacility, resetReservation } = this.props;
    resetReservation();
    updateSelectedFacility(updatedFacilityId);
  }

  updateSelectedSubFacilityHandler(updatedSubFacilityId) {
    const { updateSelectedSubFacility, resetSelectedDuration } = this.props;
    resetSelectedDuration();
    updateSelectedSubFacility(updatedSubFacilityId);
  }

  render() {
    const {
      pitchSplit,
      facility,
      searchDate,
      subFacility,
      companyId,
      retrospectiveBooking,
      updateRetrospectiveBooking,
      updateSelectedSport,
      reservationData,
      convertReservationToBlockBooking,
      selectedTimeslots,
    } = this.props;
    const {
      combineSlots,
      ageGroupBookingModifiers, eventBookingModifiers,
    } = facility;

    let subFacilityId = null;
    if (subFacility && subFacility.id) {
      subFacilityId = subFacility.id;
    }

    const renderFacilityPicker = () => (
      <CompanyFacilityPicker
        updateSelectedFacility={
          (updatedFacilityId) => {
            this.updateSelectedFacilityHandler(updatedFacilityId);
          }
        }
        updateSelectedSubFacility={(updatedSubFacilityId) => this.updateSelectedSubFacilityHandler(
          updatedSubFacilityId,
        )}
      />
    );

    const adjacentTimeslots = combineTimeslots(selectedTimeslots);

    const renderTimeTableWrapper = (isMobile) => (
      <>
        <ConditionallyVisible condition={ageGroupBookingModifiers
        && ageGroupBookingModifiers.length > 0}
        >
          <AgeGroupRadio />
          <br />
        </ConditionallyVisible>
        <ConditionallyVisible condition={eventBookingModifiers
        && eventBookingModifiers.length > 0}
        >
          <EventRadio />
          <br />
        </ConditionallyVisible>
        {facility.id && (
        <>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <MenuHeading title="Booking is a retrospective booking?" />
            <Switch
              checked={retrospectiveBooking}
              value={retrospectiveBooking}
              onChange={(event) => updateRetrospectiveBooking(event.target.checked)}
            />
          </div>
          {adjacentTimeslots.length === 1 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={convertReservationToBlockBooking}
            >
              Convert to Block Booking
            </Button>
          )}
          <div>
            {retrospectiveBooking && (
            <Alert
              id="alert"
              severity="info"
              style={{ width: '100%' }}
            >
              Note, adding a retrospective booking can cause conflicts
              with other historical bookings.
            </Alert>
            )}
          </div>
        </>
        )}

        {/* Selected Sport */}
        {facility?.sport?.length > 1 && (
        <div style={{
          display: 'flex', flexDirection: 'column', margin: '1rem 0', gap: '1rem',
        }}
        >
          <Typography variant="body1">Select Sport?</Typography>
          <PBInput
            id="sport"
            type="select"
            label="Sport"
            options={facility?.sport?.map((sport) => ({ value: sport, label: sport }))}
            value={reservationData?.sport ?? facility?.sport?.[0]}
            onChange={(event) => updateSelectedSport(event.target.value)}
            style={{ width: 'max-content' }}
          />
        </div>
        )}
        <div className="reservation-creation-timetable">
          <div className="reservation-creation-timetable-picker">
            <TimetableInitialiser
              facilityInformation={{
                facilityId: facility.id,
                subFacilityId,
                companyId,
                pitchSplit,
              }}
              searchDate={searchDate}
              mobile={isMobile}
              manager
            />
          </div>
          <div className="reservation-creation-timetable-basket">
            <TimetableSelectedTimeslots combineSlots={combineSlots} />
          </div>
        </div>
      </>
    );

    return (
      <div>
        {renderFacilityPicker()}
        <br />
        <Responsive minDeviceWidth={992}>
          <div style={{ display: 'flex' }}>
            <div>
              {renderTimeTableWrapper()}
            </div>
            <div style={{ display: 'flex', marginLeft: '20px' }} />
          </div>
        </Responsive>
        <Responsive maxDeviceWidth={991}>
          <div style={{ height: '60vh' }}>
            {renderTimeTableWrapper(true)}
          </div>
        </Responsive>
      </div>
    );
  }
}

BookingStepperTimeslotPicker.propTypes = {
  changeSelectedPitchSplit: PropTypes.func.isRequired,
  doResetTimetableData: PropTypes.func.isRequired,
  facility: PropTypes.shape().isRequired,
  pitchSplit: PropTypes.bool.isRequired,
  retrospectiveBooking: PropTypes.bool.isRequired,
  subFacility: PropTypes.shape().isRequired,
  searchDate: PropTypes.string.isRequired,
  updateSelectedFacility: PropTypes.func.isRequired,
  updateRetrospectiveBooking: PropTypes.func.isRequired,
  updateSelectedSubFacility: PropTypes.func.isRequired,
  updateSelectedSport: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  resetReservation: PropTypes.func.isRequired,
  resetSelectedDuration: PropTypes.func.isRequired,
  reservationData: PropTypes.shape().isRequired,
  convertReservationToBlockBooking: PropTypes.func.isRequired,
  selectedTimeslots: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = (state) => ({
  facility: state.facilities.selectedFacility,
  pitchSplit: state.booking.selectedPitchSplit,
  selectedDuration: state.reservation.selectedDuration,
  retrospectiveBooking: state.reservation.retrospectiveBooking,
  reservationData: state.reservation,
  searchDate: state.timetable.searchDate,
  subFacility: state.facilities.selectedSubFacility,
  companyId: state.companies.companyInfo.id,
  selectedTimeslots: state.timetable.selectedTimeslots,
});

const mapDispatchToProps = (dispatch) => ({
  changeSelectedPitchSplit: (pitchSplit) => dispatch(
    reservationsActions.changeSelectedPitchSplit(pitchSplit),
  ),
  updateSelectedDuration: (duration) => dispatch(
    reservationsActions.updateSelectedDuration(duration),
  ),
  updateSelectedSport: (sport) => dispatch(
    reservationsActions.updateSelectedSport(sport),
  ),
  updateRetrospectiveBooking: (retrospectiveBooking) => dispatch(
    reservationsActions.updateRetrospectiveBooking(retrospectiveBooking),
  ),
  doResetTimetableData: () => dispatch(timetableActions.resetTimetableData()),
  resetSelectedDuration: () => dispatch(reservationsActions.resetSelectedDuration()),
  resetReservation: () => {
    dispatch(reservationsActions.resetSelectedDuration());
    dispatch(reservationsActions.updateSelectedEvent(null));
    dispatch(reservationsActions.updateSelectedAgeGroup(null));
    dispatch(reservationsActions.updateSelectedSport(null));
  },
  updateSelectedFacility: (selectedFacility) => dispatch(
    facilitiesActions.requestFacilityRetrieval(selectedFacility.id),
  ),
  updateSelectedSubFacility: (selectedSubFacility) => dispatch(
    facilitiesActions.updateSelectedSubFacility(selectedSubFacility),
  ),
  convertReservationToBlockBooking: () => dispatch(
    reservationsActions.convertReservationToBlockBooking(),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingStepperTimeslotPicker);
