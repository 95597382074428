import React, { useState } from 'react';
import {
  Button, Typography, Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import existingStripeAccountImg from '../../../images/connect-existing-stripe-account.jpg';

const ExistingStripeAccount = () => {
  const stripeUrl = process.env.REACT_APP_STRIPE_URL;
  const tryingStripeAuthorisation = useSelector(
    (state) => state.companies?.tryingStripeAuthorisation,
  );
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <>
      <Typography variant="body1" style={{ marginBottom: '1rem' }}>
        If you already have a Stripe account,
        please continue reading below for further instructions on what to do.
      </Typography>

      <Typography variant="body1" style={{ marginBottom: '1rem' }}>
        Setting up a Stripe account on Pitchbooking Manager
        will make it easy for your customers to make quick, no-hassle
        card payments online.
      </Typography>

      <Typography variant="body1" style={{ marginBottom: '1rem' }}>
        This includes casual online bookings, Subscription bookings and Invoices via
        Pitchbooking Payment Links.
      </Typography>

      <Typography variant="body1" style={{ marginBottom: '1rem', fontWeight: 'bold', fontStyle: 'italic' }}>
        Below is some helpful information
        if you are adding an existing stripe account.
      </Typography>

      <Typography variant="body1" style={{ marginBottom: '1rem' }}>
        We recommend that you create a second account
        (still linked to the first) via the drop-down menu in your Stripe dashboard.
      </Typography>

      <Typography variant="body1" style={{ marginBottom: '1rem' }}>
        This will separate your Pitchbooking payments
        from payments from other vendors you may already work with.
      </Typography>

      <Typography variant="body1" style={{ marginBottom: '1rem' }}>
        For further information from Stripe on why it is
        recommended to separate vendor accounts, click
        {' '}
        <a href="https://stripe.com/docs/account/multiple-a" target="_blank" rel="noreferrer">here</a>
      </Typography>

      <Typography variant="body1" style={{ marginBottom: '1rem', display: 'flex' }}>
        We
        <Typography variant="body1" color="error" style={{ fontWeight: 'bold', fontStyle: 'italic', padding: '0rem 0.3rem' }}>
          strongly advise
        </Typography>
        separating accounts to ensure your payouts from Pitchbooking
        are not mixed with payments from other vendors.
      </Typography>

      <div style={{
        display: 'flex', flexDirection: 'column', width: '75%', marginTop: '2rem',
      }}
      >

        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Step One - On your Stripe Dashboard</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              <ul>
                <li style={{ marginBottom: '1rem' }}>
                  Click on your account in the top left hand corner
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  Click + New Account
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  {'Enter a name - we recommend \'Your Business - Pitchbooking\' for easy identification'}
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  Click Create Account
                </li>
                <img src={existingStripeAccountImg} alt="existing-stripe-account" style={{ width: '50%' }} />
                <li style={{ marginBottom: '1rem' }}>
                  The button below will take you to an external page
                  where you can log in to your Stripe account.
                  Once logged in to Stripe, follow the steps outlined above.
                  You must then come back to Pitchbooking in order to complete Step 2.
                </li>
                <div style={{ margin: '0rem 1rem 1rem 1rem' }}>
                  <Button
                    id="manager-settings-set-up-stripe"
                    variant="contained"
                    color="primary"
                    style={{ color: '#fff', backgroundColor: '#47FEB4' }}
                    disabled={tryingStripeAuthorisation}
                    href="https://dashboard.stripe.com/login"
                    target="_blank"
                  >
                    Go to Stripe Dashboard
                  </Button>
                </div>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Step Two - On Pitchbooking Manager</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              <ul>
                <li style={{ marginBottom: '1rem' }}>
                  Click the button below
                </li>
                <div style={{ margin: '0rem 1rem 1rem 1rem' }}>
                  <Button
                    id="manager-settings-set-up-stripe"
                    variant="contained"
                    color="primary"
                    style={{ color: '#fff', backgroundColor: '#47FEB4' }}
                    disabled={tryingStripeAuthorisation}
                    href={stripeUrl}
                    target="_blank"
                  >
                    Set Up With Stripe
                  </Button>
                </div>

                <li style={{ marginBottom: '1rem' }}>
                  Select your newly created account from the list and CONNECT to Pitchbooking.
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <Typography variant="body1" style={{ margin: '2rem 0rem' }}>
                    This will bring you back to the Manager Dashboard
                    and you will see a message saying:
                    {' '}
                    <Typography variant="body1" color="secondary" style={{ fontWeight: 'bold' }}>
                      {'\'Connected with Stripe\''}
                    </Typography>
                    Your account is now connected and you are ready to accept payments.
                  </Typography>
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default ExistingStripeAccount;
