/* eslint-disable camelcase */
import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const geoCodePlaces = async (placeId) => {
  const geocoder = new window.google.maps.Geocoder();
  let result = {};
  if (placeId) {
    return new Promise((resolve) => {
      geocoder.geocode({ placeId }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            console.log(results);
            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();

            const { address_components } = results[0];
            const filterAddressComponents = (type) => address_components.find((component) => component.types.includes(type))?.long_name || '';
            const fullAddress = {
              addressLineOne: `${filterAddressComponents('street_number')} ${filterAddressComponents('route')}`,
              addressLineTwo: filterAddressComponents('locality'),
              city: filterAddressComponents('postal_town'),
              postcode: filterAddressComponents('postal_code'),
              country: filterAddressComponents('country'),
            };

            result = { lat, lng, fullAddress };
            resolve(result);
          }
        }
      });
    });
  }
  return {};
};

const GoogleMapsAutoComplete = (props) => {
  const { onChange, locationDescription } = props;
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const classes = useStyles();
  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyBOxA9fKt57_US4_skaWJir9sTJaEZpM44&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () => throttle((request, callback) => {
      autocompleteService.current.getPlacePredictions(request, callback);
    }, 200),
    [],
  );

  useEffect(() => {
    if (!locationDescription) {
      return;
    }

    setOptions([{ description: locationDescription }]);
    setValue({ description: locationDescription });
  }, [locationDescription]);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '' || inputValue.length < 3) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      style={{ width: 300 }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      getOptionSelected={(option, value) => option.description === value.description}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={async (event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue) {
          const { lat, lng, fullAddress } = await geoCodePlaces(newValue.place_id);
          onChange({
            location: { lat, lng },
            locationDescription: newValue.description,
            fullAddress,
          });
        } else {
          onChange({
            location: null,
            locationDescription: '',
          });
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue !== locationDescription) {
          setInputValue(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField {...params} label={value ? 'Change location' : 'Add a location'} variant="outlined" fullWidth />
      )}
      renderOption={(option) => {
        if (!option.structured_formatting) {
          return null;
        }

        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = matches && matches.length > 0 ? parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        ) : [];

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part) => (
                <span key={part.text} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

GoogleMapsAutoComplete.defaultProps = {
  locationDescription: '',
};

GoogleMapsAutoComplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  locationDescription: PropTypes.string,
};

export default GoogleMapsAutoComplete;
