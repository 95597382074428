/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import moment from 'moment';
import {
  ChevronLeft,
  ChevronRight,
  Search,
  Clear,
  UnfoldMore,
  Delete,
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useToast } from '../../../../hooks';
import { updateUserMembershipExclusionsService } from '../../../../services/membershipsServices';
import { ExclusionModal } from './ExclusionModal';

export const ExclusionTable = ({
  userMembership,
  exclusions,
  ...props
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const [isExclusionModalOpen, setIsExclusionModalOpen] = useState(false);
  const [updatedExclusions, setUpdatedExclusions] = useState([]);

  const mutateExclusions = useMutation({
    mutationFn: async (exclusions) => {
      await updateUserMembershipExclusionsService(
        userMembership.membershipId, userMembership.userId, exclusions,
      );
    },
    onSuccess: () => {
      setIsExclusionModalOpen(false);
      toast.trigger({ type: 'success', message: 'Exclusions updated successfully' });
    },
    onError: (error) => {
      console.error(error);
      toast.trigger({ type: 'error', message: 'Failed to update exclusions' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['userMembership', userMembership.membershipId, userMembership.userId]);
    },
  });

  useEffect(() => {
    setUpdatedExclusions(exclusions.map((x) => moment(x.date).format('YYYY-MM-DD')));
  }, [exclusions]);

  return (
    <>
      <div {...props}>
        <MaterialTable
          columns={[
            {
              title: 'Date',
              field: 'date',
              render: (rowData) => moment(rowData.date).format('MMMM Do YYYY'),
            },
            {
              render: (rowData) => (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => mutateExclusions.mutate(
                      updatedExclusions.filter((x) => x !== moment(rowData.date).format('YYYY-MM-DD')),
                    )}
                    disabled={mutateExclusions.isLoading}
                  >
                    <Delete />
                  </Button>
                </div>
              ),
              sorting: false,
            },
          ]}
          data={exclusions}
          icons={{
            NextPage: () => <ChevronRight />,
            PreviousPage: () => <ChevronLeft />,
            Search: () => <Search />,
            ResetSearch: () => <Clear />,
            SortArrow: () => <UnfoldMore style={{ fill: '#4581E2' }} />,
          }}
          actions={[
            {
              icon: () => <Button variant="contained">Add/Edit</Button>,
              tooltip: 'Add/Edit Exclusion',
              isFreeAction: true,
              onClick: () => setIsExclusionModalOpen(true),
            },
          ]}
          title="Exclusions"
          options={{
            showTitle: true,
            sorting: true,
            selection: false,
            emptyRowsWhenPaging: false,
            showFirstLastPageButtons: false,
            exportButton: false,
            search: false,
            draggable: false,
            pageSize: 5,
            pageSizeOptions: [],
            tableLayout: 'fixed',
            headerStyle: {
              backgroundColor: '#efefef',
              fontWeight: 'bold',
            },
            actionsColumnIndex: -1,
          }}
        />
      </div>

      <ExclusionModal
        isOpen={isExclusionModalOpen}
        onClose={() => setIsExclusionModalOpen(false)}
        startDate={userMembership.createdAt}
        endDate={userMembership.validTo}
        selectedDates={updatedExclusions}
        onChange={(date) => {
          if (updatedExclusions.includes(date)) {
            setUpdatedExclusions(updatedExclusions.filter((x) => x !== date));
          } else {
            setUpdatedExclusions([...updatedExclusions, date]);
          }
        }}
        onSubmit={() => mutateExclusions.mutate(updatedExclusions)}
        isDisabled={mutateExclusions.isLoading}
      />
    </>
  );
};
