import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const SiteSelector = ({
  selectedSite, sites, onChange,
}) => (
  <div style={{ marginTop: 17 }}>
    <FormControl variant="outlined">
      <InputLabel id="demo-simple-select-outlined-label">Sites</InputLabel>
      <Select
        displayEmpty
        value={selectedSite && selectedSite}
        onChange={onChange}
        name="Site"
        label="Sites"
      >
        {sites.map((site) => (
          <MenuItem value={site.id}>{site.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);

SiteSelector.propTypes = {
  selectedSite: PropTypes.shape().isRequired,
  sites: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

export default (SiteSelector);
