/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import '../../styles/payments.css';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import TableContainer from '@material-ui/core/TableContainer';
import DeleteDialog from '../accessRestrictions/components/DeleteAccessRestrictionDialog';
import * as helper from '../../helpers/index';

const LeaguesTable = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [schedulesTableState, setSchedulesTableState] = useState(
    {
      page: 0,
      rowsPerPage: 25,
      selectedUserEmails: [],
      sendMessageRequested: false,
      changePasswordOpen: false,
    },
  );

  const { leagues } = props;

  const generateValidityString = (row) => {
    const { accessRestrictions } = row;

    if (accessRestrictions && accessRestrictions.length !== 0) {
      const validity = {
        validFrom: accessRestrictions[0].validFrom,
        validTo: accessRestrictions[0].validTo,
      };

      return helper.generateValidityText(validity);
    }
    return 'No start/end dates have been set for this league';
  };

  return (
    <>
      <div className="table-section">
        <TableContainer
          style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden' }}
          component={Paper}
        >
          <MaterialTable
            data={leagues}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            title="Pitchbooking Events"
            icons={{
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              Export: () => <SaveAlt />,
              ResetSearch: () => <Clear />,
              SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
            }}
            columns={[
              {
                title: 'Name',
                field: 'name',
                render: (row) => (
                  <>
                    <a href={`/leagues/${row.id}`}>
                      {row.name}
                    </a>
                  </>
                ),
              },
              {
                title: 'Max Teams',
                render: (row) => (row.maxTeams && (
                  <>
                      {' '}
                    {row.maxTeams}
                    {' '}
                    Teams
                  </>
                )),
              },
              {
                title: 'Site',
                render: (row) => (row.site && (
                  <>
                    {row.site?.name}
                  </>
                )),
                customFilterAndSearch: (term, row) => {
                  const siteName = row.site?.name || '';
                  const siteNameString = siteName.toString().toLowerCase();
                  return siteNameString.includes(term.toLowerCase());
                },
              },
              {
                title: 'Date Range',
                render: (row) => (generateValidityString(row)),
              },
              {
                title: 'Close Date',
                render: (row) => (row.createdAt && (
                  <>
                    {moment(row.closingDate).format('LL')}
                  </>
                )),
              },
              {
                title: 'Live',
                field: 'status',
                filtering: false,
                render: (row) => {
                  const statusIcons = row.visible
                    ? (<div className="keys activeKey" style={{ marginLeft: '10px' }} />)
                    : (<div className="keys inactiveKey" style={{ marginLeft: '10px' }} />);
                  return statusIcons;
                },
              },
              {
                title: 'Delete',
                field: 'delete',
                filtering: false,
                render: (row) => (
                  <DeleteDialog
                    name={row.name}
                    bodyTag="League"
                    type="LEAGUE"
                    leagueId={row.id}
                  />
                ),
              },
            ]}
            options={{
              search: true,
              selection: false,
              emptyRowsWhenPaging: false,
              tableLayout: 'auto',
              exportButton: false,
              exportFileName: 'Pitchbooking Users',
              pageSize: 50,
              pageSizeOptions: [10, 25, 50, 100],
              showFirstLastPageButtons: false,
              showTitle: false,
              headerStyle: {
                backgroundColor: '#efefef',
                fontWeight: 'bold',
              },
            }}
            localization={{
              toolbar: {
                exportTitle: 'Download',
              },
            }}
          />
        </TableContainer>
      </div>
    </>
  );
};

LeaguesTable.propTypes = {
  leagues: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default (LeaguesTable);
