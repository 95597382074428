import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import { withStyles } from '@material-ui/core/styles';
import {
  Divider, TableCell, TableRow, TableContainer, TableHead, TableBody, Table, Typography, Button,
} from '@material-ui/core';
import {
  convertFromRaw, EditorState, convertToRaw, ContentState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import TimePicker from 'rc-time-picker';
import DeleteIcon from '@material-ui/icons/Delete';
import { useMutation } from '@tanstack/react-query';
import EventAddonDialog from './EventAddonDialog';
import GoogleMapsAutoComplete from '../../../components/GoogleMapsAutoComplete';
import MenuHeading from '../../../components/MenuHeading';
import PBButton from '../../../components/Button';
import FacilityAndSubFacilityPicker from '../../facilities/components/FacilityAndSubFacilityPicker';
import * as facilityActions from '../../../reducers/facilitiesReducer';
import * as calendarActions from '../../../reducers/calendarReducer';
import * as formActions from '../../../reducers/formsReducer';
import { EventBookingModifierDialog } from './EventBookingModifierDialog';
import { deleteEventBookingModifier } from '../../../services/eventsServices';
import { useCompany, useToast } from '../../../hooks';

const styles = () => ({
  root: {
    minWidth: 400,
  },
  button: {
    minWidth: 0,
    marginTop: 10,
  },
});

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  description: Yup.string().required('Description is required'),
  isParentEvent: Yup.boolean(),
  maximumCapacity: Yup.number().nullable()
    .when('isParentEvent', {
      is: false,
      then: Yup.number().min(1, 'Value must be at least 1').required('Maximum Capacity is required')
        .typeError('Please provide a valid maximum capacity'),
    }),
  price: Yup.number().nullable().when('isParentEvent', {
    is: false,
    then: Yup.number().min(0, 'Value must be 0 or above').required('Price is required')
      .typeError('Please provide a valid price'),
  }),
  isOnSiteLocation: Yup.boolean(),
  siteId: Yup.string().nullable().when(['isParentEvent', 'isOnSiteLocation'], {
    is: (isParentEvent, isOnSiteLocation, isOnlineEvent) => !isParentEvent && isOnlineEvent,
    then: Yup.string().required('Site is required').typeError('Please provide a valid site'),
  }),
  isOnlineEvent: Yup.boolean(),
  facilities: Yup.array(),
  location: Yup.object({ lat: Yup.string(), lng: Yup.string() }).nullable().when(['isParentEvent', 'isOnSiteLocation'], {
    is: (
      isParentEvent,
      isOnSiteLocation,
      isOnlineEvent,
    ) => !isParentEvent && !isOnSiteLocation && isOnlineEvent,
    then: Yup.object({ lat: Yup.string(), lng: Yup.string() }).required('Location is required'),
  }),
  locationDescription: Yup.string().nullable().when(['isParentEvent', 'isOnSiteLocation'], {
    is: (
      isParentEvent,
      isOnSiteLocation,
      isOnlineEvent,
    ) => !isParentEvent && !isOnSiteLocation && isOnlineEvent,
    then: Yup.string().required('Location Description is required'),
  }),
  startTime: Yup.string().nullable().when('isParentEvent', {
    is: false,
    then: Yup.string().required('Start time is required'),
  }),
  endTime: Yup.string().nullable().when('isParentEvent', {
    is: false,
    then: Yup.string().required('End time is required'),
  }),
  startDate: Yup.string().nullable().when('isParentEvent', {
    is: false,
    then: Yup.string().required('Start date is required'),
  }),
  endDate: Yup.string().nullable().when('isParentEvent', {
    is: false,
    then: Yup.string().required('End date is required'),
  }),
  isSetCloseDate: Yup.boolean(),
  closeDate: Yup.string().nullable().when(['isParentEvent', 'isSetCloseDate'], {
    is: (subevents, isSetCloseDate) => subevents && isSetCloseDate,
    then: Yup.string().required('Close date is required'),
  }),
  closeTime: Yup.string().nullable().when(['isParentEvent', 'isSetCloseDate'], {
    is: (subevents, isSetCloseDate) => subevents && isSetCloseDate,
    then: Yup.string().required('Close time is required'),
  }),
  emailInstructions: Yup.string().nullable(),
  status: Yup.string().required('Status is required'),
  isInternal: Yup.boolean(),
  isCustomFormRequired: Yup.boolean(),
  accessRestrictions: Yup.array().required('Access Restrictions are required'),
  addons: Yup.array().required('Addons are required'),
  costCode: Yup.string().nullable(),
  taxExempt: Yup.boolean(),
  bookingModifers: Yup.array().nullable(),
});

const EventDetailsForm = ({ onValuesChanged }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { id: companyId, currency, currencySym } = useCompany();

  const {
    values, setFieldValue, errors, touched, handleChange, handleBlur,
  } = useFormikContext() ?? {};
  const isEditing = !!values?.id;

  const { sites } = useSelector((state) => state.calendar);
  const { companyFacilities: facilities } = useSelector((state) => state.facilities);
  const forms = useSelector((state) => state.forms.forms);

  const [focusedInput, setFocusedInput] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [editorState, setEditorState] = useState();
  const [formattedForms, setFormattedForms] = useState([]);

  const { mutate: deleteBookingModifierMutation } = useMutation({
    mutationFn: async (data) => {
      const res = await deleteEventBookingModifier(companyId, values.id, data);

      if (res.status !== 200) {
        throw new Error('Unable to delete booking modifier!');
      }

      return res.data;
    },
    onError: (error) => {
      toast.trigger({
        type: 'error',
        message: error.message,
      });
    },
    onSuccess: (data, vars) => {
      setFieldValue('bookingModifiers', values.bookingModifiers.filter((x) => x.id !== vars));
      toast.trigger({
        type: 'success',
        message: 'Booking Modifier deleted successfully',
      });
    },
  });

  useEffect(() => {
    if (forms) {
      const formatted = forms.map((form) => ({
        label: form.name,
        value: form.id,
      }));
      formatted.unshift({ label: 'Select Form', value: null });
      setFormattedForms(formatted);
    }
  }, [forms]);

  useEffect(() => {
    dispatch(calendarActions.getCompanySites());
    dispatch(facilityActions.requestFacilitiesRetrieval());
    dispatch(formActions.getCompanyFormsRequest());
  }, []);

  useEffect(() => {
    onValuesChanged(
      { isCustomFormRequired: values.isCustomFormRequired, formId: values.formId },
    );
  }, [values.isCustomFormRequired, values.formId]);

  useEffect(() => {
    if (!values.location) {
      if (sites.length > 0) setFieldValue('isOnSiteLocation', true);
    }
  }, [sites]);

  useEffect(() => {
    if (values.description) {
      try {
        const contentState = convertFromRaw(JSON.parse(values.description));
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      } catch (err) {
        setEditorState(
          EditorState.createWithContent(ContentState.createFromText(values.description)),
        );
      }
    } else {
      setEditorState(
        EditorState.createEmpty(),
      );
    }
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <PBInput
        id="name"
        label="Name"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        errorMessage={touched.name && errors.name}
        isError={touched.name && Boolean(errors.name)}
        required
      />

      <div style={{
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '5px',
        padding: '6px 8px',
        borderColor: 'rgba(0, 0, 0, 0.23)',
      }}
      >
        <Editor
          editorState={editorState}
          onEditorStateChange={(editorState) => {
            setEditorState(editorState);
            setFieldValue('description', JSON.stringify(convertToRaw(editorState.getCurrentContent())));
          }}
          toolbar={{
            options: ['inline', 'blockType', 'list', 'link', 'history'],
            inline: {
              options: ['bold', 'italic'],
            },
            blockType: {
              inDropdown: true,
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
            },
          }}
          placeholder="Enter your about description here..."
          stripPastedStyles
        />
      </div>
      <ConditionallyVisible condition={errors.description}>
        <Typography variant="caption" color="error">
          {errors.description}
        </Typography>
      </ConditionallyVisible>

      <Divider />

      {/* Event Occurrence */}
      <ConditionallyVisible condition={!isEditing}>
        <ConditionallyVisible condition={values.parentEventId === null}>
          <PBInput
            id="isParentEvent"
            label="More than one occurrence or location for this event?"
            type="toggle"
            value={values.isParentEvent}
            onChange={handleChange}
            onBlur={handleBlur}
            errorMessage={touched.isParentEvent && errors.isParentEvent}
            isError={touched.isParentEvent && Boolean(errors.isParentEvent)}
            disabled={values.isParentEvent && values.children && values.children.length > 0}
          />
          <small>
            If this toggle is switched on, all of the remaining options below will disappear.
            Click SUBMIT to complete the first step of event creation.
            You will then be prompted to create the first event occurrence at the next screen.
            An event occurrence can be different dates, locations or any other bookable option.
          </small>
          <Divider />
        </ConditionallyVisible>
      </ConditionallyVisible>

      <ConditionallyVisible condition={!values.isParentEvent}>
        {/* Capacity & Price */}
        <div style={{ display: 'flex', gap: '1rem' }}>
          <PBInput
            id="maximumCapacity"
            label="Maximum Capacity"
            type="number"
            value={values.maximumCapacity}
            onChange={handleChange}
            onBlur={handleBlur}
            errorMessage={touched.maximumCapacity && errors.maximumCapacity}
            isError={touched.maximumCapacity && Boolean(errors.maximumCapacity)}
            required
          />

          <PBInput
            id="price"
            type="currency"
            currencySymbol={currency ? getCurrencySymbol(currency) : '£'}
            label="Price"
            value={values.price}
            onChange={handleChange}
            onBlur={handleBlur}
            errorMessage={touched.price && errors.price}
            isError={touched.price && Boolean(errors.price)}
            required
          />
        </div>

        {/* Toggle Event on Site */}
        <ConditionallyVisible condition={!isEditing && (sites && sites.length > 0)}>
          <PBInput
            id="isOnSiteLocation"
            label="Will this event be held on site?"
            type="toggle"
            value={values.isOnSiteLocation}
            onChange={(e) => {
              handleChange(e);
              setFieldValue('siteId', null);
              setFieldValue('facilities', []);
              setFieldValue('location', null);
              setFieldValue('locationDescription', null);
            }}
            onBlur={handleBlur}
            errorMessage={touched.isOnSiteLocation && errors.isOnSiteLocation}
            isError={touched.isOnSiteLocation && Boolean(errors.isOnSiteLocation)}
          />
        </ConditionallyVisible>

        {/* Event held online */}
        <PBInput
          id="isOnlineEvent"
          label="Will this event be held online?"
          type="toggle"
          value={values.isOnlineEvent}
          onChange={(e) => {
            handleChange(e);
            setFieldValue('siteId', null);
            setFieldValue('facilities', []);
            setFieldValue('isOnSiteLocation', false);
          }}
          onBlur={handleBlur}
          errorMessage={touched.isOnlineEvent && errors.isOnlineEvent}
          isError={touched.isOnlineEvent && Boolean(errors.isOnlineEvent)}
        />

        {/* Site/Location Pickers */}
        <ConditionallyVisible condition={!values.isOnlineEvent}>
          <AlternativelyVisible condition={sites && sites.length > 0 && values.isOnSiteLocation}>
            <>
              <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '1rem',
              }}
              >
                <PBInput
                  id="siteId"
                  label="Site"
                  type="select"
                  options={sites.map((site) => ({
                    value: site.id,
                    label: site.name,
                  }))}
                  value={values.siteId}
                  onChange={(event) => {
                    setFieldValue('siteId', event.target.value);
                    setFieldValue('facilities', []);
                  }}
                  onBlur={handleBlur}
                  errorMessage={touched.siteId && errors.siteId}
                  isError={touched.siteId && Boolean(errors.siteId)}
                  required={values.isOnSiteLocation}
                />
              </div>

              <ConditionallyVisible condition={facilities.length > 0}>
                <div>
                  <div style={{ marginBottom: '0.5rem' }}>
                    <small>
                      This event can be hosted on your own facilities; doing so will create a
                      closure on each facility selected.
                    </small>
                  </div>

                  <FacilityAndSubFacilityPicker
                    facilities={facilities.filter((x) => x.siteId === values.siteId)}
                    checkedFacilities={values.facilities ?? []}
                    onUpdate={(e) => {
                      setFieldValue('facilities', e);
                    }}
                    editingAccessRestriction={false}
                  />
                </div>
              </ConditionallyVisible>
            </>

            <GoogleMapsAutoComplete
              locationDescription={values.locationDescription}
              onChange={(newValue) => {
                setFieldValue('location', newValue.location);
                setFieldValue('locationDescription', newValue.locationDescription);
              }}
              required
            />
          </AlternativelyVisible>
        </ConditionallyVisible>

        <ConditionallyVisible condition={errors.location}>
          <Typography variant="caption" color="error">
            Location is required
          </Typography>
        </ConditionallyVisible>

        {/* Dates */}
        <div>
          <Divider />

          <div style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '1rem',
          }}
          >
            <div style={{ gridColumn: '1 / span 2' }}>
              <MenuHeading title="Dates" />
              <DateRangePicker
                startDateId="eventStartDate"
                endDateId="eventEndDate"
                startDate={
                  values.startDate !== null ? moment(values.startDate) : values.startDate
                }
                endDate={values.endDate !== null ? moment(values.endDate) : values.endDate}
                onDatesChange={({ startDate, endDate }) => {
                  setFieldValue('startDate', startDate !== null ? startDate.format('YYYY-MM-DD') : startDate);
                  setFieldValue('endDate', endDate !== null ? endDate.format('YYYY-MM-DD') : endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={(newFocusedInput) => setFocusedInput(newFocusedInput)}
                minimumNights={0}
                numberOfMonths={1}
                displayFormat="ddd, DD/MM/YYYY"
                noBorder
              />
            </div>
            {(touched.startDate || touched.endDate) && (errors.startDate || errors.endDate) && (
              <Typography variant="caption" color="error" style={{ gridColumn: '1 / span 2' }}>
                Please provide a valid date range.
              </Typography>
            )}
            <div>
              <MenuHeading title="Start Time" />
              <TimePicker
                value={values.startTime ? moment(values.startTime) : values.startTime}
                showSecond={false}
                minuteStep={5}
                clearIcon={null}
                allowEmpty
                onChange={(value) => {
                  setFieldValue('startTime', moment(value).startOf('minute').format('YYYY-MM-DD HH:mm:ss'));
                }}
              />
            </div>
            <div>
              <MenuHeading title="End Time" />
              <TimePicker
                value={values.endTime ? moment(values.endTime).add(1, 's') : values.endTime}
                showSecond={false}
                minuteStep={5}
                clearIcon={null}
                allowEmpty
                onChange={(value) => {
                  setFieldValue('endTime', moment(value).startOf('minute').subtract(1, 's').format('YYYY-MM-DD HH:mm:ss'));
                }}
              />
            </div>

            {(touched.startTime || touched.endTime) && (errors.startTime || errors.endTime) && (
              <Typography variant="caption" color="error" style={{ gridColumn: '1 / span 2' }}>
                Please provide a valid start and end time.
              </Typography>
            )}
          </div>

          <div style={{ marginTop: '1rem' }}>
            <PBInput
              id="isSetCloseDate"
              type="toggle"
              label="Do you want to set a close date?"
              value={values.isSetCloseDate}
              onChange={(e) => {
                handleChange(e);
                if (e.target.checked) {
                  setFieldValue('closeDate', moment().hour(12).minute(0).format('YYYY-MM-DD HH:mm'));
                } else {
                  setFieldValue('closeDate', null);
                }
              }}
              onBlur={handleBlur}
              errorMessage={touched.isSetCloseDate && errors.isSetCloseDate}
              isError={touched.isSetCloseDate && Boolean(errors.isSetCloseDate)}
            />

            <ConditionallyVisible condition={values.isSetCloseDate}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                {/* If set close date and time will get combined on submit */}
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  {/* Close Date */}
                  <div>
                    <MenuHeading title="Close Date" />
                    <SingleDatePicker
                      date={values.closeDate ? moment(values.closeDate) : moment()}
                      onDateChange={(date) => {
                        setFieldValue('closeDate', moment(date).format('YYYY-MM-DD HH:mm'));
                      }}
                      focused={isFocused}
                      onFocusChange={({ focused }) => setIsFocused(focused)}
                      displayFormat="ddd, DD/MM/YY"
                      numberOfMonths={1}
                      isOutsideRange={() => false}
                      fullWidth
                      noBorder
                    />
                  </div>

                  {/* Close Time */}
                  <div>
                    <MenuHeading title="Close Time" />
                    <TimePicker
                      value={values.closeTime ? moment(values.closeTime).add(1, 's') : values.closeTime}
                      showSecond={false}
                      minuteStep={5}
                      clearIcon={null}
                      allowEmpty
                      onChange={(value) => {
                        setFieldValue('closeTime', moment(value).startOf('minute').subtract(1, 's').format('YYYY-MM-DD HH:mm:ss'));
                      }}
                      style={{ marginTop: '1rem' }}
                    />
                  </div>
                </div>
                <small>
                  The close date represents when you want to stop taking bookings
                  for the event (i.e. 1 day before the event starts @ 12:00pm)
                </small>
              </div>
            </ConditionallyVisible>
          </div>
        </div>

        {/* Additional Email Instructions */}
        <div>
          <Divider style={{ marginBottom: '1rem' }} />

          <PBInput
            id="emailInstructions"
            label="Additional Email Instructions"
            type="textarea"
            value={values.emailInstructions}
            onChange={handleChange}
            onBlur={handleBlur}
            errorMessage={touched.emailInstructions && errors.emailInstructions}
            isError={touched.emailInstructions && Boolean(errors.emailInstructions)}
          />
        </div>

        {/* Cost codes */}
        <div>
          <PBInput
            id="costCode"
            label="Cost Code"
            type="text"
            value={values.costCode}
            onChange={handleChange}
            onBlur={handleBlur}
            errorMessage={touched.costCode && errors.costCode}
            isError={touched.costCode && Boolean(errors.costCode)}
          />
        </div>

        <div>
          <PBInput
            id="taxExempt"
            type="toggle"
            label="Is this event Tax Exempt?"
            value={values.taxExempt}
            onChange={(e) => {
              setFieldValue('taxExempt', e.target.checked);
            }}
          />
        </div>

        {/* Addons & Access Restrictions */}
        <ConditionallyVisible condition={isEditing}>
          {/* Addons */}
          <Divider />
          <div>
            <MenuHeading title="Addons" />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Visible</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.addons.map((addon) => (
                    <TableRow>
                      <TableCell>{addon.name}</TableCell>
                      <TableCell>{addon.type}</TableCell>
                      <TableCell>{addon.quantity === null ? 'Unlimited' : addon.quantity}</TableCell>
                      <TableCell>
                        {addon.visible === true
                          ? (<div classID="keys activeKey" />)
                          : (<div classID="keys inactiveKey" />)}
                      </TableCell>
                      <TableCell>
                        {addon.status}
                      </TableCell>
                      <TableCell>
                        <PBButton
                          title="Remove"
                          color="error"
                          onClick={() => setFieldValue('addons', values.addons.filter((x) => x.id !== addon.id))}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <EventAddonDialog
              onAddonsUpdated={(e) => setFieldValue('addons', e)}
              addons={values.addons}
            />
          </div>

          {/* Booking Modifiers */}
          <Divider />
          <div>
            <MenuHeading title="Booking Modifiers" />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.bookingModifiers?.map((x) => (
                    <TableRow key={x.id}>
                      <TableCell>{x.name}</TableCell>
                      <TableCell>
                        {currencySym}
                        {x.priceModifiers?.[0]?.value?.toFixed(2)}
                      </TableCell>
                      <TableCell>
                        <Button onClick={() => deleteBookingModifierMutation(x.id)}>
                          <DeleteIcon color="error" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <EventBookingModifierDialog
              eventId={values.id}
              onUpdate={(data) => {
                setFieldValue('bookingModifiers', [...(values.bookingModifiers ?? []), data]);
              }}
            />
          </div>

          {/* Access Restrictions */}
          <Divider />
          <div>
            <MenuHeading title="Access Restrictions" />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.accessRestrictions?.flatMap((ar) => ar.facilities.map((x) => (
                    <TableRow>
                      <TableCell>{x.name}</TableCell>
                      <TableCell>
                        {ar.status}
                      </TableCell>
                    </TableRow>
                  )))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <Divider />
        </ConditionallyVisible>

      </ConditionallyVisible>

      <PBInput
        id="status"
        type="toggle"
        label="Visible on Partner Page?"
        value={values.status === 'ACTIVE'}
        onChange={(e) => {
          setFieldValue('status', e.target.checked ? 'ACTIVE' : 'INACTIVE');
        }}
      />
      <PBInput
        id="isInternal"
        type="toggle"
        label="Event is open for bookings?"
        value={!values.isInternal}
        onChange={(e) => setFieldValue('isInternal', !e.target.checked)}
      />

      <ConditionallyVisible condition={values.type === 'EVENT'}>
        <PBInput
          id="isCustomFormRequired"
          type="toggle"
          label="Create a bespoke form to collect participant data?"
          value={values.isCustomFormRequired}
          onChange={(e) => {
            setFieldValue('isCustomFormRequired', e.target.checked);
            if (!e.target.checked) {
              setFieldValue('formId', null);
            }
          }}
          onBlur={handleBlur}
          errorMessage={touched.isCustomFormRequired && errors.isCustomFormRequired}
          isError={touched.isCustomFormRequired && Boolean(errors.isCustomFormRequired)}
        />
      </ConditionallyVisible>
      <ConditionallyVisible condition={values.isCustomFormRequired}>
        Select an existing form or click next to create a new one.
        <PBInput
          id="formId"
          type="select"
          placeholder="Select Form"
          options={formattedForms}
          label="Select Form"
          value={values.formId}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={touched.forms && errors.forms}
          isError={touched.forms && Boolean(errors.forms)}
        />

      </ConditionallyVisible>
    </div>
  );
};

EventDetailsForm.propTypes = {
  onValuesChanged: PropTypes.func.isRequired,
};

export default (withStyles(styles())(EventDetailsForm));
