import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function getSubscriptionsService(companyId, dates, status) {
  let fromDate;
  let toDate = null;
  let url = `/api/companies/${companyId}/subscriptions?status=${status}`;
  if (dates) {
    fromDate = dates.fromDate;
    toDate = dates.toDate;
    url = `/api/companies/${companyId}/subscriptions?from=${fromDate}&to=${toDate}&status=${status}`;
  }

  const response = await httpHelpers.authenticatedGetRequest(url);

  return response;
}

export async function addSubscriptionNoteService(companyId, subscriptionId, note, date) {
  const body = { note, date };
  const url = `/api/companies/${companyId}/subscriptions/${subscriptionId}/notes`;
  const response = await httpHelpers.authenticatedPostRequest(url, body);

  return response;
}

export async function getSubscriptionsReservationsService(
  companyId, dates, facilities = [], status,
) {
  let fromDate = null;
  let toDate = null;
  if (dates) {
    fromDate = dates.fromDate;
    toDate = dates.toDate;
  }
  const url = `/api/companies/${companyId}/subscriptionReservations?from=${fromDate}&to=${toDate}&facilities=${facilities}&status=${status}`;
  const response = await httpHelpers.authenticatedGetRequest(url);

  return response;
}

export async function getSubscriptionService(companyId, id) {
  const url = `/api/companies/${companyId}/subscriptions/${id}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function createExclusionService(companyId, id, exclusions) {
  const body = { exclusions };
  const url = `/api/companies/${companyId}/accessRestrictions/${id}/exclusions`;
  const response = await httpHelpers.authenticatedPostRequest(url, body);

  return response;
}
export async function editSubscriptionService(companyId, id, editedSubscription) {
  const body = { editedSubscription };
  const url = `/api/companies/${companyId}/subscriptions/${id}`;
  const response = await httpHelpers.authenticatedPutRequest(url, body);

  return response;
}

export async function deleteExclusionService(companyId, id, subscriptionId) {
  const url = `/api/companies/${companyId}/exclusions/${id}`;
  const body = { subscriptionId };
  const response = await httpHelpers.authenticatedPostRequest(url, body);

  return response;
}

export async function pauseSubscriptionsService(companyId, reqBody) {
  const url = `/api/companies/${companyId}/subscriptions/pause`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);

  return response;
}

export async function createSubscriptionService(companyId, subscriptionData, checkForConflicts) {
  const body = { ...subscriptionData, checkForConflicts };
  const url = `/api/companies/${companyId}/subscriptions`;
  const response = await httpHelpers.authenticatedPostRequest(url, body);

  return response;
}
export async function deleteSubscriptionServices(subscriptionId) {
  const body = { status: 'DELETED' };
  const url = `/api/subscriptions/${subscriptionId}/delete`;
  const response = await httpHelpers.authenticatedPostRequest(url, body);

  return response;
}

export async function refundSubscriptionServices(companyId, reqBody) {
  const url = `/api/companies/${companyId}/subscriptions/refund`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}
