import React from 'react';
import PropTypes from 'prop-types';

import MenuDrawerStyles from './styles';

const MenuDrawerNestedLink = ({ children }) => React.cloneElement(
  children, { icon: 'arrowForwardIcon', className: MenuDrawerStyles().nested },
);

MenuDrawerNestedLink.propTypes = {
  children: PropTypes.shape().isRequired,
};

export default MenuDrawerNestedLink;
