import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function getPayoutsService(companyId) {
  const url = `/api/companies/${companyId}/payouts`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function getPayoutService(companyId, id) {
  const url = `/api/companies/${companyId}/payouts/${id}`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function recalculatePayoutService(companyId, payoutId) {
  const body = { payoutId };
  const url = `/api/companies/${companyId}/recalculate-payout`;
  const response = await httpHelpers.authenticatedPostRequest(url, body);
  return response;
}
