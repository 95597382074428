import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  LinearProgress,
  Dialog,
  Button,
  DialogActions,
  Typography,
} from '@material-ui/core';
import { DeleteTwoTone } from '@material-ui/icons';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as membershipsActions from '../../../reducers/accessRestrictionsReducer';

class DeleteMembershipDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      isLoading: false,
      errorMessage: null,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(props) {
    this.setState({
      open: props.open,
    });
  }

  handleFeedback = (message) => {
    this.setState({
      isLoading: false,
    });
    if (message === 'success') {
      this.setState({
        succeeded: true,
      });
    } else {
      this.setState({
        errorMessage: 'An unexpected error occurred. Try again or contact Pitchbooking.',
      });
    }
  }

  handleRequestClose = () => {
    const { succeeded } = this.state;
    const { forceOpen, success } = this.props;
    const didSucceed = succeeded;
    if (!forceOpen) {
      this.setState({
        open: false,
        succeeded: false,
        errorMessage: '',
      }, () => {
        if (didSucceed) {
          success();
        }
      });
    }
  };

  handleTextChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      this.handleDetailsSubmit();
    }
  };

  handleDetailsSubmit = () => {
    const { updateMembership, membershipID } = this.props;
    this.setState({
      isLoading: true,
    });

    const reqBody = {
      status: 'DELETED',
    };
    updateMembership(membershipID, reqBody);
    this.setState({
      isLoading: false,
    });
  }

  render() {
    const {
      membershipName, hasActiveSubscriptions,
    } = this.props;
    const {
      isLoading, errorMessage, open,
    } = this.state;

    let progressBar = <LinearProgress variant="determinate" value={100} />;

    if (isLoading) {
      progressBar = <LinearProgress />;
    }

    const isMobile = window.innerWidth < 768;

    return (
      <div>
        <Button
          style={{
            backgroundColor: !hasActiveSubscriptions ? 'var(--destructive)' : 'var(--muted)',
            color: 'white',
          }}
          variant="contained"
          color="secondary"
          onClick={() => this.setState({ open: true })}
        >
          <Typography
            style={{
              display: 'flex', alignItems: 'center', gap: '0.5rem', fontWeight: '600',
            }}
            variant="button"
          >
            <DeleteTwoTone />
            Delete
          </Typography>
        </Button>

        <Dialog open={open} onClose={this.handleRequestClose} fullScreen={isMobile}>
          {progressBar}
          <div style={{
            display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1.5rem',
          }}
          >
            <div style={{
              display: 'flex', alignItems: 'center', justifyContent: 'space-between',
            }}
            >
              <Typography variant="body1" style={{ fontWeight: '600' }}>{`Delete Membership: ${membershipName}?`}</Typography>

              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </div>

            {!errorMessage ? (
              <div style={{ display: 'flex' }}>
                {!hasActiveSubscriptions ? (
                  <Typography variant="body1">
                    Please be aware that if you delete the following membership:
                    <strong>{` ${membershipName} `}</strong>
                    it will be
                    <strong>{' permanently removed '}</strong>
                    from the system.
                  </Typography>
                ) : (
                  <Typography variant="body1">
                    You cannot delete this membership as it has active subscriptions.
                  </Typography>
                )}
              </div>
            ) : (
              <Typography variant="body1" style={{ color: 'red' }}>
                {errorMessage}
              </Typography>
            )}
          </div>

          <DialogActions>
            <Button onClick={this.handleRequestClose} variant="contained" style={{ backgroundColor: 'var(--muted)', color: 'black' }}>
              Cancel
            </Button>
            {!hasActiveSubscriptions && (
              <Button
                onClick={this.handleDetailsSubmit}
                style={{
                  backgroundColor: 'var(--destructive)',
                  color: 'white',
                }}
                variant="contained"
              >
                Delete
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DeleteMembershipDialog.propTypes = {
  membershipName: PropTypes.string.isRequired,
  updateMembership: PropTypes.string.isRequired,
  membershipID: PropTypes.string.isRequired,
  success: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  forceOpen: PropTypes.bool.isRequired,
  hasActiveSubscriptions: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const { membership } = state;
  return {
    membership,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateMembership: (membershipId, reqBody) => dispatch(
    membershipsActions.updateMembership(membershipId, reqBody),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteMembershipDialog);
