/* eslint-disable no-case-declarations */
export const TOGGLE_BOOKING_MODIFIER_DIALOG = 'TOGGLE_BOOKING_MODIFIER_DIALOG';

export const CREATE_COMPANY_BOOKING_MODIFIER = 'CREATE_COMPANY_BOOKING_MODIFIER';
export const UPDATE_COMPANY_BOOKING_MODIFIERS = 'UPDATE_COMPANY_BOOKING_MODIFIERS';
export const GET_COMPANY_BOOKING_MODIFIERS = 'GET_COMPANY_BOOKING_MODIFIERS';
export const GET_ALL_COMPANY_BOOKING_MODIFIERS = 'GET_ALL_COMPANY_BOOKING_MODIFIERS';
export const UPDATE_ALL_COMPANY_BOOKING_MODIFIERS = 'UPDATE_ALL_COMPANY_BOOKING_MODIFIERS';
export const BOOKING_MODIFIER_DIALOG_RESET = 'BOOKING_MODIFIER_DIALOG_RESET';
export const UPDATED_BOOKING_MODIFIER_STORE = 'UPDATED_BOOKING_MODIFIER_STORE';
export const UPDATED_SINGULAR_BOOKING_MODIFIER_STORE = 'UPDATED_SINGULAR_BOOKING_MODIFIER_STORE';
export const TOGGLED_EDITING_BOOKING_MODIDIER_GROUP = 'TOGGLED_EDITING_BOOKING_MODIDIER_GROUP';

export const BOOKING_MODIFIER_UPDATE_REQUESTED = 'BOOKING_MODIFIER_UPDATE_REQUESTED';
export const BOOKING_MODIFIER_UPDATE_SUCCEEDED = 'BOOKING_MODIFIER_UPDATE_SUCCEEDED';

export const BOOKING_MODIFIER_UPDATE_GROUP_REQUESTED = 'BOOKING_MODIFIER_UPDATE_GROUP_REQUESTED';
export const BOOKING_MODIFIER_UPDATE_GROUP_SUCCEEDED = 'BOOKING_MODIFIER_UPDATE_GROUP_SUCCEEDED';

const initialState = {
  bookingModifier: {
    name: '',
    type: 'AGE_GROUP',
    facilities: [],
    visible: false,
    bookingModifiers: [],
    overrides: {
      timingConfiguration: {
        allowedDurations: [],
      },
      allowedUnits: [],
    },
  },
  singularBookingModifier: {
    name: '',
    type: '',
    visible: false,
    overrides: {
      timingConfiguration: {
        allowedDurations: [],
      },
      allowedUnits: [],
    },
  },
  companyBookingModifiers: [],
  ageGroupBookingModifiers: [],
  eventBookingModifiers: [],
  bookingModifierDialogOpen: false,
  editingBookingModifier: false,
};

export function createBookingModifier(reqBody) {
  return { type: CREATE_COMPANY_BOOKING_MODIFIER, reqBody };
}

export function toggleBookingModifierDialog() {
  return { type: TOGGLE_BOOKING_MODIFIER_DIALOG };
}

export function updateCompanyBookingModifiers(companyBookingModifiers) {
  return { type: UPDATE_COMPANY_BOOKING_MODIFIERS, companyBookingModifiers };
}

export function updateAllCompanyBookingModifiers(companyBookingModifiers) {
  return { type: UPDATE_ALL_COMPANY_BOOKING_MODIFIERS, companyBookingModifiers };
}

export function getCompanyBookingModifiers() {
  return { type: GET_COMPANY_BOOKING_MODIFIERS };
}
export function getAllCompanyBookingModifiers() {
  return { type: GET_ALL_COMPANY_BOOKING_MODIFIERS };
}
export function resetBookingModifierDialog() {
  return { type: BOOKING_MODIFIER_DIALOG_RESET };
}
export function toggleEditingBookingModifier() {
  return { type: TOGGLED_EDITING_BOOKING_MODIDIER_GROUP };
}
export function updateBookingModifierStore(value) {
  return { type: UPDATED_BOOKING_MODIFIER_STORE, value };
}
export function updateSingularBookingModifierStore(value) {
  return { type: UPDATED_SINGULAR_BOOKING_MODIFIER_STORE, value };
}
export function requestBookingModifierUpdate() {
  return { type: BOOKING_MODIFIER_UPDATE_REQUESTED };
}
export function bookingModifierUpdateSucceeded(value) {
  return { type: BOOKING_MODIFIER_UPDATE_SUCCEEDED, value };
}
export function requestBookingModifierGroupUpdate() {
  return { type: BOOKING_MODIFIER_UPDATE_GROUP_REQUESTED };
}
export function bookingModifierGroupUpdateSucceeded(value) {
  return { type: BOOKING_MODIFIER_UPDATE_GROUP_SUCCEEDED, value };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_BOOKING_MODIFIER_DIALOG:
      return { ...state, bookingModifierDialogOpen: !state.bookingModifierDialogOpen };
    case UPDATE_COMPANY_BOOKING_MODIFIERS:
      return { ...state, companyBookingModifiers: action.companyBookingModifiers };
    case BOOKING_MODIFIER_DIALOG_RESET:
      return { ...state, bookingModifier: initialState.bookingModifier };
    case TOGGLED_EDITING_BOOKING_MODIDIER_GROUP:
      return {
        ...state,
        editingBookingModifier: !state.editingBookingModifier,
      };
    case UPDATE_ALL_COMPANY_BOOKING_MODIFIERS:
      return {
        ...state,
        eventBookingModifiers: action.companyBookingModifiers.eventBookingModifiers,
        ageGroupBookingModifiers: action.companyBookingModifiers.ageGroupBookingModifiers,
      };
    case UPDATED_BOOKING_MODIFIER_STORE:
      return {
        ...state,
        bookingModifier: {
          ...state.bookingModifier,
          ...action.value,
        },
      };
    case BOOKING_MODIFIER_UPDATE_SUCCEEDED:
      const filteredBookingModifiers = state.bookingModifier.bookingModifiers.filter(
        (bookingMod) => bookingMod.sys.id === action.value.id,
      );

      filteredBookingModifiers.push(action.value);

      return {
        ...state,
        bookingModifier: {
          bookingModifiers: filteredBookingModifiers,
        },
      };
    case UPDATED_SINGULAR_BOOKING_MODIFIER_STORE:
      return {
        ...state,
        singularBookingModifier: {
          ...state.singularBookingModifier,
          ...action.value,
        },
      };
    default:
      return state;
  }
};
