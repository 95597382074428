/*
    The SettingsWrapperComponent is a simple container for
    the HeaderSettingsLegend and SettingsModal components.
*/

import React from 'react';

import { IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';
import HeaderSettingsLegend from './HeaderSettingsLegend';
import SettingsModal from '../modals/SettingsModal';
import ToolTip from '../../../../components/Tooltip';
import {
  retrieveAnalyticsBookings,
  retrieveAnalyticsFacilities,
} from '../../../../reducers/analyticsReducer';
import { getFromToDates, getFromToDatesPrevious } from '../../helper';

export const SettingsWrapper = () => {
  const dispatch = useDispatch();

  const { initialAnalyticsFacilities } = useSelector((state) => state.analytics);

  const updateAnalytics = () => {
    const defaultDates = getFromToDates('This_Month');
    const defaultDatesPrevious = getFromToDatesPrevious('This_Month');
    const defaultSettings = {
      bookingType: 'Created',
      dates: defaultDates,
      datesPrevious: defaultDatesPrevious,
      facilityIDs: initialAnalyticsFacilities.facilityIDs,
      facilityNames: initialAnalyticsFacilities.facilityNames,
    };
    dispatch(retrieveAnalyticsBookings(defaultSettings, true));
    dispatch(retrieveAnalyticsFacilities());
  };

  return (
    <div className="settingsWrapper">
      <HeaderSettingsLegend />
      <SettingsModal />
      <IconButton onClick={updateAnalytics}>
        <RefreshIcon fontSize="large" />
      </IconButton>
      <ToolTip contentfulName="analyticsDashboardTabToolTip" />
    </div>
  );
};
