import React, { useState, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { useDispatch } from 'react-redux';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import defaultForm from './NewMembershipDialog/DefaultCustomForm.json';
import * as membershipServices from '../../../services/membershipsServices';

export const validationSchema = Yup.object().shape({
  form: Yup.object().shape({
    id: Yup.string().nullable(),
    formData: Yup.array(Yup.object().shape({
      name: Yup.string().required('Required'),
      label: Yup.string().required('Required'),
      type: Yup.string().required('Required'),
      status: Yup.boolean(),
      required: Yup.boolean(),
      value: Yup.string(),
      section: Yup.string(),
    })).min(1),
  }),
});

const styles = {
  dialogContent: {
    paddingRight: '2.5rem',
    paddingLeft: '2.5rem',
    paddingBottom: '2.5rem',
  },
  dialogTitle: { paddingLeft: '0rem' },
  actionWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '1rem',
  },
  triggerButton: {
    fontWeight: 800,
    width: 225,
  },
};

const EditMembershipFormDialog = ({ getMembershipFormData, membershipId, membershipFormId }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const formik = useFormik({
    initialValues: {
      form: {
        id: null,
        formData: defaultForm,
      },
    },
    validationSchema,
    onSubmit: async (values) => {
      const reqBody = {
        formData: values.form.formData,
      };
      await membershipServices.updateMembershipForm(
        membershipId, membershipFormId, reqBody,
      ).then((res) => {
        if (res.data) {
          setOpen(false);
          dispatch(sessionActions.updateSuccessfulSnackbar('Membership form updated successfully!', true));
        }
      });
    },
  });

  const sections = useMemo(() => {
    if (!formik.values.form || !formik.values.form.formData) return [];
    return formik.values.form.formData.reduce((acc, curr) => {
      if (!acc.includes(curr.section)) {
        acc.push(curr.section);
      }
      return acc;
    }, []);
  }, [formik.values.form]);

  const handleOpenClick = async () => {
    setOpen(true);
    await getMembershipFormData(membershipId, membershipFormId).then((res) => {
      if (res.data) {
        formik.setValues({ form: { id: null, formData: res.data.formData } });
      }
    });
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleOpenClick}
        variant="contained"
        color="secondary"
        style={styles.triggerButton}
      >
        Edit Membership Form
      </Button>
      <Dialog open={open} onClose={handleDialogClose} fullScreen={isMobile} maxWidth="sm">
        <div style={styles.actionWrapper}>
          <CloseDialogIcon style={styles.closeDialogIcon} onClick={handleDialogClose} />
        </div>
        <DialogContent style={styles.dialogContent}>
          <DialogTitle style={styles.dialogTitle}>Edit Membership Form</DialogTitle>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {sections.map((section) => (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} key={section}>
                <div>
                  <Typography>{section}</Typography>
                  <Divider />
                </div>

                <div style={{
                  display: 'grid', gap: '1rem', gridTemplateColumns: section !== 'Custom Consent' ? 'repeat(6, 1fr)' : 'repeat(4, 1fr)',
                }}
                >
                  <div style={{ gridColumn: 'span 2' }}>Label</div>
                  {section !== 'Custom Consent' ? <div style={{ gridColumn: 'span 2' }}>Field Type</div> : null}
                  {section !== 'Custom Consent' ? <div>Optional</div> : null}
                  <div>Required</div>
                  {section === 'Custom Consent' ? <div>Delete?</div> : null}

                  {formik.values.form.formData && formik.values.form.formData.length > 0 ? (
                    <>
                      {formik.values.form.formData.filter(
                        (x) => x.section === section,
                      ).map((field) => {
                        const fieldIndex = formik.values.form.formData.findIndex(
                          (x) => x.name === field.name,
                        );

                        return (
                          <React.Fragment key={fieldIndex}>
                            <div style={{ gridColumn: 'span 2', alignSelf: 'center' }}>
                              <Typography variant="subheading">{field.label}</Typography>
                            </div>
                            {section !== 'Custom Consent' && (
                              <>
                                <div style={{ gridColumn: 'span 2', alignSelf: 'center' }}>
                                  {(
                                    field.type === 'TEXT'
                            || field.type === 'TEXT_AREA'
                            || field.type === 'BOOLEAN'
                                  ) && (
                                  <PBInput
                                    id={`form.formData.${fieldIndex}.type`}
                                    type="select"
                                    value={field.type}
                                    options={[
                                      { value: 'TEXT', label: 'Text' },
                                      { value: 'TEXT_AREA', label: 'Text Area' },
                                      { value: 'BOOLEAN', label: 'True/False' },
                                    ]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isError={
                              formik.errors.form
                              && formik.errors.form[fieldIndex]
                              && formik.touched.form
                              && formik.touched.form[fieldIndex]
                              && formik.errors.form[fieldIndex].status
                            }
                                    errorText={
                              formik.errors.form
                              && formik.errors.form[fieldIndex]
                              && formik.touched.form
                              && formik.touched.form[fieldIndex]
                              && formik.errors.form[fieldIndex].status
                            }
                                  />
                                  )}
                                </div>

                                <div style={{ alignSelf: 'center' }}>
                                  <PBInput
                                    id={`form.formData.${fieldIndex}.status`}
                                    type="toggle"
                                    value={field.status}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    disabled={field.required}
                                  />
                                </div>
                              </>
                            )}
                            <div style={{ alignSelf: 'center' }}>
                              <PBInput
                                id={`form.formData.${fieldIndex}.required`}
                                type="toggle"
                                value={field.required}
                                onChange={(e) => {
                                  formik.handleChange(e);

                                  if (e.target.checked) {
                                    formik.setFieldValue(`form.formData.${fieldIndex}.status`, true);
                                  }
                                }}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {section === 'Custom Consent' && (
                              <div style={{ alignSelf: 'center' }}>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    const newFormData = [...formik.values.form.formData];
                                    newFormData.splice(fieldIndex, 1);
                                    formik.setFieldValue('form.formData', newFormData);
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </>
                  ) : null}
                </div>
              </div>
            ))}

            {/* Close/Submit Buttons */}
            <DialogActions>
              <Button
                variant="outlined"
                onClick={() => handleDialogClose()}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={formik.handleSubmit}
              >
                Submit
              </Button>
            </DialogActions>

            {/* Additional Consent Form */}
            <>
              <Divider />
              <div>
                <Typography>Additional consent forms</Typography>
                <Typography variant="body2">
                  Field name will be used to identify the consent form in the manager dashboard.
                  Consent text will be displayed to the user when they register for the event.
                </Typography>
              </div>
              <Divider />

              <CustomForm
                onSubmit={(customVal) => {
                  formik.setFieldValue('form.formData', [...formik.values.form.formData, customVal]);
                }}
              />
            </>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

EditMembershipFormDialog.propTypes = {
  membershipId: PropTypes.string.isRequired,
  membershipFormId: PropTypes.string.isRequired,
  getMembershipFormData: PropTypes.func.isRequired,
};

const CustomForm = ({ onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      customForm: {
        name: '',
        label: '',
        required: false,
        type: 'text',
        status: true,
        value: '',
        section: 'Custom Consent',
      },
    },
    validationSchema,
  });

  const validation = Yup.object().shape({
    name: Yup.string().required('Field Name is a Required Field'),
    label: Yup.string().required('Consent Text is a Required Field'),
    required: Yup.boolean(),
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <PBInput
        id="customForm.name"
        label="Field Name"
        type="text"
        value={formik?.values?.customForm?.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />

      <PBInput
        id="customForm.label"
        label="Consent Text"
        type="text"
        value={formik?.values?.customForm?.label}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <PBInput
        id="customForm.required"
        label="Consent is required?"
        type="toggle"
        value={formik?.values?.customForm?.required}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />

      <div>
        <Button
          onClick={async () => {
            const isValid = await validation.isValid(formik?.values?.customForm);
            if (isValid) {
              onSubmit(formik?.values?.customForm);
            }
          }}
          variant="outlined"
          color="primary"
        >
          Add Consent Form
        </Button>
      </div>
    </div>
  );
};

CustomForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default EditMembershipFormDialog;
