import React from 'react';
import '../styles/login.css';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

function ReferralsBanner() {
  return (
    <div className="referralsBanner">
      <div className="referralsBannerColumn">
        <a href="mailto:info@pitchbooking.com?subject=Referral Scheme">
          <ContactSupportIcon />
        </a>
      </div>
      <div className="eventsBannerColumn">
        <a href="mailto:info@pitchbooking.com?subject=Referral Scheme" style={{ textDecoration: 'none' }}>
          <span style={{ fontSize: '120%' }}>
            Know another team that could benefit from
            <em>
              <b> Pitchbooking software? </b>
            </em>
            <a href="mailto:info@pitchbooking.com?subject=Referral Scheme" style={{ textDecoration: 'none' }}> Get in touch about our referrals programme </a>
          </span>
        </a>
      </div>
    </div>

  );
}

export default (ReferralsBanner);
