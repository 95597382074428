import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export const getFormsService = async (companyId) => {
  const url = `/api/companies/${companyId}/forms`;
  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
};

export const updateFormService = async (companyId, formId, reqBody) => {
  let url;
  let response;
  if (formId) {
    url = `/api/companies/${companyId}/forms/${formId}`;
    response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  } else {
    if (!formId) url = `/api/companies/${companyId}/forms`;
    response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  }

  return response;
};

export const updateResourceFormService = async (companyId, reqBody) => {
  const url = `/api/companies/${companyId}/forms/resource`;
  const response = await httpHelpers.authenticatedPutRequest(url, reqBody);
  return response;
};

export const unlinkFormService = async (companyId, body) => {
  const url = `/api/companies/${companyId}/forms/unlink`;
  const response = await httpHelpers.authenticatedPostRequest(url, body);
  return response;
};
