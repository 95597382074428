import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from '@pitchbooking-dev/pb-shared/lib/components/Loading';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import EventsTable from './EventsTable';
import ToggleButtons from '../../components/ToggleButtons';
import ActionBar from '../../components/ActionBar';
import ToolTip from '../../components/Tooltip';
import * as eventsActions from '../../reducers/eventsReducer';
import * as addonsActions from '../../reducers/addonsReducer';
import '../../styles/payments.css';
import EventMutationDialog from './components/EventMutationDialog';

const displayButtonsData = [{
  buttonTitle: 'Open Events',
  buttonValue: 'ACTIVE',
},
{
  buttonTitle: 'Closed Events',
  buttonValue: 'INACTIVE',
},
{
  buttonTitle: 'All Events',
  buttonValue: 'ALL',
}];

const EventsOverview = ({
  events,
  selectedToggle,
  toggleSelectedEventsView,
  eventsLoading,
  getEvents,
  requestAddonsRetrieval,
}) => {
  const [mutate, setMutate] = React.useState({ isOpen: false, event: null });

  useEffect(() => {
    getEvents('ACTIVE');
    requestAddonsRetrieval();
  }, [getEvents, requestAddonsRetrieval]);

  const onClose = () => {
    getEvents('ACTIVE');
    setMutate({ isOpen: false, event: null });
  };

  return (
    <AlternativelyVisible condition={eventsLoading}>
      <Loading title="Loading Events" />
      <div style={{
        backgroundColor: '#fafbff', marginTop: '-20px', paddingTop: '20px', height: '95.5vh',
      }}
      >
        <div style={{
          display: 'flex', flex: '1', justifyContent: 'space-between', paddingRight: '20px',
        }}
        >
          <ActionBar>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setMutate({ isOpen: true, event: null })}
            >
              Create Event
            </Button>

            <Link to="/forms">
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: '10px' }}
              >
                View Existing Forms
              </Button>
            </Link>

            <div style={{ marginLeft: '70px' }}>
              <ToggleButtons
                buttonsData={displayButtonsData}
                changeOption={(option) => toggleSelectedEventsView(option)}
                value={selectedToggle}
              />
            </div>
          </ActionBar>

          <ToolTip contentfulName="eventsTabToolTip" />
        </div>
        <EventsTable
          events={events}
          onEdit={(e) => {
            setMutate({ isOpen: true, event: e });
          }}
          getEvents={getEvents}
        />

        <EventMutationDialog
          mutate={mutate}
          onClose={() => onClose()}
        />
      </div>
    </AlternativelyVisible>
  );
};

EventsOverview.propTypes = {
  events: PropTypes.shape().isRequired,
  getEvents: PropTypes.func.isRequired,
  requestAddonsRetrieval: PropTypes.func.isRequired,
  toggleSelectedEventsView: PropTypes.func.isRequired,
  selectedToggle: PropTypes.string.isRequired,
  eventsLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    events,
    selectedToggle,
    eventsLoading,
  } = state.events;
  return {
    events,
    selectedToggle,
    eventsLoading,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getEvents: (status, orderBy) => dispatch(eventsActions.getEvents(status, orderBy)),
  toggleSelectedEventsView: (value) => dispatch(eventsActions.toggleSelectedEventsView(value)),
  requestAddonsRetrieval: () => dispatch(addonsActions.requestAddonsRetrieval()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventsOverview);
