import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from '@material-ui/core';

import CheckboxListById from './CheckboxListById';

const AllSelector = (props) => {
  const { toggleAll, isChecked } = props;
  return (
    <List>
      <ListItem
        key={Math.random()}
        dense
        button
        onClick={toggleAll}
      >
        <Checkbox
          checked={isChecked}
          tabIndex={-1}
          disableRipple
        />
        <ListItemText primary="Select All" />
      </ListItem>
    </List>
  );
};

class CheckboxListPickerById extends React.Component {
  constructor(props) {
    super(props);
    const { checkedItems } = this.props;
    this.state = {
      open: false,
      allChecked: false,
      checkedItems: checkedItems || [],
    };
    this.toggleItem = this.toggleItem.bind(this);
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleSaveAndRequestClose = () => {
    const { editingAccessRestriction, onUpdate, flagCheckedItemsModified } = this.props;
    const { checkedItems } = this.state;
    if (editingAccessRestriction) {
      flagCheckedItemsModified({ changedMemberships: true });
    }
    onUpdate(checkedItems);
    this.handleRequestClose();
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  toggleAll = () => {
    const { listItems } = this.props;
    const { allChecked } = this.state;

    if (!allChecked) {
      this.setState({
        checkedItems: listItems.map((listItem) => listItem.id),
        allChecked: true,
      });
    }
    if (allChecked) {
      this.setState({
        checkedItems: [],
        allChecked: false,
      });
    }
  }

  toggleItem = (item) => {
    const { checkedItems } = this.state;
    const itemIsChecked = checkedItems.some((checkedItem) => checkedItem.id === item.id);
    let updatedCheckedItems = [];
    if (itemIsChecked) {
      updatedCheckedItems = checkedItems.filter((checkedItem) => checkedItem.id !== item.id);
    } else {
      updatedCheckedItems = [...checkedItems, item];
    }

    this.setState({ checkedItems: updatedCheckedItems });
  }

  render() {
    const { selectionLimit, listItems, listName } = this.props;
    const { checkedItems, open, allChecked } = this.state;
    const selectionLimitSetting = selectionLimit || -1;

    return (
      <div>
        <Button
          onClick={this.handleClickOpen}
          variant="contained"
          disabled={listItems.length === 0}
          style={{ marginBottom: '25px', backgroundColor: '#f0f0f0' }}
        >
          Select
          {' '}
          {listName}
          {' '}
          (
          {checkedItems.length}
          )
        </Button>
        <Dialog
          open={open}
          fullWidth
          onBackdropClick={this.handleRequestClose}
          onEscapeKeyDown={this.handleRequestClose}
        >
          <DialogTitle>
            {`Select ${listName}`}
            {selectionLimitSetting < 0
              && (
                <AllSelector
                  toggleAll={this.toggleAll}
                  isChecked={allChecked}
                />
              )}
          </DialogTitle>
          <DialogContent>
            <CheckboxListById
              checkedItems={checkedItems}
              items={listItems}
              toggleItem={this.toggleItem}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleSaveAndRequestClose} color="primary" autoFocus>
              Choose
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AllSelector.propTypes = {
  toggleAll: PropTypes.shape().isRequired,
  isChecked: PropTypes.bool.isRequired,
};
CheckboxListPickerById.propTypes = {
  listItems: PropTypes.shape().isRequired,
  editingAccessRestriction: PropTypes.bool.isRequired,
  selectionLimit: PropTypes.string.isRequired,
  listName: PropTypes.string.isRequired,
  checkedItems: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  flagCheckedItemsModified: PropTypes.bool.isRequired,
};

export default CheckboxListPickerById;
