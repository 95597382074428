import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import {
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
} from '@material-ui/core';
import PartialRefundTableRow from './partialRefundTableRow';

const PartialRefundTable = ({
  currencySym,
  fullyRefunded,
  cancelAllocations,
}) => (
  <div className="billingPriceTable">
    <br />
    <br />
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell align="left" style={{ fontWeight: 800 }}>Item</TableCell>
            <TableCell align="center" style={{ fontWeight: 800 }}>Original Price</TableCell>
            <TableCell align="center" style={{ fontWeight: 800 }}>Already refunded</TableCell>
            <TableCell align="center" style={{ fontWeight: 800 }}>Available to refund</TableCell>
            <TableCell align="center" style={{ fontWeight: 800 }}>Refund amount</TableCell>
            <TableCell align="center" style={{ fontWeight: 800 }}>Cancel booking?</TableCell>
          </TableRow>
          {cancelAllocations.map(
            (allocation) => (
              <PartialRefundTableRow
                row={allocation}
                currencySym={currencySym}
                fullyRefunded={fullyRefunded}
              />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <Alert
      id="alert"
      severity="info"
      style={{ width: '100%' }}
    >
      Note: Ticking the checkbox ‘Cancel Booking?’ will remove this part of the reservation
      from the bookings list and calendar.
    </Alert>
  </div>
);

PartialRefundTable.propTypes = {
  currencySym: PropTypes.string,
  cancelAllocations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fullyRefunded: PropTypes.bool,
};
PartialRefundTable.defaultProps = {
  currencySym: '£',
  fullyRefunded: false,
};

function mapStateToProps(state) {
  const { reservation } = state;
  const { cancelReservations } = reservation;
  const { cancelAllocations } = cancelReservations;
  return {
    cancelAllocations,
  };
}

export default connect(mapStateToProps, null)(PartialRefundTable);
