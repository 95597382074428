import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { removeUserFromCompanyService } from '../../../services/companyUsersServices';
import { useCompany, useToast } from '../../../hooks';

export const RemoveTeamMemberDialog = ({ name, userCompanyId }) => {
  const queryClient = useQueryClient();
  const company = useCompany();
  const toast = useToast();
  const { id: companyId } = company;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const mutation = useMutation({
    mutationFn: async () => {
      const res = await removeUserFromCompanyService(companyId, userCompanyId);
      if (res.status !== 200) {
        throw new Error('Error removing team member');
      }
      queryClient.setQueryData(['company', companyId, 'users'], (old) => old.filter((user) => user.id !== userCompanyId));
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['company', companyId, 'users']);
      toast.trigger({
        type: 'success',
        message: `${name}, successfully removed from team`,
      });
      setIsDialogOpen(false);
    },
    onError: () => {
      queryClient.invalidateQueries(['company', companyId, 'users']);
      toast.trigger({
        type: 'error',
        message: 'Error removing team member',
      });
    },
  });

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} variant="contained" color="secondary">
        Remove
      </Button>

      <Dialog open={isDialogOpen} onClose={handleClose}>

        <DialogTitle>
          <Typography variant="h6">Remove Team Member</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to remove the following team member?
            <ul>
              <li>
                <Typography variant="body1" style={{ fontWeight: '600' }}>
                  {name}
                </Typography>
              </li>
            </ul>
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleClose} disabled={mutation.isLoading}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={() => mutation.mutate()} disabled={mutation.isLoading}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

RemoveTeamMemberDialog.propTypes = {
  name: PropTypes.string.isRequired,
  userCompanyId: PropTypes.string.isRequired,
};
