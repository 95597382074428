export const companyStatuses = [
  { value: 'ALL', label: 'All' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'ARCHIVED', label: 'Archived' },
  { value: 'NEW_PENDING_APPROVAL', label: 'New Pending Approval' },
  { value: 'DIRECTORY_UNCLAIMED', label: 'Directory Unclaimed' },
  { value: 'DIRECTORY_CLAIMED_PENDING_APPROVAL', label: 'Directory Claimed Pending Approval' },
  { value: 'DIRECTORY_APPROVED', label: 'Directory Approved' },
  { value: 'DIRECTORY_PAID', label: 'Directory Paid' },
  { value: 'ACCOUNT_MANAGER_UNASSIGNED', label: 'Account Manager Unassigned' },
  { value: 'ONBOARDING', label: 'Onboarding' },
  { value: 'PROSPECT_INACTIVE', label: 'Prospect Inactive' },
  { value: 'RESTRICTED', label: 'Restricted' },
  { value: 'PAUSED', label: 'Paused' },
  { value: 'CHURNED', label: 'Churned' },
];

export const infoStatuses = [
  { value: 'NOT_SET', label: 'Not Set / Clear' },
  { value: 'INFO', label: 'Information' },
  { value: 'WARNING', label: 'Warning' },
];

export const accountManagerColors = {
  matt: {
    backgroundColor: 'rgb(222, 102, 96, 0.4)',
    border: 'rgb(217, 76, 69, 0.6)',
  },
  kate: {
    backgroundColor: '#BED7EE',
    border: '#9EC3E6',
  },
  danielle: {
    backgroundColor: '#C5BADE',
    border: '#AE9FD1',
  },
  unassigned: {
    backgroundColor: 'rgb(206, 206, 206, 0.6)',
    border: '#C2C2C2',
  },
};

export const billingMethodColors = {
  stripe: {
    backgroundColor: 'rgb(130, 224, 170, 0.4)',
    border: 'rgb(39, 174, 96, 0.6)',
  },
  invoice: {
    backgroundColor: 'rgb(71, 182, 255, 0.4)',
    border: 'rgb(31, 165, 255, 0.6)',
  },
  commission: {
    backgroundColor: 'rgb(249, 160, 16, 0.4)',
    border: 'rgb(219, 137, 6, 0.6)',
  },
  'n/a': {
    backgroundColor: 'rgb(206, 206, 206, 0.6)',
    border: '#C2C2C2',
  },
};
