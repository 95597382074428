import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@material-ui/core';
import { useCompany, useToast } from '../../hooks';
import { validateMembershipSubscriptions } from '../../services/membershipsServices';

export const ValidateMembershipSubscriptionsButton = () => {
  const company = useCompany();
  const toast = useToast();

  const { isLoading, mutate } = useMutation({
    mutationFn: async () => {
      const res = await validateMembershipSubscriptions(company.id);

      if (res.status !== 200) {
        throw new Error('Failed to validate membership subscriptions');
      }

      toast.trigger({
        type: 'success',
        message: 'Membership subscriptions validated',
      });

      return res;
    },
  });

  return (
    <Button variant="outlined" color="secondary" onClick={() => mutate()} disabled={isLoading}>
      Validate Membership Subscriptions
    </Button>
  );
};
