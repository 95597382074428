/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Dialog, Typography } from '@material-ui/core';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import { useDispatch } from 'react-redux';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Formik, Form } from 'formik';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import * as yup from 'yup';
import moment from 'moment';
import { toggleEventFormDobDialog } from '../../../reducers/eventsReducer';

const isValidDate = (dateString) => {
  if (dateString === null || dateString === '' || dateString === undefined) {
    return true; // Allow null or empty strings
  }

  const regex = /^\d{2}\/\d{2}\/\d{4}$/; // DD/MM/YYYY
  if (!regex.test(dateString)) return false;

  const [day, month, year] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day);

  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
};

const VALIDATION_SCHEMA = yup.object().shape({
  minAge: yup.string()
    .test('isValidDate', 'Invalid date', isValidDate)
    .nullable(),
  maxAge: yup.string()
    .test('isValidDate', 'Invalid date', isValidDate)
    .nullable(),
});

export const MinMaxDobDialog = ({ isOpen, formData, onSubmit }) => {
  const dispatch = useDispatch();
  const [openDialog] = useState(isOpen || false);
  const [showInfoAlert, setShowInfoAlert] = useState(false);
  const [showMinDobAlert, setShowMinDobAlert] = useState(false);
  const [showMaxDobAlert, setShowMaxDobAlert] = useState(false);
  const [minAgeData, setMinAgeData] = useState(null);
  const [maxAgeData, setMaxAgeData] = useState(null);

  useEffect(() => {
    if (formData) {
      setMinAgeData(formData.find((field) => field.name === 'minAge'));
      setMaxAgeData(formData.find((field) => field.name === 'maxAge'));
    }
  }, [formData]);

  return (
    <>
      <Dialog open={openDialog} fullWidth>
        <div style={{
          display: 'flex', flexDirection: 'column', gap: '2rem', padding: '2rem',
        }}
        >
          {/* Title and Close */}
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'space-between',
          }}
          >
            <Typography variant="h6" style={{ fontWeight: 600 }}>
              Date of Birth (DOB) Validation
            </Typography>
            <Button>
              <CancelTwoToneIcon onClick={() => dispatch(toggleEventFormDobDialog(false))} />
            </Button>
          </div>

          <Formik
            initialValues={{
              showAgeValidation: !!(minAgeData || maxAgeData),
              minAge: minAgeData?.value || null,
              maxAge: maxAgeData?.value || null,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={(values) => {
              onSubmit(values);
              dispatch(toggleEventFormDobDialog(false));
            }}
          >
            {({
              values, handleSubmit, handleChange, getFieldProps, setFieldValue, touched, errors,
              handleBlur,
            }) => (
              <Form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                {/* Show age validation toggle */}
                {showInfoAlert && (
                  <Alert severity="info">
                    Set the minimum and maximum dates of birth to define
                    the eligible age range for participants as of the event date.
                    Ensure the dates reflect the desired age criteria for your specific event.
                  </Alert>
                )}
                <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
                  <Typography variant="body1" style={{ fontWeight: 600 }}>
                    Would you like to apply age validation for this event?
                  </Typography>
                  <PBInput
                    id="showAgeValidation"
                    type="toggle"
                    {...getFieldProps('showAgeValidation')}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.checked) {
                        setFieldValue('showAgeValidation', true);
                      } else {
                        setFieldValue('showAgeValidation', false);
                        setFieldValue('minAge', null);
                        setFieldValue('maxAge', null);
                      }
                    }}
                  />
                  <HelpTwoToneIcon
                    style={{ fontSize: '1.5rem', cursor: 'pointer', color: !showInfoAlert ? 'var(--accent)' : '' }}
                    onClick={() => setShowInfoAlert(!showInfoAlert)}
                  />
                </div>

                {/* Min Age */}
                {values.showAgeValidation && (
                  <>
                    {showMinDobAlert && (
                    <Alert severity="info">
                      <AlertTitle style={{ fontWeight: '600' }}>Minimum DOB</AlertTitle>
                        {`The minimum DOB a participant must be to attend this event.
                        E.g. 01/01/${moment().year() - 30} (30 years old)
                        means the participant must be born on or after 01/01/${moment().year() - 30}`}
                    </Alert>
                    )}
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: '600', display: 'flex', gap: '0.25rem', alignItems: 'center',
                        }}
                      >
                        Minimum DOB:
                      </Typography>

                      <PBInput
                        type="dob"
                        id="minAge"
                        name="minAge"
                        value={values.minAge}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={touched.minAge && errors.minAge}
                        isError={touched.minAge && Boolean(errors.minAge)}
                      />

                      <HelpTwoToneIcon
                        style={{ fontSize: '1.5rem', cursor: 'pointer', color: !showMinDobAlert ? 'var(--accent)' : '' }}
                        onClick={() => setShowMinDobAlert(!showMinDobAlert)}
                      />
                    </div>

                    {/* Max Age */}
                    {showMaxDobAlert && (
                    <Alert severity="info">
                      <AlertTitle style={{ fontWeight: '600' }}>Maximum DOB</AlertTitle>
                        {`The maximum DOB a participant can be to attend this event.
                          E.g. 01/01/${moment().year() - 25} (25 years old) means the participant 
                          must be born on or before 01/01/${moment().year() - 25}`}
                    </Alert>
                    )}
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: '600', display: 'flex', gap: '0.25rem', alignItems: 'center',
                        }}
                      >
                        Maximum DOB:
                      </Typography>
                      <PBInput
                        type="dob"
                        id="maxAge"
                        name="maxAge"
                        value={values.maxAge}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={touched.maxAge && errors.maxAge}
                        isError={touched.maxAge && Boolean(errors.maxAge)}
                      />

                      <HelpTwoToneIcon
                        style={{ fontSize: '1.5rem', cursor: 'pointer', color: !showMaxDobAlert ? 'var(--accent)' : '' }}
                        onClick={() => setShowMaxDobAlert(!showMaxDobAlert)}
                      />
                    </div>
                  </>
                )}

                {/* Buttons */}
                <div style={{
                  display: 'flex', gap: '1rem', justifyContent: 'flex-end', marginTop: '1rem',
                }}
                >
                  {!values.showAgeValidation ? (
                    <Button
                      variant="contained"
                      type="submit"
                    >
                      Skip
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Done
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Dialog>
    </>

  );
};
