const config = {
  type: 'line',
  options: {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
        },
        scaleLabel: {
          display: true,
          labelString: '',
        },
        type: 'time',
        time: {
          displayFormats: {
            hour: 'HH:mm',
            day: 'D MMM YYYY',
            week: 'D MMM YYYY',
            month: 'D MMM YYYY',
            quarter: 'D MMM YYYY',
            year: 'D MMM YYYY',
          },
        },
      }],
      yAxes: [{
        gridLines: {
          borderDash: [10, 10],
        },
        scaleLabel: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
        },
      }],
    },
    tooltips: {
      backgroundColor: '#FFFFFF',
      bodyAlign: 'center',
      bodyFontColor: '#000000',
      bodyFontSize: 20,
      displayColors: false,
      enabled: true,
      titleFontColor: '#00000060',
    },
    maintainAspectRatio: false,
  },
};

export default config;
