import moment from 'moment';
import * as httpHelpers from '@pitchbooking-dev/pb-shared/lib/helpers/httpRequestHelpers';

export async function getCalendarUsageService(companyId, siteId, date) {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  const url = `/api/companies/${companyId}/site-calendar?siteId=${siteId}&date=${formattedDate}`;

  const response = await httpHelpers.authenticatedGetRequest(url);
  return response;
}

export async function createCalendarNoteService(companyId, note, date) {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  const reqBody = {
    value: note,
    date: formattedDate,
  };
  const url = `/api/companies/${companyId}/calendar-notes`;
  const response = await httpHelpers.authenticatedPostRequest(url, reqBody);
  return response;
}

export async function deleteCalendarNoteService(companyId, noteId) {
  const url = `/api/companies/${companyId}/calendar-notes/${noteId}`;
  const response = await httpHelpers.authenticatedDeleteRequest(url);
  return response;
}
