import Moment from 'moment';
import { extendMoment } from 'moment-range';

const extendedMoment = extendMoment(Moment);

export const checkForAdjacentTimeSlots = (anchorSlot, selectedTimeSlots, adjacentSlots) => {
  const anchorSlotStartTime = Moment(anchorSlot.startTime);
  const anchorSlotEndTime = Moment(anchorSlot.endTime);

  selectedTimeSlots.forEach((selectedTimeSlot) => {
    const selectedStartTime = Moment(selectedTimeSlot.startTime);
    const selectedEndTime = Moment(selectedTimeSlot.endTime);

    if ((anchorSlotEndTime.isSame(selectedStartTime) || anchorSlotStartTime.isSame(selectedEndTime))
      && !adjacentSlots.some(
        (adjacentSlot) => Moment(adjacentSlot.startTime).isSame(selectedStartTime),
      )
    ) {
      adjacentSlots.push(selectedTimeSlot);
      const lastAdjacentSlot = adjacentSlots[adjacentSlots.length - 1];
      checkForAdjacentTimeSlots(lastAdjacentSlot, selectedTimeSlots, adjacentSlots);
    }
  });

  return adjacentSlots;
};

/**
 * Combine adjacent timeslots, i.e. 5.00 - 5.59 and 6.00 - 6.59 will be combined to 5.00 - 6.59
 * @param {{
 *  startTime: string,
 *  endTime: string,
 *  facilityId: string,
 *  subFacilityId: string,
 *  unavailable: boolean,
 * }[]} timeslots - Array of timeslots
 * @returns {{
 *  startTime: string,
 *  endTime: string,
 *  facilityId: string,
 *  subFacilityId: string,
 *  unavailable: boolean,
 * }[]} - Array of combined timeslots
 */
export const combineTimeslots = (timeslots) => {
  let selectedTimeSlotsStrings = timeslots;
  selectedTimeSlotsStrings = selectedTimeSlotsStrings.sort(
    (a, b) => Moment.parseZone(a.startTime).valueOf() - Moment.parseZone(b.startTime).valueOf(),
  );

  const selectedTimeSlotsForWeek = [];

  const selectedTimeSlots = selectedTimeSlotsStrings.map((selectedTimeSlot) => ({
    startTime: Moment.parseZone(selectedTimeSlot.startTime),
    endTime: Moment.parseZone(selectedTimeSlot.endTime).add(1, 'second'),
    facilityId: selectedTimeSlot.facilityId,
    subFacilityId: selectedTimeSlot.subFacilityId,
    unavailable: selectedTimeSlot.unavailable,
  }));

  selectedTimeSlots.forEach((selectedTimeSlot) => {
    let adjacentSlots = [selectedTimeSlot];

    const timeSlotAlreadyChecked = selectedTimeSlotsForWeek.some((combinedTimeSlotForWeek) => {
      const combinedTimeSlotForWeekRange = extendedMoment.range(
        combinedTimeSlotForWeek.startTime,
        combinedTimeSlotForWeek.endTime,
      );
      const isContained = combinedTimeSlotForWeekRange.contains(selectedTimeSlot.startTime)
        || combinedTimeSlotForWeekRange.contains(selectedTimeSlot.endTime);
      return isContained;
    });

    if (!timeSlotAlreadyChecked) {
      adjacentSlots = checkForAdjacentTimeSlots(selectedTimeSlot, selectedTimeSlots, adjacentSlots);

      const adjacentSlotsByMoment = [];

      adjacentSlots.forEach((adjacentSlot) => {
        adjacentSlotsByMoment.push(adjacentSlot.startTime);
        adjacentSlotsByMoment.push(adjacentSlot.endTime);
      });

      const combinedSlot = {
        startTime: Moment.min(adjacentSlotsByMoment),
        endTime: Moment.max(adjacentSlotsByMoment),
        facilityId: selectedTimeSlot.facilityId,
        subFacilityId: selectedTimeSlot.subFacilityId,
        unavailable: selectedTimeSlot.unavailable,
      };

      selectedTimeSlotsForWeek.push(combinedSlot);
    }
  });

  const formattedCombinedSlots = selectedTimeSlotsForWeek.map((timeSlotToFormat) => ({
    startTime: timeSlotToFormat.startTime.format(),
    endTime: timeSlotToFormat.endTime.subtract(1, 'second').format(),
    facilityId: timeSlotToFormat.facilityId,
    subFacilityId: timeSlotToFormat.subFacilityId,
    unavailable: timeSlotToFormat.unavailable,
  }));

  return formattedCombinedSlots;
};

/**
 * Finds the nearest defined start time to a given time
 * @param {moment.Moment} selectedTime - The time selected by the user
 * @param {string[]} definedStartTimes - Array of defined start times in 'HH:mm' format
 * @returns {moment.Moment} The nearest defined start time as a moment object
 */
export const findNearestDefinedStartTime = (selectedTime, definedStartTimes) => {
  const definedMoments = definedStartTimes.map((time) => {
    const [hours, minutes] = time.split(':');
    return Moment(selectedTime).startOf('day').hour(Number(hours)).minute(Number(minutes));
  });

  let nearestTime = definedMoments[0];
  let smallestDifference = Math.abs(selectedTime.diff(nearestTime));

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < definedMoments.length; i++) {
    const difference = Math.abs(selectedTime.diff(definedMoments[i]));
    if (difference < smallestDifference) {
      smallestDifference = difference;
      nearestTime = definedMoments[i];
    }
  }

  if (selectedTime.isAfter(definedMoments[definedMoments.length - 1])) {
    return definedMoments[definedMoments.length - 1];
  }

  if (selectedTime.isBefore(definedMoments[0])) {
    return definedMoments[0];
  }

  return nearestTime;
};
