export const LIVE_DASHBOARD_BOOKINGS_REQUESTED = 'LIVE_DASHBOARD_BOOKINGS_REQUESTED';
export const LIVE_DASHBOARD_BOOKINGS_SUCCEEDED = 'LIVE_DASHBOARD_BOOKINGS_SUCCEEDED';

export const DASHBOARD_BOOKINGS_REQUESTED = 'DASHBOARD_BOOKINGS_REQUESTED';
export const DASHBOARD_BOOKINGS_SUCCEEDED = 'DASHBOARD_BOOKINGS_SUCCEEDED';

export const initialState = {
  dashboardBookings: {
    liveBookings: [],
    upcomingBookings: [],
    unpaidBookings: [],
    totalDayReservations: 0,
    totalUnpaidSubscriptions: 0,
    totalCancellationsToday: 0,
    totalSubscriptions: 0,
    lastUpdated: null,
  },
  dashboardLoading: false,
};

export function requestDashboardBookings(query) {
  return { type: DASHBOARD_BOOKINGS_REQUESTED, query };
}
export function dashboardBookingsRequestSucceeded(dashboardBookings) {
  return { type: DASHBOARD_BOOKINGS_SUCCEEDED, dashboardBookings };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_BOOKINGS_REQUESTED:
      return { ...state, refresh: action.query, dashboardLoading: true };
    case DASHBOARD_BOOKINGS_SUCCEEDED:
      return { ...state, dashboardBookings: action.dashboardBookings, dashboardLoading: false };
    default:
      return state;
  }
};
