/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogTitle, DialogContent,
} from '@material-ui/core';
import { useMutation } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import { useCompany, useMobile } from '../../../hooks';
import { createEventBookingModifier } from '../../../services/eventsServices';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  price: Yup.number().typeError('Price must be a number').min(0, 'Price must be positive').required('Price is required'),
});

export const EventBookingModifierDialog = ({
  eventId,
  onUpdate,
}) => {
  const company = useCompany();
  const isMobile = useMobile();
  const [isOpen, setIsOpen] = useState(false);

  const mutation = useMutation({
    mutationFn: async (values) => {
      const res = await createEventBookingModifier(company.id, eventId, values);
      if (res.status !== 200) throw new Error('Failed to create booking modifier');
      return res.data;
    },
    onSuccess: (data) => {
      onUpdate(data);
      setIsOpen(false);
    },
  });

  return (
    <div>
      <Button color="primary" variant="contained" onClick={() => setIsOpen(true)}>
        Add a booking modifier
      </Button>
      <Dialog
        open={isOpen}
        fullScreen={isMobile}
        fullWidth
        maxWidth="sm"
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>Add a booking modifier</DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => setIsOpen(false)} />
          </DialogActions>
        </div>
        <Formik
          initialValues={{
            name: '',
            price: null,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => mutation.mutate(values)}
        >
          {({ getFieldProps, errors, touched }) => (
            <Form>
              <DialogContent style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <PBInput
                  id="name"
                  label="Booking Modifier Name"
                  {...getFieldProps('name')}
                  isError={touched.name && errors.name}
                  errorMessage={touched.name && errors.name}
                />
                <PBInput
                  id="price"
                  label="Booking Modifier Price"
                  {...getFieldProps('price')}
                  isError={touched.price && errors.price}
                  errorMessage={touched.price && errors.price}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  color="secondary"
                  variant="outlined"
                  disabled={mutation.isLoading}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={mutation.isLoading}
                >
                  Add
                </Button>
              </DialogActions>
            </Form>
          )}

        </Formik>
      </Dialog>
    </div>
  );
};
