// @flow weak

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { runDbHealthCheck } from '../reducers/companiesReducer';

// eslint-disable-next-line react/prefer-stateless-function
class RunDbHealthCheckButton extends Component {
  render() {
    const { runDbHealthCheckAction } = this.props;
    return (
      <Button
        onClick={() => runDbHealthCheckAction()}
        color="secondary"
        variant="contained"
        style={{ margin: '20px' }}
      >
        Run Database Health Check
      </Button>
    );
  }
}

RunDbHealthCheckButton.propTypes = {
  runDbHealthCheckAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  runDbHealthCheckAction: () => dispatch(runDbHealthCheck()),
});

export default connect(null, mapDispatchToProps)(RunDbHealthCheckButton);
