/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { Button, Divider, Typography } from '@material-ui/core';

export const ContactUs = ({ companyInfo, updateCompany }) => {
  const dispatch = useDispatch();
  const { id, onboarding } = companyInfo;
  const [contactUsEnabled, setContactUsEnabled] = useState(false);

  useEffect(() => {
    setContactUsEnabled(companyInfo.onboarding?.contactUsEnabled);
  }, [onboarding]);

  return (
    <>
      <Typography style={{ textDecoration: 'underline' }}>
        Enable Contact Us
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <PBInput
          id="enableContactUs"
          type="toggle"
          label="Enable Contact Us"
          value={contactUsEnabled}
          onChange={(e) => setContactUsEnabled(e.target.checked)}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        type="button"
        style={{ width: '50%', alignSelf: 'end' }}
        onClick={() => {
          dispatch(
            updateCompany(id, {
              onboarding: {
                ...companyInfo.onboarding,
                contactUsEnabled,
              },
            }),
          );
        }}
      >
        Save
      </Button>
      <Divider />
    </>
  );
};
