/*
  The SettingsModal is a component that holds the analytics settings configuration for
  1. Booking Type (SettingsModalBookingType)
  2. Events (SettingsModalEvents)
  3. Timeframe (SettingsModalTimeframes)

  Furthermore, SettingsModal functions as the caller/dispatcher to retrieve new bookings data.
  This works as follows, as the user configures setings through the aforementioned child components,
  SettingsModal holds local state corresponding to these choices. When the user clicks GO, the
  action to retrieve bookings data is dispatched, using local state to fill the query. Then,
  global (Redux store) state is updated accordingly by dispatching the setAnalytics<> actions.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';

import Alert from '@material-ui/lab/Alert';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import CreateIcon from '@material-ui/icons/Create';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DomainIcon from '@material-ui/icons/Domain';
import SettingsIcon from '@material-ui/icons/Settings';

import '../../styles/eventAnalytics.css';
import { DialogContent } from '@material-ui/core';
import { getFromToDates, getFromToDatesPrevious } from '../../helper';
import { SettingsModalTimeframes } from './SettingsModalTimeframes';
import {
  retrieveAnalyticsEventBookings,
  setAnalyticsEventBookingsType,
  setAnalyticsEvents,
  setAnalyticsEventTimeframe,
  setAnalyticsEventTimeframeCustom,
} from '../../../../reducers/analyticsReducer';
import { TabPanel } from './subcomponents/SettingsModalTabPanel';
import SettingsModalEvents from './SettingsModalEvents';
import { SettingsModalBookingType } from './SettingsModalBookingType';

class SettingsModal extends React.Component {
  constructor(props) {
    super(props);

    const {
      analyticsEventBookingsType, analyticsEvents, analyticsEventTimeframe,
    } = this.props;

    this.state = {
      initialModalOpenLocalState: {
        localBookingsType: analyticsEventBookingsType,
        localEvents: analyticsEvents,
        localTimeframe: analyticsEventTimeframe.split('_').join(' '),
        localTimeframeCustom: [null, null],
      },
      localBookingsType: analyticsEventBookingsType,
      localEvents: analyticsEvents,
      localTimeframe: analyticsEventTimeframe.split('_').join(' '),
      localTimeframeCustom: { startDate: null, endDate: null },
      modalOpen: false,
      selectedTabIndex: 0,
    };

    this.changeSelectedTabIndex = this.changeSelectedTabIndex.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeModalSimple = this.closeModalSimple.bind(this);
    this.openModal = this.openModal.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.setMyState = this.setMyState.bind(this);
  }

  componentDidUpdate(previousProps) {
    const { analyticsEvents, setAnalyticsEvents } = this.props;
    if (previousProps.analyticsEvents.formattedEvents
      !== analyticsEvents.formattedEvents) {
      this.setState({
        localEvents: {
          eventIDs: analyticsEvents.eventIDs,
          eventNames: analyticsEvents.eventNames,
        },
      }, () => setAnalyticsEvents(
        {
          formattedEvents: analyticsEvents.formattedEvents,
          totalUtilisation: analyticsEvents.totalUtilisation,
          totalRevenue: analyticsEvents.totalRevenue,
          totalAvailableSpace: analyticsEvents.totalAvailableSpace,
          allocations: analyticsEvents.allocations,
          eventIDs: analyticsEvents.eventIDs,
          eventNames: analyticsEvents.eventNames,
        },
      ));
    }
  }

  /*
      Update component state based upon configurations in child components.
    */
  setMyState(pieceOfState, value) {
    if (pieceOfState === 'localTimeframe' && typeof (value) === 'object') {
      this.setState({
        [pieceOfState]: 'Custom',
        localTimeframeCustom: value,
      });
    } else {
      this.setState({
        [pieceOfState]: value,
      });
    }
  }

  /*
    Select all events in the Events tab.
  */
  selectAll() {
    const {
      analyticsEvents,
    } = this.props;

    const allEvents = {
      eventIDs: analyticsEvents.formattedEvents.map((event) => event.id),
      eventNames: analyticsEvents.formattedEvents.map((event) => event.name),
    };

    this.setState({
      localEvents: allEvents,
    });
  }

  /*
    Open modal and save snapshot of current local state.
  */
  openModal() {
    const {
      localBookingsType, localEvents, localTimeframe, localTimeframeCustom,
    } = this.state;

    const currentState = {
      localBookingsType,
      localEvents,
      localTimeframe,
      localTimeframeCustom,
    };
    this.setState({
      initialModalOpenLocalState: currentState,
      modalOpen: true,
    });
  }

  /*
    Called when cancel button is pressed. Reverts local state
    back to local state when the modal was last opened.
  */
  closeModalSimple() {
    const { initialModalOpenLocalState } = this.state;
    this.setState({
      localBookingsType: initialModalOpenLocalState.localBookingsType,
      localEvents: initialModalOpenLocalState.localEvents,
      localTimeframe: initialModalOpenLocalState.localTimeframe,
      modalOpen: false,
    });
  }

  /*
    Dispatch the retrieveAnalyticsEventBookings action using local
    state to fill the query. Then, 'push' local state to global state
    by dispatching the setAnalytics<> actions. Finally, close the modal.
  */
  closeModal() {
    const {
      retrieveAnalyticsEventBookings,
      setAnalyticsEventBookingsType,
      setAnalyticsEventTimeframe,
      setAnalyticsEventTimeframeCustom,
    } = this.props;
    const {
      localTimeframe, localTimeframeCustom, localBookingsType, localEvents,
    } = this.state;
    let dates; let
      datesPrevious;
    if (localTimeframe === 'Custom') {
      dates = getFromToDates(localTimeframeCustom);
      datesPrevious = getFromToDatesPrevious(localTimeframeCustom);
    } else {
      dates = getFromToDates(localTimeframe.split(' ').join('_'));
      datesPrevious = getFromToDatesPrevious(localTimeframe.split(' ').join('_'));
    }
    retrieveAnalyticsEventBookings({
      bookingType: localBookingsType,
      dates,
      datesPrevious,
      eventIDs: localEvents.eventIDs,
      eventNames: localEvents.eventNames,
    });

    setAnalyticsEventBookingsType(localBookingsType);
    setAnalyticsEvents(localEvents);
    setAnalyticsEventTimeframe(localTimeframe.split(' ').join('_'));
    if (localTimeframe === 'Custom') {
      setAnalyticsEventTimeframeCustom(dates);
    }
    this.setState({
      modalOpen: false,
    });
  }

  changeSelectedTabIndex(event, newIndex) {
    this.setState({
      selectedTabIndex: newIndex,
    });
  }

  render() {
    const {
      modalOpen, selectedTabIndex, localBookingsType,
      localTimeframe, localTimeframeCustom, localEvents,
    } = this.state;
    const goButtonDisabled = localTimeframe === 'Custom'
      && (!localTimeframeCustom.startDate || !localTimeframeCustom.endDate);
    const onEventsTab = selectedTabIndex === 1;

    return (
      <div>
        <Dialog onBackdropClick={this.closeModalSimple} open={modalOpen}>
          <DialogContent>
            <AppBar
              position="static"
              style={{
                backgroundColor: '#fafbff',
                boxShadow: 'none',
                paddingBottom: '10px',
              }}
            >
              <Tabs
                value={selectedTabIndex}
                onChange={this.changeSelectedTabIndex}
                variant="scrollable"
                scrollButtons="on"
                textColor="primary"
                aria-label="scrollable force tabs example"
              >
                <Tab label="Booking Type" icon={<CreateIcon />} {...0} />
                <Tab label="Events" icon={<DomainIcon />} {...1} />
                <Tab label="Timeframe" icon={<DateRangeIcon />} {...2} />
              </Tabs>
            </AppBar>
            <TabPanel value={selectedTabIndex} index={0}>
              <SettingsModalBookingType
                setParentState={this.setMyState}
                value={localBookingsType}
              />
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={1}>
              <SettingsModalEvents
                setParentState={this.setMyState}
                value={localEvents}
              />
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={2}>
              <SettingsModalTimeframes
                setParentState={this.setMyState}
                customSelected={localTimeframe === 'Custom'}
                customValues={localTimeframeCustom}
                value={localTimeframe}
              />
            </TabPanel>
          </DialogContent>
          <DialogActions>
            <ConditionallyVisible condition={goButtonDisabled}>
              <Alert severity="error" style={{ padding: '0 16px', width: '100%' }}>
                Select dates.
              </Alert>
            </ConditionallyVisible>
            <Button color="secondary" onClick={this.closeModalSimple} variant="outlined">
              Cancel
            </Button>
            <ConditionallyVisible condition={onEventsTab}>
              <Button
                color="secondary"
                onClick={this.selectAll}
                variant="contained"
              >
                Select All
              </Button>
            </ConditionallyVisible>
            <Button
              color="secondary"
              disabled={goButtonDisabled}
              onClick={this.closeModal}
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <IconButton onClick={this.openModal}>
          <SettingsIcon fontSize="large" />
        </IconButton>
      </div>
    );
  }
}

SettingsModal.propTypes = {
  analyticsEventTimeframe: PropTypes.string.isRequired,
  analyticsEventBookingsType: PropTypes.string.isRequired,
  retrieveAnalyticsEventBookings: PropTypes.func.isRequired,
  setAnalyticsEventTimeframe: PropTypes.func.isRequired,
  setAnalyticsEventTimeframeCustom: PropTypes.func.isRequired,
  setAnalyticsEventBookingsType: PropTypes.func.isRequired,
  setAnalyticsEvents: PropTypes.func.isRequired,
  analyticsEvents: PropTypes.shape().isRequired,
};

const mapDispatchToProps = {
  retrieveAnalyticsEventBookings,
  setAnalyticsEventBookingsType,
  setAnalyticsEvents,
  setAnalyticsEventTimeframe,
  setAnalyticsEventTimeframeCustom,
};

const mapStateToProps = (state) => ({
  analyticsEventBookingsType: state.analytics.analyticsEventBookingsType,
  analyticsEvents: state.analytics.analyticsEvents,
  analyticsEventTimeframe: state.analytics.analyticsEventTimeframe,
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);
