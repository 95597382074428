import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as reservationsActions from '../../../../reducers/reservationsReducer';
import TabbedNavigation from '../../../../shared-components/TabbedNavigation';
import PreviousBookerUserSearch from '../../../users/components/PreviousBookerUserSearch';
import NewBookerUserCreation from '../../../users/components/NewBookerUserCreation';

const BookingStepperUserInfoPicker = (props) => {
  const tabs = [];
  const { user, updateReservationUserDetails, resetUserManagerReservationCreation } = props;
  tabs.push({
    id: 0,
    name: 'Existing Customer',
    body: (<PreviousBookerUserSearch
      updateUserDetails={(updatedUserDetails) => updateReservationUserDetails(updatedUserDetails)}
      user={user}
    />),
  });

  tabs.push({
    id: 1,
    name: 'New Customer',
    onClick: () => resetUserManagerReservationCreation(),
    body: (<NewBookerUserCreation
      updateUserDetails={(updatedUserDetails) => updateReservationUserDetails(updatedUserDetails)}
      user={user}
    />),
  });

  return (
    <div className="reservation-creation-user-selecton">
      <TabbedNavigation tabsToNavigate={tabs} />
      <div className="tabbed-navigation-tabs-wrapper" />
    </div>
  );
};

BookingStepperUserInfoPicker.propTypes = {
  user: PropTypes.shape().isRequired,
  updateReservationUserDetails: PropTypes.func.isRequired,
  resetUserManagerReservationCreation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.reservation.userDetailsForReservation,
});

const mapDispatchToProps = (dispatch) => ({
  updateReservationUserDetails: (keyWithValue) => dispatch(
    reservationsActions.updateReservationUserDetails(keyWithValue),
  ),
  resetUserManagerReservationCreation: () => dispatch(
    reservationsActions.resetUserManagerReservationCreation(),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingStepperUserInfoPicker);
