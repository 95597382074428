/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import 'rc-time-picker/assets/index.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CheckboxListPickerById from '../../../../components/CheckboxListPickerById';
import * as accessRestrictionsActions from '../../../../reducers/accessRestrictionsReducer';
import * as companyActions from '../../../../reducers/companiesReducer';
import MenuHeading from '../../../../components/MenuHeading';
import { Markdown } from '../../../../helpers/Markdown/Markdown';

const AccessRestrictionsDialogMembershipPicker = ({
  companyMemberships,
  memberships,
  editingAccessRestriction,
  updateAccessRestrictionCreation,
  getCompanyMemberships,
}) => {
  useEffect(() => {
    getCompanyMemberships();
  }, [getCompanyMemberships]);

  return (
    <>
      <MenuHeading title="Memberships:" />
      {memberships.map((membership) => (
        <span key={membership.id}>
          <Markdown children={membership.description} />
        </span>
      ))}
      <CheckboxListPickerById
        listItems={companyMemberships.map((companyMembership) => ({
          id: companyMembership.id,
          description: companyMembership.name,
        }))}
        listName="Memberships"
        checkedItems={memberships}
        onUpdate={(updatedMemberships) => updateAccessRestrictionCreation(
          { memberships: updatedMemberships },
        )}
        flagCheckedItemsModified={updateAccessRestrictionCreation}
        editingAccessRestriction={editingAccessRestriction}
      />
    </>
  );
};

AccessRestrictionsDialogMembershipPicker.propTypes = {
  companyMemberships: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  memberships: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  editingAccessRestriction: PropTypes.bool.isRequired,
  updateAccessRestrictionCreation: PropTypes.func.isRequired,
  getCompanyMemberships: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  companyMemberships: state.companies.memberships,
  memberships: state.accessRestrictions.newAccessRestriction.memberships,
  editingAccessRestriction: state.accessRestrictions.editingAccessRestriction,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccessRestrictionCreation: (keyWithValue) => dispatch(
    accessRestrictionsActions.updateAccessRestrictionCreation(keyWithValue),
  ),
  getCompanyMemberships: () => dispatch(companyActions.requestCompanyMembershipRetrieval()),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(AccessRestrictionsDialogMembershipPicker);
