import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import '../../../styles/payments.css';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { CsvBuilder } from 'filefy';
import * as helper from '../../../helpers/index';
import { renderSubscriptionStatus } from '../../subscriptions/components/SubscriptionsTable';

const UserSubscriptionsTable = ({ subscriptions }) => {
  const columns = [
    {
      title: 'Name',
      field: 'subscriptionName',
      render: (row) => (
        <a href={`/subscriptions/${row.id}`}>
          {row.subscriptionName}
        </a>
      ),
    },
    {
      title: 'Facility',
      field: 'accessRestriction.facilities[0].name',
      render: (row) => (
        row.accessRestriction.facilities.map((facility) => (
          <div>
            {facility.name}
          </div>
        ))
      ),
    },
    {
      title: 'Amount',
      field: 'amount',
      render: (row) => (
        <p>
          £
          {(row.amount / 100).toFixed(2)}
        </p>
      ),
    },
    {
      title: 'Validity',
      field: 'validity',
      render: (row) => (helper.generateValidityText(row.accessRestriction,
        row.accessRestriction?.facilities[0].timezone)),
    },
    {
      title: 'Day',
      field: 'day',
      render: (row) => {
        const formattedWeekday = moment().weekday(parseInt(row.accessRestriction.weekday, 10)).format('dddd');
        return formattedWeekday;
      },
      customFilterAndSearch: (term, row) => {
        const formattedWeekday = moment().weekday(parseInt(row.accessRestriction.weekday, 10)).format('dddd');
        const dayNameString = formattedWeekday.toString().toLowerCase();
        return dayNameString.includes(term.toLowerCase());
      },
    },
    {
      title: 'Time',
      field: 'time',
      render: (row) => (helper.createTimeRangeString(
        row.accessRestriction.startTime, row.accessRestriction.endTime,
      )),
    },
    {
      title: 'Confirmed',
      field: 'status',
      render: (row) => renderSubscriptionStatus(row),
    },
    {
      title: 'Created',
      field: 'createdAt',
      render: (row) => (<div>{moment(row.createdAt).format('DD MMM YY, HH:mm')}</div>),
    },
    // {
    //   title: 'Status',
    //   field: 'status',
    // },
  ];

  return (
    <>
      <div className="table-section">
        <Paper
          style={{
            borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden', width: '100%',
          }}
          component={Paper}
        >
          <MaterialTable
            className="table"
            aria-label="simple table"
            data={subscriptions}
            title="Pitchbooking Subscriptions"
            icons={{
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              Export: () => <SaveAlt />,
              ResetSearch: () => <Clear />,
              SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
            }}
            columns={columns}
            options={{
              tableLayout: 'auto',
              exportButton: true,
              selection: true,
              emptyValue: 'No subscriptions exist for this user',
              emptyRowsWhenPaging: false,
              exportFileName: 'Pitchbooking Subscriptions',
              pageSize: 25,
              pageSizeOptions: [10, 25, 50, 100],
              showFirstLastPageButtons: false,
              showTitle: false,
              headerStyle: {
                backgroundColor: '#efefef',
                fontWeight: 'bold',
              },
            }}
            actions={[
              {
                position: 'toolbarOnSelect',
                label: { style: { display: 'block' } },
                icon: () => <Button color="primary" variant="contained">Export</Button>,
                tooltip: 'Export subscriptions to CSV',
                onClick: (e, rowData) => {
                  const fileName = 'Pitchbooking-User-Subscriptions';
                  const builder = new CsvBuilder(`${fileName}.csv`);
                  builder
                    .setColumns(columns.map((columnDef) => columnDef.title))
                    .addRows(rowData.map((row) => columns.map((columnDef) => row[columnDef.field])))
                    .exportFile();
                },
              },
            ]}
            localization={{
              toolbar: {
                exportTitle: 'Download',
              },
            }}
          />
        </Paper>
      </div>
    </>
  );
};

UserSubscriptionsTable.propTypes = {
  subscriptions: PropTypes.shape().isRequired,

};

export default (UserSubscriptionsTable);
