/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PaymentConfirm from '@pitchbooking-dev/pb-shared/lib/components/Payment/PaymentConfirm';

const BillingProcessing = (params) => {
  console.log(params);
  return (

    <div>
      <PaymentConfirm
        location={params.location}
        match={params.match}
      />
    </div>
  );
};

export default BillingProcessing;
