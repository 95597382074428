/* eslint-disable max-len */
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import TeamMembersTable from './TeamMembersTable';
import '../../styles/payments.css';
import { useCompany, usePitchbookingUser } from '../../hooks';
import { getCompanyUsersService } from '../../services/companyUsersServices';
import { setRoles } from '../../reducers/companyUsersReducer';

const TeamMembers = () => {
  const dispatch = useDispatch();
  const company = useCompany();
  const { isPitchbookingManager } = usePitchbookingUser();

  const [isForbidden, setIsForbidden] = useState(false);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['company', company.id, 'users'],
    queryFn: async () => {
      const res = await getCompanyUsersService(company.id);

      switch (res.status) {
        case 403:
          setIsForbidden(true);
          return null;
        case 200: {
          dispatch(setRoles(res.data.roles));

          if (isPitchbookingManager) {
            return res.data.companyUsers;
          }
          return res.data.companyUsers.filter((user) => !user.email.includes('@pitchbooking.com'));
        }
        default:
          throw new Error('Failed to fetch company users');
      }
    },
    enabled: !!company?.id,
  });

  if (isForbidden && company.status === 'DIRECTORY_CLAIMED_PENDING_APPROVAL') {
    return (
      <div style={{ padding: '1rem' }}>
        <Typography variant="h6" gutterBottom>
          Management Features Pending Approval
        </Typography>
        <Typography>
          As soon as your claim has been verified, you will be able to add and manage other administrators & team members.
        </Typography>
      </div>
    );
  }

  if (isError || isForbidden) {
    return <div style={{ padding: '1rem' }}>Error</div>;
  }

  if (isLoading) {
    return <div style={{ padding: '1rem' }}>Loading...</div>;
  }

  return (
    <TeamMembersTable
      team={data}
    />
  );
};

export default TeamMembers;
