import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  Switch,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as actions from '../../../reducers/addonsReducer';
import MenuHeading from '../../../components/MenuHeading';

const styles = () => ({
  dialog: {
    width: '820px',
  },
  menuHeading: {
    marginTop: 40,
  },
  textField: {
    width: '100%',
    marginLeft: 15,
  },
  fullWidth: {
    width: '100%',
  },
});

const EditPOSDialog = ({
  classes, companyAddons, editProduct, categories, companySites,
}) => {
  const addOn = companyAddons;
  if (!addOn) return null;
  const [open, setOpen] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [unlimited, setUnlimited] = React.useState(true);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: addOn?.status,
      type: addOn?.type,
      isStandalone: addOn?.isStandalone,
      price: addOn?.priceModifiers?.length > 0 ? addOn.priceModifiers[0]?.value : 0,
      visible: addOn?.visible,
      quantity: addOn?.quantity,
      name: addOn?.name,
      costCode: addOn?.costCode,
      categoryId: addOn?.category?.id,
      description: addOn?.description,
      sites: companySites.map((site) => ({ siteId: site.id, currentStock: null })),
      trackStock: addOn?.trackStock,
    },
    onSubmit: (values) => {
      editProduct(addOn.id, values);
      setOpen(false);
      formik.resetForm();
      setUnlimited(true);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
    formik.resetForm();
    setUnlimited(true);
  };

  const visibleTooltipString = 'When a product is set to visible it will be able to be selected by a booker on the public booking site. If it is set to private then it will only be able to be set by a facility manager.';
  const typeTooltipString = 'A Resource type product is a fixed resource e.g A Floodlight. A consumable has a quantity e.g birthday cake';
  const standaloneTooltipString = 'A standalone product is a product that is not associated with a facility or event. It can be booked on its own.';
  return (
    <div>
      <Button onClick={handleClickOpen} variant="contained" color="primary">
        Edit Product
      </Button>
      <Dialog
        maxWidth="820px"
        open={open}
        onClose={handleRequestClose}
      >
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText>
            A product can be a standalone item that can be sold on your partner page or POS.
            Or it can be something that can be added alongside a facility or event booking.
          </DialogContentText>
          <form onSubmit={formik.handleSubmit}>
            <div style={{ display: 'flex', gap: '15px' }}>
              <TextField
                id="name"
                label="Name"
                type="text"
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              <TextField
                id="price"
                label="Price"
                type="number"
                fullWidth
                value={formik.values.price}
                onChange={formik.handleChange}
              />
            </div>
            <MenuHeading title="Description" />
            <TextField
              id="description"
              label="Description"
              type="text"
              fullWidth
              value={formik.values.description}
              onChange={formik.handleChange}
            />
            <MenuHeading title="Category" />
            <Select
              native
              value={formik.values.categoryId}
              onChange={formik.handleChange}
              inputProps={{
                id: 'categoryId',
              }}
            >
              <option value={null}>Uncategorised</option>
              {categories.map((category) => (
                <option value={category.id}>{category.name}</option>
              ))}
            </Select>
            <MenuHeading title="Status" />
            <Select
              native
              value={formik.values.status}
              onChange={formik.handleChange}
              inputProps={{
                id: 'status',
              }}
            >
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
              <option value="ARCHIVED">Archived</option>
            </Select>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MenuHeading title="Track Stock" />
              <Tooltip title={standaloneTooltipString} placement="right">
                <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '15px' }} />
              </Tooltip>
            </div>
            <Switch
              checked={formik.values.trackStock}
              onChange={() => formik.setFieldValue('trackStock', !formik.values.trackStock)}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MenuHeading title="Is Standalone Product" />
              <Tooltip title={standaloneTooltipString} placement="right">
                <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '15px' }} />
              </Tooltip>
            </div>
            <Switch
              checked={formik.values.isStandalone}
              onChange={() => formik.setFieldValue('isStandalone', !formik.values.isStandalone)}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <ConditionallyVisible condition={!formik.values.isStandalone}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MenuHeading title="Type" />
                <Tooltip title={typeTooltipString} placement="right">
                  <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '15px' }} />
                </Tooltip>
              </div>
              <Select
                native
                value={formik.values.type}
                onChange={formik.handleChange}
                inputProps={{
                  id: 'type',
                }}
              >
                <option value="RESOURCE">Resource</option>
                <option value="CONSUMABLE">Consumable</option>
              </Select>
              <ConditionallyVisible condition={formik.values.type === 'CONSUMABLE'}>
                <MenuHeading title="Unlimited Quantity" />
                <Switch
                  checked={unlimited}
                  onChange={() => setUnlimited(!unlimited)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <ConditionallyVisible condition={!unlimited}>
                  <TextField
                    id="quantity"
                    label="Maximum quantity per booking"
                    type="number"
                    fullWidth
                    value={formik.values.quantity}
                    inputProps={{
                      step: '1',
                    }}
                    onChange={formik.handleChange}
                  />
                </ConditionallyVisible>
              </ConditionallyVisible>
            </ConditionallyVisible>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MenuHeading title="Publicly visible" />
              <Tooltip title={visibleTooltipString} placement="right">
                <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '15px' }} />
              </Tooltip>
            </div>
            <Switch
              checked={formik.values.visible}
              onChange={() => formik.setFieldValue('visible', !formik.values.visible)}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <div style={{
              display: 'flex', gap: '1rem', alignItems: 'flex-end', marginBottom: '0.5rem',
            }}
            >
              <MenuHeading title="Cost Code" />
              <HelpTwoToneIcon
                onClick={() => setShowAlert(!showAlert)}
                style={{
                  fontSize: '1.5rem', cursor: 'pointer', color: !showAlert ? 'var(--accent)' : '', marginBottom: '0.2rem',
                }}
              />
            </div>
            {showAlert && (
            <Alert severity="info" style={{ marginTop: '1rem' }}>
              <AlertTitle style={{ fontWeight: '600' }}>Cost Code</AlertTitle>
              Please note, this code will apply to the product only.
              The facility/event will have its own code.
            </Alert>
            )}
            <TextField
              id="costCode"
              label="Cost Code"
              type="text"
              fullWidth
              value={formik.values.costCode}
              onChange={formik.handleChange}
            />
            <DialogActions>
              <Button onClick={handleRequestClose} variant="outlined" color="secondary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

EditPOSDialog.propTypes = {
  classes: PropTypes.shape().isRequired,
  companyAddons: PropTypes.shape().isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  companySites: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  editProduct: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { accessRestrictions } = state;
  const { companyFacilities } = state.facilities;
  const { events } = state.events;
  const { categories, companyAddons } = state.addons;
  const { companySites } = state.sites;
  return {
    accessRestrictions,
    companyFacilities,
    companyEvents: events,
    categories,
    companySites,
    companyAddons,
  };
}

const mapDispatchToProps = (dispatch) => ({
  editProduct: (addonId, reqBody) => dispatch(actions.editProduct(addonId, reqBody)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditPOSDialog));
