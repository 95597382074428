/* eslint-disable react/prop-types */
import React from 'react';
import {
  Dialog, DialogTitle, DialogActions, DialogContent, Button,
} from '@material-ui/core';
import CloseDialogIcon from '@material-ui/icons/Close';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';

export const ExclusionModal = ({
  isOpen,
  onClose,
  onChange,
  onSubmit,
  startDate,
  endDate,
  selectedDates,
  isDisabled,
}) => (
  <Dialog open={isOpen} onClose={onClose}>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <DialogTitle>Add Exclusion</DialogTitle>
      <DialogActions variant="none">
        <CloseDialogIcon onClick={onClose} />
      </DialogActions>
    </div>
    <DialogContent>
      <DayPickerSingleDateController
        isDayBlocked={(day) => (
          day.date() !== moment(startDate).date()
          || day.isBefore(startDate)
          || day.isAfter(endDate)
        )}
        isDayHighlighted={(day) => selectedDates.some((x) => x === day.format('YYYY-MM-DD'))}
        onDateChange={(e) => onChange(e.format('YYYY-MM-DD'))}
      />
      <Button
        onClick={onSubmit}
        variant="contained"
        color="primary"
        style={{ marginTop: '1rem' }}
        disabled={isDisabled}
      >
        Update Exclusions
      </Button>
    </DialogContent>

  </Dialog>
);
