/**
 * @fileoverview Helper methods for analytics.
 */

import Moment from 'moment';

/*
    Maps a FROM and TO date range to 'hour', 'day', or 'week'.
    Used for chart.js charts in CategoryGraph component.
*/
export const calculateAxisGranularity = (from, to) => {
  const timeDifference = Moment(to).diff(Moment(from), 'hours');

  if (timeDifference <= 24) {
    return 'hour';
  }
  if (timeDifference <= 720) {
    return 'day';
  }
  return 'week';
};

/*
    Maps a TIMEFRAME string to an object containing a
    fromDate and a toDate. Both fromDate and toDate are
    of type Moment.

    Example call:
        > getFromToDates('Last_7_Days');
        > { fromDate, toDate }
*/
export const getFromToDates = (timeframe) => {
  const stringFormat = 'YYYY-MM-DD HH:mm';
  let fromDate = Moment().startOf('D').format(stringFormat);
  let toDate = Moment().endOf('D').format(stringFormat);
  switch (timeframe) {
    // Test all of these!
    case 'Today':
      fromDate = Moment(fromDate).subtract(0, 'days').format(stringFormat);
      break;
    case 'Yesterday':
      toDate = Moment(toDate).subtract(1, 'days').format(stringFormat);
      fromDate = Moment(fromDate).subtract(1, 'days').format(stringFormat);
      break;
    case 'Last_7_Days':
      fromDate = Moment(fromDate).subtract(7, 'days').format(stringFormat);
      toDate = Moment(toDate).subtract(1, 'days').format(stringFormat);
      break;
    case 'Last_30_Days':
      fromDate = Moment(fromDate).subtract(30, 'days').format(stringFormat);
      toDate = Moment(toDate).subtract(1, 'days').format(stringFormat);
      break;
    case 'Last_90_Days':
      fromDate = Moment(fromDate).subtract(90, 'days').format(stringFormat);
      toDate = Moment(toDate).subtract(1, 'days').format(stringFormat);
      break;
    case 'This_Month':
      fromDate = Moment(fromDate).startOf('M').format(stringFormat);
      toDate = Moment(fromDate).endOf('M').format(stringFormat);
      break;
      // Custom dates.
    default:
      fromDate = Moment(timeframe.startDate).startOf('D').format(stringFormat);
      toDate = Moment(timeframe.endDate).endOf('D').format(stringFormat);
      break;
  }

  return {
    fromDate,
    toDate,
  };
};

/*
    Maps a TIMEFRAME string to an object containing a fromDatePrevious
    and a toDatePrevious. Both fromDatePrevious and toDatePrevious
    are of type Moment.

    Example call:
        > getFromToDates('Last_7_Days');
        > { fromDatePrevious, toDatePrevious }
*/
export const getFromToDatesPrevious = (timeframe) => {
  const stringFormat = 'YYYY-MM-DD HH:mm';
  let fromDatePrevious = Moment().startOf('D').format(stringFormat);
  let toDatePrevious = Moment().endOf('D').format(stringFormat);
  let fromDate;
  let toDate;
  let timeDifference;
  switch (timeframe) {
    // Test all of these!
    case 'Today':
      toDatePrevious = Moment(toDatePrevious).subtract(1, 'days').format(stringFormat);
      fromDatePrevious = Moment(fromDatePrevious).subtract(1, 'days').format(stringFormat);
      break;
    case 'Yesterday':
      toDatePrevious = Moment(toDatePrevious).subtract(2, 'days').format(stringFormat);
      fromDatePrevious = Moment(fromDatePrevious).subtract(2, 'days').format(stringFormat);
      break;
    case 'Last_7_Days':
      fromDatePrevious = Moment(fromDatePrevious).subtract(14, 'days').format(stringFormat);
      toDatePrevious = Moment(toDatePrevious).subtract(8, 'days').format(stringFormat);
      break;
    case 'Last_30_Days':
      fromDatePrevious = Moment(fromDatePrevious).subtract(60, 'days').format(stringFormat);
      toDatePrevious = Moment(toDatePrevious).subtract(31, 'days').format(stringFormat);
      break;
    case 'Last_90_Days':
      fromDatePrevious = Moment(fromDatePrevious).subtract(180, 'days').format(stringFormat);
      toDatePrevious = Moment(toDatePrevious).subtract(91, 'days').format(stringFormat);
      break;
    case 'This_Month':
      fromDatePrevious = Moment(fromDatePrevious).subtract(1, 'months').startOf('M').format(stringFormat);
      toDatePrevious = Moment(toDatePrevious).subtract(1, 'months').endOf('M').format(stringFormat);
      break;
    default:
      // Handle custom dates.
      fromDate = Moment(timeframe.startDate).startOf('D').format(stringFormat);
      toDate = Moment(timeframe.endDate).endOf('D').format(stringFormat);

      // NOTE: We add 1 because toDate is stamped right before
      // midnight which causes initial diff to underestimate.
      timeDifference = Moment(toDate).diff(Moment(fromDate), 'days') + 1;

      fromDatePrevious = Moment(fromDate).subtract(timeDifference, 'days').format(stringFormat);
      toDatePrevious = Moment(toDate).subtract(timeDifference, 'days').format(stringFormat);

      break;
  }

  return {
    fromDatePrevious,
    toDatePrevious,
  };
};
