import moment from 'moment';
import ArraySort from 'array-sort';

const GenerateInvoiceCSVData = (reservations) => {
  const fields = [
    'Id',
    'Name',
    'Date',
    'Time',
    'Unit Price',
    'Tax',
    'Amount',
  ];
  const includeCostCodes = reservations
    .filter((d) => d.costCode !== null && d.costCode !== undefined).length > 0;
  if (includeCostCodes) { fields.push('Cost Code'); }
  const data = [];
  reservations.forEach((n) => {
    let { id } = n;
    let nameValue = n.facilityName;
    let dateValue = moment(n.startTime).format('DD-MMM-YYYY');
    let timeValue = `${moment(n.startTime).format('HH:mm')} - ${moment(n.endTime).add(1, 'seconds').format('HH:mm')}`;
    if (n.type === 'SUBSCRIPTION') {
      id = n.subscriptionReservationId;
    } else if (n.type === 'INVOICE_ITEM') {
      nameValue = n.notes;
      dateValue = '';
      timeValue = '';
    }
    const row = {
      [fields[0]]: id,
      [fields[1]]: nameValue,
      [fields[2]]: dateValue,
      [fields[3]]: timeValue,
      [fields[4]]: parseFloat(n.totalExTax).toFixed(2),
      [fields[5]]: parseFloat(n.tax).toFixed(2),
      [fields[6]]: parseFloat(n.total).toFixed(2),
    };
    if (includeCostCodes) { row[fields[7]] = n.costCode; }
    data.push(row);
  });
  ArraySort(data, [fields[2], fields[1], fields[3]]);
  return { data, fields };
};

export default (GenerateInvoiceCSVData);
