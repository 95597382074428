/*
    The HeaderSettingsLegend component is a visual representation
    of the currently selected analytics settings and is used to
    help the user contextualize the data they are seeing.

    The settings are:
        1. Booking Type
        2. Facilities
        3. Timeframe
*/

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StatisticLabel } from './StatisticLabel';

class HeaderSettingsLegend extends React.Component {
  render() {
    const {
      initialAnalyticsFacilities,
      analyticsFacilities,
      analyticsTimeframe,
      analyticsBookingsType,
    } = this.props;
    // If number of currently selected facilities is equal
    // to the number of initial facilities, then facilities
    // is set to 'ALL'. Otherwise, facilities is set to 'SUBSET'.
    const allFacilitiesBoolean = initialAnalyticsFacilities.facilityIDs.length
      === analyticsFacilities.facilityIDs.length;
    const facilities = allFacilitiesBoolean ? 'All' : 'Subset';
    const formattedAnalyticsTimeframe = analyticsTimeframe.includes(':') ? 'Custom' : analyticsTimeframe;
    return (
      <div id="headerSettingsLegendContainer">
        <div className="headerItem" style={{ width: 'auto' }}>
          <div className="statistic" style={{ borderRight: '1px #00000020 solid', height: '80%', width: 'auto' }}>
            <StatisticLabel labelString="Booking Type" />
            {analyticsBookingsType}
          </div>
          <div className="statistic" style={{ borderRight: '1px #00000020 solid', height: '80%', width: 'auto' }}>
            <StatisticLabel labelString="Facilities" />
            {facilities}
          </div>
          <div className="statistic" style={{ width: 'auto' }}>
            <StatisticLabel labelString="Timeframe" />
            {formattedAnalyticsTimeframe.split('_').join(' ')}
          </div>
        </div>
      </div>
    );
  }
}

HeaderSettingsLegend.propTypes = {
  analyticsBookingsType: PropTypes.string.isRequired,
  analyticsTimeframe: PropTypes.string.isRequired,
  analyticsFacilities: PropTypes.shape().isRequired,
  initialAnalyticsFacilities: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  analyticsBookingsType: state.analytics.analyticsBookingsType,
  analyticsFacilities: state.analytics.analyticsFacilities,
  analyticsTimeframe: state.analytics.analyticsTimeframe,
  initialAnalyticsFacilities: state.analytics.initialAnalyticsFacilities,
});

export default connect(mapStateToProps)(HeaderSettingsLegend);
